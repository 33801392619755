import React from "react";

import BlockPreviewHTML from './BlockPreviews/HTML';
import BlockActions from "./BlockActions";

class BlockHTMLEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      preview: true
    }
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  onChange = (name, value) => {
    const content = this.state.content
    content[name] = value
    this.setState({ content }, () => {
      this.props.onChange(this.props.index, [content])
    } )
  }

  componentDidMount() {
    if (this.props.initData) {

      const content = this.props.initData[0]
      const preview = this.props.preview

      this.setState({
        preview,
        content
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        {
          this.state.preview
          ? <div className="dd-block dd-block--default" onClick={() => this.togglePreviewEditMode()}>
            <div className="dd-block__preview">
              <BlockPreviewHTML block={this.state.content} type='textarea' />
            </div>
          </div>
          :
          <div className="dd-block dd-block--default dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-section">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="text"
                    onChange={(e) => this.onChange(e.target.name, e.target.value)}
                    defaultValue={this.state.content.text}
                    placeholder="Bitte tragen Sie hier HTML-Code ein."
                  />
                </div>
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}

export default BlockHTMLEdit;
