import React from "react";

import { throttle } from "throttle-debounce";

import Loader from "../Shared/atom/Loader/Loader";
import Title from "../Shared/module/Title/Title"
import RadioButton from "../Shared/atom/RadioButton/RadioButton";
import SimpleSelectfield from "../Shared/atom/Selectfield/SimpleSelectfield";
import Checkbox from "../Shared/atom/Checkbox/Checkbox";
import Pagination from "../Shared/element/Pagination/Pagination";
import Searchbar from "../Shared/element/Searchbar/Searchbar";
import MediaItem from "./MediaItem";

import DragAndDrop from "../Shared/element/DragAndDrop/DragAndDrop";

import { getToken } from "../../lib/lib";
import { createAsset, getAssets, deleteAsset } from "../../data/api";


class MediaList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      query: '',
      count: 0,
      pagesCount: 0,
      limit: 12,
      defaultLimit: 12,
      currentPage: 0,
      orderField: 'updated_at',
      orderDirection: 'desc',
      filterByType: [],
      filterByOwner: "",
      files: []
    }
    this.searchThrottled = throttle(1000, this.loadData);
  }

  handlePaginationClick = async (e, page) => {
    this.setState({
      ...this.state,
      currentPage: page
    }, async () => {
      await this.loadData()
    })
  }

  handleSelectLimit = (limit) => {
    localStorage.setItem('media_limit', limit);
    this.setState({ limit, currentPage: 0 }, async () => {
      await this.loadData()
    })
  }

  searchQuery = event => {
    this.setState({ query: event.target.value }, () => {
      this.searchThrottled(this.state.query.toLowerCase())
    })
  }

  deleteItem = (itemToDelete) => {
    deleteAsset(getToken(), itemToDelete.id)
    .then(async updatedArticle => {
      await this.loadData()
    })
    .catch(err => {
      this.setState({ isUpdating: false })
    })
  }

  onDrop = (files) => {
    this.setState({ isUpdating: true })
    if (files && files.length && files.length > 0) {

      createAsset(getToken(), files[0])
      .then(async response => {
        if (response) {
          this.setState({ isUpdating: false })
          await this.loadData()
        }
      })
      .catch(err => {
        console.log("error onDrop", err)
      })
    }
  }

  handleSortChange = (e) => {
    if (e.target.checked) {
      const sortBy = e.target.value || 'updated_at'
      this.setState({
        orderField: sortBy
      }, () => {
        this.loadData()
      })
    }
  }

  handleOwnerChange = (e) => {
    if (e.target.checked) {
      const owner = e.target.value
      this.setState({
        filterByOwner: owner
      }, () => {
        this.loadData()
      })
    }
  }

  handleFileTypeChange = event => {
    const value = event.target.value
    let filterByType = this.state.filterByType

    if (filterByType.includes(value)) {
      filterByType = filterByType.filter(item => item !== value) // remove / toggle
    } else (
      filterByType.push(value)
    )

    this.setState({
      filterByType
    }, () => {
      this.loadData()
    })
  }

  handleFileTypeSelection = fileType => {
    const appliedFilters = this.state.filterByType
    if (appliedFilters.includes(fileType)) {
      return true
    }
    return false
  }

  onSelectFilterDate() {
    // #FIXME
    console.log('#FIXME: MediaList.js => onSelectFilterDate()')
  }

  loadData = async () => {

    const limit = localStorage.getItem('media_limit') || this.state.defaultLimit

    const offset = this.state.currentPage * limit

    const assets = await getAssets(getToken(),
                                  limit,
                                  offset,
                                  this.state.orderField,
                                  this.state.orderDirection,
                                  this.state.filterByType,
                                  this.state.filterByOwner,
                                  this.state.query)
    const pagesCount = Math.ceil(assets.count / limit)

    // #FIXME: Dynamisieren?
    const filterDateOptions = [
      { id: '', filterDate: 'Alle anzeigen' },
      { id: 'week', filterDate: 'Letzte Woche' },
      { id: 'month', filterDate: 'Letzter Monat' },
      { id: 'year', filterDate: 'Letztes Jahr' }
    ]

    this.setState({
      pagesCount: pagesCount,
      limit: limit,
      count: assets.count,
      assets: assets.assets,
      filterDateOptions: filterDateOptions,
      loading: false,
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    return (
      <>
        <Title headline="Bilder &amp; Dateien" />
        <div className="dd-box container">
          <div className="row">
            <div className="col-md-3">
              <form className="">
                <div className="mb-3">
                  <Searchbar
                    placeholder="Dateisuche"
                    icon="search"
                    onChangeCallback={(e) => this.searchQuery(e)}
                    />
                </div>

                <div className="form-group">
                  <label className="radio-button__group-label">Sortierung</label>
                  <RadioButton
                    name="sort"
                    label="Name"
                    value="title"
                    selected={this.state.orderField === "title"}
                    handleChange={(e) => this.handleSortChange(e)} />
                  <RadioButton
                    name="sort"
                    label="Datum"
                    value="updated_at"
                    selected={this.state.orderField === "updated_at"}
                    handleChange={(e) => this.handleSortChange(e)} />
                  <RadioButton
                    name="sort"
                    label="Größe"
                    value="size"
                    handleChange={(e) => this.handleSortChange(e)} />
                </div>

                <div className="form-group">
                  <label className="checkbox__group-label">Filtern nach Dateityp</label>
                  <Checkbox label="Bilder" id="image" selected={this.handleFileTypeSelection('image')} onChange={(e) => this.handleFileTypeChange(e)} />
                  <Checkbox label="Word" id="word" selected={this.handleFileTypeSelection('word')} onChange={(e) => this.handleFileTypeChange(e)} />
                  <Checkbox label="PDF" id="pdf" selected={this.handleFileTypeSelection('pdf')} onChange={(e) => this.handleFileTypeChange(e)} />
                  <Checkbox label="Videos" id="mp4" selected={this.handleFileTypeSelection('mp4')} onChange={(e) => this.handleFileTypeChange(e)} />
                </div>

                <div className="form-group">
                  <label className="checkbox__group-label">Filtern nach Einsteller</label>

                  <RadioButton
                    name="filterByOwner"
                    label="Von mir"
                    value="self"
                    selected={this.state.filterByOwner === "self"}
                    handleChange={(e) => this.handleOwnerChange(e)} />
                  <RadioButton
                    name="filterByOwner"
                    label="Von meiner Kanzlei"
                    value="company"
                    selected={this.state.filterByOwner === "company"}
                    handleChange={(e) => this.handleOwnerChange(e)} />
                  <RadioButton
                    name="filterByOwner"
                    label="DIRO-Netzwerk"
                    value="diro"
                    selected={this.state.filterByOwner === "diro"}
                    handleChange={(e) => this.handleOwnerChange(e)} />

                </div>

                <SimpleSelectfield
                  label="Filtern nach Datum"
                  name="filter_date"
                  firstOptionValue=""
                  firstOptionLabel="Bitte auswählen"
                  indexLabel="id"
                  optionLabel="filterDate"
                  options={this.state.filterDateOptions}
                  onSelect={this.onSelectFilterDate}
                  selected=""
                />

              </form>

            </div>
            <div className="col">

              <DragAndDrop
                onDrop={this.onDrop}
              />

              <div className="row">
                {
                  this.state.assets.map((file, i) => {
                    return <div className="col-6 col-sm-4 col-xl-3" key={i}>
                      <MediaItem
                        file={file}
                        deleteItem={this.deleteItem}
                      />
                    </div>
                  })
                }
              </div>
            </div>
          </div>
          <Pagination
            total={this.state.pagesCount}
            current={this.state.currentPage}
            onChange={this.handlePaginationClick}
            onSelect={this.handleSelectLimit}
            limit={this.state.limit}
          />
        </div>

      </>
    )
  }
}

export default MediaList;
