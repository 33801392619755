import React from 'react'

class RangeScale extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      steps: []
    }
  }

  getSteps() {
    let step = parseInt(this.props.min)
    const max = parseInt(this.props.max)
    let steps = []
    while (step <= max) {
      steps.push(step)
      step++
    }
    return steps
  }

  componentDidMount() {
    const steps = this.getSteps()

    this.setState({
      steps: steps
    })
  }

  render() {
    // FIXME: Value anpassen, wenn man auf die Steps klickt
    return (
      <div className="dd-range__scale">
        {
          this.state.steps.map(function (step, i) {
          return <div className="dd-range__step" key={i}>{step}</div>
          })
        }
      </div>
    )
  }
}

export default RangeScale
