import React from "react";

const BlockPreviewTeaser = (props) => {

  const content = props.block[0] // FIXME

  return <div className="dd-block-preview dd-block-preview--teaser">
    <h3>{content.title}</h3>
    <div className={`dd-block-preview__teaser-items dd-block-preview__teaser-items--${content.grid}`}>
      {content.items && content.items.map((item, key) => {
        return (
          <div key={key} className={`dd-block-preview__teaser-item ${content.noBorder ? 'dd-block-preview__teaser-item--borderless' : ''}`}>
            <p>
              <strong>{item.title}</strong><br />
              {item.text}
            </p>
          </div>
        )}
      )}
    </div>
  </div>
}

export default BlockPreviewTeaser;
