import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { arrayMoveImmutable } from 'array-move'

import BlockSelector from './BlockSelector'

import { AppContext } from '../../../../data/ContextProvider'

import { blockTemplate } from './util'

class Blocks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isRequired: false,
      blocks: []
    }
  }

  deleteBlock = (key) => {
    const blocks = [...this.state.blocks]

    this.setState(
      {
        blocks: blocks.filter((b) => b.key !== key)
      },
      () => {
        this.sendChangeUpstream()
      }
    )
  }

  addBlock = (type, index, initData = []) => {
    const atIndex = index + 1
    const blocks = [...this.state.blocks]
    const preview = false

    if (blocks.length === atIndex) {
      blocks.push(
        blockTemplate(
          type,
          this.onChange,
          this.deleteBlock,
          preview,
          this.props.errorHandler,
          initData
        )
      )

      this.setState({ ...this.state, blocks }, () => {
        this.sendChangeUpstream()
      })
    } else {
      const tmp = [
        ...blocks.slice(0, atIndex),
        blockTemplate(
          type,
          this.onChange,
          this.deleteBlock,
          preview,
          this.props.errorHandler,
          initData
        ),
        ...blocks.slice(atIndex)
      ]

      this.setState({ blocks: tmp }, () => {
        this.sendChangeUpstream()
      })
    }
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    if (result.type !== 'BLOCKS') {
      return
    }

    const blocks = [...this.state.blocks]

    this.setState(
      {
        ...this.state,
        blocks: arrayMoveImmutable(
          blocks,
          result.source.index,
          result.destination.index
        )
      },
      () => {
        this.sendChangeUpstream()
      }
    )
  }

  onChange = (key, value) => {
    const blocks = [...this.state.blocks]
    const i = blocks.findIndex((block) => block.key === key)

    if (i < blocks.length) {
      blocks[i].content = value

      this.setState({ ...this.state, blocks }, () => {
        this.sendChangeUpstream()
      })
    }
  }

  sendChangeUpstream = () => {
    const blocks = [...this.state.blocks]
    const content = blocks.map((block) => block.content)

    try {
      this.props.handleChange(JSON.stringify(content))
    } catch (error) {
      console.log('send send', content)
    }
  }

  componentDidMount() {
    const preview = true
    const blocks = [...this.state.blocks]

    if (this.props.blocks && this.props.blocks.length > 0) {
      this.props.blocks.forEach((block) => {
        try {
          if (block && block.length > 0) {
            blocks.push(
              blockTemplate(
                block[0].type,
                this.onChange,
                this.deleteBlock,
                preview,
                this.props.errorHandler,
                block
              )
            )
          }
        } catch (error) {
          console.log(error.message)
        }
      })

      this.setState({
        ...this.state,
        blocks
      })
    } else {
      this.setState({
        ...this.state,
        blocks: [
          blockTemplate(
            'default',
            this.onChange,
            this.deleteBlock,
            preview,
            this.props.errorHandler
          )
        ]
      })
    }
  }

  render() {
    return this.state.blocks.length ? (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" type="BLOCKS">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {this.state.blocks.length &&
                this.state.blocks.map((block, index) => (
                  <Draggable
                    key={block.key}
                    draggableId={block.key}
                    index={index}
                    isDragDisabled={this.context.blocksDragDisabled}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div key={index}>
                          {block.component}
                          <BlockSelector
                            index={index}
                            addBlockCallback={(type, atIndex, initData = []) =>
                              this.addBlock(type, atIndex, initData)
                            }
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    ) : (
      <BlockSelector
        index="0"
        addBlockCallback={(type, atIndex, initData = []) =>
          this.addBlock(type, atIndex, initData)
        }
      />
    )
  }
}

Blocks.contextType = AppContext
export default Blocks
