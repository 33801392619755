export const API_HOST = process.env.REACT_APP_API_HOST

export const BASE_URL_DIRO_DEV = process.env.REACT_APP_BASE_URL_DIRO_DEV

export const DACH_COUNTRIES = [5, 16, 21]

export const EXPORT_TABLEHEADERS = [
  'id_company',
  'id_site',
  'is_subsidiary',
  'city',
  'company_name',
  'country',
  'federalstate',
  'street',
  'zip',
  'phone_office',
  'fax_office',
  'email_office',
  'website',
  'signedcontracts',
  'cooperationnational',
  'cooperationinternational',
  'lawfields',
  'advocacies',
  'jobtitle',
  'language',
  'id_person',
  'title',
  'name',
  'phone_personal',
  'mobile',
  'email_personal',
  'bt_count',
]

export const SITE_TYPES = [
    'Kanzlei',
    'Portal',
    'Intranet'
]
