import React from "react";

import Textfield from "../Shared/atom/Textfield/Textfield";
import Checkbox from "../Shared/atom/Checkbox/Checkbox";
import SimpleSelectfield from "../Shared/atom/Selectfield/SimpleSelectfield";
import TextfieldGroup from "../Shared/atom/TextfieldGroup/TextfieldGroup";
import RadioButton from "../Shared/atom/RadioButton/RadioButton";
import Icon from "../Shared/atom/Icon/Icon";
import TagList from "../Shared/atom/TagList/TagList";

import ImageSelector from "../Shared/module/ImageSelector/ImageSelector";
import Overlay from "../Shared/element/Overlay/Overlay";
import { ImagePreviewAvatar } from "../Shared/element/ImagePreview/ImagePreview";

import DndList from "../Shared/module/Dnd-list/Dnd-list";
import Tablist from "../Shared/module/Tablist/Tablist";

import PersonVitaPublication from "./PersonVitaPublication";

import { imagePathForFormat } from "../../lib/lib";

class PersonCoreData extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isUpdating: false,
      imageOverlayActive: false,
      contactDataTab: 0,
      avatar_path: "",
      secretary_mode: "-1",
      salutations: [
        { id: '', salutation: 'Bitte wählen' },
        { id: 'Frau', salutation: 'Frau' },
        { id: 'Herr', salutation: 'Herr' },
        { id: 'Divers', salutation: 'Divers' }
      ],
      formErrors: { shortTitle: '', mediumTitle: '', longTitle: '' },
      formValid: true,
      currentOpenTab: 0,
      vitas:{
        headlines: [
          "Zeit oder Zeitraum",
          "Tätigkeit",
          "Erläuterung (optional)",
        ],
        fieldNames: [
          "timeframe",
          "activity",
          "description",
        ]
      },
      usermemberships:{
        headlines: [
          "Verein, Verband etc.",
          "Funktion",
          "Erläuterung (optional)",
        ],
        fieldNames: [
          "timeframe",
          "activity",
          "description",
        ]
      },
      mandates:{
        headlines: [
          "Jahr",
          "Referenzmandat",
          "Erläuterung (optional)",
        ],
        fieldNames: [
          "timeframe",
          "activity",
          "description",
        ]
      },
      lectures:{
        headlines: [
          "Erläuterung",
        ],
        fieldNames: [
          "description",
        ]
      },
      publications:{
        headlines: [
          "Autor/en/Hrsg.",
          "Titel des Beitrages oder der Publikation",
          "Erscheinungsjahr, Verlag",
        ],
        fieldNames: [
          "author",
          "title",
          "details",
        ]
      },
    }
  }

  openContactDataTab = (key) => {
    this.setState({
      ...this.state,
      contactDataTab: key
    })
  }

  openTab = (key) => {
    this.setState({
      ...this.state,
      currentOpenTab: key
    })
  }

  openImageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      imageOverlayActive: true
    })
  }

  closeImageSelectorOverlay = () => {
    this.setState({
      imageOverlayActive: false,
    })
  }

  handleImageSelect = (file, format) => {
    const path = file[imagePathForFormat(format)]
    this.setState({
      avatar_path: path,
      imageOverlayActive: false,
      showConfirmAvatarDelete: false,
    }, () => {
      this.props.onAvatarUpdate(file.id, format)
    })
  }

  onAvatarDelete = (file, format) => {
    this.setState({
      avatar_path: "",
      showConfirmAvatarDelete: false,
    }, () => {
        this.props.onAvatarUpdate(0, "")
    })
  }


  secretaryTitleChange = (e, index) => {
    const secretaries = [...this.props.secretaries]
    secretaries[index].title = e.target.value
    this.props.updateSecretary(secretaries)
  }

  onSelectSecretary = (index, selectedID) => {
    const secretaries =  [...this.props.secretaries]
    secretaries[index].user_id = selectedID
    this.props.updateSecretary(secretaries)
  }

  addSecretaryForm = () => {
    const secretaries = [...this.props.secretaries]
    secretaries.push({
      principal_id:  this.props.person.id,
      user_id: 0,
      lang: this.props.lang,
      title: "",
    })
    this.props.updateSecretary(secretaries)
  }

  deleteSecretary = (index) => {
    const secretaries = [...this.props.secretaries]
    if (index > -1 && index < secretaries.length) {
      secretaries.splice(index, 1);
      this.props.updateSecretary(secretaries)
    }
  }

  onChangeSecretaryMode = (value) => {
    let { secretary_mode } = this.state
    secretary_mode = value
    this.setState({ ...this.state, secretary_mode })
  }

  handleSelectedState = val => {
    return (val === 1 || val=== true )? true : false
  }

  handleCheckboxChange = (field, e) => {
    this.props.changeValue(field, !this.props.person[field])
  }

  validateField = (fieldName, value) => {
    let fieldValidationErrors = this.state.formErrors;
    // let shortTitleValid = this.state.shortTitleValid;

    switch (fieldName) {
      case 'shortTitle':
        fieldValidationErrors.shortTitle = 'Der Title darf nicht leer sein.'
        break;
      default:
        break;
    }
  }

  siteCityLabel = (site) => {
    return site.city.title_de
  }

  renderSecretaryForm = () => {
    const out = []

    this.props.secretaries.forEach((secretary, index) => {
      let key = index + "-" + secretary.id + "-" + secretary.user_id
      out.push(<div className="dd-person__item dd-person__card" key={key}>
        <Textfield
          name="secretary_name"
          label="Bezeichnung"
          type="text"
          language={secretary.lang}
          defaultValue={secretary.title}
          handleChange={(e)=>this.secretaryTitleChange(e, index)}
        />
        <SimpleSelectfield
          name="secretaryPerson"
          firstOptionValue=""
          firstOptionLabel="Bitte Person auswählen"
          indexLabel="id"
          optionLabel="employee"
          options={this.props.employeesSelectOptions}
          onSelect={(value)=>this.onSelectSecretary(index, value)}
          selected={secretary.user_id}
        />
        <button className="dd-btn dd-btn--danger dd-btn--icon-only" onClick={() => this.deleteSecretary(index)}>
          <Icon icon="trash" />
        </button>
      </div>)
    })
    return out
  }

  render() {
    const { person } = this.props
    const showSecretaryForm = (this.props.secretaries && this.props.secretaries.length !== 0)

    let secretary_mode = this.state.secretary_mode

    if (this.props.secretaries && this.props.secretaries.length !== 0) {
      // intiale Ansicht
      if (this.state.secretary_mode === "-1") {
        secretary_mode = "0"
      }
    }

    if (secretary_mode === "-1") {
      secretary_mode = "1"
    }

    const company_sites = [...this.props.company.company_sites]

    let avatar_path = ""
    if (person.avatar_id === 0) {
      avatar_path = ""
    } else if (this.state.avatar_path !== "") {
      avatar_path = this.state.avatar_path
    } else {
      avatar_path = person.avatar_path
    }

    const tabs = [
      { title: "Karriere", path: `/${this.props.sysLang}/offices/${this.props.officeid}/team/${this.props.userid}` },
      {
        title: "Mitgliedschaften/Ehrenämter",
        path: `/${this.props.sysLang}/offices/${this.props.officeid}/team/${this.props.userid}/memberships`
      },
      {
        title: "Referenzmandate",
        path: `/${this.props.sysLang}/offices/${this.props.officeid}/team/${this.props.userid}/references`
      },
      {
        title: "Publikationen",
        path: `/${this.props.sysLang}/offices/${this.props.officeid}/team/${this.props.userid}/publications`
      },
      {
        title: "Lehr-/Vortragstätigkeit",
        path: `/${this.props.sysLang}/offices/${this.props.officeid}/team/${this.props.userid}/lectures`
      }
    ]

    return (

      <React.Fragment >
        <div className="dd-form-section dd-form-section--first">
          <div className="row">

            <div className="col-12 col-md-8 col-lg-4 mb-2">
              <TextfieldGroup>

                <SimpleSelectfield
                  label="Anrede"
                  name="salutation"
                  firstOptionValue=""
                  firstOptionLabel="Bitte auswählen"
                  indexLabel="id"
                  optionLabel="salutation"
                  options={this.state.salutations}
                  onSelect={this.props.onSelectSalutation}
                  selected={person.salutation}
                />

                <SimpleSelectfield
                  label="Titel"
                  name="titel"
                  firstOptionValue="0"
                  firstOptionLabel="Bitte auswählen"
                  indexLabel="id"
                  optionLabel="title_de"
                  options={this.props.academictitles}
                  onSelect={this.props.onSelectAcademictitle}
                  selected={person.academictitle_id}
                />

              </TextfieldGroup>

              <Textfield
                name="firstname"
                type="text"
                label="Vorname"
                isRequired={true}
                defaultValue={person.firstname}
                handleChange={this.props.onChange}
                errorHandler={this.props.errorHandler}
              />

              <Textfield
                name="lastname"
                type="text"
                label="Nachname"
                isRequired={true}
                defaultValue={person.lastname}
                handleChange={this.props.onChange}
                errorHandler={this.props.errorHandler}
              />

              <TagList
                  name="standorte"
                  labelCallback={this.siteCityLabel}
                  label="Berufsträger ist tätig in ..."
                  hasMargin={true}
                  data={person.company_sites}
                  availableData={company_sites}
                  onUpdate={(assignedTags) => this.props.sitesUpdate(assignedTags)}
              />

            </div>

            <div className="col-12 col-md-4 col-lg-4 mb-2">

              <div className="dd-avatar dd-avatar--office">
                {(!avatar_path) &&
                  <div>
                    <button
                      className="dd-btn dd-btn--primary dd-image-picker__choose"
                      onClick={(e) => this.openImageSelectorOverlay(e)}>
                      Bild auswählen
                    </button>
                    <p className="dd-avatar__hint">
                      <strong>Upload des Portraits</strong><br />
                      Empfohlenes Seitenverhältnis: 1:1. Zulässige Dateiformate: jpg, png. Mindestens 400px Breite.
                    </p>
                  </div>
                }

                {(avatar_path) &&
                  <ImagePreviewAvatar
                    avatar_path={avatar_path}
                    onAvatarDelete={this.onAvatarDelete}
                    openImageSelectorOverlay={(e) => this.openImageSelectorOverlay(e)}
                    message="Der Mitarbeiter wird dann auf allen Einträgen ohne Profilbild angezeigt."
                    image_profile={true}
                  />
                }
              </div>
              {this.state.imageOverlayActive &&
                <Overlay closeOverlayCallback={() => this.closeImageSelectorOverlay()}>
                  <ImageSelector
                    selectImageCallback={(file, format) => this.handleImageSelect(file, format)}
                    selectedAssetId={this.props.person.avatar_id || ''}
                    selectedFormat={this.props.person.avatar_format || ''}
                    fileTypes={["image"]}
                    allowedFormats={[
                      "original",
                      "one_by_one",
                    ]}
                  />
                </Overlay>
              }
            </div>

            <div className="col-12 col-md-8 col-lg-4 mb-2">

              <div className="dd-tabs">
                <div className={(this.state.contactDataTab === 0) ? 'dd-tabs__item active' : 'dd-tabs__item'} onClick={() => this.openContactDataTab(0)}>Allgemein</div>
                <div className={(this.state.contactDataTab === 1) ? 'dd-tabs__item active' : 'dd-tabs__item'} onClick={() => this.openContactDataTab(1)}>Social Media</div>
                <div className={(this.state.contactDataTab === 2) ? 'dd-tabs__item active' : 'dd-tabs__item'} onClick={() => this.openContactDataTab(2)}>Sekretariat</div>
              </div>

              {(this.state.contactDataTab === 0 ) &&
              <div className="dd-person__personal">
                <div className="dd-person__personal__item">
                  <Textfield
                    name="email"
                    label="Persönliche E-Mail"
                    type="email"
                    defaultValue={person.email}
                    handleChange={this.props.onChange}
                  />
                  <Checkbox
                    label="öffentlich"
                    id="email_public"
                    selected={this.handleSelectedState(person.email_public)}
                    onChange={(e) => this.handleCheckboxChange("email_public", e)}
                  />
                </div>

                <div className="dd-person__personal__item">
                  <Textfield
                    name="phone"
                    label="Persönliche Telefonnummer"
                    placeholder="+49 1234 56789 - ohne Klammern und Trennzeichen"
                    type="text"
                    defaultValue={person.phone}
                    handleChange={this.props.onChange}
                  />
                  <Checkbox
                    label="öffentlich"
                    id="phone_public"
                    selected={this.handleSelectedState(person.phone_public)}
                    onChange={(e) => this.handleCheckboxChange("phone_public", e)}
                  />
                </div>

                <div className="dd-person__personal__item">
                  <Textfield
                    name="mobile"
                    label="Persönliche Mobilnummer"
                    placeholder="+49 1234 56789 - ohne Klammern und Trennzeichen"
                    type="text"
                    defaultValue={person.mobile}
                    handleChange={this.props.onChange}
                  />
                  <Checkbox
                    label="öffentlich"
                    id="mobile_public"
                    selected={this.handleSelectedState(person.mobile_public)}
                    onChange={(e) => this.handleCheckboxChange("mobile_public", e)}
                  />
                </div>
                <div className="dd-person__personal__item">
                  <Textfield
                    name="fax"
                    label="Persönliche Faxnummer"
                    placeholder="+49 1234 56789 - ohne Klammern und Trennzeichen"
                    type="text"
                    defaultValue={person.fax}
                    handleChange={this.props.onChange}
                  />
                  <Checkbox
                    label="öffentlich"
                    id="fax_public"
                    selected={this.handleSelectedState(person.fax_public)}
                    onChange={(e) => this.handleCheckboxChange("fax_public", e)}
                  />
                </div>
              </div>
              }

            {(this.state.contactDataTab === 1) &&
              <div className="dd-person__socialmedia">
                <div className="dd-person__personal__item">
                  <Textfield
                    name="facebook_profile"
                    label="Facebook-Profil"
                    type="text"
                    defaultValue={person.facebook_profile}
                    handleChange={this.props.onChange}
                  />
                  <Checkbox
                    label="öffentlich"
                    id="facebook_profile_public"
                    selected={this.handleSelectedState(person.facebook_profile_public)}
                    onChange={(e) => this.handleCheckboxChange("facebook_profile_public", e)}
                  />
                </div>

                <div className="dd-person__personal__item">
                  <Textfield
                    name="twitter_profile"
                    label="Twitter-Profil"
                    type="text"
                    defaultValue={person.twitter_profile}
                    handleChange={this.props.onChange}
                  />
                  <Checkbox
                    label="öffentlich"
                    id="twitter_profile_public"
                    selected={this.handleSelectedState(person.twitter_profile_public)}
                    onChange={(e) => this.handleCheckboxChange("twitter_profile_public", e)}
                  />
                </div>

                <div className="dd-person__personal__item">
                  <Textfield
                    name="xing_profile"
                    label="Profil bei XING"
                    type="text"
                    defaultValue={person.xing_profile}
                    handleChange={this.props.onChange}
                  />
                  <Checkbox
                    label="öffentlich"
                    id="xing_profile_public"
                    selected={this.handleSelectedState(person.xing_profile_public)}
                    onChange={(e) => this.handleCheckboxChange("xing_profile_public", e)}
                  />
                </div>

                <div className="dd-person__personal__item">
                  <Textfield
                    name="linkedin_profile"
                    label="Profil bei LinkedIn"
                    type="text"
                    defaultValue={person.linkedin_profile}
                    handleChange={this.props.onChange}
                  />
                  <Checkbox
                    label="öffentlich"
                    id="linkedin_profile_public"
                    selected={this.handleSelectedState(person.linkedin_profile_public)}
                    onChange={(e) => this.handleCheckboxChange("linkedin_profile_public", e)}
                  />
                </div>
              </div>
            }
            {(this.state.contactDataTab === 2) &&
              <div className="dd-person__secretary">
                <div className="dd-person__item form-group">
                  <label className="textfield__label">
                    Angaben zu Assistenz, Sekretariat etc.
                  </label>
                  <div className="row">
                    <div className="col-6 col-md-8 col-lg-4">
                      <RadioButton
                        name="secretary_mode"
                        label="Allgemein"
                        value="1"
                        handleChange={(e) => this.onChangeSecretaryMode("1")}
                        selected={(secretary_mode === "1")}
                      />
                      </div>
                    <div className="col-6 col-md-8 col-lg-4">
                      <RadioButton
                        name="secretary_mode"
                        label="Individuell"
                        value="0"
                        handleChange={(e) => this.onChangeSecretaryMode("0")}
                        selected={(secretary_mode==="0")}
                      />
                    </div>
                  </div>
                </div>

              {(secretary_mode === "1") &&
                <div>
                  <div className="dd-person__item">
                    <Textfield
                      name="office_email"
                      label="E-Mail Sekretariat"
                      type="email"
                      defaultValue={person.office_email}
                      handleChange={this.props.onChange}
                    />
                  </div>

                  <div className="dd-person__item">
                    <Textfield
                      name="office_phone"
                      label="Telefon Sekretariat"
                      placeholder="+49 1234 56789 - ohne Klammern und Trennzeichen"
                      type="text"
                      defaultValue={person.office_phone}
                      handleChange={this.props.onChange}
                    />
                  </div>
                  <div className="dd-person__item">
                    <Textfield
                      name="office_fax"
                      label="Fax Sekretariat"
                      placeholder="+49 1234 56789 - ohne Klammern und Trennzeichen"
                      type="text"
                      defaultValue={person.office_fax}
                      handleChange={this.props.onChange}
                    />
                  </div>
                </div>
              }
              {(secretary_mode === "0") &&
                <div>

                {!showSecretaryForm &&
                  <div className="dd-person__item">
                    <p>Bitte beachten Sie, dass mit der Einrichtung individueller Ansprechpartner die allgemeinen Angaben <strong>nicht mehr</strong> angezeigt werden.</p>
                    <div className="text-center">
                      <button className="dd-btn dd-btn--primary" onClick={this.addSecretaryForm}>
                        Ansprechpartner hinzufügen
                      </button>
                    </div>
                  </div>
                }
                { showSecretaryForm &&
                  <div>

                    {this.renderSecretaryForm()}

                    <div className="dd-person__item text-center">
                      <button className="dd-btn dd-btn--primary dd-btn--icon-only" onClick={this.addSecretaryForm}>
                        <Icon icon="plus" />
                      </button>
                    </div>
                  </div>
                }
                </div>
              }
              </div>
            }

            </div>
          </div>
        </div>

        <div>
          {person.id &&
            <div className={"d-flex justify-content-center pt-3 person__vita-tab-header"}>
              <Tablist tabs={tabs} active={this.state.currentOpenTab} tabChange={this.openTab} />
            </div>
          }
        </div>

        <div className="dd-form-section dd-form-section--last">

          <div key={this.props.lang} className="tab-content" id="cv-nav-tabContent">
            <div className="row">
              {(this.state.currentOpenTab === 0 && this.props.vitas) &&
                <DndList
                  rows={this.props.vitas}
                  lang={this.props.lang}
                  headlines={this.state.vitas.headlines}
                  fieldNames={this.state.vitas.fieldNames}
                  onChange={this.props.onVitasUpdate}
                />
              }
              {(this.state.currentOpenTab === 1) &&
                <DndList
                  rows={this.props.usermemberships}
                  lang={this.props.lang}
                  headlines={this.state.usermemberships.headlines}
                  fieldNames={this.state.usermemberships.fieldNames}
                  onChange={this.props.onUsermembershipsUpdate}
                />
              }
              {(this.state.currentOpenTab === 2) &&
                <DndList
                  rows={this.props.mandates}
                  lang={this.props.lang}
                  headlines={this.state.mandates.headlines}
                  fieldNames={this.state.mandates.fieldNames}
                  onChange={this.props.onMandatesUpdate}
                />
              }

              {(this.state.currentOpenTab === 3) &&
                <PersonVitaPublication
                  person={this.props.person}
                  lang={this.props.lang}
                  publications={this.props.publications}
                  headlines={this.state.publications.headlines}
                  fieldNames={this.state.publications.fieldNames}
                  onPublicationsUpdate={this.props.onPublicationsUpdate}
                  changeValue={this.props.changeValue}
                />
              }
              {(this.state.currentOpenTab === 4) &&
                <DndList
                  rows={this.props.lectures}
                  lang={this.props.lang}
                  headlines={this.state.lectures.headlines}
                  fieldNames={this.state.lectures.fieldNames}
                  onChange={this.props.onLecturesUpdate}
                />
              }
            </div>
          </div>
        </div>

        <div className="py-4 px-2">

          {person.hidden
            ?
            <span className="dd-btn__wrapper d-inline-flex float-right mr-2 top__neg-space" onClick={(e) => this.props.showConfirmationOverlay(person, "visibilty")}>
              <button className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only" >
                <Icon icon="hidden" />
              </button>
              <span className="dd-btn__text">Einblenden</span>
            </span>
            :
            <span className="dd-btn__wrapper d-inline-flex float-right mr-2 top__neg-space" onClick={(e) => this.props.showConfirmationOverlay(person, "visibilty")}>
              <button className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only" >
                <Icon icon="visibilty" />
              </button>
              <span className="dd-btn__text">Ausblenden</span>
            </span>
          }


          { /*<span className="dd-btn__wrapper d-inline-flex float-right mr-2">
            <button className="dd-btn dd-btn--danger dd-btn--icon-only" >
              <Icon icon="trash" />
            </button>
            <span className="dd-btn__text">Löschen</span>
      </span>*/}

          {person.hidden
            ?
            <span className="text-danger">Diese Person ist zurzeit ausgeblendet. Änderungen werden gespeichert, aber nicht veröffentlicht.</span>
            :
            <span></span>
          }

        </div>

      </React.Fragment>
    );
  }
}


export default PersonCoreData;
