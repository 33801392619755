import React from "react";

import Icon from "../../atom/Icon/Icon";
import DiroCompanyList from './BlockPreviews/DiroCompanyList';
import BlockSectionSettings from './BlockSectionSettings';
import BlockActions from "./BlockActions";

class BlockDiroCompanyListEidt extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content:{},
      preview: false,
      showSettings: false,
      itemsCountPerPage: 10,
      pageSizes: [
        { id: 5, count: 5 },
        { id: 10, count: 10 },
        { id: 15, count: 15 },
        { id: 20, count: 20 },
      ],
    }
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  componentDidMount() {
    if (this.props.initData) {
      const preview = this.props.preview
      const content = this.props.initData[0]

      this.setState({ content, preview })
    }
  }

  render() {
    return (
        <>
        {this.state.preview
          ? <div className="dd-block" onClick={() => this.togglePreviewEditMode()}>
              <div className="dd-block__preview">
              <DiroCompanyList block={this.props.initData} />
              </div>
            </div>
          :
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-teaserlist" /> DIRO-Kanzleien
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }

              <div className="dd-block__edit-section">
                <div className="form-group">
                  <div className="row">
                  </div>
                </div>

              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
          }
        </>
    )
  }
}

export default BlockDiroCompanyListEidt
