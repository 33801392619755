import React from "react";

import Loader from "../Shared/atom/Loader/Loader";
import RadioButton from "../Shared/atom/RadioButton/RadioButton";
import Editbar from "../Shared/element/Editbar/Editbar";
import Icon from "../Shared/atom/Icon/Icon";
import DragAndDrop from "../Shared/element/DragAndDrop/DragAndDrop";

import { getSetting, updateSetting, updateFaviconImage, deleteFaviconImage } from "../../data/api";
import { getToken } from "../../lib/lib";
import { AppContext } from "../../data/ContextProvider";

class Design extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      showConfirmDelete: false,
    }
  }

  toggleConfirmation = () => {
    this.setState({
      showConfirmDelete: !this.state.showConfirmDelete
    })
  }

  onAvatarChange = (e, status) => {
    const settings = this.state.settings
    settings.design.round_avatar = status
    this.setState({ settings })
  }

  onDrop = (files) => {
    if (files && files.length && files.length > 0) {
      this.onFaviconChange(files[0])
    }
  }

  onFaviconChange = (file) => {
    if (file) {
      updateFaviconImage(getToken(), this.state.settings.id, file)
        .then(response => {
          if (response.data && response.data.favicon_path) {
            const settings = { ...this.state.settings }
            settings.favicon_path = response.data.favicon_path
            this.setState({ isUpdating: false, settings: settings, showConfirmDelete: false, })
          }
        })
        .catch(err => {
          console.log("error onDrop", err)
        })
    }
  }

  onFaviconImageDelete = () => {
    deleteFaviconImage(getToken(), this.state.settings.id)
    const settings = { ...this.state.settings }
    settings.favicon_path = ""
    this.setState({ settings, showConfirmDelete: false, isUpdating: false })
  }

  onSubmit = async () => {
    this.setState({ isUpdating: true })


    updateSetting(getToken(), this.state.settings.id, this.state.settings)
      .then(async syslanguage => {
        await this.loadData()
      })
      .catch(err => {
        this.setState({ isUpdating: false })
      })

    await this.loadData()
  }

  loadData = async () => {
    const settings = await getSetting(getToken())

    this.setState({
      settings: settings.settings,
      loading: false,
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  render() {
    const admin = (this.context.user.admin || this.context.user.diro_admin)

    if (this.state.loading || !admin ) {
      return <Loader />
    }

    return (
      <>
        <div className="dd-card">
          <div className="dd-form-section dd-form-section--small dd-form-section--last">

            <div className="form-group">
              <div className="form-check form-check-inline">

                <label className="textfield__label">
                  Porträts der Teamdarstellung&nbsp;&nbsp;&nbsp;
                </label>

                <RadioButton
                  name="round_avatar"
                  label="rund"
                  value={true}
                  selected={this.state.settings.design.round_avatar === true}
                  handleChange={(e) => this.onAvatarChange(e, true)} />
                <RadioButton
                  name="round_avatar"
                  label="quadratisch"
                  value={false}
                  selected={this.state.settings.design.round_avatar === false}
                  handleChange={(e) => this.onAvatarChange(e, false)} />
              </div>
            </div>

            <div className="form-group">
              <label className="textfield__label">
                Favicon
              </label>

              {(!this.state.settings.favicon_path) &&
                <>
                  <div className="dd-avatar__upload">
                    <DragAndDrop onDrop={this.onDrop} type="small" />
                  </div>
                  <p className="dd-avatar__hint">
                    <strong>Upload des Favicons</strong><br />
                      Empfohlenes Seitenverhältnis: 1:1. Zulässige Dateiformate: jpg, png. Mindestens 70px Breite 70px Höhe. Das beste Ergebnis erzielt man mit ab einer 260x260 Bildgrösse.
                    </p>
                </>
              }
              {(this.state.settings.favicon_path) &&
                <div>
                  <div>
                  <img src={this.state.settings.favicon_path} alt="Teaserbild" width="60px" height="60px"/>
                    {(this.state.showConfirmDelete) &&
                      <div className="dd-avatar__confirm">
                        <p>
                          <strong>Wollen Sie das Bild wirklich löschen?</strong>
                        </p>
                        <button className="dd-btn dd-btn--primary" onClick={this.toggleConfirmation}>Abbrechen</button>
                        <button className="dd-btn dd-btn--primary dd-btn--outline" onClick={this.onFaviconImageDelete}>Löschen</button>
                      </div>
                    }
                  </div>
                  <div className="dd-avatar__actions dd-avatar__actions--meta">
                    <button className="dd-btn dd-btn--icon-only dd-btn--danger" onClick={this.toggleConfirmation}>
                      <Icon icon={"trash"} />
                    </button>
                  </div>
                </div>
              }

            </div>

          </div>
        </div>

        <Editbar
          hasPublish={false}
          hasPreview={false}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
        />
      </>
    )
  }
}

Design.contextType = AppContext
export default Design;
