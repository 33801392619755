import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";
import RadioButton from "../../atom/RadioButton/RadioButton";
import Overlay from "../../element/Overlay/Overlay";
import { ImagePreviewBlock, VideoPreviewBlock } from "../../element/ImagePreview/ImagePreview";
import ImageSelector from "../../module/ImageSelector/ImageSelector";
import Checkbox from "../../atom/Checkbox/Checkbox";
import DnDRows from "../../../Shared/module/DnDRows/DnDRows";

import BlockPreviewHero from './BlockPreviews/Hero';
import BlockSectionSettings from './BlockSectionSettings';
import BlockHeroSliderItemEdit from './BlockHeroSliderItemEdit';

import { createID } from "../../../../lib/lib";
import BlockActions from "./BlockActions";

class BlockHeroEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content:{},
      mediaOverlayActive: false,
      preview: true,
      showSettings: false,
    }
  }

  get selectedAssetId() {
    let selectedAssetId = '';
    if (this.state.content.mediaType === 'image') {
      selectedAssetId =  this.state.content.imageID;
    } else if (this.state.content.mediaType === 'mp4') {
      selectedAssetId =  this.state.content.videoID;
    }
    return selectedAssetId;
  }

  get selectedAssetFormat() {
    let selectedAssetFormat = '';
    if (this.state.content.mediaType === 'image') {
      selectedAssetFormat = this.state.content.imageFormat;
    } else if (this.state.content.mediaType === 'mp4') {
      selectedAssetFormat = this.state.content.videoFormat;
    }
    return selectedAssetFormat;
  }

  onChangeType = (event) => {
    const content = this.state.content;
    content[event.target.name] = event.target.value;
    this.setState({ content: content });
    this.saveContent(content);
  };

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  openMediaSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      mediaOverlayActive: true
    })
  }

  closeMediaSelectorOverlay = () => {
    this.setState({
      mediaOverlayActive: false,
    })
  }

  handleImageDelete = () => {
    const content = this.state.content
    content.imageID = 0
    content.imageFormat = ""
    content.imagePath = ""
    this.saveContent(content)
  }

  handleVideoDelete = () => {
    const content = this.state.content
    content.videoID = 0
    content.videoFormat = ""
    content.videoPath = ""
    this.saveContent(content)
  }

  handleMediaSelect = (file, format) => {
    const content = this.state.content

    if (file.content_type === 'image') {
      content.imagePath = file.file_path
      content.imageID   = file.id
      content.imageFormat  = format
    } else if (file.content_type === 'mp4') {
      content.videoPath = file.file_path
      content.videoID   = file.id
      content.videoFormat  = format
    }

    this.saveContent(content)
    this.closeMediaSelectorOverlay()
  }

  addRow = (e) => {
    e.preventDefault()
    const content = this.state.content
    if (!content.items) {
      content.items = []
    }
    content.items.push({
      'text': '',
      'title': '',
      'hasButton': false,
      'buttonTitle': '',
      'pageID': 0,
      'key': createID(),
    })
    this.saveContent(content)
  }

  onRowChange = (row, index) => {
    const content = this.state.content
    if (index < content.items.length) {
      content.items[index] = row
      this.saveContent(content)
    }
  }

  onRowsChange = (rows) => {
    const content = this.state.content
    content.items = rows
    this.saveContent(content)
  }

  onChange = (event) => {
    const content = this.state.content
    content[event.target.name] = event.target.value
    this.saveContent(content)
  }

  isSliderChange = () => {
    const content = this.state.content
    content.isSlider = !content.isSlider

    if (content.isSlider) {
      if (!content.items || content.items.length === 0) {
        content.items = []
        content.items.push({
          'imageFormat': content.imageFormat,
          'imageID': content.imageID,
          'imagePath': content.imagePath,
          'kicker': content.kicker,
          'title': content.title,
          'key': createID(),
        })
      }
    } else {
      if (content.items && content.items.length > 0) {
        content.imageFormat = content.items[0].imageFormat
        content.imageID     = content.items[0].imageID
        content.imagePath   = content.items[0].imagePath
        // content.kicker      = content.items[0].kicker
        // content.title       = content.items[0].title
      }
    }

    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  componentDidMount() {
    if (this.props.initData) {
      const preview = this.props.preview
      const content = this.props.initData[0]

      this.setState({ content, preview })
    }
  }


  renderPreviews = () => {
    const previews = []
    if (this.state.content.items) {
      this.state.content.items.map((item, index) => {
        return previews.push(
          <BlockHeroSliderItemEdit
            item={item}
            key={item.key}
            onChange={(val) => this.onRowChange(val, index)}
          />
        )
      })
    }
    return previews
  }

  render() {
    const mediaType = this.state.content.mediaType || 'image';

    return (
        <>
        {this.state.preview
          ? <div className="dd-block" onClick={() => this.togglePreviewEditMode()}>
            <div className="dd-block__preview">
              <BlockPreviewHero block={this.props.initData} />
            </div>
          </div>
          :
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-hero" /> Hero
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }

              <div className="dd-block__edit-section">

                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <label className="radio-button__group-label text-nowrap ">Hintergrundfarbe auswählen</label>
                    <RadioButton
                      name="background"
                      label="kein"
                      value=""
                      handleChange={this.onChange}
                      selected={this.state.content.background === ""}
                    />
                    <RadioButton
                      name="background"
                      label="Primärfarbe"
                      value="primary"
                      handleChange={this.onChange}
                      selected={this.state.content.background === "primary"}
                    />
                    <RadioButton
                      name="background"
                      label="Sekundärfarbe"
                      value="secondary"
                      handleChange={this.onChange}
                      selected={this.state.content.background === "secondary"}
                    />
                  </div>
                </div>

                {this.state.content.size === "large" &&
                  <div className="form-group">
                    <div className="form-check form-check-inline">
                      <Checkbox
                        label=""
                        id="is_slider"
                        selected={this.state.content.isSlider}
                        onChange={(e) => this.isSliderChange(e)}
                      />
                      <label className="radio-button__group-label text-nowrap ">Mehrere Bilder als Karussell anzeigen</label>
                    </div>
                  </div>
                }


                {!this.state.content.isSlider &&
                  <div className="form-group">
                    <div className="form-check form-check-inline">
                    {this.state.content.mediaType !== 'mp4' ?
                          <>
                            <label className="radio-button__group-label text-nowrap ">Größe auswählen</label>
                            <RadioButton
                              name="size"
                              label="klein"
                              value="small"
                              handleChange={this.onChange}
                              selected={this.state.content.size === "small"}
                            />
                            <RadioButton
                              name="size"
                              label="groß"
                              value="large"
                              handleChange={this.onChange}
                              selected={this.state.content.size === "large"}
                            />
                          </> :
                          <label className="radio-button__group-label text-nowrap ">Größe bei Videos immer 5:2</label>
                      }
                    </div>
                  </div>
                }

                {!this.state.content.isSlider &&
                  <div>
                    <div className="form-group">
                      <Textfield
                        name="kicker"
                        type="text"
                        isRequired={false}
                        label=""
                        placeholder="Dachzeile"
                        handleChange={this.onChange}
                        defaultValue={this.state.content.kicker}
                      />
                    </div>
                    <div className="form-group">
                      <Textfield
                        name="title"
                        type="text"
                        isRequired={false}
                        label=""
                        placeholder="Hier die Headline ergänzen"
                        handleChange={this.onChange}
                        defaultValue={this.state.content.title}
                        errorHandler={this.props.errorHandler}
                      />
                    </div>
                  </div>
                }

              </div>

              <div className="dd-block__edit-section">
                {!this.state.content.isSlider &&
                  <div className="form-group">
                    <div className="form-check form-check-inline">
                      <label className="radio-button__group-label text-nowrap ">
                        Medientyp auswählen
                      </label>
                      <RadioButton
                        name="mediaType"
                        label="Bild"
                        value="image"
                        handleChange={this.onChangeType}
                        selected={mediaType === "image"}
                      />
                      <RadioButton
                        name="mediaType"
                        label="Video"
                        value="mp4"
                        handleChange={this.onChangeType}
                        selected={mediaType === "mp4"}
                      />
                    </div>
                  </div>
                }
                {!this.state.content.isSlider &&
                  <div className="form-group">
                    <div className="row">
                      <div className="col">

                      {this.state.content.mediaType === 'image' &&
                        <div className="dd-image-picker">
                          {this.state.content.imagePath ?
                            <ImagePreviewBlock
                              imagePath={this.state.content.imagePath}
                              imageId={this.state.imageId}
                              handleImageDelete={this.handleImageDelete}
                              openImageSelectorOverlay={this.openMediaSelectorOverlay}
                            />
                            :
                            <button className="dd-btn dd-btn--primary dd-image-picker__choose" onClick={(e) => this.openMediaSelectorOverlay(e)}>
                              Bild auswählen
                            </button>
                          }
                        </div>
                        }
                      {this.state.content.mediaType === 'mp4' &&
                        <div className="dd-image-picker">
                          {this.state.content.videoPath ?
                            <VideoPreviewBlock
                              videoPath={this.state.content.videoPath}
                              videoId={this.state.videoId}
                              handleVideoDelete={this.handleVideoDelete}
                              openVideoSelectorOverlay={this.openMediaSelectorOverlay}
                            />
                            :
                            <button className="dd-btn dd-btn--primary dd-image-picker__choose" onClick={(e) => this.openMediaSelectorOverlay(e)}>
                              Video auswählen
                            </button>
                          }
                        </div>
                        }

                        {this.state.mediaOverlayActive &&
                          <Overlay closeOverlayCallback={() => this.closeMediaSelectorOverlay()}>
                            <ImageSelector
                              selectImageCallback={(file, format) => this.handleMediaSelect(file, format)}
                              fileTypes={[mediaType]}
                              selectedAssetId={this.selectedAssetId}
                              selectedFormat={this.selectedAssetFormat}
                              allowedFormats={[
                                "original",
                                "five_by_two",
                                "sixteen_by_nine"
                              ]}
                            />
                          </Overlay>
                        }
                      </div>
                    </div>
                  </div>
                }

               {this.state.content.isSlider &&
                  <React.Fragment>
                    <div className="dd-mini-block">
                      <div className="dd-mini-block__blocks">
                        <DnDRows
                          rows={this.renderPreviews()}
                          rowsData={this.state.content.items}
                          handleChange={this.onRowsChange}
                        />
                      </div>
                    </div>

                    <div className="dd-mini-block__add">
                      <button className={"dd-btn dd-btn--primary dd-btn--icon-only"} onClick={(e) => this.addRow(e)}>
                        <Icon icon="plus" />
                      </button>
                    </div>
                </React.Fragment>
                }
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
          }
        </>
    )
  }
}

export default BlockHeroEdit
