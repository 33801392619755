import React from "react";

import { withRouter } from "react-router-dom";

import Loader from "../Shared/atom/Loader/Loader";
import PersonCoreData from "./PersonCoreData";
import PersonPublicData from "./PersonPublicData";
import PersonInternalData from "./PersonInternalData";
import Accordion from "../Shared/module/Accordion/Accordion";
import Editbar from "../Shared/element/Editbar/Editbar";
import OfficesEditTabs from "../../components/Offices/OfficesEditTabs";
import DeleteConfirmOverlay from "../Shared/module/Overlay/DeleteConfirmOverlay";

import LangChooser from "../Shared/element/LangTools/LangChooser";

import { getPerson, updatePerson, getTerms, getCompany, getVitas, updateVitas,
  updateMandates, getMandates, updateLectures, getLectures, translateLectures, updatePublications, getPublications, getUsermemberships, updateUsermemberships, updateSecretaries, getSecretaries, getLanguages, translateSecretaries, translateUsermemberships, translateVitas, translateMandates, translatePublications } from "../../data/api";
import { getToken } from "../../lib/lib";

class PersonEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      loading: true,
      lang: "de",
      isOpenTab: 0,
      personID: 0,
      person: {},
      academictitles: [],
      accordions: [
        {
          title: "Angaben zur Person",
          isOpen: true
        },
        {
          title: "Öffentliche Angaben zur Qualifikation",
          isOpen: false
        },
        {
          title: "Interne Angaben zur Person",
          isOpen: false
        },
      ]
    }
  }

  setLang = async (lang) => {
    await this.loadData(lang)
  }

  errorHandler = (hasError=false) => {
    this.setState({ hasError })
  }

  changeValue = (fieldname, value) => {
    const person = { ...this.state.person }
    person[fieldname] = value
    this.setState({ person }, () => {
    })
  }

  onAvatarUpdate = (avatar_id, avatar_format) => {
    const person = { ...this.state.person }
    person.avatar_id = avatar_id
    person.avatar_format = avatar_format
    this.setState({ person }, () => {
    })
  }

  onChange = (event) => {
    this.changeValue(event.target.name, event.target.value)
  }

  onSelectSalutation = (value) => {
    this.changeValue("salutation", value)
  }

  onSelectAcademictitle = (value) => {
    this.changeValue("academictitle_id", value)
  }

  onSelectAdmissionYear = (value) => {
    this.changeValue("admission_year", value)
  }

  sitesUpdate = (assignedTags) => {
    const person = { ...this.state.person }
    person.company_sites = assignedTags
    this.setState({ person })
  }

  jobtitlesUpdate = (assignedTags) => {
    const person = { ...this.state.person }
    person.jobtitles = assignedTags
    this.setState({ person })
  }

  advocaciesUpdate = (assignedTags) => {
    const person = { ...this.state.person }
    person.advocacies = assignedTags
    this.setState({ person })
  }

  languagesUpdate = (assignedTags) => {
    const person = { ...this.state.person }
    person.languages = assignedTags
    this.setState({ person })
  }

  lawfieldsUpdate = (assignedTags) => {
    const person = { ...this.state.person }
    person.lawfields = assignedTags
    this.setState({ person })
  }

  chambersUpdate = (assignedTags) => {
    const person = { ...this.state.person }
    person.chambers = assignedTags
    this.setState({ person })
  }

  nationalitiesUpdate = (assignedTags) => {
    const person = { ...this.state.person }
    person.nationalities = assignedTags
    this.setState({ person })
  }

  onVitasUpdate = (value) => {
    this.setState({ vitas: value }, () => {
    })
  }

  onMandatesUpdate = (value) => {
    this.setState({ mandates: value }, () => {
    })
  }

  onLecturesUpdate = (value) => {
    this.setState({ lectures: value }, () => {
    })
  }

  onPublicationsUpdate = (value) => {
    this.setState({ publications: value }, () => {
    })
  }

  onUsermembershipsUpdate = (value) => {
    this.setState({ usermemberships: value }, () => {
    })
  }

  updateSecretary = (secretaries) => {
    this.setState({ secretaries }, () => {
    })
  }

  closeOverlay = () => {
    this.setState({
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false,
    })
  }

  showConfirmationOverlay = (person, type) => {
    let confirmMode = "delete"
    let hideConfirmOverlay = false
    let deleteConfirmOverlay = false


    if (type === 'visibilty') {
      hideConfirmOverlay = true
      confirmMode = (person.hidden) ? "show" : "hide"
    } else {
      deleteConfirmOverlay = true
    }

    this.setState({
      deleteConfirmOverlay: deleteConfirmOverlay,
      hideConfirmOverlay: hideConfirmOverlay,
      personToModify: person.id,
      confirmMode: confirmMode
    })
  }

  setVisibility = () => {
    const person = { ...this.state.person }
    person.hidden = !person.hidden
    this.setState({ person, hideConfirmOverlay: false })
  }

  // Alle Mitarbeiter, ohne "person" selbst
  employeesSelectOptions = (company, person) => {
    const out = [{ id: 0, employee: 'Bitte wählen' }]
    company.employees.forEach((employee) => {
      if (employee.id !== person.id) {
        out.push({
          id: employee.id,
          employee: employee.full_name
        })
      }
    })
    return out
  }

  addTranslation = async (lang) => {
    this.setState({ isUpdating: true })

    const person = { ...this.state.person }

    await translateSecretaries(getToken(), person.id, lang)
    await translateVitas(getToken(), person.id, lang)
    await translateUsermemberships(getToken(), person.id, lang)
    await translateMandates(getToken(), person.id, lang)
    await translateLectures(getToken(), person.id, lang)
    await translatePublications(getToken(), person.id, lang)

    person.translated_en = true

    this.setState({ person, lang: lang }, ()=> {

      updatePerson(getToken(), person.id, person)
        .then(async updatedUser => {
          await this.loadData(this.state.lang)
        })
        .catch(err => {
          this.setState({ isUpdating: false })
        })
    })
  }


  onSave = async () => {
    this.setState({ isUpdating: true })

    const person = { ...this.state.person }

    person.company_site_ids = []

    person.company_sites.forEach((tag) => {
      person.company_site_ids.push(tag.id)
    })

    person.jobtitle_ids     = person.jobtitles.map(tag => tag.id)
    person.advocacy_ids     = person.advocacies.map(tag => tag.id)
    person.language_ids     = person.languages.map(tag => tag.id)
    person.lawfield_ids     = person.lawfields.map(tag => tag.id)
    person.chamber_ids      = person.chambers.map(tag => tag.id)
    person.nationality_ids  = person.nationalities.map(tag => tag.id)

    updateSecretaries(getToken(), person.id, this.state.secretaries, this.state.lang)
    updateVitas(getToken(), person.id, this.state.vitas, this.state.lang)
    updateMandates(getToken(), person.id, this.state.mandates, this.state.lang)
    updateLectures(getToken(), person.id, this.state.lectures, this.state.lang)
    updatePublications(getToken(), person.id, this.state.publications, this.state.lang)
    updateUsermemberships(getToken(), person.id, this.state.usermemberships, this.state.lang)

    updatePerson(getToken(), person.id, person)
    .then(async  updatedUser => {
      await this.loadData(this.state.lang)
    })
    .catch(err => {
      this.setState({ isUpdating: false })
    })
  }

  loadData = async (lang) => {
    try {
      const { person }  = await getPerson(getToken(),  this.state.personID)
      const company = await getCompany(getToken(), person.company_id)
      const vitas   = await getVitas(getToken(), person.id, lang)
      const mandates = await getMandates(getToken(), person.id, lang)
      const lectures = await getLectures(getToken(), person.id, lang)
      const secretaries   = await getSecretaries(getToken(),   person.id, lang)
      const publications = await getPublications(getToken(), person.id, lang)
      const usermemberships = await getUsermemberships(getToken(), person.id, lang)
      const academictitles  = await getTerms(getToken(), 'academictitles')
      const syslanguages = await getLanguages(getToken())

      this.setState({
        ...this.state,
        loading: false,
        lang: lang,
        person: person,
        company: company.company,
        secretaries: secretaries.secretaries,
        employeesSelectOptions: this.employeesSelectOptions(company.company, person),
        vitas: vitas.vitas,
        lectures: lectures.lectures,
        mandates: mandates.mandates,
        publications: publications.publications,
        academictitles: academictitles.academictitles,
        usermemberships: usermemberships.usermemberships,
        syslanguages: syslanguages.syslanguages,
      })
    } catch (error) {
      //FIXME NotFound
    }
  }

  async componentDidMount() {
    if (this.props.match && this.props.match.params) {

      this.setState({
        ...this.state,
        personID: this.props.match.params.userid,
      }, async () => {
        await this.loadData(this.state.lang)
      })
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    return (
      <>
          <div className="sidebar">
            <div className={`publish-status ${this.state.person.hidden ? 'publish-status--is-offline' : 'publish-status--is-live'}`}>
              <span className="publish-status--live">Live</span>
              <span className="publish-status--offline">Offline</span>
            </div>

          <LangChooser
            lang={this.state.lang}
            enableTranslations={!this.state.person.translated_en}
            translationFor={"en"}
            syslanguages={this.state.syslanguages}
            onSelect={(lang) => this.setLang(lang)}
            onTranslate={(lang) => this.addTranslation(lang)}
          />
          </div>

        <OfficesEditTabs
          headline={`Kanzlei ${this.state.company.name}`}
          officeid={this.state.company.id}
        />

        <div className="tab-pane fade show active">
          <Accordion
            title={this.state.accordions[0].title}
            isOpen={this.state.accordions[0].isOpen}
          >
            <PersonCoreData
              person={this.state.person}
              lang={this.state.lang}
              company={this.state.company}
              secretaries={this.state.secretaries}
              academictitles={this.state.academictitles}
              sysLang={this.props.match.params.lang}
              officeid={this.props.match.params.officeid}
              userid={this.props.match.params.userid}
              employeesSelectOptions={this.state.employeesSelectOptions}
              updateSecretary={this.updateSecretary}
              onChange={this.onChange}
              changeValue={this.changeValue}
              onAvatarUpdate={this.onAvatarUpdate}
              onSelectSalutation={this.onSelectSalutation}
              onSelectAcademictitle={this.onSelectAcademictitle}
              sitesUpdate={this.sitesUpdate}
              vitas={this.state.vitas}
              onVitasUpdate={this.onVitasUpdate}
              mandates={this.state.mandates}
              onMandatesUpdate={this.onMandatesUpdate}
              lectures={this.state.lectures}
              onLecturesUpdate={this.onLecturesUpdate}
              publications={this.state.publications}
              onPublicationsUpdate={this.onPublicationsUpdate}
              usermemberships={this.state.usermemberships}
              onUsermembershipsUpdate={this.onUsermembershipsUpdate}
              showConfirmationOverlay={this.showConfirmationOverlay}
              errorHandler={this.errorHandler}
            />
          </Accordion>

          <Accordion
            title={this.state.accordions[1].title}
            isOpen={this.state.accordions[1].isOpen}
          >
            <PersonPublicData
              person={this.state.person}
              company={this.state.company}
              lang={this.state.lang}
              jobtitlesUpdate={this.jobtitlesUpdate}
              advocaciesUpdate={this.advocaciesUpdate}
              languagesUpdate={this.languagesUpdate}
              lawfieldsUpdate={this.lawfieldsUpdate}
              changeValue={this.changeValue}
              onChange={this.onChange}
            />
          </Accordion>
          <Accordion
            title={this.state.accordions[2].title}
            isOpen={this.state.accordions[2].isOpen}
          >
            <PersonInternalData
              person={this.state.person}
              chambersUpdate={this.chambersUpdate}
              nationalitiesUpdate={this.nationalitiesUpdate}
              onSelectAdmissionYear={this.onSelectAdmissionYear}
              onChange={this.changeValue}
            />
          </Accordion>

          {this.state.deleteConfirmOverlay &&
            <DeleteConfirmOverlay
              closeOverlay={this.closeOverlay}
              onConfirmation={this.onDeleteConfirmation}
              mode={this.state.confirmMode}
            />
          }

          {this.state.hideConfirmOverlay &&
            <DeleteConfirmOverlay
              closeOverlay={this.closeOverlay}
              onConfirmation={this.setVisibility}
              mode={this.state.confirmMode}
            />
          }
          <Editbar
            hasPublish={false}
            hasPreview={false}
            hasError={this.state.hasError}
            onSave={this.onSave}
            successMessage="Erfolgreich gespeichert"
          />
        </div>
      </>
    )
  }
}

export default withRouter(PersonEdit);
