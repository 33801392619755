import React from 'react';

import RadioButton from '../Shared/atom/RadioButton/RadioButton';
import Icon from "../Shared/atom/Icon/Icon";

import { AppContext } from '../../data/ContextProvider'


class OfficeOpeningHours extends React.Component {
  state = {
    workdays: [
      {
        "name": "Montag - Freitag",
        "times": []
      }
    ],
    days: [
      {
        "name": "Montag",
        "times": []
      },
      {
        "name": "Dienstag",
        "times": []
      },
      {
        "name": "Mittwoch",
        "times": []
      },
      {
        "name": "Donnerstag",
        "times": []
      },
      {
        "name": "Freitag",
        "times": []
      },
      {
        "name": "Samstag",
        "times": []
      },
      {
        "name": "Sonntag",
        "times": []
      }
    ]
  }


  onChange = (daykey, timekey, name, value) => {
    const slot = ['1', '2']
    const dayname = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
    const field = dayname[daykey] + '_' + name + '_' + slot[timekey]

    if (!this.props.site.opening_hours_daily) {
      const workdays = this.state.workdays
      workdays[daykey].times[timekey][name] = value
      const days = this.state.days
      days[daykey].times[timekey][name] = value
      this.setState({ workdays, days }, () => this.props.changeValue(this.props.site.id, field, value))
    }
    if (this.props.site.opening_hours_daily) {
      const days = this.state.days
      days[daykey].times[timekey][name] = value
      const workdays = this.state.workdays
      if (daykey === 0) {
        workdays[daykey].times[timekey][name] = value
      }
      this.setState({ workdays, days }, () => this.props.changeValue(this.props.site.id, field, value))
    }
  }


  addTimes = (e, key) => {
    if (!this.props.site.opening_hours_daily) {
      const workdays = this.state.workdays
      const newTimes = { "open": "00:01", "close": "00:00" }
      workdays[key].times.push(newTimes)
      this.setState({ workdays })
    }
    if (this.props.site.opening_hours_daily) {
      const days = this.state.days
      const newTimes = { "open": "00:00", "close": "00:00" }
      days[key].times.push(newTimes)
      this.setState({ days })
    }
  }

  resetValues = (key) => {
    const dayname = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
    const values = [
      dayname[key] + '_open_1',
      dayname[key] + '_open_2',
      dayname[key] + '_close_1',
      dayname[key] + '_close_2',
    ]
    values.map((field) => {
      return this.props.changeValue(this.props.site.id, field, '')
    })
  }

  resetRow = (e, key) => {
    if (!this.props.site.opening_hours_daily) {
      const workdays = this.state.workdays
      workdays[key].times = []
      this.setState({ workdays }, () => this.resetValues(key))
    }
    if (this.props.site.opening_hours_daily) {
      const days = this.state.days
      days[key].times = []
      this.setState({ days }, () => this.resetValues(key))
    }
  }


  renderTimes = (time, timekey, daykey) => {
    return (
      <React.Fragment key={timekey}>
        {(timekey > 0) &&
          <td style={{
        padding: '15px 10px',
        display: 'flex',
        justifyContent: 'center',
        minWidth: '54px'
      }}>und</td>
        }
        <td style={{
        padding: '0px 10px'
      }}>
          <input
            type="time"
            name="open"
            value={time.open}
            onChange={(e) => this.onChange(daykey, timekey, e.target.name, e.target.value)}
          />
        </td>
        <td style={{
        padding: '0px 10px'
      }}>bis</td>
        <td style={{
        padding: '0px 10px'
      }}>
          <input
            type="time"
            name="close"
            value={time.close}
            onChange={(e) => this.onChange(daykey, timekey, e.target.name, e.target.value)}
          />
        </td>
      </React.Fragment>
    )
  }


  renderDays = (day, key) => {
    return (
      <tr key={key} style={{
        borderBottom: '2px solid white',
        padding: '8px'
      }}>
        <td style={{
        padding: '15px 0px',
        minWidth: '130px'
      }}>{day.name}</td>
        {day.times.map((time, timekey) => {
          return this.renderTimes(time, timekey, key)
        })}
        <td style={{
          padding: '10px 10px',
          display: 'flex',
          justifyContent: 'center'
        }}>
        {(day.times.length < 2) &&
            <button className="dd-btn dd-btn--primary dd-btn--icon-only" onClick={(e) => this.addTimes(e, key)}>
              <Icon icon="plus" />
            </button>
        }
          <button type="submit" className="dd-btn dd-btn--danger dd-btn--icon-only mr-2" onClick={(e) => this.resetRow(e, key)}>
            <Icon icon="trash" />
          </button>
        </td>
      </tr>
    )
  }


  componentDidMount() {
    const workdays = this.state.workdays
    const days = this.state.days

    const daynames = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
    const slots = ['1', '2']

    daynames.map((dayKey, dayIndex) => {
      slots.map((slotKey, slotIndex) => {
        const OKey = dayKey + '_open_' + slotKey
        const CKey = dayKey + '_close_' + slotKey
        if (this.props.site[OKey] || this.props.site[CKey]) {
          days[dayIndex].times.push({open: "00:00", close: "00:00"})
          days[dayIndex].times[slotIndex].open = (this.props.site[OKey]) ?? "00:00"
          days[dayIndex].times[slotIndex].close = (this.props.site[CKey]) ?? "00:00"
        }
        return ''
      })
      return ''
    })

    workdays[0].times = days[0].times

    this.setState({workdays, days})
  }


  render() {
    return (
      <div className="dd-form-section">
        <div className="dd-form-section__title">Öffnungszeiten</div>
        <div className="form-group mt-3" style={{'display':'flex', padding: '0 16px'}}>
          <RadioButton
            name={this.props.name}
            label="Eintrag gültig für die Woche"
            selected={!this.props.site.opening_hours_daily}
            handleChange={(e) => this.props.changeValue(this.props.site.id, 'opening_hours_daily', !e.target.checked)}
          />
          <div style={{marginRight: '50px'}}></div>
          <RadioButton
            name={this.props.name}
            label="Eintrag tageweise"
            selected={this.props.site.opening_hours_daily}
            handleChange={(e) => this.props.changeValue(this.props.site.id, 'opening_hours_daily', e.target.checked)}
          />
        </div>
        <div className="dd-form-section__content">
          <table>
            <tbody>
              {(!this.props.site.opening_hours_daily) ?
                this.state.workdays.map((day, key) => {
                  return this.renderDays(day, key)
                })
              :
                this.state.days.map((day, key) => {
                  return this.renderDays(day, key)
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

OfficeOpeningHours.contextType = AppContext
export default OfficeOpeningHours;
