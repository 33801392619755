import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import OfficesEditTabs from "../../components/Offices/OfficesEditTabs";
import Accordion from "../Shared/module/Accordion/Accordion";
import OfficePublicData from "./OfficePublicData";
import OfficeDiroData from "./OfficeDiroData";
import Loader from "../Shared/atom/Loader/Loader";
import Editbar from "../Shared/element/Editbar/Editbar";
import LangChooser from "../Shared/element/LangTools/LangChooser";

import { AppContext } from '../../data/ContextProvider'

import { getCompany, updateCompany, getTerms, getLanguages } from "../../data/api";
import { BASE_URL_DIRO_DEV } from "../../data/config";
import { getToken } from "../../lib/lib";


class OfficeEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      lang: "de",
      previewURL: '',
      hasError: false,
      companyID: 0,
      company: {},
      languages: [],
      accordions: [
        {
          title: "Öffentliche Angaben zur Kanzlei",
          isOpen: true
        },
        {
          title: "DIRO-Informationen zur Kanzlei",
          isOpen: false
        },
      ]
    }
  }

  setLang = async (lang) => {
    this.setState({ lang: lang })
  }

  errorHandler = (hasError=false) => {
    this.setState({ hasError })
  }

  languagesUpdate = (assignedTags) => {
    const company = { ...this.state.company };
    company.languages = assignedTags
    this.setState({ company })
  }

  changeValue = (field, value) => {
    const company = { ...this.state.company };
    company[field] = value;

    this.setState({ company }, () => {
      //this.validateField(name, value)
    });
  }

  onChange = (event) => {
    this.changeValue(event.target.name, event.target.value)
  }

  onHTTPSChange = (event) => {
    const company = { ...this.state.company };
    company['http_protocol'] = event.target.checked ? 'https' : 'http';
    this.setState({ company });
  }

  onSelectContact = (value) => {
    this.changeValue("contact_id", value)

  }

  onSelectAdministrator = (value) => {
    this.changeValue("administrator_id", value)
  }

  onStatusChange = (e) => {
    if (e.target.checked) {
      const company = { ...this.state.company };
      company.diro_status = e.target.value || 'Probezeit'
      this.setState({ company })
    }
  }

  onSave = async () => {
    this.setState({ isUpdating: true })
    const company = { ...this.state.company }

    company.language_ids = company.languages.map(tag => tag.id)

    updateCompany(getToken(), company.id, company)
      .then(async updatedCompany => {
      await this.loadData()
    })
    .catch(err => {
      this.setState({ isUpdating: false })
    })
  }

  loadData = async () => {
    const company = await getCompany(getToken(), this.state.companyID)
    const languages = await getTerms(getToken(), 'languages')
    const syslanguages = await getLanguages(getToken())

    const preview = `${BASE_URL_DIRO_DEV}/diro-kanzleien/${company.company.path}`

    this.setState({
      ...this.state,
      previewURL: preview,
      company: company.company,
      languages: languages.languages,
      loading: false,
      syslanguages: syslanguages.syslanguages,
    })
  }

  async componentDidMount() {
    let officeid = 0

    if (!this.context.user.diro_admin) {
      // Kanzlei
      if (this.context.company) {
        officeid = this.context.company.id
      }
    } else {
      // DIRO
      if (this.props.match && this.props.match.params) {
        officeid = this.props.match.params.officeid
      }
    }

    if (officeid) {
      this.setState({
        ...this.state,
        companyID: officeid,
      }, async () => {
        await this.loadData()
      })
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    return (
      <>
        <div className="sidebar">
          <LangChooser
            lang={this.state.lang}
            syslanguages={this.state.syslanguages}
            onSelect={(lang) => this.setLang(lang)}
          />
        </div>

        <OfficesEditTabs
          headline={this.state.company.name}
          officeid={this.state.companyID}
        />

        <div className="tab-pane fade show active">
          <Accordion
            title={this.state.accordions[0].title}
            isOpen={this.state.accordions[0].isOpen}
          >
            <OfficePublicData
              company={this.state.company}
              lang={this.state.lang}
              languages={this.state.languages}
              languagesUpdate={this.languagesUpdate}
              onChange={this.onChange}
              changeValue={this.changeValue}
              errorHandler={this.errorHandler}
            />
          </Accordion>

          { this.context.user.diro_admin &&
          <Accordion
            title={this.state.accordions[1].title}
            isOpen={this.state.accordions[1].isOpen}
          >
            <OfficeDiroData
              company={this.state.company}
              onSelectContact={this.onSelectContact}
              onSelectAdministrator={this.onSelectAdministrator}
              changeValue={this.changeValue}
              onChange={this.onChange}
              onHTTPSChange={this.onHTTPSChange}
              errorHandler={this.errorHandler}
            />
          </Accordion>
          }
          <Editbar
            previewURL={this.state.previewURL}
            hasPublish={false}
            hasPreview={true}
            hasError={this.state.hasError}
            onSave={this.onSave}
            successMessage="Erfolgreich gespeichert"
          />
        </div>
      </>
    );
  }
}

OfficeEdit.contextType = AppContext
export default withRouter(OfficeEdit);
