import React from "react";
import { Link, withRouter } from "react-router-dom";


import SimpleSelectfield from "../Shared/atom/Selectfield/SimpleSelectfield";
import Searchbar from "../Shared/element/Searchbar/Searchbar";

import TableColumnHeader from "../../layout/Tables/TableColumnHeader";
import Icon from "../Shared/atom/Icon/Icon";

import Loader from "../Shared/atom/Loader/Loader";
import Editbar from "../Shared/element/Editbar/Editbar";


import { getCompaniesList, getCompany } from "../../data/api";
import { getToken, formatShortDate, formatBool } from "../../lib/lib";
import { AppContext } from "../../data/ContextProvider";

class User extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      loading: true,
      selectedCompanyID: 0,

      users: [],
      companies: [],
    }
  }

  onFilterChange = (searchTerm) => {
  }

  onSelectCompany = async (company_id) => {
    this.setState({
      selectedCompanyID: company_id,
    }, async () => {
      this.props.history.push(`/de/admin/user/${company_id}`)
      await this.loadData()
    })
  }

  loadData = async () => {
    const companies = await getCompaniesList(getToken())

    if (this.state.selectedCompanyID) {
      const company = await getCompany(getToken(), this.state.selectedCompanyID)

      this.setState({
        companies: companies.companies,
        loading: false,
        company: company.company,
        users: company.company.all_employees,
      })

    } else {
      this.setState({
        companies: companies.companies,
        loading: false,
      })
    }
  }

  async componentDidMount() {

    if (this.props.match && this.props.match.params && this.props.match.params.company_id) {
      this.setState({
        selectedCompanyID: this.props.match.params.company_id,
      }, async () => {
        await this.loadData()
      })
    } else {
      await this.loadData()
    }
  }

  render() {

    const admin = (this.context.user.diro_admin)

    if (!admin || this.state.loading) {
      return <Loader />
    }

    return (
      <>
        <div className="dd-card">
         <div className="d-flex">
            <h2 className="dd-card__title">Berufsträger welcher Kanzlei wollen Sie bearbeiten?</h2>
            <div className="pt-2">
              <SimpleSelectfield
                label={false}
                name="company"
                firstOptionValue={0}
                firstOptionLabel="Bitte auswählen"
                indexLabel="id"
                optionLabel="name"
                options={this.state.companies}
                onSelect={this.onSelectCompany}
                selected={this.state.selectedCompanyID}
              />
            </div>
          </div>
          <table className="table dd-table">
            <thead>
              <tr>
                <TableColumnHeader
                  name="Name"
                  width="20%"
                  field="name"
                  currentOrderField={this.state.orderField}
                  currentOrderDirection={this.state.orderDirection}
                  onClick={this.onTableColumnClick} />

                  <TableColumnHeader
                  name="E-Mail"
                  width="15%"
                  field="email"
                  currentOrderField={this.state.orderField}
                  currentOrderDirection={this.state.orderDirection}
                  onClick={this.onTableColumnClick} />

                <TableColumnHeader
                  name="Erstellt"
                  field="created_at"
                  currentOrderField={this.state.orderField}
                  currentOrderDirection={this.state.orderDirection}
                  onClick={this.onTableColumnClick} />

                <TableColumnHeader
                  name="Geändert"
                  field="updated_at"
                  currentOrderField={this.state.orderField}
                  currentOrderDirection={this.state.orderDirection}
                  onClick={this.onTableColumnClick} />

                <TableColumnHeader
                  name="Status"
                  width="15%"
                  field="city"
                  currentOrderField={this.state.orderField}
                  currentOrderDirection={this.state.orderDirection}
                  onClick={this.onTableColumnClick}
                />

                <th scope="col">Zugang aktiv</th>

                <th scope="col" className="dd-table--actions-th">
                  <div className="dd-search-bar dd-search-bar--small dd-search-bar--white dd-table--search-filter">
                    <Searchbar
                      placeholder="Suche..."
                      icon="filter"
                      onChangeCallback={(e) => this.onFilterChange(e)}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
            {this.state.users.map((user, key) => {
               return <tr key={key} className={(user.hidden) ? 'is-inactive' : ''}>
              <td className={(this.state.orderField === 'name') ? 'is-active' : ''}>{user.full_name}</td>
              <td className={(this.state.orderField === 'email') ? 'is-active' : ''}>{user.email}</td>
              <td className={(this.state.orderField === 'created_at') ? 'is-active' : ''}>{formatShortDate(user.created_at)}</td>
              <td className={(this.state.orderField === 'updated_at') ? 'is-active' : ''}>{formatShortDate(user.updated_at)}</td>
              <td className={(this.state.orderField === 'hidden') ? 'is-active' : ''}>{formatBool(user.hidden, "deaktiviert", "aktiv")}</td>
              <td>{formatBool(user.has_backend_access)}</td>
              <td className="justify-content-center dd-table--actions">
                <div className="dd-table--actions_wrapper">
                  <Link to={`/de/admin/user/edit/${user.id}`}>
                    <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only">
                      <Icon icon="edit" />
                    </button>
                  </Link>
                </div>
              </td>
              </tr>
            })}
            </tbody>
          </table>
        </div>

        <Editbar
          hasPublish={false}
          hasPreview={false}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
        />
      </>
    )
  }
}

User.contextType = AppContext
export default withRouter(User)
