import React from 'react'
import { withRouter, Route, Switch } from 'react-router-dom'

import JobsTable from '../components/Jobs/JobsTable'
import JobNew from '../components/Jobs/JobNew'
import JobContent from '../components/Jobs/JobContent'


const Jobs = () => {
  return (
    <div className="tab-content" id="nav-tabContent">
      <Switch>
        <Route exact path="/:lang/jobs">
          <JobsTable />
        </Route>
        <Route exact path="/:lang/jobs/new">
          <JobNew />
        </Route>
        <Route exact path="/:lang/jobs/:job_id/:tabs">
          <JobContent />
        </Route>
      </Switch>
    </div>
  )
}

export default withRouter(Jobs)
