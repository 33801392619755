import React  from "react";

import Textfield from "../Shared/atom/Textfield/Textfield";
import TagList from "../Shared/atom/TagList/TagList";
import ImageSelector from "../Shared/module/ImageSelector/ImageSelector";
import Checkbox from "../Shared/atom/Checkbox/Checkbox";
import Overlay from "../Shared/element/Overlay/Overlay";
import { ImagePreviewAvatar } from "../Shared/element/ImagePreview/ImagePreview";

import { imagePathForFormat } from "../../lib/lib";

class OfficePublicData extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      imageOverlayActive: false,
      logo_path: "",
    }
  }

  openImageSelectorOverlay = (e) => {
    // e.preventDefault()
    this.setState({
      imageOverlayActive: true
    })
  }

  closeImageSelectorOverlay = () => {
    this.setState({
      imageOverlayActive: false,
    })
  }

  handleImageSelect = (file, format) => {
    const path = file[imagePathForFormat(format)]
    this.setState({
      logo_path: path,
      imageOverlayActive: false,
    },()=>{
        this.props.changeValue("logo_id", file.id)
    })
  }

  onLogoDelete = (file, format) => {
    this.setState({
      logo_path: "",
    }, () => {
        this.props.changeValue("logo_id", 0)
    })
  }

  render() {
    const { company } = this.props

    let logo_path = ""

    if (company.logo_id === 0) {
      logo_path = ""
    } else if (this.state.logo_path !== ""){
      logo_path = this.state.logo_path
    } else {
      logo_path = company.logo_path
    }

    const indexLabel = 'title_' + this.props.lang

    return (

      <div className="dd-form-section dd-form-section--last">
        <label htmlFor="allgemeines" className="dd-form-section__title">
          Allgemeines und Präsenzen auf Social Media
        </label>

        <fieldset id="allgemeines" className="dd-form-section__content">
          <div className="row">
            <div className="col-md-6 info-text">
              Hinweis: Mit einem Stern(*) gekennzeichnete Felder müssen ausgefüllt werden.
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-2">
              <div className="form-group">

                {this.props.lang === 'de' &&
                  <Textfield
                    name="name"
                    type="text"
                    isRequired={true}
                    label="Name"
                    defaultValue={company.name}
                    handleChange={this.props.onChange}
                    errorHandler={this.props.errorHandler}
                  />
                }
                {this.props.lang !== 'de' &&
                  <Textfield
                    name={`name_${this.props.lang}`}
                    type="text"
                    isRequired={true}
                    label="Name"
                    defaultValue={company[`name_${this.props.lang}`]}
                    handleChange={this.props.onChange}
                    errorHandler={this.props.errorHandler}
                  />
                }

              </div>
              <div className="form-group">
                <Textfield
                  name="name_short"
                  type="text"
                  label="Kurztitel (optional, z.B. zur Verwendung in der Titelzeile des Browsers)"
                  defaultValue={company.name_short}
                  handleChange={this.props.onChange}
                  errorHandler={this.props.errorHandler}
                />
              </div>

              <div className="form-group form-group__textarea">
                <label className={"textfield__label"} htmlFor="kurzbeschreibung">Kurzbeschreibung</label>

                {this.props.lang === 'de' &&
                  <textarea
                    className="form-control"
                    name="description"
                    rows="4"
                    value={company.description || ""}
                    onChange={this.props.onChange}
                    />
                }
                {this.props.lang !== 'de' &&
                  <textarea
                    className="form-control"
                    name={`description_${this.props.lang}`}
                    rows="4"
                    value={company[`description_${this.props.lang}`] || ""}
                    onChange={this.props.onChange}
                    />
                }
              </div>
            </div>

            <div className="col-md-4 mb-2">

              <div className="dd-avatar dd-avatar--office dd-avatar--logo">
                {(!logo_path) &&
                  <React.Fragment>
                    <button
                      className="dd-btn dd-btn--primary dd-image-picker__choose"
                      onClick={(e) => this.openImageSelectorOverlay(e)}>
                      Bild auswählen
                    </button>
                    <p className="dd-avatar__hint">
                      <strong>Upload des Firmenlogos</strong><br />
                      im Querformat. Zulässige Dateiformate: svg, png (transparent). Mindestens 400px Breite.
                    </p>
                  </React.Fragment>
                }
                {(logo_path) &&
                  <ImagePreviewAvatar
                    avatar_path={logo_path}
                    onAvatarDelete={this.onLogoDelete}
                    openImageSelectorOverlay={(e) => this.openImageSelectorOverlay(e)}
                    message="Ihr Unternehmen wird dann auf allen Einträgen unter DIRO.eu ohne Logo dargestellt."
                  />
                }
              </div>
              {this.state.imageOverlayActive &&
                <Overlay closeOverlayCallback={() => this.closeImageSelectorOverlay()}>
                  <ImageSelector
                    selectImageCallback={(file, format) => this.handleImageSelect(file, format)}
                    selectedAssetId={this.props.company.logo_id || ''}
                    selectedFormat={this.props.company.logo_format || ''}
                    fileTypes={["image", "icon"]}
                  />
                </Overlay>
              }

            </div>

            <div className="col-md-4 mb-2">
              <p className="info-text">Bitte nur aktiv genutzte Plattformen angeben. Leere Felder werden auf der
                Website nicht angezeigt.</p>
              <Textfield
                name="xingurl"
                type="text"
                label="Die Kanzlei bei XING"
                defaultValue={company.xingurl}
                handleChange={this.props.onChange}
              />
              <Textfield
                name="linkedinurl"
                type="text"
                label="Die Kanzlei bei LinkedIn"
                defaultValue={company.linkedinurl}
                handleChange={this.props.onChange}
              />
              <Textfield
                name="facebookurl"
                type="text"
                label="Die Kanzlei bei Facebook"
                defaultValue={company.facebookurl}
                handleChange={this.props.onChange}
              />
              <Textfield
                name="twitterurl"
                type="text"
                label="Die Kanzlei bei X (ehemals Twitter)"
                defaultValue={company.twitterurl}
                handleChange={this.props.onChange}
              />
              <Textfield
                name="instaurl"
                type="text"
                label="Die Kanzlei bei Instagram"
                defaultValue={company.instaurl}
                handleChange={this.props.onChange}
              />
              <Textfield
                name="youtubeurl"
                type="text"
                label="Die Kanzlei bei YouTube"
                defaultValue={company.youtubeurl}
                handleChange={this.props.onChange}
              />
            </div>
          </div>

          {company.id &&
            <div className="form-row mt-2 d-flex flex-column">
              <div className="row">
                <div className="col-md-4 d-flex justify-content-md-end align-items-center">
                  <label className="mb-md-0 label--single" htmlFor="fremdsprachen-read-only">Sprachen der Berufsträger</label>
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  <div className="form-group mb-0">
                  <span className="d-block" id="fremdsprachen-read-only">
                      <TagList
                        indexLabel={indexLabel}
                        name="Stichworte"
                        hasAddNew={false}
                        data={company.user_languages}
                        availableData={[]}
                        label=""
                        isPlusButtonVisible={false}
                        disabled={true}
                      />
                  </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 d-flex justify-content-md-end align-items-center">
                  <label className="label--single" htmlFor="fremdsprachen">Weitere Kanzleisprachen</label>
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  <TagList
                    indexLabel={indexLabel}
                    name="Sprachen"
                    data={company.languages}
                    availableData={this.props.languages}
                    onUpdate={(assignedTags) => this.props.languagesUpdate(assignedTags)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 d-flex justify-content-md-end align-items-center"></div>
                <div className="col-md-8 d-flex align-items-center">
                  <Checkbox
                    label="Fachbeiträge des DIRO-Netzwerks auf der Visitenkarte der Kanzlei unter DIRO.eu anzeigen?"
                    id="show_network_articles"
                    selected={(company.show_network_articles)}
                    onChange={(e) => this.props.changeValue("show_network_articles", !company.show_network_articles)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 d-flex justify-content-md-end align-items-center"></div>
                <div className="col-md-8 d-flex align-items-center">
                  Wenn Sie bereits eigene Beiträge für das DIRO-Netzwerk erstellt und freigegeben haben, erscheinen nur diese auf Ihrer Visitenkarte, ansonsten die vom Netzwerk empfohlenen.
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 d-flex justify-content-md-end align-items-center"></div>
                <div className="col-md-8 d-flex align-items-center">
                  <Checkbox
                    label="TÜV-Zertifizierung im Footer anzeigen"
                    id="tuev_certified"
                    selected={(company.tuev_certified)}
                    onChange={(e) => this.props.changeValue("tuev_certified", !company.tuev_certified)}
                  />
                </div>
              </div>

          </div>
          }
        </fieldset>

      </div>
    );
  }
}

export default OfficePublicData;
