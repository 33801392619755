import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Dashboard from './Dashboard'
import DiroDashboard from './DiroDashboard'
import { AppContext } from '../data/ContextProvider'

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: []
    };
  }

  render() {
    return (
      <div>
      {!this.context.user.diro_admin &&
        <Dashboard />
      }

      {this.context.user.diro_admin &&
        <DiroDashboard />
      }

      </div>
    );
  }
}

Home.contextType = AppContext
export default withRouter(Home);
