import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import Tablist from '../Shared/module/Tablist/Tablist'

class JobEditTabs extends Component {
  render() {
    const lang = this.props.match.params.lang
    const job_id = this.props.match.params.job_id

    const tabs = [
      { title: 'Beschreibung', path: `/${lang}/jobs/${job_id}/content` },
      { title: 'Details', path: `/${lang}/jobs/${job_id}/details` },
      { title: 'Meta-Daten', path: `/${lang}/jobs/${job_id}/meta` },
      {
        title: 'Veröffentlichung',
        path: `/${lang}/jobs/${job_id}/publishing`
      }
    ]

    return (
      <Tablist
        additionalClass="w-75 ml-auto mr-auto pt-4 d-flex justify-content-between"
        tabs={tabs}
        active={this.props.activeTab}
        tabChange={() => {}}
      />
    )
  }
}

export default withRouter(JobEditTabs)
