import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = (props) => {
  let className = (props.disabled) ? "checkbox__container checkbox__container--disabled" : "checkbox__container"
  className = (props.addStyles) ? className + ' checkbox__container--changes' : className
  return (
    <label className={className}>
      <input id={props.id} className={`checkbox`} type={`checkbox`} disabled={props.disabled}
             checked={props.selected}
             value={props.id}
             name={props.name}
             onChange={props.onChange ? props.onChange : null} />
      <span className={`checkbox__checkmark`}></span>
      <span className={`checkbox__label`}>{props.label}</span>
    </label>
  )
}

Checkbox.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
};
Checkbox.defaultProps = {
  disabled: false,
  selected: false
};

export default Checkbox