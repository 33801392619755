import React from "react"

import Loader from "../Shared/atom/Loader/Loader"
import Editbar from "../Shared/element/Editbar/Editbar"
import Accordion from "../Shared/module/Accordion/Accordion"
import Textfield from "../Shared/atom/Textfield/Textfield"
import Checkbox from "../Shared/atom/Checkbox/Checkbox"
import RadioButton from "../Shared/atom/RadioButton/RadioButton"
import LangSwitcherStatic from "../Shared/element/LangTools/LangSwitcherStatic"
import TagList from "../Shared/atom/TagList/TagList"
import DndList from "../Shared/module/Dnd-list/Dnd-list"
import { ImagePreviewAvatar } from "../Shared/element/ImagePreview/ImagePreview"
import Overlay from "../Shared/element/Overlay/Overlay"
import ImageSelector from "../Shared/module/ImageSelector/ImageSelector"

import { getLanguages, getCompany } from "../../data/api";
import { getChatbots, updateChatbot, createChatbot } from "../../data/admin-api";
import { getToken, imagePathForFormat } from "../../lib/lib";
import { AppContext } from "../../data/ContextProvider";
import Icon from "../Shared/atom/Icon/Icon"

class Chatbot extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      query: '',
      activeLanguage: 'de',
      imageOverlayActive: false,
      avatar_path: "",
      orderedLanguages: [],
      chatbot: {
        lang: "de",
        state: "0",
      },
      lawfields: [],
      loading: true,
      items: [],
      lectures: {
        headlines: [
          "Untergruppe*",
        ],
        fieldNames: [
          "title",
        ]
      },
      steps: [true, false, false, false, false],
      stepslength: 5,
      activeStep: 1
    }
  }

  errorHandler = (hasError = false) => {
    this.setState({hasError})
  }

  openImageSelectorOverlay = () => {
    this.setState({
      imageOverlayActive: true
    })
  }

  closeImageSelectorOverlay = () => {
    this.setState({
      imageOverlayActive: false,
    })
  }

  handleImageSelect = (file, format) => {
    const path = file[imagePathForFormat(format)]
    this.setState({
      avatar_path: path,
      imageOverlayActive: false,
    }, () => {
      this.changeValue("avatar_id", file.id)
    })
  }

  onLogoDelete = (file, format) => {
    this.setState({
      avatar_path: "",
    }, () => {
      this.changeValue("avatar_id", 0)
    })
  }

  onChange = (e) => {
    this.changeValue(e.target.name, e.target.value)
  }

  onChatChange = (e) => {
    const chatbot = { ...this.state.chatbot }
    chatbot.chat[e.target.name] = e.target.value
    this.setState({ chatbot })
  }

  onChatChangeToggle = (name) => {
    const chatbot = { ...this.state.chatbot }
    // chatbot.chat[name] = !chatbot.chat[name]
    this.setState({ chatbot })
  }

  changeValue = (fieldname, value) => {
    const chatbot = { ...this.state.chatbot }
    chatbot[fieldname] = value
    this.setState({ chatbot})
  }

  onSelectLawfield = (assignedTags) => {
    const chatbot = { ...this.state.chatbot }
    chatbot.chat.lawfields = assignedTags
    this.setState({ chatbot })
  }

  onLawfieldExtension = (rows, lawfield) => {
    const chatbot = { ...this.state.chatbot }
    chatbot.chat.lawfields_extensions[lawfield.id] = rows
    this.setState({ chatbot })
  }

  lawfieldExtensionForLawfield = (lawfield_id) => {
    if (this.state.chatbot.chat.lawfields_extensions[lawfield_id]) {
      return this.state.chatbot.chat.lawfields_extensions[lawfield_id]
    }
    return []
  }

  setActiveLanguage = (shorthand) => {
    this.setState({
      activeLanguage: shorthand
    })
  }

  defaultChatbot = () => {
    return {
      lang: this.state.activeLanguage,
      state: "0",
      chat: {
        prolog_question: "",
        prolog_answer: "",
        lawfields_question: "",
        lawfields: [],
        lawfields_extensions: {},
        other_direction: "",
        other_direction_I: "",
        contact_name: "",
        contact_request: "",
        contact_telefon_enabled: true,
        contact_telefon_question: "",
        contact_telefon_thank_you_text: "",
        contact_telefon_timing_first: "",
        contact_telefon_timing_second: "",
        contact_telefon_timing_third: "",
        contact_telefon_timing_fourth: "",
        contact_email_enabled: true,
        contact_email_question: "",
        thank_you_message: "",
        thank_you_positive: "",
        thank_you_positive_link: "",
        thank_you_negative: "",
      }
    }
  }

  onSubmit = async () => {
    this.setState({ isUpdating: true })

    const chatbot = this.state.chatbot
    chatbot.lang = this.state.activeLanguage
    chatbot.body = JSON.stringify(chatbot.chat)

    if (chatbot && chatbot.id) {
      await updateChatbot(chatbot.id, chatbot)
    } else {
      await createChatbot(chatbot)
    }

    await this.loadData()
  }

  loadData = async () => {

    let steps = this.state.steps
    let chatbot = this.defaultChatbot()
    // let counter = 0

    const chatbots = await getChatbots(this.state.activeLanguage)
    const activatedLanguagesRecord = await getLanguages(getToken())
    const company = await getCompany(getToken(), this.context.company.id)

    //default
    let activatedLanguages = [{ id: 1, shorthand: "de", title: "Deutsch" }]
    if (activatedLanguagesRecord.syslanguages && activatedLanguagesRecord.syslanguages.length > 0) {
      activatedLanguages = activatedLanguagesRecord.syslanguages
    }

    let activeLang = "de"
    if (activatedLanguages[0]) {
      activeLang = activatedLanguages[0].shorthand
    }

    let activeStep = 1
    if (chatbots && chatbots.length > 0) {
      chatbot = chatbots[0]
      chatbot.chat = JSON.parse(chatbot.body)
      steps = [true, true, true, true, true]
      activeStep = 5
    }

    // ToDo: ActiveStep in chatbot speichern, um somit nur die bereits aktiven Schritte zu sehen
    // steps.forEach(step => {
    //   console.log(activeStep);
    //   if (counter < activeStep) {
    //     steps[counter] = true
    //   } else
    //     steps[counter] = false
    //   counter++
    // })

    this.setState({
      chatbot: chatbot,
      activeLanguage: activeLang,
      activatedLanguages: activatedLanguages,
      company: company.company,
      loading: false,
      steps,
      activeStep,
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  handleNextStep = () => {
    let steps = this.state.steps
    let activeStep = this.state.activeStep
    steps[activeStep] = true
    activeStep++
    this.setState({
      activeStep,
      steps
    })
  }

  nextStepButton = (chatbot) => {
    if (this.state.activeStep !== this.state.stepslength) {
      return (
        <div className="next-btn d-flex flex-column align-items-center">
          <button className="dd-btn dd-btn--primary dd-btn--icon-only" onClick={this.handleNextStep}>
            <Icon icon="plus" />
          </button>
          <span className="dd-btn__text mt-1">Nächster Schritt</span>
        </div>
      );
    } else if (this.state.activeStep === this.state.stepslength) {
      return this.handleFormValid(chatbot) // last step form validation
    }
  };

  handleLawfieldsExtensionsValid = (chatbot) => {
    if (chatbot.chat.lawfields.length) {
      if (Object.keys(chatbot.chat.lawfields_extensions).length) {
        return true
      }
    }
    return false
  }

  handleFormValid = (chatbot) => {
    if (
      chatbot.email && chatbot.email.length
      && chatbot.chat.prolog_question && chatbot.chat.prolog_question.length
      && chatbot.chat.lawfields_question && chatbot.chat.lawfields_question.length
      && chatbot.chat.contact_name && chatbot.chat.contact_name.length
      && chatbot.chat.contact_name && chatbot.chat.contact_name.length
      && chatbot.chat.contact_request && chatbot.chat.contact_request.length
      && chatbot.chat.contact_telefon_question && chatbot.chat.contact_telefon_question.length
      && chatbot.chat.contact_telefon_thank_you_text && chatbot.chat.contact_telefon_thank_you_text.length
      && chatbot.chat.contact_email_question && chatbot.chat.contact_email_question.length
      && chatbot.chat.thank_you_message && chatbot.chat.thank_you_message.length
      && chatbot.chat.thank_you_positive && chatbot.chat.thank_you_positive.length
      && chatbot.chat.thank_you_positive_link && chatbot.chat.thank_you_positive_link.length
      && chatbot.chat.thank_you_negative && chatbot.chat.thank_you_negative.length
      && chatbot.chat.other_direction_input_placeholder && chatbot.chat.other_direction_input_placeholder.length
      && chatbot.chat.contact_name_input_placeholder && chatbot.chat.contact_name_input_placeholder.length
      && chatbot.chat.contact_email_input_placeholder && chatbot.chat.contact_email_input_placeholder.length
      && chatbot.chat.contact_telefon_input_placeholder && chatbot.chat.contact_telefon_input_placeholder.length
    ) {
      if (this.handleLawfieldsExtensionsValid(chatbot)) {
        return true
      }
    }
    return false
  }

  render() {
    const admin = (this.context.user.admin || this.context.user.diro_admin)

    if (this.state.loading || !admin) {
      return <Loader />
    }


    const chatbot = this.state.chatbot
    const hasError = this.state.hasError
    let avatar_path = ""

    if (chatbot.avatar_id === 0) {
      avatar_path = ""
    } else if (this.state.avatar_path !== "") {
      avatar_path = this.state.avatar_path
    } else {
      avatar_path = chatbot.avatar_path
    }

    return (
      <>
        <div className="sidebar">
          <LangSwitcherStatic
            activeLanguage={this.state.activeLanguage}
            syslanguages={this.state.activatedLanguages}
            setActiveLanguage={(shorthand) => this.setActiveLanguage(shorthand)}
          />
        </div>
        <Accordion
          title="Hinweis"
          isOpen={true}
        >
          <div className="form-group dd-form-section">
            <div className="form-group">
              <p>Bitte beachten Sie, dass Sie alle mit einem * gekennzeichneten Felder ausfüllen müssen, damit Darstellung und Funktionsfähigkeit des Chatbots gewährleistet sind.</p>
            </div>
          </div>
        </Accordion>

      {this.state.steps[0] &&
        <Accordion
          title="Allgemeine Einstellungen"
          isOpen={true}
        >

          <div className="form-group dd-form-section">
            <div className="form-group">
              <Textfield
                name="email"
                type="text"
                label="Kontaktadresse"
                handleChange={(e) => this.onChange(e)}
                defaultValue={chatbot.email}
                isRequired={true}
                errorHandler={this.errorHandler}
              />
              <p>Bitte geben Sie an, mit welcher E-Mail-Adresse der Chatbot korrespondieren soll.</p>
            </div>
          </div>

          <div className="form-group dd-form-section">
            <div className="form-check mb-2">
              <label className="textfield__label">
                Avatarbild
              </label>
              <div className="dd-avatar dd-avatar--office dd-avatar--logo">
                {(!avatar_path) &&
                  <React.Fragment>
                    <button
                      className="dd-btn dd-btn--primary dd-image-picker__choose"
                      onClick={this.openImageSelectorOverlay}>
                      Bild auswählen
                    </button>
                    <p className="dd-avatar__hint">
                      <strong>Upload des Avatarbildes</strong><br />
                      im 1:1 Format. Zulässige Dateiformate: svg, png. Mindestens 400px Breite.
                    </p>
                  </React.Fragment>
                }
                {(avatar_path) &&
                  <ImagePreviewAvatar
                    avatar_path={avatar_path}
                    onAvatarDelete={this.onLogoDelete}
                    openImageSelectorOverlay={this.openImageSelectorOverlay}
                    message="Das Favicon-Bild Ihres Unternehmens wird als Avatarbild eingesetzt."
                  />
                }
              </div>
              {this.state.imageOverlayActive &&
                <Overlay closeOverlayCallback={() => this.closeImageSelectorOverlay()}>
                  <ImageSelector
                    selectImageCallback={(file, format) => this.handleImageSelect(file, format)}
                    selectedAssetId={chatbot.avatar_id || ''}
                    selectedFormat={''}
                    fileTypes={["image", "icon"]}
                  />
                </Overlay>
              }
            </div>
          </div>

          <div className="form-group dd-form-section">
            <div className="form-check mb-2">
              <label className="textfield__label">
                Wo soll der Chatbot erscheinen?*
              </label>
              <RadioButton
                name="state"
                label="Deaktiviert"
                value="0"
                selected={(parseInt(chatbot.state) === 0)}
                handleChange={(e) => this.changeValue("state", 0)}
              />
              <RadioButton
                name="state"
                label="Nur auf der Startseite"
                value="1"
                selected={(parseInt(chatbot.state) === 1)}
                handleChange={(e) => this.changeValue("state", 1)}
              />
              <RadioButton
                name={`contact_option_type_`}
                label="auf allen Seiten"
                value="2"
                selected={(parseInt(chatbot.state) === 2)}
                handleChange={(e) => this.changeValue("state", 2)}
              />
            </div>
          </div>
        </Accordion>
      }
      {this.state.steps[1] &&
        <Accordion
          title="Einleitung"
          isOpen={true}
        >

          <div className="form-group dd-form-section">
            <div className="form-group">
              <Textfield
                name="prolog_question"
                type="text"
                label="Bitte formulieren Sie hier die Einleitung für die Schaltfläche/n"
                handleChange={(e) => this.onChatChange(e)}
                defaultValue={chatbot.chat.prolog_question ? chatbot.chat.prolog_question : chatbot.chat.prolog_question = "Hallo - Sie sind auf der Suche nach Rechtsberatung?"}
                errorHandler={this.errorHandler}
                isRequired={true}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="prolog_answer"
                type="text"
                label="Antwort"
                handleChange={(e) => this.onChatChange(e)}
                defaultValue={chatbot.chat.prolog_answer ? chatbot.chat.prolog_answer : chatbot.chat.prolog_answer = "Ja, Bitte!"}
              />
            </div>
          </div>
        </Accordion>
      }
      {this.state.steps[2] &&
        <Accordion
          title="Abfrage der Rechtsgebiete"
          isOpen={true}
        >
          <div className="form-group dd-form-section">
            <div className="form-group">
              <Textfield
                name="lawfields_question"
                type="text"
                label="Bitte formulieren Sie hier die Einleitung für die Schaltfläche/n zur Abfrage der Rechtsgebiete"
                handleChange={(e) => this.onChatChange(e)}
                defaultValue={chatbot.chat.lawfields_question ? chatbot.chat.lawfields_question : chatbot.chat.lawfields_question = "In welchem Rechtsgebiet brauchen Sie Beratung?"}
                errorHandler={this.errorHandler}
                isRequired={true}
              />
            </div>
            <p>Legen Sie hier die Rechtsgebiete fest, die im Chatbot zur Auswahl stehen sollen</p>
            <div className="form-group">
              <TagList
                addedTagsVisible={true}
                indexLabel="title_de"
                name="Rechtsgebiete"
                data={chatbot.chat.lawfields}
                availableData={this.state.company.lawfields}
                label="Rechtsgebiete, die im Chatbot angeboten werden sollen:"
                onUpdate={(assignedTags) => this.onSelectLawfield(assignedTags)}
                errorHandler={this.errorHandler}
                isRequired={true}
              />
            </div>

            {chatbot.chat.lawfields && chatbot.chat.lawfields.map((lawfield, index) =>  {
              return <div className="dd-form-section dd-form-section--white" key={index}>
                <div className="d-flex justify-content-between">
                  <div>{lawfield.title_de}</div>
                </div>

                <DndList
                  rows={this.lawfieldExtensionForLawfield(lawfield.id)}
                  lang={this.state.activeLanguage}
                  headlines={this.state.lectures.headlines}
                  fieldNames={this.state.lectures.fieldNames}
                  onChange={(rows)=>this.onLawfieldExtension(rows, lawfield)}
                  errorHandler={this.errorHandler}
                  isRequired={true}
                />
              </div>
            })
            }

          </div>

          <div className="form-group dd-form-section">
            <h5>Alternative zu den Rechtsgebieten</h5>
            <div className="form-group">
              <Textfield
                name="other_direction_I"
                type="text"
                label="Bitte eröffnen Sie hier dem Nutzer eine Option, falls sein Gesuch nicht in die von Ihnen vorgeschlagenen Rechtsgebiete fällt:"
                handleChange={(e) => this.onChatChange(e)}
                defaultValue={chatbot.chat.other_direction_I ? chatbot.chat.other_direction_I : chatbot.chat.other_direction_I = "Wir helfen Ihnen gerne auch bei anderen Rechtsproblemen weiter. Können Sie uns ein Stichwort nennen oder das Problem kurz beschreiben?"}
                errorHandler={this.errorHandler}
                isRequired={true}
                />
            </div>
            <div className="form-group">
              <Textfield
                name="other_direction_I_input_placeholder"
                type="text"
                label="Hinweis im Eingabefeld"
                handleChange={(e) => this.onChatChange(e)}
                defaultValue={chatbot.chat.other_direction_I_input_placeholder ? chatbot.chat.other_direction_I_input_placeholder : chatbot.chat.other_direction_I_input_placeholder = "Beschreiben Sie bitte kurz Ihr Anliegen."}
                errorHandler={this.errorHandler}
                isRequired={true}
              />
            </div>
          </div>

          <div className="form-group dd-form-section">
            <h5>Alternative zu den Untergruppen der Rechtsgebiete</h5>
            <div className="form-group">
              <Textfield
                name="other_direction"
                type="text"
                label="Bitte eröffnen Sie hier dem Nutzer eine Option, falls sein Gesuch nicht in die von Ihnen vorgeschlagenen Untergruppen der Rechtsgebiete fällt:"
                handleChange={(e) => this.onChatChange(e)}
                defaultValue={chatbot.chat.other_direction ? chatbot.chat.other_direction : chatbot.chat.other_direction = "Wir helfen Ihnen gerne auch bei anderen Untergruppen weiter. Können Sie uns ein Stichwort nennen oder das Problem kurz beschreiben?"}
                errorHandler={this.errorHandler}
                isRequired={true}
                />
            </div>
            <div className="form-group">
              <Textfield
                name="other_direction_input_placeholder"
                type="text"
                label="Hinweis im Eingabefeld"
                handleChange={(e) => this.onChatChange(e)}
                defaultValue={chatbot.chat.other_direction_input_placeholder ? chatbot.chat.other_direction_input_placeholder : chatbot.chat.other_direction_input_placeholder = "Beschreiben Sie bitte kurz Ihr Anliegen."}
                errorHandler={this.errorHandler}
                isRequired={true}
              />
            </div>
          </div>
        </Accordion>
      }
      {this.state.steps[3] &&
        <Accordion
          title="Kontaktmöglichkeiten"
          isOpen={true}
        >
          <div className="form-group dd-form-section">
            <div className="form-group">
              <Textfield
                name="contact_name"
                type="text"
                label="Erfragen Sie mit diesem Feld den Namen des Nutzers"
                handleChange={(e) => this.onChatChange(e)}
                defaultValue={chatbot.chat.contact_name ? chatbot.chat.contact_name : chatbot.chat.contact_name = "Danke. Bitte geben Sie Ihren Namen ein!"}
                errorHandler={this.errorHandler}
                isRequired={true}
              />
              <Textfield
                name="contact_name_input_placeholder"
                type="text"
                label="Hinweis im Eingabefeld"
                handleChange={(e) => this.onChatChange(e)}
                defaultValue={chatbot.chat.contact_name_input_placeholder ? chatbot.chat.contact_name_input_placeholder : chatbot.chat.contact_name_input_placeholder = "..."}
                errorHandler={this.errorHandler}
                isRequired={true}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="contact_request"
                type="text"
                label="Bitte formulieren Sie hier die Einleitung für die Schaltflächen"
                handleChange={(e) => this.onChatChange(e)}
                defaultValue={chatbot.chat.contact_request ? chatbot.chat.contact_request : chatbot.chat.contact_request = "Vielen Dank für Ihre Mitteilung. Wir melden uns so schnell wie möglich bei Ihnen. Wie können wir Sie am besten kontaktieren?"}
                errorHandler={this.errorHandler}
                isRequired={true}
              />
            </div>
            <div className="form-check">
              <Checkbox
                label="Kontakt per E-Mail"
                id="contact_email_enabled"
                selected={(chatbot.chat.contact_email_enabled)}
                onChange={(e) => this.onChatChangeToggle("contact_email_enabled")}
              />

              { chatbot.chat.contact_email_enabled &&
                <div className="form-group">
                  <Textfield
                    name="contact_email_question"
                    type="text"
                    label="Erfragen Sie mit diesem Feld die E-Mail-Adresse des Nutzers"
                    handleChange={(e) => this.onChatChange(e)}
              defaultValue={chatbot.chat.contact_email_question ? chatbot.chat.contact_email_question : chatbot.chat.contact_email_question = "Unter welcher E-Mai-Adresse erreichen wir Sie?"}
                    errorHandler={this.errorHandler}
                    isRequired={true}
                    />

                  <Textfield
                    name="contact_email_input_placeholder"
                    type="text"
                    label="Hinweis im Eingabefeld"
                    handleChange={(e) => this.onChatChange(e)}
                    defaultValue={chatbot.chat.contact_email_input_placeholder ? chatbot.chat.contact_email_input_placeholder : chatbot.chat.contact_email_input_placeholder = "..."}
                    errorHandler={this.errorHandler}
                    isRequired={true}
                  />

                </div>
              }

              <Checkbox
                label="Kontakt per Telefon"
                id="contact_telefon_enabled"
                selected={(chatbot.chat.contact_telefon_enabled)}
                onChange={(e) => this.onChatChangeToggle("contact_telefon_enabled")}
              />

              {chatbot.chat.contact_telefon_enabled &&

                <div className="form-group">
                  <Textfield
                    name="contact_telefon_question"
                    type="text"
                    label="Erfragen Sie mit diesem Feld die Telefonnummer des Nutzers"
                    handleChange={(e) => this.onChatChange(e)}
                    defaultValue={chatbot.chat.contact_telefon_question ? chatbot.chat.contact_telefon_question : chatbot.chat.contact_telefon_question = "Unter welcher Telefonnummer erreichen wir Sie am besten?"}
                    errorHandler={this.errorHandler}
                    isRequired={true}
                  />
                  <Textfield
                    name="contact_telefon_input_placeholder"
                    type="text"
                    label="Hinweis im Eingabefeld"
                    handleChange={(e) => this.onChatChange(e)}
                    defaultValue={chatbot.chat.contact_telefon_input_placeholder ? chatbot.chat.contact_telefon_input_placeholder : chatbot.chat.contact_telefon_input_placeholder = "..."}
                    errorHandler={this.errorHandler}
                    isRequired={true}
                  />
                  <Textfield
                    name="contact_telefon_thank_you_text"
                    type="text"
                    label="Formulieren Sie hier die Bestätigung des Eingangs der Telefonnumer und leiten Sie die Abfrage der Zeitfenster für die telefonische Kontaktaufnahme ein"
                    handleChange={(e) => this.onChatChange(e)}
                    defaultValue={chatbot.chat.contact_telefon_thank_you_text ? chatbot.chat.contact_telefon_thank_you_text : chatbot.chat.contact_telefon_thank_you_text = "Vielen Dank für Ihre Mitteilung. Wir melden uns so schnell wie möglich bei Ihnen. Wann können wir Sie am besten kontaktieren?"}
                    errorHandler={this.errorHandler}
                    isRequired={true}
                  />
                  <Textfield
                    name="contact_telefon_timing_first"
                    type="text"
                    label="1. Zeitraum"
                    placeholder="von 8 bis 12 Uhr"
                    handleChange={(e) => this.onChatChange(e)}
                    defaultValue={chatbot.chat.contact_telefon_timing_first ? chatbot.chat.contact_telefon_timing_first : chatbot.chat.contact_telefon_timing_first = "von 8 bis 12 Uhr"}
                  />
                  <Textfield
                    name="contact_telefon_timing_second"
                    type="text"
                    label="2. Zeitraum"
                    handleChange={(e) => this.onChatChange(e)}
                    defaultValue={chatbot.chat.contact_telefon_timing_second}
                  />
                  <Textfield
                    name="contact_telefon_timing_third"
                    type="text"
                    label="3. Zeitraum"
                    handleChange={(e) => this.onChatChange(e)}
                    defaultValue={chatbot.chat.contact_telefon_timing_third}
                  />
                  <Textfield
                    name="contact_telefon_timing_fourth"
                    type="text"
                    label="4. Zeitraum"
                    handleChange={(e) => this.onChatChange(e)}
                    defaultValue={chatbot.chat.contact_telefon_timing_fourth ? chatbot.chat.contact_telefon_timing_fourth : chatbot.chat.contact_telefon_timing_fourth = "jederzeit"}
                  />
                </div>
              }
            </div>
          </div>
        </Accordion>
      }
      {this.state.steps[4] &&
        <Accordion
          title="Erfolgsmeldung / Abschluss"
          isOpen={true}
        >
          <div className="form-group dd-form-section">
            <div className="form-group">
              <Textfield
                name="thank_you_message"
                type="text"
                label="Schließen Sie mit diesem Feld die Kontaktaufnahme ab und ermöglichen Sie optional den Aufruf einer beliebigen Seite aus Ihrem Angebot"
                handleChange={(e) => this.onChatChange(e)}
                defaultValue={chatbot.chat.thank_you_message ? chatbot.chat.thank_you_message : chatbot.chat.thank_you_message = "Alles klar. Vielen Dank für Ihre Anfrage. Wir melden uns sobald wir möglich bei Ihnen! Haben Sie Interesse an aktuellen Rechtsthemen?"}
                errorHandler={this.errorHandler}
                isRequired={true}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="thank_you_positive"
                type="text"
                label="Antwort"
                handleChange={(e) => this.onChatChange(e)}
                defaultValue={chatbot.chat.thank_you_positive ? chatbot.chat.thank_you_positive : chatbot.chat.thank_you_positive = "Ja, Bitte!"}
                errorHandler={this.errorHandler}
                isRequired={true}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="thank_you_positive_link"
                type="text"
                label="Wählen Sie hier einen Link zu weiterführenden Informationen (z.B. Ihre Fachbeiträge)"
                handleChange={(e) => this.onChatChange(e)}
                defaultValue={chatbot.chat.thank_you_positive_link ? chatbot.chat.thank_you_positive_link : chatbot.chat.thank_you_positive_link = "https://www.diro.eu"}
                errorHandler={this.errorHandler}
                isRequired={true}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="thank_you_positive_link_label"
                type="text"
                label="Geben Sie hier die zu verlinkende Linkbeschreibung"
                handleChange={(e) => this.onChatChange(e)}
                defaultValue={chatbot.chat.thank_you_positive_link_label ? chatbot.chat.thank_you_positive_link_label : chatbot.chat.thank_you_positive_link_label = "Artikel auf Diro.eu"}
              />
            </div>


            <div className="form-group">
              <Textfield
                name="thank_you_negative"
                type="text"
                label="Abschlussnachricht ohne eine Verlinkung zu weiterführenden Informationen"
                // placeholder="Nein Danke"
                handleChange={(e) => this.onChatChange(e)}
                defaultValue={chatbot.chat.thank_you_negative ? chatbot.chat.thank_you_negative : chatbot.chat.thank_you_negative = "Nein Danke!"}
                errorHandler={this.errorHandler}
                isRequired={true}
              />
            </div>
          </div>
        </Accordion>
      }
        <div className="d-flex flex-column align-items-center">
          {this.nextStepButton(chatbot) !== "true" ? this.nextStepButton(chatbot) : null}
          <div className="step-process mt-3">Schritt {this.state.activeStep} von {this.state.stepslength}</div>
        </div>

        <Editbar
          hasPublish={false}
          hasPreview={false}
          hasError={!hasError && this.nextStepButton(chatbot) === true ? false : true}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
        />
      </>
    )
  }
}

Chatbot.contextType = AppContext
export default Chatbot
