import React from "react";

import Icon from "../../atom/Icon/Icon";
import Overlay from "../../element/Overlay/Overlay";
import Textfield from "../../atom/Textfield/Textfield";
import ImageSelector from "../../module/ImageSelector/ImageSelector";
import PageSelector from "../PageSelector/PageSelector";
import { imagePathForFormat, getToken } from "../../../../lib/lib";
import { getPage, imagePath, getArticle, getProduct } from "../../../../data/api";


class BlockTextImageSlideEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      fileOverlayActive: false,
      preview: true,
    }
  }

  togglePreviewEditMode(e) {
    e.preventDefault()
    this.setState({
      preview: !this.state.preview
    })
  }

  openFileSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      fileOverlayActive: true
    })
  }

  // File Selector

  closeFileSelectorOverlay = () => {
    this.setState({
      fileOverlayActive: false
    })
  }

  handleFileSelect = (selectedFile, format) => {
    const item = this.props.item
    item.imageID   = selectedFile.id
    item.imagePath = selectedFile[imagePathForFormat(format)]
    item.fileName = selectedFile.filename
    item.imageFormat  = format

    this.saveContent(item)
    this.closeFileSelectorOverlay()

    const content = this.state.content

    this.setState({
      content: content
    })
  }

  // Page Selector

  renderSelectedPageLabel = () => {

    if (this.props.item.buttonURL) {
      return this.props.item.buttonURL
    } else if (this.props.item.pageID && this.state.page && this.state.page.title) {
      return `${this.state.page.title} (${this.state.page.navigation_title})`
    } else if (this.props.item.articleID && this.state.page && this.state.page.title) {
      return `${this.state.page.title}`
    }
  }

  renderSelectedPageDeleteButton = () => {
    return this.props.item.buttonURL || this.props.item.pageID
  }

  handlePageDelete = () => {
    const item = this.props.item
    item.buttonURL = ""
    item.pageID = ""
    item.articleID = ""
    this.setState({ page: {} })
    this.props.onChange(item)
  }

  openPageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      pageOverlayActive: true
    })
  }

  closePageSelectorOverlay = () => {
    this.setState({
      pageOverlayActive: false,
    })
  }

  handlePageSelect = (page) => {
    const item = this.props.item

    if (page.id) {
      item.pageID     = page.id
      item.pageTitle  = page.title
      item.pageType   = page.content_type
      item.buttonURL = ""
    } else {
      item.pageID = ""
      item.buttonURL = page.buttonURL
    }

    item.buttonTarget = page.buttonTarget

    this.props.onChange(item)
    this.closePageSelectorOverlay()
    this.setState({ page: page })
  }


  onChange = (event) => {
    const item = this.props.item
    item[event.target.name] = event.target.value
    this.saveContent(item)
  }

  saveContent = (item) => {
    let preview = this.state.preview
    if (this.props.item.newRow) {
      preview = !preview
    }

    this.setState({
      savedOnce: true,
      preview
    }, () => {
      this.props.onChange(item)
    })
  }

  async componentDidMount() {

    let page = {}

    if (this.props.item.pageID) {

      if (this.props.item.pageType === 'page') {
        page = await getPage(getToken(), this.props.item.pageID)
        this.setState({ page: page.page })
      } else if (this.props.item.pageType === 'article') {
        const page = await getArticle(getToken(), this.props.item.articleID)
        this.setState({ page: page.article })
      } else if (this.props.item.pageType === 'product') {
        const page = await getProduct(getToken(), this.props.item.pageID)
        this.setState({ page: page.product })
      }

    }

    // FIXME DEPRECATED
    if (this.props.item.articleID) {
      const page = await getArticle(getToken(), this.props.item.articleID)
      this.setState({ page: page.article })
    }

    if (this.props.item.imageID) {
      const imgPath = await imagePath(getToken(), this.props.item.imageID, this.props.item.imageFormat)
      this.setState({ imgPath })
    }
  }

  render() {

    let showPreview = this.state.preview
    if (this.props.item.newRow && !this.state.savedOnce) {
      showPreview = false
    }

    return (
      <>
        {showPreview ?
          <div className="dd-mini-block__preview">
            <div className="dd-mini-block__preview-item" onClick={(e) => this.togglePreviewEditMode(e)}>
              <div className="dd-mini-block__preview-image"></div>
              <div className="dd-mini-block__preview-title">{this.props.item.slideLabel}&nbsp;</div>
            </div>
          </div>

          :

          <div className="dd-mini-block__edit">
            <div className="dd-mini-block__close">
              <button className="dd-btn dd-btn--icon-only" onClick={(e) => this.togglePreviewEditMode(e)}>
                <Icon icon="close" />
              </button>
            </div>
            <div className="dd-block__edit-section">
              <div className="form-group" >

                <Textfield
                  className="form-control"
                  name="slideLabel"
                  handleChange={this.onChange}
                  defaultValue={this.props.item.slideLabel}
                  placeholder="Slide-Titel"
                />

                <textarea
                  className="form-control"
                  name="text"
                  onChange={(e) => this.onChange(e)}
                  defaultValue={this.props.item.text}
                  placeholder="Bitte tragen Sie hier Ihren Text ein."
                />
              </div>

              <div className="dd-link-selector">
                <div className="dd-link-selector__link">{this.props.item.fileName}</div>
                <button className="dd-btn dd-btn--primary" onClick={(e) => this.openFileSelectorOverlay(e, true)}>Bild auswählen </button>
              </div>

              {this.state.fileOverlayActive &&
                <Overlay closeOverlayCallback={() => this.closeFileSelectorOverlay()}>
                  <ImageSelector
                    selectImageCallback={(file, format) => this.handleFileSelect(file, format)}
                    fileTypes={["image"]}
                  />
                </Overlay>
              }
            </div>
            <div className="dd-block__edit-section dd-block__edit-section--last">
              <div className="form-group" >
                <div className="dd-link-selector">
                  <div className="dd-link-selector__link">
                    {this.renderSelectedPageDeleteButton() &&
                      <button className={"dd-btn dd-btn--danger dd-btn--icon-only dd-link-selector__delete"}
                        onClick={() => this.handlePageDelete()}>
                        <Icon icon="trash" />
                      </button>
                    }
                    {this.renderSelectedPageLabel()}
                  </div>
                  <button className="dd-btn dd-btn--primary dd-link-selector__button" onClick={(e) => this.openPageSelectorOverlay(e)}>
                    {this.renderSelectedPageDeleteButton() ?
                      <span>Linkziel ändern</span>
                      :
                      <span>Link hinzufügen</span>
                    }
                    </button>
                </div>
                {this.state.pageOverlayActive &&
                  <Overlay closeOverlayCallback={() => this.closePageSelectorOverlay()}>
                    <PageSelector
                      content={this.props.item}
                      selectPageCallback={(page) => this.handlePageSelect(page)}
                    />
                  </Overlay>
                }
              </div>

            </div>
          </div>
        }
      </>
    )
  }
}

export default BlockTextImageSlideEdit
