import React, { Component } from "react";

import RadioButton from "../Shared/atom/RadioButton/RadioButton";
import Textfield from "../Shared/atom/Textfield/Textfield";
import Checkbox from "../Shared/atom/Checkbox/Checkbox";


class PageTypeJob extends Component {
  render() {
    const background = this.props.pagetype.background || "primary"
    const shorthand = this.props.selectedLanguage

    return (
      <>
        <div className="form-group">
          <Textfield
            name={`kicker_${shorthand}`}
            type="text"
            isRequired={false}
            label="Dachzeile"
            handleChange={this.props.onChange}
            defaultValue={this.props.pagetype[`kicker_${shorthand}`]}
          />
        </div>

        <div className="form-group">
          <Textfield
            name={`headline_${shorthand}`}
            type="text"
            label="Headline"
            handleChange={this.props.onChange}
            defaultValue={this.props.pagetype[`headline_${shorthand}`]}
          />
        </div>

        <div className="form-group">
          <Textfield
            name={`related_items_headline_${shorthand}`}
            type="text"
            label="Überschrift 'Related Items'"
            handleChange={this.props.onChange}
            defaultValue={this.props.pagetype[`related_items_headline_${shorthand}`]}
          />
        </div>

        <div className="form-group">
          <Textfield
            name={`related_items_button_text_${shorthand}`}
            type="text"
            label="Beschriftung der Verlinkung zur Übersichtsseite"
            handleChange={this.props.onChange}
            defaultValue={this.props.pagetype[`related_items_button_text_${shorthand}`]}
          />
        </div>

        <div className="form-group">
          <Textfield
            name={`overview_path_${shorthand}`}
            type="text"
            label="URL-Pfad der Übersichtsseite"
            handleChange={this.props.onChange}
            defaultValue={this.props.pagetype[`overview_path_${shorthand}`]}
          />
        </div>

        {/*
        <div className="form-group">
          <label className="textfield__label">Einleitungstext</label>
          <textarea
            className="form-control"
            name={`teaser_${shorthand}`}
            defaultValue={this.props.pagetype[`teaser_${shorthand}`]}
            onChange={(e) => this.props.onChange(e)}
          />
        </div>
        */}

        <div className="form-group">
          <div className="form-check form-check-inline">
            <label className="radio-button__group-label text-nowrap ">Hintergrundfarbe</label>

            <RadioButton
              name="background"
              label="Primärfarbe"
              value="primary"
              handleChange={this.props.onChange}
              selected={background === "primary"}
            />
            <RadioButton
              name="background"
              label="Sekundärfarbe"
              value="secondary"
              handleChange={this.props.onChange}
              selected={background === "secondary"}
            />

          </div>
        </div>

        <div className="form-group">
          <div className="form-check form-check-inline">
            <Checkbox
              label="Icon zeigen"
              id="icon_visible"
              selected={this.props.pagetype.icon_visible}
              onChange={(e) => this.props.onCheckboxChange(e, "icon_visible")}
            />
          </div>
        </div>
      </>
    );
  }
}

export default PageTypeJob;
