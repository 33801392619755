import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Loader from "../Shared/atom/Loader/Loader";
import SimpleSelectfield from "../Shared/atom/Selectfield/SimpleSelectfield";
import Editbar from "../Shared/element/Editbar/Editbar";

import PageTypeArticle from "./PageTypeArticle";
import PageTypeEmployee from "./PageTypeEmployee";
import PageTypeJob from "./PageTypeJob";
import PageTypeEvent from "./PageTypeEvent";

import { AppContext } from "../../data/ContextProvider";
import { getPagetype, updatePagetype, getAsset, getSyslanguages } from "../../data/api";
import { getToken, imagePathForFormat } from "../../lib/lib";


class PageTypeEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      imageOverlayActive: false,
      showConfirmAvatarDelete: false,
      teaserPath: "",
      selectedLanguage: "de"
    }
  }

  onContactLanguageChange = (syslanguageId) => {
    this.setState({
      selectedLanguage: syslanguageId
    })
  }

  onChange = (event) => {
    const pagetype = this.state.pagetype
    pagetype[event.target.name] = event.target.value
    this.setState({ pagetype }, () => {
      //this.validateField(name, value)
    })
  }

  openImageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      imageOverlayActive: true
    })
  }

  closeImageSelectorOverlay = () => {
    this.setState({
      imageOverlayActive: false,
    })
  }

  handleImageSelect = (file, format) => {
    const path = file[imagePathForFormat(format)]
    this.setState({
      teaserPath: path,
      imageOverlayActive: false,
      showConfirmAvatarDelete: false,
    }, () => {
        const pagetype = this.state.pagetype
        pagetype.teaserimage_id     = file.id
        pagetype.teaserimage_format = format
        this.setState({ pagetype })
    })
  }

  handleImageDelete = () => {
    this.setState({
      teaserPath: "",
    }, () => {
      const pagetype = this.state.pagetype
      pagetype.teaserimage_id = 0
      pagetype.teaserimage_format = ""
      this.setState({ pagetype })
    })
  }

  onSettingsChange = (event) => {
    this.onSettingsValueChange(event.target.name, event.target.value)
  }

  onSettingsValueChange = (field, value) => {
    const pagetype = this.state.pagetype
    pagetype.settings[field] = value
    this.setState({ pagetype }, () => {
      //this.validateField(name, value)
    })
  }

  onCheckboxChange = (e, field) => {
    const pagetype = this.state.pagetype
    pagetype[field] = !pagetype[field]
    this.setState({ pagetype })
  }

  onSubmit = () => {
    this.setState({ isUpdating: true })

    updatePagetype(getToken(), this.state.pagetype.id, this.state.pagetype)
      .then(updatedArticle => {
      })
      .catch(err => {
        this.setState({ isUpdating: false })
      })
  }

  languageSelectOptions = () => {
    const out = []
    this.state.syslanguages.forEach((syslanguage) => {
      out.push({
        id: syslanguage.shorthand,
        language: syslanguage.shorthand
      })
    })
    return out
  }

  loadData = async () => {
    try {
      const pagetypeData = await getPagetype(getToken(), this.state.pagetypeID)
      const syslanguages = await getSyslanguages(getToken())
      const pagetype = pagetypeData.pagetype
      let teaserPath = ""

      if (pagetype.teaserimage_id && pagetype.teaserimage_id > 0) {
        try {
          const teaser = await getAsset(getToken(), pagetype.teaserimage_id)
          teaserPath = teaser.asset[imagePathForFormat(pagetype.teaserimage_format)]
        } catch (error) {
          console.log("teaserimage_id nicht gefunden:", pagetype.teaserimage_id)
        }
      }


      this.setState({
        ...this.state,
        pagetype: pagetype,
        loading: false,
        syslanguages: syslanguages.syslanguages,
        teaserPath
      })
    } catch (error) {
      //FIXME NotFound
      console.log(error)
    }
  }

  async componentDidMount() {
    if (this.props.match && this.props.match.params) {
      this.setState({
        ...this.state,
        pagetypeID: this.props.match.params.pagetype_id,
      }, async () => {
        await this.loadData()
      })
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    let teaserPath = ""
    if (this.state.pagetype.teaserimage_id === 0) {
      teaserPath = ""
    } else if (this.state.teaserPath !== "") {
      teaserPath = this.state.teaserPath
    } else {
      teaserPath = this.state.pagetype.teaserPath
    }

    return (
      <div className="dd-card">
        <h2 className="dd-card__title">Seitentyp: {this.state.pagetype.title}</h2>

        <div className="card-body">
          <div className="dd-form-section dd-form-section--combined dd-form-section--first">

              <SimpleSelectfield
                name="contactLanguageSelect"
                label={false}
                indexLabel="id"
                optionLabel="language"
                selected={this.state.selectedLanguage}
                options={this.languageSelectOptions()}
                onSelect={(value) => this.onContactLanguageChange(value)}
              />


            {this.state.pagetype.pagetype === 'article' &&
              <PageTypeArticle
                key={this.state.selectedLanguage}
                pagetype={this.state.pagetype}
                selectedLanguage={this.state.selectedLanguage}
                teaserPath={teaserPath}
                imageOverlayActive={this.state.imageOverlayActive}
                onChange={this.onChange}
                onCheckboxChange={this.onCheckboxChange}
                openImageSelectorOverlay={this.openImageSelectorOverlay}
                handleImageDelete={this.handleImageDelete}
                handleImageSelect={this.handleImageSelect}
              />
            }

            {this.state.pagetype.pagetype === 'employee' &&
              <PageTypeEmployee
                key={this.state.selectedLanguage}
                pagetype={this.state.pagetype}
                selectedLanguage={this.state.selectedLanguage}
                onChange={this.onChange}
                onSettingsValueChange={this.onSettingsValueChange}
                onCheckboxChange={this.onCheckboxChange}
              />
            }

            {this.state.pagetype.pagetype === 'job' &&
              <PageTypeJob
                key={this.state.selectedLanguage}
                pagetype={this.state.pagetype}
                selectedLanguage={this.state.selectedLanguage}
                onChange={this.onChange}
                onCheckboxChange={this.onCheckboxChange}
              />
            }

            {this.state.pagetype.pagetype === 'event' &&
              <PageTypeEvent
                key={this.state.selectedLanguage}
                pagetype={this.state.pagetype}
                selectedLanguage={this.state.selectedLanguage}
                onChange={this.onChange}
                onCheckboxChange={this.onCheckboxChange}
              />
            }

          </div>
          <Editbar
            hasPublish={false}
            hasPreview={false}
            onSave={this.onSubmit}
            successMessage="Erfolgreich gespeichert"
          />
        </div>
      </div>
    );
  }
}

PageTypeEdit.contextType = AppContext
export default withRouter(PageTypeEdit);
