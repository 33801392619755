import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Tablist from '../Shared/module/Tablist/Tablist'


class PageEditTabs extends Component {

  //FIXME
  dummy = () => {
  }

  render() {
    const lang = this.props.match.params.lang
    const page_id = this.props.match.params.page_id

    const tabs = []

    if (this.props.page.pagetype === "page") {
      tabs.push({ title: "Beitragstext", path: `/${lang}/pages/${page_id}/content` })
    }

    if (this.props.page.pagetype === "employee") {
      tabs.push({ title: "Panoramabild", path: `/${lang}/pages/${page_id}/content` })
    }

    tabs.push({ title: "Meta-Daten", path: `/${lang}/pages/${page_id}/meta` })

    tabs.push({ title: "Veröffentlichung", path: `/${lang}/pages/${page_id}/publishing` })


    return (
      <Tablist
        additionalClass="w-75 ml-auto mr-auto pt-4 d-flex justify-content-between"
        tabs={tabs}
        active={this.props.activeTab}
        tabChange={this.dummy}
      />
    );
  }
}

export default withRouter(PageEditTabs);
