
import React from "react";

import Icon from "../../atom/Icon/Icon";
import Loader from "../../atom/Loader/Loader";
import RadioButton from "../../atom/RadioButton/RadioButton";
// import DnDRows from "../../../Shared/module/DnDRows/DnDRows";
import SimpleSelectfield from "../../atom/Selectfield/SimpleSelectfield";
import Textfield from "../../atom/Textfield/Textfield";
// import Checkbox from "../../atom/Checkbox/Checkbox";

import BlockPreviewMap from './BlockPreviews/Map';
import BlockMapLocationEdit from './BlockMapLocationEdit';
import BlockSectionSettings from './BlockSectionSettings';

import { AppContext } from '../../../../data/ContextProvider'

import { createEditor } from "./util";
import { getCompany, getTerms } from "../../../../data/api";
import { getToken } from "../../../../lib/lib";
import TagList from "../../atom/TagList/TagList";
import { ImagePreviewBlock } from "../../element/ImagePreview/ImagePreview";
import { Link, withRouter } from "react-router-dom";
import Overlay from "../../element/Overlay/Overlay";
import ImageSelector from "../ImageSelector/ImageSelector";
// import { createID } from "../../../../lib/lib";
import BlockActions from "./BlockActions";

class BlockDiroMapEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sites: [],
      zoomsteps: [
        { id: 3.5, option: "500km (sehr weit entfernt)" },
        { id: 4, option: "350km" },
        { id: 4.25, option: "300km" },
        { id: 4.5, option: "250km" },
        { id: 4.75, option: "200km" },
        { id: 5, option: "150km" },
        { id: 6, option: "100km" },
        { id: 7, option: "50km" },
        { id: 8, option: "30km" },
        { id: 9, option: "10km" },
        { id: 10, option: "5km" },
        { id: 11, option: "3km" },
        { id: 12, option: "2km" },
        { id: 13, option: "750m" },
        { id: 14, option: "500m" },
        { id: 15, option: "250m" },
        { id: 16, option: "100m" },
        { id: 17, option: "50m" },
        { id: 18, option: "25m (sehr nah)" },
      ],
      content: {},
      preview: true,
      loading: true,
      showSettings: false,
      lawfields: [],
      jobtitles: [],
    }
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  onChangeType = (event, name) => {
    const content = this.state.content
    const key = (typeof name !== 'undefined') ? name : event.target.name
    content[key] = event.target.value
    if (content[key] === "kanzlei") { content.jobtitles = [] };
    if (content[key] === "person") { content.lawfields = [] };
    this.saveContent(content)
  };

  onChangeEditor = (key, value) => {
    const content = this.state.content
    content.editor.content = value
    this.saveContent(content)
  }

  onRowChange = (row, index) => {
    const content = this.state.content

    if (index < content.items.length ) {
      row.newRow = false
      content.items[index] = row
      this.saveContent(content)
    }
  }

  onRowsChange = (rows) => {
    const content = this.state.content
    content.items = rows
    this.saveContent(content)
  }

  onCheckboxChange = (e, field) => {
    const content = this.state.content
    content[field] = !this.state.content[field]
    this.saveContent(content)
  }


  onChange = (event, name) => {
    const content = this.state.content
    const key = (typeof name !== 'undefined') ? name : event.target.name
    content[key] = event.target.value
    this.saveContent(content)
  }

  onUpdate = (name, value) => {
    const content = this.state.content
    content[name] = value
    this.saveContent(content)
  }

  onSelect = (name, value) => {
    const content = this.state.content
    const key = (typeof name !== 'undefined') ? name : value.target.name
    content[key] = value
    this.saveContent(content)
  }

  openImageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      imageOverlayActive: true
    })
  }

  closeImageSelectorOverlay = () => {
    this.setState({
      imageOverlayActive: false,
    })
  }

  handleImageSelect = (file, format) => {
    const content = this.state.content
    content.imagePath = file.file_path
    content.imageID   = file.id
    content.imageFormat  = format

    this.saveContent(content)
    this.closeImageSelectorOverlay()
  }

  handleImageDelete = () => {
    const content = this.state.content
    content.imageID = 0
    content.imageFormat = ""
    content.imagePath = ""
    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  prepopulateSites = (company) => {
    const sites = []
    sites.push({
      id: company.id,
      option: company.city.title_de
    })

    company.company_sites.forEach(site => {
      sites.push({
        id: site.id,
        option: site.city.title_de
      })
    })

    return sites
  }

  renderBlockContent = () => {
    const content = { ...this.state.content }
    if (this.state.editorSetup) {
      return createEditor(this.props.index, "default", this.onChangeEditor, content.editor.content)
    } else {
      return []
    }
  }

  loadData = async () => {
    const company = await getCompany(getToken(), this.context.company.id)

    this.setState({
      ...this.state,
      loading: false,
      company: company.company,
      sites: this.prepopulateSites(company.company),
    })
  }

  adjustContent = (content) => {
    if (!content.editor) {
      content.editor = {
        editor: "",
        content: [{
          type: "default",
          children: [
            { text: "" }
          ],
        }],
        type: "",
      }
    }

    // init editor
    content.editor.editor = createEditor(this.props.index, "default", this.onChangeEditor, content.editor.content)

    return content
  }


  async componentDidMount() {
    const lawfields = await getTerms(getToken(), 'lawfields')
    const jobtitles = await getTerms(getToken(), 'jobtitles')
    this.setState({
      lawfields: lawfields.lawfields,
      jobtitles: jobtitles.jobtitles
    })
    if (this.props.initData) {
      const preview = this.props.preview
      let content = this.props.initData[0]
      content = this.adjustContent(content)

      this.setState({
        preview,
        content,
        loading: false,
        editorSetup: true
      }, async () => {
        await this.loadData()
      })
    }
  }

  renderPreviews = () => {
    const previews = []
    if (this.state.content.items) {
      this.state.content.items.map((item, index) => {
        return previews.push(
          <BlockMapLocationEdit
            item={item}
            sites={this.state.sites}
            key={item.key}
            onChange={(val) => this.onRowChange(val, index)}
          />
        )
      })
    }
    return previews
  }

  render() {
    const lang = this.props.match.params.lang

    if (this.state.loading) {
      return <Loader />
    }

    const labels     = { de: 'title_de', en: 'title_en', fr: 'title_fr', it: 'title_it'}
    const indexLabel = labels[this.context.lang] || 'title_en'
    const zoomstep   = this.state.content.zoom || 10
    const mapcolor   = "mapcolor__" + this.props.index
    const mapMode    = "mapMode__" + this.props.index
    const maplat     = "maplat__" + this.props.index
    const maplong    = "maplng__" + this.props.index
    const lawfields  = "lawfields__" + this.props.index
    const jobtitles  = "jobtitles__" + this.props.index

    return (
      <React.Fragment>
        {
          this.state.preview
          ? <div className="dd-block dd-block--default" onClick={() => this.togglePreviewEditMode()}>
            <div className="dd-block__preview">
              <BlockPreviewMap block={this.state.content}  isDiro={true} />
            </div>
          </div>
          :
          <div className="dd-block dd-block--default dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-location" /> DIRO-Kanzleien Standort-Karte
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }
              <div className="dd-block__edit-section">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <label className="radio-button__group-label text-nowrap ">Kartendarstellung</label>
                    <RadioButton
                      name={mapcolor}
                      label="schwarzweiss"
                      value="monochrome"
                      handleChange={(event) => this.onChange(event, 'mapcolor')}
                      selected={this.state.content.mapcolor === 'monochrome'}
                      />
                    <RadioButton
                      name={mapcolor}
                      label="farbig"
                      value="color"
                      handleChange={(event) => this.onChange(event, 'mapcolor')}
                      selected={this.state.content.mapcolor === 'color' || !this.state.content.mapcolor}
                    />
                  </div>
                </div>


               <React.Fragment>
                  <div className="form-group">
                    <Textfield
                      name={maplat}
                      label="Kartenausschnitt (Position)"
                      type="text"
                      placeholder="Latitude"
                      handleChange={(event) => this.onChange(event, 'maplat')}
                      defaultValue={this.state.content.maplat}
                    />
                    <Textfield
                      name={maplong}
                      type="text"
                      placeholder="Longitude"
                      handleChange={(event) => this.onChange(event, 'maplong')}
                      defaultValue={this.state.content.maplong}
                    />
                  </div>
                  <div className="form-group">
                    <SimpleSelectfield
                      label="Zoomstufe – 1cm auf der Karte entspricht ca."
                      name="zoom"
                      firstOptionValue=""
                      firstOptionLabel="Bitte auswählen"
                      indexLabel="id"
                      optionLabel="option"
                      options={this.state.zoomsteps}
                      onSelect={(value) => this.onSelect('zoom', value)}
                      selected={zoomstep}
                    />
                  </div>
                  </React.Fragment>
                    <div className="form-group">
                      <div className="form-check form-check-inline">
                        <label className="radio-button__group-label text-nowrap ">
                          Kartenart auswählen
                        </label>
                        <RadioButton
                          name={mapMode}
                          label="Kanzleikarte"
                          value="kanzlei"
                          handleChange={(event) => this.onChangeType(event, 'mapMode')}
                          selected={this.state.content.mapMode === "kanzlei" || !this.state.content.mapMode}
                          />
                        <RadioButton
                          name={mapMode}
                          label="Personenkarte"
                          value="person"
                          handleChange={(event) => this.onChangeType(event, 'mapMode')}
                          selected={this.state.content.mapMode === "person"}
                        />
                      </div>
                    </div>
                  <React.Fragment>
                    {(this.state.content.mapMode === "kanzlei" || !this.state.content.mapMode) &&
                    <div className="form-group">
                      <label className="radio-button__group-label text-nowrap" >Kanzleien folgender Rechtsgebiete werden angezeigt</label>
                      <div className="dd-form-section dd-form-section--small dd-form-section--combined">
                        <div className="dd-form-section__content">
                          <div className="d-flex flex-column dd_tag__row">
                            <div className="row">
                              <div className="col-md-4 d-flex justify-content-md-end align-items-center">
                                <label className="mb-0" htmlFor="Rechtsgebiete">
                                  Rechtsgebiete
                                </label>
                              </div>
                              <div className="col-md-8 d-flex align-items-center">
                                <TagList
                                  indexLabel={indexLabel}
                                  name={lawfields}
                                  data={this.state.content.lawfields}
                                  availableData={this.state.lawfields}
                                  onUpdate={(assignedTags) => this.onUpdate('lawfields', assignedTags)}
                                  />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                  </React.Fragment>
                  <React.Fragment>
                    {this.state.content.mapMode === "person" &&
                    <div className="form-group">
                      <label className="radio-button__group-label text-nowrap" >Kanzleien folgender Rechtsgebiete werden angezeigt</label>
                      <div className="dd-form-section dd-form-section--small dd-form-section--combined">
                        <div className="dd-form-section__content">
                          <div className="d-flex flex-column dd_tag__row">
                            <div className="row">
                              <div className="col-md-4 d-flex justify-content-md-end align-items-center">
                                <label className="mb-0" htmlFor="Ausbildung/Berufsbezeichnung">
                                Ausbildung/Berufsbezeichnung
                                </label>
                              </div>
                              <div className="col-md-8 d-flex align-items-center">
                                <TagList
                                  indexLabel={indexLabel}
                                  name={jobtitles}
                                  data={this.state.content.jobtitles}
                                  availableData={this.state.jobtitles}
                                  onUpdate={(assignedTags) => this.onUpdate('jobtitles', assignedTags)}
                                  />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                  </React.Fragment>

                {/* <div className="form-group">
                  <label className="radio-button__group-label text-nowrap ">Zusätzlicher Hinweistext</label>
                  {this.renderBlockContent()}
                </div> */}

              </div>

              <div className="dd-block__edit-section">
                <h5>Datenschutz</h5>
                <p className="hint-text">
                  Dieses Modul bindet einen Inhalte mithilfe eines externen Dienstes ein, der erst nach
                  Zustimmung des Nutzers geladen werden darf. Erteilt der Nutzer keine globale Zustimmung im
                  entsprechenden Popup beim ersten Seitenaufruf, muss der Aufruf des Inhalts blockiert werden.
                  Anstelle der DIRO-Kanzleien Standort-Karte wird dann ein Platzhalter-Bild und ein Button zum Laden der DIRO-Kanzleien Standort-Karte angezeigt (Zwei-Klick-Lösung).
                  Die Texte für Anfragen zur Zustimmung und den Button können Sie im Admin-Bereich unter <Link to={`/${lang}/admin/tracking#openstreetmap`}>Tracking und externe Dienste</Link> bearbeiten.
                </p>
                <div className="row">
                  <div className="col">

                  <label className="textfield__label">Platzhalter-Bild vor Anzeige des Inhaltes</label>
                    <p className="hint-text">Dieses Bild wird angezeigt, wenn der Besucher keine Zustimmung zur Anzeige des externen Inhaltes erteilt hat.</p>

                    <div className="dd-image-picker">
                      {this.state.content.imagePath ?
                        <ImagePreviewBlock
                          imagePath={this.state.content.imagePath}
                          imageId={this.state.imageId}
                          handleImageDelete={this.handleImageDelete}
                          openImageSelectorOverlay={this.openImageSelectorOverlay}
                        />
                        :
                        <button className="dd-btn dd-btn--primary dd-image-picker__choose" onClick={(e) => this.openImageSelectorOverlay(e)}>
                          Bild auswählen
                        </button>
                      }
                    </div>

                    {this.state.imageOverlayActive &&
                      <Overlay closeOverlayCallback={() => this.closeImageSelectorOverlay()}>
                        <ImageSelector
                          selectImageCallback={(file, format) => this.handleImageSelect(file, format)}
                          fileTypes={["image"]}
                          selectedAssetId={this.state.content.imageID || ''}
                          selectedFormat={this.state.content.imageFormat || ''}
                          allowedFormats={[
                            "original",
                            "five_by_two",
                            "sixteen_by_nine"
                          ]}
                        />
                      </Overlay>
                    }
                  </div>
                </div>
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}

BlockDiroMapEdit.contextType = AppContext
export default withRouter(BlockDiroMapEdit);
