import React from "react";
import Icon from "../../../Shared/atom/Icon/Icon";

class LangChooser extends React.Component {

  renderLi = (key, shorthand, lang) => {
    if (shorthand === lang) {
      return <li key={key} className="selected">{shorthand}</li>

    } else if (this.props.enableTranslations) {
      return <li key={key} className="inactive">
        {this.props.translationFor}
        <button type="submit" className="dd-btn dd-btn--primary dd-btn--icon-only" onClick={() => this.props.onTranslate(this.props.translationFor)}>
          <Icon icon="plus" />
        </button>
      </li>
    } else {
      return <li key={key} onClick={() => this.props.onSelect(shorthand)}>{shorthand}</li>
    }
  }

  render() {
    return (
      <ul className="languages-switcher">
        {this.props.syslanguages.map((language, index) => {
          return this.renderLi( index, language.shorthand, this.props.lang)
        })
        }
      </ul>
    )
  }
}

export default LangChooser;