import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Loader from "../Shared/atom/Loader/Loader";
import PersonTeaser from "./PersonTeaser";
import PersonsFilter from "./PersonsFilter";
import OfficesEditTabs from "../../components/Offices/OfficesEditTabs";
import DeleteConfirmOverlay from "../Shared/module/Overlay/DeleteConfirmOverlay"

import { AppContext } from '../../data/ContextProvider'

import { getCompany, updatePerson, deletePerson } from "../../data/api";
import { getToken } from "../../lib/lib";

class PersonsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false,
      confirmMode: "delete",
      personToDelete: 0,
      companyID: 0,
      company: {},
      users: [],
      locations: []
    }
  }

  closeOverlay = () => {
    this.setState({
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false,
    })
  }

  showDeleteConfirmation = (person) => {
    this.setState({
      deleteConfirmOverlay: true,
      personToDelete: person.id,
      confirmMode: "delete"
    })
  }

  showPublishConfirmation = (e, person) => {
    e.preventDefault()
    this.setState({
      hideConfirmOverlay: true,
      personToHide: person.id,
      confirmMode: (!person.hidden) ? "hide" : "show"
    })
  }

  updatePersonVisualOrder = async (person, order) => {
    const index = this.state.users.findIndex(s => s.id === person.id)
    if (index) {
      const user = this.state.users[index]
      user.visual_order = order
      await updatePerson(getToken(), user.id, user)
      await this.loadData()
    }
  }

  handlePublication = async () => {

    const index = this.state.users.findIndex(s => s.id === this.state.personToHide)
    const user = this.state.users[index]

    user.hidden = !user.hidden

    this.setState({
      isUpdating: true,
    }, () => {
        updatePerson(getToken(), user.id, user)
        .then(async updatedUser => {
          this.setState({ hideConfirmOverlay: false }, ()=> {
            this.loadData()
          })
        })
        .catch(err => {
          this.setState({ isUpdating: false })
        })
    })
  }

  onDeleteConfirmation = async () => {

    const personToDelete = this.state.personToDelete

    if (personToDelete) {
      this.setState({
        isUpdating: true,
      }, () => {
          deletePerson(getToken(), personToDelete)
          .then(async updatedUser => {
            await this.loadData()
          })
          .catch(err => {
            this.setState({ isUpdating: false })
          })
      })
    }
  }


  handleFilter = (location, index) => {
    const locations = this.state.locations
    locations[index].selected = !locations[index].selected

    const filterLocations = []
    locations.map((location) => {
      if (location.selected) {
        filterLocations.push(location.city)
      }
      return ''
    })

    let users = []
    if (locations.length === filterLocations.length) {
      users = this.state.company.all_employees
    }
    else {
      users = this.state.company.all_employees.filter(this.checkLocation.bind(this, filterLocations))
    }

    this.setState({ locations, users })
  }


  checkLocation = (filterLocations, user) => {
    let status = false

    if (user.company_sites.length < 1) {
      if (filterLocations.includes(this.state.company.city.title_de)) {
        return true
      }
    }

    user.company_sites.map((userLocation) => {
      if (filterLocations.includes(userLocation.city.title_de)) {
        status = true
      }
      return ''
    })
    return status
  }


  getLocations = (company) => {
    const locations = []
    company.company_sites.map((location) => {
      let city_title = (location.city) ? location.city.title_de : ""
      return locations.push({
        'city': city_title,
        'selected': true
      })
    })

    return locations
  }


  renderRow(row) {
    return row.map((person, key) => {
      return <div key={key} className="col-12 col-sm-6 col-md-4 col-xl-2">
        <PersonTeaser
          person={person}
          companyID={person.companyID}
          isAddNew={person.isAddNewPerson ? person.isAddNewPerson : false}
          showDeleteConfirmation={this.showDeleteConfirmation}
          showPublishConfirmation={this.showPublishConfirmation}
          updatePersonVisualOrder={this.updatePersonVisualOrder}
        />
      </div>
    })
  }

  loadData = async () => {
    try {
      const company = await getCompany(getToken(), this.state.companyID)
      const locations = this.getLocations(company.company)

      this.setState({
        ...this.state,
        loading: false,
        users: company.company.all_employees,
        company: company.company,
        locations
      })
    } catch (error) {
      console.log(error)
    }
  }

  async componentDidMount() {
    let officeid = 0

    if (this.props.match && this.props.match.params) {
      officeid = this.props.match.params.officeid
    }

    if (officeid) {
      this.setState({
        ...this.state,
        companyID: officeid,
      }, async () => {
        await this.loadData()
      })
    }
  }

  render() {

    if (this.state.loading) {
      return <Loader />
    }

    // if (!this.state.loading) {
    let singlerow = [];
    const rows = [];
    const rowSize = 6;
    let appendAddNewToRow = false;
    let appendRow = [];

    if (this.state.users.length % 6 === 0) {
      appendAddNewToRow = true;
    }

    if (this.state.users.length === 0) {

      appendRow.push({
        isAddNewPerson: true,
        company_id: this.state.companyID
      });
      rows.push(appendRow);

    } else {
      this.state.users.forEach((person, index) => {
        if (index % rowSize === 0) {
          rows.push(singlerow)
          singlerow = []
        }
        singlerow.push(person)
        if (index + 1 === this.state.users.length) {

          if (appendAddNewToRow) {
            // Rest nicht vergessen
            rows.push(singlerow);

            appendRow.push({
              isAddNewPerson: true,
              company_id: this.state.companyID
            });
            rows.push(appendRow);
          } else {
            singlerow.push({
              isAddNewPerson: true,
              company_id: this.state.companyID
            });
            rows.push(singlerow);
          }
        }
      })
    }

    return (
      <React.Fragment>
        <OfficesEditTabs
          headline={`Kanzlei ${this.state.company.name}`}
          officeid={this.state.companyID}
        />
        <div className="tab-pane fade show active">
          <PersonsFilter
            locations={this.state.locations}
            onChange={this.handleFilter}
          />
          <div className="dd-person-container">
            {rows.map((row, key) => {
              return <div key={key} className="row">
                {this.renderRow(row, key)}
              </div>
            })
            }
          </div>
        </div>

        {this.state.deleteConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.onDeleteConfirmation}
            mode={this.state.confirmMode}
          />
        }
        {this.state.hideConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.handlePublication}
            mode={this.state.confirmMode}
          />
        }
      </React.Fragment>
    );
  }
}

PersonsList.contextType = AppContext
export default withRouter(PersonsList);
