import React from "react";

import { withRouter } from "react-router-dom";

import PersonCoreData from "./PersonCoreData";
import Accordion from "../Shared/module/Accordion/Accordion";
import Editbar from "../Shared/element/Editbar/Editbar";
import OfficesEditTabs from "../../components/Offices/OfficesEditTabs";

import {  createPerson, getTerms, getCompany} from "../../data/api";
import { getToken } from "../../lib/lib";

class PersonNew extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      loading: true,
      isOpenTab: 0,
      personID: 0,
      person: {},
      academictitles: [],
      accordions: [
        {
          title: "Angaben zur Person",
          isOpen: true
        },
      ]
    }
  }

  errorHandler = (hasError=false) => {
    this.setState({ hasError })
  }

  changeValue = (fieldname, value) => {
    const person = { ...this.state.person }
    person[fieldname] = value
    this.setState({ person })
  }

  onAvatarUpdate = (avatar_id, avatar_format) => {
    const person = { ...this.state.person }
    person.avatar_id = avatar_id
    person.avatar_format = avatar_format
    this.setState({ person }, () => {
    })
  }

  onChange = (event) => {
    this.changeValue(event.target.name, event.target.value)
  }

  onSelectSalutation = (value) => {
    this.changeValue("salutation", value)
  }

  onSelectAcademictitle = (value) => {
    this.changeValue("academictitle_id", value)
  }

  sitesUpdate = (assignedTags) => {
    const person = { ...this.state.person }
    person.company_sites = assignedTags
    this.setState({ person })
  }

  onSave = async () => {
    this.setState({ isUpdating: true })

    const person = { ...this.state.person }

    person.company_id = this.state.company.id

    person.company_site_ids = []

    person.company_sites.forEach((tag) => {
      person.company_site_ids.push(tag.id)
    })

    createPerson(getToken(), person)
    .then(newPerson => {
      const lang = this.props.match.params.lang
      this.props.history.push(`/${lang}/offices/${this.state.company.id}/team/${newPerson.person.id}`);
    })
    .catch(err => {
      this.setState({ isUpdating: false })
    })

    await this.loadData()
  }

  loadData = async () => {

    const { company } = await getCompany(getToken(), this.state.companyID)
    const academictitles = await getTerms(getToken(), 'academictitles')
    const company_sites = []

    if (company.company_sites.length === 1) {
      company_sites.push(company.company_sites[0])
    }

    this.setState({
      ...this.state,
      loading: false,
      company,
      academictitles: academictitles.academictitles,
      person: {
        company_sites
      }
    })
  }

  async componentDidMount() {
    let officeid = 0

    // DIRO
    if (this.props.match && this.props.match.params) {
      officeid = this.props.match.params.officeid
    }

    // Kanzlei
    if (this.context.company) {
      officeid = this.context.company.id
    }

    if (officeid) {
      this.setState({
        ...this.state,
        companyID: officeid,
      }, async () => {
        await this.loadData()
      })
    }
  }

  render() {
    if (this.state.loading) {
      return "loading.."
    }

    const formValid = (this.state.person.firstname && this.state.person.lastname) ? true : false
    const hasError = (this.state.hasError || !formValid) ? true : false

    return (
      <>
        <OfficesEditTabs
          headline={`Kanzlei ${this.state.company.name}`}
          officeid={this.state.companyID}
        />
       <div className="tab-pane fade show active">
          <Accordion
            title={this.state.accordions[0].title}
            isOpen={this.state.accordions[0].isOpen}
          >
            <PersonCoreData
              person={this.state.person}
              company={this.state.company}
              academictitles={this.state.academictitles}
              lang={this.props.match.params.lang}
              officeid={this.props.match.params.officeid}
              userid={this.props.match.params.userid}
              onChange={this.onChange}
              changeValue={this.changeValue}
              onAvatarUpdate={this.onAvatarUpdate}
              onSelectSalutation={this.onSelectSalutation}
              onSelectAcademictitle={this.onSelectAcademictitle}
              sitesUpdate={this.sitesUpdate}
              vitas={this.state.vitas}
              onVitasUpdate={this.onVitasUpdate}
              mandates={this.state.mandates}
              onMandatesUpdate={this.onMandatesUpdate}
              publications={this.state.publications}
              onPublicationsUpdate={this.onPublicationsUpdate}
              usermemberships={this.state.usermemberships}
              onUsermembershipsUpdate={this.onUsermembershipsUpdate}
              errorHandler={this.errorHandler}
            />

          </Accordion>
          <Editbar
            hasPublish={false}
            hasPreview={false}
            hasError={hasError}
            onSave={this.onSave}
            successMessage="Erfolgreich gespeichert"
          />
        </div>
      </>
    )
  }
}

export default withRouter(PersonNew);
