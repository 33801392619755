import React from "react";

class LangSwitcherStatic extends React.Component {
  renderLi = (key, shorthand, activeLanguage) => {
    if (shorthand === activeLanguage) {
      return <li key={key} className="is-active" onClick={() => this.props.setActiveLanguage(shorthand)}>{shorthand}</li>
    } else {
      return (
        <li key={key} onClick={() => this.props.setActiveLanguage(shorthand)}>{shorthand}</li>
      )
    }
  }

  render() {
    const activeLanguage = this.props.activeLanguage

    return (
      <ul className={`languages-switcher`}>
        {this.props.syslanguages.map((language, index) => {
          return this.renderLi(index, language.shorthand, activeLanguage)
          })
        }
      </ul>
    )
  }
}

export default LangSwitcherStatic
