import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";
import Overlay from "../../element/Overlay/Overlay";
import ImageSelector from "../../module/ImageSelector/ImageSelector";

class BlockFileEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      fileOverlayActive: false,
      preview: true,
    }
  }

  togglePreviewEditMode(e) {
    e.preventDefault()
    this.setState({
      preview: !this.state.preview
    })
  }

  openFileSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      fileOverlayActive: true
    })
  }

  closeFileSelectorOverlay = () => {
    this.setState({
      fileOverlayActive: false
    })
  }

  handleFileSelect = (selectedFile) => {
    const file = this.props.file
    file.fileID   = selectedFile.id
    file.filePath = selectedFile.file_path
    file.fileName = selectedFile.filename
    this.props.onChange(file)
    this.closeFileSelectorOverlay()
  }

  onChange = (event) => {
    const file = this.props.file
    file.title = event.target.value
    this.props.onChange(file)
  }

  render() {
    return (
      <>
        {this.state.preview ?
          <div className="dd-mini-block__preview">
            <div className="dd-mini-block__preview-item" onClick={(e) => this.togglePreviewEditMode(e)}>
              <div className="dd-mini-block__preview-image"></div>
              <div className="dd-mini-block__preview-title">{this.props.file.title}&nbsp;</div>
            </div>
          </div>

          :

          <div className="dd-mini-block__edit">
            <div className="dd-mini-block__close">
              <button className="dd-btn dd-btn--icon-only" onClick={(e) => this.togglePreviewEditMode(e)}>
                <Icon icon="close" />
              </button>
            </div>
            <div className="dd-block__edit-section">
              <div className="form-group" >
                <Textfield
                  name="fileTitle"
                  type="text"
                  isRequired={false}
                  label=""
                  placeholder="Dateibezeichnung eintragen"
                  handleChange={this.onChange}
                  defaultValue={this.props.file.title}
                />
              </div>

              <div className="dd-link-selector">
                <div className="dd-link-selector__link">{this.props.file.fileName}</div>
                <button className="dd-btn dd-btn--primary" onClick={(e) => this.openFileSelectorOverlay(e, true)}>Datei auswählen </button>
              </div>

              {this.state.fileOverlayActive &&
                <Overlay closeOverlayCallback={() => this.closeFileSelectorOverlay()}>
                  <ImageSelector
                    selectImageCallback={(file) => this.handleFileSelect(file)}
                    fileTypes={["files"]}
                  />
                </Overlay>
              }

            </div>
          </div>
        }
      </>
    )
  }
}

export default BlockFileEdit
