import React from 'react'
import PropTypes from 'prop-types'

import RangeScale from './RangeScale'

const Range = props => (
  <div className="dd-range">
    <div className="dd-range__form">
      <input
        id={props.id}
        className="dd-range__input"
        type="range"
        disabled={props.disabled}
        name={props.name}
        min={props.min}
        max={props.max}
        onChange={props.onChange ? props.onChange : null}
      />
      {/* <div className="dd-range__highlight"></div> */}
    </div>

    <RangeScale min={props.min} max={props.max} />

  </div>
)

Range.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  onChange: PropTypes.func
};
Range.defaultProps = {
  disabled: false,
  min: 1,
  max: 10
};

export default Range
