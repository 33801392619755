import React from "react";
import Icon from "../../components/Shared/atom/Icon/Icon";


class TableColumnHeader extends React.Component {

  constructor(props) {
    super(props);

    this.state = {}
  }


  render() {
    return (
      <th width={this.props.width ?? "auto"} scope="col" className={this.props.currentOrderField === this.props.field ? "dd-table--sort-th is-active" : "dd-table--sort-th"} >
        {
          this.props.currentOrderField === this.props.field ? (
            this.props.currentOrderDirection === "asc" ?
              <Icon icon={"sorter-down"} />
              :
              <Icon icon={"sorter-up"} />
          ) :
          <Icon icon={"sorter"} />
        }
        <button className="dd-table--sort-btn"
                onClick={() => this.props.onClick(this.props.field)}>{this.props.name}</button>
      </th>
    )
  }
}

export default TableColumnHeader;
