import React from "react";
import { withRouter } from "react-router-dom";

import { AppContext } from "../../data/ContextProvider";

class ProductNetwork extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      publishOverlay: false,
    }
  }

  closeOverlay = () => {
    this.setState({
      publishOverlay: false,
    })
  }

  handlePublication = async () => {
    this.closeOverlay()
    this.props.publishOnNetwork()
  }

  publishOnNetwork = (e) => {
    this.setState({
      publishOverlay: true,
    })
  }


  render() {
    return (
      <table className="table dd-table">
        <thead>
          <tr>
            <th>Status</th>
            <th>Kanzlei</th>
            <th>Klicks</th>
          </tr>
        </thead>
        <tbody>

        </tbody>
      </table>
    )
  }
}

ProductNetwork.contextType = AppContext
export default withRouter(ProductNetwork)
