import React from "react";

import { contentForBlock } from '../util';

const BlockPreviewTextImage = (props) => {

  const content = props.block[0] // FIXME
  const editorContent = contentForBlock(content.editor.content)


  return <div className={`dd-block-preview dd-block-preview--text-image d-flex ${content.imagePosition === "right" ? "flex-row-reverse" : "flex-row"}`}>
    { props.imagePath &&
      <div className="dd-block-preview__text-image-image">
        <img src={props.imagePath} alt="" width="260px" height="150px"/>
      </div>
    }

    <div className="dd-block-preview__text-image-text px-3">
      <div>{content.kicker}</div>
      <h3>{content.title}</h3>
      <p>{editorContent[0].value}</p>
      {content.hasButton &&
        <span className="dd-btn dd-btn--primary">{content.buttonTitle}</span>
      }
    </div>
  </div>
}

export default BlockPreviewTextImage;
