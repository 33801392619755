import React from "react";
import { withRouter, Route } from "react-router-dom";

import ProductsTable from "../components/Products/ProductsTable";
import ProductNew from "../components/Products/ProductNew";
import ProductContent from "../components/Products/ProductContent";


const Products = () => {
  return (
    <div className="tab-content" id="nav-tabContent">
      <Route exact path="/:lang/products">
        <ProductsTable />
      </Route>
      <Route exact path="/:lang/products/new">
        <ProductNew />
      </Route>
      <Route exact path="/:lang/products/:productid/:tabs">
        <ProductContent />
      </Route>
    </div>
  )
}

export default withRouter(Products);
