import React from "react"
import { v4 as uuidv4 } from 'uuid';

import Editor from '../Editor/Editor'

import BlockDefault from "./BlockDefault";
import BlockHeadline from "./BlockHeadline";
import BlockTextImageEdit from "./BlockTextImageEdit";
import BlockHeroEdit from "./BlockHeroEdit";
import BlockImageEdit from "./BlockImageEdit";
import BlockFilesEdit from "./BlockFilesEdit";
import BlockFactsCounterEdit from "./BlockFactsCounterEdit";
import BlockTeaserCollectionEdit from "./BlockTeaserCollectionEdit";
import BlockBannerEdit from "./BlockBannerEdit";
import BlockAccordionsEdit from "./BlockAccordionsEdit";
import BlockTeamEdit from "./BlockTeamEdit";
import BlockCompetencesEdit from "./BlockCompetencesEdit";
import BlockArticlesOverviewEdit from "./BlockArticlesOverviewEdit";
import BlockFrontSearchEdit from "./BlockFrontSearchEdit";
import BlockEmbeddedVideoEdit from "./BlockEmbeddedVideoEdit";
import BlockPodcastEdit from "./BlockPodcastEdit";
import BlockHTMLEdit from "./BlockHTMLEdit";
import BlockMapEdit from "./BlockMapEdit";
import BlockTextImageSliderEdit from "./BlockTextImageSliderEdit";
import BlockGalleryEdit from "./BlockGalleryEdit";
import BlockTeaserEdit from "./BlockTeaserEdit";
import BlockNoticeBarEdit from "./BlockNoticeBarEdit";
import BlockContactFormEdit from "./BlockContactFormEdit";
import BlockIntroTextEdit from "./BlockIntroTextEdit";
import BlockCustomerRatingsEdit from "./BlockCustomerRatingsEdit";
import BlockDiroCompanyListEidt from "./BlockDiroCompanyListEidt";
import BlockDiroMapEdit from "./BlockDiroMapEdit";
import BlockAdvantagesList from "./BlockAdvantagesList";
import BlockPricingList from "./BlockPricingList";
import BlockProcessStepsList from "./BlockProcessStepsList";
import BlockTableOfContent from "./BlockTableOfContent"

import { createID } from "../../../../lib/lib";
import BlockConsentDiroFormsEdit from "./BlockConsentDiroFormsEdit";
import BlockConsentSmartEdit from "./BlockConsentSmartEdit";
import BlockConsentCustomEdit from "./BlockConsentCustomEdit";
import BlockConsentCalensotEdit from "./BlockConsentCalensotEdit";

export const componentByType = (type) => {
  const mapping = {
    "default": BlockDefault,
    "h2": BlockHeadline,
    "h3": BlockHeadline,
    "h4": BlockHeadline,
    "headline": BlockHeadline,
    "bulleted-list": BlockDefault,
    "numbered-list": BlockDefault,
    "hero": BlockHeroEdit,
    "image": BlockImageEdit,
    "files": BlockFilesEdit,
    "facts-counter": BlockFactsCounterEdit,
    "text-image": BlockTextImageEdit,
    "teaser-collection": BlockTeaserCollectionEdit,
    "banner": BlockBannerEdit,
    "accordion": BlockAccordionsEdit,
    "team": BlockTeamEdit,
    "competence": BlockCompetencesEdit,
    "articles-overview": BlockArticlesOverviewEdit,
    "front-search": BlockFrontSearchEdit,
    "diro-company-list": BlockDiroCompanyListEidt,
    "embeddedVideo": BlockEmbeddedVideoEdit,
    "podcast": BlockPodcastEdit,
    "diroforms": BlockConsentDiroFormsEdit,
    "smartrechner": BlockConsentSmartEdit,
    "calenso": BlockConsentCalensotEdit,
    "consentCustom": BlockConsentCustomEdit,
    "html": BlockHTMLEdit,
    "map": BlockMapEdit,
    "textarea": BlockHTMLEdit, // TMP
    "text-image-slider": BlockTextImageSliderEdit,
    "gallery": BlockGalleryEdit,
    "notice-bar": BlockNoticeBarEdit,
    "teaser": BlockTeaserEdit,
    "contact-form": BlockContactFormEdit,
    "intro-text": BlockIntroTextEdit,
    "customer-rating": BlockCustomerRatingsEdit,
    "diro-map": BlockDiroMapEdit,
    "advantages-list": BlockAdvantagesList,
    "pricing-list": BlockPricingList,
    "process-steps-list": BlockProcessStepsList,
    "toc": BlockTableOfContent,
  }

  if ((type in mapping)) {
    return mapping[type]
  }

  throw new Error("Block " + type + " not found")
}

export const initValue = (type, initData = []) => {

  if (initData && initData.length > 0) {
    return initData
  }

  if (type === 'bulleted-list' || type === 'numbered-list') {
    const text = (type === 'bulleted-list') ? "Tragen Sie hier bitte eine Auflistung ein" : "Tragen Sie hier bitte eine Aufzählung ein"
    return {
      type: type,
      section: {
        'background': '',
        'spacing': '',
      },
      children: [
        {
          type: "list-item",
          children: [
            {
              text: "",
              placeholder: text
            }
          ]
        },
      ],
    }
  } else if (type === 'text-image') {
    return [{
      type: type,
      viewmode: "default",
      kicker: "",
      title: "",
      hasButton: false,
      buttonTitle: "Mehr erfahren",
      buttonTarget: "",
      buttonURL: "",
      buttonPageID: 0,
      imagePosition: "",
      imagePath: "",
      imageID: 0,
      editor: {
        editor: "",
        content: [{
          type: "default",
          children: [
            { text: "" }
          ],
        }],
        type: "",
      },
      children: [
        { text: type }
      ],
      section: {
        'background': '',
        'spacing': 'padding',
      }
    }]
  } else if (type === 'hero') {
    return [{
      type: type,
      kicker: "",
      title: "",
      imagePath: "",
      imageID: 0,
      size: "small",
      background: "",
      children: [
        { text: type }
      ],
      section: {
        'background': '',
        'spacing': '',
      },
      mediaType: "image",
    }]
  } else if (type === 'image') {
    return [{
      type: type,
      imageID: 0,
      imagePath: "",
      imagePosition: "left",
      imageCaption: "",
      imageSource: "",
      imageFormat: 'original',
      withImageCaption: false,
      withImageSource: false,
      section: {
        'background': '',
        'spacing': '',
      }
    }]
  } else if (type === 'teaser-collection') {
    return [{
      type: type,
      collectionType: 'news',
      headline: "Headline",
      buttonText: "Alle anzeigen",
      buttonPath: "",
      section: {
        'background': 'grey',
        'spacing': 'padding',
      }
      // children: [
      //   { text: type }
      // ],
    }]
  } else if (type === 'contact-form') {
    return [{
      type: type,
      headline: "Headline",
      buttonText: "Alle anzeigen",
      buttonPath: "",
      section: {
        'background': 'grey',
        'spacing': 'padding',
      }
    }]
  } else if (type === 'articles-overview') {
    return [{
      type: type,
      itemsCountPerPage: 10,
      overviewType: 'news',
      viewmode: 'compact',
      section: {
        'background': 'grey',
        'spacing': 'padding',
      }
    }]
  } else if (type === 'front-search') {
    return [{
      type: type,
      overviewType: 'front-search',
      section: {
        'background': 'grey',
        'spacing': 'padding',
      }
    }]
  } else if (type === 'embeddedVideo') {
    return [{
      type: type,
      users_third_party_services: true,
      provider: 'youtube',
      videoURL: '',
      autoplay: false,
      width: "560",
      height: "315",
      controls: false,
      privacyMode: true,
      section: {
        'background': 'grey',
        'spacing': 'padding',
      }
    }]
  } else if (type === 'podcast') {
    return [{
      type: type,
      users_third_party_services: true,
      feedURL: '',
      originURL: '',
      episodeLimit: 1,
      section: {
        'background': '',
        'spacing': 'padding',
      }
    }]
  } else if (type === 'consentDiroForms') {
    return [{
      type: type,
      url: '',
      height: "600"
    }]
  } else if (type === 'consentSmart') {
    return [{
      type: type,
      title: "",
      children: [
        {
          text: "",
          placeholder: "Tragen Sie hier bitte den HTML-code ein"
        }
      ],
    }]
   } else if (type === 'consentCalenso') {
    return [{
      type: type,
      title: "",
      children: [
        {
          text: "",
          placeholder: "Tragen Sie hier bitte den HTML-code ein"
        }
      ],
    }]
  } else if (type === 'consentCustom') {
    return [{
      type: type,
      identifier: uuidv4(),
      title: "Sonstiger Externer Dienst",
      children: [
        {
          text: "",
          placeholder: "Tragen Sie hier bitte den HTML-code ein"
        }
      ],
    }]
  } else if (type === 'banner') {
    return [{
      type: type,
      background: '',
      title: "Headline",
      buttonTitle: "Mehr",
      buttonURL: "",
      buttonTarget: "",
      buttonPageID: 0,
      section: {
        'background': '',
        'spacing': 'padding',
      }
      // children: [
      //   { text: type }
      // ],
    }]
  } else if (type === 'accordion') {
    return [{
      type: type,
      title: "Headline",
      items: [],
      section: {
        'background': '',
        'spacing': '',
      }
      // children: [
      //   { text: type }
      // ],
    }]
  } else if (type === 'team') {
    return [{
      type: type,
      viewmode: 'compact',
      build: 'automatic',
      title: "",
      team:[],
      // children: [
      //   { text: type }
      // ],
      section: {
        'background': 'grey',
        'spacing': 'padding',
      }
    }]
  } else if (type === 'user-profile') {
    return [{
      type: type,
      userID: 0,
      imageID: 0,
      imagePath:'',
      // children: [
      //   { text: type }
      // ],
      section: {
        'background': 'grey',
        'spacing': 'padding',
      }
    }]
  } else if (type === 'competence') {
    return [{
      type: type,
      viewmode: 'topic', // icons, linklist, grid, grid_icons
      items: [],
      // children: [
      //   { text: type }
      // ],
      section: {
        'background': '',
        'spacing': 'padding',
      }
    }]
  } else if (type === 'files') {
    return [{
      type: type,
      title: "Downloads",
      files: [],
      section: {
        'background': '',
        'spacing': '',
      }
    }]
  } else if (type === 'facts-counter') {
    return [{
      type: type,
      title: "Fakten-Counter",
      facts: [],
      imageID: 0,
      imagePath: '',
      section: {
        'background': '',
        'spacing': '',
      }
    }]
  } else if (type === 'advantages-list') {
    return [{
      type: type,
      title: "",
      prolog: "",
      epilog: "",
      show_prolog: false,
      show_epilog: false,
      list: [],
      section: {
        'background': '',
        'spacing': '',
      }
    }]
  } else if (type === 'toc') {
    return [{
      type: type,
      collectionType: 'auto',
      h2: true,
      h3: false,
      h4: false,
      list: [],
      section: {
        'background': '',
        'spacing': '',
        'toc_default_status': 'open',
      }
    }]
  } else if (type === 'pricing-list') {
    return [{
      type: type,
      title: "",
      prolog: "",
      epilog: "",
      show_prolog: false,
      show_epilog: false,
      list: [],
      section: {
        'background': '',
        'spacing': '',
      }
    }]
  } else if (type === 'process-steps-list') {
    return [{
      type: type,
      viewmode: 'icons',
      title: "",
      items: [],
      section: {
        'background': '',
        'spacing': '',
      }
    }]
  } else if (type === 'text-image-slider') {
    return [{
      type: type,
      title: "Text-Bild-Slider",
      items: [],
      section: {
        'background': '',
        'spacing': '',
      }
    }]
  } else if (type === 'customer-rating') {
    return [{
      type: type,
      title: "Mandantenbewertungen",
      items: [],
      section: {
        'background': '',
        'spacing': 'padding',
      }
    }]
  } else if (type === 'gallery') {
    return [{
      type: type,
      title: "Galerie",
      items: [],
      section: {
        'background': '',
        'spacing': '',
      }
    }]
  } else if (type === 'default') {
    return [{
      type: type,
      section: {
        'background': '',
        'spacing': '',
      },
      children: [
        {
          text: "",
          placeholder: "Tragen Sie hier bitte den Text ein"
        }
      ],
    }]
  } else if (type === 'h2') {
    return [{
      type: type,
      children: [
        {
          text: "",
          placeholder: "Tragen Sie hier bitte die XL-Überschrift ein"
        }
      ],
    }]
  } else if (type === 'h3') {
    return [{
      type: type,
      children: [
        {
          text: "",
          placeholder: "Tragen Sie hier bitte die L-Überschrift ein"
        }
      ],
    }]
  } else if (type === 'h4') {
    return [{
      type: type,
      children: [
        {
          text: "",
          placeholder: "Tragen Sie hier bitte die M-Überschrift ein"
        }
      ],
    }]
  } else if (type === 'html') {
    return [{
      type: type,
      users_third_party_services: true,
      children: [
        {
          text: "",
          placeholder: "Tragen Sie hier bitte den HTML-code ein"
        }
      ],
    }]
  } else if (type === 'notice-bar') {
    return [{
      type: type,
      title: "",
      callToAction: "",
      contactDetails: "",
      section: {
        'background': '',
        'spacing': 'padding',
        'visibilityStart': '',
        'visibilityEnd': '',
      }
    }]
  } else if (type === 'teaser') {
    return [{
      type: type,
      title: "Teaser",
      grid: "2",
      items: [],
      section: {
        'background': '',
        'spacing': '',
      }
    }]
  } else if (type === 'intro-text') {
    return [{
      type: type,
      title: "Intro-Text",
      headline:"",
      text:"",
      section: {
        'background': '',
        'spacing': '',
      }
    }]
  } else if (type === 'headline') {
    return [{
      type: type,
      editor: {
        editor: "",
        content: [{
          type: "h2",
          children: [
            { text: "" }
          ],
        }],
        headlineType: "h2",
      },
      section: {
        'background': '',
        'spacing': '',
        'anchordId': '',
      }
    }]
  } else if (type === 'map') {
    return [{
      type: type,
      users_third_party_services: true,
      siteID: 0,
      zoom: 10,
      maplat: '',
      maplong: '',
      mapcolor: "monochrome",
      maplayout: "full",
      message: "",
      items: [],
      lawfields: [],
      jobtitles: [],
      section: {
        'background': '',
        'spacing': 'padding',
      }
    }]
  } else {
    return [{
      type: type,
      children: [
        { text: type }
      ],
    }]
  }
}

const convertHtoHeadline = (type, block) => {
  return [{
    type: "headline",
    editor: {
      editor: "",
      content: [{
        type: type,
        children: [...block[0].children]
      }],
      type: "",
    },
    section: {
      'background': '',
      'spacing': '',
    },
    headlineType: type
  }]
}

export const blockTemplate = (type, onChange, onDelete, preview, errorHandler, initData = []) => {
  const key = createID()
  const ComponentTag = componentByType(type)
  let init = initValue(type, initData)

  if ((type === "h4" || type === "h3" || type === "h2") && initData.length > 0) {
    init = convertHtoHeadline(type, initData)
  }

  return {
    component: <ComponentTag
      index={key}
      blockKey={key}
      type={type}
      onChange={onChange}
      errorHandler={errorHandler}
      initData={init}
      deleteBlockCallback={onDelete}
      preview={preview}
    />,
    key: key,
    type: type,
    content: init
  }
}


export const createEditor = (id, type, onChange, initData = []) => {

  let init = [initValue(type)]

  if (initData && initData.length > 0) {
    init = initData
  }

  // hier muessen sowohl key als auch die id uebergeben werden
  return <Editor key={id} id={id} initValue={init} onChange={(id, value) => onChange(id, value)} />
}


export function contentForBlock(block) {
  const out = []
  let content = []



  if (!block) {
    return out
  }

  if (!Array.isArray(block)) {
    content.push(block)
  } else {
    content = block
  }



  let output = {tag: "p", value: "" }

  content.forEach(element => {

    switch (element.type) {
      case "h2":
        output = {
          tag: "h2",
          value: element.children[0].text
        }
        break;

      case "h3":
        output = {
          tag: "h3",
          value: element.children[0].text
        }
        break;

      case "h4":
        output = {
          tag: "h4",
          value: element.children[0].text
        }
        break;

      case "textarea":
        output = {
          tag: "p",
          value: "HTML-Code-Block"
        }
        break;

      case "html":
        output = {
          tag: "code",
          value: "HTML-Code-Block"
        }
        break;

      case "toc":
        output = {
          tag: "ul",
          value: "HTML-Code-Block"
        }
        break;

      default:
        output = {
          tag: "p",
          value: renderDefaultItem(element.children)
        }
        break;
    }
    out.push(output)
  })

  return out
}

function renderDefaultItem(element) {
  let out = []
  element.forEach((child, index) => {
    // console.log(child["text"]);
    if (child["type"] === "link") {
      out.push(renderLinkItem(child, index))
    }
    else if (child["text"]) {
      if (child.bold) {
        out.push(renderBoldItem(child["text"], index))
      }
      else if (child.underlined) {
        out.push(renderUnderlinedItem(child["text"], index))
      }
      else if (child.italic) {
        out.push(renderItalicItem(child["text"], index))
      }
      else {
        out.push(child["text"])
      }
    }
  })

  return out
}

function renderBoldItem(text, key) {
  let link = ""

  if (text.length) {
    link = <strong key={key}>{text}</strong>
  }

  return link
}

function renderItalicItem(text, key) {
  let link = ""

  if (text.length) {
    link = <i key={key}>{text}</i>
  }

  return link
}

function renderUnderlinedItem(text, key) {
  let link = ""

  if (text.length) {
    link = <u key={key}>{text}</u>
  }

  return link
}

function renderLinkItem(element, key) {
  let out = ""
  let link = ""

  if (Array.isArray(element.children) ) {
    element.children.forEach((child) => {
      out += child["text"]
    })
  }

  if (out.length) {
    link = <a href={element["url"]} key={key}>{out}</a>
  }

  return link
}
