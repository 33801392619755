import React from "react";
import { Link, withRouter } from "react-router-dom";
import { throttle } from "throttle-debounce";

import Pagination from "../Shared/element/Pagination/Pagination";
import TableColumnHeader from "../../layout/Tables/TableColumnHeader";
import Searchbar from "../Shared/element/Searchbar/Searchbar";
import Title from "../Shared/module/Title/Title"
import Icon from "../Shared/atom/Icon/Icon";
import Loader from "../Shared/atom/Loader/Loader";

import { getNeworkProducts } from "../../data/api";
import { getToken, formatDate } from "../../lib/lib";
import { AppContext } from "../../data/ContextProvider";

class ArticlesTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      loading: true,
      openLangChooser: false,
      lang: "de",
      deleteConfirmOverlay: false,
      confirmMode: "delete",
      articleToDelete: 0,
      count: 0,
      pages: 0,
      currentPage: 0,
      limit: 10,
      orderField: 'updated_at',
      orderDirection: 'desc',
      articles: [],
      products: [],
      articleActiveLanguageList: 0,
      selectedTranslations: {}
    }

    this.searchThrottled = throttle(500, this.loadData);
  }

  onFilterChange = (event) => {
    this.setState({
      query: event.target.value,
      currentPage: 0
    }, () => {
      this.searchThrottled()
    })
  }

  handlePaginationClick = async (e, page) => {
    this.setState({
      ...this.state,
      currentPage: page
    }, async () => {
      await this.loadData()
    })
  }

  handleSelectLimit = (limit) => {
    this.setState({ limit, currentPage: 0 }, async () => {
      await this.loadData()
    })
  }

  onTableColumnClick = (column) => {
    const { orderDirection } = this.state

    this.setState({
      orderField: column,
      orderDirection: (orderDirection === 'desc') ? 'asc' : 'desc'
    }, async () => {
      await this.loadData()
    })
  }

  closeOverlay = () => {
    this.setState({
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false,
    })
  }

  loadData = async () => {
    const offset = this.state.currentPage * this.state.limit

    const products = await getNeworkProducts(getToken(), this.state.lang, this.state.limit, offset, this.state.orderField, this.state.orderDirection, this.state.query)

    this.setState({
      ...this.state,
      loading: false,
      pages: Math.ceil(products.count / this.state.limit),
      count: products.count,
      products: products.products,
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false,
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  renderAuthorsList = (article) => {
    const out = []

    if (article.authors) {
      article.authors.forEach((author) => {
        out.push(author.full_name)
      })
    }
    return out.join(", ")
  }

  renderRow = (article, key) => {
    let a = article

    return <tr key={key} className={(!a.published) ? 'is-inactive' : ''}>

      <td className={(this.state.orderField === 'title') ? 'is-active' : ''}>{a.title}</td>
      <td className={(this.state.orderField === 'updated_at') ? 'is-active' : ''}>{formatDate(a.updated_at)}</td>
      <td className={(this.state.orderField === 'created_at') ? 'is-active' : ''}>{formatDate(a.created_at)}</td>
      <td className={(this.state.orderField === 'author') ? 'is-active' : ''}>{a.published_from_user}</td>
      <td className={(this.state.orderField === 'views') ? 'is-active' : ''}>{a.published_from_company}</td>
      <td className="justify-content-center dd-table--actions">
        <div className="dd-table--actions_wrapper">
          <Link to={`/de/products/${a.id}/content`}>
            <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only">
              <Icon icon="edit" />
            </button>
          </Link>
        </div>
      </td>
    </tr>
  }

  render() {

    if (this.state.loading) {
      return <Loader />
    }

    return (
      <>
        { this.state.products.length > 0 &&
          <>
            <Title headline="Produkte zur Freigabe" />
            <table className="table dd-table">
              <thead>
                <tr>
                  <TableColumnHeader
                    name="Titel"
                    field="title"
                    currentOrderField={this.state.orderField}
                    currentOrderDirection={this.state.orderDirection}
                    onClick={this.onTableColumnClick} />
                  <TableColumnHeader
                    name="Geändert"
                    field="updated_at"
                    currentOrderField={this.state.orderField}
                    currentOrderDirection={this.state.orderDirection}
                    onClick={this.onTableColumnClick} />
                  <TableColumnHeader
                    name="Erstellt"
                    field="created_at"
                    currentOrderField={this.state.orderField}
                    currentOrderDirection={this.state.orderDirection}
                    onClick={this.onTableColumnClick} />
                  <TableColumnHeader
                    name="Autor"
                    field="author"
                    currentOrderField={this.state.orderField}
                    currentOrderDirection={this.state.orderDirection}
                    onClick={this.onTableColumnClick} />
                  <TableColumnHeader
                    name="Kanzlei"
                    field="views"
                    currentOrderField={this.state.orderField}
                    currentOrderDirection={this.state.orderDirection}
                    onClick={this.onTableColumnClick} />
                  <th scope="col" className="dd-table--actions-th">
                    <div className="dd-search-bar dd-search-bar--small dd-search-bar--white dd-table--search-filter">
                      <Searchbar
                        placeholder="Verfügbare Produkte filtern …"
                        icon="filter"
                        onChangeCallback={(e) => this.onFilterChange(e)}
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.products.map((product, key) => {
                  return this.renderRow(product, key)
                })}
              </tbody>
            </table>

            <div className="float-right">
              <Pagination
                total={this.state.pages}
                current={this.state.currentPage}
                onChange={this.handlePaginationClick}
                onSelect={this.handleSelectLimit}
                limit={this.state.limit}
              />
            </div>
          </>
        }
      </>
    )
  }
}

ArticlesTable.contextType = AppContext
export default withRouter(ArticlesTable)
