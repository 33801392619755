import React from "react";

import Icon from "../../atom/Icon/Icon";
import Checkbox from "../../atom/Checkbox/Checkbox";

import BlockSectionSettings from "./BlockSectionSettings";

import { AppContext } from "../../../../data/ContextProvider";
import BlockTableOfContentPreview from "./BlockPreviews/TableOfContentPreview";
import RadioButton from "../../atom/RadioButton/RadioButton";
import BlockActions from "./BlockActions";

class BlockTableOfContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {},
      preview: true,
      showSettings: false,
      showManualWay: false,
      showAutoWay: true,
    };
  }

  togglePreviewEditMode() {
    this.setState(
      {
        preview: !this.state.preview,
      },
      () => {
        if (this.state.preview) {
          this.context.toggleBlocksDraggable(false);
        } else {
          // Edit Mode, disable outer dragging
          this.context.toggleBlocksDraggable(true);
        }
      }
    );
  }

  toggleSettings = (e) => {
    e.preventDefault();
    this.setState({
      showSettings: !this.state.showSettings,
    });
  };

  closeEditMode() {
    this.togglePreviewEditMode();
  }

  onChangeType = (event) => {
    let showAutoWay;
    let showManualWay;
    const content = this.state.content;
    content[event.target.name] = event.target.value;

    showManualWay = event.target.value === "manual" ? true : false;
    showAutoWay = event.target.value === "auto" ? true : false;

    if (showManualWay) this.resetFields();
    if (showAutoWay) content.h2 = true;

    this.setState({ showAutoWay });
    this.saveContent(content);
  };

  resetFields = () => {
    const fields = ["h2", "h3", "h4"];
    const content = this.state.content;
    fields.forEach((field) => {
      content[field] = false;
    });
    this.setState({ content });
    this.saveContent(content);
  };

  toggleField = (e, field) => {
    const content = this.state.content;
    content[field] = !this.state.content[field];
    this.saveContent(content);
  };

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream();
    });
  };

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content]);
  };

  async componentDidMount() {
    let showAutoWay = false;
    let showManualWay = false;

    if (this.props.initData) {
      const preview = this.props.preview;
      const content = this.props.initData[0];
      showAutoWay = content.collectionType === "auto" ? true : false;
      this.setState({ content, preview, showAutoWay, showManualWay });
    }
  }

  render() {
    return (
      <>
        {this.state.preview ? (
          <div
            className="dd-block"
            onClick={() => this.togglePreviewEditMode()}
          >
            <div className="dd-block__preview">
              <BlockTableOfContentPreview block={this.props.initData} />
            </div>
          </div>
        ) : (
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button
                  className="dd-block__settings-trigger dd-btn--reset"
                  onClick={(e) => this.toggleSettings(e)}
                >
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-ul" /> Inhaltsverzeichnis
              </div>

              {this.state.showSettings && (
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              )}
              <div className="dd-block__edit-section">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <label className="radio-button__group-label text-nowrap ">
                      Generierungsart auswählen
                    </label>
                    <RadioButton
                      name="collectionType"
                      label="Automatisch aus Headlines"
                      value="auto"
                      handleChange={this.onChangeType}
                      selected={this.state.content.collectionType === "auto"}
                    />
                    <RadioButton
                      name="collectionType"
                      label="Nur manuell gekennzeichnete Headlines (Anchor ID)"
                      value="manual"
                      handleChange={this.onChangeType}
                      selected={this.state.content.collectionType === "manual"}
                    />
                  </div>
                </div>

                {this.state.showAutoWay && (
                  <>
                    <p className="dd-block__settings-text">
                      Headlines automatisch ins Inhaltsverzeichnis aufnehmen:
                    </p>
                    <div className="form-group">
                      <div className="form-check form-check-inline">
                        <Checkbox
                          label="H2"
                          id="h2"
                          selected={this.state.content.h2}
                          onChange={(e) => this.toggleField(e, "h2")}
                        />
                        &nbsp;&nbsp;
                        <Checkbox
                          label="H3"
                          id="h3"
                          selected={this.state.content.h3}
                          onChange={(e) => this.toggleField(e, "h3")}
                        />
                        &nbsp;&nbsp;
                        <Checkbox
                          label="H4"
                          id="h4"
                          selected={this.state.content.h4}
                          onChange={(e) => this.toggleField(e, "h4")}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

BlockTableOfContent.contextType = AppContext;
export default BlockTableOfContent;
