import React from 'react'

import Icon from '../../atom/Icon/Icon'
import { AppContext } from '../../../../data/ContextProvider'

class BlockSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      activeSelectorTab: 'elements',
      selectorIsOpen: false
    }
  }

  setActiveSelectorTab(e, tab) {
    e.stopPropagation()
    this.setState({
      activeSelectorTab: tab
    })
  }

  openSelector(e) {
    e.preventDefault()
    this.setState({
      selectorIsOpen: !this.state.selectorIsOpen
    })
  }

  addBlock(e, type, atIndex, initData = []) {
    e.preventDefault()
    this.props.addBlockCallback(type, atIndex, initData)
    this.setState({
      selectorIsOpen: !this.state.selectorIsOpen
    })
  }

  async pasteBlock(e) {
    try {
      const stringifiedObject = sessionStorage.getItem('DdClipboardBlock')
      const blockContent = JSON.parse(stringifiedObject)
      const blockType = blockContent.type ? blockContent.type : 'default'
      const content = blockType === 'default' ? blockContent : [blockContent]
      this.addBlock(e, blockType, this.props.index, content)
      sessionStorage.removeItem('DdClipboardBlock')
    } catch (error) {
      console.error('Failed to paste: ', error)
    }
  }

  render() {
    const showPasteButton =
      !!(sessionStorage.getItem('DdClipboardBlock') &&
        sessionStorage.getItem('DdClipboardBlock').length > 0)

    return (
      <div
        className={
          this.state.selectorIsOpen
            ? 'dd-block-selector is-open'
            : 'dd-block-selector'
        }
      >
        <button
          className="dd-btn dd-btn--primary dd-btn--icon-only dd-block-selector__add"
          onClick={(event) => this.openSelector(event)}
        >
          <Icon icon="plus" />
        </button>

        <div className="dd-block-selector__content">
          {/* #FIXME: Das geht evtl. eleganter und wiederverwendbarer */}
          <ol className="dd-block-selector__tabs">
            <li
              className={
                this.state.activeSelectorTab === 'elements'
                  ? 'dd-block-selector__tab is-active'
                  : 'dd-block-selector__tab'
              }
              onClick={(e) => this.setActiveSelectorTab(e, 'elements')}
            >
              Einzelelemente
            </li>
            <li
              className={
                this.state.activeSelectorTab === 'modules'
                  ? 'dd-block-selector__tab is-active'
                  : 'dd-block-selector__tab'
              }
              onClick={(e) => this.setActiveSelectorTab(e, 'modules')}
            >
              Fertige Bausteine
            </li>
            <li
              className={
                this.state.activeSelectorTab === 'tracking'
                  ? 'dd-block-selector__tab is-active'
                  : 'dd-block-selector__tab'
              }
              onClick={(e) => this.setActiveSelectorTab(e, 'tracking')}
            >
              Externe Dienste
            </li>
            {showPasteButton && (
              <li className="dd-block-selector__paste">
                <button
                  className="dd-btn dd-btn--inverted"
                  onClick={(e) => this.pasteBlock(e)}
                >
                  Kopierten Block einfügen
                </button>
              </li>
            )}
          </ol>
          <div className="dd-block-selector__tab-content">
            <div className="dd-block-selector__types">
              {this.state.activeSelectorTab === 'elements' && (
                <React.Fragment>
                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'intro-text', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-intro-text" />
                    </div>
                    Intro-Text
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'headline', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-headline" />
                    </div>
                    Überschrift
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'default', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-paragraph" />
                    </div>
                    Absatz
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'bulleted-list', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-ul" />
                    </div>
                    Auflistung
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'numbered-list', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-ol" />
                    </div>
                    Aufzählung
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) => this.addBlock(e, 'image', this.props.index)}
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-image" />
                    </div>
                    Bild
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) => this.addBlock(e, 'html', this.props.index)}
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-html" />
                    </div>
                    HTML
                  </button>
                </React.Fragment>
              )}
              {this.state.activeSelectorTab === 'modules' && (
                <React.Fragment>
                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'text-image', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-text-image" />
                    </div>
                    Text/Bild
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'accordion', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-accordion" />
                    </div>
                    Accordion
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) => this.addBlock(e, 'hero', this.props.index)}
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-hero" />
                    </div>
                    Hero
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'banner', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-banner" />
                    </div>
                    Banner
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'teaser-collection', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-teaserlist" />
                    </div>
                    Teaser
                  </button>
                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'teaser', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-teaser-group" />
                    </div>
                    Teaser-Gruppe
                  </button>
                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'articles-overview', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-teaserlist" />
                    </div>
                    Fachartikel&shy;übersicht
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) => this.addBlock(e, 'team', this.props.index)}
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-team" />
                    </div>
                    Team
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'contact-form', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-contact-form" />
                      Kontakt&shy;formular
                    </div>
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'gallery', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-gallery" />
                    </div>
                    Galerie
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) => this.addBlock(e, 'files', this.props.index)}
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-download" />
                    </div>
                    Downloads
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'competence', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-competence" />
                    </div>
                    Kompetenz
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'text-image-slider', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-text-image-slider" />
                    </div>
                    Text-Bild-Slider
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'customer-rating', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-user-rating" />
                    </div>
                    Mandanten&shy;bewertungen
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'facts-counter', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-fact-counter" />
                    </div>
                    Fakten-Counter
                  </button>
                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'advantages-list', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-advantages" />
                    </div>
                    Advantages
                  </button>
                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) => this.addBlock(e, 'toc', this.props.index)}
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-ul" />
                    </div>
                    Inhalts-verzeichnis
                  </button>
                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'pricing-list', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-pricelist" />
                    </div>
                    Preisliste
                  </button>
                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'process-steps-list', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-multistep" />
                    </div>
                    Prozess in X Steps
                  </button>

                  {this.context.user.diro_admin && (
                    <>
                      <button
                        className="dd-btn--transparent dd-block-selector__button"
                        onClick={(e) =>
                          this.addBlock(e, 'front-search', this.props.index)
                        }
                      >
                        <div className="dd-block-selector__button-icon">
                          <Icon icon="search" />
                        </div>
                        Kanzlei-Suche
                      </button>
                      <button
                        className="dd-btn--transparent dd-block-selector__button"
                        onClick={(e) =>
                          this.addBlock(
                            e,
                            'diro-company-list',
                            this.props.index
                          )
                        }
                      >
                        <div className="dd-block-selector__button-icon">
                          <Icon icon="btn-editor-teaserlist" />
                        </div>
                        DIRO-Kanzleien
                      </button>
                    </>
                  )}
                </React.Fragment>
              )}
              {this.state.activeSelectorTab === 'tracking' && (
                <React.Fragment>
                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'embeddedVideo', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-video" />
                    </div>
                    Video
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'podcast', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="podcast" />
                    </div>
                    Podcast
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) => this.addBlock(e, 'map', this.props.index)}
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="btn-editor-location" />
                    </div>
                    Standort-Karte
                  </button>

                  {this.context.user.diro_admin && (
                    <button
                      className="dd-btn--transparent dd-block-selector__button"
                      onClick={(e) =>
                        this.addBlock(e, 'diro-map', this.props.index)
                      }
                    >
                      <div className="dd-block-selector__button-icon">
                        <Icon icon="btn-editor-location" />
                      </div>
                      DIRO-Kanzleien Standorte
                    </button>
                  )}

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'diroforms', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="diro-webformular" />
                    </div>
                    Diro-Webformular
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'calenso', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="kalender" />
                    </div>
                    Calenso
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'smartrechner', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="smartrechner" />
                    </div>
                    Smartrechner
                  </button>

                  <button
                    className="dd-btn--transparent dd-block-selector__button"
                    onClick={(e) =>
                      this.addBlock(e, 'consentCustom', this.props.index)
                    }
                  >
                    <div className="dd-block-selector__button-icon">
                      <Icon icon="custom-consent" />
                    </div>
                    Sonstiges (HTML)
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

BlockSelector.contextType = AppContext
export default BlockSelector
