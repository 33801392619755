import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import Textfield from '../Shared/atom/Textfield/Textfield'
import { MainHelperText } from "../Shared/element/HelperText/HelperText"
import { AppContext } from "../../data/ContextProvider"
import { createJob } from '../../data/api'
import { getToken } from '../../lib/lib'

class JobNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      job: {}
    }
  }

  errorHandler = (hasError=false) => {
    this.setState({ hasError })
  }

  onChange = (event) => {
    const job = this.state.job
    job[event.target.name] = event.target.value
    this.setState({ job })
  }

  onSubmit = () => {
    const lang = this.props.match.params.lang || "de"

    const job = this.state.job
    job.lang = lang

    createJob(getToken(), job)
    .then(job => {
      this.props.history.push(`/${lang}/jobs/${job.job.id}/content`)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  render() {
    const formValid = (this.state.job.title && this.state.job.navigation_title) ? true : false
    const disabled = (this.state.hasError || !formValid) ? true : false

    return (
      <div className="tab-pane fade show active">
        <div className="card-body card-body--standard dd-background dd-background--sand">
          <div className="card-body__content w-75 ml-auto mr-auto">
            <div className="form-group">
              <Textfield
                name="title"
                type="text"
                isRequired={true}
                label="Titel der Stellenanzeige"
                handleChange={this.onChange}
                errorHandler={this.errorHandler}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="navigation_title"
                type="text"
                isRequired={true}
                label="Navigationstitel"
                handleChange={this.onChange}
                errorHandler={this.errorHandler}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="author"
                type="text"
                isRequired={true}
                label="Autor"
                defaultValue={this.context.user.full_name}
                errorHandler={this.errorHandler}
              />
            </div>
          </div>
        </div>
        <MainHelperText
          disabled={disabled}
          onSubmit={this.onSubmit}
        />
      </div>
    )
  }
}

JobNew.contextType = AppContext
export default withRouter(JobNew)
