import React from "react";
import Flatpickr from 'react-flatpickr'

import SimpleSelectfield from "../../atom/Selectfield/SimpleSelectfield";
import RadioButton from "../../atom/RadioButton/RadioButton";

import { German } from 'flatpickr/dist/l10n/de.js'
import { dateToRailsDateString } from '../../../../lib/lib'
import Textfield from "../../atom/Textfield/Textfield";

class BlockSectionSettings extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      sectionBackgroundOptions: [
        { id: '', option: 'keine' },
        { id: 'white', option: 'Weiß' },
        { id: 'grey', option: 'Grau' },
        { id: 'primary', option: 'Primärfarbe' },
        { id: 'secondary', option: 'Sekundärfarbe' },
      ],
      sectionSpacingOptions: [
        { id: '', option: 'keine' },
        { id: 'padding-top', option: 'oben' },
        { id: 'padding-bottom', option: 'unten' },
        { id: 'padding', option: 'oben und unten' }
      ],
      hideBackgroundOptions: [],
      showPublishingSettings: [
        'notice-bar'
      ],
      showTextAlignOptions: [
        'default',
        'headline'
      ],
      showAnchorIdTextfield: [
        'headline'
      ],
      showDefaultTocStatus: [
        'toc'
      ]
    }
  }

  errorHandler = (hasError = false) => {
    this.setState({hasError})
  }

  onChangeAnchorId = (event) => {
    //deep Clone
    if (this.props.content) {
      const content = JSON.parse(JSON.stringify(this.props.content));
      if (typeof content.section !== 'undefined') {
        content.section["anchorId"] = event.target.value
        this.props.saveContent(content)
      }
    }
  }

  onSelectBackground = (value) => {
    this.onSelect("background", value)
  }

  onSelectSpacing = (value) => {
    this.onSelect("spacing", value)
  }

  onChangeTextAlign = (value) => {
    //deep Clone
    if (this.props.content) {
      const content = JSON.parse(JSON.stringify(this.props.content));
      if (typeof content.section !== 'undefined') {
        content.section["text_align"] = value.target.value
        this.props.saveContent(content)
      }
    }
  }

  onChangeDefaultTocStatus = (value) => {
    //deep Clone
    if (this.props.content) {
      const content = JSON.parse(JSON.stringify(this.props.content));
      if (typeof content.section !== 'undefined') {
        content.section["toc_default_status"] = value.target.value
        this.props.saveContent(content)
      }
    }
  }

  onSelect = (field, value) => {
    //deep Clone
    if (this.props.content) {
      const content = JSON.parse(JSON.stringify(this.props.content));
      if (typeof content.section !== 'undefined') {
        content.section[field] = value
        this.props.saveContent(content)
      }
    }
  }

  render() {
    const showBackgroundOptions = (this.state.hideBackgroundOptions.includes(this.props.content.type)) ? false : true
    const showAnchorIdTextfield = (this.state.showAnchorIdTextfield.includes(this.props.content.type)) ? true : false
    const showPublishingSettings = (this.state.showPublishingSettings.includes(this.props.content.type)) ? true : false
    const showTextAlignOptions = (this.state.showTextAlignOptions.includes(this.props.content.type)) ? true : false
    const showDefaultTocStatus = (this.state.showDefaultTocStatus.includes(this.props.content.type)) ? true : false

    const visibilityStart = (this.props.content.section && this.props.content.section.visibilityStart)
      ? new Date(this.props.content.section.visibilityStart)
      : new Date()

    const visibilityEnd = (this.props.content.section && this.props.content.section.visibilityEnd)
      ? new Date(this.props.content.section.visibilityEnd)
      : new Date()

    const anchorId = (this.props.content.section && this.props.content.section.anchorId) ? this.props.content.section.anchorId : ""
    const background = (this.props.content.section && this.props.content.section.background) ? this.props.content.section.background : ""
    const spacing = (this.props.content.section && this.props.content.section.spacing) ? this.props.content.section.spacing : ""

    const text_align = (this.props.content.section && this.props.content.section.text_align) ? this.props.content.section.text_align : "left"
    const toc_default_status = (this.props.content.section && this.props.content.section.toc_default_status) ? this.props.content.section.toc_default_status : "close"

    return (
      <div className="dd-block__settings">
        <div className="dd-block__edit-section">
          {showAnchorIdTextfield &&
            <>
              <p className="dd-block__settings-text">
                Falls diese Headline im manuellen Inhaltsverzeichnis vorhanden sein soll, vergeben Sie eine Anchor ID, welche in der URL angezeigt wird.
              </p>
              <div className="row">
                <div className="col-md-6">
                  <Textfield
                    name="anchorId"
                    type="text"
                    label="Anchor ID"
                    handleChange={(e) => this.onChangeAnchorId(e)}
                    defaultValue={anchorId}
                    isRequired={false}
                    errorHandler={this.errorHandler}
                    />
                </div>
              </div>
            </>
          }
          {showBackgroundOptions && !showDefaultTocStatus &&
            <p className="dd-block__settings-text">
              An dieser Stelle lässt sich der Bereich, in dem das Modul platziert ist, beeinflussen.
            </p>
          }
          <div className="row">
            {showBackgroundOptions && !showDefaultTocStatus &&
              <div className="col-md-6">
                <SimpleSelectfield
                  label="Hintergrundfarbe"
                  name="sectionBackground"
                  firstOptionValue=""
                  firstOptionLabel="Bitte auswählen"
                  indexLabel="id"
                  optionLabel="option"
                  options={this.state.sectionBackgroundOptions}
                  onSelect={this.onSelectBackground}
                  selected={background}
                />
              </div>
            }
            {!showDefaultTocStatus &&
              <div className="col-md-6">
                <SimpleSelectfield
                  label="Abstände"
                  name="sectionSpacing"
                  firstOptionValue=""
                  firstOptionLabel="Bitte auswählen"
                  indexLabel="id"
                  optionLabel="option"
                  options={this.state.sectionSpacingOptions}
                  onSelect={this.onSelectSpacing}
                  selected={spacing}
                />
              </div>
            }
            {showPublishingSettings &&
              <React.Fragment>
                <div className="col-md-6">
                  <label className="selectfield__label">Sichtbar ab (Datum & Zeit)</label>
                  <Flatpickr
                    options={{
                      mode: 'single',
                      dateFormat: "d-m-Y HH:MM",
                      altInput: true,
                      enableTime: true,
                      time_24hr: true,
                      locale: German,
                      altInputClass: 'form-control textfield__input'
                    }}
                    value={visibilityStart}
                    onChange={(date) =>
                      this.onSelect(
                        'visibilityStart',
                        dateToRailsDateString(date[0])
                      )
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label className="selectfield__label">Sichtbar bis (Datum & Zeit)</label>
                  <Flatpickr
                    options={{
                      mode: 'single',
                      dateFormat: "d-m-Y HH:MM",
                      altInput: true,
                      enableTime: true,
                      time_24hr: true,
                      locale: German,
                      altInputClass: 'form-control textfield__input'
                    }}
                    value={visibilityEnd}
                    onChange={(date) =>
                      this.onSelect(
                        'visibilityEnd',
                        dateToRailsDateString(date[0])
                      )
                    }
                  />
                </div>
              </React.Fragment>
            }

            {showTextAlignOptions &&
              <div className="col-md-12">
                <div className="form-check-inline">
                  <label className="radio-button__group-label text-nowrap ">Ausrichtung</label>
                    <RadioButton
                      name="text_align"
                      label="linksbündig"
                      value="left"
                      handleChange={this.onChangeTextAlign}
                      selected={text_align === "left"}
                    />
                    <RadioButton
                      name="grid"
                      label="zentriert"
                      value="center"
                      handleChange={this.onChangeTextAlign}
                      selected={text_align === "center"}
                    />
                </div>
              </div>
            }

            {showDefaultTocStatus &&
              <div className="col-md-12">
                <div className="form-check-inline">
                  <label className="radio-button__group-label text-nowrap ">Inhaltsverzeichnis anzeigen</label>
                    <RadioButton
                      name="toc_default_status"
                      label="offen"
                      value="open"
                      handleChange={this.onChangeDefaultTocStatus}
                      selected={toc_default_status === "open"}
                      />
                    <RadioButton
                      name="toc_default_status"
                      label="geschlossen"
                      value="close"
                      handleChange={this.onChangeDefaultTocStatus}
                      selected={toc_default_status === "close"}
                      />
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default BlockSectionSettings
