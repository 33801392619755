import React, {useEffect, useState} from "react";
import {deleteEventParticipant, getEventParticipants} from "../../data/api";
import {formatShortDate, getToken} from "../../lib/lib";
import Loader from "../Shared/atom/Loader/Loader";
import Searchbar from "../Shared/element/Searchbar/Searchbar";
import TableColumnHeader from "../../layout/Tables/TableColumnHeader";
import Pagination from "../Shared/element/Pagination/Pagination";
import Icon from "../Shared/atom/Icon/Icon";
import DeleteConfirmOverlay from "../Shared/module/Overlay/DeleteConfirmOverlay";

export default function EventParticipants({event}) {

    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [pagesCount, setPagesCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [orderField, setOrderField] = useState('participant_name');
    const [orderDirection, setOrderDirection] = useState('asc');
    const [participants, setParticipants] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [participantToDelete, setParticipantToDelete] = useState();

    const loadData = async () => {
        const offset = currentPage * limit

        const response = await getEventParticipants(
            getToken(),
            event.id,
            limit,
            offset,
            orderField,
            orderDirection,
            query
        )

        setLimit(limit);
        setLoading(false);
        setPagesCount(Math.ceil(response.count / limit));
        setParticipants(response.participants);
    };

    useEffect(() => {
        loadData();
    }, [orderDirection, orderField, limit, currentPage, query]);

    const onFilterChange = (event) => {
        setTimeout(() => {
            setQuery(event.target.value);
            setCurrentPage(0);
        }, 500)
    }

    const handlePaginationClick = async (e, page) => {
        setCurrentPage(page);
    }

    const handleSelectLimit = async (limit) => {
        setLimit(limit)
        setCurrentPage(0)
    }

    const onTableColumnClick = async (column) => {
        setCurrentPage(0)
        if (column === orderField) {
            setOrderDirection(dir => dir === 'desc' ? 'asc' : 'desc')
        } else {
            setOrderField(column);
            setOrderDirection('asc')
        }
    }

    const deleteParticipant = async () => {
        setShowDeleteModal(false);
        await deleteEventParticipant(getToken(), participantToDelete)
        loadData();
        setCurrentPage(0);
    }

    const startDeletionProcess = (id) => {
        setShowDeleteModal(true);
        setParticipantToDelete(id);
    }

    if (loading) {
        return <Loader/>
    }

    return (
        <>
            <table className="table dd-table">
                <thead>
                <tr>
                    <TableColumnHeader
                        name="Name"
                        field="participant_name"
                        currentOrderField={orderField}
                        currentOrderDirection={orderDirection}
                        onClick={onTableColumnClick}
                    />
                    <TableColumnHeader
                        name="Anmeldedatum"
                        field="registration_date"
                        currentOrderField={orderField}
                        currentOrderDirection={orderDirection}
                        onClick={onTableColumnClick}
                    />
                    <TableColumnHeader
                        name="E-Mail"
                        field="email"
                        currentOrderField={orderField}
                        currentOrderDirection={orderDirection}
                        onClick={onTableColumnClick}
                    />
                    <th scope="col" className="dd-table--actions-th">
                        <div
                            className="dd-search-bar dd-search-bar--small dd-search-bar--white dd-table--search-filter">
                            <Searchbar
                                placeholder="Teilnehmer filtern …"
                                icon="filter"
                                onChangeCallback={(e) => onFilterChange(e)}
                            />
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    participants?.map((participant) =>
                        <tr key={participant.id}>
                            <td>{participant.firstname} {participant.lastname}</td>
                            <td>{formatShortDate(participant.registration_date)}</td>
                            <td>
                                <a href={`mailto:${participant.email}`}>
                                    {participant.email}
                                </a>
                            </td>
                            <td className="justify-content-center dd-table--actions">
                                <div className="dd-table--actions_wrapper">
                                    <button type="submit" className="dd-btn dd-btn--danger dd-btn--icon-only mr-2" onClick={() => startDeletionProcess(participant.id)}>
                                        <Icon icon="trash" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    )
                }
                {
                    participants.length === 0 &&
                    <tr>
                        <td colSpan={5} className="text-center">Keine Teilnehmer verfügbar</td>
                    </tr>
                }
                </tbody>
            </table>

            {
                participants.length > 0 &&
                <div className="float-right">
                    <Pagination
                        total={pagesCount}
                        current={currentPage}
                        onChange={handlePaginationClick}
                        onSelect={handleSelectLimit}
                        limit={limit}
                    />
                </div>
            }

            {
                showDeleteModal &&
                <DeleteConfirmOverlay
                    closeOverlay={() => setShowDeleteModal(false)}
                    onConfirmation={() => deleteParticipant()}
                    mode={'total_delete'}
                />
            }
        </>
    )
}