import React from "react";

import DateSelectfield from "../Shared/atom/Selectfield/DateSelectfield";
import SimpleSelectfield from "../Shared/atom/Selectfield/SimpleSelectfield";
import TextfieldGroup from "../Shared/atom/TextfieldGroup/TextfieldGroup";
import TagList from "../Shared/atom/TagList/TagList";

import { getTerms } from "../../data/api";
import { getToken } from "../../lib/lib";

class PersonInternalData extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      chambers: [],
      nationalities: [],
      admission_years:[]
    }
  }

  async componentDidMount() {
    const chambers = await getTerms(getToken(), 'chambers')
    const nationalities = await getTerms(getToken(), 'nationalities')

    const date = new Date();
    const year = date.getFullYear();

    const admission_years = []

    for (let i = year; i >= 1968; i--) {
      admission_years.push({
        id: i,
        year: i
      })
    }

    this.setState({
      chambers: chambers.chambers,
      nationalities: nationalities.nationalities,
      admission_years
    })
  }



  render() {

    const dob = this.props.person.dob || "1900-01-01"

    return (
      <div>
        <div className="dd-form-section dd-form-section--transparent">
          <div className="dd_form-notice-message">
            Ausschließlich zur internen Verwendung – diese Angaben werden <b>nicht</b> veröffentlicht.
          </div>
        </div>
        <form>
          <div className="" id="form_interne-angaben">
            <div className="dd-form-section dd-form-section--small dd-form-section--first dd-form-section--combined dd-form-section--member">
              <div className="dd-form-section__content">

                <div className="d-flex flex-column dd_tag__row">
                  <div className="row">
                    <div className="col-md-4 d-flex justify-content-md-end align-items-center">
                      <label className="mb-0">
                        Zulassungsjahr
                      </label>
                    </div>
                    <div className="col-md-8">
                      <div className="col-3">
                        <SimpleSelectfield
                          label={false}
                          name="admission_year"
                          firstOptionValue={0}
                          firstOptionLabel="Bitte auswählen"
                          indexLabel="id"
                          optionLabel="year"
                          options={this.state.admission_years}
                          selected={this.props.person.admission_year}
                          onSelect = { this.props.onSelectAdmissionYear }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          {this.state.chambers.length &&
            <div className="dd-form-section dd-form-section--small dd-form-section--combined dd-form-section--member">
              <div className="dd-form-section__content">
                <div className="d-flex flex-column dd_tag__row">
                  <div className="row">
                    <div className="col-md-4 d-flex justify-content-md-end align-items-center">
                      <label className="label--single" htmlFor="Sprachen">
                        Kammerzulassung
                      </label>
                    </div>
                    <div className="col-md-8 d-flex align-items-center">
                      <TagList
                        name="Kammerzulassung"
                        indexLabel='title_de'
                        data={this.props.person.chambers}
                        availableData={this.state.chambers}
                        onUpdate={(assignedTags) => this.props.chambersUpdate(assignedTags)}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            }

            {this.state.nationalities.length &&
            <div className="dd-form-section dd-form-section--small dd-form-section--combined dd-form-section--member">
              <div className="dd-form-section__content">
                <div className="d-flex flex-column dd_tag__row">
                  <div className="row">
                    <div className="col-md-4 d-flex justify-content-md-end align-items-center">
                      <label className="label--single" htmlFor="Sprachen">
                        Nationalität
                      </label>
                    </div>
                    <div className="col-md-8 d-flex align-items-center">
                      <TagList
                        name="Nationalität"
                        indexLabel='title_de'
                        data={this.props.person.nationalities}
                        availableData={this.state.nationalities}
                        onUpdate={(assignedTags) => this.props.nationalitiesUpdate(assignedTags)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }

            <div className="dd-form-section dd-form-section--small dd-form-section--last dd-form-section--member">
              <div className="dd-form-section__content">
                <div className="d-flex flex-column dd_tag__row">
                  <div className="row">
                    <div className="col-md-4 d-flex justify-content-md-end align-items-end">
                      <label className="label--single">
                        Geburtstag
                      </label>
                    </div>
                    <div className="col-md-8">
                      <div className="col-6">
                        <TextfieldGroup isDob={true}>
                          <DateSelectfield
                            dateAsString={dob}
                            onChange={this.props.onChange}
                          />
                        </TextfieldGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>
    )
  }
}

export default PersonInternalData;