import React from "react";

import Icon from "../../atom/Icon/Icon";
import RadioButton from "../../atom/RadioButton/RadioButton";
import Textfield from "../../atom/Textfield/Textfield";
import Checkbox from "../../atom/Checkbox/Checkbox";

import BlockSectionSettings from './BlockSectionSettings';

import { ImagePreviewBlock } from "../../element/ImagePreview/ImagePreview";
import ImageSelector from "../ImageSelector/ImageSelector";
import Overlay from "../../element/Overlay/Overlay";
import BlockPreviewEmbeddedVideo from "./BlockPreviews/EmbeddedVideoPreview";
import { Link, withRouter } from "react-router-dom";
import { AppContext } from "../../../../data/ContextProvider";
import BlockActions from "./BlockActions";

class BlockEmbeddedVideoEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {},
      preview: true,
      imageOverlayActive: false,
    }
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  toggleField = (e, field) => {
    const content = this.state.content
    content[field] = !this.state.content[field]
    this.saveContent(content)
  }

  onChangeRadio = (event, field) => {
    const content = this.state.content
    content[field] = event.target.value
    this.saveContent(content)
  }

  onChange = (event) => {
    const content = this.state.content
    content[event.target.name] = event.target.value
    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  openImageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      imageOverlayActive: true
    })
  }

  closeImageSelectorOverlay = () => {
    this.setState({
      imageOverlayActive: false,
    })
  }

  handleImageSelect = (file, format) => {
    const content = this.state.content
    content.imagePath = file.file_path
    content.imageID   = file.id
    content.imageFormat  = format

    this.saveContent(content)
    this.closeImageSelectorOverlay()
  }

  handleImageDelete = () => {
    const content = this.state.content
    content.imageID = 0
    content.imageFormat = ""
    content.imagePath = ""
    this.saveContent(content)
  }

  componentDidMount() {
    if (this.props.initData) {
      const preview = this.props.preview
      const content = this.props.initData[0]

      this.setState({ content, preview })
    }
  }

  render() {
    const lang = this.props.match.params.lang

    return (
        <React.Fragment>
        {this.state.preview
          ? <div className="dd-block" onClick={() => this.togglePreviewEditMode()}>
              <div className="dd-block__preview">
                <BlockPreviewEmbeddedVideo block={this.state.content} />
              </div>
            </div>
          :
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-video" /> Eingebettetes Video
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }

              <div className="dd-block__edit-section">
                <div className="form-group">
                  <Textfield
                    name="videoURL"
                    type="text"
                    isRequired={true}
                    label=""
                    placeholder="Video URL-Adresse*"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.videoURL}
                    errorHandler={this.props.errorHandler}
                  />
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col col-lg-4">
                      <div className="form-check">
                        <label className="radio-button__group-label">Anbieter</label>

                        <RadioButton
                          name={`/${this.props.blockKey}-provider`}
                          label="YouTube"
                          value="youtube"
                          handleChange={(e) => this.onChangeRadio(e, "provider")}
                          selected={this.state.content.provider === "youtube"}
                        />
                        <RadioButton
                          name={`/${this.props.blockKey}-provider`}
                          label="Vimeo"
                          value="vimeo"
                          handleChange={(e) => this.onChangeRadio(e, "provider")}
                          selected={this.state.content.provider === "vimeo"}
                        />

                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-check">
                         <Checkbox
                            label="Automatisch starten?"
                            id="autoplay"
                            selected={this.state.content.autoplay}
                            onChange={(e) => this.toggleField(e, "autoplay")}
                          />

                        {(this.state.content.provider === "youtube") &&
                          <React.Fragment>
                            <Checkbox
                              label="Datenschutzmodus aktivieren?"
                              id="controls"
                              selected={this.state.content.privacyMode}
                              onChange={(e) => this.toggleField(e, "privacyMode")}
                            />
                            <Checkbox
                              label="Player-Steuerelemente anzeigen?"
                              id="controls"
                              selected={this.state.content.controls}
                              onChange={(e) => this.toggleField(e, "controls")}
                            />
                          </React.Fragment>
                        }
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-check">
                        <Textfield
                          name="width"
                          type="text"
                          isRequired={true}
                          label=""
                          placeholder="Breite*"
                          handleChange={this.onChange}
                          defaultValue={this.state.content.width}
                          errorHandler={this.props.errorHandler}
                        />

                        <Textfield
                          name="height"
                          type="text"
                          isRequired={true}
                          label=""
                          placeholder="Höhe*"
                          handleChange={this.onChange}
                          defaultValue={this.state.content.height}
                          errorHandler={this.props.errorHandler}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="dd-block__edit-section">
                <h5>Datenschutz</h5>
                <p className="hint-text">
                  Dieses Modul bindet einen Inhalte mithilfe eines externen Dienstes ein, der erst nach
                  Zustimmung des Nutzers geladen werden darf. Erteilt der Nutzer keine globale Zustimmung im
                  entsprechenden Popup beim ersten Seitenaufruf, muss der Aufruf des Inhalts blockiert werden.
                  Anstelle des eingebetteten Videos wird dann ein Platzhalter-Bild und ein Button zum Laden des eingebetteten Videos angezeigt (Zwei-Klick-Lösung).
                  Die Texte für Anfragen zur Zustimmung und den Button können Sie im Admin-Bereich unter <Link to={`/${lang}/admin/tracking#${this.state.content.provider}`}>Tracking und externe Dienste</Link> bearbeiten.
                </p>
                <div className="row">
                  <div className="col">

                  <label className="textfield__label">Platzhalter-Bild vor Anzeige des Inhaltes</label>
                    <p className="hint-text">Dieses Bild wird angezeigt, wenn der Besucher keine Zustimmung zur Anzeige des externen Inhaltes erteilt hat.</p>

                    <div className="dd-image-picker">
                      {this.state.content.imagePath ?
                        <ImagePreviewBlock
                          imagePath={this.state.content.imagePath}
                          imageId={this.state.imageId}
                          handleImageDelete={this.handleImageDelete}
                          openImageSelectorOverlay={this.openImageSelectorOverlay}
                        />
                        :
                        <button className="dd-btn dd-btn--primary dd-image-picker__choose" onClick={(e) => this.openImageSelectorOverlay(e)}>
                          Bild auswählen
                        </button>
                      }
                    </div>

                    {this.state.imageOverlayActive &&
                      <Overlay closeOverlayCallback={() => this.closeImageSelectorOverlay()}>
                        <ImageSelector
                          selectImageCallback={(file, format) => this.handleImageSelect(file, format)}
                          fileTypes={["image"]}
                          selectedAssetId={this.state.content.imageID || ''}
                          selectedFormat={this.state.content.imageFormat || ''}
                          allowedFormats={[
                            "original",
                            "five_by_two",
                            "sixteen_by_nine"
                          ]}
                        />
                      </Overlay>
                    }
                  </div>
                </div>
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
          }
        </React.Fragment>
    )
  }
}

BlockEmbeddedVideoEdit.contextType = AppContext
export default withRouter(BlockEmbeddedVideoEdit);
