import React from "react";
import LangChooserSelect from "./LangChooserSelect";
import LangSelector from "./LangSelector";
import LangSwitcher from "./LangSwitcher";


export const TableColumnHeaderLang = props => {
  return (
    <th width="90" className="languages--table-view">
      <div className="languages__wrapper">
        <LangChooserSelect
          lang={props.lang}
          onSelect={(lang) => props.setLang(lang)}
        />
      </div>
    </th>
  )
}

export const TableColumnBodyLang = props => {
  return (
    <td className="languages--table-view">
      <div className="publish-status no-label">
        <span className="publish-status--live">Live</span>
        <span className="publish-status--offline">Offline</span>
      </div>
      <div className="languages__wrapper">
        <LangSelector
          item={props.item}
          onTranslate={(lang) => props.translateItem(props.item, lang)}
          onSelect={(lang) => props.selectTranslation(props.item, lang)}
        />
      </div>
    </td>
  )
}

export const SidebarLang = props => {
  return (
    <div className="sidebar">
      <div className={`publish-status ${props.item.published ? 'publish-status--is-live' : 'publish-status--is-offline'}`}>
        <span className="publish-status--live">Live</span>
        <span className="publish-status--offline">Offline</span>
      </div>
      <LangSwitcher
        itemType={props.itemType}
        item={props.item}
        controller={props.controller}
        onTranslate={(lang) => props.translatePage(props.item, lang)}
      />
    </div>
  )
}


