import React from 'react'
import { Switch, Route } from 'react-router-dom'

import LoginLayout from './layout/LoginLayout'
import Layout from './layout/Layout'

import Home from './pages/home'
import LoginPage from './pages/LoginPage'
import Pages from './pages/Pages'
import Offices from './pages/Offices'
import Office from './pages/Office'
import Events from './pages/Events'
import Jobs from './pages/Jobs'
import Articles from './pages/Articles'
import Products from './pages/Products'
import Media from './pages/Media'
import Admin from './pages/Admin'
import Loader from "./components/Shared/atom/Loader/Loader";

import { checkToken, getToken } from './lib/lib'
import { getCompany, getLanguages } from "./data/api";
import { AppContext } from './data/ContextProvider'


class App extends React.Component {
  constructor() {
    super()
    this.state = {
      checkedToken: false
    }
  }

  async componentDidMount() {
    try {
      const userData = await checkToken()
      if (userData) {
        const userCompany = await getCompany(getToken(), userData.company_id)
        this.context.saveUserData(userData, userCompany.company)

        try {
          const syslanguages = await getLanguages(getToken())
          this.context.saveSysLanguages(syslanguages.syslanguages)
        } catch (error) {
          console.error(error)
        }

      }
    } catch {
      console.info("Erneuter Login notwendig")
    }

    this.setState({
      checkedToken: true
    })
  }

  render() {

    if (!this.context.isUserLoggedIn) {
      if (!this.state.checkedToken) {
        return <Loader />
      }

      return(
        <Switch>
          <Route>
            <LoginLayout>
              <LoginPage />
            </LoginLayout>
          </Route>
        </Switch>
      )
    }

    return (
      <div className="container-fluid dd-base__container">
        <Switch>
          <Layout isDiroLayout={this.context.diroLayout}>
            <Route path="/:lang/pages">
              <Pages />
            </Route>

            <Route path="/:lang/office">
              <Office />
            </Route>

            <Route path="/:lang/offices">
              <Offices />
            </Route>

            <Route path="/:lang/events">
              <Events />
            </Route>
            <Route path="/:lang/jobs">
              <Jobs />
            </Route>
            <Route path="/:lang/products">
              <Products />
            </Route>
            <Route path="/:lang/articles">
              <Articles />
            </Route>
            <Route path="/:lang/media">
              <Media />
            </Route>
            <Route path="/:lang/admin">
              <Admin />
            </Route>
            <Route exact path="/:lang?">
              <Home />
            </Route>

          </Layout>
        </Switch>
      </div>
    )
  }
}

App.contextType = AppContext
export default App
