import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {arrayMoveImmutable} from 'array-move';

import DndRow from "./Dnd-row";
import DndRowPublications from "./DndRowPublications";

import { createID } from "../../../../lib/lib";

class DndList extends React.Component {

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const rows = [...this.props.rows]
    const reorderedRows = arrayMoveImmutable(rows, result.source.index, result.destination.index)

    reorderedRows.map((row, index) => row.weight = index)
    this.props.onChange(reorderedRows)
  }

  onChange = (e, rowIndex) => {
    const rows = this.props.rows
    rows[rowIndex][e.target.name] = e.target.value
    this.props.onChange(rows)
  }

  onChangeCheckbox = (e, rowIndex) => {
    const rows = this.props.rows
    rows[rowIndex][e.target.name] = e.target.checked
    this.props.onChange(rows)
  }

  createNewRow = () => {
    const row = {}
    const rows = this.props.rows
    this.props.fieldNames.forEach((fieldName) => {
      row[fieldName] = ""
    })
    row.weight = rows.length
    row.key  = createID()
    row.lang = this.props.lang || "de"
    rows.push(row)
    return rows
  }

  addRow = () => {
    const rows = this.createNewRow(this.props.rows.length)
    this.props.onChange(rows)
  }

  removeRow = (key) => {
    const rows = this.props.rows.filter(row => row.key !== key);
    this.props.onChange(rows)
  }

  render() {
    let rows = [...this.props.rows]

    if (rows.length === 0) {
      rows = this.createNewRow()
    }

    const className = (this.props.tab && (this.props.tab === "publications")) ? "dnd dnd--no-padding-bottom" : "dnd"

    return (
      <div className={className}>
        <span className="dnd__header">
          <span>{this.props.headlines[0]}</span>
          <span>{this.props.headlines[1]}</span>
          <span>{this.props.headlines[2]}</span>
        </span>

        <DragDropContext onDragEnd={this.onDragEnd} >
          <Droppable droppableId="droppable" type="DndList">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {rows && rows.map((row, index) => (

                  <Draggable key={row.key} draggableId={row.key} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {this.props.tab && (this.props.tab === "publications") ?
                            <DndRowPublications
                              key={index}
                              row={row}
                              fieldNames={this.props.fieldNames}
                              addRow={() => this.addRow()}
                              deleteRow={() => this.removeRow(row.key)}
                              onChange={(e) => this.onChange(e, index)}
                              onChangeCheckbox={(e) => this.onChangeCheckbox(e, index)}
                            />
                            :
                            <DndRow
                              key={index}
                              row={row}
                              fieldNames={this.props.fieldNames}
                              addRow={() => this.addRow()}
                              deleteRow={() => this.removeRow(row.key)}
                              onChange={(e) => this.onChange(e, index)}
                            />
                          }
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    )
  }
}

DndList.defaultProps = {
  isOpen: false
};

export default DndList;
