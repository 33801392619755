import React from "react";
import Icon from "../../../atom/Icon/Icon";

import { contentForBlock } from "../util";

class Headline extends React.Component {
  render() {
    const content = contentForBlock(this.props.block.editor.content);
    const anchorId = this.props.block.section.anchorId;

    return (
      <div className="dd-block-preview dd-block-preview--default">
        <div className="d-flex justify-content-between align-items-center">
          {content.map((item, index) => {
            return <item.tag key={index}>{item.value}</item.tag>;
          })}
          <div className="mb-1">
            {anchorId && <Icon icon="anchor" />}
          </div>
        </div>
      </div>
    );
  }
}

export default Headline;
