import React from "react";
import { Link } from "react-router-dom";

import Textfield from "../Shared/atom/Textfield/Textfield";
import Icon from "../Shared/atom/Icon/Icon";

class FooterBuilderItem extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      itemClassNames: ["dd-navbuilder__item"]
    }
  }

  itemClassName() {
    let itemClassNames = this.state.itemClassNames

    if (this.props.menuItem.is_virtual) {
      itemClassNames.push("is-firstchild")
    }

    if (this.props.menuItem.new) {
      itemClassNames.push("is-new")
    }

    return this.state.itemClassNames.join(' ')
  }

  handleDragMouseOver() {
    const itemClassNames = this.state.itemClassNames
    itemClassNames.push("is-dragged")
    this.setState({
      itemClassNames: itemClassNames
    })
  }

  handleDragMouseOut() {
    const itemClassNames = this.state.itemClassNames
    this.setState({
      itemClassNames: itemClassNames.filter(el => el !== "is-dragged")
    })
  }

  render() {

    const hasError = (this.props.pageWithError === this.props.menuItem.id)

    return (
      <li className={this.itemClassName()}>
        <div className="dd-navbuilder__item-title">
          <Textfield
            name={this.props.menuIndex.toString()}
            label=''
            type="text"
            hasError={hasError}
            defaultValue={this.props.menuItem.navigation_title}
          />
          <div className="dd-navbuilder__item-firstchild-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
              <path d="M20 19a1 1 0 001-1v-1a1 1 0 00-2 0v1a1 1 0 001 1zM20 15a1 1 0 001-1v-1a1 1 0 00-2 0v1a1 1 0 001 1zM27.71 23.29a1 1 0 00-1.42 1.42l2.3 2.29H21v-6a1 1 0 00-2 0v8h9.59l-2.3 2.29a1 1 0 000 1.42 1 1 0 001.42 0l4.7-4.71z"/>
            </svg>
          </div>
        </div>

        <div className="dd-navbuilder__item-actions">
          <div className="dd-navbuilder__item-edit">
            <Link to={`/de/pages/${this.props.menuItem.page_id}/content`}>
              <button className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only">
                <Icon icon="edit" />
              </button>
            </Link>
          </div>
          <div className="dd-navbuilder__item-move">
            <button className="dd-btn dd-btn--secondary dd-btn--icon-only" onMouseOver={() => this.handleDragMouseOver()} onMouseOut={() => this.handleDragMouseOut()}>
              <Icon icon="sorter" />
            </button>
          </div>

          <div className="dd-navbuilder__item-delete">
            <button className="dd-btn dd-btn--primary dd-btn--icon-only"
              onClick={() => this.props.onDelete()}>
              <Icon icon="trash" />
            </button>
          </div>

        </div>
      </li>
    )
  }
}

export default FooterBuilderItem;
