import React from "react";

import Icon from "../../atom/Icon/Icon";
import Overlay from "../../element/Overlay/Overlay";
import Textfield from "../../atom/Textfield/Textfield";
import ImageSelector from "../../module/ImageSelector/ImageSelector";
import { ImagePreviewBlock } from "../../element/ImagePreview/ImagePreview";

import { getToken, imagePathForFormat } from "../../../../lib/lib";
import { imagePath } from "../../../../data/api";

class BlockTextImageSlideEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      fileOverlayActive: false,
      preview: true,
    }
  }

  togglePreviewEditMode(e) {
    e.preventDefault()
    this.setState({
      preview: !this.state.preview
    })
  }

  openImageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      fileOverlayActive: true
    })
  }

  closeFileSelectorOverlay = () => {
    this.setState({
      fileOverlayActive: false
    })
  }

  handleFileSelect = (selectedFile, format) => {
    const item = this.props.item
    item.imageID   = selectedFile.id
    item.imagePath = selectedFile[imagePathForFormat(format)]
    item.fileName = selectedFile.filename
    item.imageFormat  = format
    item.title  = selectedFile.title


    const imgPath = item.imagePath

    this.setState({
      imgPath
    }, () => {
      this.props.onChange(item)
      this.closeFileSelectorOverlay()
    })
  }

  handleImageDelete = () => {
    const item = this.props.item
    item.imageID = 0
    item.imagePath = ""
    item.imageName = ""
    this.setState({ imgPath: "" })
    this.props.onChange(item)
  }

  onChange = (event) => {
    const item = this.props.item
    item[event.target.name] = event.target.value
    this.props.onChange(item)
  }

  async componentDidMount() {

    if (this.props.item.imageID) {
      const imgPath = await imagePath(getToken(), this.props.item.imageID, this.props.item.imageFormat)
      this.setState({ imgPath })
    }

  }

  render() {
    return (
      <>
        {this.state.preview ?
          <div className="dd-mini-block__preview">
            <div className="dd-mini-block__preview-item" onClick={(e) => this.togglePreviewEditMode(e)}>
              <div className="dd-mini-block__preview-image"></div>
              <div className="dd-mini-block__preview-title">{this.props.item.title}&nbsp;</div>
            </div>
          </div>

          :

          <div className="dd-mini-block__edit">
            <div className="dd-mini-block__close">
              <button className="dd-btn dd-btn--icon-only" onClick={(e) => this.togglePreviewEditMode(e)}>
                <Icon icon="close" />
              </button>
            </div>
            <div className="dd-block__edit-section">

              <div className="dd-image-picker">
                {this.state.imgPath ?
                  <ImagePreviewBlock
                    imagePath={this.state.imgPath}
                    imageId={this.props.item.imageID}
                    handleImageDelete={this.handleImageDelete}
                    openImageSelectorOverlay={this.openImageSelectorOverlay}
                  />
                  :
                  <button className="dd-btn dd-btn--primary dd-image-picker__choose" onClick={(e) => this.openImageSelectorOverlay(e)}>
                    Bild auswählen
                        </button>
                }
              </div>
              <div className="form-group" >
                <Textfield
                  className="form-control"
                  name="title"
                  handleChange={this.onChange}
                  defaultValue={this.props.item.title}
                  placeholder="Bildtitel"
                />
              </div>

              {this.state.fileOverlayActive &&
                <Overlay closeOverlayCallback={() => this.closeFileSelectorOverlay()}>
                  <ImageSelector
                    selectImageCallback={(file, format) => this.handleFileSelect(file, format)}
                    selectedAssetId={this.props.item.imageID || ''}
                    selectedFormat={this.props.item.imageFormat || ''}
                    fileTypes={["image"]}
                  />
                </Overlay>
              }

            </div>
          </div>
        }
      </>
    )
  }
}

export default BlockTextImageSlideEdit
