import axios from 'axios'
import { getToken } from '../lib/lib';

import { API_HOST } from "./config";

//--------------- Chatbots -----------------------

export async function getChatbots(lang) {
  const response = await axios.get(
    `${API_HOST}/chatbots?lang=${lang}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': getToken()
    }
  })
  .catch(function (error) {
    console.log(error);
  });

  return response.data
}

export async function getChatbot(id) {
  const response = await axios.get(
    `${API_HOST}/chatbots/${id}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': getToken()
    }
  })
  .catch(function (error) {
    console.log(error);
  });

  return response.data
}

export async function updateChatbot(id, chatbot) {
  return axios.put(
    `${API_HOST}/chatbots/${id}`,
    { chatbot: chatbot },
    {
      headers: {
        'Authorization': getToken()
      }
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

export async function createChatbot(chatbot) {
  return axios.post(
    `${API_HOST}/chatbots`,
    { chatbot: chatbot },
    {
      headers: {
        'Authorization': getToken()
      }
    }
  )
  .then(response => response.data)
  .catch(error => { throw (error.response) })
}

export async function deleteChatbot(id) {
  return axios.delete(
    `${API_HOST}/chatbots/${id}`,
    {
      headers: {
        'Authorization': getToken()
      },
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

//--------------- Export Configurator -----------------------

export async function deleteExport(token, id) {
  return axios.delete(
    `${API_HOST}/exports/${id}`,
    {
      headers: {
        'Authorization': token
      },
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

export async function createExport(token, export_conf) {
  console.log("Hier", export_conf);
  return axios.post(
    `${API_HOST}/exports`,
    { export_conf: export_conf },
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response.data)
    .catch(error => { throw (error.response) })
}

export async function duplicateExport(token, id) {
  return axios.put(
    `${API_HOST}/exports/duplicate/${id}`,
    {},
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response.data)
    .catch(error => { throw (error.response) })
}

export async function getExport(token, id) {
  const response = await axios.get(
    `${API_HOST}/exports/${id}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function getExportDownload(token, id) {
  return axios.get(
    `${API_HOST}/export/${id}`, {
    responseType: 'blob',
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }).then((response) => {
    if (response.blob) return response.blob()
    return response.data
  }).catch(function (error) {
    console.log(error);
  });
}

export async function getExports(token, limit, offset, orderField, orderDirection, query) {
  const response = await axios.get(
    `${API_HOST}/exports?limit=${limit}&offset=${offset}&order=${orderField}&dir=${orderDirection}&query=${query}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  })
  .catch(function (error) {
    console.log(error);
  });

  return response.data
}

export async function updateExport(token, id, export_conf) {
  return axios.put(
    `${API_HOST}/exports/${id}/edit`,
    { export: export_conf },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}
