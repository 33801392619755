import React from 'react'
import { withRouter } from 'react-router-dom'

import Flatpickr from 'react-flatpickr'
import { German } from 'flatpickr/dist/l10n/de.js'

import RadioButton from '../Shared/atom/RadioButton/RadioButton'
import { dateToRailsDateString } from '../../lib/lib'


class EventPublishing extends React.Component {

  render() {

    const published_since = this.props.event.published_since
      ? new Date(this.props.event.published_since)
      : new Date()
    const published_until = this.props.event.published_until
      ? new Date(this.props.event.published_until)
      : new Date()

    return (
      <React.Fragment>
        <div className="form-row border-bottom border-black">
          <div className="form-group mt-3">
            <label className="textfield__label">
              Veröffentlichungsstatus
            </label>
            <RadioButton
              name="published"
              label="Entwurf"
              handleChange={(e) => this.props.onChange(e.target.name, !e.target.checked)}
              selected={!this.props.event.published}
            />
            <RadioButton
              name="published"
              label="Veröffentlicht"
              handleChange={(e) => this.props.onChange(e.target.name, e.target.checked)}
              selected={this.props.event.published}
            />
          </div>
        </div>
        <div className="form-row border-bottom border-black">
          <div className="form-group mt-3">
            <label className="textfield__label">Freigabe für …</label>
            <RadioButton
              name="released_for"
              label={[
                "Nur intern",
                <a key="intranet_link" href="/">
                  &nbsp;(Intranet)
                </a>
              ]}
              value="0"
              handleChange={(e) => this.props.onChange(e.target.name, Number(e.target.value))}
              selected={this.props.event.released_for === 0}
            />
            <RadioButton
              name="released_for"
              label="Öffentlich (www)"
              value="1"
              handleChange={(e) => this.props.onChange(e.target.name, Number(e.target.value))}
              selected={this.props.event.released_for === 1}
            />
          </div>
        </div>
        <div className="form-row border-bottom border-black">
          <div className="form-group mt-3">
            <label className="textfield__label">
              Soll dieses Event zeitgesteuert veröffentlicht werden?
            </label>
            <RadioButton
              name="instant_release"
              label="Nein (Veröffentlichung sofort und ohne Ablaufdatum)"
              handleChange={(e) => this.props.onChange(e.target.name, !e.target.checked)}
              selected={!this.props.event.instant_release}
            />
            <RadioButton
              name="instant_release"
              label="Ja"
              handleChange={(e) => this.props.onChange(e.target.name, e.target.checked)}
              selected={this.props.event.instant_release}
            />
          </div>
        </div>

        {this.props.event.instant_release &&
          <React.Fragment>
            <div className="form-group mt-3">
              <div className="row">
                <div className="col-3">
                  <label className="textfield__label">Ab dem ...</label>
                  <Flatpickr
                    options={{
                      mode: 'single',
                      dateFormat: 'd-m-Y HH:MM',
                      altInput: true,
                      enableTime: true,
                      time_24hr: true,
                      locale: German,
                      altInputClass: 'form-control textfield__input'
                    }}
                    value={published_since}
                    onChange={(date) => this.props.onChange('published_since', dateToRailsDateString(date[0]))}
                  />
                </div>
              </div>
            </div>
            <div className="form-group mt-3">
              <div className="row">
                <div className="col-3">
                  <label className="textfield__label">Bis zum ...</label>
                  <Flatpickr
                    options={{
                      mode: 'single',
                      dateFormat: 'd-F-Y H:i',
                      altInput: true,
                      enableTime: true,
                      time_24hr: true,
                      locale: German,
                      altInputClass: 'form-control textfield__input'
                    }}
                    value={published_until}
                    onChange={(date) => this.props.onChange('published_until', dateToRailsDateString(date[0]))}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

export default withRouter(EventPublishing)
