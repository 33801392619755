import React from "react";
import Checkbox from "../../atom/Checkbox/Checkbox";
import Icon from "../../atom/Icon/Icon";

class BlockTeamMemberEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      selectedAdvocacy: this.props.member.advocacies,
      // loading: true,
    }
  }

  onCheckAdvocacy = (e, advocacyId) => {
    let selectedAdvocacy = [...this.state.selectedAdvocacy]
    if (this.state.selectedAdvocacy.includes(advocacyId)) {
      selectedAdvocacy = selectedAdvocacy.filter(number => number !== advocacyId);
    } else {
      selectedAdvocacy.push(advocacyId)
    }
    this.setState({
      selectedAdvocacy
    })
    this.props.onChangeAdvocacies(this.props.member.userID, selectedAdvocacy)
  }

  toggleEditMode = () => {
    this.setState({
      edit: !this.state.edit
    })
  }

  renderEdit() {
    return (
      <div className="dd-block__edit">
        <button className={"dd-btn dd-btn--icon-only dd-block__edit-close"} onClick={this.toggleEditMode}>
          <Icon icon="plus" />
        </button>
        <div className="dd-block__edit-section">
          <div className="dd-mini-block__preview-title">{this.props.member.full_name}&nbsp;</div>
          <div className="dd-mini-block__preview-title" style={{fontWeight:"normal"}}>
          Anzeige der Fachanwaltschaft:<br/>
          <br/>
          {this.props.advocacies.map((advocacy) => {
            let title_key = "title_de";
            return <Checkbox
                label={advocacy[title_key]}
                id={advocacy.id}
                key={advocacy.id}
                selected={!this.state.selectedAdvocacy.includes(advocacy.id)}
                onChange={(e) => this.onCheckAdvocacy(e, advocacy.id)}
              />
            })}
          </div>
        </div>
      </div>)
  }

  render() {
    // console.table(this.props.member)
    if (this.state.edit) {
      return this.renderEdit()
    }
    return (
      <div className="dd-mini-block__preview">
        <div className="dd-mini-block__preview-item">
          <div className="dd-mini-block__preview-image"></div>
          <div className="dd-mini-block__preview-title">{this.props.member.full_name}&nbsp;</div>
          {(this.props.advocacies && (this.props.advocacies.length >= 1) && (this.state.selectedAdvocacy)  &&
            <div className="dd-mini-block__preview-meta" onClick={this.toggleEditMode}>Anzeige bearbeiten</div>
          )}
        </div>
      </div>
    )
  }
}

export default BlockTeamMemberEdit
