import React from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {arrayMoveImmutable} from 'array-move';

import Loader from "../Shared/atom/Loader/Loader";
import Editbar from '../Shared/element/Editbar/Editbar'
import Searchbar from "../Shared/element/Searchbar/Searchbar";
import Pagination from "../Shared/element/Pagination/Pagination";
import Range from "../Shared/atom/Range/Range";
import NavBuilderItem from './NavBuilderItem';
import LangSwitcherStatic from "../Shared/element/LangTools/LangSwitcherStatic";

import { getNotInMenu, updatePages, getMenu, getHomepage, getLanguages } from "../../data/api";
import { getToken, createID } from "../../lib/lib";

class NavBuilder extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeLanguage: "de",
      query: '',
      loading: true,
      hasError: false,
      errorMessage: "",
      pageWithError: 0,
      visibleMenuDepth: 3,
      limit: 10,
      count: 0,
      pagesCount: 0,
      currentPage: 0,
      orderField: 'updated_at',
      orderDirection: 'desc',
      page: {},
      pages: [],
      menu: []
    }
  }

  handleMenuDepthChange = (event) => {
    this.setState({
      visibleMenuDepth: event.target.value
    })
  }

  setActiveLanguage = (shorthand) => {
    this.setState({
      activeLanguage: shorthand
    }, async () => {
      await this.loadData()
    })
  }

  handleTitleUpdate = (event, index) => {
    const menu = [...this.state.menu]
    menu[index].navigation_title = event.target.value
    this.setState({ menu })
  }

  handlePaginationClick = async (e, page) => {
    this.setState({
      ...this.state,
      currentPage: page
    }, async () => {
        await this.loadJustPages()
    })
  }

  handleSelectLimit = (limit) => {
    this.setState({ limit, currentPage: 0 }, async () => {
      await this.loadJustPages()
    })
  }

  onFilterChange = (event) => {
    this.setState({
      query: event.target.value,
      currentPage: 0
    }, () => {
        this.loadJustPages()
    })
  }

  onChange = (event) => {
    const page = this.state.page
    page.title = event.target.value

    this.setState({ page: page, hasError: false }, () => {

    })
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const menu = [...this.state.menu]
    const reorderedMenu = arrayMoveImmutable(menu, result.source.index, result.destination.index)

    reorderedMenu.map((menuItem, index) => menuItem.weight = index)

    this.setState({
      ...this.state,
      menu: reorderedMenu
    }, () => {
      updatePages(getToken(), this.state.menu)
        .then(newPage => {
          this.loadData()
        })
        .catch(err => {
          this.setState({ isUpdating: false })
        })
    })
  }

  addToMenu = (page) => {

    const pages = [...this.state.pages]
    const menu  = [...this.state.menu]

    // const maxWeight = Math.max(...this.state.menu.map(page => page.weight))

    page.in_menu = true
    page.depth = 1
    page.has_children = false //reset
    page.parentid = 0     //reset
    page.p1 = 0     //reset
    page.p2 = 0     //reset
    page.p3 = 0     //reset
    page.key = createID()
    page.new = true
    page.weight = 0

    const index = this.state.pages.findIndex(s => s.id === page.id)

    if (index >= 0) {
      pages[index] = page
    }

    menu.unshift(page)
    this.setState({ menu, pages })
  }

  onIndentChange = (page, depth) => {

    const index = this.state.menu.findIndex(s => s.id === page.id)

    if (index >= 0) {
      const menu = [...this.state.menu]

      if (index > 0) {
        const previousMenuItem = menu[index-1]

        // Indent, hier nicht mehr als ein Schritt zulassen
        if (menu[index].depth < depth) {
          // Einrückung um mehr als einen Schritt => reset
          if ((depth - previousMenuItem.depth) > 1) {
            menu[index].depth = previousMenuItem.depth + 1
          } else {
            menu[index].depth = depth || 1
          }
        } else {
          menu[index].depth = depth || 1
        }
      }

      this.setState({ menu }, () => {
      })
    }
  }

  onMenuDelete = (page) => {
    const index = this.state.menu.findIndex(s => s.id === page.id)
    if (index >= 0) {

      const menu = [...this.state.menu]

      menu[index].depth   = 1 // reset
      menu[index].in_menu = false
      menu[index].has_children = false
      menu[index].parentid = 0
      menu[index].p1 = 0
      menu[index].p2 = 0
      menu[index].p3 = 0
      menu[index].weight = 0

      this.setState({ menu }, () => {
      })
    }
  }

  updateMatPath = (menu) => {

    this.state.menu.forEach((page) => page.parents = [0])

    let current_level = 1
    let parentID = 0
    let lastPage = null
    const parents = [0]

    menu.forEach((page) => {
      page.p1 = 0
      page.p2 = 0
      page.p3 = 0
      page.has_children = false

      if (page.depth > current_level) {

        if (lastPage) {
          lastPage.has_children = true
        }

        page.parentid = lastPage.id
        parents.push(parentID)

      } else if (page.depth < current_level) {

        let pop_step = current_level - page.depth

        Array(pop_step).fill().map((i) => parents.pop());

        if (lastPage) {
          lastPage.has_children = false //reset
        }
      }

      page.parents = [...parents]

      if (page.parents.length) {
        page.parents.forEach((parentid, index) => {
          if (index === 0) (
            page.p1 = parentid
          )
          if (index === 1) (
            page.p2 = parentid
          )
          if (index === 2) (
            page.p3 = parentid
          )
        })

      }

      current_level = page.depth
      parentID = page.id
      lastPage = page
    })

    return menu
  }

  onSubmit = () => {
    const menu = this.updateMatPath(this.state.menu)

    updatePages(getToken(), menu)
    .then(newPage => {
      this.loadData()
    })
    .catch(err => {
      this.setState({
        isUpdating: false,
        hasError: true,
        errorMessage: err.data.message,
        pageWithError: err.data.page_with_error })
    })
  }

  loadJustPages = async () => {
    try{
      const offset = this.state.currentPage * this.state.limit
      const pages = await getNotInMenu(getToken(), this.state.activeLanguage, this.state.limit, offset, this.state.query)
      const pagesCount = Math.ceil(pages.count / this.state.limit)

      this.setState({
        ...this.state,
        pagesCount: pagesCount,
        count: pages.count,
        pages: pages.pages,
      }, () => {

      })
    } catch(error) {
      //FIXME NotFound
      console.log(error)
    }
  }

  loadData = async () => {
    try {
      const offset = this.state.currentPage * this.state.limit
      const pages = await getNotInMenu(getToken(), this.state.activeLanguage, this.state.limit, offset, this.state.query)
      const menu = await getMenu(getToken(), this.state.activeLanguage)
      const homepage = await getHomepage(getToken(), this.state.activeLanguage)
      const syslanguages = await getLanguages(getToken())

      const pagesCount = Math.ceil(pages.count / this.state.limit)

      this.setState({
        ...this.state,
        pagesCount: pagesCount,
        count: pages.count,
        pages: pages.pages,
        menu:  menu.menu,
        homepage: homepage.homepage,
        syslanguages: syslanguages.syslanguages,
        loading: false,
      }, () => {
      })
    } catch (error) {
      //FIXME NotFound
      console.log(error)
    }
  }

  async componentDidMount() {
    await this.loadData()
  }

  // TMP: So lange page.title noch nicht vorhanden ist
  renderPageType = (page) => {
    // FIXME: Page Type
    return page.type || 'Website'
  }

  renderTitle = (page) => {
    if (page.title !== page.navigation_title) {
      return <>{page.title} <span className="dd-page-select__navigation-title">({page.navigation_title})</span></>
    } else {
      return page.title
    }
  }

  render() {

    if (this.state.loading) {
      return <Loader />
    }

    const filteredMenu  = this.state.menu.filter((page)  => page.in_menu) || []
    const menuIDs  = this.state.menu.map((page)  => page.id) || []
    const filteredPages = this.state.pages.filter((page) => !menuIDs.includes(page.id)) || []


    return (

      <div className="row">
        <div className="col-sm">
          <div className="sidebar">
            <LangSwitcherStatic
              activeLanguage={this.state.activeLanguage}
              syslanguages={this.state.syslanguages}
              setActiveLanguage={(shorthand) => this.setActiveLanguage(shorthand)}
            />
          </div>

          <div className="dd-navbuilder mb-4 ml-3">
            <div className="dd-navbuilder__header">
              <div>Menü-Ebenen anzeigen</div>
              <div className="dd-navbuilder__depth-selector">
                <Range min="1" max="3" onChange={this.handleMenuDepthChange} />
              </div>
            </div>

            <div className="dd-navbuilder__menu" data-visiblemenudepth={this.state.visibleMenuDepth}>

              {this.state.homepage &&
                <ul className="dd-navbuilder__pagelist">
                  <li className="dd-page-select">
                      <button className="dd-page-select__button">
                    <div className="dd-page-select__type">Startseite</div>
                    <div className="dd-page-select__title">{this.state.homepage.title} </div>
                      </button>
                    </li>
                </ul>
              }

              {(this.state.menu.length > 0) &&
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        >
                        { filteredMenu.map((menuItem, index) => (
                              <Draggable key={menuItem.key} draggableId={menuItem.key} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div key={index}>
                                      <NavBuilderItem
                                        menuID={menuItem.id}
                                        menuItem={menuItem}
                                        menuIndex={index}
                                        pageWithError={this.state.pageWithError}
                                        onIndentChange={(depth) => this.onIndentChange(menuItem, depth)}
                                        onDelete={() => this.onMenuDelete(menuItem)}
                                        handleTitleUpdate={(e) => this.handleTitleUpdate(e, index)}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              }
            </div>
          </div>
        </div>

        <div className="col-sm">
          <div className="mb-4">

            <form className="pt-2 mb-4">
              <div className="form-row justify-content-end">
                <Searchbar
                  placeholder="Verfügbare Seiten filtern …"
                  icon="filter"
                  onChangeCallback={(e) => this.onFilterChange(e)}
                />
              </div>
            </form>

            <ul className="dd-navbuilder__pagelist">
              { filteredPages.map((page, index) => {
                // FIXME: eigene Komponente?
                return <li key={index} className="dd-page-select">
                  <button className="dd-page-select__button" onClick={()=>this.addToMenu(page)}>
                    <div className="dd-page-select__type">{this.renderPageType(page)}</div>
                    <div className="dd-page-select__title">{this.renderTitle(page)}</div>
                  </button>
                </li>
              })}
            </ul>
            <Pagination
              total={this.state.pagesCount}
              current={this.state.currentPage}
              onChange={this.handlePaginationClick}
              onSelect={this.handleSelectLimit}
              limit={this.state.limit}
            />

          </div>
        </div>

        <Editbar
          hasPublish={false}
          hasPreview={false}
          hasError={this.state.hasError}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
          errorMessage={this.state.errorMessage}
        />
      </div>
    )
  }
}

export default NavBuilder;
