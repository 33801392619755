import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Textfield from "../Shared/atom/Textfield/Textfield";
import { MainHelperText } from "../Shared/element/HelperText/HelperText";
import { AppContext } from "../../data/ContextProvider";
import { createProduct } from "../../data/api";
import { getToken } from "../../lib/lib";

class ProductNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      product: {}
    }
  }

  errorHandler = (hasError = false) => {
    this.setState({ hasError })
  }

  onChange = (event) => {
    const product = { ...this.state.product };
    product[event.target.name] = event.target.value;
    this.setState({ product })
  }

  onSubmit = () => {
    createProduct(getToken(), this.state.product)
      .then(product => {
        const lang = this.props.match.params.lang
        this.props.history.push(`/${lang}/products/${product.product.id}/content`);
      })
      .catch(err => {
        console.log(err)
      })
  }

  render() {
    const formValid = (this.state.product.title) ? true : false
    const disabled = (this.state.hasError || !formValid) ? true : false

    return (
      <div className="tab-pane fade show active">
        <div className="card-body card-body--standard dd-background dd-background--sand">
          <div className="card-body__content w-75 ml-auto mr-auto">
            <div className="form-group">
              <Textfield
                name="kicker"
                type="text"
                isRequired={false}
                label="Dachzeile"
                handleChange={this.onChange}
                defaultValue={this.state.product.kicker}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="title"
                type="text"
                isRequired={true}
                label="Produkttitel"
                handleChange={this.onChange}
                defaultValue={this.state.product.title}
                errorHandler={this.errorHandler}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="author"
                type="text"
                isRequired={true}
                label="Autor"
                defaultValue={this.context.user.full_name}
                errorHandler={this.errorHandler}
              />
            </div>
            <div className="form-group">
              <label className="textfield__label">Einführungstext</label>
              <textarea
                className="form-control"
                name="teaser"
                defaultValue={this.state.product.teaser}
                onChange={(e) => this.onChange(e)}
              />
            </div>
          </div>
        </div>
        <MainHelperText
          disabled={disabled}
          onSubmit={this.onSubmit}
        />
      </div>
    )
  }
}

ProductNew.contextType = AppContext
export default withRouter(ProductNew)
