import React from "react";

import Icon from "../../atom/Icon/Icon";
import RadioButton from "../../atom/RadioButton/RadioButton";
import Textfield from "../../atom/Textfield/Textfield";
import DnDRows from "../DnDRows/DnDRows";

import BlockPreviewCompetences from './BlockPreviews/Competences';
import BlockSectionSettings from './BlockSectionSettings';
import BlockProcessStep from './BlockProcessStep';

import { createID, getToken } from "../../../../lib/lib";
import { getAdvisoryfields } from "../../../../data/api";
import BlockActions from "./BlockActions";

class BlockProcessStepsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content:{},
      preview: false,
      showSettings: false
    }
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  addRow = (e) => {
    e.preventDefault()
    const content = this.state.content
    content.items.push({
      'icon': '',
      'title': 'Neu',
      'text': '',
      'linkPageID': 0,
      'linkTarget': 0,
      'linkURL': '',
      'key': createID(),
    })
    this.saveContent(content)
  }

  onRowChange = (row, index) => {
    const content = this.state.content

    if (index < content.items.length) {
      content.items[index] = row
      this.saveContent(content)
    }
  }

  onRowsChange = (rows) => {
    const content = this.state.content
    content.items = rows
    this.saveContent(content)
  }

  onChange = (event, name) => {
    const content = this.state.content
    const key = (typeof name !== 'undefined') ? name : event.target.name
    content[key] = event.target.value
    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  loadAdvisoryfields = async (pageID) => {
    const advisoryfields = await getAdvisoryfields(getToken())
    this.setState({
      advisoryfields: advisoryfields.advisoryfields,
    }, () => {
    })
  }


  async componentDidMount() {
    if (this.props.initData) {
      const preview = this.props.preview
      const content = this.props.initData[0]

      await this.loadAdvisoryfields()

      this.setState({ content, preview })
    }
  }

  renderPreviews = () => {
    const previews = []
    if (this.state.content.items) {
      this.state.content.items.map((item, index) => {
        return previews.push(
          <BlockProcessStep
            item={item}
            viewmode={this.state.content.viewmode}
            key={item.key}
            advisoryfields={this.state.advisoryfields}
            onChange={(val) => this.onRowChange(val, index)}
          />
        )
      })
    }
    return previews
  }

  render() {

    const viewmode = "viewmode__" + this.props.blockKey

    return (
        <>
        {this.state.preview
          ? <div className="dd-block" onClick={() => this.togglePreviewEditMode()}>
              <div className="dd-block__preview">
                <BlockPreviewCompetences block={this.props.initData} />
              </div>
            </div>
          :
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-multistep" /> Prozess in X Steps
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }

              <div className="dd-block__edit-section">
                <div className="form-group">
                  <div className="form-check">
                    <label className="radio-button__group-label text-nowrap ">Darstellung auswählen</label>
                    <RadioButton
                      name={viewmode}
                      label="Beschreibung mit Icons"
                      value="icons"
                      handleChange={(e) => this.onChange(e, "viewmode")}
                      selected={this.state.content.viewmode === "icons"}
                    />
                    <RadioButton
                      name={viewmode}
                      label="Ohne Icons"
                      value="without_icons"
                      handleChange={(e) => this.onChange(e, "viewmode")}
                      selected={this.state.content.viewmode === "without_icons"}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <Textfield
                    name="title"
                    type="text"
                    label=""
                    placeholder="Kompetenz Titel"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.title}
                  />
                </div>
                <div className="form-group">
                  <div className="dd-mini-block">
                    <div className="dd-mini-block__blocks">
                      <DnDRows
                        rows={this.renderPreviews()}
                        rowsData={this.state.content.items}
                        handleChange={this.onRowsChange}
                      />
                    </div>
                  </div>
                  <div className="dd-mini-block__add">
                    <button className={"dd-btn dd-btn--primary dd-btn--icon-only"} onClick={(e) => this.addRow(e)}>
                      <Icon icon="plus" />
                    </button>
                  </div>
                </div>

              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
          }
        </>
    )
  }
}

export default BlockProcessStepsList
