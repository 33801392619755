import React from 'react'

import SimpleSelectfield from "./SimpleSelectfield";
import { addZeroToNumber } from '../../../../helpers/utils';
import t from "../../../../helpers/t9n";


class DateSelectfield extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedYear: new Date().getFullYear(),
      selectedMonth: new Date().getMonth(),
      selectedDay: new Date().getDate(),
      days:[],
      months:[],
      years:[],
    }
  }

  onChange = (value, field) => {
    const state = this.state
    state[field] = value

    const dob = state.selectedYear + "-" + state.selectedMonth + "-" + state.selectedDay

    this.setState({...state})
    this.props.onChange("dob", dob)
  }

  async componentDidMount() {

    let {selectedDay, selectedMonth, selectedYear} = this.state

    const initDate = this.props.dateAsString || null

    let day = 0
    const days   = []
    const months = []
    const years  = []
    const monthLabels = t('date.months')

    for (let i = 1; i <= 31; i++) {
      day = addZeroToNumber(i.toString())
      days.push({
        id: day,
        day: day
      })
    }

    for (let i = 0; i < 12; i++) {
      months.push({
        id: addZeroToNumber((i + 1).toString()), // format 01 bis 12
        month: monthLabels[i]
      })
    }

    for (let i = 1900; i <= Number(new Date().getFullYear()); i++) {
      years.push({
        id: i,
        year: i
      })
    }

    if (initDate) {
      const initDateParts = initDate.split("-")
      if (initDateParts.length === 3) {
        selectedDay   = initDateParts[2]
        selectedMonth = initDateParts[1]
        selectedYear  = initDateParts[0]
      }
    }

    this.setState({
      selectedYear,
      selectedMonth,
      selectedDay,
      days,
      months,
      years,
    })
  }

  render() {
    if (this.state.days === 0)
      return

    return <>
      <SimpleSelectfield
        label={t('date.day')}
        name="day"
        indexLabel="id"
        optionLabel="day"
        options={ this.state.days }
        onSelect={(value) => this.onChange(value, "selectedDay") }
        selected={this.state.selectedDay}
      />

      <SimpleSelectfield
        label={t('date.month')}
        name="month"
        indexLabel="id"
        optionLabel="month"
        options={this.state.months}
        onSelect={(value) => this.onChange(value, "selectedMonth")}
        selected={this.state.selectedMonth}
      />

      <SimpleSelectfield
        label={t('date.year')}
        name="year"
        indexLabel="id"
        optionLabel="year"
        options={this.state.years}
        onSelect={(value) => this.onChange(value, "selectedYear")}
        selected={this.state.selectedYear}
      />
    </>
    }
}



export default DateSelectfield
