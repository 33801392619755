import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import Textfield from '../Shared/atom/Textfield/Textfield'
import TagList from '../Shared/atom/TagList/TagList'
import TextfieldGroup from '../Shared/atom/TextfieldGroup/TextfieldGroup'
import Loader from '../Shared/atom/Loader/Loader'

import { getTerms, createKeyword } from '../../data/api'
import { getToken } from '../../lib/lib'


class JobMetadata extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      lawfields: [],
      countries: [],
      federalstates: [],
      keywords: []
    }
  }

  addKeywordTag = (newTag) => {
    const keywords = this.state.keywords
    const jobKeywords = this.props.job.keywords
    createKeyword(getToken(), newTag)
    .then((newKeyword) => {
      keywords.push(newKeyword.keyword)
      jobKeywords.push(newKeyword.keyword)
      this.setState({
        keywords
      }, this.props.onChange('keywords', jobKeywords))
    })
    .catch((err) => {
      this.setState({ isUpdating: false })
    })
  }

  loadData = async () => {
    try {
      const lawfields = await getTerms(getToken(), 'lawfields')
      const federalstates = await getTerms(getToken(), 'federalstates')
      const countries = await getTerms(getToken(), 'countries')
      const keywords = await getTerms(getToken(), 'keywords')

      this.setState(
        {
          lawfields: lawfields.lawfields,
          federalstates: federalstates.federalstates,
          countries: countries.countries,
          keywords: keywords.keywords,
          loading: false
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  componentDidMount() {
    this.loadData()
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    const indexLabel = 'title_' + this.props.job.lang

    return (
      <React.Fragment>
        <div className="form-group">
          <TagList
            indexLabel={indexLabel}
            name="Stichworte"
            data={this.props.job.keywords}
            availableData={this.state.keywords}
            label="Stichworte, um die es in dieser Stellenanzeige geht"
            onUpdate={(assignedTags) =>
              this.props.onChange('keywords', assignedTags)
            }
            hasAddNew={true}
            onCreate={(newTag) => this.addKeywordTag(newTag)}
          />
        </div>
        <div className="form-group">
          <label className="textfield__label">
            Kurzbeschreibung der Stellenanzeige (Meta Description)
          </label>
          <textarea
            className="form-control"
            name="metaDescription"
            defaultValue={this.props.job.metaDescription}
            onChange={(e) => this.props.onChange('metaDescription', e.target.value)}
            placeholder="Die Länge der Meta Description sollte 160 Zeichen nicht überschreiten. 130 bis 160 Zeichen sind optimal. Der Inhalt sollte zum Klick auf das Suchergebnis animieren."
          />
        </div>
        <div className="form-group">
          <label className="textfield__label">
            Eintrag, unter dem die Stellenanzeige in der Navigation erscheinen soll
          </label>
          <Textfield
            name="navigation_title"
            type="text"
            isRequired={true}
            handleChange={(e) => this.props.onChange('navigation_title', e.target.value)}
            defaultValue={this.props.job.navigation_title}
            errorHandler={this.props.errorHandler}
          />
        </div>
        <div className="form-group">
          <TagList
            indexLabel={indexLabel}
            name="Rechtsgebiete"
            data={this.props.job.lawfields}
            availableData={this.state.lawfields}
            label="Rechtsgebiete, die in der Ausschreibung angesprochen werden"
            onUpdate={(assignedTags) =>
              this.props.onChange('lawfields', assignedTags)
            }
          />
        </div>
        <div className="form-group">
          <TagList
            indexLabel={indexLabel}
            name="Länder"
            data={this.props.job.countries}
            availableData={this.state.countries}
            label="Besonders relevant für Interessenten aus:"
            onUpdate={(assignedTags) =>
              this.props.onChange('countries', assignedTags)
            }
          />
        </div>
        <div className="form-group">
          <TagList
            indexLabel={indexLabel}
            name="Bundesländer"
            data={this.props.job.federalstates}
            availableData={this.state.federalstates}
            label="Optional - Stellenanzeige ist besonders relevant für Interessenten aus:"
            onUpdate={(assignedTags) =>
              this.props.onChange('federalstates', assignedTags)
            }
          />
        </div>
        <div className="form-group">
          <Textfield
            name="default_path"
            type="text"
            isReadonly={true}
            label="Automatische Adresse für diese Stellenanzeige"
            defaultValue={`${this.props.job.company.website}/${this.props.job.lang}/${this.props.job.default_path}`}
          />
        </div>
        <div className="form-group">
          <TextfieldGroup label="Adresse, unter der diese Stellenanzeige stattdessen auffindbar sein soll">
            <Textfield
              name="home_url"
              type="text"
              isReadonly={true}
              defaultValue={`${this.props.job.company.website}/${this.props.job.lang}/`}
            />
            <Textfield
              name="overridden_path"
              type="text"
              handleChange={(e) => this.props.onChange('overridden_path', e.target.value ) }
              defaultValue={this.props.job.overridden_path}
            />
          </TextfieldGroup>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(JobMetadata)
