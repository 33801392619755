import React, { useEffect, useState } from 'react';
import SimpleMap from '../Shared/element/SimpleMap/SimpleMap'
import Textfield from "../Shared/atom/Textfield/Textfield";
import TextfieldGroup from "../Shared/atom/TextfieldGroup/TextfieldGroup";
import SimpleSelectfield from "../Shared/atom/Selectfield/SimpleSelectfield";
import RadioButton from "../Shared/atom/RadioButton/RadioButton";

import SiteCompanyData from "./SiteCompanyData";
import OfficeOpeningHours from "./OfficeOpeningHours";

import { getFederalstates } from "../../data/api";
import { DACH_COUNTRIES } from "../../data/config";
import { getToken } from "../../lib/lib";

const loadFederalStatesForCountry = async (countryID) => {
  if (DACH_COUNTRIES.includes(parseInt(countryID))) {
    const federalstatesData = await getFederalstates(getToken(), countryID)
    return federalstatesData.federalstates
  }
  return []
}

const OfficeSiteEdit = (props) => {
  const [federalstates, setFederalstates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await loadFederalStatesForCountry(props.site.country_id)
      setFederalstates(response);
    };

    fetchData();
  }, [props.site.country_id]);



  const is_subsidiary = "is_subsidiary__" + props.site.id
  const all_workdays = "all_workdays__" + props.site.id

  let formSectionClass = "dd-form-section dd-form-section--first"

  return (
    <React.Fragment>
      <div className={formSectionClass}>
        <div className="dd-form-section__content">
            <div className="row">
            <div className="col-md-6 info-text">
              Hinweis: Mit einem Stern(*) gekennzeichnete Felder müssen ausgefüllt werden.
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-2">
              <Textfield
                name="street"
                label="Straße (inkl. Hausnummer)"
                type="text"
                isRequired={true}
                defaultValue={props.site.street}
                handleChange={(e) => props.onChange(props.site.id, e)}
                errorHandler={props.errorHandler}
              />

              <Textfield
                name="address_addon"
                label="Adresszusatz"
                type="text"
                defaultValue={props.site.address_addon}
                handleChange={(e) => props.onChange(props.site.id, e)}
              />

              <TextfieldGroup>
                <Textfield
                  name="zip"
                  label="PLZ"
                  type="text"
                  isRequired={true}
                  defaultValue={props.site.zip}
                  maxLength="7"
                  handleChange={(e) => props.onChange(props.site.id, e)}
                  errorHandler={props.errorHandler}
                />
                <SimpleSelectfield
                  label="Ort"
                  name="city_id"
                  firstOptionValue="0"
                  firstOptionLabel="Bitte auswählen"
                  indexLabel="id"
                  optionLabel="title_de"
                  options={props.cities}
                  onSelect={(value) => props.onSelectCity(props.site.id, value)}
                  selected={props.site.city_id}
                />
              </TextfieldGroup>

              <SimpleSelectfield
                label="Land"
                name="country_id"
                firstOptionValue="0"
                firstOptionLabel="Bitte auswählen"
                indexLabel="id"
                optionLabel="title_de"
                options={props.countries}
                onSelect={(value) => props.onSelectCountry(props.site.id, value)}
                selected={props.site.country_id}
              />

              <SimpleSelectfield
                label="Bundesland"
                name="federalstate_id"
                firstOptionValue="0"
                firstOptionLabel="Bitte auswählen"
                indexLabel="id"
                optionLabel="title_de"
                disabled={((federalstates).length === 0)}
                options={federalstates}
                onSelect={(value) => props.onSelectFederalstate(props.site.id, value)}
                selected={props.site.federalstate_id}
              />


              <label className="radio-button__group-label">Ist dieser Standort eine Zweigstelle?</label><br />
              <div className="form-check form-check-inline">
                <RadioButton
                  name={is_subsidiary}
                  label="Ja"
                  selected={props.site.is_subsidiary}
                  handleChange={(e) => props.changeValue(props.site.id, "is_subsidiary", e.target.checked)}
                />
                <RadioButton
                  name={is_subsidiary}
                  label="Nein"
                  selected={!props.site.is_subsidiary}
                  handleChange={(e) => props.changeValue(props.site.id, "is_subsidiary", !e.target.checked)}
                />
              </div>

            </div>
            <div className="col-md-4 mb-2">
              <Textfield
                name="phone"
                label="Telefonnummer"
                placeholder="+49 1234 56789 - ohne Klammern und Trennzeichen"
                type="text"
                defaultValue={props.site.phone}
                handleChange={(e) => props.onChange(props.site.id, e)}
              />

              <Textfield
                name="fax"
                label="Fax-Nummer"
                placeholder="+49 1234 56789 - ohne Klammern und Trennzeichen"
                type="text"
                defaultValue={props.site.fax}
                handleChange={(e) => props.onChange(props.site.id, e)}
              />

              <Textfield
                name="email"
                label="E-Mail Adresse"
                type="email"
                defaultValue={props.site.email}
                handleChange={(e) => props.onChange(props.site.id, e)}
              />

              <Textfield
                name="website"
                label="WWW-Adresse"
                type="text"
                defaultValue={props.site.website}
                handleChange={(e) => props.onChange(props.site.id, e)}
              />

              <Textfield
                name="imprint_url"
                label="Impressum der Website"
                type="text"
                defaultValue={props.site.imprint_url}
                handleChange={(e) => props.onChange(props.site.id, e)}
              />
            </div>
            <div className="col-md-4 mb-2">
              <Textfield
                name="lon"
                label="Longitude"
                type="text"
                defaultValue={props.site.lon}
                handleChange={(e) => props.onChange(props.site.id, e)}
              />
              <Textfield
                name="lat"
                label="Latitude"
                type="text"
                defaultValue={props.site.lat}
                handleChange={(e) => props.onChange(props.site.id, e)}
              />

              <div className="form-group">
                <div>
                  {props.site.lat &&
                    <SimpleMap lat={props.site.lat} lng={props.site.lon} popup={props.site.name} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OfficeOpeningHours
        name={all_workdays}
        site={props.site}
        changeValue={props.changeValue}
        onChange={(e) => props.onChange(props.site.id, e)}
      />
      <SiteCompanyData
        key={props.site.id}
        site={props.site}
        company={props.company}
        companyforms={props.companyforms}
        courts={props.courts}
        signedcontracts={props.signedcontracts}
        cooperationnationals={props.cooperationnationals}
        cooperationinternationals={props.cooperationinternationals}
        contractsUpdate={(value) => props.contractsUpdate(props.site.id, value)}
        cooperationnationalsUpdate={(value) => props.cooperationnationalsUpdate(props.site.id, value)}
        cooperationinternationalsUpdate={(value) => props.cooperationinternationalsUpdate(props.site.id, value)}
        onSelectCompanyForm={(value) => props.onSelectCompanyForm(props.site.id, value)}
        onSelectCourt={(value) => props.onSelectCourt(props.site.id, value)}
        changeValue={props.changeValue}
        onChange={(e) => props.onChange(props.site.id, e)}
        showConfirmationOverlay={props.showConfirmationOverlay}
      />
    </React.Fragment>
  )
}

export default OfficeSiteEdit;
