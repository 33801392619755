import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";
import Checkbox from "../../atom/Checkbox/Checkbox";

import { createID } from "../../../../lib/lib";

import BlockPreviewFiles from './BlockPreviews/Files';
import BlockSectionSettings from './BlockSectionSettings';
import BlockPricingItem from './BlockPricingItem';

import DnDRows from "../../../Shared/module/DnDRows/DnDRows";

import { getToken, imagePathForFormat } from "../../../../lib/lib";
import { getAsset } from "../../../../data/api";
import { AppContext } from "../../../../data/ContextProvider";
import BlockActions from "./BlockActions";

class BlockPricingList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      preview: true,
      showSettings: false,
    }
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    }, () => {
      if (this.state.preview) {
        this.context.toggleBlocksDraggable(false)
      } else {
        // Edit Mode, disable outer dragging
        this.context.toggleBlocksDraggable(true)
      }
    })
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }


  addRow = (e) => {
    e.preventDefault()
    const content = this.state.content
    content.list.push({
      'title': '',
      'number': 0,
      'decription': '',
      'key': createID(),
    })
    this.saveContent(content)
  }

  onRowChange = (row, index) => {
    const content = this.state.content

    if (index < content.list.length ) {
      content.list[index] = row
      this.saveContent(content)
    }
  }

  onRowsChange = (rows) => {
    const content = this.state.content
    content.list = rows
    this.saveContent(content)
  }

  onChange = (event) => {
    const content = this.state.content
    content[event.target.name] = event.target.value
    this.saveContent(content)
  }

  toggleField = (e, field) => {
    const content = this.state.content
    content[field] = !this.state.content[field]
    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  async componentDidMount() {

    let image = {}
    let imagePath = ""

    if (this.props.initData) {
      const preview = this.props.preview
      const content = this.props.initData[0]

      try {
        if (content.imageID) {
          image = await getAsset(getToken(), content.imageID)
          imagePath = image.asset[imagePathForFormat(content.imageFormat)]
        }
      } catch (error) {
        console.log(error)
      }


      this.setState({ content, preview, imagePath })
    }
  }

  renderPreviews = () => {
    const previews = []
    if (this.state.content.list) {
      this.state.content.list.map((item, index) => {
        return previews.push(
          <BlockPricingItem
            item={item}
            key={item.key}
            onChange={(val) => this.onRowChange(val, index)}
          />
        )
      })
    }
    return previews
  }

  render() {

    return (
      <>
        {this.state.preview
          ? <div className="dd-block" onClick={() => this.togglePreviewEditMode()}>
              <div className="dd-block__preview">
                <BlockPreviewFiles block={this.props.initData} />
              </div>
            </div>
          :
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-pricelist" /> Preisliste
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }

              <div className="dd-block__edit-section">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <Checkbox
                      label="Einleitungstext einblenden?"
                      id="show_prolog"
                      selected={this.state.content.show_prolog}
                      onChange={(e) => this.toggleField(e, "show_prolog")}
                    />&nbsp;&nbsp;
                    <Checkbox
                      label="Abschlusstext einblenden?"
                      id="show_epilog"
                      selected={this.state.content.show_epilog}
                      onChange={(e) => this.toggleField(e, "show_epilog")}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <Textfield
                    name="title"
                    type="text"
                    isRequired={true}
                    label=""
                    placeholder="Titel der Preisliste*"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.title}
                    errorHandler={this.props.errorHandler}
                  />
                </div>
                {this.state.content.show_prolog &&
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="prolog"
                      onChange={(e) => this.onChange(e)}
                      defaultValue={this.state.content.prolog}
                      placeholder="Einleitungstext (optional)"
                    />
                  </div>
                }

                <div className="dd-mini-block">
                  <div className="dd-mini-block__blocks">
                    <DnDRows
                      rows={this.renderPreviews()}
                      rowsData={this.state.content.list}
                      handleChange={this.onRowsChange}
                    />
                  </div>
                </div>
                <div className="dd-mini-block__add">
                  <button className={"dd-btn dd-btn--primary dd-btn--icon-only"} onClick={(e) => this.addRow(e)}>
                    <Icon icon="plus" />
                  </button>
                </div>
                {this.state.content.show_epilog &&
                  <div className="form-group">
                    <br/>
                    <textarea
                      className="form-control"
                      name="epilog"
                      onChange={(e) => this.onChange(e)}
                      defaultValue={this.state.content.epilog}
                      placeholder="Abschlusstext (optional)"
                    />
                  </div>
                }
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
        }
      </>
    )
  }
}

BlockPricingList.contextType = AppContext
export default BlockPricingList
