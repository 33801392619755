import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";
import RadioButton from "../../atom/RadioButton/RadioButton";
import Checkbox from "../../atom/Checkbox/Checkbox";


import BlockPreviewTeaserCollection from './BlockPreviews/TeaserCollection';
import BlockSectionSettings from './BlockSectionSettings';
import Overlay from "../../element/Overlay/Overlay";
import PageSelector from "../../module/PageSelector/PageSelector";
import TagList from "../../atom/TagList/TagList";

import { getToken } from "../../../../lib/lib";
import { getPage, getArticle, getTerms, getProduct} from "../../../../data/api";
import BlockActions from "./BlockActions";

class BlockTeaserCollectionEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content:{},
      preview: true,
      pageOverlayActive: false,
      showSettings: false,
      showLawfields: false,
      blockLawfields: []
    }
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  toggleField = (e, field) => {
    const content = this.state.content
    content[field] = !this.state.content[field]
    this.saveContent(content)
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  openPageSelectorOverlay = (e) => {
    e.preventDefault()

    this.setState({
      pageOverlayActive: true,
    })
  }

  closePageSelectorOverlay = () => {
    this.setState({
      pageOverlayActive: false,
    })
  }

  handlePageSelect = (page) => {
    const content = this.state.content

    if (page.id) {
      content.pageID    = page.id
      content.pageTitle = page.title
      content.pageType  = page.content_type
      content.buttonURL = ""
    } else {
      content.pageID = ""
      content.buttonURL = page.buttonURL
    }

    content.buttonTarget = page.buttonTarget

    this.saveContent(content)
    this.closePageSelectorOverlay()
    this.setState({ page: page })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  onChangeType = (event) => {
    let showLawfields = false
    const content = this.state.content
    content[event.target.name] = event.target.value

    if (event.target.value === 'shared_articles' || event.target.value === 'news') {
      showLawfields = true
    }

    this.setState({ showLawfields })
    this.saveContent(content)
  }

  onChange = (event) => {
    const content = this.state.content
    content[event.target.name] = event.target.value
    this.saveContent(content)
  }

  lawFieldsSelection = (blockLawfields) => {
    const lawfield_ids = blockLawfields.map(tag => tag.id)

    const content = this.state.content
    content.lawfield_ids = lawfield_ids

    this.setState({
      blockLawfields: blockLawfields
    }, () => { this.saveContent(content) })
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }


  async componentDidMount() {
    let showLawfields = false
    const blockLawfields = []


    if (this.props.initData) {
      const preview = this.props.preview
      const content = this.props.initData[0]

      const lawfields = await getTerms(getToken(), 'lawfields')

      let page = {}

      if (content.pageID) {
        if (content.pageType === 'page') {
          const pageData = await getPage(getToken(), content.pageID)
          page = pageData.page
        } else if (content.pageType === 'article') {
          const pageData = await getArticle(getToken(), content.pageID)
          page = pageData.article
        } else if (content.pageType === 'product') {
          const pageData = await getProduct(getToken(), content.pageID)
          page = pageData.product
        }
      }
      // FIXME DEPRECATED
      else if (content.articleID) {
        const articleData = await getArticle(getToken(), content.articleID)
        page = articleData.article
      }

      if (content.collectionType === 'shared_articles' || content.collectionType === 'news') {
        showLawfields = true
      }

      //Compatibility
      if (content.lawfield_id && parseInt(content.lawfield_id) !== 0) {
        if (content.lawfield_ids === undefined) content.lawfield_ids = []
        content.lawfield_ids.push(parseInt(content.lawfield_id))
      }

      if (content.lawfield_ids && content.lawfield_ids.length) {
        content.lawfield_ids.forEach((lawfield_id) => {

          const lawfield = lawfields.lawfields.find(element => element.id === lawfield_id)

          if (lawfield) {
            blockLawfields.push(lawfield)
          }
        })
      }

      this.setState({ content, preview, page, showLawfields, blockLawfields, lawfields: lawfields.lawfields })
    }
  }

  renderSeletedItemLabel = () => {

    if (this.state.content.buttonURL) {
      return this.state.content.buttonURL
    } else if (this.state.content.pageID && this.state.page && this.state.page.title) {
      return `${this.state.page.title} (${this.state.page.navigation_title})`
    } else if (this.state.content.articleID && this.state.page && this.state.page.title) {
      return `${this.state.page.title}`
    }
  }

  render() {
    let showOverviewCheckBox = false
    let showOverviewButton   = true

    if (this.state.content.collectionType === 'events') {
      showOverviewCheckBox = true
      showOverviewButton   = this.state.content.showOverviewButton
    }

    return (
        <>
        {this.state.preview
          ? <div className="dd-block" onClick={() => this.togglePreviewEditMode()}>
              <div className="dd-block__preview">
                <BlockPreviewTeaserCollection block={this.props.initData} />
              </div>
            </div>
          :
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-teaserlist" /> Teaser
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }

              <div className="dd-block__edit-section">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <label className="radio-button__group-label text-nowrap ">Typ auswählen</label>
                    <RadioButton
                      name="collectionType"
                      label="Events"
                      value="events"
                      handleChange={this.onChangeType}
                      selected={this.state.content.collectionType === "events"}
                    />
                    <RadioButton
                      name="collectionType"
                      label="Jobs"
                      value="jobs"
                      handleChange={this.onChangeType}
                      selected={this.state.content.collectionType === "jobs"}
                    />
                    <RadioButton
                      name="collectionType"
                      label="Eigene Fachartikel"
                      value="news"
                      handleChange={this.onChangeType}
                      selected={this.state.content.collectionType === "news"}
                    />
                    <RadioButton
                      name="collectionType"
                      label="Fachartikel aus dem DIRO-Netzwerk"
                      value="shared_articles"
                      handleChange={this.onChangeType}
                      selected={this.state.content.collectionType === "shared_articles"}
                    />

                  </div>
                </div>

                { this.state.showLawfields &&
                  <div className="form-group">
                    <TagList
                      indexLabel="title_de"
                      name="Rechtsgebiete"
                      data={this.state.blockLawfields}
                      availableData={this.state.lawfields}
                      label="Fachartikel zu Rechtsgebiet/zu den Rechtsgebieten:"
                      onUpdate={(blockLawfields) => this.lawFieldsSelection(blockLawfields)}
                    />
                  </div>
                }

                <div className="form-group">
                  <Textfield
                    name="headline"
                    type="text"
                    isRequired={false}
                    label=""
                    placeholder="Headline"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.headline}
                  />
                </div>

                {showOverviewCheckBox &&
                  <div className="form-group">
                    <Checkbox
                      label="Link zur Events-Übersicht anzeigen?"
                      id="controls"
                      selected={this.state.content.showOverviewButton}
                      onChange={(e) => this.toggleField(e, "showOverviewButton")}
                    />
                  </div>
                }

                {showOverviewButton &&
                  <React.Fragment>
                    <div className="form-group">
                      <Textfield
                        name="buttonText"
                        type="text"
                        isRequired={false}
                        label=""
                        placeholder="Button Beschriftung"
                        handleChange={this.onChange}
                        defaultValue={this.state.content.buttonText}
                      />
                    </div>

                    <div className="form-group">
                      <div className="dd-link-selector">
                        <div className="dd-link-selector__link">{this.renderSeletedItemLabel()}</div>
                        <button className="dd-btn dd-btn--primary dd-link-selector__button" onClick={(e) => this.openPageSelectorOverlay(e)}>
                          URL-Pfad zur Übersicht ändern
                        </button>
                      </div>
                      {this.state.pageOverlayActive &&
                        <Overlay closeOverlayCallback={() => this.closePageSelectorOverlay()}>
                          <PageSelector
                            content={this.state.content}
                            selectPageCallback={(page) => this.handlePageSelect(page)}
                          />
                        </Overlay>
                      }
                    </div>
                  </React.Fragment>
                }

              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
          }
        </>
    )
  }
}

export default BlockTeaserCollectionEdit
