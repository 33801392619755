import React from "react";
import Icon from "../../atom/Icon/Icon";
import ImageSelector from "../../module/ImageSelector/ImageSelector";
import Overlay from "../Overlay/Overlay";

import { imagePathForFormat } from "../../../../lib/lib";


export class ImagePreviewAvatar extends React.Component {
  state = {
    showConfirmDelete: false,
  }

  toggleConfirmation = () => {
    this.setState({
      showConfirmDelete: !this.state.showConfirmDelete
    })
  }

  render() {

    const classNameActions = (this.props.actions_meta) ? "dd-avatar__actions dd-avatar__actions--meta" : "dd-avatar__actions"
    const classNameImage = (this.props.image_profile) ? "dd-avatar__image dd-avatar__image--profile" : "dd-avatar__image"

    return (
      <div className="dd-avatar__image-wrapper">
        <div className={classNameImage}>
          <img id="Profilbild" src={this.props.avatar_path} alt="Profilbild" className="d-block" />
          {(this.state.showConfirmDelete) &&
            <div className="dd-avatar__confirm">
              <p>
                <strong>Wollen Sie das Bild wirklich löschen?</strong><br />
                {this.props.message}
              </p>
              <button className="dd-btn dd-btn--primary" onClick={() => this.toggleConfirmation()}>Abbrechen</button>
              <button
                className="dd-btn dd-btn--primary dd-btn--outline"
                onClick={() => {
                  this.toggleConfirmation()
                  this.props.onAvatarDelete()
                }}
              >
                Löschen
              </button>
            </div>
          }
        </div>
        <div className={classNameActions}>
          <button className="dd-btn dd-btn--icon-only dd-btn--danger" onClick={() => this.toggleConfirmation()}>
            <Icon icon="trash" />
          </button>
          <button className="dd-btn dd-btn--icon-only dd-btn--primary dd-btn--inverted" onClick={this.props.openImageSelectorOverlay}>
            <Icon icon="edit" />
          </button>
        </div>
      </div>
    )
  }
}


export const ImagePreviewBlock = props => {
  return (
    <React.Fragment>
      <div className="dd-image-picker__preview">
        <img className="dd-image-picker__image" src={props.imagePath} alt="Preview" width="260px" height="150px"/>
      </div>

      <button className="dd-btn dd-btn--danger dd-btn--icon-only dd-image-picker__delete"
        onClick={(e) => props.handleImageDelete(e, props.imageId)}>
        <Icon icon="trash" />
      </button>
      <button className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only"
        onClick={(e) => props.openImageSelectorOverlay(e)}>
        <Icon icon="edit" />
      </button>
    </React.Fragment>
  )
}

export const VideoPreviewBlock = props => {
  return (
    <React.Fragment>
      <div className="dd-image-picker__preview">
        <video src={props.videoPath} autoPlay muted playsInline loop width="260px" height="150px" className="dd-image-picker__video"></video>
      </div>

      <button className="dd-btn dd-btn--danger dd-btn--icon-only dd-image-picker__delete"
        onClick={(e) => props.handleVideoDelete(e, props.videoId)}>
        <Icon icon="trash" />
      </button>
      <button className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only"
        onClick={(e) => props.openVideoSelectorOverlay(e)}>
        <Icon icon="edit" />
      </button>
    </React.Fragment>
  )
}


export class ImagePreview extends React.Component {
  state = {
    imageOverlay: false,
  }

  toggleImageOverlay = () => {
    this.setState({
      imageOverlay: !this.state.imageOverlay
    })
  }

  handleImageChange = (teaserimage_id, teaserimage_format, teaserimage_path) => {
    const article = this.props.article
    article.teaserimage_id = teaserimage_id
    article.teaserimage_format = teaserimage_format
    article.teaserimage_path = teaserimage_path
    this.props.onItemChange(article)
  }

  render() {

    const teaserimage_path = (this.props.article.teaserimage_id !== 0) ? this.props.article.teaserimage_path : ""

    let hint = "Empfohlenes Seitenverhältnis: 1:1. Zulässige Dateiformate: jpg, png. Mindestens 400px Breite."
    if (this.props.hint) {
      hint = this.props.hint
    }

    return (
      <React.Fragment>
        {(teaserimage_path) ?
          <ImagePreviewAvatar
            avatar_path={teaserimage_path}
            onAvatarDelete={() => this.handleImageChange(0, "", "")}
            openImageSelectorOverlay={() => this.toggleImageOverlay()}
            message=""
            actions_meta={true}
          />
          :
          <div className="dd-avatar dd-avatar--office">
            <button
              className="dd-btn dd-btn--primary dd-image-picker__choose"
              onClick={() => this.toggleImageOverlay()}>
              Bild auswählen
            </button>
            <p className="dd-avatar__hint">
              <strong>Upload des Teaserbildes</strong><br />
              {hint}
            </p>
          </div>
        }
        {this.state.imageOverlay &&
          <Overlay closeOverlayCallback={() => this.toggleImageOverlay()}>
            <ImageSelector
              selectImageCallback={(file, format) => {
                this.toggleImageOverlay()
                const path = file[imagePathForFormat(format)]
                this.handleImageChange(file.id, format, path)
              }}
              selectedAssetId={this.props.article.teaserimage_id || ''}
              selectedFormat={this.props.article.teaserimage_format || ''}
              fileTypes={["image"]}
              allowedFormats={[
                "original",
                "one_by_one",
                "three_by_two",
                "sixteen_by_nine",
              ]}
            />
          </Overlay>
        }
      </React.Fragment>
    )
  }
}
