import React from 'react'

import Icon from '../../atom/Icon/Icon'
import Textfield from '../../atom/Textfield/Textfield'

import ContactForm from './BlockPreviews/ContactForm'
import BlockSectionSettings from './BlockSectionSettings'
import BlockActions from './BlockActions'

class BlockContactFormEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: {},
      preview: true,
      showSettings: false
    }
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  onChange = (event) => {
    const content = this.state.content
    content[event.target.name] = event.target.value
    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  componentDidMount() {
    if (this.props.initData) {
      const preview = this.props.preview
      const content = this.props.initData[0]
      this.setState({ content, preview })
    }
  }

  render() {
    return (
      <>
        {this.state.preview ? (
          <div
            className="dd-block"
            onClick={() => this.togglePreviewEditMode()}
          >
            <div className="dd-block__preview">
              <ContactForm block={this.props.initData} />
            </div>
          </div>
        ) : (
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button
                  className="dd-block__settings-trigger dd-btn--reset"
                  onClick={(e) => this.toggleSettings(e)}
                >
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-contact-form" /> Kontaktformular
              </div>

              {this.state.showSettings && (
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              )}

              <div className="dd-block__edit-section">
                <div className="form-group">
                  <Textfield
                    name="headline"
                    type="text"
                    isRequired={false}
                    label="Formular Überschrift"
                    placeholder="Ihre Anfrage"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.headline}
                  />
                </div>
                <div className="form-group">
                  <Textfield
                    name="submit_button_title"
                    type="text"
                    isRequired={false}
                    label="Beschriftung des Absenden-Buttons"
                    placeholder="Anfrage senden"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.submit_button_title}
                  />
                </div>
                <div className="form-group">
                  <label className="textfield__label">
                    Text, welcher nach dem erfolgreichen Absenden des Formulars
                    angezeigt wird.
                  </label>
                  <textarea
                    className="form-control"
                    name="success_message"
                    defaultValue={this.state.content.success_message}
                    onChange={(e) => this.onChange(e)}
                  />
                </div>
                <div className="form-group">
                  <Textfield
                    name="email_from"
                    type="text"
                    isRequired={false}
                    label="Absender E-Mail Adresse"
                    placeholder=""
                    handleChange={this.onChange}
                    defaultValue={this.state.content.email_from}
                  />
                </div>
                <div className="form-group">
                  <Textfield
                    name="email_reply_to"
                    type="text"
                    isRequired={false}
                    label="Antwort E-Mail Adresse"
                    placeholder=""
                    handleChange={this.onChange}
                    defaultValue={this.state.content.email_reply_to}
                  />
                </div>
                <div className="form-group">
                  <Textfield
                    name="email_backoffice"
                    type="text"
                    isRequired={false}
                    label="E-Mail-Adresse, an welche die Anfrage geschickt werden soll"
                    placeholder=""
                    handleChange={this.onChange}
                    defaultValue={this.state.content.email_backoffice}
                  />
                </div>
                <div className="form-group">
                  <Textfield
                    name="email_subject"
                    type="text"
                    isRequired={false}
                    label="Betreffzeile der E-Mail"
                    placeholder="Kontakt Formular"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.email_subject}
                  />
                </div>
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
        )}
      </>
    )
  }
}

export default BlockContactFormEdit
