import React from "react";

import Articles from "../components/Dashboard/Articles";
import Products from "../components/Dashboard/Products";

class DiroDashboard extends React.Component {
  render() {
    return (
      <>
      <div className="tab-content" id="nav-tabContent">
        <Articles />
      </div>
      <br/>
      <br/>
      <br/>
      <div className="tab-content" id="nav-tabContent">
        <Products />
      </div>
      </>
    )
  }
}

export default DiroDashboard
