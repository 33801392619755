
import React from "react";

import Icon from "../../atom/Icon/Icon";
import Loader from "../../atom/Loader/Loader";
import RadioButton from "../../atom/RadioButton/RadioButton";
import DnDRows from "../../../Shared/module/DnDRows/DnDRows";
import SimpleSelectfield from "../../atom/Selectfield/SimpleSelectfield";
import Textfield from "../../atom/Textfield/Textfield";
import Checkbox from "../../atom/Checkbox/Checkbox";

import BlockPreviewMap from './BlockPreviews/Map';
import BlockMapLocationEdit from './BlockMapLocationEdit';
import BlockSectionSettings from './BlockSectionSettings';

import { AppContext } from '../../../../data/ContextProvider'

import { createEditor } from "./util";
import { getCompany } from "../../../../data/api";
import { getToken, createID } from "../../../../lib/lib";
import { Link, withRouter } from "react-router-dom";
import { ImagePreviewBlock } from "../../element/ImagePreview/ImagePreview";
import Overlay from "../../element/Overlay/Overlay";
import ImageSelector from "../ImageSelector/ImageSelector";
import BlockActions from "./BlockActions";

class BlockMapEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sites: [],
      latitude: '',
      zoomsteps: [
        { id: 3.5, option: "500km (sehr weit entfernt)" },
        { id: 4, option: "350km" },
        { id: 4.25, option: "300km" },
        { id: 4.5, option: "250km" },
        { id: 4.75, option: "200km" },
        { id: 5, option: "150km" },
        { id: 6, option: "100km" },
        { id: 7, option: "50km" },
        { id: 8, option: "30km" },
        { id: 9, option: "10km" },
        { id: 10, option: "5km" },
        { id: 11, option: "3km" },
        { id: 12, option: "2km" },
        { id: 13, option: "750m" },
        { id: 14, option: "500m" },
        { id: 15, option: "250m" },
        { id: 16, option: "100m" },
        { id: 17, option: "50m" },
        { id: 18, option: "25m (sehr nah)" },
      ],
      content: {},
      preview: true,
      loading: true,
      showSettings: false,
    }
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  onChangeEditor = (key, value) => {
    const content = this.state.content
    content.editor.content = value
    this.saveContent(content)
  }

  addRow = (e) => {
    e.preventDefault()
    const content = this.state.content

    // backwards compatibility
    if (!content.items) {
      content.items = [];
    }

    content.items.push({
      'siteID': 0,
      'key': createID(),
      'newRow': true,
    })
    this.saveContent(content)
  }

  onRowChange = (row, index) => {
    const content = this.state.content

    if (index < content.items.length ) {
      row.newRow = false
      content.items[index] = row
      this.saveContent(content)
    }
  }

  onRowsChange = (rows) => {
    const content = this.state.content
    content.items = rows
    this.saveContent(content)
  }

  onCheckboxChange = (e, field) => {
    const content = this.state.content
    content[field] = !this.state.content[field]
    this.saveContent(content)
  }


  onChange = (name, value) => {
    const content = this.state.content
    content[name] = value
    this.saveContent(content)
  }

  openImageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      imageOverlayActive: true
    })
  }

  closeImageSelectorOverlay = () => {
    this.setState({
      imageOverlayActive: false,
    })
  }

  handleImageSelect = (file, format) => {
    const content = this.state.content
    content.imagePath = file.file_path
    content.imageID   = file.id
    content.imageFormat  = format

    this.saveContent(content)
    this.closeImageSelectorOverlay()
  }

  handleImageDelete = () => {
    const content = this.state.content
    content.imageID = 0
    content.imageFormat = ""
    content.imagePath = ""
    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  prepopulateSites = (company) => {
    const sites = []
    company.company_sites.forEach(site => {
      sites.push({
        id: site.id,
        option: site.city.title_de
      })
    })

    return sites
  }

  renderBlockContent = () => {
    const content = { ...this.state.content }
    if (this.state.editorSetup) {
      return createEditor(this.props.index, "default", this.onChangeEditor, content.editor.content)
    } else {
      return []
    }
  }

  loadData = async () => {
    const company = await getCompany(getToken(), this.context.company.id)

    this.setState({
      ...this.state,
      loading: false,
      company: company.company,
      sites: this.prepopulateSites(company.company),
    })
  }

  adjustContent = (content) => {
    if (!content.editor) {
      content.editor = {
        editor: "",
        content: [{
          type: "default",
          children: [
            { text: "" }
          ],
        }],
        type: "",
      }
    }

    // init editor
    content.editor.editor = createEditor(this.props.index, "default", this.onChangeEditor, content.editor.content)

    return content
  }


  async componentDidMount() {
    if (this.props.initData) {
      const preview = this.props.preview
      let content = this.props.initData[0]
      content = this.adjustContent(content)

      this.setState({
        preview,
        content,
        loading: false,
        editorSetup: true
      }, async () => {
        await this.loadData()
      })
    }
  }

  renderPreviews = () => {
    const previews = []
    if (this.state.content.items) {
      this.state.content.items.map((item, index) => {
        return previews.push(
          <BlockMapLocationEdit
            item={item}
            sites={this.state.sites}
            key={item.key}
            onChange={(val) => this.onRowChange(val, index)}
          />
        )
      })
    }
    return previews
  }

  render() {
    const lang = this.props.match.params.lang

    if (this.state.loading) {
      return <Loader />
    }

    // const latitude = this.state.content.latitude || 10
    // const longitude = this.state.content.latitude || 10
    const zoomstep = this.state.content.zoom || 10
    const mapcolor = this.state.content.mapcolor || "monochrome"
    const maplayout = this.state.content.maplayout || "full"
    const gmaplink = this.state.content.gmaplink || false

    return (
      <React.Fragment>
        {
          this.state.preview
          ? <div className="dd-block dd-block--default" onClick={() => this.togglePreviewEditMode()}>
            <div className="dd-block__preview">
              <BlockPreviewMap block={this.state.content} />
            </div>
          </div>
          :
          <div className="dd-block dd-block--default dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-location" /> Standort-Karte
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }
              <div className="dd-block__edit-section">

                <div className="form-group">
                  <Textfield
                    name="headline"
                    type="text"
                    isRequired={false}
                    label="Headline"
                    placeholder="Headline"
                    handleChange={(event) => this.onChange(event.target.name, event.target.value)}
                    defaultValue={this.state.content.headline}
                  />
                </div>

                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <label className="radio-button__group-label text-nowrap ">Kartendarstellung</label>
                    <RadioButton
                      name="mapcolor"
                      label="schwarzweiss"
                      value="monochrome"
                      handleChange={(value) => this.onChange("mapcolor", "monochrome")}
                      selected={mapcolor === "monochrome"}
                    />
                    <RadioButton
                      name="mapcolor"
                      label="farbig"
                      value="color"
                      handleChange={(value) => this.onChange("mapcolor", "color")}
                      selected={mapcolor === "color"}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <label className="radio-button__group-label text-nowrap ">Layout</label>
                    <RadioButton
                      name="maplayout"
                      label="Große Karte"
                      value="full"
                      handleChange={(value) => this.onChange("maplayout", "full")}
                      selected={maplayout === "full"}
                    />
                    <RadioButton
                      name="maplayout"
                      label="Standorte als Teaser"
                      value="teaser"
                      handleChange={(value) => this.onChange("maplayout", "teaser")}
                      selected={maplayout === "teaser"}
                    />
                  </div>
                </div>

                {this.state.content.maplayout === 'full' &&
                  <React.Fragment>
                  <div className="form-group">
                    <Textfield
                      name="maplat"
                      label="Kartenausschnitt (Position)"
                      type="text"
                      placeholder="Latitude"
                      handleChange={(event) => this.onChange(event.target.name, event.target.value)}
                      defaultValue={this.state.content.maplat}
                    />
                    <Textfield
                      name="maplong"
                      type="text"
                      placeholder="Longitude"
                      handleChange={(event) => this.onChange(event.target.name, event.target.value)}
                      defaultValue={this.state.content.maplong}
                    />
                  </div>
                  <div className="form-group">
                    <SimpleSelectfield
                      label="Zoomstufe – 1cm auf der Karte entspricht ca."
                      name="zoom"
                      firstOptionValue=""
                      firstOptionLabel="Bitte auswählen"
                      indexLabel="id"
                      optionLabel="option"
                      options={this.state.zoomsteps}
                      onSelect={(value) => this.onChange("zoom", value)}
                      selected={zoomstep}
                    />
                  </div>
                  </React.Fragment>
                }

                <div className="form-group">
                  <label className="radio-button__group-label text-nowrap ">Zusätzlicher Hinweistext</label>
                  {this.renderBlockContent()}
                </div>

                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <Checkbox
                      label="Link zu Google Maps anzeigen"
                      id="gmaplink"
                      selected={gmaplink}
                      onChange={(e) => this.onCheckboxChange(e, 'gmaplink')}
                    />
                  </div>
                </div>

                <div className="dd-mini-block">
                  <div className="dd-mini-block__blocks">
                    <DnDRows
                      rows={this.renderPreviews()}
                      rowsData={this.state.content.items}
                      handleChange={this.onRowsChange}
                    />
                  </div>
                </div>
                <div className="dd-mini-block__add">
                  <button className={"dd-btn dd-btn--primary dd-btn--icon-only"} onClick={(e) => this.addRow(e)}>
                    <Icon icon="plus" />
                  </button>
                  <div className="dd-mini-block__add-text">
                    Standort hinzufügen
                  </div>
                </div>
              </div>


              <div className="dd-block__edit-section">
                  <h5>Datenschutz</h5>
                  <p className="hint-text">
                    Dieses Modul bindet einen Inhalte mithilfe eines externen Dienstes ein, der erst nach
                    Zustimmung des Nutzers geladen werden darf. Erteilt der Nutzer keine globale Zustimmung im
                    entsprechenden Popup beim ersten Seitenaufruf, muss der Aufruf des Inhalts blockiert werden.
                    Anstelle der Standort-Karte wird dann ein Platzhalter-Bild und ein Button zum Laden der Standort-Karte angezeigt (Zwei-Klick-Lösung).
                    Die Texte für Anfragen zur Zustimmung und den Button können Sie im Admin-Bereich unter <Link to={`/${lang}/admin/tracking#openstreetmap`}>Tracking und externe Dienste</Link> bearbeiten.
                  </p>
                  <div className="row">
                    <div className="col">

                    <label className="textfield__label">Platzhalter-Bild vor Anzeige des Inhaltes</label>
                      <p className="hint-text">Dieses Bild wird angezeigt, wenn der Besucher keine Zustimmung zur Anzeige des externen Inhaltes erteilt hat.</p>

                        <div className="dd-image-picker">
                          {this.state.content.imagePath ?
                            <ImagePreviewBlock
                              imagePath={this.state.content.imagePath}
                              imageId={this.state.imageId}
                              handleImageDelete={this.handleImageDelete}
                              openImageSelectorOverlay={this.openImageSelectorOverlay}
                            />
                            :
                            <button className="dd-btn dd-btn--primary dd-image-picker__choose" onClick={(e) => this.openImageSelectorOverlay(e)}>
                              Bild auswählen
                            </button>
                          }
                        </div>

                        {this.state.imageOverlayActive &&
                          <Overlay closeOverlayCallback={() => this.closeImageSelectorOverlay()}>
                            <ImageSelector
                              selectImageCallback={(file, format) => this.handleImageSelect(file, format)}
                              fileTypes={["image"]}
                              selectedAssetId={this.state.content.imageID || ''}
                              selectedFormat={this.state.content.imageFormat || ''}
                              allowedFormats={[
                                "original",
                                "five_by_two",
                                "sixteen_by_nine"
                              ]}
                            />
                          </Overlay>
                        }
                    </div>
                </div>

              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}

BlockMapEdit.contextType = AppContext
export default withRouter(BlockMapEdit);
