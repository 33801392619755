import React, { Component } from "react";

import { deleteUserData } from "../lib/lib";

export const AppContext = React.createContext();


class ContextProvider extends Component {

  state = {
    syslanguages: [],
    isUserLoggedIn: false,
    diroLayout: false,
    lang: "de",
    blocksDragDisabled: false,
    availableLanguages  : ["de", "en"],
    user: {
      id: "",
      email: "",
      firstname:"",
      lastname:"",
      admin: false,
      editor: false,
      author: false,
    },

    setLanguage: newLang => {
      const origin = window.origin;
      const currentPath = window.location.pathname;
      const noLangPath = currentPath.substr(3);

      window.location = origin + "/" + newLang.toLowerCase() + noLangPath;
    },

    getAvailableLanguages: () => {
      let langs = [...this.state.availableLanguages];
      langs = langs.map(el => {
        return el.toUpperCase();
      });
      return langs;
    },

    saveSysLanguages: (syslanguages) => {
      this.setState({
        ...this.state,
        syslanguages
      }, () => {
        return true
      })
    },

    saveUserData: (userData, userCompany) => {
      this.setState({
        ...this.state,
        isUserLoggedIn: true,
        diroLayout: userCompany.is_diro,
        user: {
          uid: userData.id,
          email: userData.email,
          lastname: userData.lastname,
          firstname: userData.firstname,
          full_name: userData.full_name,
          diro_admin: userData.diro_admin,
          admin: userData.admin,
          editor: userData.editor,
          author: userData.author,
        },
        company: {
          id: userCompany.id,
          name: userCompany.name,
          domain: userCompany.domain,
          is_diro: userCompany.is_diro,
          is_diro_member: userCompany.is_diro_member,
        }
      }, () => {
        return true
      })
    },

    isLoggedIn: () => {
      return this.state.isUserLoggedIn;
    },

    toggleBlocksDraggable: (state) => {
      this.setState({
        ...this.state,
        blocksDragDisabled: state,
      })
    },

    logout: () => {
      this.setState({
        ...this.state,
        isUserLoggedIn: false,
        diroLayout: false,
        user: {
          uid: "",
          email: "",
          lastname: "",
          firstname: "",
        }
      }, () => {
        deleteUserData()
        return true
      })
    },
  }

  // OPTIMIZE: Move out of ContextProvider
  componentDidMount() {
    const pathLang = window.location.pathname.substr(1, 2);
    const currentLang =
      pathLang === "" ||
      !this.state.getAvailableLanguages().includes(pathLang.toUpperCase())
        ? this.state.getAvailableLanguages()[0]
        : pathLang;

    window.currentLang = currentLang.toLowerCase();

    this.setState({
      lang: currentLang.toLowerCase()
    });
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default ContextProvider;
