import React from 'react'


const RadioButton = props => {
  return <label
    className={`radio-button__container ${props.disabled ? 'radio-button__container--disabled' : ''}`}>{props.label}
    <input name={props.name}
           className={`radio-button`}
           type={`radio`}
           value={props.value}
           disabled={props.disabled}
           checked={props.selected || false}
           onChange={props.handleChange ? props.handleChange : null}
    />
    <span className={`radio-button__checkmark`}><span className={"radio-button__dot"} /></span>
  </label>
}


export default RadioButton
