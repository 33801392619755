import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Textfield from "../Shared/atom/Textfield/Textfield";
import Editbar from "../Shared/element/Editbar/Editbar";

import { AppContext } from "../../data/ContextProvider";
import { createSyslanguage } from "../../data/api";
import { getToken } from "../../lib/lib";


class LanguageNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      syslanguage: {}
    }
  }

  errorHandler = (hasError=false) => {
    this.setState({ hasError })
  }

  onChange = (event) => {
    const syslanguage = { ...this.state.syslanguage }
    syslanguage[event.target.name] = event.target.value
    this.setState({ syslanguage })
  }

  onSubmit = () => {

    createSyslanguage(getToken(), this.state.syslanguage)
    .then(syslanguage => {
      const lang = this.props.match.params.lang
        this.props.history.push(`/${lang}/admin/i18n/${syslanguage.syslanguage.id}/edit`);
    })
    .catch(err => {
      console.log(err)
    })
  }


  render() {
    const formValid = (this.state.syslanguage.shorthand) ? true : false
    const hasError = (this.state.hasError || !formValid) ? true : false

    return (
      <div className="tab-pane fade show active">
        <div className="card-body card-body--standard dd-background dd-background--sand">
          <div className="card-body__content w-75 ml-auto mr-auto">
            <div className="form-group">
              <Textfield
                name="title"
                type="text"
                isRequired={false}
                label="Sprache"
                handleChange={this.onChange}
                defaultValue={this.state.syslanguage.title}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="shorthand"
                type="text"
                isRequired={true}
                label="URL-Kürzel"
                handleChange={this.onChange}
                defaultValue={this.state.syslanguage.shorthand}
                errorHandler={this.errorHandler}
              />
            </div>
          </div>
        </div>
        <Editbar
          hasPublish={false}
          hasPreview={false}
          hasError={hasError}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
        />
      </div>
    );
  }
}

LanguageNew.contextType = AppContext
export default withRouter(LanguageNew);
