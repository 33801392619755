import React, { Component } from "react";

import Title from "../Shared/module/Title/Title";
import Subnavigation from "../Shared/module/Navigation/Subnavigation";


class AdminTabs extends Component {
  render() {
    return (
      <Title headline={this.props.headline}>
        <Subnavigation
          tabs={this.props.tabs}
          tabChange={this.openTab} />
      </Title>
    )
  }
}

export default AdminTabs;
