import React from "react";

// import { AppContext } from "../Data/ContextProvider";

class Loader extends React.Component {
  
  render() {
    return (
      <div className="loading__wrapper loading__wrapper-processed">
        <div className="loading">
          <div className="loading__dot">/</div>
          <div className="loading__dot">/</div>
          <div className="loading__dot">/</div>
          <div className="loading__dot">/</div>
          <div className="loading__dot">/</div>
        </div>
      </div>
    )
  }
}

// Loader.contextType = AppContext

export default Loader;

