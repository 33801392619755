import axios from 'axios'
import { getToken } from '../lib/lib';


import { API_HOST } from "./config";
import {off} from "leaflet/src/dom/DomEvent";
import { get } from 'leaflet/src/dom/DomUtil';


export async function login(email, password) {
  const payload = {
    "user": {
      "email": email,
      "password": password
    }
  }

  const response = await axios.post(
    `${API_HOST}/login`,
    JSON.stringify(payload),
    {
      headers: {
        'Content-Type': 'application/json',
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })

  return {
    user: response.data,
    authorization: response.headers['authorization']
  }
}

export async function reloginUser(token) {
  const response = await axios.get(
    `${API_HOST}/relogin`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }
  ).catch(error => {
    throw (error.response)
  })
  return response.data
}

export async function loadUser(token, id) {
  const response = await axios.get(
    `${API_HOST}/users/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
  return response.data
}


export async function resetPassword(data) {
  const response = await axios.post(
    `${API_HOST}/reset_password`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    }
  )
  .catch(error => {
    throw (error.response)
  })
  return response.data
}



//--------------- Person -----------------------

export async function getLawyers() {
  const response = await axios.get(
    `${API_HOST}/users/lawyers`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': getToken()
    }}
  )
  .catch(function (error) {
    console.log(error);
  });
  return response.data
}

export async function getNotLawyers() {
  const response = await axios.get(
    `${API_HOST}/users/not-lawyers`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': getToken()
    }}
  )
  .catch(function (error) {
    console.log(error);
  });
  return response.data
}

export async function getPerson(token, id) {
  const response = await axios.get(
    `${API_HOST}/users/${id}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }}
  )
  .catch(function (error) {
    console.log(error);
  });

  return response.data
}

export async function createPerson(token, person) {
  return axios.post(
    `${API_HOST}/users`,
    { user: person },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response.data)
  .catch(error => { throw (error.response) })
}


export async function updatePerson(token, id, user) {
  return axios.put(
    `${API_HOST}/users/${id}`,
    { user: user },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

export async function deletePerson(token, id) {
  return axios.delete(
    `${API_HOST}/users/${id}`,
    {
      headers: {
        'Authorization': token
      },
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

export async function updatePassword(token, id, user) {
  return axios.put(
    `${API_HOST}/users/${id}/password`,
    { user: user },
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

//--------------- Secretaries -----------------------

export async function getSecretaries(token, principal_id, lang) {
  const response = await axios.get(
    `${API_HOST}/secretaries?principal_id=${principal_id}&lang=${lang}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  })
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}


export async function updateSecretaries(token, id, secretaries, lang) {
  return axios.put(
    `${API_HOST}/secretaries/${id}`,
    { secretaries: secretaries, lang: lang },
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

export async function translateSecretaries(token, id, lang) {
  return axios.put(
    `${API_HOST}/secretaries/translate/${id}/${lang}`,
    {  },
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}


//--------------- Vitas -----------------------

export async function getVitas(token, user_id, lang) {
  const response = await axios.get(
    `${API_HOST}/vitas?user_id=${user_id}&lang=${lang}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  })
  .catch(function (error) {
    console.log(error);
  });

  return response.data
}

export async function updateVitas(token, id, vitas, lang) {
  return axios.put(
    `${API_HOST}/vitas/update_vitas/${id}`,
    { vitas: vitas, lang: lang  },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

export async function translateVitas(token, id, lang) {
  return axios.put(
    `${API_HOST}/vitas/translate/${id}/${lang}`,
    {},
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

//--------------- Mandates -----------------------

export async function getMandates(token, user_id, lang) {
  const response = await axios.get(
    `${API_HOST}/mandates?user_id=${user_id}&lang=${lang}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  })
  .catch(function (error) {
    console.log(error);
  });

  return response.data
}

export async function updateMandates(token, id, mandates, lang) {
  return axios.put(
    `${API_HOST}/mandates/update_mandates/${id}`,
    { mandates: mandates, lang: lang },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

export async function translateMandates(token, id, lang) {
  return axios.put(
    `${API_HOST}/mandates/translate/${id}/${lang}`,
    {},
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

//--------------- Lectures -----------------------

export async function getLectures(token, user_id, lang) {
  const response = await axios.get(
    `${API_HOST}/lectures?user_id=${user_id}&lang=${lang}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  })
  .catch(function (error) {
    console.log(error);
  });

  return response.data
}

export async function updateLectures(token, id, lectures, lang) {
  return axios.put(
    `${API_HOST}/lectures/update_lectures/${id}`,
    { lectures: lectures, lang: lang },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

export async function translateLectures(token, id, lang) {
  return axios.put(
    `${API_HOST}/lectures/translate/${id}/${lang}`,
    {},
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

//--------------- Publications -----------------------

export async function getPublications(token, user_id, lang) {
  const response = await axios.get(
    `${API_HOST}/publications?user_id=${user_id}&lang=${lang}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  })
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function updatePublications(token, id, publications, lang) {
  return axios.put(
    `${API_HOST}/publications/update_publications/${id}`,
    { publications: publications, lang: lang },
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

export async function translatePublications(token, id, lang) {
  return axios.put(
    `${API_HOST}/publications/translate/${id}/${lang}`,
    {},
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

//--------------- Usermemberships -----------------------

export async function getUsermemberships(token, user_id, lang) {
  const response = await axios.get(
    `${API_HOST}/usermemberships?user_id=${user_id}&lang=${lang}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  })
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function updateUsermemberships(token, id, usermemberships, lang) {
  return axios.put(
    `${API_HOST}/usermemberships/update_usermemberships/${id}`,
    { usermemberships: usermemberships, lang: lang },
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

export async function translateUsermemberships(token, id, lang) {
  return axios.put(
    `${API_HOST}/usermemberships/translate/${id}/${lang}`,
    {},
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

//--------------- Companies -----------------------

export async function getCompanies(token, limit=0, offset=0, orderField="", orderDirection="", query="") {
  const response = await axios.get(
    `${API_HOST}/companies?limit=${limit}&offset=${offset}&order=${orderField}&dir=${orderDirection}&query=${query}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function getCompaniesList(token) {
  const response = await axios.get(
    `${API_HOST}/companies/list`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  )
  .catch(function (error) {
    console.log(error);
  });
  return response.data
}

export async function getCompany(token, id) {
  const response = await axios.get(
    `${API_HOST}/companies/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function updateCompany(token, id, company) {
  return axios.put(
    `${API_HOST}/companies/${id}`,
    { company: company },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

export async function createCompany(token, company) {
  return axios.post(
    `${API_HOST}/companies`,
    { company: company },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response.data)
  .catch(error => { throw (error.response) })
}

//--------------- Company Sites -----------------------

export async function getSitesForCompany(token, companyID) {
  const response = await axios.get(
    `${API_HOST}/company_sites?cid=${companyID}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response.data
}

export async function createCompanySite(token, site) {
  return axios.post(
    `${API_HOST}/company_sites`,
    { company_site: site },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response.data)
  .catch(error => { throw (error.response) })
}

export async function updateCompanySite(token, id, site) {
  return axios.put(
    `${API_HOST}/company_sites/${id}`,
    { company_site: site },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}


//--------------- Terms -----------------------


function getVocabulary(vocabulary) {
  const vocabularies =  {
    'eventcategories': 'eventcategory',
    'targetgroups': 'targetgroup',
    'languages': 'language',
    'jobtitles': 'jobtitle',
    'lawfields': 'lawfield',
    'advocacies': 'advocacy',
    'academictitles': 'academictitle',
    'chambers': 'chamber',
    'nationalities': 'nationality',
    'countries': 'country',
    'cities': 'city',
    'federalstates': 'federalstate',
    'keywords': 'keyword',
    'companyforms': 'companyform',
    'courts': 'court',
    'signedcontracts': 'signedcontract',
    'cooperationnationals': 'cooperationnational',
    'cooperationinternationals': 'cooperationinternational',
    'joblevels': 'joblevel',
    'jobtypes': 'jobtype',
    'jobtimecontigents': 'jobtimecontigent',
  }

  if (Object.keys(vocabularies).includes(vocabulary)) {
    return {
      vocabulary: vocabulary,
      object: vocabularies[vocabulary]
    }
  }

  return null
}

export async function createItem(token, item, vocabulary) {
  const allowedVocabulary = getVocabulary(vocabulary)

  if (!allowedVocabulary) {
    return null;
  }

  return axios.post(
    `${API_HOST}/${vocabulary}`,
    { [allowedVocabulary.object]: item },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response.data)
  .catch(error => { throw (error.response) })
}

export async function updateTerms(token, items, vocabulary) {
  const allowedVocabulary = getVocabulary(vocabulary)

  if (!allowedVocabulary) {
    return null;
  }

  return axios.put(
    `${API_HOST}/${allowedVocabulary.vocabulary}/update_${vocabulary}`,
    { [allowedVocabulary.vocabulary]: items },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response.data)
  .catch(error => { throw (error.response) })
}

export async function deleteTerm(token, id, vocabulary) {
  const allowedVocabulary = getVocabulary(vocabulary)

  if (!allowedVocabulary) {
    return null;
  }

  return axios.delete(
    `${API_HOST}/${allowedVocabulary.vocabulary}/${id}`,
    {
      headers: {
        'Authorization': token
      },
      data: {
        id: id
      }
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}


export async function getTerms(token, vocabulary, limit, offset, orderField, orderDirection, query, showIncomplete) {

  if (!limit) {
    limit = 0
  }

  if (!offset) {
    offset = 0
  }

  if (!orderField) {
    orderField = ""
  }

  if (!orderDirection) {
    orderDirection = ""
  }

  if (!query) {
    query = ""
  }

  if (!showIncomplete) {
    showIncomplete = ""
  } else {
    showIncomplete = 1
  }


  const allowedVocabulary = getVocabulary(vocabulary)

  if (!allowedVocabulary) {
    return null;
  }

  const response = await axios.get(
    `${API_HOST}/${vocabulary}?limit=${limit}&offset=${offset}&order=${orderField}&dir=${orderDirection}&query=${query}&inc=${showIncomplete}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }).catch(function (error) {
      console.log(error);
  });

  return response.data
}

//--------------- Syslanguages -----------------------

export async function getLanguages(token) {
  const response = await axios.get(
    `${API_HOST}/syslanguages/languages`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  })
    .catch(function (error) {
      console.log(error);
    });
  return response.data
}

export async function getSyslanguages(token) {
  const response = await axios.get(
    `${API_HOST}/syslanguages`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  })
  .catch(function (error) {
    console.log(error);
  });
  return response.data
}


export async function getSyslanguage(token, id) {
  const response = await axios.get(
    `${API_HOST}/syslanguages/${id}`,
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function updateSyslanguage(token, id, syslanguage) {
  return axios
    .put(
      `${API_HOST}/syslanguages/${id}`,
      { syslanguage: syslanguage },
      {
        headers: {
          'Authorization': token
        }
      }
    )
    .then(response => response)
    .catch(error => {
      throw (error.response)
    })
}

export async function createSyslanguage(token, syslanguage) {
  return axios
  .post(
    `${API_HOST}/syslanguages`,
    { syslanguage: syslanguage },
    {
      headers: {
        Authorization: token
      }
    }
  )
  .then((response) => response.data)
  .catch((error) => {
    throw error.response
  })
}

//--------------- Settings -----------------------
export async function getSetting(token) {
  const response = await axios.get(
    `${API_HOST}/settings`,
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  )
  .catch(function (error) {
    console.log(error);
  });

  return response.data
}

export async function updateSetting(token, id, setting) {
  return axios
    .put(
      `${API_HOST}/settings/${id}`,
      { setting: setting },
      {
        headers: {
          'Authorization': token
        }
      }
    )
    .then(response => response)
    .catch(error => {
      throw (error.response)
    })
}

export async function updateFaviconImage(token, id, file) {
  const data = new FormData();
  data.append("id", id)
  data.append("favicon", file)

  return axios.post(
    `${API_HOST}/settings/${id}/favicon`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': token
      }
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

export async function deleteFaviconImage(token, id) {
  return axios.delete(
    `${API_HOST}/settings/${id}/favicon`,
    {
      headers: {
        'Authorization': token
      },
      data: {
        id: id
      }
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}


//--------------- Federalstates -----------------------
export async function getFederalstates(token, countryID) {
  const response = await axios.get(
    `${API_HOST}/federalstates?country=${countryID}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  })
  .catch(function (error) {
    console.log(error);
  });
  return response.data
}

//--------------- Events -----------------------
export async function getEvents(
  token,
  lang,
  limit,
  offset,
  orderField,
  orderDirection,
  query
) {
  const response = await axios
    .get(
      `${API_HOST}/events?lang=${lang}&limit=${limit}&offset=${offset}&order=${orderField}&dir=${orderDirection}&query=${query}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: token
        }
      }
    )
    .catch(function (error) {
      console.log(error)
    })

  return response.data
}

 export async function getEvent(token, id) {
  const response = await axios.get(
    `${API_HOST}/events/${id}`,
    {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function updateEvent(token, id, event) {
  return axios
  .put(
    `${API_HOST}/events/${id}`,
    { event: event },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response)
  .catch(error => {
    throw (error.response)
  })
}

export async function translateEvent(token, id, lang) {
  return axios.put(
    `${API_HOST}/events/translate/${id}/${lang}`,
    {},
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response.data)
    .catch(error => { throw (error.response) })
}

export async function deleteEvent(token, id) {
  return axios.delete(
    `${API_HOST}/events/${id}`,
    {
      headers: {
        'Authorization': token
      },
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

export async function createEvent(token, event) {
  return axios
    .post(
      `${API_HOST}/events`,
      { event: event },
      {
        headers: {
          Authorization: token
        }
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error.response
    })
}

export async function getEventlocation(token, id) {
  const response = await axios.get(
    `${API_HOST}/eventlocations/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function createEventlocation(token, eventlocation) {
  return axios
  .post(
    `${API_HOST}/eventlocations`,
    { eventlocation: eventlocation },
    {
      headers: {
        Authorization: token
      }
    }
  )
  .then((response) => response.data)
  .catch((error) => {
    throw error.response
  })
}

export async function updateEventlocation(token, id, eventlocation) {
  return axios
  .put(
    `${API_HOST}/eventlocations/${id}`,
    { eventlocation: eventlocation },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response)
  .catch(error => {
    throw (error.response)
  })
}

export async function getEventParticipants(token, id, limit, offset, orderField, orderDirection, query) {
    const response = await axios.get(
        `${API_HOST}/events/${id}/users?limit=${limit}&offset=${offset}&order=${orderField}&dir=${orderDirection}&query=${query}`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': token
            }
        }
    )
        .catch(function (error) {
            console.log(error);
        });

    return response.data
}

export async function deleteEventParticipant(token, id) {
    return axios.delete(
        `${API_HOST}/event_participants/${id}`,
        {
            headers: {
                'Authorization': token
            },
        }
    )
        .then(response => response)
        .catch(error => { throw (error.response) })
}


//--------------- Articles -----------------------
export async function getArticles(token, lang, limit, offset, orderField, orderDirection, query) {
  const response = await axios.get(
    `${API_HOST}/articles?limit=${limit}&lang=${lang}&offset=${offset}&order=${orderField}&dir=${orderDirection}&query=${query}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  })
  .catch(function (error) {
    console.log(error);
  });

  return response.data
}

export async function getNeworkArticles(token, lang, limit, offset, orderField, orderDirection, query) {
  const response = await axios.get(
    `${API_HOST}/articles/network_articles?limit=${limit}&lang=${lang}&offset=${offset}&order=${orderField}&dir=${orderDirection}&query=${query}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function getArticle(token, id) {
  const response = await axios.get(
    `${API_HOST}/articles/${id}`,
    {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function createArticle(token, article) {
  return axios.post(
    `${API_HOST}/articles`,
    { article: article },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response.data)
  .catch(error => { throw (error.response) })
}

export async function updateArticle(token, id, article) {
  return axios.put(
    `${API_HOST}/articles/${id}`,
    { article: article },
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

export async function translateArticle(token, id, lang) {
  return axios.put(
    `${API_HOST}/articles/translate/${id}/${lang}`,
    {},
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response.data)
    .catch(error => { throw (error.response) })
}

export async function deleteArticle(token, id) {
  return axios.delete(
    `${API_HOST}/articles/${id}`,
    {
      headers: {
        'Authorization': token
      },
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

//--------------- Products -----------------------
export async function getProducts(token, lang, limit, offset, orderField, orderDirection, query) {
  const response = await axios.get(
    `${API_HOST}/products?limit=${limit}&lang=${lang}&offset=${offset}&order=${orderField}&dir=${orderDirection}&query=${query}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  })
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function getNeworkProducts(token, lang, limit, offset, orderField, orderDirection, query) {
  const response = await axios.get(
    `${API_HOST}/products/network_products?limit=${limit}&lang=${lang}&offset=${offset}&order=${orderField}&dir=${orderDirection}&query=${query}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function getNetworkReleasedProducts(token, lang, limit, offset, orderField, orderDirection, query) {
  const response = await axios.get(
    `${API_HOST}/products/released_network_products?limit=${limit}&lang=${lang}&offset=${offset}&order=${orderField}&dir=${orderDirection}&query=${query}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function importReleasedProduct(product_id) {
  return axios.post(
    `${API_HOST}/products/import_released_product`,
    { product_id: product_id },
    {
      headers: {
        'Authorization': getToken()
      }
    }
  )
  .then(response => response.data)
  .catch(error => { throw (error.response) })
}

export async function getProduct(token, id) {
  const response = await axios.get(
    `${API_HOST}/products/${id}`,
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function createProduct(token, product) {
  return axios.post(
    `${API_HOST}/products`,
    { product: product },
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response.data)
    .catch(error => { throw (error.response) })
}

export async function updateProduct(token, id, product) {
  return axios.put(
    `${API_HOST}/products/${id}`,
    { product: product },
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

export async function translateProduct(token, id, lang) {
  return axios.put(
    `${API_HOST}/products/translate/${id}/${lang}`,
    {},
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response.data)
    .catch(error => { throw (error.response) })
}

export async function deleteProduct(token, id) {
  return axios.delete(
    `${API_HOST}/products/${id}`,
    {
      headers: {
        'Authorization': token
      },
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

//--------------- Keywords -----------------------

export async function createKeyword(token, keyword) {
  return axios.post(
    `${API_HOST}/keywords`,
    { keyword: keyword },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response.data)
  .catch(error => { throw (error.response) })
}

export async function updateKeywords(token, keywords) {
  return axios.put(
    `${API_HOST}/keywords/update_keywords`,
    { keywords: keywords },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

//--------------- Pagetypes -----------------------------

export async function getPagetype(token, id) {
  const response = await axios.get(
    `${API_HOST}/pagetypes/${id}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}


export async function getPagetypes(token) {
  const response = await axios.get(
    `${API_HOST}/pagetypes`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function updatePagetype(token, id, pagetype) {
  return axios.put(
    `${API_HOST}/pagetypes/${id}`,
    { pagetype: pagetype },
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

//--------------- Pages -----------------------------

export async function createPage(token, page) {
  return axios.post(
    `${API_HOST}/pages`,
    { page: page },
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response.data)
    .catch(error => { throw (error.response) })
}

export async function deletePage(token, id) {
  return axios.delete(
    `${API_HOST}/pages/${id}`,
    {
      headers: {
        'Authorization': token
      },
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

export async function getPage(token, id) {
  const response = await axios.get(
    `${API_HOST}/pages/${id}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}


export async function getAllPages(lang) {
  const response = await axios.get(
    `${API_HOST}/pages?lang=${lang}&order=title&dir=asc`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': getToken()
    }
  }
  )
  .catch(function (error) {
    console.log(error);
  });

  return response.data
}

export async function getPages(token, lang, limit, offset, orderField, orderDirection, query) {
  const response = await axios.get(
    `${API_HOST}/pages?lang=${lang}&limit=${limit}&offset=${offset}&order=${orderField}&dir=${orderDirection}&query=${query}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }
  )
  .catch(function (error) {
    console.log(error);
  });

  return response.data
}

export async function updatePage(token, id, page) {
  return axios.put(
    `${API_HOST}/pages/${id}`,
    { page: page },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

export async function translatePage(token, id, lang) {
  return axios.put(
    `${API_HOST}/pages/translate/${id}/${lang}`,
    {},
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response.data)
    .catch(error => { throw (error.response) })
}

export async function duplicatePage(token, id) {
  return axios.put(
    `${API_HOST}/pages/duplicate/${id}`,
    {},
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response.data)
    .catch(error => { throw (error.response) })
}

export async function updatePages(token, pages) {
  return axios.put(
    `${API_HOST}/pages/update_pages`,
    { pages: pages },
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

export async function pageToHomepage(id) {
  return axios.put(
    `${API_HOST}/pages/homepage/${id}`,
    {},
    {
      headers: {
        'Authorization': getToken()
      }
    }
  )
    .then(response => response.data)
    .catch(error => { throw (error.response) })
}

export async function pageToContactpage(id) {
  return axios.put(
    `${API_HOST}/pages/contactpage/${id}`,
    {},
    {
      headers: {
        'Authorization': getToken()
      }
    }
  )
    .then(response => response.data)
    .catch(error => { throw (error.response) })
}


export async function getMenu(token, lang) {
  const response = await axios.get(
    `${API_HOST}/pages/menu?lang=${lang}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function getHomepage(token, lang) {
  const response = await axios.get(
    `${API_HOST}/pages/homepage?lang=${lang}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  })
  .catch(function (error) {
    console.log(error);
  });

  return response.data
}

export async function getNotInMenu(token, lang, limit, offset, query) {
  const response = await axios.get(
    `${API_HOST}/pages/not_in_menu?limit=${limit}&lang=${lang}&offset=${offset}&query=${query}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  })
  .catch(function (error) {
    console.log(error);
  });

  return response.data
}

//--------------- FooterNavigation -----------------------------

export async function getFooterNavigation() {
  const response = await axios.get(
    `${API_HOST}/footer_navigations`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': getToken()
    }
  }).catch(function (error) {
    console.log(error);
  });

  return response.data
}

export async function getFooterPagesNotInMenu(lang, limit, offset, query) {
  const response = await axios.get(
    `${API_HOST}/footer_navigations/not_in_menu?limit=${limit}&lang=${lang}&offset=${offset}&query=${query}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': getToken()
    }
  })
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}


export async function updateFooterNavigation(pages, company_id) {
  return axios.put(
    `${API_HOST}/footer_navigations/update/${company_id}`,
    { pages: pages },
    {
      headers: {
        'Authorization': getToken()
      }
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

export async function deleteFooterNavigation(id) {
  return axios.delete(
    `${API_HOST}/footer_navigations/${id}`,
    {
      headers: {
        'Authorization': getToken()
      },
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}


//--------------- Jobs -----------------------------

export async function getJobsMenu(token) {
  const response = await axios
    .get(`${API_HOST}/jobs/menu`, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
    .catch(function (error) {
      console.log(error)
    })

  return response.data
}

export async function getJobs(
  token,
  lang,
  limit,
  offset,
  orderField,
  orderDirection,
  query
) {
  const response = await axios
    .get(
      `${API_HOST}/jobs?limit=${limit}&offset=${offset}&order=${orderField}&dir=${orderDirection}&query=${query}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: token
        }
      }
    )
    .catch(function (error) {
      console.log(error)
    })

  return response.data
}

export async function createJob(token, job) {
  return axios
    .post(
      `${API_HOST}/jobs`,
      { job: job },
      {
        headers: {
          Authorization: token
        }
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error.response
    })
}

export async function getJob(token, id) {
  const response = await axios
    .get(`${API_HOST}/jobs/${id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: token
      }
    })
    .catch(function (error) {
      console.log(error)
    })

  return response.data
}

export async function updateJob(token, id, job) {
  return axios
    .put(
      `${API_HOST}/jobs/${id}`,
      { job: job },
      {
        headers: {
          Authorization: token
        }
      }
    )
    .then((response) => response)
    .catch((error) => {
      console.log('error', error)
      throw error.response
    })
}

export async function translateJob(token, id, lang) {
  return axios.put(
    `${API_HOST}/jobs/translate/${id}/${lang}`,
    {},
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response.data)
    .catch(error => { throw (error.response) })
}

export async function deleteJob(token, id) {
  return axios.delete(
    `${API_HOST}/jobs/${id}`,
    {
      headers: {
        'Authorization': token
      },
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}

export async function updateJobs(token, jobs) {
  return axios
    .put(
      `${API_HOST}/jobs/update_jobs`,
      { jobs: jobs },
      {
        headers: {
          Authorization: token
        }
      }
    )
    .then((response) => response)
    .catch((error) => {
      throw error.response
    })
}

//--------------- Assets -----------------------------

export async function createAsset(token, file) {
  const data = new FormData();
  data.append("file", file)

  return axios.post(
    `${API_HOST}/assets`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': token
      }
    }
  )
  .then(response => response.data)
  .catch(error => { throw (error.response) })
}

export async function getAsset(token, id) {
  const response = await axios.get(
    `${API_HOST}/assets/${id}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }
  )
  .catch(function (error) {
    console.log(error);
  });
  return response.data
}

export async function downloadAsset(token, id) {
  const response = await axios.get(
    `${API_HOST}/assets/download/${id}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }
  )
  .catch(function (error) {
    console.log(error);
  });
  return response.data
}

export async function imagePath(token, id, format) {
  const response = await axios.get(
    `${API_HOST}/assets/image/${id}/${format}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }
  )
  .catch(function (error) {
    console.log(error);
  });
  return response.data
}

export async function getAssets(token, limit, offset, orderField, orderDirection, filterByType, filterByOwner,query) {
  const response = await axios.get(
    `${API_HOST}/assets`, {
      params: {
        limit: limit,
        offset: offset,
        order: orderField,
        dir: orderDirection,
        filterType: filterByType,
        filterByOwner: filterByOwner,
        query: query,
      },
      headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  })
  .catch(function (error) {
    console.log(error);
  });

  return response.data
}

export async function updateAsset(token, id, asset) {
  return axios.put(
    `${API_HOST}/assets/${id}`,
    { asset: asset },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

export async function updateVariant(token, id, ratio, cropX, cropY, width, height) {
  const data = new FormData();
  data.append("ratio", ratio)
  data.append("cropX", cropX)
  data.append("cropY", cropY)
  data.append("width", width)
  data.append("height", height)

  return axios.put(
    `${API_HOST}/assets/variant/${id}`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': token
      }
    }
  )
  .then(response => response)
  .catch(error => {  })
}

export async function deleteAsset(token, id) {
  return axios.delete(
    `${API_HOST}/assets/${id}`,
    {
      headers: {
        'Authorization': token
      },
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

//--------------- Companies -----------------------

export async function getRedirects(token, limit, offset, query) {
  const response = await axios.get(
    `${API_HOST}/redirects?limit=${limit}&offset=${offset}&query=${query}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function getRedirect(token, id) {
  const response = await axios.get(
    `${API_HOST}/redirects/${id}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }
  )
    .catch(function (error) {
      console.log(error);
    });

  return response.data
}

export async function updateRedirect(token, id, redirect) {
  return axios.put(
    `${API_HOST}/redirects/${id}`,
    { redirect: redirect },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

export async function updateRedirects(token, items) {
  return axios.put(
    `${API_HOST}/redirects/update_redirects`,
    { redirects: items },
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response.data)
    .catch(error => { throw (error.response) })
}

export async function createRedirect(token, redirect) {
  return axios.post(
    `${API_HOST}/redirects`,
    { redirect: redirect },
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response.data)
  .catch(error => { throw (error.response) })
}

export async function deleteRedirect(token, id) {
  return axios.delete(
    `${API_HOST}/redirects/${id}`,
    {
      headers: {
        'Authorization': token
      },
    }
  )
    .then(response => response)
    .catch(error => { throw (error.response) })
}


//--------------- Advisoryfields -----------------------------

export async function getAdvisoryfields(token) {
  const response = await axios.get(
    `${API_HOST}/advisoryfields`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  })
    .catch(function (error) {
      console.log(error);
    });
  return response.data
}

//--------------- Translate Helper -----------------------------

export async function translateItem(token, id, lang, controller) {
  return axios
  .put(
    `${API_HOST}/${controller}/translate/${id}/${lang}`,
    {},
    {
      headers: {
        'Authorization': token
      }
    }
  )
  .then(response => response.data)
  .catch(error => { throw (error.response) })
}

//--------------- Exports -----------------------
export async function getHUKProfessionals(token) {
  return axios.get(
    `${API_HOST}/export/huk-professionals`, {
      responseType: 'blob',
      headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }).then((response) => {
    if (response.blob)
      return response.blob()
    return response.data
  })
  .catch(function (error) {
    console.log(error);
  });

}

export async function getHUKChancelleries(token) {
  return axios.get(
    `${API_HOST}/export/huk-chancelleries`, {
    responseType: 'blob',
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }).then((response) => {
    if (response.blob)
      return response.blob()
    return response.data
  })
    .catch(function (error) {
      console.log(error);
    });

}

export async function getAuxiliaProfessionals(token) {
  return axios.get(
    `${API_HOST}/export/auxilia-professionals`, {
      responseType: 'blob',
      headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }).then((response) => {
    if (response.blob)
      return response.blob()
    return response.data
  })
  .catch(function (error) {
    console.log(error);
  });

}

export async function getAuxiliaChancelleries(token) {
  return axios.get(
    `${API_HOST}/export/auxilia-chancelleries`, {
    responseType: 'blob',
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }).then((response) => {
    if (response.blob)
      return response.blob()
    return response.data
  })
    .catch(function (error) {
      console.log(error);
    });

}

export async function getChancelleries(token) {
  return axios.get(
    `${API_HOST}/export/chancelleries`, {
    responseType: 'blob',
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }).then((response) => {
    if (response.blob)
      return response.blob()
    return response.data
  })
    .catch(function (error) {
      console.log(error);
    });

}

export async function getMediators(token) {
  return axios.get(
    `${API_HOST}/export/mediators`, {
    responseType: 'blob',
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }).then((response) => {
    if (response.blob)
      return response.blob()
    return response.data
  }).catch(function (error) {
    console.log(error);
  });
}

export async function getMedicinelawyers(token) {
  return axios.get(
    `${API_HOST}/export/medicinelawyers`, {
    responseType: 'blob',
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }).then((response) => {
    if (response.blob)
      return response.blob()
    return response.data
  }).catch(function (error) {
    console.log(error);
  });
}


//--------------- Consent Management -----------------------------

export async function deleteThirdPartyService(token, id) {
  return axios.delete(
    `${API_HOST}/third_party_services/${id}`,
    {
      headers: {
        'Authorization': token
      },
    }
  )
  .then(response => response)
  .catch(error => { throw (error.response) })
}

export async function getConsents(token, lang) {
  return axios.get(
    `${API_HOST}/consents?lang=${lang}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }).then((response) => {
    return response.data
  }).catch(function (error) {
    console.log(error);
  });
}

export async function updateConsent(token, id, consent) {
  return axios
    .put(
      `${API_HOST}/consents/${id}`,
      { consent: consent },
      {
        headers: {
          'Authorization': token
        }
      }
    )
    .then(response => response)
    .catch(error => {
      throw (error.response)
    })
}

export async function updateConsentTypeTranslation(token, consentTypeTranslation) {
  return axios
    .put(
      `${API_HOST}/consent_type_translations/${consentTypeTranslation.id}`,
      { consent_type_translation: consentTypeTranslation },
      {
        headers: {
          'Authorization': token
        }
      }
    )
    .then(response => response)
    .catch(error => {
      throw (error.response)
    })
}

export async function getConsentTranslation(token, id, lang) {
  return axios.get(
    `${API_HOST}/consent_translations/${id}?lang=${lang}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }).then((response) => {
    return response.data
  }).catch(function (error) {
    console.log(error);
  });
}

export async function updateConsentTranslation(token, consent_translation) {
  return axios.put(
    `${API_HOST}/consent_translations/${consent_translation.id}`,
    { consent_translation: consent_translation, },
    { headers: {
      'Authorization': token
      }
    }
  )
  .then((response) => response)
  .catch((error) => {
    throw (error.response)
  });
}

export async function getConsentTypes(token, lang) {
  return axios.get(
    `${API_HOST}/consent_types?lang=${lang}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': token
    }
  }).then((response) => {
    return response.data
  }).catch(function (error) {
    console.log(error);
  });
}
