import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { AppContext } from '../../data/ContextProvider'

import Title from "../../components/Shared/module/Title/Title"
import Subnavigation from "../Shared/module/Navigation/Subnavigation";

class PagesTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
    }
  }

  openTab = (key) => {
    this.setState({
      activeTab: key
    })
  }

  render() {

    const admin = (this.context.user.admin || this.context.user.diro_admin)

    const lang = this.props.match.params.lang
    const tabs = [
      { title: "Seitenübersicht", path: `/${lang}/pages` },
      { title: "Navigation", path: `/${lang}/pages/menu` },
      { title: "Footer", path: `/${lang}/pages/footer` },
    ]

    if (admin) {
      tabs.push({ title: "Seitentypen", path: `/${lang}/pages/types` })
    }

    let active = 0
    tabs.map((tab, index) => {
      if (this.props.match.url.indexOf(tab.path) !== -1) {
        active = index
      }
      return ''
    })

    return (
      <Title headline={this.props.headline}>
        <Subnavigation
          tabs={tabs}
          active={active}
          tabChange={this.openTab} />
      </Title>
    )
  }
}

PagesTabs.contextType = AppContext
export default withRouter(PagesTabs);
