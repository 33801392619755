import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Title from "../Shared/module/Title/Title";
import Subnavigation from "../Shared/module/Navigation/Subnavigation";

class OfficesEditTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
    }
  }

  openTab = (key) => {
    this.setState({
      activeTab: key
    })
  };

  render() {
    const lang = this.props.match.params.lang
    const officeid = this.props.officeid

    const tabs = [
      { title: "Stammdaten", path: `/${lang}/offices/${officeid}/data` },
      { title: "Standorte", path: `/${lang}/offices/${officeid}/locations` },
      { title: "Personen", path: `/${lang}/offices/${officeid}/team` }
    ]

    let active = 0
    tabs.map((tab, index) => {
      if (this.props.match.url.indexOf(tab.path) !== -1) {
        active = index
      }
      return ''
    })

    return (
      <Title headline={this.props.headline}>
        <Subnavigation
          tabs={tabs}
          active={active}
          tabChange={this.openTab}
        />
      </Title>
    )
  }
}

export default withRouter(OfficesEditTabs);
