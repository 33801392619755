import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {arrayMoveImmutable} from 'array-move';

import DnDRow from "./DnDRow";


class DnDRows extends React.Component {

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.type !== "DnDRows") {
      return;
    }

    const rows = [...this.props.rowsData]
    const reorderedRows = arrayMoveImmutable(rows, result.source.index, result.destination.index)
    this.props.handleChange(reorderedRows)
  }

  removeRow = (key) => {
    const rows = this.props.rowsData.filter(row => row.key !== key);
    this.props.handleChange(rows)
  }

  render() {
    let rows = [...this.props.rows]

    return (
      <div className="dragagble">
        <DragDropContext onDragEnd={this.onDragEnd} >
          <Droppable droppableId="droppable" type="DnDRows">
            {(provided, snapshot) => (
              <div
                className="draggable__rows"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {rows && rows.map((row, index) => (

                  <Draggable key={row.key} draggableId={row.key} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <DnDRow
                            key={index}
                            row={row}
                            fieldNames={this.props.fieldNames}
                            deleteRow={() => this.removeRow(row.key)}
                            />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    )
  }
}

export default DnDRows;
