import React, { Component } from "react";
import { withRouter, Route, Switch } from "react-router-dom";


import OfficeEdit from "../components/Offices/OfficeEdit";
import OfficeSites from "../components/Offices/OfficeSites";
import PersonsList from "../components/Persons/PersonsList";
import PersonEdit from "../components/Persons/PersonEdit";
import PersonNew from "../components/Persons/PersonNew";

class Offices extends Component {

  render() {
    return (
      <div className="tab-content" id="nav-tabContent">
        <Switch>
          <Route exact path="/:lang/office">
            <OfficeEdit />
          </Route>

          <Route exact path="/:lang/offices/:officeid/data">
            <OfficeEdit />
          </Route>

          <Route exact path="/:lang/offices/:officeid/locations">
            <OfficeSites />
          </Route>

          <Route exact path="/:lang/offices/:officeid/team">
            <PersonsList />
          </Route>

          <Route exact path="/:lang/offices/:officeid/team/new">
            <PersonNew />
          </Route>

          <Route exact path="/:lang/offices/:officeid/team/:userid/:tabs?">
            <PersonEdit />
          </Route>

        </Switch>
      </div>
    );
  }
}

export default withRouter(Offices);
