import React from 'react';
import Overlay from "../../element/Overlay/Overlay";

function PublishOnNetworkConfirmOverlay(props) {
  return (
    <Overlay closeOverlayCallback={() => props.closeOverlay()}>
      <div className="dd-dialog dd-dialog--danger">

        {props.content_type === 'article' &&
          <h3 className="dd-dialog__title">
              {!props.published && "Wollen Sie diesen Beitrag wirklich im DIRO-Netzwerk veröffentlichen?"}
              {props.published && "Wollen Sie diesen Beitrag wirklich im DIRO-Netzwerk depublizieren?"}
          </h3>
        }

        {props.content_type === 'product' &&
          <h3 className="dd-dialog__title">
              {!props.published && "Wollen Sie dieses Produkt wirklich im DIRO-Netzwerk veröffentlichen?"}
              {props.published && "Wollen Sie dieses Produkt wirklich aus dem DIRO-Netzwerk zurückziehen?"}
          </h3>
        }

        {props.content_type === 'article' &&
          <p className="dd-dialog__text">
            {!props.published &&
              "Wenn Sie die Freigabe im Netzwerk erteilen, beginnt der redaktionelle Abnahmeprozess durch die DIRO-Redaktion, bevor der Beitrag endgültig in das Netzwerk verteilt wird. Wollen Sie den Beitrag jetzt zur Abnahme senden?"
            }
            {props.published &&
              "Wenn Sie für diesen Fachbeitrag die Verbreitung in das DIRO-Netzwerk unterbinden, ist er im Netzwerk nicht mehr verfügbar. Um ihn wieder freizugeben, ist es notwendig, den Freigabeprozess über die DIRO erneut zu durchlaufen."
            }
          </p>
        }

        {props.content_type === 'product' &&
          <p className="dd-dialog__text">
            {!props.published &&
              "Wenn Sie die Freigabe im Netzwerk erteilen, beginnt der redaktionelle Abnahmeprozess durch die DIRO-Redaktion, bevor das Produkt endgültig in das Netzwerk verteilt wird. Wollen Sie das Produkt jetzt zur Abnahme senden?"
            }
            {props.published &&
              "Wenn Sie für dieses Produkt die Verbreitung in das DIRO-Netzwerk unterbinden, kann es zukünftig nicht mehr von Kanzleien übernommen werden. Bestehende Übernahmen durch Kanzleien sind davon nicht betroffen. Um das Produkt wieder freizugeben, ist es notwendig, den Freigabeprozess über die DIRO erneut zu durchlaufen."
            }
          </p>
        }

        <div className="dd-dialog__actions">
          <button className="dd-btn dd-btn--outline" onClick={() => props.onConfirmation()}>
            {!props.published && "Zur Freigabe senden"}
            {props.published && "Beitrag zurückziehen"}
          </button>
          <button className="dd-btn dd-btn--primary" onClick={() => props.closeOverlay()}>
            Abbrechen
          </button>
        </div>
      </div>
    </Overlay>
  );
}

export default PublishOnNetworkConfirmOverlay;
