import React from "react";

import { throttle } from "throttle-debounce";

import Textfield from '../../atom/Textfield/Textfield'
import Searchbar from "../../element/Searchbar/Searchbar";
import Pagination from "../../element/Pagination/Pagination";
import Tablist from '../../../Shared/module/Tablist/Tablist'
import RadioButton from "../../../Shared/atom/RadioButton/RadioButton";

import { getToken } from "../../../../lib/lib";
import { getPages, getArticles, getProducts } from "../../../../data/api";


class PageSelector extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      tabValue: "a",
      buttonURL: "",
      buttonTarget: false,
      loading: true,
      query: '',
      count: 0,
      pagesCount: 0,
      loadMode: 0,
      limit: 10,
      lang: 'de',
      currentPage: 0,
      orderField: 'updated_at',
      orderDirection: 'desc',
      filterByType: [],
      filterByOwner: [],
      // page: {},
      pages: []
    }

    this.searchThrottled = throttle(500, this.loadData);
  }

  onLoadModeChange = (e, status) => {
    const state = this.state
    state.loadMode = status

    this.setState({
      state,
      currentPage: 0
    }, async () => {
      await this.loadData()
    })
  }

  changeTab = (tabValue) => {
    this.setState({ tabValue })
  }

  handlePaginationClick = async (e, page) => {
    e.preventDefault()
    this.setState({
      ...this.state,
      currentPage: page
    }, async () => {
      await this.loadData()
    })
  }

  handleSelectLimit = (limit) => {
    this.setState({ limit, currentPage: 0 }, async () => {
      await this.loadData()
    })
  }

  onChangeExternalUrl = e => {
    this.setState({ buttonURL: e.target.value })
  }

  externalTargetChange = () => {
    const buttonTarget = !this.state.buttonTarget
    this.setState({ buttonTarget }, () => {
    })
  }

  onChange = e => {
    this.setState({ query: e.target.value })
    this.searchQuery()
  }

  searchQuery = () => {
    this.searchThrottled(this.state.query.toLowerCase())
  }

  selectPage = (e, page) => {
    e.preventDefault()
    this.props.selectPageCallback(page)
  }

  selectExternalLink = (e) => {
    e.preventDefault()

    this.props.selectPageCallback({
      buttonURL: this.state.buttonURL.trim(),
      buttonTarget: this.state.buttonTarget,
    })
  }

  loadData = async () => {
    const offset = this.state.currentPage * this.state.limit

    let pages     = {}
    let pagesData = []

    // Pages
    if (this.state.loadMode === 0) {
       pages = await getPages(getToken(),
        'none', //this.state.lang,
        this.state.limit,
        offset,
        this.state.orderField,
        this.state.orderDirection,
        this.state.query)

      pagesData = pages.pages

    } else if (this.state.loadMode === 1) {
      pages = await getArticles(getToken(),
        'none', //this.state.lang,
        this.state.limit,
        offset,
        this.state.orderField,
        this.state.orderDirection,
        this.state.query)

      pagesData = pages.articles

    } else if (this.state.loadMode === 2) {

      pages = await getProducts(getToken(),
        'none', //this.state.lang,
        this.state.limit,
        offset,
        this.state.orderField,
        this.state.orderDirection,
        this.state.query)

      pagesData = pages.products
    }

    const pagesCount = Math.ceil(pages.count / this.state.limit)

    let buttonURL = ""

    if (this.props.buttonURL) {
      buttonURL = this.props.buttonURL
    }

    if (this.props.content && this.props.content.buttonURL) {
      buttonURL = this.props.content.buttonURL
    }

    this.setState({
      ...this.state,
      pagesCount: pagesCount,
      count: pages.count,
      pages: pagesData,
      pageID: (this.props.content && this.props.content.pageID) ? this.props.content.pageID : 0,
      buttonURL: buttonURL,
      buttonTarget: (this.props.content && this.props.content.buttonTarget) ? this.props.content.buttonTarget : "",
    })
  }

  async componentDidMount() {
    this.setState({
      tabValue: (this.props.content && this.props.content.pageID) ? "b" : "a"
    }, async () => {
      await this.loadData()
    })
  }

  render() {
    let tabValue = this.state.tabValue

    const tabs = []
    if (!this.props.disableExternal) {
      tabs.push({ title: 'Link im www', tabValue: 'a' })
    } else {
      tabValue = 'b'
    }

    tabs.push({ title: 'Internes Ziel', tabValue: 'b' })

    return (
      <div className="dd-page-selector">

        <Tablist
          additionalClass="w-75 ml-auto mr-auto mb-4 d-flex justify-content-between"
          tabs={tabs}
          active={this.state.tabValue}
          tabChange={this.changeTab}
        />

        {(tabValue === "a") &&
          <React.Fragment>
            <div className="form-group">
              <Textfield
                name="buttonURL"
                type="text"
                isRequired={false}
                label="Bitte hier die Web-Adresse eintragen, auf die verlinkt werden soll."
                placeholder="https://diro.eu"
                handleChange={this.onChangeExternalUrl}
                defaultValue={this.state.buttonURL}
              />
            </div>

            <div className="form-group">
              <button className="dd-btn dd-btn--primary" onClick={(e) => this.selectExternalLink(e)}>Linkziel übernehmen</button>
            </div>
          </React.Fragment>
        }

        {(tabValue === "b") &&
          <React.Fragment>
            <div className="dd-page-selector__search">
              <div className="form-row justify-content-end">

                <RadioButton
                  name="load_mode"
                  label="Seiten"
                  value={true}
                  selected={this.state.loadMode === 0}
                  handleChange={(e) => this.onLoadModeChange(e, 0)}
                />

                <RadioButton
                  name="load_mode"
                  label="Fachbeiträge"
                  value={false}
                  selected={this.state.loadMode === 1}
                  handleChange={(e) => this.onLoadModeChange(e, 1)}
                />

                <RadioButton
                  name="load_mode"
                  label="Produkte"
                  value={false}
                  selected={this.state.loadMode === 2}
                  handleChange={(e) => this.onLoadModeChange(e, 2)}
                />

                <Searchbar
                  placeholder="Seite oder Beitrag …"
                  icon="search"
                  onChangeCallback={(e) => this.onChange(e)}
                />
              </div>
            </div>

            <ul className="dd-page-selector__list">
              {this.state.pages && this.state.pages.map((page, index) => {
                return <li key={index} className="dd-page-select">
                    <button className="dd-page-select__button" onClick={(e) => this.selectPage(e, page)}>
                    <div className="dd-page-select__type">Sprache: {(page.lang)? page.lang.toUpperCase() : ''}</div>
                      <div className="dd-page-select__title">{page.title}</div>
                    </button>
                  </li>
              })}
            </ul>

            <div className="dd-page-selector__pager">
              <Pagination
                total={this.state.pagesCount}
                current={this.state.currentPage}
                onChange={this.handlePaginationClick}
                onSelect={this.handleSelectLimit}
                limit={this.state.limit}
              />
            </div>
          </React.Fragment>
        }

      </div>
    )
  }
}

export default PageSelector;
