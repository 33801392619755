import React from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {arrayMoveImmutable} from 'array-move';

import Loader from "../Shared/atom/Loader/Loader";
import Textfield from "../Shared/atom/Textfield/Textfield";
import Editbar from "../Shared/element/Editbar/Editbar";
import Checkbox from "../Shared/atom/Checkbox/Checkbox";
import RadioButton from "../Shared/atom/RadioButton/RadioButton";
import Accordion from "../Shared/module/Accordion/Accordion";
import LangSwitcherStatic from "../Shared/element/LangTools/LangSwitcherStatic";
import SimpleSelectfield from "../Shared/atom/Selectfield/SimpleSelectfield";

import { getSetting, updateSetting, getSyslanguages, getLanguages, getAllPages, pageToHomepage, pageToContactpage } from "../../data/api";
import { getToken } from "../../lib/lib";
import { AppContext } from "../../data/ContextProvider";

class Misc extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      loading: true,
      activeLanguage: 'de',
      contactpage: 0,
      homepage: 0,
      items: [],
      orderedLanguages: []
    }
  }

  onIncompleteChange = (e) => {
    const settings = { ...this.state.settings }
    settings.misc.info_msg_enabled = !this.state.settings.misc.info_msg_enabled
    this.setState({ settings })
  }

  onBreadcrumbChange = (e) => {
    const settings = { ...this.state.settings }
    settings.misc.breadcrumb_enabled = !this.state.settings.misc.breadcrumb_enabled
    this.setState({ settings })
  }

  onMenuSearchChange = (e) => {
    const settings = { ...this.state.settings }
    settings.misc.menu_search_enabled = !this.state.settings.misc.menu_search_enabled
    this.setState({ settings })
  }

  onSwitcherFormatChange = (e, status) => {
    const settings = this.state.settings
    settings.misc.lang_switcher_format = status
    this.setState({ settings })
  }

  onCheckboxChange = (e, field) => {
    const settings = { ...this.state.settings }
    settings.misc[field] = !this.state.settings.misc[field]
    this.setState({ settings })
  }

  onLangStatusChange = (e, lang) => {
    const settings = { ...this.state.settings }
    settings.lang[lang].enabled = !settings.lang[lang].enabled
    this.setState({ settings })
  }

  onLangPublicChange = (e, lang) => {
    const settings = { ...this.state.settings }
    settings.lang[lang].public = !settings.lang[lang].public
    this.setState({ settings })
  }

  changeValue = (e, fieldname) => {
    const settings = { ...this.state.settings }
    const value = e.target.value
    const name = fieldname ? fieldname : e.target.name
    settings.misc[name] = value
    this.setState({ settings})
  }

  changeSMTPValue = (e, fieldname) => {
    const settings = { ...this.state.settings }
    const value = e.target.value
    const name = fieldname ? fieldname : e.target.name

    settings.smtp[name] = value
    this.setState({ settings})
  }

  onContactChange = (e, fieldname) => {
    const settings = { ...this.state.settings }
    settings[fieldname] = e.target.value
    this.setState({ settings})
  }

  onContactValueChange = (fieldname, value) => {
    const settings = { ...this.state.settings }
    settings[fieldname] = value
    this.setState({ settings})
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const langs = [...this.state.orderedLanguages ]
    const settings = this.state.settings

    const reorderedLangs = arrayMoveImmutable(langs, result.source.index, result.destination.index)

    //Gewichte aktualisieren
    reorderedLangs.forEach((lang, index) => {
      settings.lang[lang.shorthand].weight = index
    })

    this.setState({
      orderedLanguages: reorderedLangs,
      settings: settings
    })
  }


  onContactLanguageChange = (syslanguageId) => {
    this.setState({
      contactOptionsLanguage: syslanguageId
    })
  }

  setActiveLanguage = (shorthand) => {
    this.setState({
      activeLanguage: shorthand
    }, ()=>{
      this.loadData()
    })
  }

  onSelectHomepage = (value) => {
    this.setState({ homepage: value })
  }

  onSelectSearchLandingPage = (value) => {
    const settings = { ...this.state.settings }
    const name = "search_not_found_page_" + this.state.activeLanguage
    settings.misc[name] = value
    this.setState({ settings })
  }

  onSelectContactpage = (value) => {
    this.setState({ contactpage: value })
  }

  onSubmit = async () => {
    this.setState({ isUpdating: true })


    updateSetting(getToken(), this.state.settings.id, this.state.settings)
      .then(async syslanguage => {
        await this.loadData()
      })
      .catch(err => {
        this.setState({ isUpdating: false })
      })

    if (this.state.homepage) {
      await pageToHomepage(this.state.homepage)
    }

    if (this.state.contactpage) {
      await pageToContactpage(this.state.contactpage)
    }

    await this.loadData()
  }

  sortLanguages = (hash) => {
    const tmp = []
    for (const [key, val] of Object.entries(hash)) {
      tmp.push(val)
    }

    tmp.sort((a, b) =>  parseInt(a.weight) - parseInt(b.weight))
    return tmp
  }

  languageTitle = (shorthand) => {
    const lang = this.state.syslanguages.find(lang => lang.shorthand === shorthand)
    if (lang) {
      return lang.title
    }
    return ""
  }

  loadData = async () => {
    const settingsObj  = await getSetting(getToken())
    const syslanguages = await getSyslanguages(getToken())
    const activatedLanguagesRecord = await getLanguages(getToken())


    const pages = await getAllPages(this.state.activeLanguage)
    const settings = settingsObj.settings

    const homepage    = pages.pages.find(page => page.homepage === true)
    const contactpage = pages.pages.find(page => page.contactpage === true)

    let contactpage_id = 0
    if (contactpage) {
      contactpage_id = contactpage.id
    }

    let homepage_id = 0
    if (homepage) {
      homepage_id = homepage.id
    }

    if (!settings.lang) {
      settings.lang = {}
    }

    //default
    let activatedLanguages = [{ id: 1, shorthand: "de", title: "Deutsch"}]
    if (activatedLanguagesRecord.syslanguages && activatedLanguagesRecord.syslanguages.length > 0) {
      activatedLanguages = activatedLanguagesRecord.syslanguages
    }

    //add missing syslanguages
    syslanguages.syslanguages.forEach(element => {
      if (!(element.shorthand in settings.lang)) {
        settings.lang[element.shorthand] = { enabled: false, public: false, weight: 10, shorthand: element.shorthand }
      }
    })

    let activeLang = this.state.activeLanguage
    // if (activatedLanguages[0]) {
    //   activeLang = activatedLanguages[0].shorthand
    // }

    for (let i=0; i<activatedLanguages.length;i++) {
      if (typeof settings.misc[`expertise_${activatedLanguages[i].shorthand}`] === 'undefined') {
        settings.misc[`expertise_${activatedLanguages[i].shorthand}`] = 'Expertise'
      }
    }

    if (!settings.smtp) {
      settings.smtp = {}
    }

    this.setState({
      settings: settings,
      pages: pages.pages,
      homepage: homepage_id,
      contactpage: contactpage_id,
      loading: false,
      syslanguages: syslanguages.syslanguages,
      activatedLanguages: activatedLanguages,
      activeLanguage: activeLang,
      orderedLanguages: this.sortLanguages(settings.lang),
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  render_contact_options = (number, shorthand) => {
    return <div key={`contact_option_label_${number}_${shorthand}`} className="col-sm-6 col-lg-3">
      <div className="card-body__content ml-auto mr-auto mt-3">
        <div className="form-check mb-2">
          <label className="textfield__label">
            Feldtyp auswählen
          </label>
          <RadioButton
            name={`contact_option_type_${number}_${shorthand}`}
            label="Text-Info"
            value="text"
            selected={this.state.settings[`contact_option_type_${number}_${shorthand}`] === "text"}
            handleChange={(e) => this.onContactValueChange(`contact_option_type_${number}_${shorthand}`, "text")}
          />
          <RadioButton
            name={`contact_option_type_${number}_${shorthand}`}
            label="Telefon"
            value="tel"
            selected={this.state.settings[`contact_option_type_${number}_${shorthand}`] === "tel"}
            handleChange={(e) => this.onContactValueChange(`contact_option_type_${number}_${shorthand}`, "tel")}
          />
          <RadioButton
            name={`contact_option_type_${number}_${shorthand}`}
            label="E-Mail"
            value="email"
            selected={this.state.settings[`contact_option_type_${number}_${shorthand}`] === "email"}
            handleChange={(e) => this.onContactValueChange(`contact_option_type_${number}_${shorthand}`, "email")}
          />
        </div>
        <div className="form-group">
          <Textfield
            name={`contact_option_label_${number}_${shorthand}`}
            type="text"
            label="Überschrift"
            handleChange={(e) => this.onContactChange(e, `contact_option_label_${number}_${shorthand}`)}
            defaultValue={this.state.settings[`contact_option_label_${number}_${shorthand}`]}
          />
        </div>
        <div className="form-group">
          <Textfield
            name={`contact_option_value_${number}_${shorthand}`}
            type="text"
            label="Unterzeile"
            handleChange={(e) => this.onContactChange(e, `contact_option_value_${number}_${shorthand}`)}
            defaultValue={this.state.settings[`contact_option_value_${number}_${shorthand}`]}
          />
        </div>
      </div>
    </div>
  }

  render() {

    const admin = (this.context.user.admin || this.context.user.diro_admin)

    if (this.state.loading || !admin) {
      return <Loader />
    }

    const search_not_found_text_key = "search_not_found_text_" + this.state.activeLanguage
    const search_not_found_page_key = "search_not_found_page_" + this.state.activeLanguage
    const search_not_found_text_value = this.state.settings.misc[search_not_found_text_key] ?? ""
    const search_not_found_page_value = this.state.settings.misc[search_not_found_page_key] ?? ""

    return (
      <>
        <div className="sidebar">
          <LangSwitcherStatic
            activeLanguage={this.state.activeLanguage}
            syslanguages={this.state.activatedLanguages}
            setActiveLanguage={(shorthand) => this.setActiveLanguage(shorthand)}
          />
        </div>
        <Accordion
          title="Hinweisleiste am oberen Seitenrand"
          isOpen={false}
        >
          <div className="dd-form-section dd-form-section--combined dd-form-section--first">
            <div className="form-check form-check-inline">
              <Checkbox
                label="Adresshinweis am Seitenkopf anzeigen"
                id="info_msg_enabled"
                selected={this.state.settings.misc.info_msg_enabled}
                onChange={(e) => this.onIncompleteChange(e)}
              />
            </div>
          </div>

          <div className="dd-form-section dd-form-section--last">
            {this.state.activatedLanguages.map((syslanguage, key) => {
              return <div key={key}>
                {(this.state.activeLanguage === syslanguage.shorthand) &&
                  <React.Fragment>
                    <strong>{syslanguage.title}</strong>
                    <div className="row">
                    {
                      [...Array(4)].map((x, i) => (
                        this.render_contact_options(i, syslanguage.shorthand)
                      ))
                    }
                    </div>
                  </React.Fragment>
                }
              </div>
            })}

            <div className="text-center pt-2">Hinweis: Es werden <strong>maximal vier Gruppen</strong> angezeigt. Um weniger Gruppen anzuzeigen, lassen Sie einfach die entsprechenden Felder leer.</div>
          </div>
        </Accordion>

        <Accordion title="Sonstige Einstellungen" isOpen={false} >

          <div className="dd-form-section dd-form-section--combined dd-form-section--first dd-form-section--last">
            <div className="form-group">
              <div className="form-check form-check-inline">
                  <Checkbox
                    label="Breadcrumb aktivieren?"
                    id="breadcrumb_enabled"
                    selected={this.state.settings.misc.breadcrumb_enabled}
                    onChange={(e) => this.onBreadcrumbChange(e)}
                  />
                </div>
            </div>

            <div className="form-group">
            {/* loop over languages, because Textfield is uncontrolled */}
            {this.state.activatedLanguages.map((syslanguage, key) => {
              return (this.state.activeLanguage === syslanguage.shorthand &&
                <Textfield key={key}
                  name={`expertise_${this.state.activeLanguage}`}
                  type="text"
                  label='Beschriftung "Expertise"'
                  handleChange={(e) => this.changeValue(e)}
                  defaultValue={this.state.settings.misc[`expertise_${this.state.activeLanguage}`]}
                />
              )
            })}
            </div>

            <div className="form-group">
              <div className="form-check ">
                <label className="radio-button__group-label ">Wählen Sie bitte ihre Startseite aus</label>
                <SimpleSelectfield
                  label={false}
                  name="homepage"
                  firstOptionValue="0"
                  firstOptionLabel="Bitte auswählen"
                  indexLabel="id"
                  optionLabel="title"
                  options={this.state.pages}
                  onSelect={this.onSelectHomepage}
                  selected={this.state.homepage}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="form-check ">
                <label className="radio-button__group-label ">Wählen Sie bitte ihre Kontaktseite aus</label>
                <SimpleSelectfield
                  label={false}
                  name="contactpage"
                  firstOptionValue="0"
                  firstOptionLabel="Bitte auswählen"
                  indexLabel="id"
                  optionLabel="title"
                  options={this.state.pages}
                  onSelect={this.onSelectContactpage}
                  selected={this.state.contactpage}
                />
              </div>
            </div>

          </div>
        </Accordion>

        <Accordion title="Suche" isOpen={false} >

          <div className="dd-form-section dd-form-section--combined dd-form-section--first dd-form-section--last">

            <div className="form-group">
              <div className="form-check form-check-inline">
                  <Checkbox
                    label="Suchleiste aktivieren?"
                    id="menu_search_enabled"
                    selected={this.state.settings.misc.menu_search_enabled}
                    onChange={(e) => this.onMenuSearchChange(e)}
                  />
                </div>

              <div className="form-group">
                <p>Wenn Sie die Nutzer bei ergebnisloser Suche auf eine bestimmte Seite Ihres Angebotes leiten möchten, können Sie hier eine kurze Erklärung dazu verfassen. Den gewünschten Linktext setzen Sie bitte in [eckige Klammern] und wählen unten die Zielseite aus.</p>
                <label className="textfield__label">Text bei ergebnisloser Suche</label>
                <textarea
                  className="form-control"
                  name={search_not_found_text_key}
                  value={search_not_found_text_value}
                  onChange={(e) => this.changeValue(e, search_not_found_text_key)}
                />
              </div>
              <div className="form-check ">
                <label className="radio-button__group-label ">Wählen Sie bitte hier die Seite aus, auf die Nutzer bei erfolgloser Suche geleitet werden sollen.</label>
                <SimpleSelectfield
                  label={false}
                  name={search_not_found_page_key}
                  firstOptionValue="0"
                  firstOptionLabel="Bitte auswählen"
                  indexLabel="id"
                  optionLabel="title"
                  options={this.state.pages}
                  onSelect={this.onSelectSearchLandingPage}
                  selected={search_not_found_page_value}
                />
              </div>
            </div>
          </div>
        </Accordion>

        <Accordion
          title="Sprachen"
          isOpen={false}
          >
          <div className="dd-form-section dd-form-section--first dd-form-section--last">
            <div className="form-check form-check-inline mb-3">
              <label className="textfield__label">
                Darstellung des Sprachumschalters&nbsp;&nbsp;&nbsp;
              </label>

              <RadioButton
                name="lang_switcher_format"
                label="Kürzel"
                value="shortcut"
                selected={this.state.settings.misc.lang_switcher_format !== "flag"}
                handleChange={(e) => this.onSwitcherFormatChange(e, "shortcut")} />
              <RadioButton
                name="lang_switcher_format"
                label="Flaggen"
                value="flag"
                selected={this.state.settings.misc.lang_switcher_format === "flag"}
                handleChange={(e) => this.onSwitcherFormatChange(e, "flag")} />
            </div>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {this.state.orderedLanguages.map((lang, key) => (
                      <Draggable key={lang.shorthand} draggableId={lang.shorthand} index={key}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div key={key}>
                              <div className="dd-form-section dd-form-section--white">
                                <div className="d-flex justify-content-between">
                                  <div className={(this.state.orderField === 'name') ? 'is-active' : ''}>{this.languageTitle(lang.shorthand)}</div>
                                  <div>
                                    <div className="form-check form-check-inline">
                                      <Checkbox
                                        label="aktiviert"
                                        id={lang.shorthand}
                                        selected={(this.state.settings.lang[lang.shorthand].enabled)}
                                        onChange={(e) => this.onLangStatusChange(e, lang.shorthand)}
                                      />
                                      <Checkbox
                                        label="veröffentlicht"
                                        id={lang.shorthand}
                                        selected={(this.state.settings.lang[lang.shorthand].public)}
                                        onChange={(e) => this.onLangPublicChange(e, lang.shorthand)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Accordion>

        <Accordion title="E-Mail Server Einstellungen" isOpen={false} >

          <div className="dd-form-section dd-form-section--combined dd-form-section--first dd-form-section--last">

            <div className="form-group">
              <div className="form-group">
                <p>Hier können Sie die Zugangsdaten zu einem SMTP-Konto Ihres E-Mail Servers eintragen. Sobald die Zugangsdaten hinterlegt sind, erfolgt der Mailversand entsprechend über das hinterlegte Konto und nicht mehr über die globale DIRO-Ifrastruktur. Bitte füllen Sie alle Textfelder aus.</p>
              </div>
              <div className="form-group ">
                <Textfield
                  name={'server'}
                  type="text"
                  label='Serveradresse'
                  handleChange={(e) => this.changeSMTPValue(e)}
                  defaultValue={this.state.settings?.smtp?.server}
                />
              </div>
              <div className="form-group ">
                <Textfield
                  name={'port'}
                  type="text"
                  label='Port'
                  handleChange={(e) => this.changeSMTPValue(e)}
                  defaultValue={this.state.settings?.smtp?.port}
                />
              </div>
              <div className="form-group ">
                <Textfield
                  name={'login'}
                  type="text"
                  label='Login'
                  handleChange={(e) => this.changeSMTPValue(e)}
                  defaultValue={this.state.settings?.smtp?.login}
                />
              </div>
              <div className="form-group ">
                <Textfield
                  name={'password'}
                  type="password"
                  label='Passwort'
                  handleChange={(e) => this.changeSMTPValue(e)}
                  defaultValue={this.state.settings?.smtp?.password}
                />
              </div>
              <div className="form-group ">
                <Textfield
                  name={'from'}
                  type="text"
                  label='Absenderadresse'
                  handleChange={(e) => this.changeSMTPValue(e)}
                  defaultValue={this.state.settings?.smtp?.from}
                />
              </div>
            </div>
          </div>
        </Accordion>

        <Editbar
          hasPublish={false}
          hasPreview={false}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
        />
      </>
    )
  }
}

Misc.contextType = AppContext
export default Misc;
