import React from 'react';
import { Link } from "react-router-dom";

function Tablist(props) {
  const tabs = props.tabs || []
  const active = props.active || 0

  return (
    <nav>
      <div className={`nav nav-tabs dd_nav-tabs ${props.additionalClass ? props.additionalClass : ""}`} id="nav-tab"
           role="tablist">
        {tabs.map((tab, key) => {
          if (tab.tabValue) {
            return (
              <button
                key={key}
                onClick={(e) => {
                  e.preventDefault()
                  props.tabChange(tab.tabValue)
                }}
                className={(tab.tabValue === active) ? "btn btn-link active" : "btn btn-link"}
              >
                {tab.title}
              </button>
            )
          }
          return (
            <Link to={tab.path} key={key}>
              <button
                onClick={() => props.tabChange(key)}
                className={(key === active) ? "btn btn-link active" : "btn btn-link"}
              >
                {tab.title}
              </button>
            </Link>
          )
        })
        }
      </div>
    </nav>
  );
}

export default Tablist;
