import React from "react";

const ContactForm = (props) => {

  const content = props.block[0] // FIXME

  return <div className="dd-block-preview ">
    <h2>{content.headline}</h2>
    <div className="d-flex">
    </div>
  </div>
}

export default ContactForm;
