import React from "react";

import Switch from "react-switch";
import Loader from "../Shared/atom/Loader/Loader";
import Editbar from "../Shared/element/Editbar/Editbar";
import Accordion from "../Shared/module/Accordion/Accordion";

import { getLawyers, getNotLawyers, updatePerson } from "../../data/api";
import { getToken } from "../../lib/lib";
import { AppContext } from "../../data/ContextProvider";

class Access extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      loading: true,
      activeLanguage: 'de',
      contactpage: 0,
      homepage: 0,
      items: [],
      orderedLanguages: []
    }
  }

  onCheckboxChange = (e, field) => {
    const settings = { ...this.state.settings }
    settings.misc[field] = !this.state.settings.misc[field]
    this.setState({ settings })
  }

  changeValue = (e, fieldname) => {
    const settings = { ...this.state.settings }
    const value = e.target.value
    const name = fieldname ? fieldname : e.target.name
    settings.misc[name] = value
    this.setState({ settings})
  }

  handleIntranetAccess = async (user) => {
    user.intranet_access = !user.intranet_access
    await updatePerson(getToken(), user.id, user)
    await this.loadData()
  }

  handleBackendAccess = async (user) => {
    user.backend_access = !user.backend_access
    await updatePerson(getToken(), user.id, user)
    await this.loadData()
  }

  onSubmit = async () => {
    this.setState({ isUpdating: true })

    await this.loadData()
  }

  loadData = async () => {

    const lawyers = await getLawyers();
    const notLawyers = await getNotLawyers();
    console.log(lawyers, notLawyers)

    this.setState({
      lawyers: lawyers,
      notLawyers: notLawyers,
      loading: false,
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  renderLawyer = (user, index) => {
    return <div key={index} className="dd-form-section dd-form-section--white">
              <div className="d-flex justify-content-between">
                <div>{user?.full_name} ({user?.email})</div>
                  <div className="form-check form-check-inline">
                    <div className="dd-consent__action-activate">
                      <span>Zugang zum Intranet</span>
                      <Switch
                        onChange={() => this.handleIntranetAccess(user) }
                        checked={user.intranet_access}
                        offColor="#BCBDB5"
                        onColor="#707070"
                        uncheckedIcon={false}
                        height={20}
                        width={40}
                        activeBoxShadow="0px 0px 2px 2px rgba(0, 0, 0, 0.2)"
                      />
                    </div>
                   <div className="dd-consent__action-activate">
                      <span>Zugang zum CMS</span>
                      <Switch
                        onChange={() => this.handleBackendAccess(user) }
                        checked={user.backend_access}
                        offColor="#BCBDB5"
                        onColor="#707070"
                        uncheckedIcon={false}
                        height={20}
                        width={40}
                        activeBoxShadow="0px 0px 2px 2px rgba(0, 0, 0, 0.2)"
                      />
                    </div>
                </div>
              </div>
            </div>
  }

  render() {

    const admin = (this.context.user.admin || this.context.user.diro_admin)

    if (this.state.loading || !admin) {
      return <Loader />
    }

    return (
      <>
        <Accordion title="Berufsträger" isOpen={false} >
          <div className="dd-form-section dd-form-section--first dd-form-section--last">
            {this.state.lawyers?.map((lawyer, index) => {
              return this.renderLawyer(lawyer, index)
            })}
          </div>
        </Accordion>

        <Accordion title="Kanzleimitarbeiter" isOpen={false} >
          <div className="dd-form-section dd-form-section--first dd-form-section--last">
            {this.state.notLawyers?.map((lawyer, index) => {
              return this.renderLawyer(lawyer, index)
            })}
          </div>
        </Accordion>

        <Editbar
          hasPublish={false}
          hasPreview={false}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
        />
      </>
    )
  }
}

Access.contextType = AppContext
export default Access;
