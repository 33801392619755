import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";
import Overlay from "../../element/Overlay/Overlay";
import { ImagePreviewBlock } from "../../element/ImagePreview/ImagePreview";
import ImageSelector from "../../module/ImageSelector/ImageSelector";
import Checkbox from "../../atom/Checkbox/Checkbox";
import PageSelector from "../PageSelector/PageSelector";

import { getToken, imagePathForFormat } from "../../../../lib/lib";
import { getPage, imagePath, getArticle, getProduct } from "../../../../data/api";


class BlockTeaserItemEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      fileOverlayActive: false,
      preview: true,
      page: {}
    }
  }

  togglePreviewEditMode(e) {
    e.preventDefault()
    this.setState({
      preview: !this.state.preview
    })
  }

  openImageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      fileOverlayActive: true
    })
  }

  closeFileSelectorOverlay = () => {
    this.setState({
      fileOverlayActive: false
    })
  }

  handleFileSelect = (selectedFile, format) => {
    const item = this.props.item
    item.imageID   = selectedFile.id
    item.imagePath = selectedFile[imagePathForFormat(format)]
    item.imageName = selectedFile.filename
    item.imageFormat  = format

    const imgPath = item.imagePath

    this.setState({
      imgPath
    }, () => {
        this.props.onChange(item)
        this.closeFileSelectorOverlay()
    })
  }

  handleImageDelete = () => {
    const item = this.props.item
    item.imageID   = 0
    item.imagePath = ""
    item.imageName = ""
    this.setState({ imgPath: "" })
    this.props.onChange(item)
  }

  openPageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      pageOverlayActive: true
    })
  }

  closePageSelectorOverlay = () => {
    this.setState({
      pageOverlayActive: false,
    })
  }

  handlePageSelect = (page) => {
    const item = this.props.item

    if (page.id ) {
      item.pageID = page.id
      item.pageTitle = page.title
      item.pageType  = page.content_type
      item.buttonURL = ""
    } else {
      item.pageID = ""
      item.buttonURL = page.buttonURL
    }

    item.buttonTarget = page.buttonTarget

    this.props.onChange(item)
    this.closePageSelectorOverlay()
    this.setState({ page: page })
  }

  onChange = (event) => {
    const item = this.props.item
    item[event.target.name] = event.target.value
    this.props.onChange(item)
  }

  hasButtonChange = () => {
    const item = this.props.item
    item.hasButton = !this.props.item.hasButton
    this.props.onChange(item)
  }

  renderSelectedPageLabel = () => {
    if (this.props.item.buttonURL) {
      return this.props.item.buttonURL
    } else if (this.props.item.pageID && this.props.item.pageID !== 0 && this.state.page && this.state.page.title) {
      return `${this.state.page.title} (${this.state.page.navigation_title})`
    } else if (this.props.item.articleID && this.state.page && this.state.page.title) {
      return `${this.state.page.title}`
    } else {
      return ""
    }
  }

  renderSelectedPageDeleteButton = () => {
    return (this.props.item.buttonURL || this.props.item.pageID || this.props.item.articleID)
  }

  handlePageDelete = () => {
    const item = this.props.item
    item.buttonURL = ""
    item.pageID = ""
    this.setState({ page: {} })
    this.props.onChange(item)
  }

  async componentDidMount() {
    try {

      if (this.props.item.pageID) {

        try {

          let page = {}

          if (this.props.item.pageType === 'page') {
            const pageData = await getPage(getToken(), this.props.item.pageID)
            page = pageData.page
          } else if (this.props.item.pageType === 'article') {
            const pageData = await getArticle(getToken(), this.props.item.pageID)
            page = pageData.article
          } else if (this.props.item.pageType === 'product') {
            const pageData = await getProduct(getToken(), this.props.item.pageID)
            page = pageData.product
          }

          this.setState({ page: page })

        } catch (error) {
          console.log("Error: BlockTextImageEdit - pageID konnte nicht geladen werden: ", this.props.item.pageID)
        }
      }


      // FIXME DEPRECATED
      if (this.props.item.articleID) {
        const page = await getArticle(getToken(), this.props.item.articleID)
        this.setState({ page: page.article })
      }

      if (this.props.item.imageID) {
        const imgPath = await imagePath(getToken(), this.props.item.imageID, this.props.item.imageFormat)
        this.setState({ imgPath })
      }

    } catch (error) {
      console.log(error)
    }
  }


  render() {

    return (
      <>
        {this.state.preview ?
          <div className="dd-mini-block__preview">
            <div className="dd-mini-block__preview-item" onClick={(e) => this.togglePreviewEditMode(e)}>
              <div className="dd-mini-block__preview-image"></div>
              <div className="dd-mini-block__preview-title">{this.props.item.title}&nbsp;</div>
            </div>
          </div>

          :

          <div className="dd-mini-block__edit">
            <div className="dd-mini-block__close">
              <button className="dd-btn dd-btn--icon-only" onClick={(e) => this.togglePreviewEditMode(e)}>
                <Icon icon="close" />
              </button>
            </div>
            <div className="dd-block__edit-section">
              <div className="form-group">
                <Textfield
                  name="title"
                  type="text"
                  label=""
                  placeholder="Titel des Teasers"
                  handleChange={this.onChange}
                  defaultValue={this.props.item.title}
                />
              </div>

              <div className="form-group" >
                <textarea
                  className="form-control"
                  name="text"
                  onChange={this.onChange}
                  defaultValue={this.props.item.text}
                  placeholder="Bitte tragen Sie hier Ihren Text ein."
                />
              </div>
              <div className="form-group">

                <div className="dd-link-selector">
                  <div className="dd-link-selector__link">
                    {this.renderSelectedPageDeleteButton() &&
                      <button className={"dd-btn dd-btn--danger dd-btn--icon-only dd-link-selector__delete"}
                        onClick={() => this.handlePageDelete()}>
                        <Icon icon="trash" />
                      </button>
                    }
                    {this.renderSelectedPageLabel()}
                  </div>
                  <button className="dd-btn dd-btn--primary dd-link-selector__button" onClick={(e) => this.openPageSelectorOverlay(e)}>
                    {this.renderSelectedPageDeleteButton() ?
                      <span>Linkziel ändern</span>
                      :
                      <span>Link hinzufügen</span>
                    }
                    </button>
                </div>
                {this.state.pageOverlayActive &&
                  <Overlay closeOverlayCallback={() => this.closePageSelectorOverlay()}>
                    <PageSelector
                      content={this.props.item}
                      selectPageCallback={(page) => this.handlePageSelect(page)}
                    />
                  </Overlay>
                }
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <Checkbox
                      label="Mit Button"
                      id="teaser_button"
                      selected={this.props.item.hasButton}
                      onChange={(e) => this.hasButtonChange(e)}
                    />
                  </div>
                  {this.props.item.hasButton &&
                  <div className="col">
                    <Textfield
                      name="buttonTitle"
                      type="text"
                      isRequired={false}
                      label="Beschriftung"
                      placeholder="Mehr erfahren"
                      handleChange={this.onChange}
                      defaultValue={this.props.item.buttonTitle}
                    />
                  </div>
                  }
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <div className="dd-image-picker">
                      {this.state.imgPath ?
                        <ImagePreviewBlock
                          imagePath={this.state.imgPath}
                          imageId={this.props.item.imageID}
                          handleImageDelete={this.handleImageDelete}
                          openImageSelectorOverlay={this.openImageSelectorOverlay}
                        />
                        :
                        <button className="dd-btn dd-btn--primary dd-image-picker__choose" onClick={(e) => this.openImageSelectorOverlay(e)}>
                          Bild auswählen
                        </button>
                      }
                    </div>
                     {this.state.fileOverlayActive &&
                        <Overlay closeOverlayCallback={() => this.closeFileSelectorOverlay()}>
                          <ImageSelector
                            selectImageCallback={(file, format) => this.handleFileSelect(file, format)}
                            selectedAssetId={this.props.item.imageID || ''}
                            selectedFormat={this.props.item.imageFormat || ''}
                            fileTypes={["image"]}
                          />
                        </Overlay>
                      }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

export default BlockTeaserItemEdit
