import React from "react";
import Icon from "../../../Shared/atom/Icon/Icon";
import { AppContext } from "../../../../data/ContextProvider";

class LangSelector extends React.Component {
  state = {
    active: false
  }

  onActivate = () => {
    this.setState({ active: !this.state.active })
  }

  renderLi = (key, shorthand, item, translations) => {
    if (shorthand === item.lang) {
      return <li key={key} className="selected">{shorthand}</li>
    } else if (translations.includes(shorthand)) {
      return <li key={key} onClick={() => this.props.onSelect(shorthand)}>{shorthand}</li>
    } else {
      return <li key={key} className="inactive"> {shorthand}
              <button type="submit" className="dd-btn dd-btn--primary dd-btn--icon-only" onClick={() => this.props.onTranslate(shorthand)}>
                  <Icon icon="plus" />
              </button>
            </li>
    }
  }

  render() {
    const item = this.props.item
    const translations = Object.keys(this.props.item.translations)

    return (
      <ul className={`languages-switcher language-switcher--table-select ${this.state.active ? 'is-active' : ''}`} onClick={this.onActivate}>
        {this.context.syslanguages.map((language, index) => {
          return this.renderLi(item.id + "-" + index, language.shorthand, item, translations)
          })
        }
      </ul>
    )
  }
}

LangSelector.contextType = AppContext
export default LangSelector