import React from "react";

class BlockPreviewNumberedList extends React.Component {
  render() {
    let content = this.props.block || []
    const li = []

    if (!Array.isArray(content)) {
      content = [content]
    }

    content.forEach(item => {
      item.children.forEach(child => {
        let content = ''

        child.children.map(item => {
          let text = item.text

          if (item.type && item.type === 'link') {
            text = '<a href="' + item.url + '">' + item.children[0].text + '</a>'
          }

          if (item.bold) {
            text = '<b>' + text + '</b>'
          }

          if (item.underlined) {
            text = '<u>' + text + '</u>'
          }

          if (item.italic) {
            text = '<i>' + text + '</i>'
          }

          content += text
          return ''
        })

        li.push(content)
      })
    })

    const RawHTML = ({children, className = ""}) => <div className={className} dangerouslySetInnerHTML={{ __html: children}} />

    return (
      <div className="dd-block-preview dd-block-preview--default">
        <ol>
          {
            li.map((item, index) => {
              return <li key={index} ><RawHTML>{item}</RawHTML></li>
            })
          }
        </ol>
      </div>
    )
  }
}

export default BlockPreviewNumberedList