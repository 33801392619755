import React from 'react'
import { Link } from "react-router-dom";

import Icon from "../Shared/atom/Icon/Icon";

import { AppContext } from '../../data/ContextProvider'
import { formatShortDate} from "../../lib/lib";

class MediaItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showActions: false,
    }
  }

  isLikeImage = (asset) => {
    if (asset.content_type === 'image' || asset.content_type === 'icon') {
      return true
    }
    return false
  }

  isAssetEditable = (asset) => {
    if (asset.released_for_diro) {
      if (asset.company_id !== this.context.company.id) {
        return false
      }
    }
    return true
  }

  toggleActions = () => {
    this.setState({
      showActions: !this.state.showActions
    })
  }

  deleteItem = () => {
    this.props.deleteItem(this.props.file)
    this.setState({
      showActions: false,
    })
  }

  renderPreview = () => {
    return (
      <>
        {(this.isLikeImage(this.props.file))
        ?
          <img src={this.props.file.file_path} alt="" width="260px" height="150px" />
        :
          <img className="dd-media__file-icon" alt="" src={process.env.PUBLIC_URL + '/icons/static/file-' + this.props.file.content_type + '.svg'} />
        }
      </>
    )
  }

  render() {

    const releasedForNetwork = (this.props.file.released_for_diro)? "dd-media__network_release" : ""

    return (
      <div className={`dd-media ${releasedForNetwork}`}>
        <div className="dd-media__box">
          <div onClick={() => this.toggleActions()}>
            <div className="dd-media__filetype">
              {this.props.file.content_type}
            </div>
            <div className="dd-media__preview">
              {this.renderPreview()}
            </div>
            <div className="dd-media__meta">
              <div className="dd-media__date">
                {formatShortDate(this.props.file.updated_at)}
              </div>
              <div className="dd-media__filesize">
                {this.props.file.filesize}
              </div>
            </div>
          </div>

          {(this.state.showActions &&
            <div className="dd-media__actions">

              <a href={this.props.file.file_path} className="dd-btn__wrapper" download target="blank">
                <button className="dd-btn dd-btn--inverted dd-btn--icon-only" >
                  <Icon icon="download" />
                </button>
                <span className="dd-btn__text">Download</span>
              </a>

            {this.isAssetEditable(this.props.file) &&
              <>
                <Link to={`/de/media/${this.props.file.id}`} className="dd-btn__wrapper">
                  <button className="dd-btn dd-btn--inverted dd-btn--icon-only" >
                    <Icon icon="edit" />
                  </button>
                  <span className="dd-btn__text">Bearbeiten</span>
                </Link>

                <div className="dd-btn__wrapper dd-btn--reset" onClick={() => this.deleteItem()}>
                  <button className="dd-btn dd-btn--danger dd-btn--icon-only">
                    <Icon icon="trash" />
                  </button>
                  <span className="dd-btn__text">Löschen</span>
                </div>
              </>
            }
            </div>

          )}
        </div>
        <div className="dd-media__filename">
          {this.props.file.filename}
        </div>

      </div>
    )
  }
}

MediaItem.contextType = AppContext
export default MediaItem
