import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";

import { AppContext } from '../data/ContextProvider'

import AdminTabs from "../components/Admin/AdminTabs";

import Taxonomies from "../components/Admin/Taxonomies";
import Redirects from "../components/Admin/Redirects";
import Tracking from "../components/Admin/Tracking";
import Chatbot from "../components/Admin/Chatbot";
import Design from "../components/Admin/Design";
import Misc from "../components/Admin/Misc";
import Languages from "../components/Admin/Languages";
import LanguageNew from "../components/Admin/LanguageNew";
import LanguageEdit from "../components/Admin/LanguageEdit";
import ExportsTable from "../components/Admin/Exports/ExportsTable";
import Exports from "../components/Admin/Exports/Exports";
import ExportsEdit from "../components/Admin/Exports/ExportsEdit";
import User from "../components/Admin/User";
import UserEdit from "../components/Admin/UserEdit";
import Access from "../components/Admin/Access";

class Admin extends Component {

  render() {
    const admin = (this.context.user.admin || this.context.user.diro_admin)


    const lang = this.props.match.params.lang
    const tabs = []

    if (this.context.user.admin || this.context.user.diro_admin) {
      tabs.push({ title: "Allgemeines", path: `/${lang}/admin/general` })
      tabs.push({ title: "Design", path: `/${lang}/admin/design` })
      tabs.push({ title: "Weiterleitungen", path: `/${lang}/admin/redirects` })
      tabs.push({ title: "Tracking & Externe Dienste", path: `/${lang}/admin/tracking` })

      tabs.push({ title: "Chatbot", path: `/${lang}/admin/chatbot` })
      tabs.push({ title: "Zugangsverwaltung", path: `/${lang}/admin/access` })
    }

    if (this.context.user.diro_admin) {
      tabs.push({ title: "Pflege der Datenfelder", path: `/${lang}/admin/taxonomies` })
      tabs.push({ title: "Sprachversionen", path: `/${lang}/admin/i18n` })
      // tabs.push({ title: "Exports Konfigurator", path: `/${lang}/admin/exports/new/export` })
      tabs.push({ title: "Exporte", path: `/${lang}/admin/exports` })
      tabs.push({ title: "Benutzerverwaltung", path: `/${lang}/admin/user` })
    }

    if (!admin) {
      return (
        <h4 className="text-center p-4">Dieser Bereich befindet sich im Aufbau</h4>
      )
    }

    return (
      <div className="tab-content" id="nav-tabContent">
        <Route exact path="/:lang/admin/general">
          <AdminTabs headline="Admin" tabs={tabs}/>
          <Misc />
        </Route>
        <Route exact path="/:lang/admin/design">
          <AdminTabs headline="Admin" tabs={tabs} />
          <Design />
        </Route>
        <Route exact path="/:lang/admin/redirects">
          <AdminTabs headline="Admin" tabs={tabs} />
          <Redirects />
        </Route>
        <Route exact path="/:lang/admin/tracking">
          <AdminTabs headline="Admin" tabs={tabs} />
          <Tracking />
        </Route>
        <Route exact path="/:lang/admin/chatbot">
          <AdminTabs headline="Admin" tabs={tabs} />
          <Chatbot />
        </Route>
        <Route exact path="/:lang/admin/user/:company_id?">
          <AdminTabs headline="Admin" tabs={tabs} />
          <User />
        </Route>
        <Route exact path="/:lang/admin/user/edit/:user_id">
          <AdminTabs headline="Admin" tabs={tabs} />
          <UserEdit />
        </Route>

        <Route exact path="/:lang/admin/i18n">
          <AdminTabs headline="Admin" tabs={tabs}/>
          <Languages />
        </Route>
        <Route exact path="/:lang/admin/i18n/new">
          <AdminTabs headline="Admin" tabs={tabs}/>
          <LanguageNew />
        </Route>
        <Route exact path="/:lang/admin/i18n/:lang_id/edit">
          <AdminTabs headline="Admin" tabs={tabs}/>
          <LanguageEdit />
        </Route>

        <Route exact path="/:lang/admin/taxonomies">
          <AdminTabs headline="Admin" tabs={tabs} />
          <Taxonomies />
        </Route>

        <Route exact path="/:lang/admin/exports">
          <AdminTabs headline="Admin" tabs={tabs} />
          <ExportsTable />
        </Route>

        <Route exact path="/:lang/admin/exports/new/export">
          <AdminTabs headline="Admin" tabs={tabs} />
          <Exports />
        </Route>

        <Route exact path="/:lang/admin/exports/:export_id/edit">
          <AdminTabs headline="Admin" tabs={tabs} />
          <ExportsEdit />
        </Route>

        <Route exact path="/:lang/admin/access">
          <AdminTabs headline="Admin" tabs={tabs} />
          <Access/>
        </Route>
      </div>
    )
  }
}

Admin.contextType = AppContext
export default withRouter(Admin);
