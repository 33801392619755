import React from "react";
import { withRouter } from "react-router-dom";

// import Searchbar from "../../element/Searchbar/Searchbar";

class Navigation extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      lang: "de",
      locationPath: "/",
      navigationDiro:
      [
        {
          path: "pages",
          label: "Seiten"
        },
        {
          path: "offices",
          label: "Kanzleien"
        },
        {
          path: "articles",
          label: "Fachartikel"
        },
        {
          path: "events",
          label: "Events"
        },
        {
          path: "jobs",
          label: "Stellen"
        },
        {
          path: "products",
          label: "Rechtsprodukte"
        },
        {
          path: "media",
          label: "Bilder & Dateien"
        },
        {
          path: "admin/general",
          label: "Admin"
        },
      ],
      navigationData:
        [
          {
            path: "pages",
            label: "Seiten"
          },
          {
            path: "articles",
            label: "Fachartikel"
          },
          {
            path: "events",
            label: "Events"
          },
          {
            path: "jobs",
            label: "Stellen"
          },
          {
            path: "products",
            label: "Rechtsprodukte"
          },
          {
            path: "media",
            label: "Bilder & Dateien"
          },
          {
            path: "office",
            label: "Kanzleidaten"
          },
          {
            path: "admin/general",
            label: "Admin"
          },
        ]
    }
  }

  renderNavItem = (item) => {
    const path = `/${this.state.lang}/${item.path}`
    if (this.state.locationPath.includes(path)) {
      return <a className="nav-link active" href={path}>{item.label}</a>
    }
    return <a className="nav-link" href={path}>{item.label}</a>
  }

  searchQuery(event) {
    console.log(event.target.value)
  }

  componentDidMount() {

    //FIXME
    if (this.props.location && this.props.location.pathname) {
      this.setState({
        ...this.state,
        locationPath: this.props.location.pathname,
      })
    }
  }

  render() {

    const navigationData = (this.props.isDiro === true)? this.state.navigationDiro : this.state.navigationData

    return (
      <ul className="nav nav-pills dd-navigation w-100">
        {navigationData.map((item, index) => {
          return <li className="nav-item" key={index}>
            {this.renderNavItem(item)}
          </li>
        })}
        {/*
        <li className="ml-auto">
          <form className="my-lg-0">
            <Searchbar
              placeholder="Suche"
              icon="search"
              onChangeCallback={(e) => this.searchQuery(e)}
            />
          </form>
        </li>
        */}
      </ul>
    )
  }
}

export default withRouter(Navigation)
