import React from "react";
import { withRouter } from 'react-router-dom';

import Loader from "../../Shared/atom/Loader/Loader";
import Editbar from "../../Shared/element/Editbar/Editbar";

import {
  getCompaniesList,
  getTerms,
} from "../../../data/api";

import { getToken } from "../../../lib/lib";
import { AppContext } from "../../../data/ContextProvider";
import RadioButton from "../../Shared/atom/RadioButton/RadioButton";
import TagList from "../../Shared/atom/TagList/TagList";
import { createExport } from "../../../data/admin-api";
import Textfield from "../../Shared/atom/Textfield/Textfield";
import Checkbox from "../../Shared/atom/Checkbox/Checkbox";
import { EXPORT_TABLEHEADERS } from "../../../data/config";

class Exports extends React.Component {
  constructor(props) {
    super(props);

    let tableHeaders = {};

    EXPORT_TABLEHEADERS.forEach((tableHeader) => {
      tableHeaders[tableHeader] = true;
    })

    this.state = {
      hasError: false,
      isUpdating: false,
      activeLanguage: "de",
      allSelected: true,
      export_conf: {
        lang: "de",
        title: "",
        user_id: 0,
        main_filter: 0,
        table_headers: tableHeaders,
        include_non_members: false,
        config: {
          lawfields: [],
          jobtitles: [],
          countries: [],
          languages: [],
          federalstates: [],
          cities: [],
          companies: [],
          signedcontracts: [],
          cooperationnationals: [],
          cooperationinternationals: [],
          advocacies: [],
        },
      },
      query: "",
      loading: true,
      items: [],
    };
  }

  errorHandler = (hasError=false) => {
    this.setState({ hasError })
  }

  onChange = (e) => {
    const export_conf = this.state.export_conf;
    export_conf[e.target.name] = e.target.value;
    this.setState({ export_conf });
  };

  selectAll = (e) => {
    let table_headers = this.state.export_conf.table_headers;
    if (this.state.allSelected) {
      Object.keys(table_headers).forEach((key) => {
        table_headers[key] = false;
      })
      this.setState({ allSelected: false, table_headers });
    } else {
      Object.keys(table_headers).forEach((key) => {
        table_headers[key] = true;
      })
      this.setState({ allSelected: true, table_headers });
    }
  }

  changeValue = (fieldname, value) => {
    const export_conf = this.state.export_conf;
    export_conf[fieldname] = value;
    this.setState({ export_conf });
  };


  onSubmit = async () => {
    this.setState({ isUpdating: true });

    const export_conf = this.state.export_conf;
    const lang = this.state.activeLanguage;
    export_conf.lang = lang;

    createExport(getToken(), export_conf)
    .then(() => {
      this.props.history.push(`/${lang}/admin/exports`);
    })
    .catch(err => {
      console.log(err)
    })
  };

  onUpdateTableHeadersCheckbox = (value) => {
    const export_conf = this.state.export_conf;
    export_conf.table_headers[value] = !this.state.export_conf.table_headers[value]
    this.setState({ export_conf });
  }

  handleSelectedState = val => {
    return (val === 1 || val=== true )? true : false
  }

  onUpdateCheckbox = (name) => {
    const export_conf = this.state.export_conf;
    export_conf[name] = !export_conf[name];
    this.setState({ export_conf });
  }

  onUpdate = (name, value) => {
    const export_conf = this.state.export_conf;
    export_conf.config[name] = value;
    this.setState({ export_conf });
  };

  loadData = async () => {
    const lawfields = await getTerms(getToken(), "lawfields");
    const jobtitles = await getTerms(getToken(), "jobtitles");
    const countries = await getTerms(getToken(), "countries");
    const languages = await getTerms(getToken(), "languages");
    const federalstates = await getTerms(getToken(), "federalstates");
    const cities = await getTerms(getToken(), "cities");
    const companies = await getCompaniesList(getToken());
    const signedcontracts = await getTerms(getToken(), "signedcontracts");
    const cooperationnationals = await getTerms(
      getToken(),
      "cooperationnationals"
    );
    const cooperationinternationals = await getTerms(
      getToken(),
      "cooperationinternationals"
    );
    const advocacies = await getTerms(getToken(), "advocacies");
    const export_conf = this.state.export_conf;
    const user_id = this.context.user.uid;
    export_conf.user_id = user_id;

    this.setState({
      lawfields: lawfields.lawfields,
      jobtitles: jobtitles.jobtitles,
      countries: countries.countries,
      languages: languages.languages,
      federalstates: federalstates.federalstates,
      cities: cities.cities,
      companies: companies.companies,
      signedcontracts: signedcontracts.signedcontracts,
      cooperationnationals: cooperationnationals.cooperationnationals,
      cooperationinternationals:
        cooperationinternationals.cooperationinternationals,
      advocacies: advocacies.advocacies,
      export_conf,
      loading: false,
    });
  };

  async componentDidMount() {
    await this.loadData();
  }

  render() {
    const formValid = (this.state.export_conf.title) ? true : false
    const hasError = (this.state.hasError || !formValid) ? true : false
    const admin = this.context.user.diro_admin;

    if (this.state.loading || !admin) {
      return <Loader />;
    }

    const export_conf = this.state.export_conf;

    return (
      <>
        <div className="dd-card">
          <div className="d-flex">
            <h2 className="dd-card__title">Export-Konfigurator</h2>
          </div>
          <div className="card-body card-body--standard dd-background dd-background--no-padding-bottom">
            <div className="card-body__content ml-auto mr-auto">
              <div className="form-group dd-form-section">
                <div className="form-check mb-2">
                  <Textfield
                    name="title"
                    type="text"
                    isRequired={true}
                    label="Titel der Konfiguration"
                    handleChange={(e) => this.onChange(e)}
                  />
                </div>
              </div>
              <div className="form-group dd-form-section">
                <label className="textfield__label">
                  Nach welchem Hauptkriterium soll die Tabelle aufgebaut
                  werden?*
                </label>
                <br></br>
                <div className="form-check form-check-inline mb-2">
                  <RadioButton
                    name="main_filter"
                    label="Kanzlei"
                    value="0"
                    selected={parseInt(export_conf.main_filter) === 0}
                    handleChange={(e) => this.changeValue("main_filter", 0)}
                  />
                  {/* <RadioButton
                    name="main_filter"
                    label="Standort"
                    value="1"
                    selected={parseInt(export_conf.main_filter) === 1}
                    handleChange={(e) => this.changeValue("main_filter", 1)}
                  /> */}
                  <RadioButton
                    name="main_filter"
                    label="Berufsträger"
                    value="2"
                    selected={parseInt(export_conf.main_filter) === 2}
                    handleChange={(e) => this.changeValue("main_filter", 2)}
                  />
                </div>
                  <div className="form-check mt-3">
                    <Checkbox
                      label="Nicht-Diro-Mitglieder einbeziehen?"
                      id="include_non_members"
                      selected={this.handleSelectedState(this.state.export_conf.include_non_members)}
                      onChange={(e) => this.onUpdateCheckbox("include_non_members")}
                    />
                  </div>
              </div>
            </div>
            <div className="form-group dd-form-section">
              <label className="textfield__label mb-2">
                Tabelle filtern nach (werden keine Tags einer Kategorie
                ausgewählt, so werden automatisch alle Tags miteinbezogen):
              </label>
              <div className="form-check mb-2">
                <TagList
                  addedTagsVisible={true}
                  indexLabel="name"
                  name="Kanzleien"
                  data={export_conf.config.companies}
                  availableData={this.state.companies}
                  label="Kanzleien"
                  onUpdate={(assignedTags) =>
                    this.onUpdate("companies", assignedTags)
                  }
                />
                <TagList
                  addedTagsVisible={true}
                  indexLabel="title_de"
                  name="Städte"
                  data={export_conf.config.cities}
                  availableData={this.state.cities}
                  label="Städte"
                  onUpdate={(assignedTags) =>
                    this.onUpdate("cities", assignedTags)
                  }
                />
                <TagList
                  addedTagsVisible={true}
                  indexLabel="title_de"
                  name="Bundesländer"
                  data={export_conf.config.federalstates}
                  availableData={this.state.federalstates}
                  label="Bundesländer"
                  onUpdate={(assignedTags) =>
                    this.onUpdate("federalstates", assignedTags)
                  }
                />
                <TagList
                  addedTagsVisible={true}
                  indexLabel="title_de"
                  name="Länder"
                  data={export_conf.config.countries}
                  availableData={this.state.countries}
                  label="Länder"
                  onUpdate={(assignedTags) =>
                    this.onUpdate("countries", assignedTags)
                  }
                />
                <TagList
                  addedTagsVisible={true}
                  indexLabel="title_de"
                  name="Berufsbezeichnungen"
                  data={export_conf.config.jobtitles}
                  availableData={this.state.jobtitles}
                  label="Berufsbezeichnungen"
                  onUpdate={(assignedTags) =>
                    this.onUpdate("jobtitles", assignedTags)
                  }
                />
                <TagList
                  addedTagsVisible={true}
                  indexLabel="title_de"
                  name="Rechtsgebiete"
                  data={export_conf.config.lawfields}
                  availableData={this.state.lawfields}
                  label="Rechtsgebiete"
                  onUpdate={(assignedTags) =>
                    this.onUpdate("lawfields", assignedTags)
                  }
                />
                <TagList
                  addedTagsVisible={true}
                  indexLabel="title_de"
                  name="Fachanwaltschaften"
                  data={export_conf.config.advocacies}
                  availableData={this.state.advocacies}
                  label="Fachanwaltschaften"
                  onUpdate={(assignedTags) =>
                    this.onUpdate("advocacies", assignedTags)
                  }
                />
                <TagList
                  addedTagsVisible={true}
                  indexLabel="title_de"
                  name="Sprachen"
                  data={export_conf.config.languages}
                  availableData={this.state.languages}
                  label="Sprachen"
                  onUpdate={(assignedTags) =>
                    this.onUpdate("languages", assignedTags)
                  }
                />
                <TagList
                  addedTagsVisible={true}
                  indexLabel="title_de"
                  name="UnterzeichneteVerträge"
                  data={export_conf.config.signedcontracts}
                  availableData={this.state.signedcontracts}
                  label="Unterzeichnete Verträge"
                  onUpdate={(assignedTags) =>
                    this.onUpdate("signedcontracts", assignedTags)
                  }
                />
                <TagList
                  addedTagsVisible={true}
                  indexLabel="title_de"
                  name="NationaleKooperationen"
                  data={export_conf.config.cooperationnationals}
                  availableData={this.state.cooperationnationals}
                  label="Nationale Kooperationen"
                  onUpdate={(assignedTags) =>
                    this.onUpdate("cooperationnationals", assignedTags)
                  }
                />
                <TagList
                  addedTagsVisible={true}
                  indexLabel="title_de"
                  name="InternationaleKooperationen"
                  data={export_conf.config.cooperationinternationals}
                  availableData={this.state.cooperationinternationals}
                  label="Internationale Kooperationen"
                  onUpdate={(assignedTags) =>
                    this.onUpdate("cooperationinternationals", assignedTags)
                  }
                />
              </div>
            </div>
            <div className="form-group dd-form-section">
              <div className="form-check mb-2">
                <label className="textfield__label">
                  Konfiguration der Ausgabedatei
                </label>
                <div className="form-check mt-2 mb-2">
                  <button
                    className="dd-btn"
                    onClick={() => this.selectAll()}
                  >
                    {this.state.allSelected ? "Alle abwählen" : "Alle auswählen"}
                  </button>
                </div>
                <div className="d-flex">
                  <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6 pt-2">
                    {(this.state.export_conf.main_filter === 0 || this.state.export_conf.main_filter === 1) &&
                      <>
                        <div className="col pt-2">
                          <Checkbox
                            label="id Kanzlei"
                            id="id_company"
                            selected={this.state.export_conf.table_headers.id_company}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="id Standort"
                            id="id_site"
                            selected={this.state.export_conf.table_headers.id_site}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Standort"
                            id="city"
                            selected={this.state.export_conf.table_headers.city}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Kanzleiname"
                            id="company_name"
                            selected={this.state.export_conf.table_headers.company_name}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Zweigstelle ja/nein"
                            id="is_subsidiary"
                            selected={this.state.export_conf.table_headers.is_subsidiary}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Land"
                            id="country"
                            selected={this.state.export_conf.table_headers.country}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Bundesland"
                            id="federalstate"
                            selected={this.state.export_conf.table_headers.federalstate}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Straße"
                            id="street"
                            selected={this.state.export_conf.table_headers.street}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="PLZ"
                            id="zip"
                            selected={this.state.export_conf.table_headers.zip}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Telefon (Kanzlei)"
                            id="phone_office"
                            selected={this.state.export_conf.table_headers.phone_office}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Fax (Kanzlei)"
                            id="fax_office"
                            selected={this.state.export_conf.table_headers.fax_office}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="E-Mail (Kanzlei)"
                            id="email_office"
                            selected={this.state.export_conf.table_headers.email_office}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Website"
                            id="website"
                            selected={this.state.export_conf.table_headers.website}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Unterzeichnete Verträge"
                            id="signedcontracts"
                            selected={this.state.export_conf.table_headers.signedcontracts}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Nationale Kooperation"
                            id="cooperationnational"
                            selected={this.state.export_conf.table_headers.cooperationnational}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Internationale Kooperation"
                            id="cooperationinternational"
                            selected={this.state.export_conf.table_headers.cooperationinternational}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Rechtsgebiete"
                            id="lawfields"
                            selected={this.state.export_conf.table_headers.lawfields}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Fachanwaltschaften"
                            id="advocacies"
                            selected={this.state.export_conf.table_headers.advocacies}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Berufsbezeichnungen"
                            id="jobtitle"
                            selected={this.state.export_conf.table_headers.jobtitle}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Berufsträger Anzahl"
                            id="bt_count"
                            selected={this.state.export_conf.table_headers.bt_count}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Sprachen"
                            id="language"
                            selected={this.state.export_conf.table_headers.language}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                      </>
                    }
                    {this.state.export_conf.main_filter === 2 &&
                      <>
                        <div className="col pt-2">
                          <Checkbox
                            label="id Person"
                            id="id_person"
                            selected={this.state.export_conf.table_headers.id_person}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="id Kanzlei"
                            id="id_company"
                            selected={this.state.export_conf.table_headers.id_company}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Titel"
                            id="title"
                            selected={this.state.export_conf.table_headers.title}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Name, Vorname"
                            id="name"
                            selected={this.state.export_conf.table_headers.name}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Berufsbezeichnungen"
                            id="jobtitle"
                            selected={this.state.export_conf.table_headers.jobtitle}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Kanzleiname"
                            id="company_name"
                            selected={this.state.export_conf.table_headers.company_name}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Land"
                            id="country"
                            selected={this.state.export_conf.table_headers.country}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Bundesland"
                            id="federalstate"
                            selected={this.state.export_conf.table_headers.federalstate}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Straße"
                            id="street"
                            selected={this.state.export_conf.table_headers.street}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="PLZ"
                            id="zip"
                            selected={this.state.export_conf.table_headers.zip}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Stadt"
                            id="city"
                            selected={this.state.export_conf.table_headers.city}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Telefon (persönlich)"
                            id="phone_personal"
                            selected={this.state.export_conf.table_headers.phone_personal}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Mobil"
                            id="mobile"
                            selected={this.state.export_conf.table_headers.mobile}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Telefon (Kanzlei)"
                            id="phone_office"
                            selected={this.state.export_conf.table_headers.phone_office}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="E-Mail (persönlich)"
                            id="email_personal"
                            selected={this.state.export_conf.table_headers.email_personal}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="E-Mail (Kanzlei)"
                            id="email_office"
                            selected={this.state.export_conf.table_headers.email_office}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Website"
                            id="website"
                            selected={this.state.export_conf.table_headers.website}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Unterzeichnete Verträge"
                            id="signedcontracts"
                            selected={this.state.export_conf.table_headers.signedcontracts}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Nationale Kooperation"
                            id="cooperationnational"
                            selected={this.state.export_conf.table_headers.cooperationnational}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Internationale Kooperation"
                            id="cooperationinternational"
                            selected={this.state.export_conf.table_headers.cooperationinternational}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Fachanwaltschaften"
                            id="advocacies"
                            selected={this.state.export_conf.table_headers.advocacies}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                        <div className="col pt-2">
                          <Checkbox
                            label="Rechtsgebiete"
                            id="lawfields"
                            selected={this.state.export_conf.table_headers.lawfields}
                            onChange={(e) => this.onUpdateTableHeadersCheckbox(e.target.value)}
                          />
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Editbar
          hasPublish={false}
          hasPreview={false}
          historyBack={true}
          hasError={hasError}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
        />
      </>
    );
  }
}

Exports.contextType = AppContext;
export default withRouter(Exports);
