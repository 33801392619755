import React from "react";

const BlockPreviewArticlesOverview = (props) => {

  return <div className="dd-block-preview dd-block-preview--articles">
    <h2 className="pb-2">Kanzlei-Suche</h2>
    <div>…</div>
  </div>
}

export default BlockPreviewArticlesOverview;
