import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";

import BlockPreviewNoticeBar from './BlockPreviews/NoticeBar';
import BlockSectionSettings from './BlockSectionSettings';
import BlockActions from "./BlockActions";

class BlockNoticeBarEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: [],
      preview: true,
      showSettings: false
    }
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  onChange = (event) => {
    const value = event.target.value
    const content = this.state.content
    content[event.target.name] = value
    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content }, () => {
      this.props.onChange(this.props.blockKey, [content])
    })
  }

  componentDidMount() {
    if (this.props.initData) {
      const preview = this.props.preview
      const content = this.props.initData[0]

      this.setState({ preview, content })
    }
  }

  render() {
    return (
      <React.Fragment>
        {
          this.state.preview
          ?
          <div className="dd-block" onClick={() => this.togglePreviewEditMode()}>
            <div className="dd-block__preview">
              <BlockPreviewNoticeBar block={this.state.content} />
            </div>
          </div>
          :
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">

              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-paragraph" /> Hinweisleiste
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }

              <div className="dd-block__edit-section dd-block__edit-section--last">
                <div className="form-group">
                  <Textfield
                    name="title"
                    type="text"
                    additionalClassName=" textfield__input--bold"
                    placeholder="Tragen Sie hier die Frage ein, die Sie an die Nutzer richten wollen."
                    handleChange={this.onChange}
                    defaultValue={this.state.content.title}
                  />
                </div>
                <div className="form-group">
                  <Textfield
                    name="callToAction"
                    type="text"
                    placeholder="Tragen Sie hier die Antwort oder Aufforderung ein ..."
                    handleChange={this.onChange}
                    defaultValue={this.state.content.callToAction}
                  />
                </div>
                <div className="form-group">
                  <Textfield
                    name="contactDetails"
                    type="text"
                    placeholder="Hier die Kontaktmöglichkeit per Telefon – keine Klammern oder Trennzeichen"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.contactDetails}
                  />
                </div>
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}

export default BlockNoticeBarEdit;
