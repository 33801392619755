import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";
import SimpleSelectfield from "../../atom/Selectfield/SimpleSelectfield";

class BlockTextImageSlideEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      fileOverlayActive: false,
      preview: true,
      stars: [
        { id: '0', type: 'Bitte Bewertung wählen' },
        { id: '0.5', type: '0,5' },
        { id: '1', type: '1' },
        { id: '1.5', type: '1,5' },
        { id: '2', type: '2' },
        { id: '2.5', type: '2,5' },
        { id: '3', type: '3' },
        { id: '3.5', type: '3,5' },
        { id: '4', type: '4' },
        { id: '4.5', type: '4,5' },
        { id: '5', type: '5' }
      ],
    }
  }

  togglePreviewEditMode(e) {
    e.preventDefault()
    this.setState({
      preview: !this.state.preview
    })
  }

  onSelectLawfield = (value) => {
    const item = this.props.item
    item.quoteLawfield = value
    this.saveContent(item)
  }

  onSelectStar = (value) => {
    const item = this.props.item
    item.stars = value
    this.saveContent(item)
  }

  onChange = (event) => {
    const item = this.props.item
    item[event.target.name] = event.target.value
    this.saveContent(item)
  }

  saveContent = (item) => {
    let preview =  this.state.preview
    if (this.props.item.newRow) {
      preview = !preview
    }

    this.setState({
      savedOnce: true,
      preview
    }, () => {
      this.props.onChange(item)
    })
  }

  render() {
    let showPreview = this.state.preview
    if (this.props.item.newRow && !this.state.savedOnce) {
      showPreview = false
    }

    return (
      <>
        {showPreview ?
          <div className="dd-mini-block__preview">
            <div className="dd-mini-block__preview-item" onClick={(e) => this.togglePreviewEditMode(e)}>
              <div className="dd-mini-block__preview-image"></div>
              <div className="dd-mini-block__preview-title">{this.props.item.slideLabel}&nbsp;</div>
            </div>
          </div>

          :

          <div className="dd-mini-block__edit">
            <div className="dd-mini-block__close">
              <button className="dd-btn dd-btn--icon-only" onClick={(e) => this.togglePreviewEditMode(e)}>
                <Icon icon="close" />
              </button>
            </div>
            <div className="dd-block__edit-section">
              <div className="form-group" >
                <SimpleSelectfield
                  label={"Rechtsgebiete"}
                  name="quoteLawfield"
                  firstOptionValue={0}
                  firstOptionLabel="Bitte auswählen"
                  indexLabel="id"
                  optionLabel="title_de"
                  options={this.props.lawfields}
                  onSelect={this.onSelectLawfield}
                  selected={this.props.item.quoteLawfield}
                />
                <textarea
                  className="form-control"
                  name="quote"
                  onChange={(e) => this.onChange(e)}
                  defaultValue={this.props.item.quote}
                  placeholder="Bitte tragen Sie hier das Zitat ein."
                />
              </div>
              <div className="form-group" >
                <Textfield
                  className="form-control"
                  name="quoteAuthor"
                  handleChange={this.onChange}
                  defaultValue={this.props.item.quoteAuthor}
                  placeholder="Zitatgeber"
                />
                <Textfield
                  className="form-control"
                  name="quoteDate"
                  handleChange={this.onChange}
                  defaultValue={this.props.item.quoteDate}
                  placeholder="Datum des Zitats"
                />
                <Textfield
                  className="form-control"
                  name="quoteSource"
                  handleChange={this.onChange}
                  defaultValue={this.props.item.quoteSource}
                  placeholder="Zitatquelle"
                />
                <Textfield
                  className="form-control"
                  name="quoteURL"
                  handleChange={this.onChange}
                  defaultValue={this.props.item.quoteURL}
                  placeholder="Zitatquelle (URL)"
                />
                <SimpleSelectfield
                  label={false}
                  name="stars"
                  firstOptionValue=""
                  firstOptionLabel="Bitte auswählen"
                  indexLabel="id"
                  optionLabel="type"
                  options={this.state.stars}
                  onSelect={this.onSelectStar}
                  selected={this.props.item.stars}
                />
              </div>

            </div>
          </div>
        }
      </>
    )
  }
}

export default BlockTextImageSlideEdit
