import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { AppContext } from '../../data/ContextProvider'

import Tablist from '../Shared/module/Tablist/Tablist'

class ProductsEditTabs extends Component {
  render() {
    const lang = this.props.match.params.lang
    const productid = this.props.match.params.productid

    const tabs = [
      { title: "Produkttext", path: `/${lang}/products/${productid}/content` },
      { title: "Meta-Daten", path: `/${lang}/products/${productid}/meta` },
      { title: "Veröffentlichung", path: `/${lang}/products/${productid}/publishing` },
    ]

    if (this.context.user.diro_admin) {
      tabs.push({ title: "Netzwerk", path: `/${lang}/products/${productid}/network` })
    }

    return (
      <Tablist
        additionalClass="w-75 ml-auto mr-auto pt-4 d-flex justify-content-between"
        tabs={tabs}
        active={this.props.activeTab}
        tabChange={() => { }}
      />
    )
  }
}

ProductsEditTabs.contextType = AppContext
export default withRouter(ProductsEditTabs);
