import React from "react";
import { withRouter, Route, Switch } from "react-router-dom";

import EventsTable from "../components/Events/EventsTable";
import EventNew from '../components/Events/EventNew'
import EventContent from '../components/Events/EventContent'


const Events = () => {
  return (
    <div className="tab-content" id="nav-tabContent">
      <Switch>
        <Route exact path="/:lang/events">
          <EventsTable />
        </Route>
        <Route exact path="/:lang/events/new">
          <EventNew />
        </Route>
        <Route exact path="/:lang/events/:event_id/:tabs">
          <EventContent />
        </Route>
      </Switch>
    </div>
  )
}

export default withRouter(Events);
