import React from 'react'

import { createID } from "../../../../lib/lib";

class SimpleSelectfield extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  renderValue = (option) => {
    if (this.props.optionLabel) {
      return option[this.props.optionLabel]
    }
    if (this.props.optionLabelCallback) {
      return this.props.optionLabelCallback(option)
    }
  }

  renderLabel = (option) => {

  }

  renderOptions = () => {
    const options =[]

    if (this.props.firstOptionValue !== "" && this.props.firstOptionLabel) {
      options.push(<option value={this.props.firstOptionValue} key={createID()}>{this.props.firstOptionLabel}</option>)
    }

    this.props.options.forEach((option) => {
      options.push(<option value={option[this.props.indexLabel]} key={option[this.props.indexLabel]}>{this.renderValue(option)}</option>)
    })

    return options
  }

  onChange = (e) => {
    e.preventDefault()
    if (this.props.onSelect) {
      this.props.onSelect(e.target.value);
    }
  }

  render() {
    const disabled = this.props.disabled || false
    return (
      <div className="form-group select">
        <div className="selectfield__container">
          <div className="selectfield__wrapper">
            {(this.props.label !== false &&
              <label className="selectfield__label">
                {this.props.label}{this.props.isRequired ? "*" : ""}
              </label>
            )}
            <select
              className="selectfield__select custom-select"
              name={this.props.name}
              onChange={this.onChange}
              disabled={disabled}
              value={this.props.selected || this.props.firstOptionValue}
            >
              {this.renderOptions()}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

export default SimpleSelectfield
