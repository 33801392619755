import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";

import { createEditor } from "./util";

class BlockAccordionEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      preview: true,
    }
  }

  togglePreviewEditMode(e) {
    e.preventDefault()
    this.setState({
      preview: !this.state.preview
    })
  }

  onChange = (event) => {
    const item = this.props.item
    item[event.target.name] = event.target.value
    this.props.onChange(item)
  }

  onChangeEditor = (key, value) => {
    const content = this.state.content

    content.editor.content = value
    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.state.content)
  }

  componentDidMount() {
    const content = this.props.item

    if (!content.editor) {
      content.editor = {
        editor: "",
        content: [{
          type: "default",
          children: [
            { text: "Bitte tragen Sie hier einen Text ein" }
          ],
        }],
        type: "",
      }
    }

    let contentInput = []
    if (!Array.isArray(content.editor.content)) {
      contentInput = [content.editor.content]
    } else {
      contentInput = content.editor.content
    }

    content.editor.editor = createEditor(this.props.index, "default", this.onChangeEditor, contentInput)

    this.setState({
      content
    })
  }

  render() {
    return (
      <>
        {this.state.preview ?
          <div className="dd-mini-block__preview">
            <div className="dd-mini-block__preview-item" onClick={(e) => this.togglePreviewEditMode(e)}>
              <div className="dd-mini-block__preview-image"></div>
              <div className="dd-mini-block__preview-title">{this.props.item.title}&nbsp;</div>
            </div>
          </div>

          :

          <div className="dd-mini-block__edit">
            <div className="dd-mini-block__close">
              <button className="dd-btn dd-btn--icon-only" onClick={(e) => this.togglePreviewEditMode(e)}>
                <Icon icon="close" />
              </button>
            </div>
            <div className="dd-block__edit-section">
              <div className="form-group" >
                <Textfield
                  name="title"
                  type="text"
                  isRequired={true}
                  label=""
                  placeholder="Titel eintragen*"
                  handleChange={this.onChange}
                  defaultValue={this.props.item.title}
                  errorHandler={this.props.errorHandler}
                />
                <div className="form-group">
                  {this.state.content.editor && this.state.content.editor.editor}
                </div>
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

export default BlockAccordionEdit

