import React from 'react';
import { NavLink } from "react-router-dom";

function Subnavigation(props) {

  const tabs = props.tabs || []

  return (
    <ul className="dd-subnavigation">
        {tabs.map((tab, key) => {
          return <li key={key} className="dd-subnavigation__item">
            <NavLink
              to={tab.path}
              className="dd-subnavigation__link"
              >
                {tab.title}
            </NavLink>
          </li>
        })
        }
    </ul>
  );
}

export default Subnavigation;
