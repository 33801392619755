import React from "react";
import Icon from "../../../atom/Icon/Icon";

const BlockPreviewPodcast = ({block}) => {
	return <div className="dd-block-preview dd-block-preview--articles" style={{ paddingBottom: 0 }}>
		<h3 className="mb-1"><Icon icon="podcast" style={{ width: "25px", height: "25px", position: "relative", top: "3px", marginRight: "5px" }} /> Podcast</h3>
		<p>Feed-URL: <strong>{block.feedURL}</strong><br/>Anzahl Episoden: <strong>{block.episodeLimit}</strong></p>
	</div>
}

export default BlockPreviewPodcast;
