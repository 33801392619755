import React from "react";
import Icon from "../../atom/Icon/Icon";


class DnDRow extends React.Component {
  render() {
    return (
      <div className="draggable__row">
        <div className="draggable__row-content">
          {this.props.row}
        </div>
        <div className="draggable__delete">
          <button className="dd-btn dd-btn--danger dd-btn--icon-only"
            onClick={() => this.props.deleteRow()}>
            <Icon icon="trash" />
          </button>
        </div>
      </div>
    )
  }
}

export default DnDRow;
