const t9n = {

  // ===== DE =====

  "de": {
    "action.preview": "Vorschau",
    "action.save": "Speichern",
    "action.publish": "Veröffentlichen",
    "action.reset_publish": "Neu publizieren",

    // Global
    "action.close": "Schließen",
    "action.open": "Öffnen",
    "action.filter": "Eingrenzen",
    "action.search": "Suchen",
    "action.backToTop": "Zum Seitenanfang",
    "action.sendToAcceptance": "Zur Abnahme schicken",

    "read.more": "Mehr lesen",

    "view.more": "Mehr anzeigen",
    "view.all": "Alle anzeigen",

    // Label
    "label.description": "Beschreibung",
    "label.gallery": "Galerie",
    "label.events": "Events",

    // User
    "user.register": "Registrieren",
    "user.login": "Anmelden",

    // Date
    "date.months": [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez",
    ],

    "date.day" : "Tag",
    "date.month" : "Monat",
    "date.year" : "Jahr",

  },

  // ===== EN =====

  "en": {
    "action.preview": "Preview",
    "action.save": "Save",
    "action.publish": "Publish",
    "action.reset_publish": "Republish",

    // Global
    "action.close": "Close",
    "action.open": "Open",
    "action.filter": "Filter",
    "action.search": "Search",
    "action.sendToAcceptance": "Zur Abnahme schicken",
    "action.backToTop": "Back to top",

    "read.more": "Read more",

    "view.more": "View more",
    "view.all": "View all",

    // Label
    "label.description": "Description",
    "label.gallery": "Gallery",
    "label.events": "Events",

    // User
    "user.register": "Sign up",
    "user.login": "Log in",

    // Date
    "date.months": [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    "date.day" : "Day",
    "date.month" : "Month",
    "date.year" : "Year",

  },
}

function t(key) {
  const lang = window.currentLang;
  if (typeof t9n[lang] === 'undefined') return key;
  const s = t9n[lang][key];
  return (typeof s !== 'undefined')
    ? (s)
    : key
}

export default t;
