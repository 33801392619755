import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";

class BlockFactEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      preview: true,
    }
  }

  togglePreviewEditMode(e) {
    e.preventDefault()
    this.setState({
      preview: !this.state.preview
    })
  }

  onChange = (event) => {
    const fact = this.props.fact
    fact[event.target.name] = event.target.value
    this.props.onChange(fact)
  }

  render() {
    return (
      <>
        {this.state.preview ?
          <div className="dd-mini-block__preview">
            <div className="dd-mini-block__preview-item" onClick={(e) => this.togglePreviewEditMode(e)}>
              <div className="dd-mini-block__preview-image"></div>
              <div className="dd-mini-block__preview-title">{this.props.fact.title}&nbsp;</div>
            </div>
          </div>

          :

          <div className="dd-mini-block__edit">
            <div className="dd-mini-block__close">
              <button className="dd-btn dd-btn--icon-only" onClick={(e) => this.togglePreviewEditMode(e)}>
                <Icon icon="close" />
              </button>
            </div>
            <div className="dd-block__edit-section">
              <div className="form-group" >
                <Textfield
                  name="number"
                  type="text"
                  isRequired={false}
                  label=""
                  placeholder="Zahl"
                  handleChange={this.onChange}
                  defaultValue={this.props.fact.number}
                />
              </div>
              <div className="form-group" >
                <Textfield
                  name="title"
                  type="text"
                  isRequired={false}
                  label=""
                  placeholder="Eigenschaft"
                  handleChange={this.onChange}
                  defaultValue={this.props.fact.title}
                />
              </div>
              <div className="form-group" >
                <Textfield
                  name="description"
                  type="text"
                  isRequired={false}
                  label=""
                  placeholder="Kurzbeschreibung"
                  handleChange={this.onChange}
                  defaultValue={this.props.fact.description}
                />
              </div>

            </div>
          </div>
        }
      </>
    )
  }
}

export default BlockFactEdit
