import React from "react";

const TextfieldGroup = props => (
  <>
    {props.label &&
    <label className="textfield__label d-block">{props.label}</label>
    }
    <div className={`textfield-group ${props.isDob ? "textfield-group--dob" : "textfield-group--standard"}`}>
      {props.children}
    </div>
  </>
);

export default TextfieldGroup;
