import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import ProductsEditTabs from "./ProductsEditTabs";
import ProductMetadata from "./ProductMetadata";
import ProductPublishing from "./ProductPublishing";
import ProductNetwork from "./ProductNetwork";

import Textfield from "../Shared/atom/Textfield/Textfield";
import Blocks from "../Shared/module/Blocks/Blocks";
import Loader from "../Shared/atom/Loader/Loader";
import Editbar from "../Shared/element/Editbar/Editbar";

import { SidebarLang } from "../Shared/element/LangTools/LangHelper";

import { AppContext } from "../../data/ContextProvider";
import { getProduct, updateProduct, getCompany } from "../../data/api";
import { getToken, getCurrentOpenPageTab } from "../../lib/lib";


class ProductContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      productID: 0,
      loading: true,
      product: {},
      authors: {},
      blockContent: []
    }
  }

  errorHandler = (hasError = false) => {
    this.setState({ hasError })
  }

  onChange = (name, value) => {
    const product = this.state.product
    product[name] = value
    this.setState({ product })
  }

  onItemChange = (product) => {
    this.setState({ product })
  }

  onBlocksChange = (blockContent) => {
    const product = this.state.product
    product.content = blockContent
    this.setState({ product, blockContent })
  }

  onShowImportedContentChange = (e) => {
    const product = this.state.product
    product.show_imported_content = !product.show_imported_content

    this.setState({ product }, () => {
    })
  }

  publishOnNetwork = (e) => {
    const product = this.state.product
    product.published_on_network = !product.published_on_network
    this.setState({ product })
  }

  releaseOnNetwork = (e) => {
    const product = this.state.product
    product.released_on_network = !product.released_on_network
    this.setState({ product })
  }

  onSubmit = () => {
    const product = this.state.product
    product.lawfield_ids = product.lawfields.map(tag => tag.id)
    product.federalstate_ids = product.federalstates.map(tag => tag.id)
    product.country_ids = product.countries.map(tag => tag.id)
    product.keyword_ids = product.keywords.map(tag => tag.id)

    updateProduct(getToken(), product.id, product)
      .then(updatedProduct => {
        this.loadData()
      })
      .catch(err => {
        console.log(err)
      })
  }

  loadData = async () => {
    // try {
      const company = await getCompany(getToken(), this.context.company.id)
      const product = await getProduct(getToken(), this.state.productID)
      const blocksJSONData = product.product.content || "[]"

      let productPath = product.product.path

      if (!product.product.published) {
        productPath += "?p=" + product.product.preview_token
      }

      const preview = 'http://' + company.company.domain + '/' + product.product.lang + '/' + productPath

      this.setState({
        ...this.state,
        product: product.product,
        authors: company.company.employees,
        blockContent: JSON.parse(blocksJSONData),
        previewURL: preview,
        loading: false
      })
    // } catch (error) {
    //   //FIXME NotFound
    // }
  }

  async componentDidMount() {
    if (this.props.match && this.props.match.params) {
      this.setState({
        ...this.state,
        productID: this.props.match.params.productid
      }, async () => {
        await this.loadData()
      })
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    const currentOpenTab = getCurrentOpenPageTab(this.props.match.params.tabs)
    const formValid = (this.state.product.title) ? true : false
    const hasError = (this.state.hasError || !formValid) ? true : false

    return (
      <div className="tab-pane fade show active">
        <div className="card-body card-body--standard dd-background dd-background--no-padding-bottom dd-background--white">
          <SidebarLang
            item={this.state.product}
            controller="products"
            itemType="product"
          />
          <div className="card-body__content w-75 ml-auto mr-auto">
            <div className="form-group">
              <Textfield
                name="kicker"
                type="text"
                isRequired={false}
                label="Dachzeile"
                handleChange={(e) => this.onChange('kicker', e.target.value)}
                defaultValue={this.state.product.kicker}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="title"
                type="text"
                isRequired={true}
                label="Produkttitel"
                handleChange={(e) => this.onChange('title', e.target.value)}
                defaultValue={this.state.product.title}
                errorHandler={this.errorHandler}
              />
            </div>
            <div className="form-group">
              <label className="textfield__label">Einführungstext</label>
              <textarea
                className="form-control"
                name="teaser"
                defaultValue={this.state.product.teaser}
                onChange={(e) => this.onChange('teaser', e.target.value)}
              />
            </div>
          </div>

          <ProductsEditTabs
            activeTab={currentOpenTab}
          />

        </div>
        <div className="card-body card-body--standard">
          <div className="card-body__content w-75 ml-auto mr-auto">
            {currentOpenTab === 0 &&
              <div className="form-group">
                <Blocks
                  handleChange={this.onBlocksChange}
                  blocks={this.state.blockContent}
                  errorHandler={this.errorHandler}
                />
              </div>
            }
            {currentOpenTab === 1 &&
              <ProductMetadata
                product={this.state.product}
                onChange={this.onChange}
                onItemChange={this.onItemChange}
                errorHandler={this.errorHandler}
              />
            }
            {currentOpenTab === 3 &&
              <ProductPublishing
                product={this.state.product}
                onChange={this.onChange}
                publishOnNetwork={this.publishOnNetwork}
                releaseOnNetwork={this.releaseOnNetwork}
              />
            }
            {currentOpenTab === 4 &&
              <ProductNetwork
                product={this.state.product}
              />
            }
          </div>
        </div>
        <Editbar
          previewURL={this.state.previewURL}
          hasPublish={false}
          hasPreview={true}
          hasError={hasError}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
        />
      </div>
    );
  }
}

ProductContent.contextType = AppContext
export default withRouter(ProductContent);
