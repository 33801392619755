import React from 'react';
import Overlay from "../../element/Overlay/Overlay";

function DeleteConfirmOverlay(props) {
  return (
    <Overlay closeOverlayCallback={() => props.closeOverlay()}>
      <div className="dd-dialog dd-dialog--danger">

        <h3 className="dd-dialog__title">
          {props.mode === "hide" && "Wollen Sie den Eintrag wirklich ausblenden?" }
          {props.mode === "show" && "Wollen Sie den Eintrag wirklich einblenden?" }
          {props.mode === "delete" && "Wollen Sie den Eintrag wirklich löschen?"  }
          {props.mode === "total_delete" && "Wollen Sie den Eintrag wirklich löschen?"  }
        </h3>

        <p className="dd-dialog__text">
          {(props.mode === "delete") &&
            "Der Eintrag wird dann endgültig enfernt. Er ist nicht wiederherstellbar und muss bei Bedarf wieder neu angelegt werden. Wenn Sie den Eintrag nur ausblenden möchten, verwenden Sie bitte die Funktion „Ausblenden“."
          }
          {(props.mode === "total_delete") &&
            "Der Eintrag wird dann endgültig enfernt. Er ist nicht wiederherstellbar und muss bei Bedarf wieder neu angelegt werden."
          }
        </p>

        <div className="dd-dialog__actions">
          <button className="dd-btn dd-btn--outline" onClick={() => props.onConfirmation()}>
            {props.mode === "hide" && "Ausblenden"}
            {props.mode === "show" && "Einblenden"}
            {props.mode === "delete" && "Löschen"}
            {props.mode === "total_delete" && "Löschen"}
          </button>
          <button className="dd-btn dd-btn--primary" onClick={() => props.closeOverlay()}>
            Abbrechen
          </button>
        </div>
      </div>
    </Overlay>
  );
}

export default DeleteConfirmOverlay;
