import React from "react";

const BlockPreviewBanner = (props) => {

  const content = props.block[0] // FIXME

  let styles = {}

  if (content.background === 'primary') {
    styles = {
      backgroundColor: '#c25218',
      borderColor: '#c25218',
      color: '#fff',
    }
  }

  if (content.background === 'secondary') {
    styles = {
      backgroundColor: '#111C39',
      borderColor: '#111C39',
      color: '#fff',
    }
  }

  return <div className="dd-block-preview dd-block-preview--banner" style={styles}>
    <h4>{content.title}</h4>
    <div>
      <span className="dd-btn dd-btn--primary">
        {content.buttonTitle}
      </span>
    </div>
  </div>
}

export default BlockPreviewBanner;
