import React from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {arrayMoveImmutable} from 'array-move';

import Loader from "../Shared/atom/Loader/Loader";
import Editbar from '../Shared/element/Editbar/Editbar'
import Searchbar from "../Shared/element/Searchbar/Searchbar";
import Pagination from "../Shared/element/Pagination/Pagination";
import FooterBuilderItem from './FooterBuilderItem';

import { AppContext } from "../../data/ContextProvider";

import { getFooterPagesNotInMenu, updateFooterNavigation, getFooterNavigation, deleteFooterNavigation } from "../../data/api";
import { createID } from "../../lib/lib";

class FooterBuilder extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeLanguage: "de",
      query: '',
      loading: true,
      hasError: false,
      errorMessage: "",
      pageWithError: 0,
      visibleMenuDepth: 3,
      limit: 10,
      count: 0,
      pagesCount: 0,
      currentPage: 0,
      orderField: 'updated_at',
      orderDirection: 'desc',
      page: {},
      pages: [],
      menu: []
    }
  }

  handleMenuDepthChange = (event) => {
    this.setState({
      visibleMenuDepth: event.target.value
    })
  }

  setActiveLanguage = (shorthand) => {
    this.setState({
      activeLanguage: shorthand
    }, async () => {
      await this.loadData()
    })
  }


  handlePaginationClick = async (e, page) => {
    this.setState({
      ...this.state,
      currentPage: page
    }, async () => {
        await this.loadJustPages()
    })
  }

  handleSelectLimit = (limit) => {
    this.setState({ limit, currentPage: 0 }, async () => {
      await this.loadJustPages()
    })
  }

  onFilterChange = (event) => {
    this.setState({
      query: event.target.value,
      currentPage: 0
    }, () => {
        this.loadJustPages()
    })
  }

  onChange = (event) => {
    const page = this.state.page
    page.title = event.target.value

    this.setState({ page: page, hasError: false }, () => {

    })
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const menu = [...this.state.menu]
    const reorderedMenu = arrayMoveImmutable(menu, result.source.index, result.destination.index)

    reorderedMenu.map((menuItem, index) => menuItem.weight = index)

    this.setState({
      ...this.state,
      menu: reorderedMenu
    }, () => {

      updateFooterNavigation(this.state.menu, this.context.company.id)
        .then(newPage => {
          this.loadData()
        })
        .catch(err => {
          this.setState({ isUpdating: false })
        })
    })
  }

  addToMenu = (page) => {

    const footer_page = { page_id: 0, weight: 0, key: createID(), new: true}

    const pages = [...this.state.pages]
    const menu  = [...this.state.menu]

    const index = this.state.pages.findIndex(s => s.id === page.id)

    if (index >= 0) {
      pages[index] = page
    }

    footer_page.page_id = page.id
    footer_page.title   = page.title
    footer_page.navigation_title = page.navigation_title

    menu.unshift(footer_page)

    this.setState({ menu, pages })
  }

  onMenuDelete = async (page) => {
    await deleteFooterNavigation(page.id)
    this.loadData()
  }

  onSubmit = () => {

    updateFooterNavigation(this.state.menu, this.context.company.id)
    .then(newPage => {
      this.loadData()
    })
    .catch(err => {
      this.setState({
        isUpdating: false,
        hasError: true,
        errorMessage: err.data.message,
        pageWithError: err.data.page_with_error })
    })
  }

  loadJustPages = async () => {
    try{
      const offset = this.state.currentPage * this.state.limit
      const pages = await getFooterPagesNotInMenu(this.state.activeLanguage, this.state.limit, offset, this.state.query)
      const pagesCount = Math.ceil(pages.count / this.state.limit)

      this.setState({
        ...this.state,
        pagesCount: pagesCount,
        count: pages.count,
        pages: pages.pages,
      }, () => {

      })
    } catch(error) {
      //FIXME NotFound
      console.log(error)
    }
  }

  loadData = async () => {
    try {
      const offset = this.state.currentPage * this.state.limit
      const pages = await getFooterPagesNotInMenu(this.state.activeLanguage, this.state.limit, offset, this.state.query)
      const menu = await getFooterNavigation()

      const pagesCount = Math.ceil(pages.count / this.state.limit)

      this.setState({
        ...this.state,
        pagesCount: pagesCount,
        count: pages.count,
        pages: pages.pages,
        menu:  menu.menu,
        loading: false,
      }, () => {
      })
    } catch (error) {
      //FIXME NotFound
      console.log(error)
    }
  }

  async componentDidMount() {
    await this.loadData()
  }

  // TMP: So lange page.title noch nicht vorhanden ist
  renderPageType = (page) => {
    // FIXME: Page Type
    return page.type || 'Website'
  }

  renderTitle = (page) => {
    if (page.title !== page.navigation_title) {
      return <>{page.title} <span className="dd-page-select__navigation-title">({page.navigation_title})</span></>
    } else {
      return page.title
    }
  }

  render() {

    if (this.state.loading) {
      return <Loader />
    }

    const menuIDs  = this.state.menu.map((page)  => page.page_id) || []
    const filteredPages = this.state.pages.filter((page) => !menuIDs.includes(page.id)) || []


    return (

      <div className="row">
        <div className="col-sm">

          <div className="dd-navbuilder mb-4 ml-3">
            <div className="dd-navbuilder__header">
            </div>

            <div className="dd-navbuilder__menu" data-visiblemenudepth={this.state.visibleMenuDepth}>

              {(this.state.menu.length > 0) &&
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        >
                        { this.state.menu.map((menuItem, index) => (
                              <Draggable key={menuItem.key} draggableId={menuItem.key} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div key={index}>
                                  <FooterBuilderItem
                                        menuID={menuItem.id}
                                        menuItem={menuItem}
                                        menuIndex={index}
                                        pageWithError={this.state.pageWithError}
                                        onDelete={() => this.onMenuDelete(menuItem)}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              }
            </div>
          </div>
        </div>

        <div className="col-sm">
          <div className="mb-4">

            <form className="pt-2 mb-4">
              <div className="form-row justify-content-end">
                <Searchbar
                  placeholder="Verfügbare Seiten filtern …"
                  icon="filter"
                  onChangeCallback={(e) => this.onFilterChange(e)}
                />
              </div>
            </form>

            <ul className="dd-navbuilder__pagelist">
              { filteredPages.map((page, index) => {
                // FIXME: eigene Komponente?
                return <li key={index} className="dd-page-select">
                  <button className="dd-page-select__button" onClick={()=>this.addToMenu(page)}>
                    <div className="dd-page-select__type">{this.renderPageType(page)}</div>
                    <div className="dd-page-select__title">{this.renderTitle(page)}</div>
                  </button>
                </li>
              })}
            </ul>
            <Pagination
              total={this.state.pagesCount}
              current={this.state.currentPage}
              onChange={this.handlePaginationClick}
              onSelect={this.handleSelectLimit}
              limit={this.state.limit}
            />

          </div>
        </div>

        <Editbar
          hasPublish={false}
          hasPreview={false}
          hasError={this.state.hasError}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
          errorMessage={this.state.errorMessage}
        />
      </div>
    )
  }
}

FooterBuilder.contextType = AppContext
export default FooterBuilder;
