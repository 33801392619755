import React from "react";

import { throttle } from "throttle-debounce";

import Loader from "../Shared/atom/Loader/Loader";
import Icon from "../Shared/atom/Icon/Icon";
import Textfield from "../Shared/atom/Textfield/Textfield";
import RadioButton from "../Shared/atom/RadioButton/RadioButton";
import Checkbox from "../Shared/atom/Checkbox/Checkbox";
import SimpleSelectfield from "../Shared/atom/Selectfield/SimpleSelectfield";
import Searchbar from "../Shared/element/Searchbar/Searchbar";
import Pagination from "../Shared/element/Pagination/Pagination";
import Editbar from "../Shared/element/Editbar/Editbar";
import DeleteConfirmOverlay from "../Shared/module/Overlay/DeleteConfirmOverlay";

import { getSyslanguages, getTerms, updateTerms, createItem, deleteTerm } from "../../data/api";
import { getToken } from "../../lib/lib";
import { AppContext } from "../../data/ContextProvider";

const defaultTitleFields = { title_de: '', title_en: '', title_fr: '', title_it: '', title_es: '', title_zh: '', title_pl: '' }

class Taxonomies extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      loading: true,
      addNewItem: false,
      newItems: [{ id: 0, ...defaultTitleFields }],
      count: 0,
      pages: 0,
      currentPage: 0,
      limit: 10,
      orderField: 'title_de',
      orderDirection: 'asc',
      deleteConfirmOverlay: false,
      confirmMode: "total_delete",
      syslanguages: [],
      items: [],
      showIncomplete: false,
      selectedType: 'keywords',
      types: [
        //{ id: '0', type: 'Bitte wählen' },
        { id: 'academictitles', type: 'Akademische Titel' },
        { id: 'keywords', type: 'Stichwörter (tags)' },
        { id: 'advocacies', type: 'Fachanwaltschaften' },
        { id: 'chambers', type: 'Kammern' },
        { id: 'companyforms', type: 'Unternehmensformen' },
        { id: 'cooperationinternationals', type: 'Internationale Kooperationen' },
        { id: 'cooperationnationals', type: 'Nationale Kooperationen' },
        { id: 'countries', type: 'Länder' },
        { id: 'courts', type: 'Landgerichts-Bezirke' },
        { id: 'federalstates', type: 'Bundesländer' },
        { id: 'joblevels', type: 'Einstiegslevel' },
        { id: 'jobtimecontigents', type: 'Zeitkontigente' },
        { id: 'jobtitles', type: 'Berufsbezeichnungen' },
        { id: 'jobtypes', type: 'Anstellungsverhältnisse' },
        { id: 'languages', type: 'Sprachen' },
        { id: 'lawfields', type: 'Rechtsgebiete' },
        { id: 'nationalities', type: 'Nationalitäten' },
        { id: 'signedcontracts', type: 'Unterzeichnete Verträge' },
        { id: 'cities', type: 'Städte' },
      ],
    }

    this.searchThrottled = throttle(500, this.loadData);
  }

  closeOverlay = () => {
    this.setState({
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false,
    })
  }

  showDeleteConfirmation = (item) => {
    this.setState({
      deleteConfirmOverlay: true,
      itemToDelete: item.id,
      confirmMode: "total_delete"
    })
  }

  onFilterChange = (event) => {
    this.setState({
      query: event.target.value,
      currentPage: 0
    }, () => {
      this.searchThrottled()
    })
  }

  onOrderChange = (e) => {
    if (e.target.checked) {
      const orderField = e.target.value || 'title_de'
      this.setState({
        orderField
      }, async () => {
        await this.loadData()
      })
    }
  }

  onOrderDirection = (e) => {
    if (e.target.checked) {
      const orderDirection = e.target.value || 'asc'
        this.setState({ orderDirection
      }, async () => {
        await this.loadData()
      })
    }
  }

  onIncompleteChange = (e) => {
    this.setState({
      showIncomplete: !this.state.showIncomplete
    }, async () => {
      await this.loadData()
    })
  }

  addNewItem = () => {
    const newItems = this.state.newItems
    if (this.state.addNewItem) {
      let id = newItems[0].id
      id++
      newItems.unshift({ id: id, ...defaultTitleFields });
    }
    this.setState({ addNewItem: true, newItems })
  }

  changeNewValue = (e, item, fieldname) => {
    const index = this.state.newItems.findIndex(s => s.id === item.id)
    if (index >= 0) {
      const newItems = [...this.state.newItems]
      newItems[index][fieldname] = e.target.value
      this.setState({ newItems }, () => {
      })
    }
  }

  changeValue = (e, item, fieldname) => {
    const index = this.state.items.findIndex(s => s.id === item.id)
    if (index >= 0) {
      const items = [...this.state.items]
      items[index][fieldname] = e.target.value
      this.setState({ items }, () => {
      })
    }
  }

  onSelectType = (value) => {
    this.setState({ selectedType: value }, async () => {
      await this.loadData()
    })
  }

  handlePaginationClick = async (e, page) => {
    this.setState({
      currentPage: page
    }, async () => {
      await this.loadData()
    })
  }

  handleSelectLimit = (limit) => {
    this.setState({ limit, currentPage: 0 }, async () => {
      await this.loadData()
    })
  }

  onSubmit = async () => {
    this.setState({ isUpdating: true })

    const selectedType = (this.state.selectedType === '0') ? 'keywords' : this.state.selectedType

    if (this.state.addNewItem) {
      this.state.newItems.map(async newItem => {
        await createItem(getToken(), newItem, selectedType)
      })
    }

    updateTerms(getToken(), this.state.items, selectedType)
      .then(async syslanguage => {
        await this.loadData()
      })
      .catch(err => {
        this.setState({ isUpdating: false })
      })

    await this.loadData()
  }

  onDeleteConfirmation = async () => {
    const selectedType = (this.state.selectedType === '0') ? 'keywords' : this.state.selectedType
    const itemToDelete = this.state.itemToDelete

    if (itemToDelete) {
      this.setState({
        isUpdating: true,
      }, () => {
        deleteTerm(getToken(), itemToDelete, selectedType)
          .then(async updatedArticle => {
            this.closeOverlay()
            await this.loadData()
          })
          .catch(err => {
            this.setState({ isUpdating: false })
          })
      })
    }
  }

  loadData = async () => {
    const offset = this.state.currentPage * this.state.limit
    const syslanguages = await getSyslanguages(getToken())

    const selectedType = (this.state.selectedType === '0') ? 'keywords' : this.state.selectedType

    const items = await getTerms(
      getToken(),
      selectedType,
      this.state.limit,
      offset,
      this.state.orderField,
      this.state.orderDirection,
      this.state.query,
      this.state.showIncomplete,
    )

    this.setState({
      ...this.state,
      pagesCount: Math.ceil(items.count / this.state.limit),
      count: items.count,
      items: items[selectedType],
      syslanguages: syslanguages.syslanguages.sort((a, b) => {
        const prioLanguages = ['fr', 'en', 'de'];
        return prioLanguages.indexOf(b.shorthand) - prioLanguages.indexOf(a.shorthand);
      }),
      loading: false,
      newItems: [{ id: 0, ...defaultTitleFields }],
      addNewItem: false
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  render() {
    if (this.state.loading || !this.context.user.diro_admin ) {
      return <Loader />
    }

    const types = this.state.types.sort((a, b) => a.type.localeCompare(b.type))

    return (
      <>
        <div className="dd-card">
          <div className="d-flex">
            <h2 className="dd-card__title">Welche Datenfelder wollen Sie bearbeiten?</h2>
            <div className="pt-2">
              <SimpleSelectfield
                label={false}
                name="type"
                firstOptionValue=""
                firstOptionLabel="Bitte auswählen"
                indexLabel="id"
                optionLabel="type"
                options={types}
                onSelect={this.onSelectType}
                selected={this.state.selectedType}
              />
            </div>
          </div>

          <div className="dd-form-section dd-form-section--transparent d-flex">
            <div className="form-check form-check-inline">
              <RadioButton
                name="orderField"
                label="Alphabetisch"
                value="title_de"
                selected={this.state.orderField === "title_de"}
                handleChange={(e) => this.onOrderChange(e)} />
              <RadioButton
                name="orderField"
                label="Nach Bearbeitung"
                value="updated_at"
                selected={this.state.orderField === "updated_at"}
                handleChange={(e) => this.onOrderChange(e)} />
            </div>

            <div className="form-check form-check-inline">
              <RadioButton
                name="orderDirection"
                label="absteigend"
                value="desc"
                selected={this.state.orderDirection === "desc"}
                handleChange={(e) => this.onOrderDirection(e)} />
              <RadioButton
                name="orderDirection"
                label="aufsteigend"
                value="asc"
                selected={this.state.orderDirection === "asc"}
                handleChange={(e) => this.onOrderDirection(e)} />
            </div>

            <div className="form-check form-check-inline">
              <Checkbox
                label="Nur unvollständige zeigen"
                id="incomplete"
                selected={this.state.showIncomplete}
                onChange={(e) => this.onIncompleteChange(e)}
              />
            </div>

            <div className="dd-search-bar dd-search-bar--small">
              <Searchbar
                placeholder=""
                icon="filter"
                onChangeCallback={(e) => this.onFilterChange(e)}
              />
            </div>
          </div>

          <div className="dd-form-section dd-form-section--first">
            <div className="d-flex justify-content-center">
              <span
                className="dd-btn__wrapper"
                onClick={this.addNewItem}
              >
                <button className="dd-btn dd-btn--primary dd-btn--icon-only" >
                  <Icon icon="plus" />
                </button>
                <span className="dd-btn__text">Neuer Eintrag</span>
              </span>
            </div>
          </div>


          <div className="dd-form-section dd-form-section--small dd-form-section--last">
            <table className="table dd-table dd-table--transparent">
              <thead>
                <tr>
                  {this.state.syslanguages.map((language, key) => {
                    return <th key={key} scope="col">{language.title}</th>
                  })}
                  <th scope="col" className="dd-table--actions-th"></th>
                </tr>
              </thead>
              <tbody>

              {(this.state.addNewItem) &&
                <React.Fragment>
                  {this.state.newItems.map((newItem, key) => {
                    return <tr key={newItem.id}>

                      {this.state.syslanguages.map(({ shorthand }) => {
                        return (
                            <td key={ `${ newItem.id }-${ shorthand }` }>
                              <Textfield
                                  name={ `title_${ shorthand }` }
                                  type="text"
                                  handleChange={ (e) => this.changeNewValue(e, newItem, `title_${ shorthand }`) }
                                  defaultValue={ newItem[`title_${ shorthand }`] }
                              />
                            </td>
                        )
                      })}

                      <td className="justify-content-center dd-table--actions">
                      </td>
                    </tr>
                  })}
                </React.Fragment>
              }

                {this.state.items.map((item, key) => {
                  return <tr key={key}>

                    {this.state.syslanguages.map(({ shorthand }) => {
                      return (
                        <td key={ `${ item.id }-${shorthand}` }>
                          <Textfield
                              name={ `title_${ shorthand }` }
                              type="text"
                              handleChange={ (e) => this.changeValue(e, item, `title_${shorthand}`) }
                              defaultValue={ item[`title_${shorthand}`] }
                          />
                        </td>
                      )
                    })}

                    <td className="justify-content-center dd-table--actions">
                      <div className="dd-table--actions_wrapper">
                        <button type="submit" className="dd-btn dd-btn--danger dd-btn--icon-only" onClick={() => this.showDeleteConfirmation(item)}>
                          <Icon icon="trash" />
                        </button>
                      </div>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </div>

        {this.state.deleteConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.onDeleteConfirmation}
            mode={this.state.confirmMode}
          />
        }

        <Pagination
          total={this.state.pagesCount}
          current={this.state.currentPage}
          onChange={this.handlePaginationClick}
          onSelect={this.handleSelectLimit}
          limit={this.state.limit}
        />

        <Editbar
          hasPublish={false}
          hasPreview={false}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
        />
      </>
    )
  }
}

Taxonomies.contextType = AppContext
export default Taxonomies;
