import React, { Component } from "react";

import Dropzone from 'react-dropzone';
import Icon from '../../atom/Icon/Icon'

class DragAndDrop extends Component {

  onDrop = (files) => {
    this.props.onDrop(files)
  }
//accept="image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  render() {
    return (
      <>
        <Dropzone onDrop={this.onDrop} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <section className={this.props.type ? "dd-upload dd-upload--" + this.props.type : "dd-upload dd-upload--large"}>
              <div {...getRootProps({ className: 'dropzone' })}>
                <h2 className="dd-upload__title">Upload hier …</h2>
                <input {...getInputProps()} />
                <span className="dd-upload__icon">
                  <Icon icon="upload" />
                </span>
                <p className="dd-upload__info">Ziehen Sie das Bild oder die Datei einfach auf dieses Feld oder klicken Sie auf den Pfeil, um die Datei auszuwählen.</p>
              </div>
            </section>
          )}
        </Dropzone>
      </>
    );
  }
}

export default DragAndDrop;
