import React, { useState } from 'react'
import Icon from '../../atom/Icon/Icon'

export default function BlockActions({
  closeBlockCallback,
  deleteBlockCallback,
  blockContent,
  blockKey
}) {
  const [showCheckmark, setShowCheckmark] = useState(false)

  const copyBlock = async function () {
    try {
      sessionStorage.setItem('DdClipboardBlock', JSON.stringify(blockContent))
      await navigator.clipboard.writeText(JSON.stringify(blockContent))
      setShowCheckmark(true)
      setTimeout(() => {
        setShowCheckmark(false)
        closeBlockCallback()
      }, 2000)
    } catch (error) {
      console.error('Failed to copy: ', error)
    }
  }

  return (
    <>
      <span className="dd-block__edit-move">
        <Icon icon="sorter" />
      </span>
      <button
        className="dd-btn dd-btn--icon-only dd-block__edit-close"
        onClick={() => closeBlockCallback()}
        title="Block schließen"
      >
        <Icon icon="plus" />
      </button>
      <button
        className="dd-btn dd-btn--icon-only dd-block__edit-copy"
        onClick={() => copyBlock()}
        title="Block kopieren"
      >
        <Icon icon={showCheckmark ? 'checkmark' : 'duplicate'} />
      </button>
      <button
        className="dd-btn dd-btn--danger dd-btn--icon-only dd-block__edit-delete"
        onClick={() => deleteBlockCallback(blockKey)}
        title="Block löschen"
      >
        <Icon icon="trash" />
      </button>
    </>
  )
}
