import React from "react";

import Icon from "../../atom/Icon/Icon";

class Overlay extends React.Component {

  render() {
    return (
      <div className="dd-overlay" onClick={(e) => this.props.closeOverlayCallback(e)}>
        <div className="dd-overlay__wrapper" onClick={(e) => e.stopPropagation()}>
          <button className="dd-btn dd-btn--icon-only dd-btn--transparent dd-overlay__close" onClick={(e) => this.props.closeOverlayCallback(e)}>
            <Icon icon="close" />
          </button>
          {(this.props.title &&
            <div className="dd-overlay__title">{this.props.title}</div>
            )}
          <div className="dd-overlay__content">
            {this.props.children}
          </div>
        </div>
      </div>
      );
    }
  }

export default Overlay;