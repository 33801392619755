import React, { Component } from "react";
import { withRouter, Route } from "react-router-dom";

import MediaEdit from "../components/Media/MediaEdit";
import MediaList from "../components/Media/MediaList";

class Media extends Component {
  render() {
    return (
      <>
        <Route exact path="/:lang/media" render={(props) => <MediaList />} />
        <Route path="/:lang/media/:asset_id" component={MediaEdit} />
      </>
    );
  }
}

export default withRouter(Media);
