import React from "react";
import { Link } from "react-router-dom";

import { getSyslanguages} from "../../data/api";
import { getToken } from "../../lib/lib";

import { AppContext } from "../../data/ContextProvider";
import Icon from "../Shared/atom/Icon/Icon";

class Languages extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      syslanguages: []
    }
  }

  loadData = async () => {
    const syslanguages = await getSyslanguages(getToken())

    this.setState({
      ...this.state,
      syslanguages: syslanguages.syslanguages,
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  render() {
    return (
      <>
        <table className="table dd-table">
          <thead>
            <tr>
              <th scope="col">Sprache</th>
              <th scope="col" className="dd-table--actions-th"></th>
            </tr>
          </thead>
          <tbody>
            {this.state.syslanguages.map((syslanguage, key) => {
              return <tr key={key}>
                <td className={(this.state.orderField === 'name') ? 'is-active' : ''}>{syslanguage.title}</td>
                <td className="justify-content-center dd-table--actions">
                  <div className="dd-table--actions_wrapper">
                    <Link to={`/de/admin/i18n/${syslanguage.id}/edit`}>
                      <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only">
                        <Icon icon="edit" />
                      </button>
                    </Link>
                  </div>
                </td>
              </tr>
            })}
          </tbody>
        </table>
        <Link to={`/de/admin/i18n/new`} className="dd-btn__wrapper">
          <button className="dd-btn dd-btn--primary dd-btn--icon-only" >
            <Icon icon="plus" />
          </button>
          <span className="dd-btn__text">Neuen Sprache anlegen</span>
        </Link>
      </>
    )
  }
}

Languages.contextType = AppContext
export default Languages;
