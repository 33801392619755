import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";
import SimpleSelectfield from "../../atom/Selectfield/SimpleSelectfield";

class BlockProcessStep extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      item: {},
      preview: true,
      pageOverlayActive: false,
    }
  }

  togglePreviewEditMode = async (e) => {
    e.preventDefault()

    const newState = !this.state.preview
    if (!newState) { //open
      if (this.props.item.pageID) {
        await this.loadPage(this.props.item.pageID)
      }
      if (this.props.item.articleID) {
        await this.loadArticle(this.props.item.articleID)
      }
    }

    this.setState({ preview: newState})
  }

  onChange = (event) => {
    const item = this.props.item
    item[event.target.name] = event.target.value
    this.props.onChange(item)
  }

  onSelectIcon = (value) => {
    const item = this.props.item
    item.icon  = value
    this.props.onChange(item)
  }

  render() {
    return (
      <>
        {this.state.preview ?
          <div className="dd-mini-block__preview">
            <div className="dd-mini-block__preview-item" onClick={(e) => this.togglePreviewEditMode(e)}>
              <div className="dd-mini-block__preview-image"></div>
              <div className="dd-mini-block__preview-title">{this.props.item.title}&nbsp;</div>
            </div>
          </div>

          :

          <div className="dd-mini-block__edit">
            <div className="dd-mini-block__close">
              <button className="dd-btn dd-btn--icon-only" onClick={(e) => this.togglePreviewEditMode(e)}>
                <Icon icon="close" />
              </button>
            </div>
            <div className="dd-block__edit-section">
              <div className="form-group" >

              {this.props.viewmode === 'icons' &&
                <SimpleSelectfield
                    label="Icon"
                    name="icon"
                    firstOptionValue=""
                    firstOptionLabel="Bitte auswählen"
                    indexLabel="icon"
                    optionLabel="title_de"
                    options={this.props.advisoryfields}
                    onSelect={(value) => this.onSelectIcon(value)}
                    selected={this.props.item.icon}
                  />
              }

                <Textfield
                  name="title"
                  type="text"
                  isRequired={false}
                  label="Titel"
                  placeholder="Titel eintragen"
                  handleChange={this.onChange}
                  defaultValue={this.props.item.title}
                />

                <Textfield
                  name="description"
                  type="text"
                  isRequired={false}
                  label="Text"
                  placeholder="Kurzbeschreibung eintragen"
                  handleChange={this.onChange}
                  defaultValue={this.props.item.description}
                />
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

export default BlockProcessStep
