import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Textfield from "../Shared/atom/Textfield/Textfield";
import { MainHelperText } from "../Shared/element/HelperText/HelperText";
import { AppContext } from "../../data/ContextProvider";
import { createArticle } from "../../data/api";
import { getToken } from "../../lib/lib";

class ArticleNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      article: {}
    }
  }

  errorHandler = (hasError=false) => {
    this.setState({ hasError })
  }

  onChange = (event) => {
    const article = { ...this.state.article };
    article[event.target.name] = event.target.value;
    this.setState({ article })
  }

  onSubmit = () => {
    createArticle(getToken(), this.state.article)
    .then(article => {
      const lang = this.props.match.params.lang
      this.props.history.push(`/${lang}/articles/${article.article.id}/content`);
    })
    .catch(err => {
      console.log(err)
    })
  }

  render() {
    const formValid = (this.state.article.title) ? true : false
    const disabled = (this.state.hasError || !formValid) ? true : false

    return (
      <div className="tab-pane fade show active">
        <div className="card-body card-body--standard dd-background dd-background--sand">
          <div className="card-body__content w-75 ml-auto mr-auto">
            <div className="form-group">
              <Textfield
                name="kicker"
                type="text"
                isRequired={false}
                label="Dachzeile"
                handleChange={this.onChange}
                defaultValue={this.state.article.kicker}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="title"
                type="text"
                isRequired={true}
                label="Titel des Beitrags"
                handleChange={this.onChange}
                defaultValue={this.state.article.title}
                errorHandler={this.errorHandler}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="author"
                type="text"
                isRequired={true}
                label="Autor"
                defaultValue={this.context.user.full_name}
                errorHandler={this.errorHandler}
              />
            </div>
            <div className="form-group">
              <label className="textfield__label">Einführungstext</label>
              <textarea
                className="form-control"
                name="teaser"
                defaultValue={this.state.article.teaser}
                onChange={(e) => this.onChange(e)}
              />
            </div>
          </div>
        </div>
        <MainHelperText
          disabled={disabled}
          onSubmit={this.onSubmit}
        />
      </div>
    )
  }
}

ArticleNew.contextType = AppContext
export default withRouter(ArticleNew)
