import React from "react";

const BlockPreviewBanner = (props) => {

  // const content = props.block

  return <div className="dd-block-preview dd-block-preview--html">
    HTML-CODE-BLOCK &lt;/&gt;
  </div>
}

export default BlockPreviewBanner;
