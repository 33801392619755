import React from "react";

import TagList from "../Shared/atom/TagList/TagList";
import Textfield from "../Shared/atom/Textfield/Textfield";
import Icon from '../Shared/atom/Icon/Icon'
import SimpleSelectfield from "../Shared/atom/Selectfield/SimpleSelectfield";
import RadioButton from "../Shared/atom/RadioButton/RadioButton";


import { AppContext } from '../../data/ContextProvider'


class SiteCompanyData extends React.Component {

  render() {

    const is_subsidiary = this.props.site.is_subsidiary
    const is_diro_member = "is_diro_member__" + this.props.site.id
    const is_diro_business_member = "is_diro_business_member__" + this.props.site.id
    const diro_status = "diro_status__" + this.props.site.id

    return (
      <React.Fragment>
        {!is_subsidiary &&
          <>
            <div className="dd-form-section">
              <div className="dd-form-section__title">
                Unternehmensdaten
              </div>
              <div className="dd-form-section__content">
                <div className="row">
                  <div className="col-md-4 mb-2">
                    <Textfield
                      name="company_registration_number"
                      type="text"
                      label="Handelsregister-Nr"
                      defaultValue={this.props.site.company_registration_number}
                      handleChange={this.props.onChange}
                    />

                    <SimpleSelectfield
                      label="Unternehmensform"
                      name="titel"
                      firstOptionValue="0"
                      firstOptionLabel="Bitte auswählen"
                      indexLabel="id"
                      optionLabel="title_de"
                      options={this.props.companyforms}
                      onSelect={this.props.onSelectCompanyForm}
                      selected={this.props.site.companyform_id}
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <Textfield
                      name="partnership_register_number"
                      type="text"
                      label="Partnerschaftsregister-Nr."
                      defaultValue={this.props.site.partnership_register_number}
                      handleChange={this.props.onChange}
                    />

                  <Textfield
                      name="tax_identification_number"
                      type="text"
                      label="Umsatzsteuer-ID"
                      defaultValue={this.props.site.tax_identification_number}
                      handleChange={this.props.onChange}
                    />

                  </div>
                  <div className="col-md-4 mb-2">
                    <SimpleSelectfield
                      label="Landgerichts-Bezirk"
                      name="titel"
                      firstOptionValue="0"
                      firstOptionLabel="Bitte auswählen"
                      indexLabel="id"
                      optionLabel="title_de"
                      options={this.props.courts}
                      onSelect={this.props.onSelectCourt}
                      selected={this.props.site.court_id}
                    />
                  </div>
                </div>
              </div>
            </div>

            {this.context.user.diro_admin &&
              <div className="dd-form-section dd-form-section--diro">
                <div className="dd-form-section__content">
                  <div className="row">
                    <div className="col-lg-5">
                      <SimpleSelectfield
                        label="DIRO-Ansprechpartner"
                        name="contact_id"
                        firstOptionValue="0"
                        firstOptionLabel="Bitte auswählen"
                        indexLabel="id"
                        optionLabel="full_name"
                        options={this.props.company.employees}
                        onSelect={(value) => this.props.changeValue(this.props.site.id, "contact_id", value)}
                        selected={this.props.site.contact_id}
                      />
                    </div>
                  <div className="col-md-6 col-lg-2 offset-lg-0">
                      <label className="radio-button__group-label mb-2">
                        Ist dieser Standort DIRO-Mitglied?
                      </label><br />
                      <div className="form-check-inline">
                        <RadioButton
                          name={is_diro_member}
                          label="Nein"
                          selected={!this.props.site.is_diro_member}
                          handleChange={(e) => this.props.changeValue(this.props.site.id, "is_diro_member", !e.target.checked)}
                        />
                        <RadioButton
                          name={is_diro_member}
                          label="Ja"
                          selected={this.props.site.is_diro_member}
                          handleChange={(e) => this.props.changeValue(this.props.site.id, "is_diro_member", e.target.checked)}
                        />
                      </div>
                    </div>

                    {(this.props.site.is_diro_member) &&
                      <div className="col-md-6 col-lg-2">
                        <label className="radio-button__group-label mb-2">
                      Status der Mitgliedschaft
                        </label><br />
                        <div className="form-check form-check-inline">
                          <RadioButton
                            name={diro_status}
                            label="Probezeit"
                            value="Probezeit"
                            selected={this.props.site.diro_status === "Probezeit"}
                            handleChange={(e) => this.props.changeValue(this.props.site.id, "diro_status", e.target.value)}
                          />
                          <RadioButton
                            name={diro_status}
                            label="Aktionär"
                            value="AG"
                            selected={this.props.site.diro_status === "AG"}
                            handleChange={(e) => this.props.changeValue(this.props.site.id, "diro_status", e.target.value)}
                          />
                        </div>
                      </div>
                    }

                    <div className="col-md-6 col-lg-2">
                      <label className="radio-button__group-label mb-2">
                        Ist der Standort DIRO-Business-Kanzlei?
                        </label><br />
                      <div className="form-check form-check-inline">
                        <RadioButton
                          name={is_diro_business_member}
                          label="Nein"
                          selected={!this.props.site.is_diro_business_member}
                          handleChange={(e) => this.props.changeValue(this.props.site.id, "is_diro_business_member", false)}
                        />
                        <RadioButton
                          name={is_diro_business_member}
                          label="Ja"
                          selected={this.props.site.is_diro_business_member}
                          handleChange={(e) => this.props.changeValue(this.props.site.id, "is_diro_business_member", true)}
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            }
          </>
        }

        <div className="dd-form-section dd-form-section--diro dd-form-section--small dd-form-section--combined">
          <label htmlFor="form_kooperationen" className="dd-form-section__title">Verträge und Kooperationen</label>
          <div className="dd-form-section__content">
            <div className="d-flex flex-column">
              <div className="row">
                <div className="col-md-4 d-flex justify-content-md-end align-items-center">
                  <label className="label--single" htmlFor="unterzeichnete">
                    Unterzeichnete Verträge
                  </label>
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  <TagList
                    indexLabel='title_de'
                    name="Unterzeichnete Verträge"
                    data={this.props.site.signedcontracts}
                    availableData={this.props.signedcontracts}
                    onUpdate={(assignedTags) => this.props.contractsUpdate(assignedTags)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dd-form-section dd-form-section--diro dd-form-section--small dd-form-section--combined">
          <div className="dd-form-section__content">
            <div className="d-flex flex-column">
              <div className="row">
                <div className="col-md-4 d-flex justify-content-md-end align-items-center">
                  <label className="label--single" htmlFor="nationale_kooperationen">
                    Nationale Kooperationen
                  </label>
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  <TagList
                    indexLabel='title_de'
                    name="Nationale Kooperationen"
                    data={this.props.site.cooperationnationals}
                    availableData={this.props.cooperationnationals}
                    onUpdate={(assignedTags) => this.props.cooperationnationalsUpdate(assignedTags)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dd-form-section dd-form-section--diro dd-form-section--combined dd-form-section--small dd-form-section--last">
          <div className="dd-form-section__content">
            <div className="d-flex flex-column">
              <div className="row">
                <div className="col-md-4 d-flex justify-content-md-end align-items-center">
                  <label className="label--single" htmlFor="international_kooperationen">
                    Internationale Kooperationen
                  </label>
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  <TagList
                    indexLabel='title_de'
                    name="Internationale Kooperationen"
                    data={this.props.site.cooperationinternationals}
                    availableData={this.props.cooperationinternationals}
                    onUpdate={(assignedTags) => this.props.cooperationinternationalsUpdate(assignedTags)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-4 px-2">
          <span className="dd-btn__wrapper d-inline-flex float-right mr-2">
            <button className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only" onClick={(e) => { this.props.showConfirmationOverlay(this.props.site, "visibilty")}}>
              <Icon icon={this.props.site.published ? 'hidden' : 'visibilty'} />
            </button>
            <span className="dd-btn__text">
              {this.props.site.published ? 'Ausblenden' : 'Einblenden' }
            </span>
          </span>
          {/*
          <span className="dd-btn__wrapper d-inline-flex float-right mr-2">
            <button className="dd-btn dd-btn--danger dd-btn--icon-only" onClick={(e) => this.props.showConfirmationOverlay(this.props.site, "deletion")}>
              <Icon icon="trash" />
            </button>
            <span className="dd-btn__text">Löschen</span>
          </span>
        */}
          {!this.props.site.published &&
            <span className="text-danger">Dieser Standort ist zurzeit ausgeblendet. Änderungen werden gespeichert, aber nicht veröffentlicht.</span>
          }

        </div>
      </React.Fragment>
    );
  }
}

SiteCompanyData.contextType = AppContext
export default SiteCompanyData;
