
const range = (start, stop, step = 1) =>
  Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)


const addZeroToNumber = (number) => {
  if (number.length === 1) {
    number = '0' + number
    return number
  }
  return number
}

const convertToValidFilename = (filename) => {
  const validFilename = filename.replace(/([^_a-zA-Z0-9])/g, '_').replace(/(_)\1+/g, '_')
  return validFilename
}


export { range, addZeroToNumber, convertToValidFilename }