import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { AppContext } from "../../data/ContextProvider";
import Textfield from "../Shared/atom/Textfield/Textfield";
import TagList from "../Shared/atom/TagList/TagList";
import TextfieldGroup from "../Shared/atom/TextfieldGroup/TextfieldGroup";
import Loader from "../Shared/atom/Loader/Loader";
import { ImagePreview } from "../Shared/element/ImagePreview/ImagePreview";

import { getTerms, createKeyword } from "../../data/api";
import { getToken } from "../../lib/lib";


class ArticleMetadata extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      article: {},
      lawfields: [],
      countries: [],
      federalstates: [],
      keywords: []
    }
  }

  addKeywordTag = (newTag) => {
    const keywords = this.state.keywords
    const articleKeywords = this.props.article.keywords
    createKeyword(getToken(), newTag)
    .then(newKeyword => {
      keywords.push(newKeyword.keyword)
      articleKeywords.push(newKeyword.keyword)
      this.setState({
        keywords
      }, this.props.onChange('keywords', articleKeywords)
      )
    })
    .catch(err => {
      this.setState({ isUpdating: false })
    })
  }

  loadData = async () => {
    try {
      const lawfields = await getTerms(getToken(), 'lawfields')
      const countries = await getTerms(getToken(), 'countries')
      const keywords  = await getTerms(getToken(), 'keywords')
      const federalstates = await getTerms(getToken(), 'federalstates')

      this.setState(
        {
          lawfields: lawfields.lawfields,
          countries: countries.countries,
          federalstates: federalstates.federalstates,
          keywords: keywords.keywords,
          loading: false
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  componentDidMount() {
    this.loadData()
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    const indexLabel = 'title_' + this.props.article.lang
    const canonical = this.props.article["canonical_" + this.props.article.lang]

    return (
      <React.Fragment>
        <div className="form-group">
          <TagList
            indexLabel={indexLabel}
            name="Stichworte"
            data={this.props.article.keywords}
            availableData={this.state.keywords}
            label="Stichworte, um die es in diesem Beitrag geht"
            onUpdate={(assignedTags) => this.props.onChange('keywords', assignedTags)}
            hasAddNew={true}
            onCreate={(newTag) => this.addKeywordTag(newTag)}
          />
        </div>
        <div className="form-group">
          <label className="textfield__label">Kurzbeschreibung des Beitrags (Meta Description)</label>
            <textarea
              className="form-control"
              name="description"
              defaultValue={this.props.article.description}
              onChange={(e) => this.props.onChange("description", e.target.value)}
              placeholder="Die Länge der Meta Description sollte 160 Zeichen nicht überschreiten. 130 bis 160 Zeichen sind optimal. Der Inhalt sollte zum Klick auf das Suchergebnis animieren."
            />
        </div>
        <div className="form-group">
          <TagList
            indexLabel={indexLabel}
            name="Rechtsgebiete"
            data={this.props.article.lawfields}
            availableData={this.state.lawfields}
            label="Rechtsgebiete, die im Beitrag behandelt werden:"
            onUpdate={(assignedTags) => this.props.onChange('lawfields', assignedTags)}
          />
        </div>
        <div className="form-group">
            <TagList
              indexLabel={indexLabel}
              name="Länder"
              data={this.props.article.countries}
              availableData={this.state.countries}
              label="Beitrag ist besonders relevant für Interessenten aus:"
              onUpdate={(assignedTags) => this.props.onChange('countries', assignedTags)}
            />
        </div>
        <div className="form-group">
          <TagList
            indexLabel={indexLabel}
            name="Bundesländer"
            data={this.props.article.federalstates}
            availableData={this.state.federalstates}
            label="Optional - Beitrag ist besonders relevant für Interessenten aus:"
            onUpdate={(assignedTags) => this.props.onChange('federalstates', assignedTags)}
          />
        </div>

        <div className="form-group">
          <Textfield
            name="default_path"
            type="text"
            isReadonly={true}
            label="Automatische Adresse für diesen Beitrag"
            defaultValue={`https://${this.context.company.domain}/${this.props.article.lang}/${this.props.article.default_path}`}
          />
        </div>

        <div className="form-group">
          <TextfieldGroup label={"Adresse, unter der dieser Beitrag stattdessen auffindbar sein soll"}>
            <Textfield
              name="domain"
              type="text"
              isReadonly={true}
              defaultValue={`https://${this.context.company.domain}/${this.props.article.lang}/`}
            />
            <Textfield
              name="overridden_path"
              type="text"
              handleChange={(e) => this.props.onChange('overridden_path', e.target.value ) }
              defaultValue={this.props.article.overridden_path}
            />
          </TextfieldGroup>
        </div>

        <div className="form-group">
          <Textfield
            name="default_path"
            type="text"
            isReadonly={true}
            label="Automatische Titelangabe dieses Beitrags in den Metadaten"
            defaultValue={this.props.article.title}
          />
        </div>

        <div className="form-group">
          <TextfieldGroup label="Titelangabe des Beitrages, die stattdessen in den Meta-Angaben verwendet werden soll">
            <Textfield
              name="meta_title"
              type="text"
              handleChange={(e) => this.props.onChange('meta_title', e.target.value ) }
              defaultValue={this.props.article.meta_title}
            />
          </TextfieldGroup>
        </div>

        <div className="form-group">
          <Textfield
            name="default_path"
            type="text"
            isReadonly={true}
            label="Canonical Adresse für diesen Beitrag"
            defaultValue={`${canonical}`}
          />
        </div>

        <div className="form-group">
          <Textfield
            name="external_canonical"
            type="text"
            label="Canonical Adresse, unter der dieser Beitrag stattdessen auffindbar sein soll"
            defaultValue={this.props.article.external_canonical}
            handleChange={(e) => this.props.onChange('external_canonical', e.target.value)}
          />
        </div>

        <div className="form-group">
          <ImagePreview
            article={this.props.article}
            onItemChange={this.props.onItemChange}
            hint={"Empfohlenes Seitenverhältnis: 3:2. Empfohlenes Dateiformat: jpg. Mindestens 800px Breite."}
          />
        </div>
      </React.Fragment>
    )
  }
}

ArticleMetadata.contextType = AppContext
export default withRouter(ArticleMetadata);
