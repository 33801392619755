import React from "react";
import { getToken } from "../../../lib/lib";
import Icon from "../../Shared/atom/Icon/Icon";
import Textfield from "../../Shared/atom/Textfield/Textfield";
import Switch from "react-switch";
import {
  getConsentTranslation,
  updateConsentTranslation,
  updateConsent,
  deleteThirdPartyService
} from "../../../data/api";

class Consent extends React.Component {

  constructor(props) {
    super(props);

    const consent = this.props.consent;
    const usesBlockUsage = !['head', 'diro', 'analytics'].includes(consent.third_party_service.identifier);
    const hasBlockUsage = consent.usage > 0;
    const showBlockUsage = usesBlockUsage && hasBlockUsage;
    const showNoBlockUsage = usesBlockUsage && !hasBlockUsage;
    const itemClassNames = ["dd-consent"];
    const active = consent.active || this.props.mandatory
    const disabled = this.props.mandatory
    const fixed = consent.third_party_service.identifier === 'diro'

    if (showBlockUsage) itemClassNames.push('dd-consent--with-block-usage');
    if (!active && showBlockUsage) itemClassNames.push('dd-consent--warning');

    const deletable = consent.third_party_service.custom && consent.usage === 0

    this.state = {
      itemClassNames,
      showBlockUsage,
      showNoBlockUsage,
      consentTranslation: {},
      active,
      disabled,
      deletable,
      validates: true,
      fixed
    }
  }

  async componentDidMount() {
    this.checkIdIdentifier()
    await this.loadData()
  }

  loadData = async () => {
    const translation = this.props.consent.consent_translations.filter(
      translation => translation.lang === this.props.activeLang
    )
    const translationId = [...translation][0].id.toString()
    const data = await getConsentTranslation(getToken(), translationId, this.props.activeLang)

    this.setState({
      consentTranslation: data.consent_translation,
    })
  }

  checkIdIdentifier = () => {
    const hash = window.location.hash.replace("#", '')
    const identifier = this.props.consent.third_party_service.identifier
    const element = document.getElementById(hash)

    if (hash === identifier) {
      setTimeout(() => {
        element.scrollIntoView({behavior: "smooth"})
      }, 100)

      let itemClassNames = this.state.itemClassNames
      if (itemClassNames.includes("dd-consent--expanded")) {
        itemClassNames = itemClassNames.filter(el => el !== "dd-consent--expanded")
      } else {
        itemClassNames.push("dd-consent--expanded")
      }
      this.setState({
        itemClassNames: itemClassNames
      })
    }
  }

  itemClassName() {
    return this.state.itemClassNames.join(' ')
  }

  handleDragMouseOver() {
    const itemClassNames = this.state.itemClassNames
    itemClassNames.push("dd-consent--drag-mode")
    this.setState({
      itemClassNames: itemClassNames
    })
  }

  handleDragMouseOut() {
    const itemClassNames = this.state.itemClassNames
    this.setState({
      itemClassNames: itemClassNames.filter(el => el !== "dd-consent--drag-mode")
    })
  }

  handleEditClick() {
    let itemClassNames = this.state.itemClassNames
    if (itemClassNames.includes("dd-consent--expanded")) {
      itemClassNames = itemClassNames.filter(el => el !== "dd-consent--expanded")
    } else {
      itemClassNames.push("dd-consent--expanded")
    }
    this.setState({
      itemClassNames: itemClassNames
    })
  }

  handleDeleteClick = async (id) => {
    await deleteThirdPartyService(getToken(), id).catch(err => { console.log(err) });
    this.props.refreshCallback();
  }

  handleActivation(checked) {
    let itemClassNames = this.state.itemClassNames
    if (!checked && this.state.showBlockUsage) {
      itemClassNames.push("dd-consent--warning")
    } else {
      itemClassNames = itemClassNames.filter(el => el !== "dd-consent--warning")
    }
    this.setState({
      itemClassNames: itemClassNames,
      active: checked
    })
    const payload = { active: checked }
    updateConsent(getToken(), this.props.consent.id, payload)
    .catch(err => { console.log(err) })
    this.props.refreshCallback();
  }

  changeTranslationValue = (e, fieldname) => {
    const fieldValue = e.target.value;
    const consentTranslation = this.state.consentTranslation;

    consentTranslation[fieldname] = fieldValue;
    this.setState({ consentTranslation, validates: fieldValue !== '' });
  }

  validateConsentTranslation() {
    let isValid = true;
    const mandatoryFields = [
      'banner_title',
      'banner_description',
      'shield_title',
      'shield_message',
      'shield_button_text'
    ]
    for (const [key, value] of Object.entries(this.state.consentTranslation)) {
      if (mandatoryFields.includes(key) && value === '') isValid = false;
    };
    this.setState({ validates: isValid });
    return isValid;
  }

  onSubmit = async (id) => {
    if (this.validateConsentTranslation()) {
      this.setState({ isUpdating: true })

      updateConsentTranslation(getToken(), this.state.consentTranslation)
      .then(async syslanguage => {
        await this.loadData()

        setTimeout(() => {
          this.setState({ isUpdating: false })
        }, 1000)
      })
      .catch(err => {
        this.setState({ isUpdating: false })
      })

      this.props.submitCallback(id)
    }
  }

  render() {

    const consent = this.props.consent;
    const consentTranslation = this.state.consentTranslation;
    const serviceIdentifier = consent.third_party_service.identifier;

    return (
      <div className={this.itemClassName()} id={serviceIdentifier}>
        <div className="dd-consent__bar">
          <h5 className="dd-consent__title">{consentTranslation.banner_title}</h5>
          <div className="dd-consent__usage">
            {this.state.showBlockUsage &&
              <span className="dd-consent__usage-count"> {consent.usage}x verwendet </span>
            }
            {this.state.showNoBlockUsage &&
              <span className="dd-consent__usage-count">{ this.state.deletable ? 'dazugehöriges Inhaltselement wurde gelöscht' : 'nicht verwendet' }</span>
            }
          </div>

          <div className="dd-consent__actions">
            <div className="dd-consent__action-activate">
              <span>{ this.state.disabled ? 'Verpflichtend (keine Ablehnung möglich)' : 'Zustimmung des Nutzers einholen?' }</span>
              <Switch
                onChange={(checked) => { this.handleActivation(checked) }}
                checked={this.state.active}
                disabled={this.state.disabled}
                offColor="#BCBDB5"
                onColor="#707070"
                uncheckedIcon={false}
                height={20}
                width={40}
                activeBoxShadow="0px 0px 2px 2px rgba(0, 0, 0, 0.2)"
              />
            </div>
            <div className="dd-consent__action-edit">
              <button className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only" onClick={() => this.handleEditClick()}>
                {this.state.itemClassNames.includes("dd-consent--expanded") ? <Icon icon="plus" /> : <Icon icon="edit" />}
              </button>
            </div>
            <div className="dd-consent__action-delete">
              {this.state.deletable &&
                <button className="dd-btn dd-btn--danger dd-btn--icon-only" onClick={() => this.handleDeleteClick(consent.third_party_service.id)}>
                  <Icon icon="trash" />
                </button>
              }
            </div>
            <div className="dd-consent__action-move" {...this.props.dragHandleProps}>
              {this.state.fixed
                ?
                <button className="dd-btn dd-btn--disabled dd-btn--icon-only">
                  <Icon icon="sorter" />
                </button>
                :
                <button className="dd-btn dd-btn--secondary dd-btn--icon-only" onMouseOver={() => this.handleDragMouseOver()} onMouseOut={() => this.handleDragMouseOut()}>
                  <Icon icon="sorter" />
                </button>
              }
            </div>
          </div>
        </div>
        <div className="dd-consent__form">
          <div className="row">
            <div className="col-sm">
              <h5>Datenschutz-Popup</h5>
              <div className="form-group">
                <label className="textfield__label">Titel</label>
                <Textfield
                  name="banner_title"
                  type="text"
                  handleChange={(e) => this.changeTranslationValue(e, "banner_title")}
                  defaultValue={consentTranslation.banner_title}
                />
              </div>
              <div className="form-group">
                <label className="textfield__label">Kurzbeschreibung</label>
                <textarea
                  className="form-control"
                  name="banner_description"
                  rows="3"
                  onChange={(e) => this.changeTranslationValue(e, "banner_description")}
                  defaultValue={consentTranslation.banner_description}
                />
              </div>
            </div>
            <div className="col-sm">
              {consent.third_party_service.identifier === 'analytics' && (
                <>
                  <div className="form-group">
                    <label className="textfield__label">Einbettungs-Code (HTML) für Kontext &#x3C;body&#x3E;</label>
                    <p className="hint-text">z.B. für Matomo oder Google Tag Manager</p>
                    <textarea
                      className="form-control"
                      name="analytics_body"
                      rows="8"
                      defaultValue={this.props.analytics_body}
                      onChange={(e) => this.props.changeValue(e, "analytics_body")}
                    />
                  </div>
                  <div className="form-group">
                    <label className="textfield__label">Einbettungs-Code (HTML) für Kontext &#x3C;head&#x3E;</label>
                    <p className="hint-text">z.B. für Google Tag Manager</p>
                    <textarea
                      className="form-control"
                      name="analytics_head"
                      rows="8"
                      defaultValue={this.props.analytics_head}
                      onChange={(e) => this.props.changeValue(e, "analytics_head")}
                    />
                  </div>
                </>
              )}
              {consent.third_party_service.identifier === 'head' && (
                <div className="form-group">
                  <label className="textfield__label">Code (HTML) für Kontext &#x3C;head&#x3E;</label>
                  <p className="hint-text">z.B. für zusätzliche Metaangaben</p>
                  <textarea
                    className="form-control"
                    name="head_metatags"
                    rows="8"
                    defaultValue={this.props.head_metatags}
                    onChange={(e) => this.props.changeValue(e, "head_metatags")}
                  />
                </div>
              )}
              {!['tracking', 'head', 'diro', 'analytics'].includes(consent.third_party_service.identifier) && (
                <>
                  <h5>Zwei-Klick-Lösung</h5>
                  <div className="form-group">
                    <label className="textfield__label">Überschrift</label>
                    <Textfield
                      name="shield_title"
                      type="text"
                      handleChange={(e) => this.changeTranslationValue(e, "shield_title")}
                      defaultValue={consentTranslation.shield_title}
                    />
                  </div>
                  <div className="form-group">
                    <label className="textfield__label">Erklärtext</label>
                    <textarea
                      className="form-control"
                      name="shield_message"
                      rows="3"
                      onChange={(e) => this.changeTranslationValue(e, "shield_message")}
                      defaultValue={consentTranslation.shield_message}
                    />
                  </div>
                  <div className="form-group">
                    <label className="textfield__label">Buttontext</label>
                    <Textfield
                      name="shield_button_text"
                      type="text"
                      handleChange={(e) => this.changeTranslationValue(e, "shield_button_text")}
                      defaultValue={consentTranslation.shield_button_text}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="dd-consent__footer">
            <span className={this.state.validates ? "dd-consent__error" : "dd-consent__error dd-consent__error--active"}>Die Felder für die Datenschutztexte dürfen nicht leer bleiben</span>
            <button className="dd-btn dd-btn--primary" disabled={this.state.isUpdating || !this.state.validates} onClick={() => this.onSubmit(serviceIdentifier)}>
              {this.state.isUpdating ? (
                <>
                  <div className="spinner-grow spinner-grow-sm text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <span style={{marginLeft: "10px"}}>Wird gespeichert</span>
                </>
              ) : "Speichern"}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Consent;
