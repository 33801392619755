import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import PageEditTabs from "./PageEditTabs";
import PageMetadata from "./PageMetadata";
import PagePublishing from "./PagePublishing";

import SimpleSelectfield from "../Shared/atom/Selectfield/SimpleSelectfield";
import Textfield from "../Shared/atom/Textfield/Textfield";
import Blocks from "../Shared/module/Blocks/Blocks";
import Loader from "../Shared/atom/Loader/Loader";
import Editbar from "../Shared/element/Editbar/Editbar";
import LangSwitcher from "../Shared/element/LangTools/LangSwitcher";
import Checkbox from "../Shared/atom/Checkbox/Checkbox";

import { AppContext } from "../../data/ContextProvider";
import { getPage, updatePage, getCompany, getLanguages, getTerms, createKeyword  } from "../../data/api";
import { getToken, getCurrentOpenPageTab, dateToRailsDateString } from "../../lib/lib";


class PageContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      pageID: 0,
      previewURL: '',
      loading: true,
      article: {},
      page: {},
      errorMessage: "",
      isUpdating: false,
      blockContent: [],
      lawfields: [],
      pageLawfields: [],
      countries: [],
      pageCountries: [],
      federalstates: [],
      pageFederalstates: [],
      keywords: [],
      pageKeywords: []
    }
  }

  errorHandler = (hasError=false) => {
    this.setState({ hasError })
  }

  onIncompleteChange = (e) => {
    const page = { ...this.state.page };
    page.homepage = !page.homepage

    this.setState({ page }, () => {
    })
  }

  onIsVirtualChange = (e) => {
    const page = { ...this.state.page }
    page.is_virtual = !page.is_virtual
    this.setState({ page })
  }

  onNoIndexChange = (e) => {
    const page = { ...this.state.page }
    page.noindex = !page.noindex
    this.setState({ page })
  }

  onNoFollowChange = (e) => {
    const page = { ...this.state.page }
    page.nofollow = !page.nofollow
    this.setState({ page })
  }

  onShowImportedContentChange = (e) => {
    const page = { ...this.state.page };
    page.show_imported_content = !page.show_imported_content

    this.setState({ page }, () => {
    })
  }

  keywordsTagsUpdate = (assignedTags) => {
    this.setState({
      pageKeywords: assignedTags
    })
  }

  lawFieldsTagsUpdate = (assignedTags) => {
    this.setState({
      pageLawfields: assignedTags
    })
  }

  countriesTagsUpdate = (assignedTags) => {
    this.setState({
      pageCountries: assignedTags
    })
  }

  federalstatesTagsUpdate = (assignedTags) => {
    this.setState({
      pageFederalstates: assignedTags
    })
  }


  addKeywordTag = (newTag) => {
    const keywords = this.state.keywords
    createKeyword(getToken(), newTag)
      .then(newKeyword => {
        this.setState({
          keywords: keywords.push(newKeyword)
        })
      })
      .catch(err => {
        this.setState({ isUpdating: false })
      })
  }

  onChange = (event) => {
    this.onValueChange(event.target.name, event.target.value)
  }

  onValueChange = (name, value) => {
    const page = this.state.page
    page[name] = value
    this.setState({ page }, () => {

    })
  }

  saveDateChange = (name, value) => {
    const page = this.state.page
    page[name] = dateToRailsDateString(value[0])
    this.setState({ page }, () => {
    })
  }

  onSelectProfile = (value) => {

    const employee = this.state.employees.find(s => s.id === parseInt(value))

    if (employee) {
      const page = this.state.page
      page.employee_id = employee.id
      this.setState({ page: page })
    }
  }

  onBlocksChange = (blockContent) => {
    const page = this.state.page
    page.content = blockContent
    this.setState({ page, blockContent })
  }

  selectTranslation = async (shorthand) => {
    const lang = this.props.match.params.lang

    if (this.state.page.translations[shorthand]) {
      const translatetPage = this.state.page.translations[shorthand]

      this.props.history.push(`/${lang}/pages/${translatetPage.id}/content`);

      this.setState({ pageID: translatetPage.id }, ()=> {
        this.loadData()
      })
    }
  }

  onSubmit = () => {

    this.setState({ isUpdating: true })
    const page = this.state.page

    page.keyword_ids = this.state.pageKeywords.map(tag => tag.id)
    page.lawfield_ids = this.state.pageLawfields.map(tag => tag.id)
    page.country_ids = this.state.pageCountries.map(tag => tag.id)
    page.federalstate_ids = this.state.pageFederalstates.map(tag => tag.id)



    updatePage(getToken(), this.state.page.id, page)
      .then(updatedArticle => {
        this.loadData()
      })
      .catch(err => {
        this.setState({ isUpdating: false, hasError: true, errorMessage: err.data.message })
      })
  }

  loadData = async () => {
    try {

      const pageResponse   = await getPage(getToken(), this.state.pageID)
      const lawfields     = await getTerms(getToken(), 'lawfields')
      const countries     = await getTerms(getToken(), 'countries')
      const keywords      = await getTerms(getToken(), 'keywords')
      const federalstates = await getTerms(getToken(), 'federalstates')


      const company = await getCompany(getToken(), this.context.company.id)
      const syslanguages = await getLanguages(getToken())

      const page = pageResponse.page

      const blocksJSONData = page.content || "[]"

      let pagePath = page.path

      if (!page.published) {
        pagePath += "?p=" + page.preview_token
      }

      const preview = 'http://' + page.company.domain + '/' + page.lang + '/' + pagePath

      this.setState({
        ...this.state,
        page: page,
        company: company.company,
        lawfields: lawfields.lawfields,
        pageLawfields: page.lawfields,
        countries: countries.countries,
        pageCountries: page.countries,
        federalstates: federalstates.federalstates,
        pageFederalstates: page.federalstates,
        keywords: keywords.keywords,
        pageKeywords: page.keywords,
        employees: company.company.employees,
        blockContent: JSON.parse(blocksJSONData),
        previewURL: preview,
        loading: false,
        syslanguages: syslanguages.syslanguages,
      })
    } catch (error) {
      //FIXME NotFound
      console.log(error)
    }
  }

  async componentDidMount() {
    if (this.props.match && this.props.match.params) {
      this.setState({
        ...this.state,
        pageID: this.props.match.params.page_id,
      }, async () => {
        await this.loadData()
      })
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    const currentOpenTab = getCurrentOpenPageTab(this.props.match.params.tabs)

    const formValid = (this.state.page.navigation_title) ? true : false
    const hasError = (this.state.hasError || !formValid) ? true : false

    return (
      <div key={this.state.page.id + this.state.page.lang } className="tab-pane fade show active">
        <div className="card-body card-body--standard dd-background dd-background--no-padding-bottom dd-background--white">

          <div className="sidebar">
            <div className={`publish-status ${this.state.page.published ? 'publish-status--is-live' : 'publish-status--is-offline'}`}>
              <span className="publish-status--live">Live</span>
              <span className="publish-status--offline">Offline</span>
            </div>

            <LangSwitcher
              item={this.state.page}
              controller="pages"
              itemType="page"
              syslanguages={this.state.syslanguages}
              selectTranslation={(shorthand)=>this.selectTranslation(shorthand)}
            />
          </div>

          <div className="card-body__content w-75 ml-auto mr-auto">
            <div className="form-group">
              <Textfield
                name="title"
                type="text"
                isRequired={false}
                label="Titel des Beitrags"
                handleChange={this.onChange}
                defaultValue={this.state.page.title}
              />
            </div>

            <div className="form-group">
              <Textfield
                name="navigation_title"
                type="text"
                isRequired={true}
                label="Navigationstitel"
                handleChange={this.onChange}
                defaultValue={this.state.page.navigation_title}
                errorHandler={this.errorHandler}
              />
            </div>

            <div className="form-group">
              <Textfield
                name="author"
                type="text"
                isRequired={true}
                label="Autor"
                defaultValue={this.context.user.full_name}
                errorHandler={this.errorHandler}
              />
            </div>

            {this.state.page.pagetype === 'employee' &&
              <div className="form-group">
                <SimpleSelectfield
                  label="Berufsträger"
                  name="employee_id"
                  indexLabel="id"
                  optionLabel="full_name"
                  firstOptionLabel="Bitte auswählen"
                  options={this.state.employees}
                  onSelect={this.onSelectProfile}
                  selected={this.state.page.employee_id}
                />
              </div>
            }

            {this.state.page.imported_content && this.state.page.imported_content.length  &&
              <div className="form-group">
                <label className="textfield__label">Importierte Inhalte</label>
                <textarea
                  className="form-control"
                  name="imported_content"
                  rows="10"
                  defaultValue={this.state.page.imported_content}
                  onChange={(e) => this.onChange(e)}
                  placeholder=""
                />

                <label className="textfield__label">Importierte Inhalte anzeigen?</label>
                <Checkbox
                  label="ja"
                  id="show_imported_content"
                  selected={this.state.page.show_imported_content}
                  onChange={(e) => this.onShowImportedContentChange(e)}
                />
              </div>
          }

          </div>

          <PageEditTabs
            activeTab={currentOpenTab}
            page={this.state.page}
          />

        </div>


        {currentOpenTab === 0 &&
          <React.Fragment>
            <div className="card-body card-body--standard">
              <div className="card-body__content w-75 ml-auto mr-auto">

                  <div className="form-group">
                    <Blocks
                      handleChange={this.onBlocksChange}
                      blocks={this.state.blockContent}
                      errorHandler={this.errorHandler}
                    />
                  </div>
              </div>
            </div>
          </React.Fragment>
        }
        {currentOpenTab === 1 &&
          <PageMetadata
            page={this.state.page}
            company={this.state.company}
            lawfields={this.state.lawfields}
            pageLawfields={this.state.pageLawfields}
            countries={this.state.countries}
            pageCountries={this.state.pageCountries}
            federalstates={this.state.federalstates}
            pageFederalstates={this.state.pageFederalstates}
            keywords={this.state.keywords}
            pageKeywords={this.state.pageKeywords}
            onChange={this.onChange}
            addKeywordTag={this.addKeywordTag}
            onIncompleteChange={this.onIncompleteChange}
            onIsVirtualChange={this.onIsVirtualChange}
            onNoIndexChange={this.onNoIndexChange}
            onNoFollowChange={this.onNoFollowChange}
            keywordsTagsUpdate={this.keywordsTagsUpdate}
            lawFieldsTagsUpdate={this.lawFieldsTagsUpdate}
            countriesTagsUpdate={this.countriesTagsUpdate}
            federalstatesTagsUpdate={this.federalstatesTagsUpdate}
          />
        }
        {currentOpenTab === 3 &&
          <PagePublishing
            page={this.state.page}
            onValueChange={this.onValueChange}
            saveDateChange={this.saveDateChange}
            onChange={this.onChange}
          />
        }
        <Editbar
          previewURL={this.state.previewURL}
          hasPublish={false}
          hasPreview={true}
          hasError={hasError}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
          errorMessage={this.state.errorMessage}
        />
      </div>
    );
  }
}

PageContent.contextType = AppContext
export default withRouter(PageContent);
