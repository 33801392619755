import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";
import RadioButton from "../../atom/RadioButton/RadioButton";
import Overlay from "../../element/Overlay/Overlay";
import PageSelector from "../../module/PageSelector/PageSelector";

import BlockPreviewBanner from './BlockPreviews/Banner';
import BlockSectionSettings from './BlockSectionSettings';

import { getToken } from "../../../../lib/lib";
import { getPage, getArticle, getProduct } from "../../../../data/api";
import BlockActions from "./BlockActions";

class BlockBannerEdit extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      content:{},
      imageOverlayActive: false,
      preview: true,
      showSettings: false
    }
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  openPageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      pageOverlayActive: true
    })
  }

  closePageSelectorOverlay = () => {
    this.setState({
      pageOverlayActive: false,
    })
  }

  handlePageSelect = (page) => {
    const content = this.state.content

    if (page.id) {
      content.pageID    = page.id
      content.pageTitle = page.title
      content.pageType  = page.content_type
      content.buttonURL =  ""
    } else {
      content.pageID = ""
      content.pageTitle = ""
      content.pageType  = ""
      content.buttonURL = page.buttonURL
    }

    content.buttonTarget = page.buttonTarget

    this.saveContent(content)
    this.closePageSelectorOverlay()
    this.setState({ page: page })
  }

  onChange = (event) => {
    const content = this.state.content
    content[event.target.name] = event.target.value
    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  async componentDidMount() {
    try {
      if (this.props.initData) {
        const preview = this.props.preview
        const content = this.props.initData[0]
        let page = {}

        if (content.pageID) {

          if (content.pageType === 'page') {
            const pageData = await getPage(getToken(), content.pageID)
            page = pageData.page
          } else if (content.pageType === 'article') {
            const pageData = await getArticle(getToken(), content.pageID)
            page = pageData.article
          } else if (content.pageType === 'product') {
            const pageData = await getProduct(getToken(), content.pageID)
            page = pageData.product
          }
        }
        // FIXME DEPRECATED
        else if (content.articleID) {
          const articleData = await getArticle(getToken(), content.articleID)
          page = articleData.article
        }

        this.setState({ content, preview, page })
      }
    } catch (error) {
      //FIXME NotFound
      console.log(error)
    }
  }

  renderSeletedItemLabel = () => {

    if (this.state.content.buttonURL) {
      return this.state.content.buttonURL
    } else if (this.state.content.pageID && this.state.page && this.state.page.title) {

      if (this.state.page.navigation_title) {
        return `${this.state.page.title} (${this.state.page.navigation_title})`
      } else {
        return `${this.state.page.title}`
      }

    } else if (this.state.content.articleID && this.state.page && this.state.page.title) {
      return `${this.state.page.title}`
    }
  }

  render() {

    return (
      <>
        {this.state.preview
          ?
          <div className="dd-block" onClick={() => this.togglePreviewEditMode()}>
            <div className="dd-block__preview">
              <BlockPreviewBanner block={this.props.initData} />
            </div>
          </div>
          :
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-banner" /> Banner
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }

              <div className="dd-block__edit-section">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <label className="radio-button__group-label text-nowrap ">Hintergrundfarbe auswählen</label>
                    <RadioButton
                      name="background"
                      label="keine"
                      value=""
                      handleChange={this.onChange}
                      selected={this.state.content.background === ""}
                    />
                    <RadioButton
                      name="background"
                      label="Primärfarbe"
                      value="primary"
                      handleChange={this.onChange}
                      selected={this.state.content.background === "primary"}
                    />
                    <RadioButton
                      name="background"
                      label="Sekundärfarbe"
                      value="secondary"
                      handleChange={this.onChange}
                      selected={this.state.content.background === "secondary"}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <Textfield
                    name="title"
                    type="text"
                    isRequired={true}
                    label="Text"
                    placeholder="Titel*"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.title}
                    errorHandler={this.props.errorHandler}
                  />
                </div>
                <div className="form-group">
                  <Textfield
                    name="buttonTitle"
                    type="text"
                    isRequired={true}
                    label="Button Beschriftung"
                    placeholder="Button Titel*"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.buttonTitle}
                    errorHandler={this.props.errorHandler}
                  />
                </div>
                <div className="form-group">

                  <div className="dd-link-selector">
                    <div className="dd-link-selector__link">{this.renderSeletedItemLabel()}</div>
                    <button className="dd-btn dd-btn--primary dd-link-selector__button" onClick={(e) => this.openPageSelectorOverlay(e)}>
                      Linkziel ändern
                      </button>
                  </div>
                  {this.state.pageOverlayActive &&
                    <Overlay closeOverlayCallback={() => this.closePageSelectorOverlay()}>
                      <PageSelector
                        content={this.state.content}
                        selectPageCallback={(page) => this.handlePageSelect(page)}
                      />
                    </Overlay>
                  }
                </div>
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
        }
      </>
    )
  }
}

export default BlockBannerEdit
