import React, { Component } from 'react'

class Textfield extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  onChange = (e) => {

    if (this.props.isRequired) {
      if (!e.target.value.length) {
        this.setState({ hasError: true })

        if (this.props.errorHandler) {
          this.props.errorHandler(true, e)
        }

        if (this.props.handleChange) {
          this.props.handleChange(e)
        }

        return;
      }
    }

    this.setState({ hasError: false })

    if (this.props.handleChange) {
      this.props.handleChange(e)
    }

    //reset
    if (this.props.errorHandler) {
      this.props.errorHandler(false, e)
    }

  }

  render() {

    const value = this.props.defaultValue || ""

    let inputClassName = (this.props.hasError || this.state.hasError)
      ? 'form-control textfield__input textfield__input--error'
      : 'form-control textfield__input'


    if (this.props.additionalClassName) {
      inputClassName += this.props.additionalClassName
    }

    if (this.props.language) {
      inputClassName += ' languages--field-padding '
    }

    return (
      <div className="form-group textfield">
        {this.props.label && (
          <label className="textfield__label" htmlFor={this.props.name}>
            {this.props.label}
            {this.props.isRequired ? '*' : ''}
          </label>
        )}

        {this.props.language && (
          <span className="languages__language-indicator">{this.props.language}</span>
        )}


        {this.props.isRequired ? (

          <input
            type={this.props.type}
            required
            readOnly={this.props.isReadonly}
            className={inputClassName}
            name={this.props.name}
            defaultValue={value}
            placeholder={this.props.placeholder}
            maxLength={this.props.maxLength || ''}
            onChange={this.onChange}
          />
        ) : (
          <input
            type={this.props.type}
            className={inputClassName}
            name={this.props.name}
            readOnly={this.props.isReadonly}
            defaultValue={value}
            placeholder={this.props.placeholder}
            maxLength={this.props.maxLength || ''}
            onKeyUp={(e) => { if (e.keyCode === 8 && !e.target.value && this.props.handleChange) this.props.handleChange(e) } }
            onChange={
              this.props.handleChange ? (e) => this.props.handleChange(e) : null
            }
          />
        )}

        {this.state.hasError && (
          <div className="textfield__error-wrapper">
            <div className="textfield__error-message">Bitte füllen Sie dieses Feld aus</div>
          </div>
        )}

        {this.props.hint && (
          <div className="textfield__hint">{ this.props.hint }</div>
        )}

      </div>
    )
  }
}


export default Textfield
