import React from "react";
import Loader from "../Shared/atom/Loader/Loader";
import { DragDropContext } from "react-beautiful-dnd";
import { getSetting, updateSetting, getConsents, getConsentTypes, updateConsent, getSyslanguages, getLanguages } from "../../data/api";
import { getToken } from "../../lib/lib";
import { AppContext } from "../../data/ContextProvider";
import LangSwitcherStatic from "../Shared/element/LangTools/LangSwitcherStatic";
import ConsentType from "./Tracking/ConsentType";
import Textfield from "../Shared/atom/Textfield/Textfield";
import Accordion from "../Shared/module/Accordion/Accordion";
import Icon from "../Shared/atom/Icon/Icon";

class Tracking extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      activeLanguage: 'de',
      loading: true,
      isUpdating: false,
      dirty: false,
      items: [],
      consents: []
    };
  }

  setActiveLanguage = (shorthand) => {
    this.loadData(shorthand);
  };

  changeValue = (e, fieldname) => {
    const settings = { ...this.state.settings };
    settings[fieldname] = e.target.value;
    this.setState({ settings });
  };

  changeValueCookieBanner = (e, fieldname) => {
    this.setState({ dirty: true });
    const settings = { ...this.state.settings };
    const value = e.target.value;
    const name = fieldname ? fieldname : e.target.name;
    settings.misc[name] = value;
    this.setState({ settings });
  };

  onDragEnd(result) {
    if (!result.destination) return;

    const consent_id = parseInt(result.draggableId);
    const destination = parseInt(result.destination.droppableId);
    const consents = this.state.consents;

    consents.forEach((consent, index) => {
      if (consent.id === consent_id) {
        consents[index].consent_type_id = destination;
      }
    });

    this.setState({ consents });

    const payload = { consent_type_id: destination };

    updateConsent(getToken(), consent_id, payload)
      .then(async syslanguage => {
        await this.loadData();
      })
      .catch(err => {
        console.log(err);
      });
  }

  onSubmit = async (serviceIdentifier) => {
    this.setState({ isUpdating: true });

    let payload = {};

    if (serviceIdentifier === 'head') payload = {
      head_metatags: this.state.settings.head_metatags
    };

    if (serviceIdentifier === 'analytics') payload = {
      analytics_head: this.state.settings.analytics_head,
      analytics_body: this.state.settings.analytics_body
    };

    if (Object.keys(payload).length > 0) {
      updateSetting(getToken(), this.state.settings.id, payload)
        .then(async syslanguage => {
          await this.loadData();
        })
        .catch(err => {
          this.setState({ isUpdating: false });
        });
    }

    updateSetting(getToken(), this.state.settings.id, this.state.settings)
      .then(async syslanguage => {
        setTimeout(() => {
          this.setState({
            isUpdating: false,
            dirty: false
          });
        }, 1000);

        await this.loadData();
      })
      .catch(err => {
        this.setState({ isUpdating: false });
      });
  };

  refreshCallback = async () => {
    await this.loadData();
  };

  loadData = async (lang) => {
    let activeLang = lang || this.state.activeLanguage;
    const settingsObj = await getSetting(getToken());
    const consentsObj = await getConsents(getToken(), activeLang);
    const consentTypesObj = await getConsentTypes(getToken(), activeLang);
    const syslanguages = await getSyslanguages(getToken());
    const activatedLanguagesRecord = await getLanguages(getToken());
    const settings = settingsObj.settings;
    const consents = consentsObj.consents.sort((a) => {
      if (a.third_party_service.identifier === 'diro') return -1
      return 0
    })

    const consentTypes = consentTypesObj?.consent_types || []

    if (!settings.lang) {
      settings.lang = {};
    }

    //default
    let activatedLanguages = [{ id: 1, shorthand: "de", title: "Deutsch" }];
    if (activatedLanguagesRecord.syslanguages && activatedLanguagesRecord.syslanguages.length > 0) {
      activatedLanguages = activatedLanguagesRecord.syslanguages;
    }

    //add missing syslanguages
    syslanguages.syslanguages.forEach(element => {
      if (!(element.shorthand in settings.lang)) {
        settings.lang[element.shorthand] = { enabled: false, public: false, weight: 10, shorthand: element.shorthand };
      }
    });

    // if (activatedLanguages[0]) {
    //   activeLang = activatedLanguages[0].shorthand
    // }

    for (let i = 0; i < activatedLanguages.length; i++) {
      if (typeof settings.misc[`expertise_${activatedLanguages[i].shorthand}`] === 'undefined') {
        settings.misc[`expertise_${activatedLanguages[i].shorthand}`] = 'Expertise';
      }
    }

    this.setState({
      settings,
      consents,
      consentTypes,
      syslanguages: syslanguages.syslanguages,
      activatedLanguages: activatedLanguages,
      activeLanguage: activeLang,
      loading: false,
    });
  };

  async componentDidMount() {
    await this.loadData();
  }

  getNoCookiesNeeded() {
    const firstActiveNonMandatoryConsent = this.state.consents.findIndex((consent) => {
      let type = this.state.consentTypes.find((consentType) => consentType.id === consent.consent_type_id);
      return consent.active && !type.mandatory;
    });
    return firstActiveNonMandatoryConsent === -1;
  }

  getUnreviewedConsentsCount() {
    const unreviewedConsents = this.state.consents.filter((consent) => {
      let type = this.state.consentTypes.find((consentType) => consentType.id === consent.consent_type_id);
      return !type.mandatory && consent.usage > 0 && !consent.active;
    })
    return unreviewedConsents.length;
  }

  render() {

    const admin = (this.context.user.admin || this.context.user.diro_admin);
    const consents = this.state.consents;
    const consentTypes = this.state.consentTypes;
    const noCookiesNeeded = this.getNoCookiesNeeded();
    const unreviewedConsentsCount = this.getUnreviewedConsentsCount();

    if (this.state.loading || !admin) {
      return <Loader />;
    }

    return (
      <>
        <div className="sidebar">
          <LangSwitcherStatic
            activeLanguage={this.state.activeLanguage}
            syslanguages={this.state.activatedLanguages}
            setActiveLanguage={(shorthand) => this.setActiveLanguage(shorthand)}
          />
        </div>

        <div className="dd-status">
          <div className="dd-status__item">
            <div className="dd-status__title">Datenschutz-Popup:</div>
            <div className="dd-status__info">{ noCookiesNeeded ? 'deaktiviert (keine Cookies)' : 'aktiv' }</div>
            <div className="dd-status__icon">
              <Icon icon={ noCookiesNeeded ? 'hidden' : 'checkmark' } />
            </div>
          </div>
          <div className={`dd-status__item ${unreviewedConsentsCount > 0 && 'dd-status__item--alert'}`}>
            <div className="dd-status__title">Prüfung erforderlich:</div>
            <div className="dd-status__info">{ unreviewedConsentsCount ? `${unreviewedConsentsCount} ${ unreviewedConsentsCount > 1 ? 'Dienste' : 'Dienst'}` : 'keine' }</div>
            <div className="dd-status__icon">
              <Icon icon={ unreviewedConsentsCount > 0 ? 'warning' : 'checkmark' } />
            </div>
          </div>
        </div>

        <Accordion
          title="Texte und Bezeichnungen"
          isOpen={false}
          style={{ width: "100%" }}
        >
          {this.state.activatedLanguages.map((syslanguage, key) => {
            return <div key={key}>
              {(this.state.activeLanguage === syslanguage.shorthand) &&
                <div className="dd-form-section dd-form-section--first dd-form-section--last">
                  <React.Fragment>
                    <div className="row">
                      <div className="col-sm">
                        <h6>Texte Datenschutz-Popup</h6>
                        <p><small>({syslanguage.title})</small></p>
                        <div className="form-group">
                          <Textfield
                            name={`cookie_banner_headline_${syslanguage.shorthand}`}
                            type="text"
                            label="Überschrift"
                            handleChange={(e) => this.changeValueCookieBanner(e, "cookie_banner_headline_" + syslanguage.shorthand)}
                            defaultValue={this.state.settings.misc["cookie_banner_headline_" + syslanguage.shorthand]}
                          />
                        </div>
                        <div className="form-group">
                          <label className="textfield__label">Einleitung</label>
                          <textarea
                            className="form-control"
                            name={`cookie_banner_text_${syslanguage.shorthand}`}
                            defaultValue={this.state.settings.misc["cookie_banner_text_" + syslanguage.shorthand]}
                            onChange={(e) => this.changeValueCookieBanner(e, "cookie_banner_text_" + syslanguage.shorthand)}
                          />
                        </div>
                        <div className="form-group">
                          <Textfield
                            name={`cookie_banner_ok_button_${syslanguage.shorthand}`}
                            type="text"
                            label="Beschriftung des Buttons zur Bestätigung"
                            handleChange={(e) => this.changeValueCookieBanner(e, "cookie_banner_ok_button_" + syslanguage.shorthand)}
                            defaultValue={this.state.settings.misc["cookie_banner_ok_button_" + syslanguage.shorthand]}
                          />
                        </div>
                        <div className="form-group">
                          <Textfield
                            name="cookie_banner_info_button"
                            type="text"
                            label="Beschriftung des Buttons zu weiteren Informationen"
                            handleChange={(e) => this.changeValueCookieBanner(e, "cookie_banner_info_button_" + syslanguage.shorthand)}
                            defaultValue={this.state.settings.misc["cookie_banner_info_button_" + syslanguage.shorthand]}
                          />
                        </div>
                        <div className="form-group">
                          <Textfield
                            name="cookie_banner_cancel_button"
                            type="text"
                            label="Beschriftung des Buttons zur Ablehnung"
                            handleChange={(e) => this.changeValueCookieBanner(e, "cookie_banner_cancel_button_" + syslanguage.shorthand)}
                            defaultValue={this.state.settings.misc["cookie_banner_cancel_button_" + syslanguage.shorthand]}
                          />
                        </div>
                      </div>
                      <div className="col-sm">
                        <h6>Link zur Datenschutzerklärung</h6>
                        <p><small>Wird im Datenschutz-Popup und Content-Shield angezeigt.</small></p>
                        <div className="form-group">
                          <label className="textfield__label">Einleitungstext</label>
                          <Textfield
                            name="shield_privacy_intro"
                            type="text"
                            placeholder="Details finden Sie in unserer"
                            handleChange={(e) => this.changeValueCookieBanner(e, "shield_privacy_intro_" + syslanguage.shorthand)}
                            defaultValue={this.state.settings.misc["shield_privacy_intro_" + syslanguage.shorthand]}
                          />
                        </div>
                        <div className="form-group">
                          <label className="textfield__label">Linktext</label>
                          <Textfield
                            name="shield_privacy_label"
                            type="text"
                            placeholder="Datenschutzerklärung"
                            handleChange={(e) => this.changeValueCookieBanner(e, "shield_privacy_label_" + syslanguage.shorthand)}
                            defaultValue={this.state.settings.misc["shield_privacy_label_" + syslanguage.shorthand]}
                          />
                        </div>
                        <div className="form-group">
                          <label className="textfield__label">Internetadresse (URL)</label>
                          <Textfield
                            name="shield_privacy_link"
                            type="text"
                            handleChange={(e) => this.changeValueCookieBanner(e, "shield_privacy_link_" + syslanguage.shorthand)}
                            defaultValue={this.state.settings.misc["shield_privacy_link_" + syslanguage.shorthand]}
                          />
                        </div>
                      </div>
                      <div className="col-sm">
                        <h6>Link zum Impressum</h6>
                        <p><small>Wird im Datenschutz-Popup angezeigt.</small></p>
                        <div className="form-group">
                          <label className="textfield__label">Einleitungstext</label>
                          <Textfield
                            name="shield_imprint_intro"
                            type="text"
                            placeholder="Unser Impressum"
                            handleChange={(e) => this.changeValueCookieBanner(e, "shield_imprint_intro_" + syslanguage.shorthand)}
                            defaultValue={this.state.settings.misc["shield_imprint_intro_" + syslanguage.shorthand]}
                          />
                        </div>
                        <div className="form-group">
                          <label className="textfield__label">Linktext</label>
                          <Textfield
                            name="shield_imprint_label"
                            type="text"
                            placeholder="finden Sie hier"
                            handleChange={(e) => this.changeValueCookieBanner(e, "shield_imprint_label_" + syslanguage.shorthand)}
                            defaultValue={this.state.settings.misc["shield_imprint_label_" + syslanguage.shorthand]}
                          />
                        </div>
                        <div className="form-group">
                          <label className="textfield__label">Internetadresse (URL)</label>
                          <Textfield
                            name="shield_imprint_link"
                            type="text"
                            handleChange={(e) => this.changeValueCookieBanner(e, "shield_imprint_link_" + syslanguage.shorthand)}
                            defaultValue={this.state.settings.misc["shield_imprint_link_" + syslanguage.shorthand]}
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              }
            </div>;
          })}

          <div className="consent-type__save" style={{ marginTop: "10px", marginBottom: "10px" }}>
            <button className="dd-btn dd-btn--primary" disabled={this.state.isUpdating || !this.state.dirty} onClick={this.onSubmit}>
              {this.state.isUpdating ? (
                <>
                  <div className="spinner-grow spinner-grow-sm text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <span style={{ marginLeft: "10px" }}>Wird gespeichert</span>
                </>
              ) : "Speichern"}
            </button>
          </div>
        </Accordion>

        {this.state.activatedLanguages.map((syslanguage, key) => {
          return <div key={key}>
            {(this.state.activeLanguage === syslanguage.shorthand) &&

              <DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
                {consentTypes.map((consentType, index) => (

                  <ConsentType
                    consentType={consentType}
                    consents={consents}
                    activeLang={this.state.activeLanguage}
                    analytics_body={this.state.settings.analytics_body}
                    analytics_head={this.state.settings.analytics_head}
                    head_metatags={this.state.settings.head_metatags}
                    changeValue={(e, fieldName) => this.changeValue(e, fieldName)}
                    submitCallback={this.onSubmit}
                    refreshCallback={this.refreshCallback}
                    key={index}
                  />

                ))}
              </DragDropContext>
            }
          </div>;
        })}
      </>
    );
  }
}

Tracking.contextType = AppContext;
export default Tracking;
