import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { AppContext } from "../../data/ContextProvider";
import Textfield from "../Shared/atom/Textfield/Textfield";
import TagList from "../Shared/atom/TagList/TagList";
import TextfieldGroup from "../Shared/atom/TextfieldGroup/TextfieldGroup";
import Loader from "../Shared/atom/Loader/Loader";
import { ImagePreview } from "../Shared/element/ImagePreview/ImagePreview";

import { getTerms, createKeyword } from "../../data/api";
import { getToken } from "../../lib/lib";


class ProductMetadata extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      product: {},
      lawfields: [],
      countries: [],
      federalstates: [],
      keywords: []
    }
  }

  addKeywordTag = (newTag) => {
    const keywords = this.state.keywords
    const productKeywords = this.props.product.keywords
    createKeyword(getToken(), newTag)
      .then(newKeyword => {
        keywords.push(newKeyword.keyword)
        productKeywords.push(newKeyword.keyword)
        this.setState({
          keywords
        }, this.props.onChange('keywords', productKeywords)
        )
      })
      .catch(err => {
        this.setState({ isUpdating: false })
      })
  }

  loadData = async () => {
    try {
      const lawfields = await getTerms(getToken(), 'lawfields')
      const countries = await getTerms(getToken(), 'countries')
      const keywords = await getTerms(getToken(), 'keywords')
      const federalstates = await getTerms(getToken(), 'federalstates')

      this.setState(
        {
          lawfields: lawfields.lawfields,
          countries: countries.countries,
          federalstates: federalstates.federalstates,
          keywords: keywords.keywords,
          loading: false
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  componentDidMount() {
    this.loadData()
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    const indexLabel = 'title_' + this.props.product.lang
    const canonical = this.props.product["canonical_" + this.props.product.lang]

    return (
      <React.Fragment>
        <div className="form-group">
          <TagList
            indexLabel={indexLabel}
            name="Stichworte"
            data={this.props.product.keywords}
            availableData={this.state.keywords}
            label="Stichworte, um die es in diesem Produkt geht"
            onUpdate={(assignedTags) => this.props.onChange('keywords', assignedTags)}
            hasAddNew={true}
            onCreate={(newTag) => this.addKeywordTag(newTag)}
          />
        </div>
        <div className="form-group">
          <label className="textfield__label">Kurzbeschreibung des Produkts (Meta Description)</label>
            <textarea
              className="form-control"
              name="description"
              defaultValue={this.props.product.description}
              onChange={(e) => this.props.onChange('description', e.target.value)}
              placeholder="Die Länge der Meta Description sollte 160 Zeichen nicht überschreiten. 130 bis 160 Zeichen sind optimal. Der Inhalt sollte zum Klick auf das Suchergebnis animieren."
            />
        </div>
        <div className="form-group">
          <TagList
            indexLabel={indexLabel}
            name="Rechtsgebiete"
            data={this.props.product.lawfields}
            availableData={this.state.lawfields}
            label="Rechtsgebiete, die im Produkt behandelt werden:"
            onUpdate={(assignedTags) => this.props.onChange('lawfields', assignedTags)}
          />
        </div>
        <div className="form-group">
          <TagList
            indexLabel={indexLabel}
            name="Länder"
            data={this.props.product.countries}
            availableData={this.state.countries}
            label="Produkt ist besonders relevant für Interessenten aus:"
            onUpdate={(assignedTags) => this.props.onChange('countries', assignedTags)}
          />
        </div>
        <div className="form-group">
          <TagList
            indexLabel={indexLabel}
            name="Bundesländer"
            data={this.props.product.federalstates}
            availableData={this.state.federalstates}
            label="Optional - Produkt ist besonders relevant für Interessenten aus:"
            onUpdate={(assignedTags) => this.props.onChange('federalstates', assignedTags)}
          />
        </div>

        <div className="form-group">
          <Textfield
            name="default_path"
            type="text"
            isReadonly={true}
            label="Automatische Adresse für dieses Produkt"
            defaultValue={`https://${this.context.company.domain}/${this.props.product.lang}/${this.props.product.default_path}`}
          />
        </div>

        <div className="form-group">
          <TextfieldGroup label={"Adresse, unter der dieses Produkt stattdessen auffindbar sein soll"}>
            <Textfield
              name="domain"
              type="text"
              isReadonly={true}
              defaultValue={`https://${this.context.company.domain}/${this.props.product.lang}/`}
            />
            <Textfield
              name="overridden_path"
              type="text"
              handleChange={(e) => this.props.onChange('overridden_path', e.target.value)}
              defaultValue={this.props.product.overridden_path}
            />
          </TextfieldGroup>
        </div>

        <div className="form-group">
          <Textfield
            name="default_path"
            type="text"
            isReadonly={true}
            label="Canonical Adresse für dieses Produkt"
            defaultValue={`${canonical}`}
          />
        </div>

        <div className="form-group">
          <Textfield
            name="external_canonical"
            type="text"
            label="Canonical Adresse, unter der dieses Produkt stattdessen auffindbar sein soll"
            defaultValue={this.props.product.external_canonical}
            handleChange={(e) => this.props.onChange('external_canonical', e.target.value)}
          />
        </div>

        <div className="form-group">
          <ImagePreview
            article={this.props.product}
            onItemChange={this.props.onItemChange}
            hint={"Empfohlenes Seitenverhältnis: 3:2. Empfohlenes Dateiformat: jpg. Mindestens 800px Breite."}
          />
        </div>
      </React.Fragment>
    )
  }
}

ProductMetadata.contextType = AppContext
export default withRouter(ProductMetadata);
