import React from "react";
import { Link, withRouter } from "react-router-dom";

import { throttle } from "throttle-debounce";

import Pagination from "../Shared/element/Pagination/Pagination";
import TableColumnHeader from "../../layout/Tables/TableColumnHeader";
import Searchbar from "../Shared/element/Searchbar/Searchbar";
import LangSelector from "../Shared/element/LangTools/LangSelector";
import LangChooserSelect from "../Shared/element/LangTools/LangChooserSelect";
import DeleteConfirmOverlay from "../Shared/module/Overlay/DeleteConfirmOverlay";
import Editbar from "../Shared/element/Editbar/Editbar";
import Loader from "../Shared/atom/Loader/Loader";

import { AppContext } from "../../data/ContextProvider";
import Icon from "../Shared/atom/Icon/Icon";

import { getPages, updatePage, deletePage, getPage, getLanguages, translatePage, duplicatePage } from "../../data/api";
import { getToken, formatDate, renderPageType } from "../../lib/lib";

class PagesTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      loading: true,
      openLangChooser: false,
      deleteConfirmOverlay: false,
      confirmMode: "delete",
      pageToDelete: 0,
      count: 0,
      pagesCount: 0,
      limit: 10,
      defaultLimit: 10,
      currentPage: 0,
      lang: "de",
      orderField: 'updated_at',
      orderDirection: 'desc',
      pages: [],
      showCreatePageDialog: false,
      pageActiveLanguageList: 0,
      selectedTranslations: {}
    }

    this.searchThrottled = throttle(500, this.loadData);
  }

  onFilterChange = (event) => {
    this.setState({
      query: event.target.value,
      currentPage: 0
    }, () => {
      this.searchThrottled()
    })
  }

  handlePaginationClick = async (e, page) => {
    this.setState({
      ...this.state,
      currentPage: page
    }, async () => {
      await this.loadData()
    })
  }

  handleSelectLimit = (limit) => {

    localStorage.setItem('pages_limit', limit);

    this.setState({ limit, currentPage: 0 }, async () => {
      await this.loadData()
    })
  }

  onTableColumnClick = (column) => {
    const { orderDirection } = this.state

    this.setState({
      orderField: column,
      orderDirection: (orderDirection === 'desc') ? 'asc' : 'desc'
    }, async () => {
      await this.loadData()
    })
  }

  toggleCreatePageDialog(e) {
    e.preventDefault()
    this.setState({
      showCreatePageDialog: !this.state.showCreatePageDialog
    })
  }

  setLang = async (lang) => {
    this.setState({
      lang: lang,
      selectedTranslations:{}
    }, async () =>{
      await this.loadData()
    })
  }

  toogleLangChooser = () => {
    this.setState({
      openLangChooser: !this.state.openLangChooser
    })
  }

  toggleLanguageList = (page) => {
    if (this.state.pageActiveLanguageList === 0) {
      this.setState({
        pageActiveLanguageList: page.id
      })
    } else {
      this.setState({
        pageActiveLanguageList: 0
      })
    }
  }

  selectTranslation = async (page, lang) => {
    const { selectedTranslations } = this.state
    const alreadyTranslated = Object.keys(selectedTranslations)

    const idToTranslate = page.translations[lang].toString()

    // reset
    if (alreadyTranslated.includes(idToTranslate)) {
      delete selectedTranslations[idToTranslate]
    } else {
      const transltedPage = await getPage(getToken(), page.translations[lang])
      selectedTranslations[page.id] = transltedPage.page
    }

    this.setState({
      selectedTranslations
    }, () => { })
  }

  closeOverlay = () => {
    this.setState({
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false,
    })
  }

  showDeleteConfirmation = (page) => {
    this.setState({
      deleteConfirmOverlay: true,
      pageToDelete: page.id,
      confirmMode: "delete"
    })
  }

  showPublishConfirmation = (page) => {
    this.setState({
      hideConfirmOverlay: true,
      pageToHide: page.id,
      confirmMode: (page.published) ? "hide" : "show"
    })
  }

  douplicatePage = async (pageToCopy) => {
    const page = await duplicatePage(getToken(), pageToCopy.id)
    const lang = this.props.match.params.lang
    this.props.history.push(`/${lang}/pages/${page.page.id}/content`);
  }

  translatePage = async (pageToTranslate, lang) => {
    const page = await translatePage(getToken(), pageToTranslate.id, lang)
    const sysLang = this.props.match.params.lang
    this.props.history.push(`/${sysLang}/pages/${page.page.id}/content`);
  }

  handlePublication = async () => {

    const index = this.state.pages.findIndex(s => s.id === this.state.pageToHide)
    const page = this.state.pages[index]

    page.published = !page.published

    this.setState({
      isUpdating: true,
    }, () => {
      updatePage(getToken(), page.id, page)
      .then(async updatedArticle => {
        this.setState({ hideConfirmOverlay: true })
        await this.loadData()
      })
      .catch(err => {
        this.setState({ isUpdating: false })
      })
    })
  }

  onDeleteConfirmation = async () => {

    const pageToDelete = this.state.pageToDelete

    if (pageToDelete) {
      this.setState({
        isUpdating: true,
      }, () => {
        deletePage(getToken(), pageToDelete)
        .then(async updatedArticle => {
          this.setState({ selectedTranslations: {} })
          await this.loadData()
        })
        .catch(err => {
          this.setState({ isUpdating: false })
        })
      })
    }
  }

  loadData = async () => {
    try {

      const limit = localStorage.getItem('pages_limit') || this.state.defaultLimit

      const offset = this.state.currentPage * limit

      const pages = await getPages(getToken(), this.state.lang, limit, offset, this.state.orderField, this.state.orderDirection, this.state.query)
      const pagesCount = Math.ceil(pages.count / limit)
      const syslanguages = await getLanguages(getToken())

      this.setState({
        ...this.state,
        limit: limit,
        pagesCount: pagesCount,
        count: pages.count,
        pages: pages.pages,
        deleteConfirmOverlay: false,
        hideConfirmOverlay: false,
        syslanguages: syslanguages.syslanguages,
        loading: false
      })
    } catch (error) {
      //FIXME NotFound
      console.log(error)
    }
  }

  async componentDidMount() {
    await this.loadData()
  }

  renderTitle = (page) => {
    if (page.title !== page.navigation_title) {
      // return `${page.title} <div className="dd-table__cell-info">${page.navigation_title}</div>`
      return <>{page.title} <div className="dd-table__cell-info">{page.navigation_title}</div></>
    } else {
      return page.title
    }
  }

  renderRow = (page, key) => {

    const { selectedTranslations } = this.state

    let p = page

    if (selectedTranslations[page.id]) {
      p = selectedTranslations[page.id]
    }

    return <tr key={key} className={(!p.published) ? 'is-inactive' : ''}>
      <td className="languages--table-view">
        <div className="publish-status no-label">
          <span className="publish-status--live">Live</span>
          <span className="publish-status--offline">Offline</span>
        </div>

        <div className="languages__wrapper">
          <LangSelector
            item={p}
            syslanguages={this.state.syslanguages}
            active={(this.state.pageActiveLanguageList === p.id) ? true : false}
            onActivate={() => this.toggleLanguageList(p)}
            onTranslate={(lang) => this.translatePage(p, lang)}
            onSelect={(lang) => this.selectTranslation(p, lang)}
          />
        </div>
      </td>

      <td className={(this.state.orderField === 'name') ? 'is-active' : ''}>{this.renderTitle(p)} </td>
      <td className={(this.state.orderField === 'updated_at') ? 'is-active' : ''}>{formatDate(p.updated_at)}</td>
      <td className={(this.state.orderField === 'created_at') ? 'is-active' : ''}>{formatDate(p.created_at)}</td>
      <td className={(this.state.orderField === 'user') ? 'is-active' : ''}>{p.user}</td>
      <td>{renderPageType(p.pagetype)}</td>
      <td>{p.views}</td>
      <td className="justify-content-center dd-table--actions">
        <div className="dd-table--actions_wrapper">

          <button type="submit" className="dd-btn dd-btn--danger dd-btn--icon-only mr-2" onClick={() => this.showDeleteConfirmation(p)}>
            <Icon icon="trash" />
          </button>

          <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only" onClick={() => this.showPublishConfirmation(p)}>
            {p.published
              ? < Icon icon="visibilty" />
              : < Icon icon="hidden" />
            }
          </button>
          <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only mr-2" onClick={() => this.douplicatePage(p)}>
            <Icon icon="duplicate" />
          </button>

          {p.pagetype === 'contact' &&
            <Link to={`/de/pages/${p.id}/meta`}>
              <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only">
                <Icon icon="edit" />
              </button>
            </Link>
          }

          {p.pagetype !== 'contact' &&
            <Link to={`/de/pages/${p.id}/content`}>
              <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only">
                <Icon icon="edit" />
              </button>
            </Link>
          }
        </div>
      </td>
    </tr>
  }

  render() {

    if (this.state.loading) {
      return <Loader />
    }

    return (
      <>
        <table className="table dd-table">
          <thead>
            <tr>
              <th width="90" className="languages--table-view">
                <div className="languages__wrapper">
                  <LangChooserSelect
                    lang={this.state.lang}
                    active={this.state.openLangChooser}
                    syslanguages={this.state.syslanguages}
                    onSelect={(lang) => this.setLang(lang)}
                    onActivate={this.toogleLangChooser}
                  />
                </div>
              </th>
              <TableColumnHeader
                name="Titel"
                field="name"
                width={"25%"}
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick} />
              <TableColumnHeader
                name="Geändert"
                field="updated_at"
                width={"144"}
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick} />
              <TableColumnHeader
                name="Erstellt"
                field="created_at"
                width={"144"}
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick} />
              <TableColumnHeader
                name="Autor"
                field="user"
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick} />
              <th scope="col">Seitentyp</th>
              <th scope="col">Abrufe</th>
              <th scope="col" width="160" className="dd-table--actions-th">
                <div className="dd-search-bar dd-search-bar--small dd-search-bar--white dd-table--search-filter">
                  <Searchbar
                    placeholder="Verfügbare Seiten filtern …"
                    icon="filter"
                    onChangeCallback={(e) => this.onFilterChange(e)}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.pages.map((page, key) => {
              return this.renderRow(page, key)
            })}
          </tbody>
        </table>

        {this.state.deleteConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.onDeleteConfirmation}
            mode={this.state.confirmMode}
          />
        }

        {this.state.hideConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.handlePublication}
            mode={this.state.confirmMode}
          />
        }

        <div className="float-right">
          <Pagination
            total={this.state.pagesCount}
            current={this.state.currentPage}
            onChange={this.handlePaginationClick}
            onSelect={this.handleSelectLimit}
            limit={this.state.limit}
          />
        </div>

        {(!this.state.showCreatePageDialog &&
          <div className="dd-btn__wrapper" onClick={(event) => this.toggleCreatePageDialog(event)}>
            <button className="dd-btn dd-btn--primary dd-btn--icon-only" >
              <Icon icon="plus" />
            </button>
            <span className="dd-btn__text">Neue Seite anlegen</span>
          </div>
        )}

        {(this.state.showCreatePageDialog &&
          <div className="dd-block-selector dd-block-selector--small is-open">
            <button
              className="dd-btn dd-btn--transparent dd-btn--icon-only dd-block-selector__close"
              onClick={(event) => this.toggleCreatePageDialog(event)}>
              <Icon icon="plus" />
            </button>
            <div className="dd-block-selector__content">
              <div className="dd-block-selector__tab-content">
                <div className="dd-block-selector__types">
                  <Link to={`/de/pages/new/page`}>
                    <button className="dd-btn--transparent dd-block-selector__button">
                      <span className="dd-block-selector__button-letter">S</span>
                      Seite
                    </button>
                  </Link>
                  <Link to={`/de/pages/new/employee`}>
                    <button className="dd-btn--transparent dd-block-selector__button">
                      <span className="dd-block-selector__button-letter">B</span>
                        Berufsträger
                      </button>
                  </Link>
                  <Link to={`/de/pages/new/lawfield`}>
                    <button className="dd-btn--transparent dd-block-selector__button">
                      <span className="dd-block-selector__button-letter">R</span>
                      Rechtsgebiet
                    </button>
                  </Link>
                  <Link to={`/de/pages/new/contact`}>
                    <button className="dd-btn--transparent dd-block-selector__button">
                      <span className="dd-block-selector__button-letter">K</span>
                      Kontaktformular
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        <Editbar
          hasPublish={false}
          hasPreview={false}
          hasSave={false}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
        />
      </>
    )
  }
}

PagesTable.contextType = AppContext
export default withRouter(PagesTable)
