import React from "react";

const BlockPreviewFiles = (props) => {

  const content = props.block[0] // FIXME

  return <div className="dd-block-preview dd-block-preview--files">
    <h4>{content.title}</h4>
    <div className="dd-block-preview__files-files">
      {content.files && content.files.map((file, key) => {
        return (
          <div key={key} className="dd-block-preview__files-file">
            <strong>{file.title}</strong><br />
            <div className="dd-block-preview__files-filepath">{file.fileName}</div>
          </div>
        )}
      )}
    </div>
  </div>
}

export default BlockPreviewFiles;
