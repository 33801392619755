import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { throttle } from "throttle-debounce"

import Icon from '../Shared/atom/Icon/Icon'
import Title from "../Shared/module/Title/Title"
import Searchbar from "../Shared/element/Searchbar/Searchbar"
import Pagination from '../Shared/element/Pagination/Pagination'
import TableColumnHeader from '../../layout/Tables/TableColumnHeader'
import DeleteConfirmOverlay from "../Shared/module/Overlay/DeleteConfirmOverlay"
import { TableColumnHeaderLang, TableColumnBodyLang } from "../Shared/element/LangTools/LangHelper"
import Loader from "../Shared/atom/Loader/Loader"

import { getJobs, getJob, translateJob, deleteJob, updateJob } from '../../data/api'
import { getToken, formatShortDate } from '../../lib/lib'

import { AppContext } from '../../data/ContextProvider'

class JobsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      loading: true,
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false,
      confirmMode: "delete",
      jobToDelete: 0,
      count: 0,
      pagesCount: 0,
      limit: 10,
      defaultLimit: 10,
      currentPage: 0,
      orderField: 'updated_at',
      orderDirection: 'desc',
      jobs: [],
      lang: 'de'
    }

    this.searchThrottled = throttle(500, this.loadData);
  }

  setLang = (lang) => {
    this.setState({ lang }, async () => { await this.loadData() })
  }

  selectTranslation = async (item, lang, key) => {
    const jobs = this.state.jobs
    const translatedItem = await getJob(getToken(), item.translations[lang])
    jobs[key] = translatedItem.job
    this.setState({ jobs })
  }

  translateItem = async (itemToTranslate, lang) => {
    const response = await translateJob(getToken(), itemToTranslate.id, lang)
    this.props.history.push(`${this.props.match.url}/${response.job.id}/content`);
  }

  onFilterChange = (event) => {
    this.setState({
      query: event.target.value,
      currentPage: 0
    }, () => {
      this.searchThrottled()
    })
  }

  closeOverlay = () => {
    this.setState({
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false,
    })
  }

  showDeleteConfirmation = (job) => {
    this.setState({
      deleteConfirmOverlay: true,
      jobToDelete: job.id,
      confirmMode: "delete"
    })
  }

  showPublishConfirmation = (job) => {
    this.setState({
      hideConfirmOverlay: true,
      jobToHide: job.id,
      confirmMode: (job.published) ? "hide" : "show"
    })
  }

  handlePublication = async () => {

    const index = this.state.jobs.findIndex(s => s.id === this.state.jobToHide)
    const job = this.state.jobs[index]

    job.published = !job.published

    this.setState({
      isUpdating: true,
    }, () => {
        updateJob(getToken(), job.id, job)
        .then(async updatedJob => {
          await this.loadData()
        })
        .catch(err => {
          this.setState({ isUpdating: false })
        })
    })
  }

  onDeleteConfirmation = async () => {

    const jobToDelete = this.state.jobToDelete

    if (jobToDelete) {
      this.setState({
        isUpdating: true,
      }, () => {
          deleteJob(getToken(), jobToDelete)
          .then(async updatedJob => {
            await this.loadData()
          })
          .catch(err => {
            this.setState({ isUpdating: false })
          })
      })
    }
  }

  handlePaginationClick = async (e, page) => {
    this.setState(
      {
        ...this.state,
        currentPage: page
      },
      async () => {
        await this.loadData()
      }
    )
  }

  handleSelectLimit = (limit) => {
    localStorage.setItem('jobs_limit', limit);
    this.setState({ limit, currentPage: 0 }, async () => {
      await this.loadData()
    })
  }

  onTableColumnClick = (column) => {
    const { orderDirection } = this.state

    this.setState(
      {
        orderField: column,
        orderDirection: orderDirection === 'desc' ? 'asc' : 'desc'
      },
      async () => {
        await this.loadData()
      }
    )
  }

  loadData = async () => {
    const limit = localStorage.getItem('jobs_limit') || this.state.defaultLimit

    const offset = this.state.currentPage * limit

    const jobs = await getJobs(
      getToken(),
      this.state.lang,
      limit,
      offset,
      this.state.orderField,
      this.state.orderDirection,
      this.state.query
    )

    const pagesCount = Math.ceil(jobs.count / limit)

    this.setState({
      ...this.state,
      limit: limit,
      loading: false,
      pagesCount: pagesCount,
      count: jobs.count,
      jobs: jobs.jobs,
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false
    })
  }

  componentDidMount() {
    this.loadData()
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }
    console.log("......",  this.state.jobs)

    const showPagination = (this.state.count > this.state.limit) ? true : false;

    return (
      <React.Fragment>
        <Title headline="Stellen im DIRO-Netzwerk" />
        <table className="table dd-table">
          <thead>
            <tr>
              <TableColumnHeaderLang
                lang={this.state.lang}
                setLang={this.setLang}
              />
              <TableColumnHeader
                width="300px"
                name="Kanzlei"
                field="name"
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick}
              />
              <TableColumnHeader
                width="120px"
                name="Erstellt"
                field="created_at"
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick}
              />
              <TableColumnHeader
                width="120px"
                name="Geändert"
                field="updated_at"
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick}
              />
              <TableColumnHeader
                name="Ort"
                field="city"
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick}
              />
              <th scope="col">Ansprechpartner</th>
              <th scope="col">Abrufe</th>
              <th scope="col" className="dd-table--actions-th">
                <div className="dd-search-bar dd-search-bar--small dd-search-bar--white dd-table--search-filter">
                  <Searchbar
                    placeholder="Verfügbare Jobs filtern …"
                    icon="filter"
                    onChangeCallback={(e) => this.onFilterChange(e)}
                  />
                </div>
              </th>
              {/*<th scope="col" className="dd-table--actions-th">Actions</th>*/}
            </tr>
          </thead>
          <tbody>
            {this.state.jobs.map((job, key) => {
              return (
                <tr key={key} className={(!job.published) ? 'is-inactive' : ''}>
                  <TableColumnBodyLang
                    item={job}
                    translateItem={this.translateItem}
                    selectTranslation={(page, lang) => {
                      this.selectTranslation(page, lang, key)
                    }}
                  />
                  <td className={(this.state.orderField === 'name') ? 'is-active' : ''}>{job.title}</td>
                  <td className={(this.state.orderField === 'created_at') ? 'is-active' : ''}>{formatShortDate(job.updated_at)}</td>
                  <td className={(this.state.orderField === 'updated_at') ? 'is-active' : ''}>{formatShortDate(job.created_at)}</td>
                  <td className={(this.state.orderField === 'city') ? 'is-active' : ''}>{job.company.hq.city.title_de}</td>
                  <td>{job.company.name}</td>
                  <td>{job.views}</td>
                  <td className="justify-content-center dd-table--actions">
                    <div className="dd-table--actions_wrapper">
                      <button type="submit" className="dd-btn dd-btn--danger dd-btn--icon-only mr-2" onClick={() => this.showDeleteConfirmation(job)}>
                        <Icon icon="trash" />
                      </button>
                      <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only" onClick={() => this.showPublishConfirmation(job)}>
                        {job.published
                          ? <Icon icon="visibilty" />
                          : <Icon icon="hidden" />
                        }
                      </button>
                      <Link to={`/de/jobs/${job.id}/content`}>
                        <button
                          type="submit"
                          className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only"
                        >
                          <Icon icon="edit" />
                        </button>
                      </Link>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>

        {this.state.deleteConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.onDeleteConfirmation}
            mode={this.state.confirmMode}
          />
        }
        {this.state.hideConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.handlePublication}
            mode={this.state.confirmMode}
          />
        }

        {showPagination &&
          <div className="float-right">
            <Pagination
              total={this.state.pagesCount}
              current={this.state.currentPage}
              onChange={this.handlePaginationClick}
              onSelect={this.handleSelectLimit}
              limit={this.state.limit}
            />
          </div>
        }
        <Link to={`/de/jobs/new`} className="dd-btn__wrapper">
          <button className="dd-btn dd-btn--primary dd-btn--icon-only" >
            <Icon icon="plus" />
          </button>
          <span className="dd-btn__text">Neue Stellenanzeige anlegen</span>
        </Link>

      </React.Fragment>
    )
  }
}

JobsTable.contextType = AppContext
export default withRouter(JobsTable)
