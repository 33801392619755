import React from "react";

const BlockPreviewAccordions = (props) => {

  const content = props.block[0] // FIXME

  return <div className="dd-block-preview dd-block-preview--accordion">
    <h4 className="dd-block-preview__accordion-headline">{content.title}</h4>
    {content.items && content.items.map((item, key) => {
      return (
        <div key={key} className="dd-block-preview__accordion-item">
          <strong>{item.title}</strong>
        </div>
      )
    }
    )}
  </div>
}

export default BlockPreviewAccordions;
