import React from "react";

function LoginLayout({ children }) {
  return (
    <React.Fragment>
      <div className={"content login-content"}>
        {children}
      </div>

    </React.Fragment>
  );
}

export default LoginLayout;
