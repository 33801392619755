import React from "react";

const BlockPreviewImage = (props) => {

  const content = props.block[0] // FIXME

  return <div className="dd-block-preview dd-block-preview--image">
    <figure className={`dd-block-preview__position--${content.imagePosition}`}>
      <div className="dd-block-preview__image">
        <img className="dd-image-picker__image" src={props.imagePath} alt="" width="260px" height="150px"/>
        <div className="dd-block-preview__image-source">{content.imageSource}</div>
      </div>
    </figure>
    <div className="dd-block-preview__image-caption">{content.imageCaption}</div>
  </div>
}

export default BlockPreviewImage;
