import React from "react";
import { Link, withRouter } from "react-router-dom";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";
import { ImagePreviewBlock } from "../../element/ImagePreview/ImagePreview";
import Overlay from "../../element/Overlay/Overlay";
import ImageSelector from "../ImageSelector/ImageSelector";
import BlockPreviewConsentDiroForms from './BlockPreviews/ConsentDiroForms';
import BlockActions from "./BlockActions";

class BlockConsentDiroFormsEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      preview: true,
      imageOverlayActive: false,
    }
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  onChange = (event) => {
    const content = this.state.content
    content[event.target.name] = event.target.value
    this.saveContent(content)
  }

  openImageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      imageOverlayActive: true
    })
  }

  closeImageSelectorOverlay = () => {
    this.setState({
      imageOverlayActive: false,
    })
  }

  handleImageSelect = (file, format) => {
    const content = this.state.content
    content.imagePath = file.file_path
    content.imageID   = file.id
    content.imageFormat  = format

    this.saveContent(content)
    this.closeImageSelectorOverlay()
  }

  handleImageDelete = () => {
    const content = this.state.content
    content.imageID = 0
    content.imageFormat = ""
    content.imagePath = ""
    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  componentDidMount() {
    if (this.props.initData) {

      const content = this.props.initData[0]
      const preview = this.props.preview

      this.setState({
        preview,
        content
      })
    }
  }

  render() {
    const lang = this.props.match.params.lang

    return (
      <React.Fragment>
        {
          this.state.preview
          ? <div className="dd-block dd-block--default" onClick={() => this.togglePreviewEditMode()}>
            <div className="dd-block__preview">
              <BlockPreviewConsentDiroForms block={this.state.content} />
            </div>
          </div>
          :
          <div className="dd-block dd-block--default dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <Icon icon="diro-webformular" /> Diro-Webformular
              </div>

              <div className="dd-block__edit-section">
                <div className="form-group">
                  <label className="textfield__label">Iframe URL</label>
                  <Textfield
                    name="url"
                    type="text"
                    isRequired={false}
                    label=""
                    placeholder="Iframe URL hier eintragen"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.url}
                    errorHandler={this.props.errorHandler}
                  />
                </div>

                <div className="form-group">
                  <label className="textfield__label">Iframe Höhe</label>
                  <Textfield
                    name="height"
                    type="text"
                    isRequired={false}
                    label=""
                    placeholder="Iframe Höhe hier eintragen"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.height}
                    errorHandler={this.props.errorHandler}
                  />
                </div>
              </div>

              <div className="dd-block__edit-section">
                <h5>Datenschutz</h5>
                <p className="hint-text">
                  Dieses Modul bindet einen Inhalte mithilfe eines externen Dienstes ein, der erst nach
                  Zustimmung des Nutzers geladen werden darf. Erteilt der Nutzer keine globale Zustimmung im
                  entsprechenden Popup beim ersten Seitenaufruf, muss der Aufruf des Inhalts blockiert werden.
                  Anstelle des Iframes wird dann ein Platzhalter-Bild und ein Button zum Laden des Iframes angezeigt (Zwei-Klick-Lösung).
                  Die Texte für Anfragen zur Zustimmung und den Button können Sie im Admin-Bereich unter <Link to={`/${lang}/admin/tracking#${this.state.content.type}`}>Tracking und externe Dienste</Link> bearbeiten.
                </p>
                <div className="row">
                  <div className="col">

                  <label className="textfield__label">Platzhalter-Bild vor Anzeige des Inhaltes</label>
                    <p className="hint-text">Dieses Bild wird angezeigt, wenn der Besucher keine Zustimmung zur Anzeige des externen Inhaltes erteilt hat.</p>

                    <div className="dd-image-picker">
                      {this.state.content.imagePath ?
                        <ImagePreviewBlock
                          imagePath={this.state.content.imagePath}
                          imageId={this.state.imageId}
                          handleImageDelete={this.handleImageDelete}
                          openImageSelectorOverlay={this.openImageSelectorOverlay}
                        />
                        :
                        <button className="dd-btn dd-btn--primary dd-image-picker__choose" onClick={(e) => this.openImageSelectorOverlay(e)}>
                          Bild auswählen
                        </button>
                      }
                    </div>

                    {this.state.imageOverlayActive &&
                      <Overlay closeOverlayCallback={() => this.closeImageSelectorOverlay()}>
                        <ImageSelector
                          selectImageCallback={(file, format) => this.handleImageSelect(file, format)}
                          fileTypes={["image"]}
                          selectedAssetId={this.state.content.imageID || ''}
                          selectedFormat={this.state.content.imageFormat || ''}
                          allowedFormats={[
                            "original",
                            "five_by_two",
                            "sixteen_by_nine"
                          ]}
                        />
                      </Overlay>
                    }
                  </div>
                </div>
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}

export default withRouter(BlockConsentDiroFormsEdit);
