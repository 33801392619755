import React from "react";
import { withRouter, Route } from "react-router-dom";

import ArticlesTable from "../components/Articles/ArticlesTable";
import ArticleNew from "../components/Articles/ArticleNew";
import ArticleContent from "../components/Articles/ArticleContent";


const Articles = () => {
  return (
    <div className="tab-content" id="nav-tabContent">
      <Route exact path="/:lang/articles">
        <ArticlesTable />
      </Route>
      <Route exact path="/:lang/articles/new">
        <ArticleNew />
      </Route>
      <Route exact path="/:lang/articles/:articleid/:tabs">
        <ArticleContent />
      </Route>
    </div>
  )
}

export default withRouter(Articles);
