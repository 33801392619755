import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import EventMetadata from './EventMetadata'
import EventPublishing from './EventPublishing'
import EventDetails from './EventDetails'
import EventEditTabs from './EventEditTabs'
import EventParticipants from "./EventParticipants";

import Textfield from '../Shared/atom/Textfield/Textfield'
import TagList from '../Shared/atom/TagList/TagList'
import Blocks from '../Shared/module/Blocks/Blocks'
import Loader from '../Shared/atom/Loader/Loader'
import Editbar from '../Shared/element/Editbar/Editbar'
import { SidebarLang } from '../Shared/element/LangTools/LangHelper'

import { AppContext } from '../../data/ContextProvider'
import {
  getEvent,
  updateEvent,
  getEventlocation,
  createEventlocation,
  updateEventlocation,
  getCompany
} from '../../data/api'
import { getToken, getCurrentOpenTab } from '../../lib/lib'

class EventContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      eventID: 0,
      loading: true,
      event: {},
      contacts: {}
    }
  }

  errorHandler = (hasError = false) => {
    this.setState({ hasError })
  }

  onChange = (name, value) => {
    const event = this.state.event
    event[name] = value
    this.setState({ event })
  }

  onChangeEventLocation = (name, value) => {
    const event = this.state.event
    event.eventlocation[name] = value
    this.setState({ event })
  }

  onChangeExternalOrganizer = (name, value) => {
    const event = this.state.event
    event[name] = value
    this.setState({ event })
  }

  onChangeExternalLocation = (name, value) => {
    const event = this.state.event
    event[name] = value
    this.setState({ event })
  }

  onChangeWebinar = (name, value) => {
    const event = this.state.event
    event[name] = value
    event.is_external_location = false
    this.setState({ event })
  }

  setEventlocation = async () => {
    const event = this.state.event

    if (!event.is_external_location) {
      return event.eventlocation_id
    }

    if (event.eventlocation_id) {
      updateEventlocation(
        getToken(),
        event.eventlocation_id,
        event.eventlocation
      )
        .then((updatedEventlocation) => {})
        .catch((err) => {
          console.log(err)
        })

      return event.eventlocation_id
    }

    const eventlocation = await createEventlocation(
      getToken(),
      event.eventlocation
    )

    return eventlocation.eventlocation.id
  }

  onSubmit = async () => {
    const event = this.state.event
    event.eventlocation_id = await this.setEventlocation()
    event.lawfield_ids = event.lawfields.map((tag) => tag.id)
    event.federalstate_ids = event.federalstates.map((tag) => tag.id)
    event.country_ids = event.countries.map((tag) => tag.id)
    event.keyword_ids = event.keywords.map((tag) => tag.id)
    event.targetgroup_ids = event.targetgroups.map((tag) => tag.id)
    event.contact_ids = event.contacts.map((tag) => tag.id)
    event.company_sites = []

    if (event.organizer_id === 0) event.organizer_id = this.state.company.hq.id
    if (event.eventlocation_company_site_id === 0) event.eventlocation_company_site_id = this.state.company.hq.id

    updateEvent(getToken(), event.id, event)
      .then((updatedEvent) => {
        this.loadData()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  loadData = async () => {
    try {
      const company = await getCompany(getToken(), this.context.company.id)
      const event = await getEvent(getToken(), this.state.eventID)
      const blocksJSONData = event.event.content || '[]'
      event.event.content = JSON.parse(blocksJSONData)


      let eventPath = event.event.path

      if (!event.event.published) {
        eventPath += '?p=' + event.event.preview_token
      }

      const preview =
        'http://' +
        company.company.domain +
        '/' +
        event.event.lang +
        '/' +
        eventPath

      event.event.eventlocation = {}
      if (event.event.eventlocation_id) {
        const eventlocation = await getEventlocation(
          getToken(),
          event.event.eventlocation_id
        )
        event.event.eventlocation = eventlocation.eventlocation
      }

      this.setState({
        ...this.state,
        company: company.company,
        event: event.event,
        contacts: company.company.employees,
        previewURL: preview,
        loading: false
      })
    } catch (error) {
      console.log(error)
      //FIXME NotFound
    }
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params) {
      this.setState(
        {
          ...this.state,
          eventID: this.props.match.params.event_id
        },
        async () => {
          await this.loadData()
        }
      )
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    const currentOpenTab = getCurrentOpenTab(this.props.match.params.tabs)

    return (
      <div className="tab-pane fade show active">
        <div className="card-body card-body--standard dd-background dd-background--no-padding-bottom dd-background--white">
          <SidebarLang
            item={this.state.event}
            controller="events"
            itemType="event"
          />

          <div className="card-body__content w-75 ml-auto mr-auto">
            <div className="form-group">
              <Textfield
                name="title"
                type="text"
                isRequired={true}
                handleChange={(e) => this.onChange('title', e.target.value)}
                defaultValue={this.state.event.title}
                errorHandler={this.errorHandler}
              />
            </div>

            <div className="form-group">
              <textarea
                className="form-control"
                name="content_pre"
                defaultValue={this.state.event.description}
                onChange={(e) => this.onChange('description', e.target.value)}
                placeholder="Einleitungstext zum Event."
              />
            </div>

            <div className="form-group">
              <TagList
                indexLabel="full_name"
                name="contacts"
                data={this.state.event.contacts || []}
                availableData={this.state.contacts}
                label="Ansprechpartner"
                onUpdate={(assignedTags) =>
                  this.onChange('contacts', assignedTags)
                }
              />
            </div>
          </div>
          <EventEditTabs activeTab={currentOpenTab} />
        </div>

        <div className="card-body card-body--standard">
          <div className="card-body__content w-75 ml-auto mr-auto">
            {currentOpenTab === 0 && (
              <div className="form-group">
                <Blocks
                  handleChange={(value) => this.onChange('content', value)}
                  blocks={this.state.event.content}
                  errorHandler={this.errorHandler}
                />
              </div>
            )}
            {currentOpenTab === 1 && (
              <EventDetails
                event={this.state.event}
                onChange={this.onChange}
                onChangeWebinar={this.onChangeWebinar}
                onChangeEventLocation={this.onChangeEventLocation}
                onChangeExternalLocation={this.onChangeExternalLocation}
                onChangeExternalOrganizer={this.onChangeExternalOrganizer}
              />
            )}
            {currentOpenTab === 2 && (
              <EventMetadata
                event={this.state.event}
                onChange={this.onChange}
                errorHandler={this.errorHandler}
              />
            )}
            {currentOpenTab === 3 && (
              <EventPublishing
                event={this.state.event}
                onChange={this.onChange}
              />
            )}
            {currentOpenTab === 4 && (
                <EventParticipants event={this.state.event}/>
            )}
          </div>
        </div>
        <Editbar
          previewURL={this.state.previewURL}
          hasPublish={false}
          hasPreview={true}
          hasError={this.state.hasError}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
        />
      </div>
    )
  }
}

EventContent.contextType = AppContext
export default withRouter(EventContent)
