import React from "react";

import Icon from "../../atom/Icon/Icon";
import HeadlinePreview from './BlockPreviews/Headline';
import Loader from "../../../Shared/atom/Loader/Loader";
import BlockSectionSettings from './BlockSectionSettings';
import RadioButton from "../../atom/RadioButton/RadioButton";

import { createEditor } from "./util";
import { createID } from "../../../../lib/lib";
import BlockActions from "./BlockActions";

class BlockDefault extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      editor: {},
      editorSetup: false,
      preview: true,
      loading: true,
      showSettings: false,
      headlineType: "h2"
    }
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  onChangeType = (event) => {
    const content = {...this.state.content}
    content.headlineType = event.target.value

    const newContent = []
    let tmp = {}

    content.editor.content.forEach(element => {
      tmp = {...element}
      tmp.type = event.target.value
      newContent.push(tmp)
    });

    content.editor.content = newContent
    content.editor.editor  = createEditor(createID(), event.target.value, this.onChangeEditor, content.editor.content)

    this.saveContent(content)
  }

  onChangeEditor = (key, value) => {
    const content = this.state.content
    content.editor.content = value
    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  adjustContent = (content) => {
    if (!content.editor) {
      content.editor = {
        editor: "",
        content: [{
          type: "headline",
          children: [
            { text: "h2" }
          ],
        }],
        type: "",
      }
    }

    // Issue #212
    let contentInput = []
    if (!Array.isArray(content.editor.content)) {
      contentInput = [content.editor.content]
    } else {
      contentInput = content.editor.content
    }

    content.headlineType = contentInput[0].type

    // init editor
    content.editor.editor = createEditor(this.props.index, this.props.type, this.onChangeEditor, contentInput)

    return content
  }

  componentDidMount() {
    if (this.props.initData) {
      const preview = this.props.preview

      let content = this.props.initData[0]
      content = this.adjustContent(content)

      this.setState({ content, loading: false, editorSetup: true, preview })
    }
  }

  renderBlockContent = () => {
    if (this.state.editorSetup) {
      return createEditor(this.props.index, this.props.type, this.onChangeEditor, this.state.content.editor.content)
    } else {
      return []
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    return (
      <>
        {this.state.preview
          ? <div className="dd-block dd-block--default" onClick={() => this.togglePreviewEditMode()}>
            <div className="dd-block__preview">
              <HeadlinePreview block={this.state.content} />
            </div>
          </div>
          :
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-headline" /> Überschrift
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }

              <div className="dd-block__edit-section">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <label className="radio-button__group-label text-nowrap ">Darstellung auswählen</label>
                    <RadioButton
                      name={`headlineType-${this.props.index}`}
                      label="XL (H2)"
                      value="h2"
                      handleChange={this.onChangeType}
                      selected={this.state.content.headlineType === "h2"}
                    />
                    <RadioButton
                      name={`headlineType-${this.props.index}`}
                      label="L (H3)"
                      value="h3"
                      handleChange={this.onChangeType}
                      selected={this.state.content.headlineType === "h3"}
                    />
                    <RadioButton
                      name={`headlineType-${this.props.index}`}
                      label="M (H4)"
                      value="h4"
                      handleChange={this.onChangeType}
                      selected={this.state.content.headlineType === "h4"}
                    />
                  </div>
                </div>

                <div className="form-group">
                  {this.renderBlockContent()}
                </div>
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
        }
      </>
    )
  }
}

export default BlockDefault;
