import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";
import SimpleSelectfield from "../../atom/Selectfield/SimpleSelectfield";

import Overlay from "../../element/Overlay/Overlay";
import PageSelector from "../PageSelector/PageSelector";

import { getToken } from "../../../../lib/lib";
import { getPage, getArticle, getProduct } from "../../../../data/api";

class BlockCompetenceEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      item: {},
      preview: true,
      pageOverlayActive: false,
    }
  }

  togglePreviewEditMode = async (e) => {
    e.preventDefault()

    const newState = !this.state.preview

    if (!newState) { //open

      if (this.props.item.pageID) {
        if (this.props.item.pageType === 'page') {
          await this.loadPage(this.props.item.pageID)
        } else if (this.props.item.pageType === 'article') {
          await this.loadArticle(this.props.item.pageID)
        } else if (this.props.item.pageType === 'product') {
          await this.loadProduct(this.props.item.pageID)
        }
      }

      if (this.props.item.articleID) {
        await this.loadArticle(this.props.item.articleID)
      }
    }

    this.setState({ preview: newState})
  }

  loadPage = async (pageID) => {
    const page = await getPage(getToken(), pageID)
    this.setState({
      page: page.page,
    }, () => {
    })
  }

  loadProduct = async (pageID) => {
    const page = await getProduct(getToken(), pageID)
    this.setState({
      page: page.product,
    }, () => {
    })
  }

  loadArticle = async (articleID) => {
    const page = await getArticle(getToken(), articleID)
    this.setState({
      page: page.article,
    }, () => {
    })
  }

  onChange = (event) => {
    const item = this.props.item
    item[event.target.name] = event.target.value
    this.props.onChange(item)
  }

  onSelectIcon = (value) => {
    const item = this.props.item
    item.icon  = value
    this.props.onChange(item)
  }

  openPageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      pageOverlayActive: true
    })
  }

  closePageSelectorOverlay = () => {
    this.setState({
      pageOverlayActive: false,
    })
  }

  handlePageSelect = (page) => {
    const item = this.props.item

    if (page.id) {
      item.pageID    = page.id
      item.pageTitle = page.title
      item.pageType  = page.content_type
      item.buttonURL = ""
    } else {
      item.pageID = ""
      item.articleID = ""
      item.buttonURL = page.buttonURL
    }

    item.buttonTarget = page.buttonTarget

    this.props.onChange(item)
    this.closePageSelectorOverlay()
    this.setState({page: page})
  }

  renderSeletedItemLabel = () => {

    if (this.props.item.buttonURL) {
      return this.props.item.buttonURL
    } else if (this.props.item.pageID && this.state.page && this.state.page.title) {
      return `${this.state.page.title} (${this.state.page.navigation_title})`
    } else if (this.props.item.articleID && this.state.page && this.state.page.title) {
      return `${this.state.page.title}`
    }
  }

  render() {

    return (
      <>
        {this.state.preview ?
          <div className="dd-mini-block__preview">
            <div className="dd-mini-block__preview-item" onClick={(e) => this.togglePreviewEditMode(e)}>
              <div className="dd-mini-block__preview-image"></div>
              <div className="dd-mini-block__preview-title">{this.props.item.title}&nbsp;</div>
            </div>
          </div>

          :

          <div className="dd-mini-block__edit">
            <div className="dd-mini-block__close">
              <button className="dd-btn dd-btn--icon-only" onClick={(e) => this.togglePreviewEditMode(e)}>
                <Icon icon="close" />
              </button>
            </div>
            <div className="dd-block__edit-section">
              <div className="form-group" >
                <SimpleSelectfield
                  label="Icon"
                  name="icon"
                  firstOptionValue=""
                  firstOptionLabel="Bitte auswählen"
                  indexLabel="icon"
                  optionLabel="title_de"
                  options={this.props.advisoryfields}
                  onSelect={(value) => this.onSelectIcon(value)}
                  selected={this.props.item.icon}
                />

                <Textfield
                  name="title"
                  type="text"
                  isRequired={false}
                  label="Titel"
                  placeholder="Titel eintragen"
                  handleChange={this.onChange}
                  defaultValue={this.props.item.title}
                />
                <Textfield
                  name="text"
                  type="text"
                  isRequired={false}
                  label="Text"
                  placeholder="Kurzbeschreibung eintragen"
                  handleChange={this.onChange}
                  defaultValue={this.props.item.text}
                />
              </div>

              <div className="form-group">

                <div className="dd-link-selector">
                  <div className="dd-link-selector__link">{this.renderSeletedItemLabel()}</div>
                  <button className="dd-btn dd-btn--primary dd-link-selector__button" onClick={(e) => this.openPageSelectorOverlay(e)}>
                    Linkziel ändern
                    </button>
                </div>
                {this.state.pageOverlayActive &&
                  <Overlay closeOverlayCallback={() => this.closePageSelectorOverlay()}>
                    <PageSelector
                      content={this.props.item}
                      selectPageCallback={(page) => this.handlePageSelect(page)}
                    />
                  </Overlay>
                }
              </div>

            </div>
          </div>
        }
      </>
    )
  }
}

export default BlockCompetenceEdit
