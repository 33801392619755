
import moment from 'moment'
import 'moment-timezone'

import {
  login,
  reloginUser } from "../data/api";

import { addZeroToNumber } from '../helpers/utils'

const DIRO_JWT_TOKEN = 'diro_token'
const DIRO_UID       = 'diro_uid'

export async function tryToLogInUser(email, password) {
  try {
    const response = await login(email, password)

    saveToken(response.authorization)
    saveUserData(response.user)

    return response.user

  } catch (error) {
    throw (error)
  }
}

export async function relogintUser(token) {
  try {
    const response = await reloginUser(token)
    saveUserData(response.user)
    return response.user
  } catch (error) {
    throw (error)
  }
}

export async function checkToken() {
  try {
    const token = getToken()
    if (!token) return false //kein Token => Login-Maske
    //token liegt vor, User nicht eingeloggt => versuchen einzuloggen
    return await relogintUser(token)
  } catch (error) {
    throw (error)
  }
}

export function getToken() {
  return localStorage.getItem(DIRO_JWT_TOKEN);
}

function saveToken(token) {
  localStorage.setItem(DIRO_JWT_TOKEN, token);
}

function saveUserData(user) {
  localStorage.setItem(DIRO_UID, user.id);
}

export function deleteUserData() {
  localStorage.removeItem(DIRO_JWT_TOKEN)
  localStorage.removeItem(DIRO_UID)
}

export function urlForPage(page) {
  return `https://${page.company.domain}/${page.path}`
}

export function createID() {
  return Math.random().toString(32).slice(2)
}

export function imagePathForFormat (format) {
  const formats = []
  formats['original'] = 'file_path'
  formats['one_by_one']      = 'one_by_one_file_path'
  formats['two_by_three']    = 'two_by_three_file_path'
  formats['three_by_two']    = 'three_by_two_file_path'
  formats['sixteen_by_nine'] = 'sixteen_by_nine_file_path'
  formats['five_by_two']     = 'five_by_two_file_path'

  if (!(format in formats)) {
    return 'file_path'
  } else {
    return formats[format]
  }
}

export function filesFormatsList() {
  return ["word", "pdf", "excel"]
}

//--------------- Datetime -----------------------

export function formatDate(dateString) {
  const timestamp = Date.parse(dateString)
  const date = new Date(timestamp)

  if (date) {
    const yyyy = date.getFullYear().toString()
    const mm = addZeroToNumber((date.getMonth() + 1).toString())
    const dd = addZeroToNumber(date.getDate().toString())

    const hours = addZeroToNumber(date.getHours().toString())
    const minutes = addZeroToNumber(date.getMinutes().toString())

    return dd + '.' + mm + '.' + yyyy + ' ' + hours + ':' + minutes
  }
  return ''
}

export function formatShorDateObj(date) {
  if (date) {
    const yyyy = date.getFullYear().toString()
    const mm = addZeroToNumber((date.getMonth() + 1).toString())
    const dd = addZeroToNumber(date.getDate().toString())

    return yyyy + '-' + mm + '-' + dd
  }
  return ''
}

export function formatShortDate(dateString) {
  const timestamp = Date.parse(dateString)
  const date = new Date(timestamp)

  if (date) {
    const yyyy = date.getFullYear().toString()
    const mm = addZeroToNumber((date.getMonth() + 1).toString())
    const dd = addZeroToNumber(date.getDate().toString())

    return dd + '.' + mm + '.' + yyyy
  }
  return ''
}

export function dateToRailsDateString(dateObj) {
  //2020-04-12T11:49:23.233Z
  //2020-04-19T12:00:00+02:00
  const RAILS_FORMAT = "YYYY-MM-DDTHH:mm:ssZ"
  return moment(dateObj).format(RAILS_FORMAT)
}

//--------------- Helper -----------------------

export function getCurrentOpenPageTab(tab) {
  const mapping = {
    "content": 0,
    "meta": 1,
    "contact": 2,
    "publishing": 3,
    "network": 4,
  }

  return mapping[tab] || 0
}

export function getCurrentOpenTab(tab) {
  const mapping = {
    "content": 0,
    "details": 1,
    "meta": 2,
    "publishing": 3,
    "contact": 4,
    "participants": 4,
  }

  return mapping[tab] || 0
}

export function renderPageType(type) {
  const mapping = {
    "page": "Seite",
    "employee": "Berufsträger",
    "lawfield": "Rechtsgebiet",
    "contact": "Kontaktformular",
  }

  return mapping[type] || "Seite"
}

export function formatBool(value, positiveLabel="ja", negativeLabel="nein") {
  return (value) ? positiveLabel : negativeLabel
}

export function checkPasswordPolicy(password) {

  if (password.length < 8) {
    return false
  }

  if (!password.match(/[A-Z]+/)) {
    return false
  }

  if (!password.match(/[0-9]+/)) {
    return false
  }

  // if (!password.match(/[$@#&!]+/)) {
  //   return false
  // }

  return true
}
