import React from "react";

const BlockPreviewHero = (props) => {

  const content = props.block[0] // FIXME
  let heroStyles = {}

  if (content.mediaType === 'image') {
    let backgroundImageUrl = (content.imagePath) ? content.imagePath : '';
    heroStyles = { backgroundImage: 'url(' + backgroundImageUrl + ')' };
  }

  return (
    <div className="dd-block-preview dd-block-preview--hero" style={heroStyles}>
      {content.mediaType === 'mp4' && (
        <video src={content.videoPath} autoPlay loop muted playsInline className="dd-block-preview__hero-video"></video>
      )}
      <div className="dd-block-preview__hero-content">
        <div className="dd-block-preview__hero-content-kicker">{content.kicker}</div>
        <h1>{content.title}</h1>
      </div>
    </div>
  )
}

export default BlockPreviewHero;
