import React from "react";

const BlockTableOfContentPreview = (props) => {

  // const content = props.block[0] // FIXME


  return <div className="dd-block-preview dd-block-preview--articles">
    <h2 className="pb-2">Inhaltsverzeichnis</h2>
  </div>
}

export default BlockTableOfContentPreview;
