import React from "react";

const BlockPreviewNoticeBar = (props) => {

  // const content = props.block

  return <div className="dd-block-preview">
    <h4>HINWEISLEISTE</h4>
  </div>
}

export default BlockPreviewNoticeBar;
