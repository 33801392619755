import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import ArticlesEditTabs from "./ArticlesEditTabs";
import ArticleMetadata from "./ArticleMetadata";
import ArticlePublishing from "./ArticlePublishing";

import Textfield from "../Shared/atom/Textfield/Textfield";
import TagList from '../Shared/atom/TagList/TagList'
import Blocks from "../Shared/module/Blocks/Blocks";
import Loader from "../Shared/atom/Loader/Loader";
import Editbar from "../Shared/element/Editbar/Editbar";
import Checkbox from "../Shared/atom/Checkbox/Checkbox";

import { SidebarLang } from "../Shared/element/LangTools/LangHelper";

import { AppContext } from "../../data/ContextProvider";
import { getArticle, updateArticle, getCompany } from "../../data/api";
import { getToken, getCurrentOpenPageTab } from "../../lib/lib";


class ArticleContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      articleID: 0,
      loading: true,
      article: {},
      authors: {},
      blockContent: []
    }
  }

  errorHandler = (hasError=false) => {
    this.setState({ hasError })
  }

  onChange = (name, value) => {
    const article = this.state.article
    article[name] = value
    this.setState({ article })
  }

  onItemChange = (article) => {
    this.setState({ article })
  }

  onBlocksChange = (blockContent) => {
    const article = this.state.article
    article.content = blockContent
    this.setState({ article, blockContent })
  }

  onShowImportedContentChange = (e) => {
    const article = this.state.article
    article.show_imported_content = !article.show_imported_content

    this.setState({ article }, () => {
    })
  }

  publishOnNetwork = (e) => {
    const article = this.state.article
    article.published_on_network = !article.published_on_network
    this.setState({ article })
  }

  releaseOnNetwork = (e) => {
    const article = this.state.article
    article.released_on_network = !article.released_on_network
    this.setState({ article })
  }

  onSubmit = () => {
    const article = this.state.article
    article.lawfield_ids = article.lawfields.map(tag => tag.id)
    article.federalstate_ids = article.federalstates.map(tag => tag.id)
    article.country_ids  = article.countries.map(tag => tag.id)
    article.keyword_ids  = article.keywords.map(tag => tag.id)
    article.author_ids = article.authors.map((tag) => tag.id)

    updateArticle(getToken(), article.id, article)
    .then(updatedArticle => {
      this.loadData()
    })
    .catch(err => {
      console.log(err)
    })
  }

  onRepublish = () => {
    const article = this.state.article
    article.reset_publishing_date = true
    this.setState({ article }, () => {
      this.onSubmit()
    })
  }

  loadData = async () => {
    try {
      const company = await getCompany(getToken(), this.context.company.id)
      const article = await getArticle(getToken(), this.state.articleID)
      const blocksJSONData = article.article.content || "[]"

      let articlePath = article.article.path

      if (!article.article.published) {
        articlePath += "?p=" + article.article.preview_token
      }

      const preview = 'http://' + company.company.domain + '/' + article.article.lang + '/' + articlePath

      this.setState({
        ...this.state,
        article: article.article,
        authors: company.company.employees,
        blockContent: JSON.parse(blocksJSONData),
        previewURL: preview,
        loading: false
      })
    } catch (error) {
      //FIXME NotFound
    }
  }

  async componentDidMount() {
    if (this.props.match && this.props.match.params) {
      this.setState({
        ...this.state,
        articleID: this.props.match.params.articleid
      }, async () => {
        await this.loadData()
      })
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    const currentOpenTab = getCurrentOpenPageTab(this.props.match.params.tabs)
    const formValid = (this.state.article.authors.length && this.state.article.title) ? true : false
    const hasError = (this.state.hasError || !formValid) ? true : false

    return (
      <div className="tab-pane fade show active">
        <div className="card-body card-body--standard dd-background dd-background--no-padding-bottom dd-background--white">
          <SidebarLang
            item={this.state.article}
            controller="articles"
            itemType="article"
          />
          <div className="card-body__content w-75 ml-auto mr-auto">
            <div className="form-group">
              <Textfield
                name="kicker"
                type="text"
                isRequired={false}
                label="Dachzeile"
                handleChange={(e) => this.onChange('kicker', e.target.value)}
                defaultValue={this.state.article.kicker}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="title"
                type="text"
                isRequired={true}
                label="Titel des Beitrags"
                handleChange={(e) => this.onChange('title', e.target.value)}
                defaultValue={this.state.article.title}
                errorHandler={this.errorHandler}
              />
            </div>
            <div className="form-group">
              <label className="textfield__label">Einführungstext</label>
              <textarea
                className="form-control"
                name="teaser"
                defaultValue={this.state.article.teaser}
                onChange={(e) => this.onChange('teaser', e.target.value)}
              />
            </div>

            {this.state.article.imported_content && this.state.article.imported_content.length &&
              <div className="form-group">
                <label className="textfield__label">Importierte Inhalte</label>
                <textarea
                  className="form-control"
                  name="imported_content"
                  rows="10"
                  defaultValue={this.state.article.imported_content}
                onChange={(e) => this.onChange("imported_content", e.target.value)}
                  placeholder=""
                />

                <label className="textfield__label">Importierte Inhalte anzeigen?</label>
                <Checkbox
                  label="ja"
                  id="show_imported_content"
                  selected={this.state.article.show_imported_content}
                  onChange={(e) => this.onShowImportedContentChange(e)}
                />
              </div>
            }

            <div className="form-group">
              <TagList
                indexLabel="full_name"
                name="authors"
                data={this.state.article.authors || []}
                availableData={this.state.authors}
                label="Autor"
                isRequired={true}
                onUpdate={(assignedTags) =>
                  this.onChange('authors', assignedTags)
                }
              />
            </div>
          </div>

          <ArticlesEditTabs
            activeTab={currentOpenTab}
          />

        </div>
        <div className="card-body card-body--standard">
          <div className="card-body__content w-75 ml-auto mr-auto">
            {currentOpenTab === 0 &&
              <div className="form-group">
                <Blocks
                  handleChange={this.onBlocksChange}
                  blocks={this.state.blockContent}
                  errorHandler={this.errorHandler}
                />
              </div>
            }
            {currentOpenTab === 1 &&
              <ArticleMetadata
                article={this.state.article}
                onChange={this.onChange}
                onItemChange={this.onItemChange}
                errorHandler={this.errorHandler}
              />
            }
            {currentOpenTab === 3 &&
              <ArticlePublishing
                article={this.state.article}
                onChange={this.onChange}
                publishOnNetwork={this.publishOnNetwork}
                releaseOnNetwork={this.releaseOnNetwork}
              />
            }
          </div>
        </div>
        <Editbar
          previewURL={this.state.previewURL}
          hasPublish={true}
          hasPreview={true}
          hasError={hasError}
          onSave={this.onSubmit}
          onRepublish={this.onRepublish}
          successMessage="Erfolgreich gespeichert"
        />
      </div>
    );
  }
}

ArticleContent.contextType = AppContext
export default withRouter(ArticleContent);
