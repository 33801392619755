import React from "react";
import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";

class DndRow extends React.Component {
  render() {
    const row = this.props.row
    const fieldNames = this.props.fieldNames

    return (
      <div className="dnd__row-container">
        <button className="btn btn-primary dd_tag-popup-btn dnd__btn dnd__btn-add"
                onClick={this.props.addRow}>
          <Icon icon="plus" />
        </button>

        <span className="dnd__row">

          {fieldNames.map((field, index) => {
            return <Textfield
              key={index}
              name={field}
              type="text"
              defaultValue={row[field]}
              handleChange={this.props.onChange}
            />
          })}

        </span>

        <div className="dnd__order">
          <button className="dd-btn dd-btn--icon-only dd-btn--transparent">
            <Icon icon="sorter" />
          </button>
          <button className="dd-btn dd-btn--danger dd-btn--icon-only"
            onClick={() => this.props.deleteRow()}>
            <Icon icon="trash" />
          </button>
        </div>
      </div>
    )
  }
}

export default DndRow;
