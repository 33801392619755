import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";


// import Loader from "../Shared/atom/Loader/Loader";
import Textfield from "../Shared/atom/Textfield/Textfield";
import { AppContext } from '../../data/ContextProvider'

import { resetPassword } from "../../data/api";


class PasswordReset extends Component {

  constructor(props) {
    super(props)
    const resetToken = (props.match && props.match.params && props.match.params.reset_token) ? props.match.params.reset_token : ''
    this.state = {
      step: props.step ? props.step : 0,
      error: "",
      message: "",
      email: '',
      password: '',
      resetToken: resetToken,
      hasSuccess: false,
    }
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const state = this.state
    state[name] = value
    this.setState({ ...state });
  }

  handleNewPassword = async (e) => {
    e.preventDefault();

    if (!this.state.password) {
      this.setState({
        message: "Passwort darf nicht leer sein",
        error: true
      })
      return false;
    }

    try {
      const response = await resetPassword({
        // email: this.state.email,
        password: this.state.password,
        token: this.state.resetToken,
      })
      // console.log(response)
      this.setState({
        message: response.message,
        hasSuccess: true,
      })

    } catch (error) {
      // console.log(error.data)
      if (error.data && error.data.error) {
        this.setState({
          message: error.data.error,
          error: true
        })
      }

    }
    return false;
  }

  render() {
    // if (!this.context.user.diro_admin) {
    //   return <Loader />
    // }
    return (
      <form onSubmit={(e) => this.handleNewPassword(e)}>
        <h2>Passwort zurücksetzen</h2>
        {this.state.message &&
        <p className={this.state.error ? "text-danger" : "text-info" }>
          {this.state.message}
        </p>
        }
        {(this.state.hasSuccess && (
          <div className="form-group d-flex align-items-end justify-content-end" style={{height: '160px'}}>
            <div className="mt-auto">
              <Link to="/">
                <button
                  className="dd-btn dd-btn--primary ml-auto"
                  // onClick={(e) => false}
                >
                  Zum Login
                </button>
              </Link>
            </div>
          </div>
        ))
        ||
        (<>
          <p className="card-info">
            Geben Sie bitte Ihr neues Passwort ein
          </p>
          <Textfield
            name={"password"}
            label={"Passwort"}
            handleChange={this.handleChange}
            isRequired={true}
            type={"password"}
          />
          <div className="form-group d-flex align-items-center justify-content-between">
            <button
              className="dd-btn dd-btn--primary ml-auto"
              // type="submit"
              onClick={(e) => this.handleNewPassword(e)}
            >
              Neues Passwort setzen
            </button>
          </div>
        </>)
        }
      </form>
    );
  }
}

PasswordReset.contextType = AppContext
export default withRouter(PasswordReset);
