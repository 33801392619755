import React from "react";

import { AppContext } from "../../../../data/ContextProvider";
import Icon from "../../atom/Icon/Icon";

class Searchbar extends React.Component {

  render() {
    return (
      <div className="dd-search-bar">
        <input
          className="form-control mr-sm-2"
          type="search"
          placeholder={this.props.placeholder}
          aria-label={this.props.placeholder}
          onChange={(e) => this.props.onChangeCallback(e)}
        />
        <Icon icon={this.props.icon} />
      </div>
    )
  }
}

Searchbar.contextType = AppContext
export default Searchbar;