import React from "react";

const BlockPreviewMap = (props) => {

  const content = props.block
  let info = (!props.isDiro)  ? "Standort-Karte" : "DIRO-Kanzleien Standort-Karte"

  if (content.maplayout === "teaser") {
    info += 'n';
  }

  return <div className="dd-block-preview dd-block-preview--map">
    <h2>{content.headline}</h2>
    <div className="dd-block-preview--map-placeholder">
      <div>{info}</div>
    </div>
  </div>
}

export default BlockPreviewMap;
