import React from "react";
import { Link, withRouter } from "react-router-dom";

import Loader from "../Shared/atom/Loader/Loader";

import { AppContext } from "../../data/ContextProvider";
import Icon from "../Shared/atom/Icon/Icon";

import { getPagetypes } from "../../data/api";
import { getToken } from "../../lib/lib";

class PagesTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  loadData = async () => {
    const pagetypes = await getPagetypes(getToken())
    this.setState({
      ...this.state,
      pagetypes: pagetypes.pagetypes,
      loading: false
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    return (
      <>
        <table className="table dd-table">
          <thead>
            <tr>
              <th>Titel</th>
              <th scope="col" className="dd-table--actions-th"></th>
            </tr>
          </thead>
          <tbody>
            {this.state.pagetypes.map((pagetype, key) => {
              return <tr key={key}>
                <td>{pagetype.title}</td>
                <td className="justify-content-center dd-table--actions">
                  <div className="dd-table--actions_wrapper">
                    <Link to={`/de/pages/types/${pagetype.id}`}>
                      <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only">
                        <Icon icon="edit" />
                      </button>
                    </Link>
                  </div>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </>
    )
  }
}

PagesTable.contextType = AppContext
export default withRouter(PagesTable)