import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import JobMetadata from './JobMetadata'
import JobPublishing from './JobPublishing'
import JobDetails from './JobDetails'
import JobEditTabs from './JobEditTabs'

import Textfield from '../Shared/atom/Textfield/Textfield'
import TagList from '../Shared/atom/TagList/TagList'
import Blocks from '../Shared/module/Blocks/Blocks'
import Loader from '../Shared/atom/Loader/Loader'
import Editbar from '../Shared/element/Editbar/Editbar'
import { SidebarLang } from "../Shared/element/LangTools/LangHelper";

import { AppContext } from "../../data/ContextProvider";
import { getJob, updateJob, getCompany } from '../../data/api'
import { getToken, getCurrentOpenTab } from '../../lib/lib'


class JobContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      jobID: 0,
      loading: true,
      job: {},
      contacts: {},
    }
  }

  errorHandler = (hasError=false) => {
    this.setState({ hasError })
  }

  onBlocksChange = (blockContent) => {
    const job = this.state.job
    job.content = blockContent
    this.setState({ job, blockContent })
  }


  onChange = (name, value) => {
    const job = this.state.job
    job[name] = value
    this.setState({ job })
  }

  onSubmit = () => {
    const job = this.state.job
    job.lawfield_ids = job.lawfields.map((tag) => tag.id)
    job.federalstate_ids = job.federalstates.map((tag) => tag.id)
    job.country_ids = job.countries.map((tag) => tag.id)
    job.keyword_ids = job.keywords.map((tag) => tag.id)
    job.contact_ids = job.contacts.map((tag) => tag.id)
    job.company_site_ids = []
    job.company_sites.forEach((tag) => job.company_site_ids.push(tag.id))
    job.company_sites = []

    updateJob(getToken(), job.id, job)
    .then((updatedJob) => {})
    .catch((err) => {
      console.log(err)
    })
  }

  loadData = async () => {
    try {
      const company = await getCompany(getToken(), this.context.company.id)
      const job = await getJob(getToken(), this.state.jobID)
      const blocksJSONData = job.job.content || '[]'

      const preview = 'http://' + company.company.domain + '/' + job.job.lang + '/' + job.job.path

      this.setState({
        ...this.state,
        company: company.company,
        job: job.job,
        contacts: company.company.employees,
        blockContent: JSON.parse(blocksJSONData),
        previewURL: preview,
        loading: false
      })
    } catch (error) {
      //FIXME NotFound
      console.log(error)
    }
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params) {
      this.setState({
          ...this.state,
          jobID: this.props.match.params.job_id
        },
        async () => {
          await this.loadData()
        }
      )
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    const currentOpenTab = getCurrentOpenTab(this.props.match.params.tabs)
    const formValid = (this.state.job.contacts.length) ? true : false
    const hasError = (this.state.hasError || !formValid) ? true : false

    return (
      <div className="tab-pane fade show active">
        <div className="card-body card-body--standard dd-background dd-background--no-padding-bottom dd-background--white">
          <SidebarLang
           item={this.state.job}
           controller="jobs"
           itemType="job"
          />
          <div className="card-body__content w-75 ml-auto mr-auto">
            <div className="form-group">
              <textarea
                className="form-control"
                name="content_pre"
                defaultValue={this.state.job.description}
                onChange={(e) => this.onChange('description', e.target.value)}
                placeholder="Einleitungstext für Stellenausschreibung."
              />
            </div>

            <div className="form-group">
              <Textfield
                name="title"
                type="text"
                label="Titel"
                isRequired={true}
                handleChange={(e) => this.onChange('title', e.target.value)}
                defaultValue={this.state.job.title}
                errorHandler={this.errorHandler}
              />
            </div>

            <div className="form-group">
              <Textfield
                name="navigation_title"
                type="text"
                isRequired={true}
                label="Navigationstitel"
                handleChange={(e) => this.onChange('navigation_title', e.target.value)}
                defaultValue={this.state.job.navigation_title}
                errorHandler={this.errorHandler}
              />
            </div>

            <div className="form-group">
              <TagList
                indexLabel="full_name"
                name="contacts"
                data={this.state.job.contacts || []}
                availableData={this.state.contacts}
                label="Ansprechpartner"
                isRequired={true}
                onUpdate={(assignedTags) =>
                  this.onChange('contacts', assignedTags)
                }
              />
            </div>
          </div>


          <JobEditTabs
            activeTab={currentOpenTab}
          />
        </div>

        <div className="card-body card-body--standard">
          <div className="card-body__content w-75 ml-auto mr-auto">
            {currentOpenTab === 0 &&
              <div className="form-group">
                <Blocks
                  handleChange={this.onBlocksChange}
                  blocks={this.state.blockContent}
                  errorHandler={this.errorHandler}
                />
              </div>
            }
            {currentOpenTab === 1 &&
              <JobDetails
                job={this.state.job}
                onChange={this.onChange}
              />
            }
            {currentOpenTab === 2 &&
              <JobMetadata
                job={this.state.job}
                onChange={this.onChange}
                errorHandler={this.errorHandler}
              />
            }
            {currentOpenTab === 3 &&
              <JobPublishing
                job={this.state.job}
                onChange={this.onChange}
              />
            }
          </div>
        </div>
        <Editbar
          previewURL={this.state.previewURL}
          hasPublish={false}
          hasPreview={true}
          hasError={hasError}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
        />
      </div>
    )
  }
}

JobContent.contextType = AppContext
export default withRouter(JobContent)
