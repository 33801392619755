import React from "react";
import Icon from "../../atom/Icon/Icon";

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.isOpen,
    }
  }

  toggle = () => {
    this.setState({ open: !this.state.open })
  };

  render() {
    return (
      <div style={this.props.style} className={`dd-card dd-accordion ${this.state.open ? "is-open" : ""}`}>
        <div className="dd-accordion__header">
          <button className="dd-accordion__title dd-btn dd-btn--reset" type="button" onClick={() => this.toggle()}>
            {this.props.title}<Icon
              icon="arrow" />
          </button>
        </div>

        <div className="dd-accordion__content">
          {this.props.children}
        </div>
      </div>
    )
  }
}

Accordion.defaultProps = {
  isOpen: false
};

export default Accordion;
