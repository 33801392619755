import React from "react";

import Icon from "../../atom/Icon/Icon";
import RadioButton from "../../atom/RadioButton/RadioButton";

import SimpleSelectfield from "../../../Shared/atom/Selectfield/SimpleSelectfield";
import BlockArticlesPreview from './BlockPreviews/ArticlesOverview';
import BlockSectionSettings from './BlockSectionSettings';
import BlockActions from "./BlockActions";

class BlockArticlesOverviewEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content:{},
      preview: false,
      showSettings: false,
      itemsCountPerPage: 10,
      pageSizes: [
        { id: 5, count: 5 },
        { id: 10, count: 10 },
        { id: 15, count: 15 },
        { id: 20, count: 20 },
      ],
    }
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  onSelectType = (value) => {
    const content = {...this.state.content}
    content.itemsCountPerPage = value
    this.saveContent(content)
  }

  onChange = (event) => {
    const content = this.state.content
    content[event.target.name] = event.target.value
    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  componentDidMount() {
    if (this.props.initData) {
      const preview = this.props.preview
      const content = this.props.initData[0]

      this.setState({ content, preview })
    }
  }

  render() {
    return (
        <>
        {this.state.preview
          ? <div className="dd-block" onClick={() => this.togglePreviewEditMode()}>
              <div className="dd-block__preview">
                <BlockArticlesPreview block={this.props.initData} />
              </div>
            </div>
          :
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-teaserlist" /> Fachartikelübersicht
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }

              <div className="dd-block__edit-section">
                <div className="form-group">
                  <div className="row">
                    <div className="col col-lg-4">
                      <div className="form-check">
                        <label className="radio-button__group-label">Darstellung auswählen</label>

                        <RadioButton
                          name="viewmode"
                          label="Kompakt"
                          value="compact"
                          handleChange={this.onChange}
                          selected={this.state.content.viewmode === "compact"}
                        />
                        <RadioButton
                          name="viewmode"
                          label="Ausführlich"
                          value="extended"
                          handleChange={this.onChange}
                          selected={this.state.content.viewmode === "extended"}
                        />

                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-check">
                        <label className="radio-button__group-label">Typ</label>
                        <RadioButton
                          name="overviewType"
                          label="Neuigkeit"
                          value="news"
                          handleChange={this.onChange}
                          selected={this.state.content.overviewType === "news"}
                        />
                        <RadioButton
                          name="overviewType"
                          label="Fachbeitrag"
                          value="article"
                          handleChange={this.onChange}
                          selected={this.state.content.overviewType === "article"}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-check ">
                        <label className="radio-button__group-label ">Anzahl der Beiträge pro Seite</label>
                        <SimpleSelectfield
                          label={false}
                          name="itemsCountPerPage"
                          firstOptionValue=""
                          firstOptionLabel="Bitte auswählen"
                          indexLabel="id"
                          optionLabel="count"
                          options={this.state.pageSizes}
                          onSelect={this.onSelectType}
                          selected={this.state.content.itemsCountPerPage}
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
          }
        </>
    )
  }
}

export default BlockArticlesOverviewEdit
