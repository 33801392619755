import React from "react";
import { AppContext } from "../../../../data/ContextProvider";

class LangChooserSelect extends React.Component {
  state = {
    active: false
  }

  onActivate = () => {
    this.setState({ active: !this.state.active })
  }

  renderLi = (key, shorthand, lang) => {
    if (shorthand === lang) {
      return <li key={key} className="selected">{shorthand}</li>
    } else {
      return <li key={key} onClick={() => this.props.onSelect(shorthand)}>{shorthand}</li>
    }
  }

  render() {
    return (
      <ul className={`languages-switcher language-switcher--table-select ${this.state.active ? 'is-active' : ''}`} onClick={this.onActivate}>
        {this.context.syslanguages.map((language, index) => {
          return this.renderLi( index, language.shorthand, this.props.lang)
        })
        }
      </ul>
    )
  }
}

LangChooserSelect.contextType = AppContext
export default LangChooserSelect