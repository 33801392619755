import React from "react";

import SimpleSelectfield from '../../../Shared/atom/Selectfield/SimpleSelectfield'

class Pagination extends React.Component {
  pagination() {
    const out = []
    for (let i = 1; i < (this.props.total+1); i++) {

      const currentPage = this.props.current + 1
      const pushPage = ((i < 3) || (i > (this.props.total - 2)) || (i === currentPage)) ? true : false

      if (pushPage) {
        const classNameLi = (i === currentPage) ? "page-item is-active" : "page-item"
        out.push(<li className={classNameLi} key={i}>
            <button className="page-link" onClick={(e) => this.props.onChange(e, (i-1))}>
              {i}
            </button>
          </li>)
      }
      else if (
        (this.props.total > 4)
        && (i !== currentPage)
        && (i === 3)
      ) {
        const display = (this.props.total === 5) ? '3' : '...'
        out.push(<li className="page-item disabled" key={i}>
            <button className="page-link">
              {display}
            </button>
          </li>)
      }
      else if (
        (this.props.total > 4)
        && (i !== currentPage)
        && (i === (this.props.total - 2))
        && !(
            (currentPage < 3)
            || (currentPage > (this.props.total - 2))
          )
      ) {
        out.push(<li className="page-item disabled" key={i}>
            <button className="page-link">
              ...
            </button>
          </li>)
      }
    }
    return out;
  }

  render() {
    const prevPage = ((this.props.current - 1) >= 0) ? this.props.current - 1 : 0
    const nextPage = ((this.props.current + 1) <= this.props.total) ? this.props.current + 1 : this.props.total

    const itemsPerPage = [
      {id: '10'},
      {id: '25'},
      {id: '50'}
    ]

    return (
      <nav className="pagination">
        <div className="pagination__itemcount">
        <span>Ergebnisse pro Seite</span>
        <SimpleSelectfield
          label=""
          name="itemsPerPage"
          firstOptionValue="1"
          indexLabel="id"
          optionLabel="id"
          options={itemsPerPage}
          onSelect={this.props.onSelect}
          selected={this.props.limit || ''}
        />
        </div>
        <ul className="pagination__pages justify-content-end">

          <li className={(this.props.current === 0) ? "page-item disabled" : "page-item" }>
            <button className="page-link pagination__prev" onClick={(e) => this.props.onChange(e, prevPage)}>Previous</button>
          </li>
          {this.pagination()}
          <li className={((this.props.current+1) === this.props.total) ? "page-item disabled" : "page-item"}>
            <button className="page-link pagination__next" onClick={(e) => this.props.onChange(e, nextPage)}>Next</button>
          </li>

        </ul>
      </nav>
    )
  }
}


export default Pagination
