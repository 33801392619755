import React from "react";
import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";
import Checkbox from "../../atom/Checkbox/Checkbox";

class DndRowPublications extends React.Component {
  render() {
    const row = this.props.row
    const fieldNames = this.props.fieldNames

    return (
      <div className="dnd__row-container" style={{'marginBottom': '20px'}}>
        <button className="btn btn-primary dd_tag-popup-btn dnd__btn dnd__btn-add"
                onClick={this.props.addRow}>
          <Icon icon="plus" />
        </button>

        <div className="dnd__wrapper" style={{
          'flexGrow': '1',
        }}>
          <div className="dnd__row" style={{
              'marginBottom': '8px'
            }}
          >
            <Textfield
              name={fieldNames[0]}
              type="text"
              defaultValue={row[fieldNames[0]]}
              handleChange={this.props.onChange}
            />
            <Textfield
              name={fieldNames[1]}
              type="text"
              defaultValue={row[fieldNames[1]]}
              handleChange={this.props.onChange}
            />
            <Textfield
              name={fieldNames[2]}
              type="text"
              defaultValue={row[fieldNames[2]]}
              handleChange={this.props.onChange}
            />
          </div>


          <div className="dnd__row">
            <div style={{
              'flex': '1 1',
              'display': 'flex',
              'padding': '9px 4px'
            }}>
              <Checkbox
                label={row.has_publication_link ? "Link zur Publikation" : "Link hinzufügen"}
                name="has_publication_link"
                id="buttonTarget"
                selected={row.has_publication_link}
                onChange={this.props.onChangeCheckbox}
                addStyles={true}
              />
            </div>
            <div style={{
              'flex': '2 1',
            }}>
            {row.has_publication_link &&
              <Textfield
                name="publication_path"
                type="text"
                defaultValue={row.publication_path}
                handleChange={this.props.onChange}
              />
            }
            </div>
          </div>
        </div>


        <div className="dnd__order" style={{padding: '33px 20px'}}>
          <button className="dd-btn dd-btn--icon-only dd-btn--transparent">
            <Icon icon="sorter" />
          </button>
          <button className="dd-btn dd-btn--danger dd-btn--icon-only"
            onClick={() => this.props.deleteRow()}>
            <Icon icon="trash" />
          </button>
        </div>
      </div>
    )
  }
}

export default DndRowPublications;
