import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { throttle } from "throttle-debounce"

import { getEvents, getEvent, translateEvent, deleteEvent, updateEvent } from '../../data/api'
import { getToken, formatShortDate } from '../../lib/lib'

import Title from "../Shared/module/Title/Title"
import Searchbar from "../Shared/element/Searchbar/Searchbar"
import Pagination from '../Shared/element/Pagination/Pagination'
import TableColumnHeader from '../../layout/Tables/TableColumnHeader'
import DeleteConfirmOverlay from "../Shared/module/Overlay/DeleteConfirmOverlay"
import { TableColumnHeaderLang, TableColumnBodyLang } from "../Shared/element/LangTools/LangHelper"
import Loader from "../Shared/atom/Loader/Loader"

import { AppContext } from '../../data/ContextProvider'
import Icon from '../Shared/atom/Icon/Icon'


class EventsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      loading: true,
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false,
      confirmMode: 'delete',
      eventToDelete: 0,
      count: 0,
      pagesCount: 0,
      limit: 10,
      defaultLimit: 10,
      currentPage: 0,
      orderField: 'updated_at',
      orderDirection: 'desc',
      events: [],
      lang: 'de'
    }

    this.searchThrottled = throttle(500, this.loadData);
  }

  setLang = (lang) => {
    this.setState({ lang }, async () => { await this.loadData() })
  }

  selectTranslation = async (item, lang, key) => {
    const events = this.state.events
    const translatedItem = await getEvent(getToken(), item.translations[lang])
    events[key] = translatedItem.event
    this.setState({ events })
  }

  translateItem = async (itemToTranslate, lang) => {
    const response = await translateEvent(getToken(), itemToTranslate.id, lang)
    this.props.history.push(`${this.props.match.url}/${response.event.id}/content`);
  }

  onFilterChange = (event) => {
    this.setState({
      query: event.target.value,
      currentPage: 0
    }, () => {
      this.searchThrottled()
    })
  }

  closeOverlay = () => {
    this.setState({
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false
    })
  }

  showDeleteConfirmation = (event) => {
    this.setState({
      deleteConfirmOverlay: true,
      eventToDelete: event.id,
      confirmMode: "delete"
    })
  }

  showPublishConfirmation = (event) => {
    this.setState({
      hideConfirmOverlay: true,
      eventToHide: event.id,
      confirmMode: (event.published) ? "hide" : "show"
    })
  }

  handlePublication = async () => {

    const index = this.state.events.findIndex(s => s.id === this.state.eventToHide)
    const event = this.state.events[index]

    event.published = !event.published

    this.setState({
      isUpdating: true,
    }, () => {
        updateEvent(getToken(), event.id, event)
        .then(async updatedEvent => {
          await this.loadData()
        })
        .catch(err => {
          this.setState({ isUpdating: false })
        })
    })
  }

  onDeleteConfirmation = async () => {

    const eventToDelete = this.state.eventToDelete

    if (eventToDelete) {
      this.setState({
        isUpdating: true,
      }, () => {
          deleteEvent(getToken(), eventToDelete)
          .then(async updatedEvent => {
            await this.loadData()
          })
          .catch(err => {
            this.setState({ isUpdating: false })
          })
      })
    }
  }

  handlePaginationClick = async (e, page) => {
    this.setState(
      {
        ...this.state,
        currentPage: page
      },
      async () => {
        await this.loadData()
      }
    )
  }

  handleSelectLimit = (limit) => {

    localStorage.setItem('events_limit', limit);

    this.setState({ limit, currentPage: 0 }, async () => {
      await this.loadData()
    })
  }

  onTableColumnClick = (column) => {
    const { orderDirection } = this.state

    this.setState(
      {
        orderField: column,
        orderDirection: orderDirection === 'desc' ? 'asc' : 'desc'
      },
      async () => {
        await this.loadData()
      }
    )
  }

  loadData = async () => {

    const limit = localStorage.getItem('events_limit') || this.state.defaultLimit

    const offset = this.state.currentPage * limit

    const events = await getEvents(
      getToken(),
      this.state.lang,
      limit,
      offset,
      this.state.orderField,
      this.state.orderDirection,
      this.state.query
    )

    const pagesCount = Math.ceil(events.count / limit)

    this.setState({
      ...this.state,
      limit: limit,
      loading: false,
      pagesCount: pagesCount,
      count: events.count,
      events: events.events,
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false
    })
  }

  componentDidMount() {
    this.loadData()
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    return (
      <React.Fragment>

        <Title headline="Stellen im DIRO-Netzwerk" />

        <table className="table dd-table">
          <thead>
            <tr>
              <TableColumnHeaderLang
                lang={this.state.lang}
                setLang={this.setLang}
              />
              <TableColumnHeader
                width="300px"
                name="Kanzlei"
                field="name"
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick}
              />
              <TableColumnHeader
                width="120px"
                name="Erstellt"
                field="created_at"
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick}
              />
              <TableColumnHeader
                width="120px"
                name="Geändert"
                field="updated_at"
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick}
              />
              <TableColumnHeader
                name="Ort"
                field="city"
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick}
              />
              <th scope="col">Ansprechpartner</th>
              <th scope="col">Abrufe</th>
              <th scope="col" className="dd-table--actions-th">
                <div className="dd-search-bar dd-search-bar--small dd-search-bar--white dd-table--search-filter">
                  <Searchbar
                    placeholder="Verfügbare Events filtern …"
                    icon="filter"
                    onChangeCallback={(e) => this.onFilterChange(e)}
                  />
                </div>
              </th>
              {/*<th scope="col" className="dd-table--actions-th">Actions</th>*/}
            </tr>
          </thead>
          <tbody>
            {this.state.events.map((event, key) => {
              return (
                <tr key={key} className={(!event.published) ? 'is-inactive' : ''}>
                  <TableColumnBodyLang
                    item={event}
                    translateItem={this.translateItem}
                    selectTranslation={(page, lang) => {
                      this.selectTranslation(page, lang, key)
                    }}
                  />
                  <td className={(this.state.orderField === 'name') ? 'is-active' : ''}>{event.title}</td>
                  <td className={(this.state.orderField === 'created_at') ? 'is-active' : ''}>{formatShortDate(event.updated_at)}</td>
                  <td className={(this.state.orderField === 'updated_at') ? 'is-active' : ''}>{formatShortDate(event.created_at)}</td>
                  <td className={(this.state.orderField === 'city') ? 'is-active' : ''}>{event?.company?.hq?.city?.title_de}</td>
                  <td>{event.company.name}</td>
                  <td>{event.views}</td>
                  <td className="justify-content-center dd-table--actions">
                    <div className="dd-table--actions_wrapper">
                      <button type="submit" className="dd-btn dd-btn--danger dd-btn--icon-only mr-2" onClick={() => this.showDeleteConfirmation(event)}>
                        <Icon icon="trash" />
                      </button>
                      <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only" onClick={() => this.showPublishConfirmation(event)}>
                        {event.published
                          ? <Icon icon="visibilty" />
                          : <Icon icon="hidden" />
                        }
                      </button>
                      <Link to={`/de/events/${event.id}/content`}>
                        <button
                          type="submit"
                          className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only"
                        >
                          <Icon icon="edit" />
                        </button>
                      </Link>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>

        {this.state.deleteConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.onDeleteConfirmation}
            mode={this.state.confirmMode}
          />
        }
        {this.state.hideConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.handlePublication}
            mode={this.state.confirmMode}
          />
        }

        <div className="float-right">
          <Pagination
            total={this.state.pagesCount}
            current={this.state.currentPage}
            onChange={this.handlePaginationClick}
            onSelect={this.handleSelectLimit}
            limit={this.state.limit}
          />
        </div>

        <Link to={`/de/events/new`} className="dd-btn__wrapper">
          <button className="dd-btn dd-btn--primary dd-btn--icon-only" >
            <Icon icon="plus" />
          </button>
          <span className="dd-btn__text">Neues Event anlegen</span>
        </Link>

      </React.Fragment>
    )
  }
}

EventsTable.contextType = AppContext
export default withRouter(EventsTable)
