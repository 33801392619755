import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import Accordion from "../Shared/module/Accordion/Accordion";
import Icon from "../Shared/atom/Icon/Icon";
import SimpleSelectfield from "../Shared/atom/Selectfield/SimpleSelectfield";

function PersonTeaser(props) {
  const personPath = "/de/offices/" + props.person.company_id + "/team/" + props.person.id
  const newPersonPath = "/de/offices/" + props.person.company_id + "/team/new"

  const nameParts = []

  if (props.person.salutation) {
    nameParts.push(props.person.salutation)
  }

  if (props.person.firstname) {
    nameParts.push(props.person.firstname)
  }

  if (props.person.lastname) {
    nameParts.push(props.person.lastname)
  }

  const fullName = nameParts.join(" ")

  const path = props.isAddNew ? newPersonPath : personPath
  const history = useHistory();

  function setPersonVisaualOrder(order) {
    props.updatePersonVisualOrder(props.person, order)
  }


  const visual_order = []
  for (let i = 0; i < 100; i++) {
    visual_order.push({
      visual_order: i,
      visual_order_label: i,
    })
  }

  return (
    <div >
      <div className={`dd-person ${props.isAddNew ? "dd-person--add-new" : ""} ${props.person.hidden ? "is-inactive" : ""}`}>
        <span className="dd-person__image-container" onClick={() => history.push(path)}>

          {!props.isAddNew && props.person.avatar_path &&
            <img src={props.person.avatar_path} alt={fullName} className="d-block" />
          }

        </span>
        <div className="dd-person__content">
          <span className="dd-person__spacer" />
          <span className="dd-person__name">
            {props.isAddNew ? "Neue Person hinzufügen" : fullName}
          </span>
          {!props.isAddNew &&
            <div className="dd-person__actions">
              <div className="dd-person__order">
                <div className="dd-person__order-select">
                  <SimpleSelectfield
                    label={false}
                    name="visual_order"
                    firstOptionValue={100}
                    indexLabel="visual_order"
                    optionLabel="visual_order_label"
                    options={visual_order}
                    selected={props.person.visual_order}
                    onSelect = { setPersonVisaualOrder }
                  />
                </div>
                <div className="dd-person__order-hint">
                  Position in Teamlisten
                </div>
              </div>
              <div className="dd-person__visibility">
                <div className="dd-person__visibility-button">
                  <button
                    className="dd-btn dd-btn--primary dd-btn--transparent dd-btn--inverted dd-btn--icon-only"
                    onClick={ (e)=> props.showPublishConfirmation(e, props.person) }
                  >
                    {!props.person.hidden
                      ? <Icon icon="visibilty" />
                      : <Icon icon="hidden" />
                    }
                  </button>
                </div>
                <div className="dd-person__visibility-hint">
                    Person ist <strong>{!props.person.hidden ? 'sichtbar' : 'ausgeblendet' }</strong>
                </div>
              </div>
            </div>
          }
          {props.isAddNew &&
            <div className="dd-person__actions">
              <div className="dd-person__buttons">
                <button className="dd-btn dd-btn--icon-only dd-btn--primary dd-person__add" onClick={() => history.push(path)}><Icon icon="plus" /></button>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

Accordion.defaultProps = {
  isAddNew: false,
};

export default withRouter(PersonTeaser);
