import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Textfield from "../Shared/atom/Textfield/Textfield";
import Loader from "../Shared/atom/Loader/Loader";
import Editbar from "../Shared/element/Editbar/Editbar";

import { AppContext } from "../../data/ContextProvider";
import { getSyslanguage, updateSyslanguage } from "../../data/api";
import { getToken } from "../../lib/lib";


class LanguageEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      loading: false,
      syslanguage: {},
      langID: 0
    }
  }

  errorHandler = (hasError=false) => {
    this.setState({ hasError })
  }

  onChange = (event) => {
    const syslanguage = { ...this.state.syslanguage }
    syslanguage[event.target.name] = event.target.value
    this.setState({ syslanguage })
  }

  onSubmit = async () => {
    const syslanguage = { ...this.state.syslanguage }

    updateSyslanguage(getToken(), syslanguage.id, syslanguage)
    .then(async syslanguage => {
      await this.loadData()
    })
    .catch(err => {
      console.log(err)
    })
  }

  loadData = async () => {
    const syslanguage = await getSyslanguage(getToken(), this.state.langID)
    this.setState({
      ...this.state,
      loading: false,
      syslanguage: syslanguage.syslanguage
    })
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params) {
      this.setState({
        ...this.state,
        langID: this.props.match.params.lang_id,
      },
        async () => {
          await this.loadData()
        }
      )
    }
  }


  render() {
    if (this.state.loading) {
      return <Loader />
    }

    const formValid = (this.state.syslanguage.shorthand && this.state.syslanguage.title) ? true : false
    const hasError = (this.state.hasError || !formValid) ? true : false

    return (
      <div className="tab-pane fade show active">
        <div className="card-body card-body--standard dd-background dd-background--sand">
          <div className="card-body__content w-75 ml-auto mr-auto">
            <div className="form-group">
              <Textfield
                name="title"
                type="text"
                isRequired={true}
                label="Sprache"
                handleChange={this.onChange}
                defaultValue={this.state.syslanguage.title}
                errorHandler={this.errorHandler}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="shorthand"
                type="text"
                isRequired={true}
                label="URL-Kürzel"
                handleChange={this.onChange}
                defaultValue={this.state.syslanguage.shorthand}
                errorHandler={this.errorHandler}
              />
            </div>
          </div>
        </div>
        <Editbar
          hasPublish={false}
          hasPreview={false}
          hasError={hasError}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
        />
      </div>
    );
  }
}

LanguageEdit.contextType = AppContext
export default withRouter(LanguageEdit);
