import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'


function SimpleMap (props) {
  const zoom = 13
  const position = [props.lat, props.lng]
  const popup = props.popup

  if (!props.lat) return ''

  return (
    <MapContainer center={position} zoom={zoom}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Popup>
          {popup}
        </Popup>
      </Marker>
    </MapContainer>
  )
}

export default SimpleMap;