import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { AppContext } from "../../data/ContextProvider";
import Textfield from "../Shared/atom/Textfield/Textfield";
import TagList from "../Shared/atom/TagList/TagList";
import TextfieldGroup from "../Shared/atom/TextfieldGroup/TextfieldGroup";
import Checkbox from "../Shared/atom/Checkbox/Checkbox";


class PageMetadata extends Component {

  render() {

    const indexLabel = 'title_' + this.props.page.lang

    return (
      <div className="tab-pane fade show active">
        <form onSubmit={this.onSubmit}>
          <div className="card-body card-body--standard">
            <div className="card-body__content w-75 ml-auto mr-auto">

              <div className="form-group">
                <TagList
                  name="Stichworte"
                  indexLabel={indexLabel}
                  hasAddNew={true}
                  data={this.props.pageKeywords}
                  availableData={this.props.keywords}
                  label="Stichworte, um die es in diesem Beitrag geht"
                  onUpdate={(assignedTags) => this.props.keywordsTagsUpdate(assignedTags)}
                  onCreate={(newTag) => this.props.addKeywordTag(newTag)}
                />
              </div>

              <div className="form-group">
                <label className="textfield__label">Kurzbeschreibung des Beitrags (Meta Description)</label>
                  <textarea
                    className="form-control"
                    name="description"
                    defaultValue={this.props.page.description}
                    onChange={(e) => this.props.onChange(e)}
                    placeholder="Die Länge der Meta Description sollte 160 Zeichen nicht überschreiten. 130 bis 160 Zeichen sind optimal. Der Inhalt sollte zum Klick auf das Suchergebnis animieren."
                  />
              </div>

              {this.props.lawfields.length &&
                <div className="form-group">
                  <TagList
                    indexLabel={indexLabel}
                    name="Rechtsgebiete"
                    data={this.props.pageLawfields}
                    availableData={this.props.lawfields}
                    label="Rechtsgebiete, die im Beitrag behandelt werden:"
                    onUpdate={(assignedTags) => this.props.lawFieldsTagsUpdate(assignedTags)}
                  />
                </div>
              }


              {this.props.countries.length &&
                <div className="form-group">
                  <TagList
                    indexLabel={indexLabel}
                    name="Länder"
                    data={this.props.pageCountries}
                    availableData={this.props.countries}
                    label="Beitrag ist besonders relevant für Interessenten aus:"
                    onUpdate={(assignedTags) => this.props.countriesTagsUpdate(assignedTags)}
                  />
                </div>
              }

              {this.props.federalstates.length &&
                <div className="form-group">
                  <TagList
                    indexLabel={indexLabel}
                    name="Bundesländer"
                    data={this.props.pageFederalstates}
                    availableData={this.props.federalstates}
                    label="Optional - Beitrag ist besonders relevant für Interessenten aus:"
                    onUpdate={(assignedTags) => this.props.federalstatesTagsUpdate(assignedTags)}
                  />
                </div>
              }

              <div className="form-group">
                <Textfield
                  name="default_path"
                  type="text"
                  isReadonly={true}
                  label="Automatische Adresse für diesen Beitrag"
                  defaultValue={`https://${this.props.company.domain}/${this.props.page.lang}/${this.props.page.default_path}`}
                />
              </div>

              <div className="form-group">
                <TextfieldGroup label="Adresse, unter der dieser Beitrag stattdessen auffindbar sein soll">
                <Textfield
                  name="home_url"
                  type="text"
                  isReadonly={true}
                    defaultValue={`https://${this.props.company.domain}/${this.props.page.lang}/`}
                />
                <Textfield
                  name="overridden_path"
                  type="text"
                  hasError={this.props.hasError}
                  handleChange={(e) => this.props.onChange(e)}
                  defaultValue={this.props.page.overridden_path}
                />
                </TextfieldGroup>
              </div>

              <div className="form-group">
                <label className="textfield__label"></label>
                <Checkbox
                  label="Diese Seite nur als Navigationseintrag ausspielen und auf den ersten Unterpunkt weiterleiten"
                  id="is_virtual"
                  selected={this.props.page.is_virtual === true || this.props.page.is_virtual === 1}
                  onChange={(e) => this.props.onIsVirtualChange(e)}
                />
              </div>

              <div className="form-group">
                <label className="textfield__label"></label>
                <Checkbox
                  label="Diese Seite nicht von Google erfassen lassen "
                  id="noindex"
                  selected={(this.props.page.noindex === true || this.props.page.noindex === 1)}
                  onChange={(e) => this.props.onNoIndexChange(e)}
                />
              </div>

              <div className="form-group">
                <label className="textfield__label"></label>
                <Checkbox
                  label="Google soll alle Links dieser Seite ignorieren"
                  id="nofollow"
                  selected={this.props.page.nofollow === true || this.props.page.nofollow === 1}
                  onChange={(e) => this.props.onNoFollowChange(e)}
                />
              </div>

            </div>
          </div>
        </form>
      </div>
    )
  }
}

PageMetadata.contextType = AppContext
export default withRouter(PageMetadata);
