import React from "react";

import { contentForBlock } from '../util';

class BlockPreviewDefault extends React.Component {
  render() {
    const content = contentForBlock(this.props.block)

    return (
      <div className="dd-block-preview dd-block-preview--default">
      {
        content.map((item, index) => {
          return <item.tag key={index} >{item.value}</item.tag>
       })
      }
      </div>
    )
  }
}

export default BlockPreviewDefault