import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Loader from "../Shared/atom/Loader/Loader";
import Icon from "../Shared/atom/Icon/Icon";
import Accordion from "../Shared/module/Accordion/Accordion";
import Editbar from "../Shared/element/Editbar/Editbar";
import OfficesEditTabs from "../../components/Offices/OfficesEditTabs";
import OfficeSiteEdit from "./OfficeSiteEdit";
import DeleteConfirmOverlay from "../Shared/module/Overlay/DeleteConfirmOverlay";



import { getCompany, getTerms, createCompanySite, updateCompanySite, getSitesForCompany } from "../../data/api";
import { DACH_COUNTRIES } from "../../data/config";
import { getToken } from "../../lib/lib";

class OfficeSites extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      showAddCompanySiteForm: false,
      companyID: 0,
      loading: true,
      company: {},
      countries: [],
      cities: [],
      sites: [],
      signedcontracts: [],
      cooperationnationals: [],
      cooperationinternationals: [],
    }
  }

  errorHandler = (hasError=false) => {
    this.setState({ hasError })
  }

  changeValue = (siteID, fieldname, value) => {
    const index = this.state.sites.findIndex(s => s.id === siteID)

    if (index >= 0) {
      const sites = [...this.state.sites]
      sites[index].data[fieldname] = value
      this.setState({ sites }, () => {
      })
    }
  }

  onChange = (siteID, event) => {
    this.changeValue(siteID, event.target.name, event.target.value)
  }

  onSelectCountry = async (siteID, value)  => {
    this.changeValue(siteID, "country_id", value)
  }

  onSelectCity = async (siteID, value) => {
    this.changeValue(siteID, "city_id", value)
  }

  onSelectFederalstate = (siteID, value) => {
    this.changeValue(siteID, "federalstate_id", value)
  }

  onSelectCompanyForm = (siteID, value) => {
    this.changeValue(siteID, "companyform_id", value)
  }

  onSelectCourt = (siteID, value) => {
    this.changeValue(siteID, "court_id", value)
  }

  contractsUpdate = (siteID, assignedTags) => {
    const index = this.state.sites.findIndex(s => s.id === siteID)
    if (index >= 0) {
      const sites = [...this.state.sites]
      sites[index].data["signedcontracts"]    = assignedTags
      sites[index].data["signedcontract_ids"] = assignedTags.map(tag => tag.id)
      this.setState({ sites })
    }
  }

  cooperationnationalsUpdate = (siteID, assignedTags) => {
    const index = this.state.sites.findIndex(s => s.id === siteID)
    if (index >= 0) {
      const sites = [...this.state.sites]
      sites[index].data["cooperationnationals"] = assignedTags
      sites[index].data["cooperationnational_ids"] = assignedTags.map(tag => tag.id)
      this.setState({ sites })
    }
  }

  cooperationinternationalsUpdate = (siteID, assignedTags) => {
    const index = this.state.sites.findIndex(s => s.id === siteID)
    if (index >= 0) {
      const sites = [...this.state.sites]
      sites[index].data["cooperationinternationals"] = assignedTags
      sites[index].data["cooperationinternational_ids"] = assignedTags.map(tag => tag.id)
      this.setState({ sites })
    }
  }

  closeOverlay = () => {
    this.setState({
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false,
    })
  }

  showConfirmationOverlay = (site, type) => {
    let confirmMode = "delete"
    let hideConfirmOverlay = false
    let deleteConfirmOverlay = false


    if (type === 'visibilty') {
      hideConfirmOverlay = true
      confirmMode = (site.published) ? "hide" : "show"
    } else {
      deleteConfirmOverlay = true
    }

    this.setState({
      deleteConfirmOverlay: deleteConfirmOverlay,
      hideConfirmOverlay: hideConfirmOverlay,
      siteToModify: site.id,
      confirmMode: confirmMode
    })
  }


  handlePublication = async () => {
    this.setState({ isUpdating: true })

    const index = this.state.sites.findIndex(s => s.id === this.state.siteToModify)
    const site = this.state.sites[index]

    site.data.published = !site.data.published

    if (!site.data.id) { // neue Site
      await createCompanySite(getToken(), site.data)
    } else {
      await updateCompanySite(getToken(), site.data.id, site.data)
    }

    await this.loadData()
  }


  onSave = async () => {
    this.setState({ isUpdating: true })

    this.state.sites.forEach( async (site) => {
      if (!site.data.id) { // neue Site
        await createCompanySite(getToken(), site.data)
      } else {
        await updateCompanySite(getToken(), site.data.id, site.data)
      }
    })

    await this.loadData()
  }


  loadData = async () => {
    try {
      const sites = []
      const courts = await getTerms(getToken(), 'courts')
      const countries = await getTerms(getToken(), 'countries')
      const cities    = await getTerms(getToken(), 'cities')
      const { company } = await getCompany(getToken(), this.state.companyID)
      const { company_sites } = await getSitesForCompany(getToken(), this.state.companyID)
      const signedcontracts = await getTerms(getToken(), 'signedcontracts')
      const cooperationnationals = await getTerms(getToken(), 'cooperationnationals')
      const cooperationinternationals = await getTerms(getToken(), 'cooperationinternationals')
      const companyforms = await getTerms(getToken(), 'companyforms')

      company_sites.forEach((site, index) => {
        let city_title = (site.city) ? site.city.title_de : "";
        let is_hq = company.hq_id === site.id;

        let data = {
          name: is_hq ? "Hauptstandort " + city_title : "Standort " + city_title,
          locationKey: (index + 1),
          isOpen: is_hq,
          data: site,
          id: site.id
        }

        sites.push(data);
      })

      this.setState({
        company,
        courts: courts.courts,
        countries: countries.countries,
        cities: cities.cities,
        companyforms: companyforms.companyforms,
        sites: sites,
        loading: false,
        signedcontracts: signedcontracts.signedcontracts,
        cooperationnationals: cooperationnationals.cooperationnationals,
        cooperationinternationals: cooperationinternationals.cooperationinternationals,
        deleteConfirmOverlay: false,
        hideConfirmOverlay: false
      })
    } catch (error) {
      console.log(error)
    }
  }

  async componentDidMount() {
    let companyID = 0

    // DIRO
    if (this.props.match && this.props.match.params) {
      companyID = this.props.match.params.officeid
    }

    // Kanzlei
    if (this.context.company) {
      companyID = this.context.company.id
    }

    if (companyID) {
      this.setState({
        ...this.state,
        companyID: companyID,
      }, async () => {
        await this.loadData()
      })
    }
  }

  addCompanySiteForm = () => {
    const sites = this.state.sites
    sites.push({
      name: "Standort " + sites.length,
      locationKey: sites.length,
      isOpen: true,
      data: { company_id: this.state.company.id},
    })
    this.setState({ sites })
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    return (
      <React.Fragment>
        <OfficesEditTabs
          headline={`Kanzlei ${this.state.company.name}`}
          officeid={this.state.companyID}
        />

        <div className="tab-pane fade show active pb-3">
          {this.state.sites.map((site, key) => {
            return <Accordion key={key} title={site.name} isOpen={site.isOpen} >
              <OfficeSiteEdit
                site={site.data}
                company={this.state.company}
                countries={this.state.countries}
                cities={this.state.cities}
                companyforms={this.state.companyforms}
                courts={this.state.courts}
                signedcontracts={this.state.signedcontracts}
                cooperationnationals={this.state.cooperationnationals}
                cooperationinternationals={this.state.cooperationinternationals}
                contractsUpdate={this.contractsUpdate}
                cooperationnationalsUpdate={this.cooperationnationalsUpdate}
                cooperationinternationalsUpdate={this.cooperationinternationalsUpdate}
                onChange={this.onChange}
                onSelectCountry={this.onSelectCountry}
                onSelectCity={this.onSelectCity}
                onSelectCourt={this.onSelectCourt}
                onSelectCompanyForm={this.onSelectCompanyForm}
                onSelectFederalstate={this.onSelectFederalstate}
                changeValue={this.changeValue}
                showConfirmationOverlay={this.showConfirmationOverlay}
                errorHandler={this.errorHandler}
              />
            </Accordion>
          })}
        </div>
        <div className="dd-btn__wrapper">
          <button className="dd-btn dd-btn--primary dd-btn--icon-only" onClick={this.addCompanySiteForm}>
            <Icon icon="plus" />
          </button>
          <span className="dd-btn__text" onClick={this.addCompanySiteForm}>Standort hinzufügen</span>
        </div>

        {this.state.deleteConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.onDeleteConfirmation}
            mode={this.state.confirmMode}
          />
        }

        {this.state.hideConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.handlePublication}
            mode={this.state.confirmMode}
          />
        }

        <Editbar
          hasPublish={false}
          hasPreview={false}
          hasError={this.state.hasError}
          onSave={this.onSave}
          successMessage="Erfolgreich gespeichert"
        />
      </React.Fragment>
    );
  }
}

export default withRouter(OfficeSites);
