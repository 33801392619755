import React from "react";

import Icon from "../../atom/Icon/Icon";
import Checkbox from "../../atom/Checkbox/Checkbox";
import SimpleSelectfield from "../../atom/Selectfield/SimpleSelectfield";
import Overlay from "../../element/Overlay/Overlay";
import { ImagePreviewBlock } from "../../element/ImagePreview/ImagePreview";
import ImageSelector from "../../module/ImageSelector/ImageSelector";
import PageSelector from "../../module/PageSelector/PageSelector";

import BlockPreviewImage from './BlockPreviews/Image';
import BlockSectionSettings from './BlockSectionSettings';

import { getToken, imagePathForFormat } from "../../../../lib/lib";
import { getAsset, getPage, getArticle } from "../../../../data/api";
import BlockActions from "./BlockActions";

class BlockImageEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      imageOverlayActive: false,
      preview: true,
      showSettings: false,
      imagePositionOptions: [
        { value: 'center', position: 'zentriert' },
        { value: 'left', position: 'links' },
        { value: 'right', position: 'rechts' },
        { value: 'fullsize', position: 'fullsize' }
      ]
    }
  }
  openPageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      pageOverlayActive: true
    })
  }

  closePageSelectorOverlay = () => {
    this.setState({
      pageOverlayActive: false,
    })
  }

  handlePageSelect = (page) => {
    const content = this.state.content

    if (page.id && ('pagetype_id' in page )) {
      content.pageID = page.id
      content.pageTitle = page.title
      content.buttonURL = ""
      content.articleID = ""
    } else if (page.id ) {
      content.pageID = ""
      content.articleID = page.id
      content.pageTitle = page.title
      content.buttonURL = ""
    } else {
      content.pageID = ""
      content.articleID = ""
      content.buttonURL = page.buttonURL
    }

    content.buttonTarget = page.buttonTarget

    this.saveContent(content)
    this.closePageSelectorOverlay()
    this.setState({ page: page })
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  onSelectImagePosition = (value) => {
    const content = this.state.content
    content.imagePosition = value
    this.saveContent(content)
  }

  toggleImageCaption = (e) => {
    const content = this.state.content
    content.withImageCaption = !this.state.content.withImageCaption
    this.saveContent(content)
  }

  toggleImageSource = (e) => {
    const content = this.state.content
    content.withImageSource = !this.state.content.withImageSource
    this.saveContent(content)
  }

  toggleWithLink = (e) => {
    const content = this.state.content
    content.withLink = !this.state.content.withLink
    this.saveContent(content)
  }

  openImageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      imageOverlayActive: true
    })
  }

  closeImageSelectorOverlay = () => {
    this.setState({
      imageOverlayActive: false,
    })
  }

  handleImageDelete = () => {
    const content = this.state.content
    content.imagePath = ""
    content.imageID = 0
    this.saveContent(content)
  }

  handleImageSelect = (file, format) => {
    const content = this.state.content
    const state = this.state
    content.imagePath = file[imagePathForFormat(format)]
    content.imageID   = file.id
    content.imageCaption = file.title
    content.imageSource  = file.copyright
    content.imageFormat  = format
    state.imagePath = content.imagePath
    this.saveContent(content)
    this.closeImageSelectorOverlay()
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  loadData = async () => {
    try {
      const asset = await getAsset(getToken(), this.state.content.imageID)

      this.setState({
        ...this.state,
        asset: asset.asset,
      })

    } catch (error) {
      //FIXME NotFound
    }
  }

  async componentDidMount() {

    let image = {}
    let imagePath = ""

    if (this.props.initData) {
      const preview = this.props.preview
      const content = this.props.initData[0]
      let page = {}

      if (content.pageID) {
        const pageData = await getPage(getToken(), content.pageID)
        page = pageData.page
      }
      else if (content.articleID) {
        const articleData = await getArticle(getToken(), content.articleID)
        page = articleData.article
      }

      try {
        if (content.imageID) {
          image = await getAsset(getToken(), content.imageID)
          imagePath = image.asset[imagePathForFormat(content.imageFormat)]
        }
      } catch (error) {
        console.log(error)
      }

      this.setState({ content, preview, page, imagePath  }, async () => {
        await this.loadData()
      })
    }
  }

  renderSeletedItemLabel = () => {

    if (this.state.content.buttonURL) {
      return this.state.content.buttonURL
    } else if (this.state.content.pageID && this.state.page && this.state.page.title) {
      return `${this.state.page.title} (${this.state.page.navigation_title})`
    } else if (this.state.content.articleID && this.state.page && this.state.page.title) {
      return `${this.state.page.title}`
    }
  }

  render() {
    return (
        <>
        {this.state.preview
          ? <div className="dd-block" onClick={() => this.togglePreviewEditMode()}>
            <div className="dd-block__preview">
              <BlockPreviewImage block={this.props.initData} imagePath={this.state.imagePath}/>
            </div>
          </div>
          :
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-image" /> Image
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }

              <div className="dd-block__edit-section">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">

                      <div className="dd-image-picker">
                        {this.state.imagePath ?
                          <ImagePreviewBlock
                            imagePath={this.state.imagePath}
                            imageId={this.state.imageId}
                            handleImageDelete={this.handleImageDelete}
                            openImageSelectorOverlay={this.openImageSelectorOverlay}
                          />
                          :
                          <button className="dd-btn dd-btn--primary dd-image-picker__choose" onClick={(e) => this.openImageSelectorOverlay(e)}>
                            Bild auswählen
                          </button>
                        }
                      </div>

                      {this.state.imageOverlayActive &&
                        <Overlay closeOverlayCallback={() => this.closeImageSelectorOverlay()}>
                          <ImageSelector
                            selectImageCallback={(file, format) => this.handleImageSelect(file, format)}
                            selectedAssetId={this.state.content.imageID || ''}
                            selectedFormat={this.state.content.imageFormat || ''}
                            fileTypes={["image"]}
                          />
                        </Overlay>
                      }
                    </div>
                    <div className="col-md-6 pt-2">
                      <div className="form-group pb-1">
                        <SimpleSelectfield
                          label="Position"
                          name="imagePosition"
                          indexLabel="value"
                          optionLabel="position"
                          options={this.state.imagePositionOptions}
                          onSelect={this.onSelectImagePosition}
                          selected={this.state.content.imagePosition}
                        />
                      </div>

                      <div className="form-group">
                        <div className="form-check form-check-inline">
                          <Checkbox
                            label="Bildbeschreibung anzeigen"
                            id="withImageCaption"
                            selected={this.state.content.withImageCaption}
                            onChange={(e) => this.toggleImageCaption(e)}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="form-check form-check-inline">
                          <Checkbox
                            label="Bildquelle anzeigen"
                            id="withImageSource"
                            selected={this.state.content.withImageSource}
                            onChange={(e) => this.toggleImageSource(e)}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="form-check form-check-inline">
                          <Checkbox
                            label="Link hinzufügen"
                            id="withLink"
                            selected={this.state.content.withLink}
                            onChange={(e) => this.toggleWithLink(e)}
                          />
                        </div>
                      </div>

                      {this.state.content.withLink &&
                        <div className="form-group">
                          <div className="dd-link-selector">
                            <div className="dd-link-selector__link">{this.renderSeletedItemLabel()}</div>
                            <button className="dd-btn dd-btn--primary dd-link-selector__button" onClick={(e) => this.openPageSelectorOverlay(e)}>
                              Linkziel ändern
                            </button>
                          </div>
                          {this.state.pageOverlayActive &&
                            <Overlay closeOverlayCallback={() => this.closePageSelectorOverlay()}>
                              <PageSelector
                                content={this.state.content}
                                selectPageCallback={(page) => this.handlePageSelect(page)}
                              />
                            </Overlay>
                          }
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
          }
        </>
    )
  }
}

export default BlockImageEdit
