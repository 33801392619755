import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Accordion from "../Shared/module/Accordion/Accordion";
import OfficePublicData from "./OfficePublicData";
import Editbar from "../Shared/element/Editbar/Editbar";
import Loader from "../Shared/atom/Loader/Loader";

import { AppContext } from '../../data/ContextProvider'

import { createCompany } from "../../data/api";
import { getToken } from "../../lib/lib";


class OfficeNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: {},
      accordions: [
        {
          title: "Öffentliche Angaben zur Kanzlei",
          isOpen: true
        },
      ]
    }
  }

  onChange = (event) => {
    const company = { ...this.state.company };
    company[event.target.name] = event.target.value;

    this.setState({ company }, () => {
      //this.validateField(name, value)
    });
  }

  onSave = async () => {
    this.setState({ isUpdating: true })
    const company = { ...this.state.company }

    createCompany(getToken(), company)
    .then(createdCompany => {
      const lang = this.props.match.params.lang
      this.props.history.push(`/${lang}/offices/${createdCompany.company.id}/data`);
    })
    .catch(err => {
      this.setState({ isUpdating: false })
    })
  }

  render() {
    if (!this.context.user.diro_admin) {
      return <Loader />
    }
    return (
      <div className="tab-pane fade show active">
          <Accordion
            title={this.state.accordions[0].title}
            isOpen={this.state.accordions[0].isOpen}
          >
            <OfficePublicData
              company={this.state.company}
              lang={this.props.match.params.lang || "de"}
              languages={this.state.languages}
              languagesUpdate={this.languagesUpdate}
              onChange={this.onChange}
              onLogoChange={this.onLogoChange}
              onLogoDelete={this.onLogoDelete}
            />
          </Accordion>


          <Editbar
            hasPublish={false}
            hasPreview={false}
            onSave={this.onSave}
            successMessage="Erfolgreich gespeichert"
          />
      </div>
    );
  }
}

OfficeNew.contextType = AppContext
export default withRouter(OfficeNew);
