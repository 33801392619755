import React, { Component } from "react";
import { withRouter } from "react-router-dom";


import Textfield from "../Shared/atom/Textfield/Textfield";
import { MainHelperText } from "../Shared/element/HelperText/HelperText";
import { AppContext } from "../../data/ContextProvider";
import { createPage } from "../../data/api";
import { getToken } from "../../lib/lib";

class PageNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      page: {}
    }
  }

  errorHandler = (hasError=false) => {
    this.setState({ hasError })
  }

  onChange = (event) => {
    const page = this.state.page
    page[event.target.name] = event.target.value
    this.setState({ page })
  }

  onSubmit = () => {
    const lang     = this.props.match.params.lang || "de"
    const pagetype = this.props.match.params.type || "page"

    const page = this.state.page
    page.pagetype = pagetype
    page.lang = lang

    createPage(getToken(), page)
    .then(page => {
      this.props.history.push(`/${lang}/pages/${page.page.id}/content`);
    })
    .catch(err => {
      console.log(err)
    })
  }

  render() {
    const formValid = (this.state.page.title && this.state.page.navigation_title) ? true : false
    const disabled = (this.state.hasError || !formValid) ? true : false

    return (
      <div className="tab-pane fade show active">
        <div className="card-body card-body--standard dd-background dd-background--sand">
          <div className="card-body__content w-75 ml-auto mr-auto">
            <div className="form-group">
              <Textfield
                name="title"
                type="text"
                isRequired={true}
                label="Titel des Beitrags"
                handleChange={this.onChange}
                errorHandler={this.errorHandler}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="navigation_title"
                type="text"
                isRequired={true}
                label="Navigationstitel"
                handleChange={this.onChange}
                errorHandler={this.errorHandler}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="author"
                type="text"
                isRequired={true}
                label="Autor"
                defaultValue={this.context.user.full_name}
                errorHandler={this.errorHandler}
              />
            </div>
          </div>
        </div>
        <MainHelperText
          disabled={disabled}
          onSubmit={this.onSubmit}
        />
      </div>
    )
  }
}

PageNew.contextType = AppContext
export default withRouter(PageNew)
