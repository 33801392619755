import React from "react";
import { withRouter } from "react-router-dom";
import Flatpickr from "react-flatpickr";

import Textfield from "../Shared/atom/Textfield/Textfield";
import RadioButton from "../Shared/atom/RadioButton/RadioButton";

import { German } from 'flatpickr/dist/l10n/de.js';


class PagePublishing extends React.Component {

  render() {

    const published_since = (this.props.page.published_since) ? new Date(this.props.page.published_since) : new Date()
    const published_until = (this.props.page.published_until) ? new Date(this.props.page.published_until) : new Date()

    return (
      <div className="tab-pane fade show active">
        <div className="card-body card-body--standard">
          <div className="card-body__content w-75 ml-auto mr-auto">
            <div className="form-row border-bottom border-black">
              <div className="form-group mt-3">
                <label className="textfield__label">Veröffentlichungsstatus</label>

                <RadioButton
                  name="published"
                  label="Entwurf"
                  value="0"
                  handleChange={(e) => this.props.onValueChange(e.target.name, !e.target.checked)}
                  selected={!this.props.page.published}
                  />

                <RadioButton
                  name="published"
                  label="Veröffentlicht"
                  value="1"
                  handleChange={(e) => this.props.onValueChange(e.target.name, e.target.checked)}
                  selected={this.props.page.published}
                />
              </div>
            </div>

            <div className="form-row border-bottom border-black">
              <div className="form-group mt-3">
                <label className="textfield__label">Freigabe für …</label>

                <RadioButton
                  name="released_for"
                  label={["Nur intern", <a key={"intranet_link"} href={"/"}>&nbsp;(Intranet)</a>]}
                  value="0"
                  handleChange={(e) => this.props.onValueChange(e.target.name, !e.target.checked)}
                  selected={!this.props.page.released_for}
                />

                <RadioButton
                  name="released_for"
                  label="Öffentlich (www)"
                  value="1"
                  handleChange={(e) => this.props.onValueChange(e.target.name, e.target.checked)}
                  selected={this.props.page.released_for}
                />

              </div>
            </div>
            <div className="form-row border-bottom border-black">
              <div className="form-group mt-3">
                <label className="textfield__label">Soll dieser Beitrag zeitgesteuert veröffentlicht werden?</label>

                <RadioButton
                  name="instant_release"
                  label="Nein (Veröffentlichung sofort und ohne Ablaufdatum)"
                  value="1"
                  handleChange={(e) => this.props.onValueChange(e.target.name, e.target.checked)}
                  selected={this.props.page.instant_release}
                />

                <RadioButton
                  name="instant_release"
                  label="Ja"
                  value="0"
                  handleChange={(e) => this.props.onValueChange(e.target.name, !e.target.checked)}
                  selected={!this.props.page.instant_release}
                />

              </div>
            </div>

            {(!this.props.page.instant_release) ?
              <React.Fragment>
                <div className="form-group mt-3">
                  <div className={"row"}>
                    <div className={"col-3"}>
                      <label className="textfield__label">Ab dem ...</label>
                      <Flatpickr
                      options={{
                        mode: "single",
                        dateFormat: "d-m-Y HH:MM",
                        altInput: true,
                        enableTime: true,
                        time_24hr: true,
                        locale: German,
                        altInputClass: "form-control textfield__input"
                      }}
                      value={published_since}
                      onChange={(date) => this.props.saveDateChange("published_since", date)}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group mt-3">
                  <div className={"row"}>
                    <div className={"col-3"}>
                      <label className="textfield__label">Bis zum ...</label>
                      <Flatpickr options={{
                        mode: "single",
                        dateFormat: "d-F-Y H:i",
                        altInput: true,
                        enableTime: true,
                        time_24hr: true,
                        locale: German,
                        altInputClass: "form-control textfield__input"
                      }}
                      value={published_until}
                      onChange={(date) => this.props.saveDateChange("published_until", date)}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
              :
              ""
            }
            <div className="form-row border-bottom border-black">
              <div className="form-group mt-3">
                <label className="textfield__label">Soll dieser Beitrag passwortgeschützt werden?</label>
                <Textfield
                  name="password"
                  type="text"
                  isRequired={false}
                  placeholder="Geben Sie hier Ihr Passwort ein"
                  handleChange={this.props.onChange}
                  defaultValue={this.props.page.password}
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(PagePublishing);
