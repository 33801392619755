import React from "react"

import Textfield from "../Shared/atom/Textfield/Textfield"
import Checkbox from "../Shared/atom/Checkbox/Checkbox"
import DndList from "../Shared/module/Dnd-list/Dnd-list"


class PersonVitaPublication extends React.Component {
  render() {
    return (
      <React.Fragment>
        <DndList
          tab="publications"
          lang={this.props.lang}
          rows={this.props.publications}
          headlines={this.props.headlines}
          fieldNames={this.props.fieldNames}
          onChange={this.props.onPublicationsUpdate}
        />
        <div style={{
          backgroundColor: 'white',
          width: '101%',
          margin: '-1px -1px',
          height: '4px'
        }}>
        </div>
        <div style={{
          width: '100%',
          padding: '20px 128px'
        }}>
          <div style={{
            'position': 'relative',
            'padding': '8px'
          }}>
            <div className="dnd__row">
              <div style={{
                'flex': '1 0 0',
                'display': 'flex',
                'padding': '9px 4px'
              }}>
                <Checkbox
                  label={this.props.person.has_publication_link ? "Link zur Publikationsliste" : "Link zur Publikationsliste hinzufügen"}
                  name="has_publication_link"
                  id="buttonTarget"
                  selected={this.props.person.has_publication_link}
                  onChange={(e) => this.props.changeValue("has_publication_link", e.target.checked)}
                  addStyles={true}
                />
              </div>
              <div style={{flex: '2 0 0'}}>
                {this.props.person.has_publication_link && this.props.lang === "de" &&
                  <Textfield
                    name="pub_path"
                    type="text"
                    defaultValue={this.props.person.pub_path}
                    handleChange={(e) => this.props.changeValue("pub_path", e.target.value)}
                  />
               }
              {this.props.person.has_publication_link && this.props.lang !== "de" &&
                  <Textfield
                    name={`pub_path_${this.props.lang}`}
                    type="text"
                    defaultValue={this.props.person[`pub_path_${this.props.lang}`]}
                    handleChange={(e) => this.props.changeValue(`pub_path_${this.props.lang}`, e.target.value)}
                  />
               }

              </div>
            </div>
            <div className="dnd__row">
              <div style={{
                'flex': '1 0 0',
                'display': 'flex',
                'padding': '9px 4px'
              }}>
                <label>Angepasste Überschrift der Publikationsliste</label>
              </div>
              <div style={{flex: '2 0 0'}}>

                <Textfield
                  name={`publication_custom_title_${this.props.lang}`}
                  type="text"
                  defaultValue={this.props.person[`publication_custom_title_${this.props.lang}`]}
                  handleChange={(e) => this.props.changeValue(`publication_custom_title_${this.props.lang}`, e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default PersonVitaPublication;