import React from "react";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";

import { AppContext } from "../data/ContextProvider";
import Login from "../components/Auth/Login";
import PasswordReset from "../components/Auth/PasswordReset";
import PasswordForgot from "../components/Auth/PasswordForgot";

class LoginPage extends React.Component {

  render() {
    return (
    <div className="login__container d-flex">
      <div className="card-image">
        <img src="https://picsum.photos/400/300" alt={"placeholder"} />
      </div>
      <div className="card-body">
      <Switch>
        <Route exact path="/:lang?/new_password/:reset_token">
          <PasswordReset />
        </Route>
        <Route exact path="/:lang?/password_forgot">
          <PasswordForgot />
        </Route>
        <Route exact path="/:lang?">
          <Login />
        </Route>
        <Redirect to='/' />
      </Switch>
      </div>
    </div>
    )
  }
}

LoginPage.contextType = AppContext
export default withRouter(LoginPage)