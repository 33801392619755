import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";

import { createID } from "../../../../lib/lib";

import Overlay from "../../element/Overlay/Overlay";
import { ImagePreviewBlock } from "../../element/ImagePreview/ImagePreview";
import ImageSelector from "../../module/ImageSelector/ImageSelector";

import BlockPreviewFiles from './BlockPreviews/Files';
import BlockSectionSettings from './BlockSectionSettings';
import BlockFactEdit from './BlockFactEdit';

import DnDRows from "../../../Shared/module/DnDRows/DnDRows";

import { getToken, imagePathForFormat } from "../../../../lib/lib";
import { getAsset } from "../../../../data/api";
import { AppContext } from "../../../../data/ContextProvider";
import BlockActions from "./BlockActions";

class BlockFactsCounterEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      imageOverlayActive: false,
      preview: true,
      showSettings: false,
    }
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    }, () => {
      if (this.state.preview) {
        this.context.toggleBlocksDraggable(false)
      } else {
        // Edit Mode, disable outer dragging
        this.context.toggleBlocksDraggable(true)
      }
    })
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  openImageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      imageOverlayActive: true
    })
  }

  closeImageSelectorOverlay = () => {
    this.setState({
      imageOverlayActive: false,
    })
  }

  handleImageDelete = () => {
    const content = this.state.content
    content.imagePath = ""
    content.imageID = 0

    this.saveContent(content)
    this.setState({
      imagePath: ""
    })
  }

  handleImageSelect = (file, format) => {
    const content = this.state.content
    const state = this.state
    content.imagePath = file[imagePathForFormat(format)]
    content.imageID = file.id
    state.imagePath = content.imagePath
    this.saveContent(content)
    this.closeImageSelectorOverlay()
  }


  addRow = (e) => {
    e.preventDefault()
    const content = this.state.content
    content.facts.push({
      'title': '',
      'number': 0,
      'decription': '',
      'key': createID(),
    })
    this.saveContent(content)
  }

  onRowChange = (row, index) => {
    const content = this.state.content

    if (index < content.facts.length ) {
      content.facts[index] = row
      this.saveContent(content)
    }
  }

  onRowsChange = (rows) => {
    const content = this.state.content
    content.facts = rows
    this.saveContent(content)
  }

  onChange = (event) => {
    const content = this.state.content
    content[event.target.name] = event.target.value
    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  async componentDidMount() {

    let image = {}
    let imagePath = ""

    if (this.props.initData) {
      const preview = this.props.preview
      const content = this.props.initData[0]

      try {
        if (content.imageID) {
          image = await getAsset(getToken(), content.imageID)
          imagePath = image.asset[imagePathForFormat(content.imageFormat)]
        }
      } catch (error) {
        console.log(error)
      }


      this.setState({ content, preview, imagePath })
    }
  }

  renderPreviews = () => {
    const previews = []
    if (this.state.content.facts) {
      this.state.content.facts.map((fact, index) => {
        return previews.push(
          <BlockFactEdit
            fact={fact}
            key={fact.key}
            onChange={(val) => this.onRowChange(val, index)}
          />
        )
      })
    }
    return previews
  }

  render() {

    return (
      <>
        {this.state.preview
          ? <div className="dd-block" onClick={() => this.togglePreviewEditMode()}>
              <div className="dd-block__preview">
                <BlockPreviewFiles block={this.props.initData} />
              </div>
            </div>
          :
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-fact-counter" /> Fakten-Counter
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }

              <div className="dd-block__edit-section">

                <div className="form-group">
                  <Textfield
                    name="title"
                    type="text"
                    label=""
                    placeholder="Titel des Teasers"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.title}
                    errorHandler={this.props.errorHandler}
                  />
                </div>
                <div className="form-group">
                  <div className="dd-image-picker">
                    {this.state.imagePath ?
                      <ImagePreviewBlock
                        imagePath={this.state.imagePath}
                        imageId={this.state.imageId}
                        handleImageDelete={this.handleImageDelete}
                        openImageSelectorOverlay={this.openImageSelectorOverlay}
                      />
                      :
                      <button className="dd-btn dd-btn--primary dd-image-picker__choose" onClick={(e) => this.openImageSelectorOverlay(e)}>
                        Bild auswählen
                      </button>
                    }
                  </div>
                   {this.state.imageOverlayActive &&
                        <Overlay closeOverlayCallback={() => this.closeImageSelectorOverlay()}>
                          <ImageSelector
                            selectImageCallback={(file, format) => this.handleImageSelect(file, format)}
                            selectedAssetId={this.state.content.imageID || ''}
                            selectedFormat={this.state.content.imageFormat || ''}
                            fileTypes={["image"]}
                          />
                        </Overlay>
                      }
                </div>



                <div className="dd-mini-block">
                  <div className="dd-mini-block__blocks">
                    <DnDRows
                      rows={this.renderPreviews()}
                      rowsData={this.state.content.facts}
                      handleChange={this.onRowsChange}
                    />
                  </div>
                </div>
                <div className="dd-mini-block__add">
                  <button className={"dd-btn dd-btn--primary dd-btn--icon-only"} onClick={(e) => this.addRow(e)}>
                    <Icon icon="plus" />
                  </button>
                </div>
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
        }
      </>
    )
  }
}

BlockFactsCounterEdit.contextType = AppContext
export default BlockFactsCounterEdit
