import React from "react";

import Icon from "../../atom/Icon/Icon";
import BlockPreviewDefault from './BlockPreviews/Default';
import BlockPreviewNumberedList from './BlockPreviews/NumberedList';
import BlockPreviewBulletedList from './BlockPreviews/BulletedList';
import BlockSectionSettings from './BlockSectionSettings';
import Loader from "../../../Shared/atom/Loader/Loader";
import BlockActions from "./BlockActions";

import { createEditor } from "./util";

class BlockDefault extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      editor: {},
      editorSetup: false,
      preview: true,
      loading: true,
      showSettings: false,
    }
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  onChangeEditor = (key, value) => {
    const content = value
    this.setState({ content })
    this.props.onChange(this.props.blockKey, value)
  }

  saveContent = (first_item) => {
    const content = JSON.parse(JSON.stringify(this.state.content));
    content[0] = first_item
    this.setState({ content: content }, () => {
      this.props.onChange(this.props.blockKey, content)
    })
  }

  renderBlockPreview() {
    const defaultTemplateTypes = ['default', 'h1', 'h2', 'h3', 'h4']
    const type = this.props.type

    if (defaultTemplateTypes.includes(type)) {
      return <BlockPreviewDefault block={this.state.content} type={type} />
    }

    if (type === 'numbered-list') {
      return <BlockPreviewNumberedList block={this.state.content} type={type} />
    }

    if (type === 'bulleted-list') {
      return <BlockPreviewBulletedList block={this.state.content} type={type} />
    }

    return 'MISSING PREVIEW TEMPLATE ' + this.props.type;
  }

  componentDidMount() {
    if (this.props.initData) {

      const content = this.props.initData
      const preview = this.props.preview

      // init editor
      const editor = createEditor(this.props.index, this.props.type, this.onChangeEditor, this.props.initData)


      if (content && content[0]) {

        //backwards compatibility
        if (!content[0].section) {
          content[0]["section"] = { 'background': '', 'spacing': '' }
        }
      }


      this.setState({
        preview,
        content,
        editor,
        editorSetup: true,
        loading: false
      })
    }
  }

  renderBlockContent = () => {
    if (this.state.editorSetup) {
      return createEditor(this.props.index, this.props.type, this.onChangeEditor, this.state.content)
    } else {
      return []
    }
  }


  render() {
    if (this.state.loading) {
      return <Loader />
    }

    let test = []
    if (this.state.content && this.state.content[0]) {
      test = JSON.parse(JSON.stringify(this.state.content[0]));
    }

    return (
      <>
        {this.state.preview
          ? <div className="dd-block dd-block--default" onClick={() => this.togglePreviewEditMode()}>
            <div className="dd-block__preview">
              {this.renderBlockPreview()}
            </div>
          </div>
          :
          <div className="dd-block dd-block--default dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-paragraph" /> Editor
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={test}
                  saveContent={this.saveContent}
                />
              }

              <div className="dd-block__edit-section">
                <div className="form-group">
                  { this.renderBlockContent() }
                </div>
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
        }
      </>
    )
  }
}

export default BlockDefault;
