import React from 'react';
import { createRoot } from 'react-dom/client';

import { BrowserRouter as Router } from "react-router-dom";
import './sass/styles.sass';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ContextProvider from "./data/ContextProvider";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ContextProvider>
    <Router  >
      <App />
    </Router>
  </ContextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
