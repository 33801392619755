import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";
import PreviewIntroText from './BlockPreviews/IntroText';
import BlockSectionSettings from './BlockSectionSettings';
import BlockActions from "./BlockActions";

class BlockIntroTextEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      preview: true,
      showSettings: false,
    }
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  onChange = (name, value) => {
    const content = this.state.content
    content[name] = value
    this.saveContent(content)
  }

  componentDidMount() {
    if (this.props.initData) {

      const content = this.props.initData[0]
      const preview = this.props.preview

      this.setState({
        preview,
        content
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        {
          this.state.preview
          ? <div className="dd-block dd-block--default" onClick={() => this.togglePreviewEditMode()}>
            <div className="dd-block__preview">
              <PreviewIntroText block={this.state.content} type='textarea' />
            </div>
          </div>
          :
          <div className="dd-block dd-block--default dd-block--edit">
            <div className="dd-block__edit">
                <div className="dd-block__edit-info">
                  <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                    <Icon icon="settings" />
                  </button>
                  <Icon icon="btn-intro-text" /> Intro-Text
              </div>

                {this.state.showSettings &&
                  <BlockSectionSettings
                    content={this.state.content}
                    saveContent={this.saveContent}
                  />
                }

              <div className="dd-block__edit-section">
                  <div className="form-group">
                    <Textfield
                      name="headline"
                      type="text"
                      isRequired={false}
                      label=""
                      placeholder="Bitte hier die aussagekräftige Überschrift eintragen"
                      handleChange={(e) => this.onChange(e.target.name, e.target.value)}
                      defaultValue={this.state.content.headline}
                    />
                  </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="text"
                    onChange={(e) => this.onChange(e.target.name, e.target.value)}
                    defaultValue={this.state.content.text}
                    placeholder="Bitte hier den gewünschten Text eintragen"
                  />
                </div>
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}

export default BlockIntroTextEdit;
