import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Flatpickr from 'react-flatpickr'

import Loader from '../Shared/atom/Loader/Loader'
import Checkbox from '../Shared/atom/Checkbox/Checkbox'
import SimpleSelectfield from '../Shared/atom/Selectfield/SimpleSelectfield'
import TagList from '../Shared/atom/TagList/TagList'

import { German } from 'flatpickr/dist/l10n/de.js'

import { getTerms, getCompany } from '../../data/api'
import { getToken, dateToRailsDateString } from '../../lib/lib'

class JobDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  loadData = async () => {
    const jobtimecontigents = await getTerms(getToken(), 'jobtimecontigents')
    const joblevels = await getTerms(getToken(), 'joblevels')
    const jobtypes = await getTerms(getToken(), 'jobtypes')
    const company = await getCompany(getToken(), this.props.job.company_id)

    // Sort alphabetically and put hq first

    company.company.company_sites.sort((a,b) => {
      const acity = a.city.title_de;
      const bcity = b.city.title_de;
      return (acity < bcity) ? -1 : (acity > bcity) ? 1 : 0;
    }).sort((a) => {
      const site_id = a.id;
      const hq_id = company.company.hq.id;
      return site_id === hq_id ? -1 : 0;
    })

    this.setState({
      jobtimecontigents: jobtimecontigents.jobtimecontigents,
      joblevels: joblevels.joblevels,
      jobtypes: jobtypes.jobtypes,
      company: company.company,
      loading: false
    })
  }

  siteCityLabel = (site) => {
    return site.city.title_de
  }

  componentDidMount() {
    this.loadData()
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    const occupy_from = this.props.job.occupy_from
      ? new Date(this.props.job.occupy_from)
      : new Date()
    const occupy_until = this.props.job.occupy_until
      ? new Date(this.props.job.occupy_until)
      : new Date()

    const hasMultipleSites = this.state.company.company_sites.length > 1;
    const company_sites = this.state.company.company_sites;

    return (
      <React.Fragment>
        <div className="form-row">
          <div className="col-md-6 mb-2">
            { (hasMultipleSites)
              ?
                (<TagList
                  labelCallback={this.siteCityLabel}
                  name="Standorte"
                  data={this.props.job.company_sites}
                  availableData={company_sites}
                  label="Tätigkeit am Standort"
                  onUpdate={(assignedTags) =>
                    this.props.onChange('company_sites', assignedTags)
                  }
                />)
              :
                (<TagList
                  labelCallback={this.siteCityLabel}
                  name="Standorte"
                  data={[{ city: company_sites[0].city }]}
                  label="Tätigkeit am Standort"
                  isPlusButtonVisible={false}
                  disabled={true}
                />)
            }
          </div>
          <div className="col-md-6 mb-2 mt-3">
            <label className="selectfield__label"></label>
            <Checkbox
              name="noLocation"
              label="Keine Standortbindung"
              value={'noLocation'}
              onChange={() => this.props.onChange("noLocation", !this.props.job.noLocation)}
              selected={this.props.job.noLocation || false}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-2">
            <SimpleSelectfield
              label="Einstiegslevel"
              name="joblevel"
              firstOptionValue="0"
              firstOptionLabel="Bitte auswählen"
              indexLabel="id"
              optionLabel="title_de"
              options={this.state.joblevels}
              onSelect={(value) => this.props.onChange("joblevel_id", value)}
              selected={this.props.job.joblevel_id}
            />
          </div>
          <div className="col-md-6 mb-2">
            <SimpleSelectfield
              label="Anstellungsverhältnis"
              name="jobtype"
              firstOptionValue="0"
              firstOptionLabel="Bitte auswählen"
              indexLabel="id"
              optionLabel="title_de"
              options={this.state.jobtypes}
              onSelect={(value) => this.props.onChange("jobtype_id", value)}
              selected={this.props.job.jobtype_id}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-2">
            <SimpleSelectfield
              label="Zeitkontigent der Stelle"
              name="jobtimecontigent"
              firstOptionValue="0"
              firstOptionLabel="Bitte auswählen"
              indexLabel="id"
              optionLabel="title_de"
              options={this.state.jobtimecontigents}
              onSelect={(value) => this.props.onChange("jobtimecontigent_id", value)}
              selected={this.props.job.jobtimecontigent_id}
            />
          </div>
          <div className="col-md-6 mb-2"></div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-2">
            <label className="selectfield__label">
              Die Stelle wird besetzt ab
            </label>
            <Flatpickr
              options={{
                mode: 'single',
                dateFormat: 'd-m-Y',
                altInput: true,
                locale: German,
                altInputClass: 'form-control textfield__input'
              }}
              value={occupy_from}
              onChange={(date) => this.props.onChange('occupy_from', dateToRailsDateString(date[0]))}
            />
          </div>

          {parseInt(this.props.job.jobtype_id) === 2 && (
            <div className="col-md-6 mb-2">
              <label className="selectfield__label">
                Die Stelle wird besetzt bis
              </label>
              <Flatpickr
                options={{
                  mode: 'single',
                  dateFormat: 'd-m-Y',
                  altInput: true,
                  locale: German,
                  altInputClass: 'form-control textfield__input'
                }}
                value={occupy_until}
                onChange={(date) =>
                  this.props.onChange('occupy_until', dateToRailsDateString(date[0]))
                }
              />
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(JobDetails)
