import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";
import DnDRows from "../../../Shared/module/DnDRows/DnDRows";

import BlockPreviewFiles from './BlockPreviews/Files';
import BlockSectionSettings from './BlockSectionSettings';
import BlockTextImageSlideEdit from './BlockTextImageSlideEdit';

import { createID } from "../../../../lib/lib";
import { AppContext } from "../../../../data/ContextProvider";
import BlockActions from "./BlockActions";

class BlockTextImageSliderEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      imageOverlayActive: false,
      preview: true,
      showSettings: false,
    }
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    }, () => {
      if (this.state.preview) {
        this.context.toggleBlocksDraggable(false)
      } else {
        // Edit Mode, disable outer dragging
        this.context.toggleBlocksDraggable(true)
      }
    })
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  addRow = (e) => {
    e.preventDefault()
    const content = this.state.content
    content.items.push({
      'text': '',
      'imageID': 0,
      'fileName': '',
      'filePath': 0,
      'key': createID(),
      'newRow': true,
    })
    this.saveContent(content)
  }

  onRowChange = (row, index) => {
    const content = this.state.content

    if (index < content.items.length ) {
      row.newRow = false
      content.items[index] = row
      this.saveContent(content)
    }
  }

  onRowsChange = (rows) => {
    const content = this.state.content
    content.items = rows
    this.saveContent(content)
  }

  onChange = (event) => {
    const content = this.state.content
    content[event.target.name] = event.target.value
    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  componentDidMount() {
    if (this.props.initData) {
      const preview = this.props.preview
      const content = this.props.initData[0]

      this.setState({ content, preview })
    }
  }

  renderPreviews = () => {
    const previews = []
    if (this.state.content.items) {
      this.state.content.items.map((item, index) => {
        return previews.push(
          <BlockTextImageSlideEdit
            item={item}
            key={item.key}
            onChange={(val) => this.onRowChange(val, index)}
          />
        )
      })
    }
    return previews
  }

  render() {
    return (
      <>
        {this.state.preview
          ? <div className="dd-block" onClick={() => this.togglePreviewEditMode()}>
              <div className="dd-block__preview">
                <BlockPreviewFiles block={this.props.initData} />
              </div>
            </div>
          :
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-text-image-slider" /> Text-Bild-Slider
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }

              <div className="dd-block__edit-section">

                <div className="form-group">
                  <Textfield
                    name="title"
                    type="text"
                    label=""
                    placeholder="Titel des Teasers"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.title}
                    errorHandler={this.props.errorHandler}
                  />

                </div>

                <div className="dd-mini-block">
                  <div className="dd-mini-block__blocks">
                    <DnDRows
                      rows={this.renderPreviews()}
                      rowsData={this.state.content.items}
                      handleChange={this.onRowsChange}
                    />
                  </div>
                </div>
                <div className="dd-mini-block__add">
                  <button className={"dd-btn dd-btn--primary dd-btn--icon-only"} onClick={(e) => this.addRow(e)}>
                    <Icon icon="plus" />
                  </button>
                </div>
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
        }
      </>
    )
  }
}

BlockTextImageSliderEdit.contextType = AppContext
export default BlockTextImageSliderEdit
