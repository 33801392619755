import React, { Component } from "react";
import { withRouter } from "react-router-dom";


// import Loader from "../Shared/atom/Loader/Loader";
import Textfield from "../Shared/atom/Textfield/Textfield";
import { AppContext } from '../../data/ContextProvider'

import {  resetPassword } from "../../data/api";


class PasswordForgot extends Component {

  constructor(props) {
    super(props)
    const resetToken = (props.match && props.match.params && props.match.params.reset_token) ? props.match.params.reset_token : ''
    this.state = {
      step: props.step ? props.step : 0,
      error: "",
      message: "",
      email: '',
      password: '',
      resetToken: resetToken,
      hasSentPasswordResetReq: false,
    }
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const state = this.state
    state[name] = value
    this.setState({ ...state });
  }



  renderPasswordResetSuccess() {
    // set Message to State
    return (
      <form onSubmit={(e) => false}>
        <h2>Ihr Passwort wurde zurückgesetzt</h2>
        <p className="card-info">
          Wir haben Ihnen einen Link zugeschickt, mit dem das Passwort zurückgesetzt werden kann.
          <br />
          <br />
          Wenn Sie die Mail
          nicht sofort finden, sehen Sie bitte auch im Spamverdachtsordner nach.
          <br />
          <br />
          Wenn diese nicht geklappt hat, melden
          Sie sich bitte telefonisch unter +49 40 41352231
        </p>
        <div className="form-group d-flex align-items-center justify-content-between">
          <button onClick={() => window.location.href = 'https://diro.eu/' }
            className="dd-btn dd-btn--primary ml-auto"
            type="button">Zur DIRO-Homepage</button>
        </div>
      </form>
    )
  }

  handlePasswordReset = async (e) => {
    e.preventDefault();

    try {
      // eslint-disable-next-line
      const response = await resetPassword({
        email: this.state.email,
      })
      this.setState({
        hasSentPasswordResetReq: true,
      })
    } catch (error) {
      // console.log(error.data)
      if (error.data && error.data.error) {
        this.setState({
          message: error.data.error,
          error: true
        })
      }
    }
  }


  render() {
    // if (!this.context.user.diro_admin) {
    //   return <Loader />
    // }

    if (this.state.hasSentPasswordResetReq) {
      return this.renderPasswordResetSuccess()
    }

    return (
      <form onSubmit={(e) => this.handlePasswordReset(e)}>
      <h2>Passwort vergessen</h2>
      {this.state.message &&
      <p className={this.state.error ? "text-danger" : "text-info" }>
        {this.state.message}
      </p>
      }
      <p className="card-info">
        Geben Sie bitte Ihre E-Mail-Adresse ein. Sie bekommen dann eine E-Mail zugeschickt, mit der Sie Ihr
        Passwort zurücksetzen zu können.
      </p>
      <Textfield
        name={"email"}
        label={"E-Mail"}
        handleChange={this.handleChange}
        isRequired={true}
        type={"email"}
      />
      <div className="form-group d-flex align-items-center justify-content-between">
        <button
          className="dd-btn dd-btn--primary ml-auto"
          type="submit"
        >
          Neues Passwort anfordern
        </button>
      </div>
    </form>
    );
  }
}

PasswordForgot.contextType = AppContext
export default withRouter(PasswordForgot);
