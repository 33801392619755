import React from "react";
import { withRouter, Route, Switch } from "react-router-dom";

import PagesTabs from "../components/Pages/PagesTabs";
import PageTypes from "../components/Pages/PageTypes";
import PagesTable from "../components/Pages/PagesTable";
import NavBuilder from "../components/NavBuilder/NavBuilder";
import FooterBuilder from "../components/FooterBuilder/FooterBuilder";
import PageNew from "../components/Pages/PageNew";
import PageContent from "../components/Pages/PageContent";
import PageTypeEdit from "../components/Pages/PageTypeEdit";

class  Pages extends React.Component {
  render() {
    return (
      <div className="tab-content" id="nav-tabContent">
        <Switch>
          <Route exact path="/:lang/pages">
            <PagesTabs headline="Seiten"/>
            <PagesTable />
          </Route>

          <Route exact path="/:lang/pages/menu">
            <PagesTabs headline="Seiten" />
            <NavBuilder />
          </Route>

          <Route exact path="/:lang/pages/footer">
            <PagesTabs headline="Footernavigation" />
            <FooterBuilder />
          </Route>

          <Route exact path="/:lang/pages/new/:type">
            <PageNew />
          </Route>

          <Route exact path="/:lang/pages/types">
            <PagesTabs headline="Seitentypen" />
            <PageTypes />
          </Route>

          <Route exact path="/:lang/pages/types/:pagetype_id">
            <PagesTabs headline="Seitentypen" />
            <PageTypeEdit />
          </Route>

          <Route exact path="/:lang/pages/:page_id/:tabs">
            <PageContent />
          </Route>
        </Switch>
      </div>
    )
  }
}

export default withRouter(Pages)
