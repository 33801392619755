import React from "react";

const BlockPreviewBanner = (props) => {

  const content = props.block

  return <div className="dd-block-preview dd-block-preview--intro-text">
    <strong>{content.title}</strong>
    <p>{content.text}</p>
  </div>
}

export default BlockPreviewBanner;
