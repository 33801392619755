import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";
import RadioButton from "../../atom/RadioButton/RadioButton";
import Checkbox from "../../atom/Checkbox/Checkbox";
import Overlay from "../../element/Overlay/Overlay";
import { ImagePreviewBlock } from "../../element/ImagePreview/ImagePreview";
import ImageSelector from "../ImageSelector/ImageSelector";
import PageSelector from "../PageSelector/PageSelector";
import Loader from "../../atom/Loader/Loader";

import BlockPreviewTextImage from './BlockPreviews/TextImage';
import BlockSectionSettings from './BlockSectionSettings';

import  { createEditor } from "./util";
import { getToken, imagePathForFormat } from "../../../../lib/lib";
import { getPage, getAsset, getArticle, getProduct } from "../../../../data/api";

import { initValue } from "./util";
import BlockActions from "./BlockActions";

class BlockTextImageEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      defaultContent: '',
      pageOverlayActive: false,
      imageOverlayActive: false,
      preview: true,
      loading: true,
      showSettings: false,
      editorSetup: false,
    }
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  openImageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      imageOverlayActive: true
    })
  }

  closeImageSelectorOverlay = () => {
    this.setState({
      imageOverlayActive: false,
    })
  }

  handleImageDelete = () => {
    const content = this.state.content
    const state   = this.state
    content.imageID     = 0
    content.imageFormat = ""
    content.imagePath   = ""
    state.imagePath = ""

    this.saveContent(content, state)
  }

  handleImageSelect = (file, format) => {
    const content = this.state.content
    const state   = this.state
    content.imagePath = file[imagePathForFormat(format)]
    content.imageID   = file.id
    content.imageFormat  = format
    state.imagePath = content.imagePath
    this.saveContent(content, state)
    this.closeImageSelectorOverlay()
  }

  openPageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      pageOverlayActive: true
    })
  }

  closePageSelectorOverlay = () => {
    this.setState({
      pageOverlayActive: false,
    })
  }

  handlePageSelect = (page) => {
    const content = this.state.content

    if (page.id) {
      content.pageID    = page.id
      content.pageTitle = page.title
      content.pageType  = page.content_type
      content.buttonURL = ""
    } else {
      content.pageID = ""
      content.articleID = ""
      content.buttonURL = page.buttonURL
    }

    content.buttonTarget = page.buttonTarget

    this.saveContent(content)
    this.closePageSelectorOverlay()
    this.setState({ page: page })
  }

  onChange = (event, name) => {
    const content = this.state.content
    const key = (typeof name !== 'undefined') ? name : event.target.name
    content[key] = event.target.value
    this.saveContent(content)
  }

  onChangeEditor = (key, value) => {
    const content = this.state.content

    content.editor.content = value
    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  hasButtonChange = () => {
    const content = this.state.content
    content.hasButton = !this.state.content.hasButton

    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  renderBlockContent = () => {
    const content = {...this.state.content}
    if (this.state.editorSetup) {
      return createEditor(this.props.index, "default", this.onChangeEditor, content.editor.content)
    } else {
      return []
    }
  }


  async componentDidMount() {
    if (this.props.initData) {
      const preview = this.props.preview
      const content = this.props.initData[0]

      if (!content.editor) {
        content.editor = {
          editor: "",
          content: [{
            type: "default",
            children: [
              { text: "default" }
            ],
          }],
          type: "",
        }
      }

      // Issue #212
      let contentInput = []
      if (!Array.isArray(content.editor.content)) {
        contentInput = [content.editor.content]
      } else {
        contentInput = content.editor.content
      }

      let page = {}
      let image = {}
      let imagePath = ""

      if (content.pageID) {
        try {

          if (content.pageType === 'page') {
            const pageData = await getPage(getToken(), content.pageID)
            page = pageData.page
          } else if (content.pageType === 'article') {
            const pageData = await getArticle(getToken(), content.pageID)
            page = pageData.article
          } else if (content.pageType === 'product') {
            const pageData = await getProduct(getToken(), content.pageID)
            page = pageData.product
          }

        } catch (error) {
          console.log("Error: BlockTextImageEdit - pageID konnte nicht geladen werden: ", content.pageID)
        }
      }

      // FIXME DEPRECATED
      if (content.articleID) {
        try {
          const articleData = await getArticle(getToken(), content.articleID)
          page = articleData.article
        } catch (error) {
          console.log("Error: BlockTextImageEdit - pageID konnte nicht geladen werden: ", content.pageID)
        }
      }

      try {
        if (content.imageID) {
          image = await getAsset(getToken(), content.imageID)
          imagePath = image.asset[imagePathForFormat(content.imageFormat)]
        }
      } catch (error) {
        console.log(error)
      }

      // init editor
      content.editor.editor = createEditor(this.props.index, "default", this.onChangeEditor, contentInput)

      let defaultContent = initValue(this.props.type)[0]
      defaultContent.editor.editor = content.editor.editor
      defaultContent = JSON.stringify(defaultContent)

      this.setState({ content, page, imagePath, loading: false, editorSetup: true, preview, defaultContent })
    }
  }


  renderSelectedItemLabel = () => {
    if (this.state.content.buttonURL) {
      return this.state.content.buttonURL
    } else if (this.state.content.pageID && this.state.page && this.state.page.title) {
      return `${this.state.page.title} (${this.state.page.navigation_title})`
    } else if (this.state.content.articleID && this.state.page && this.state.page.title) {
      return `${this.state.page.title}`
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    const viewmode = "viewmode__" + this.props.index
    const imagePosition = "imagePosition__" + this.props.index

    return (
        <>
        {this.state.preview
          ? <div className="dd-block" onClick={() => this.togglePreviewEditMode()}>
            <div className="dd-block__preview">
              <BlockPreviewTextImage block={this.props.initData} imagePath={this.state.imagePath}/>
            </div>
          </div>
          :
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-text-image" /> Text/Bild
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }

              <div className="dd-block__edit-section">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <label className="radio-button__group-label text-nowrap ">Darstellung auswählen</label>
                    <RadioButton
                      name={viewmode}
                      label="Standard"
                      value="default"
                      handleChange={(e) => this.onChange(e, 'viewmode')}
                      selected={this.state.content.viewmode === "default"}
                    />
                    <RadioButton
                      name={viewmode}
                      label="Bühne"
                      value="stage"
                      handleChange={(e) => this.onChange(e, 'viewmode')}
                      selected={this.state.content.viewmode === "stage"}
                    />
                    <RadioButton
                      name={viewmode}
                      label="Hinweis"
                      value="alert"
                      handleChange={(e) => this.onChange(e, 'viewmode')}
                      selected={this.state.content.viewmode === "alert"}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <Textfield
                    name="kicker"
                    type="text"
                    isRequired={false}
                    label=""
                    placeholder="Dachzeile"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.kicker}
                  />
                </div>
                <div className="form-group">
                  <Textfield
                    name="title"
                    type="text"
                    label=""
                    placeholder="Titel des Teasers"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.title}
                    errorHandler={this.props.errorHandler}
                  />
                </div>

                <div className="form-group">
                  {this.renderBlockContent()}
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col">
                      <Checkbox
                        label="Mit Button"
                        id="teaser_button"
                        selected={this.state.content.hasButton}
                        onChange={(e) => this.hasButtonChange(e)}
                      />
                    </div>
                    {this.state.content.hasButton &&
                      <div className="col">
                        <Textfield
                          name="buttonTitle"
                          type="text"
                          isRequired={false}
                          label="Beschriftung"
                          placeholder="Mehr erfahren"
                          handleChange={this.onChange}
                          defaultValue={this.state.content.buttonTitle}
                        />
                      </div>
                    }
                  </div>
                </div>
                {this.state.content.hasButton &&
                  <div className="form-group">

                    <div className="dd-link-selector">
                      <div className="dd-link-selector__link">{this.renderSelectedItemLabel()}</div>
                      <button className="dd-btn dd-btn--primary dd-link-selector__button" onClick={(e) => this.openPageSelectorOverlay(e)}>
                          Linkziel ändern
                      </button>
                    </div>
                    {this.state.pageOverlayActive &&
                      <Overlay closeOverlayCallback={() => this.closePageSelectorOverlay()}>
                        <PageSelector
                          content={this.state.content}
                          selectPageCallback={(page) => this.handlePageSelect(page)}
                        />
                      </Overlay>
                    }
                  </div>
                }
              </div>

              <div className="dd-block__edit-section">
                <div className="form-group">
                  <div className="row">
                    <div className="col">

                      <div className="dd-image-picker">
                        {this.state.imagePath ?
                          <ImagePreviewBlock
                            imagePath={this.state.imagePath}
                            imageId={this.state.imageId}
                            handleImageDelete={this.handleImageDelete}
                            openImageSelectorOverlay={this.openImageSelectorOverlay}
                          />
                          :
                          <button className="dd-btn dd-btn--primary dd-image-picker__choose" onClick={(e) => this.openImageSelectorOverlay(e)}>
                            Bild auswählen
                          </button>
                        }
                      </div>


                      {this.state.imageOverlayActive &&
                        <Overlay closeOverlayCallback={() => this.closeImageSelectorOverlay()}>
                          <ImageSelector
                            selectImageCallback={(file, format) => this.handleImageSelect(file, format)}
                            selectedAssetId={this.state.content.imageID || ''}
                            selectedFormat={this.state.content.imageFormat || ''}
                            fileTypes={["image"]}
                          />
                        </Overlay>
                      }
                    </div>

                    <div className="col">
                      {/* #FIXME: Hide if no image is available */}
                      <div className="form-check form-check-inline d-flex">
                        <RadioButton
                          name={imagePosition}
                          label="Bild links"
                          value="left"
                          selected={this.state.content.imagePosition === "left"}
                          handleChange={(e) => this.onChange(e, 'imagePosition')}
                        />
                        <RadioButton
                          name={imagePosition}
                          label="Bild rechts"
                          value="right"
                          selected={this.state.content.imagePosition === "right"}
                          handleChange={(e) => this.onChange(e, 'imagePosition')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
          }
        </>
    )
  }
}

export default BlockTextImageEdit
