import React from "react";

const BlockPreviewTeam = (props) => {

  const content = props.block[0] // FIXME

  return <div className="dd-block-preview dd-block-preview--team">
    <h4 className="dd-block-preview__team-headline">{content.title}</h4>
    <div className="dd-block-preview__team-blocks">
      <div className="dd-block-preview__team-block">
        <div className="dd-block-preview__team-person"></div>
        <div className="dd-block-preview__team-text"></div>
      </div>
      <div className="dd-block-preview__team-block">
        <div className="dd-block-preview__team-person"></div>
        <div className="dd-block-preview__team-text"></div>
      </div>
    </div>
  </div>
}

export default BlockPreviewTeam;
