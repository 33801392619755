import React from "react";

import NetworkProducts from "../components/Dashboard/NetworkProducts";
import { AppContext } from "../data/ContextProvider";

class Dashboard extends React.Component {
  render() {
    return (
      <div className="tab-content" id="nav-tabContent">
        {this.context.company.is_diro_member &&
          <NetworkProducts />
        }
      </div>
    )
  }
}

Dashboard.contextType = AppContext
export default Dashboard