import React from "react";
import { withRouter } from "react-router-dom";

import Icon from "../../../Shared/atom/Icon/Icon";
import { AppContext } from "../../../../data/ContextProvider";
import { getToken } from '../../../../lib/lib'
import { translateItem } from '../../../../data/api'


class LangSwitcher extends React.Component {

  translateItem = async (id, lang) => {
    const response = await translateItem(getToken(), id, lang, this.props.controller)
    this.props.history.push(`/${this.props.match.params.lang}/${this.props.controller}/${response[this.props.itemType].id}/content`)
    this.props.history.go()
  }

  renderLi = (key, shorthand, item, hasTranslation, translations) => {
    const lang = this.props.match.params.lang

    if (shorthand === item.lang) {
      return <li key={key} className="is-active">{shorthand}</li>
    } else if (hasTranslation) {
      return (
        <a key={key} href={`/${lang}/${this.props.controller}/${translations[shorthand]}/${this.props.match.params.tabs}`}>
          <li>{shorthand}</li>
        </a>
      )
    } else {
      return (
        <li key={key} className="inactive">{shorthand}
          <button type="submit" className="dd-btn dd-btn--primary dd-btn--icon-only" onClick={() => this.translateItem(item.id, shorthand)}>
            <Icon icon="plus" />
          </button>
        </li>
      )
    }
  }

  render() {
    const item = this.props.item
    const translations = Object.keys(this.props.item.translations)

    return (
      <ul className={`languages-switcher`}>
        {this.context.syslanguages.map((language, index) => {
          return this.renderLi(item.id + "-" + index, language.shorthand, item, translations.includes(language.shorthand), this.props.item.translations)
          })
        }
      </ul>
    )
  }
}

LangSwitcher.contextType = AppContext
export default withRouter(LangSwitcher);
