import React from "react";
import { withRouter } from "react-router-dom";

class Title extends React.Component {

  render() {
    return (
      <div className="dd-title">
        <div className="dd-title__navigation">
          {this.props.children}
        </div>
        <h1 className="dd-title__headline">
          {this.props.headline}
        </h1>
      </div>
    );
  }
}

export default withRouter(Title);