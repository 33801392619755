import React from "react";

export const MainHelperText = props => {
  const className = (props.disabled) ? "dd-btn dd-btn--primary dd-btn--outline" : "dd-btn dd-btn--primary"
  return (
    <div className="text-center">
      <div className="my-4">
        Bitte füllen Sie zunächst die oben angegebenen Felder aus und speichern Sie die Seite,<br />
        um mit der weiteren Bearbeitung fortfahren zu können.<br />
        – Keine Sorge, Sie können die Einträge später jederzeit bearbeiten. –
      </div>
      <button
        disabled={props.disabled}
        className={className}
        onClick={() => props.onSubmit()}
      >
        Speichern und fortfahren
      </button>
    </div>
  )
}
