import React from "react";

const BlockPreviewTeaserCollection = (props) => {

  const content = props.block[0] // FIXME
  const teasers = [1, 2, 3]

  return <div className="dd-block-preview dd-block-preview--teaser-collection">
    <h2>{content.headline}</h2>
    <div className="d-flex">
      {
        teasers.map((key) => {
          return <div key={key} className="dd-block-preview__teaser-collection-teaser">
          <p>An dieser Stelle werden die aktuellsten Einträge angezeigt.</p>
          </div>
        })
      }
    </div>
    <span className="dd-btn dd-btn--outline dd-btn--primary">{content.buttonText}</span>
  </div>
}

export default BlockPreviewTeaserCollection;
