import React from "react";

const BlockPreviewCompetence = (props) => {

  const content = props.block[0] // FIXME

  return <div className="dd-block-preview dd-block-preview--competence">
    <h4 className="dd-block-preview__team-headline">{content.title}</h4>
    {(content.viewmode === 'topic') &&
      <div className="dd-block-preview__competence-topics">
      {content.items.map((item, key) => {
        return <div key={key} className="dd-block-preview__competence-block dd-block-preview__competence-topic">
          <div className="dd-block-preview__competence-topic-icon">
            <img src={'/icons/competences/' + item.icon + '.svg'} alt=""/>
          </div>
          <div className="dd-block-preview__competence-topic-text">
            <strong>{item.title}</strong><br />
            {item.text}
          </div>
        </div>
      })
      }
      </div>
    }
    {(content.viewmode === 'icons') &&
      <div className="dd-block-preview__competence-icons">
        {content.items.map((item, key) => {
          return <div key={key} className="dd-block-preview__competence-block">
            <div className="dd-block-preview__competence-icons-icon">
              <img src={'/icons/competences/' + item.icon + '.svg'} alt=""/>
            </div>
            <div className="dd-block-preview__competence-icons-text">
              {item.title}
            </div>
          </div>
          })
        }
      </div>
    }
    {(content.viewmode === 'grid') &&
      <div className="dd-block-preview__competence-grid">
        {content.items.map((item, key) => {
          return <div key={key} className="dd-block-preview__competence-block dd-block-preview__competence-block--grid">
            <div className="dd-block-preview__competence-icons-text">
              {item.title}
            </div>
          </div>
          })
        }
      </div>
    }
    {(content.viewmode === 'grid_icons') &&
      <div className="dd-block-preview__competence-grid">
        {content.items.map((item, key) => {
          return <div key={key} className="dd-block-preview__competence-block dd-block-preview__competence-block--grid">
            <div className="dd-block-preview__competence-icons-icon">
              <img src={'/icons/competences/' + item.icon + '.svg'} alt=""/>
            </div>
            <div className="dd-block-preview__competence-icons-text">
              {item.title}
            </div>
          </div>
          })
        }
      </div>
    }
    {(content.viewmode === 'linklist') &&
      <div className="dd-block-preview__competence-linklist">
        {content.items.map((item, key) => {
          return <div key={ key } className="dd-block-preview__competence-linklist__item">
            <div className="dd-block-preview__competence-linklist-icon">
              <img src={ '/icons/competences/' + item.icon + '.svg' } alt=""/>
            </div>
            <div className="dd-block-preview__competence-linklist-content">
              <div className="dd-block-preview__competence-linklist-title">
                { item.title }
              </div>
              <div className="dd-block-preview__competence-linklist-text">
                { item.text }
              </div>
            </div>
          </div>
        }) }
      </div>
    }
  </div>
}

export default BlockPreviewCompetence;
