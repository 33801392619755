import React from "react";
import { Link, withRouter } from "react-router-dom";
import { saveAs } from "file-saver";

import { throttle } from "throttle-debounce";

import Pagination from "../../Shared/element/Pagination/Pagination";
import TableColumnHeader from "../../../layout/Tables/TableColumnHeader";
import Searchbar from "../../Shared/element/Searchbar/Searchbar";
import DeleteConfirmOverlay from "../../Shared/module/Overlay/DeleteConfirmOverlay";
import Editbar from "../../Shared/element/Editbar/Editbar";
import Loader from "../../Shared/atom/Loader/Loader";

import { AppContext } from "../../../data/ContextProvider";
import Icon from "../../Shared/atom/Icon/Icon";

import { getExports, deleteExport, getExportDownload, duplicateExport } from "../../../data/admin-api";
import { updatePage, getLanguages, getCompany } from "../../../data/api";
import { getToken, formatDate, formatShorDateObj } from "../../../lib/lib";
import { convertToValidFilename } from "../../../helpers/utils";

class ExportsTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      loading: true,
      deleteConfirmOverlay: false,
      confirmMode: "total_delete",
      exportToDelete: 0,
      count: 0,
      exportsCount: 0,
      limit: 10,
      defaultLimit: 10,
      currentPage: 0,
      lang: "de",
      orderField: 'updated_at',
      orderDirection: 'desc',
      exports: [],
      showCreateExportDialog: false,
    }

    this.searchThrottled = throttle(500, this.loadData);
  }

  onFilterChange = (event) => {
    this.setState({
      query: event.target.value,
      currentPage: 0
    }, () => {
      this.searchThrottled()
    })
  }

  handlePaginationClick = async (e, page) => {
    this.setState({
      ...this.state,
      currentPage: page
    }, async () => {
      await this.loadData()
    })
  }

  handleSelectLimit = (limit) => {

    localStorage.setItem('exports_limit', limit);

    this.setState({ limit, currentPage: 0 }, async () => {
      await this.loadData()
    })
  }

  onTableColumnClick = (column) => {
    const { orderDirection } = this.state

    this.setState({
      orderField: column,
      orderDirection: (orderDirection === 'desc') ? 'asc' : 'desc'
    }, async () => {
      await this.loadData()
    })
  }

  toggleCreatePageDialog(e) {
    e.preventDefault()
    this.setState({
      showCreateExportDialog: !this.state.showCreateExportDialog
    })
  }

  setLang = async (lang) => {
    this.setState({
      lang: lang,
      selectedTranslations:{}
    }, async () =>{
      await this.loadData()
    })
  }

  closeOverlay = () => {
    this.setState({
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false,
    })
  }

  showDeleteConfirmation = (exportItem) => {
    this.setState({
      deleteConfirmOverlay: true,
      exportToDelete: exportItem.id,
      confirmMode: "total_delete"
    })
  }

  showPublishConfirmation = (page) => {
    this.setState({
      hideConfirmOverlay: true,
      pageToHide: page.id,
      confirmMode: (page.published) ? "hide" : "show"
    })
  }

  loadExportDownload = async (exportItem) => {
    this.setState({loading: true})
    const blob = await getExportDownload(getToken(), exportItem.id);
    const dateString = formatShorDateObj(new Date());
    const title = convertToValidFilename(exportItem.title).toLowerCase();
    const fileName = `${title}-export-${dateString}.csv`;
    saveAs(blob, fileName);
    this.setState({loading: false})
  };

  duplicateExport = async (exportToCopy) => {
    const exportItem = await duplicateExport(getToken(), exportToCopy.id)
    const lang = this.props.match.params.lang
    this.props.history.push(`/${lang}/admin/exports/${exportItem.export.id}/edit`);
  }

  handlePublication = async () => {

    const index = this.state.pages.findIndex(s => s.id === this.state.pageToHide)
    const page = this.state.pages[index]

    page.published = !page.published

    this.setState({
      isUpdating: true,
    }, () => {
      updatePage(getToken(), page.id, page)
      .then(async updatedArticle => {
        this.setState({ hideConfirmOverlay: true })
        await this.loadData()
      })
      .catch(err => {
        this.setState({ isUpdating: false })
      })
    })
  }

  onDeleteConfirmation = async () => {

    const exportToDelete = this.state.exportToDelete

    if (exportToDelete) {
      this.setState({
        isUpdating: true,
      }, () => {
        deleteExport(getToken(), exportToDelete)
        .then(async updatedArticle => {
          this.setState({ selectedTranslations: {} })
          await this.loadData()
        })
        .catch(err => {
          this.setState({ isUpdating: false })
        })
      })
    }
  }

  loadData = async () => {
    try {

      const limit = localStorage.getItem('exports_limit') || this.state.defaultLimit
      const offset = this.state.currentPage * limit
      const exportSets = await getExports(getToken(), limit, offset, this.state.orderField, this.state.orderDirection, this.state.query)
      const exportsCount = Math.ceil(exportSets.count / limit)
      const syslanguages = await getLanguages(getToken())
      const company = await getCompany(getToken(), this.context.company.id)

      this.setState({
        ...this.state,
        limit: limit,
        exportsCount: exportsCount,
        count: exportSets.count,
        exports: exportSets.exports,
        deleteConfirmOverlay: false,
        hideConfirmOverlay: false,
        syslanguages: syslanguages.syslanguages,
        all_employees: company.company.all_employees,
        loading: false
      })
    } catch (error) {
      //FIXME NotFound
      console.log(error)
    }
  }

  async componentDidMount() {
    await this.loadData()
  }

  renderTitle = (exportItem) => {
    if (exportItem.title !== exportItem.navigation_title) {
      return <>{exportItem.title} <div className="dd-table__cell-info">{exportItem.navigation_title}</div></>
    } else {
      return exportItem.title
    }
  }

  getFullName = (exportItem) => {
    let full_name = ""
    this.state.all_employees.forEach(employee => {
      if (employee.id === exportItem.user_id) {
        full_name = employee.full_name
      }
    })
    return full_name
  }

  renderRow = (exportItem, key) => {

    let e = exportItem

    return <tr key={key}>
      <td className={(this.state.orderField === 'name') ? 'is-active' : ''}>{this.renderTitle(e)} </td>
      <td className={(this.state.orderField === 'updated_at') ? 'is-active' : ''}>{formatDate(e.updated_at)}</td>
      <td className={(this.state.orderField === 'created_at') ? 'is-active' : ''}>{formatDate(e.created_at)}</td>
      <td className={(this.state.orderField === 'user') ? 'is-active' : ''}>{e.user_id !== null ? this.getFullName(e) : ''}</td>
      <td className="justify-content-center dd-table--actions">
        <div className="dd-table--actions_wrapper">

          <button type="submit" className="dd-btn dd-btn--danger dd-btn--icon-only mr-2" onClick={() => this.showDeleteConfirmation(e)}>
            <Icon icon="trash" />
          </button>
          <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only mr-2" onClick={() => this.loadExportDownload(e)}>
            <Icon icon="download" />
          </button>
          <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only mr-2" onClick={() => this.duplicateExport(e)}>
            <Icon icon="duplicate" />
          </button>
          <Link to={`/de/admin/exports/${e.id}/edit`}>
            <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only">
              <Icon icon="edit" />
            </button>
          </Link>
        </div>
      </td>
    </tr>
  }

  render() {

    if (this.state.loading) {
      return <Loader />
    }

    return (
      <>
        <table className="table dd-table">
          <thead>
            <tr>
              <TableColumnHeader
                name="Titel"
                field="name"
                width={"25%"}
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick} />
              <TableColumnHeader
                name="Geändert"
                field="updated_at"
                width={"144"}
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick} />
              <TableColumnHeader
                name="Erstellt"
                field="created_at"
                width={"144"}
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick} />
              <TableColumnHeader
                name="Autor"
                field="user"
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick} />
              <th scope="col" width="160" className="dd-table--actions-th">
                <div className="dd-search-bar dd-search-bar--small dd-search-bar--white dd-table--search-filter">
                  <Searchbar
                    placeholder="Verfügbare Exports filtern …"
                    icon="filter"
                    onChangeCallback={(e) => this.onFilterChange(e)}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.exports.map((exportItem, key) => {
              return this.renderRow(exportItem, key)
            })}
          </tbody>
        </table>

        {this.state.deleteConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.onDeleteConfirmation}
            mode={this.state.confirmMode}
          />
        }

        {this.state.hideConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.handlePublication}
            mode={this.state.confirmMode}
          />
        }

        <div className="float-right">
          <Pagination
            total={this.state.exportsCount}
            current={this.state.currentPage}
            onChange={this.handlePaginationClick}
            onSelect={this.handleSelectLimit}
            limit={this.state.limit}
          />
        </div>
        <div className="dd-btn__wrapper" onClick={(event) => this.toggleCreatePageDialog(event)}>
          <button className="dd-btn dd-btn--primary dd-btn--icon-only" onClick={() =>
          {this.props.history.push(`/${this.state.lang}/admin/exports/new/export`)}}>
            <Icon icon="plus" />
          </button>
          <span className="dd-btn__text">Neuen Export anlegen</span>
        </div>
        <Editbar
          hasPublish={false}
          hasPreview={false}
          hasSave={false}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
        />
      </>
    )
  }
}

ExportsTable.contextType = AppContext
export default withRouter(ExportsTable)
