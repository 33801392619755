import React from "react";

import Icon from "../../atom/Icon/Icon";
import Textfield from "../../atom/Textfield/Textfield";
import Overlay from "../../element/Overlay/Overlay";
import PageSelector from "../PageSelector/PageSelector";


class BlockAdavantageItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      preview: true,
    }
  }

  togglePreviewEditMode(e) {
    e.preventDefault()
    this.setState({
      preview: !this.state.preview
    })
  }

  onChange = (event) => {
    const item = this.props.item
    item[event.target.name] = event.target.value
    this.props.onChange(item)
  }

  openPageSelectorOverlay = (e) => {
    e.preventDefault()
    this.setState({
      pageOverlayActive: true
    })
  }

  closePageSelectorOverlay = () => {
    this.setState({
      pageOverlayActive: false,
    })
  }

  handlePageSelect = (page) => {
    const item = this.props.item

    item.pageID = page.id || ""
    item.pageTitle = page.title || ""
    item.pageType  = page.content_type || ""
    item.buttonURL = page.buttonURL || ""
    item.buttonTarget = page.buttonTarget

    this.props.onChange(item)
    this.closePageSelectorOverlay()
    this.setState({ page: page })
  }

  renderSelectedPageLabel = () => {
    if (this.props.item.buttonURL) {
      return this.props.item.buttonURL
    } else if (this.props.item.pageID && this.props.item.pageID !== 0 && this.state.page && this.state.page.title) {
      return `${this.state.page.title} (${this.state.page.navigation_title})`
    } else if (this.props.item.articleID && this.state.page && this.state.page.title) {
      return `${this.state.page.title}`
    } else {
      return ""
    }
  }

  renderSelectedPageDeleteButton = () => {
    return (this.props.item.buttonURL || this.props.item.pageID || this.props.item.articleID)
  }

  handlePageDelete = () => {
    const item = this.props.item
    item.pageID = ""
    item.pageTitle = ""
    item.pageType  = ""
    item.buttonURL = ""
    this.setState({ page: {} })
    this.props.onChange(item)
  }

  render() {
    return (
      <>
        {this.state.preview ?
          <div className="dd-mini-block__preview">
            <div className="dd-mini-block__preview-item" onClick={(e) => this.togglePreviewEditMode(e)}>
              <div className="dd-mini-block__preview-image"></div>
              <div className="dd-mini-block__preview-title">{this.props.item.title}&nbsp;</div>
            </div>
          </div>

          :

          <div className="dd-mini-block__edit">
            <div className="dd-mini-block__close">
              <button className="dd-btn dd-btn--icon-only" onClick={(e) => this.togglePreviewEditMode(e)}>
                <Icon icon="close" />
              </button>
            </div>
            <div className="dd-block__edit-section">
              <div className="form-group" >
                <Textfield
                  name="title"
                  type="text"
                  isRequired={false}
                  label=""
                  placeholder="Titel"
                  handleChange={this.onChange}
                  defaultValue={this.props.item.title}
                />
              </div>
              <div className="form-group" >
                <Textfield
                  name="description"
                  type="text"
                  isRequired={false}
                  label=""
                  placeholder="Kurzbeschreibung"
                  handleChange={this.onChange}
                  defaultValue={this.props.item.description}
                />
              </div>
              <div className="form-group" >
                <Textfield
                  name="url"
                  type="text"
                  isRequired={false}
                  label=""
                  placeholder="Internet-Adresse"
                  handleChange={this.onChange}
                  defaultValue={this.props.item.url}
                />
              </div>
              <div className="form-group">

                <div className="dd-link-selector">
                  <div className="dd-link-selector__link">
                    {this.renderSelectedPageDeleteButton() &&
                      <button className={"dd-btn dd-btn--danger dd-btn--icon-only dd-link-selector__delete"}
                        onClick={() => this.handlePageDelete()}>
                        <Icon icon="trash" />
                      </button>
                    }
                    {this.renderSelectedPageLabel()}
                  </div>
                  <button className="dd-btn dd-btn--primary dd-link-selector__button" onClick={(e) => this.openPageSelectorOverlay(e)}>
                    {this.renderSelectedPageDeleteButton() ?
                      <span>Linkziel ändern</span>
                      :
                      <span>Link hinzufügen</span>
                    }
                    </button>
                </div>
                {this.state.pageOverlayActive &&
                  <Overlay closeOverlayCallback={() => this.closePageSelectorOverlay()}>
                    <PageSelector
                      content={this.props.item}
                      selectPageCallback={(page) => this.handlePageSelect(page)}
                    />
                  </Overlay>
                }
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

export default BlockAdavantageItem
