import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Tablist from '../Shared/module/Tablist/Tablist'

class ArticlesEditTabs extends Component {
  render() {
    const lang = this.props.match.params.lang
    const articleid = this.props.match.params.articleid

    const tabs = [
      { title: "Beitragstext", path: `/${lang}/articles/${articleid}/content` },
      { title: "Meta-Daten", path: `/${lang}/articles/${articleid}/meta` },
      { title: "Veröffentlichung", path: `/${lang}/articles/${articleid}/publishing` }
    ]

    return (
      <Tablist
        additionalClass="w-75 ml-auto mr-auto pt-4 d-flex justify-content-between"
        tabs={tabs}
        active={this.props.activeTab}
        tabChange={() => {}}
      />
    )
  }

}

export default withRouter(ArticlesEditTabs);
