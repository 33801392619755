import React from "react";

import Navbar from "../components/Shared/element/Navbar/Navbar";

function Layout(props) {
  return (
    <React.Fragment>
      <Navbar isDiroLayout={props.isDiroLayout}/>

      <div className={"content"}>
        {props.children}
      </div>

    </React.Fragment>
  );
}

export default Layout;
