import React from "react";
import SimpleSelectfield from "../Shared/atom/Selectfield/SimpleSelectfield";
import Checkbox from "../Shared/atom/Checkbox/Checkbox";
import Textfield from "../Shared/atom/Textfield/Textfield";

function OfficeDiroData(props) {
  return (
    <React.Fragment>
      <div className="dd-form-section dd-form-section--diro dd-form-section--first">
        <label htmlFor="private_diro" className="dd-form-section__title">
          Ansprechpartner und Mitgliedstatus
        </label>
        <fieldset id="private_diro" className="dd-form-section__content">
          <div className="form-row">
            <div className="col-md-4 mb-2">
              <SimpleSelectfield
                label="Administrator der Website"
                name="administrator_id"
                firstOptionValue="0"
                firstOptionLabel="Bitte auswählen"
                indexLabel="id"
                optionLabel="full_name"
                options={props.company.employees}
                onSelect={props.onSelectAdministrator}
                selected={props.company.administrator_id}
              />
            </div>
          </div>
        </fieldset>
      </div>

      <div className="dd-form-section dd-form-section--diro">
        <label htmlFor="form_kooperationen" className="dd-form-section__title">Multisite - Einstellungen</label>
        <fieldset id="private_diro" className="dd-form-section__content">
          <div className="form-row">
            <div className="col-md-4 mb-2">
              <Checkbox
                label="eine Multisite Kanzlei?"
                id="is_multisite"
                selected={(props.company.is_multisite)}
                onChange={(e) => props.changeValue("is_multisite", !props.company.is_multisite)}
              />
              <Checkbox
                label="eine Autorenkanzlei?"
                id="is_content_deliverer"
                selected={(props.company.is_content_deliverer)}
                onChange={(e) => props.changeValue("is_content_deliverer", !props.company.is_content_deliverer)}
              />
            </div>
            { props.company.is_multisite &&
              <div className="col-md-4 mb-2">
                  <Textfield
                    name="domain"
                    type="text"
                    label="Domain"
                    placeholder="xyz.diro-multisite.de"
                    defaultValue={props.company.domain}
                    handleChange={props.onChange}
                    errorHandler={props.errorHandler}
                  />
                  <Checkbox
                    label="https verwenden?"
                    id="use_https"
                    selected={props.company.http_protocol === 'https'}
                    onChange={(e) => props.onHTTPSChange(e)}
                  />
              </div>
            }
            <div className="col-md-4 mb-2">
              <div className="form-group">
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </React.Fragment>
  );
}

export default OfficeDiroData;
