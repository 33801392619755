import React from "react";
import Icon from "../../../atom/Icon/Icon";

const BlockPreviewConsentCalenso = () => {
  return <div className="dd-block-preview dd-block-preview--articles" style={{ paddingBottom: 0 }}>
    <h3 className="mb-1"><Icon icon="kalender" style={{ width: "25px", height: "25px", position: "relative", top: "3px", marginRight: "5px" }} /> Calenso</h3>
    ...
  </div>
}

export default BlockPreviewConsentCalenso;
