import React from "react";
import { withRouter } from "react-router-dom";

import Textfield from "../Shared/atom/Textfield/Textfield";
import Loader from "../Shared/atom/Loader/Loader";
import Editbar from "../Shared/element/Editbar/Editbar";


import { getPerson, updatePassword } from "../../data/api";
import { getToken, checkPasswordPolicy } from "../../lib/lib";
import { AppContext } from "../../data/ContextProvider";

class UserEdit extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      loading: true,
      errorMessage: "",
      password: "",
      password_confirmation: ""
    }
  }

  errorHandlerPassword = (hasError, event) => {
    this.setState({ hasError})
  }

  errorHandlerPasswordConfirmation = (hasError, event) => {
    if (this.state.password !== event.target.value) {
      return this.setState({ hasError: true, errorMessage: "Das Passwort und Passwort Bestätigung stimmen nicht überein" })
    }

    this.setState({ hasError })
  }

  onChange = (event) => {
    this.setState({ password: event.target.value })
  }

  onChangeConfirmation = (event) => {
    this.setState({ password_confirmation: event.target.value })
  }

  onSubmit = async () => {

    if (this.state.password.length === 0 || this.state.password_confirmation.length === 0) {
      this.setState({ hasError: true, errorMessage: "Das Passwort darf nicht leer sein"})
      return;
    }

    if (!checkPasswordPolicy(this.state.password)) {
      this.setState({ hasError: true, errorMessage: "Ihr gewähltes Passwort entspricht nicht den Sicherheitsbestimmungen. Ihr Passwort muss aus mindestens 8 Zeichen, Groß- und Kleinschreibung und mindestens eine Zahl beinhalten." })
      return;
    }

    const user = {password: this.state.password}

    try {
      await updatePassword(getToken(), this.state.userID, user)
    } catch (error) {
      console.log(error)
    }

  }

  loadData = async () => {
    const personRecord  = await getPerson(getToken(), this.state.userID)
    const person = personRecord.person

    this.setState({
      person: person,
      loading: false,
    })
  }

  async componentDidMount() {

    if (this.props.match && this.props.match.params) {
      this.setState({
        ...this.state,
        userID: this.props.match.params.user_id,
      },
        async () => {
          await this.loadData()
        }
      )
    }
  }

  render() {

    const admin = (this.context.user.diro_admin)

    if (!admin || this.state.loading) {
      return <Loader />
    }

    const hasError = (this.state.hasError) ? true : false

    return (
     <div className="tab-pane fade show active">
        <div className="card-body card-body--standard dd-background dd-background--sand">
          <div className="card-body__content w-75 ml-auto mr-auto">
            <div className="form-group">
              <strong>{this.state.person.full_name}</strong>
            </div>

            <div className="form-group">
              <Textfield
                name="password"
                type="text"
                isRequired={true}
                hasError={hasError}
                label="Passwort"
                handleChange={this.onChange}
                defaultValue={this.state.password}
                errorHandler={this.errorHandlerPassword}
              />
            </div>
            <div className="form-group">
              <Textfield
                name="password_confirmation"
                type="text"
                isRequired={true}
                hasError={hasError}
                label="Passwort Wiederholung"
                handleChange={this.onChangeConfirmation}
                defaultValue={this.state.password_confirmation}
                errorHandler={this.errorHandlerPasswordConfirmation}
              />
            </div>
          </div>
        </div>

        <Editbar
          hasPublish={false}
          hasPreview={false}
          historyBack={true}
          hasError={hasError}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
          errorMessage={this.state.errorMessage}
        />
      </div>
    )
  }
}

UserEdit.contextType = AppContext
export default withRouter(UserEdit)
