import React from "react";

import TagList from "../Shared/atom/TagList/TagList"
import Checkbox from "../Shared/atom/Checkbox/Checkbox";

import { AppContext } from "../../data/ContextProvider";
import { getTerms, getCompany, getPages } from "../../data/api";
import { getToken } from "../../lib/lib";

class PersonPublicData extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      languages: [],
      jobtitles: [],
      lawfields: [],
      advocacies: [],
      company: [],
      pages: [],
    }
  }

  async componentDidMount() {
    const languages = await getTerms(getToken(), 'languages')
    const jobtitles = await getTerms(getToken(), 'jobtitles')
    const lawfields = await getTerms(getToken(), 'lawfields')
    const advocacies = await getTerms(getToken(), 'advocacies')
    const company = await getCompany(getToken(), this.context.company.id)
    const pages = await getPages(getToken(), 0, 0, "", "", "")

    this.setState({
      languages:  languages.languages,
      jobtitles:  jobtitles.jobtitles,
      lawfields:  lawfields.lawfields,
      advocacies: advocacies.advocacies,
      company: company.company,
      pages: pages.pages,
    })
  }

  isWorkingOutsideOfGermany = () => {
    let outsideOfGermany = false

    if (this.props.person && this.props.person.company_sites && this.props.person.company_sites.length) {
      this.props.person.company_sites.map((company) => {
        if (company.country_id !== 5) {
          outsideOfGermany = true
        }
        return ''
      })
    }

    if (this.props.company.country_id !== 5) {
      outsideOfGermany = true
    }

    return outsideOfGermany
  }


  render () {

    const labels = { de: 'title_de', en: 'title_en', fr: 'title_fr', it: 'title_it'}
    const indexLabel = labels[this.props.lang] || 'title_en'

    return (
      <form id="form_angaben-qualifikation">
        <div>
          <div>

          {this.state.jobtitles.length &&
            <div className="dd-form-section dd-form-section--small dd-form-section--first dd-form-section--combined">
              <div className="dd-form-section__content">

                <div className="d-flex flex-column dd_tag__row">
                  <div className="row">
                    <div className="col-md-4 d-flex justify-content-md-end align-items-center">
                      <label className="mb-0" htmlFor="Ausbildung/Berufsbezeichnung">
                        Ausbildung/Berufsbezeichnung
                      </label>
                    </div>
                    <div className="col-md-8 d-flex align-items-center">
                      <TagList
                        indexLabel={indexLabel}
                        name="Ausbildung/Berufsbezeichnung"
                        data={this.props.person.jobtitles}
                        availableData={this.state.jobtitles}
                        onUpdate={(assignedTags) => this.props.jobtitlesUpdate(assignedTags)}
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
            }

            {this.state.advocacies.length &&
              <div className="dd-form-section dd-form-section--small dd-form-section--combined">
                <div className="dd-form-section__content">
                  <div className="d-flex flex-column dd_tag__row">
                    <div className="row">
                      <div className="col-md-4 d-flex justify-content-md-end align-items-center">
                        <label className="mb-0" htmlFor="Fachanwaltschaften">
                          Fachanwaltschaften
                        </label>
                      </div>
                      <div className="col-md-8 d-flex align-items-center">
                        <TagList
                          indexLabel={indexLabel}
                          name="Fachanwaltschaften"
                          data={this.props.person.advocacies}
                          availableData={this.state.advocacies}
                          onUpdate={(assignedTags) => this.props.advocaciesUpdate(assignedTags)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            {this.state.languages.length &&
              <div className="dd-form-section dd-form-section--small dd-form-section--combined">
                <div className="dd-form-section__content">
                  <div className="d-flex flex-column dd_tag__row">
                    <div className="row">
                      <div className="col-md-4 d-flex justify-content-md-end align-items-center">
                        <label className="label--single" htmlFor="Sprachen">
                          Sprachen
                        </label>
                      </div>
                      <div className="col-md-8 d-flex align-items-center">
                        <TagList
                          indexLabel={indexLabel}
                          name="SprachenX"
                          data={this.props.person.languages}
                          availableData={this.state.languages}
                          onUpdate={(assignedTags) => this.props.languagesUpdate(assignedTags)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            {this.state.lawfields.length &&
              <div className="dd-form-section dd-form-section--small dd-form-section--combined">
                <div className="dd-form-section__content">
                  <div className="d-flex flex-column dd_tag__row">
                    <div className="row">
                      <div className="col-md-4 d-flex justify-content-md-end align-items-center">
                        <label className="mb-0" htmlFor="Rechtsgebiete">
                          Rechtsgebiete
                        </label>
                      </div>
                      <div className="col-md-8 d-flex align-items-center">
                        <TagList
                          indexLabel={indexLabel}
                          name="Rechtsgebiete"
                          data={this.props.person.lawfields}
                          availableData={this.state.lawfields}
                          onUpdate={(assignedTags) => this.props.lawfieldsUpdate(assignedTags)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className="dd-form-section dd-form-section--small dd-form-section--last">
                <div className="dd-form-section__content">
                <div className="d-flex flex-column dd_tag__row">
                  <div className="row">
                    <div className="col-md-4 d-flex justify-content-md-end align-items-center">
                      <label className="mb-0" htmlFor="Spezialkenntnisse">
                        Individuelle Berufsbezeichnung / Zusatzqualifikation
                      </label>
                    </div>
                    {this.props.lang === 'de' &&
                    <div className="col-md-8 align-items-center">
                      <textarea
                        name="specialskills"
                        className="form-control"
                        rows="4"
                        defaultValue={this.props.person.specialskills}
                        onChange={this.props.onChange}
                      />
                    </div>
                    }
                    {this.props.lang !== 'de' &&
                    <div className="col-md-8 align-items-center">
                      <textarea
                        name={`specialskills_${this.props.lang}`}
                        className="form-control"
                        rows="4"
                        defaultValue={this.props.person[`specialskills_${this.props.lang}`]}
                        onChange={this.props.onChange}
                      />
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="dd-form-section dd-form-section--small dd-form-section--last">
              <div className="dd-form-section__content">
                <div className="d-flex flex-column dd_tag__row">
                  <div className="row">
                    <div className="col-md-4 d-flex justify-content-md-end align-items-center">
                      <label className="mb-0" htmlFor="Freitextfeld">
                        Freitextfeld für die Profilseite dieser Person
                      </label>
                    </div>
                    {this.props.lang === 'de' &&
                      <div className="col-md-8 d-flex align-items-center">
                        <textarea
                          className="form-control"
                          name="description"
                          rows="4"
                          defaultValue={this.props.person.description}
                          onChange={this.props.onChange}
                        />
                      </div>
                    }
                    {this.props.lang !== 'de' &&
                      <div className="col-md-8 d-flex align-items-center">
                        <textarea
                          className="form-control"
                          name={`description_${this.props.lang}`}
                          rows="4"
                          defaultValue={this.props.person[`description_${this.props.lang}`]}
                          onChange={this.props.onChange}
                        />
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            {this.isWorkingOutsideOfGermany() &&
              <div className="dd-form-section dd-form-section--small dd-form-section--last">
                <div className="dd-form-section__content">
                  <div className="d-flex flex-column dd_tag__row">
                    <div className="row">
                      <div className="col-md-4 d-flex justify-content-md-end">
                        <label className="mb-0" htmlFor="Freitextfeld">
                          Tätig am German Desk der Kanzlei
                        </label>
                      </div>
                      <div className="col-md-8 align-items-center">
                        <Checkbox
                          name="german_desk"
                          onChange={(e) => this.props.changeValue(e.target.name, !this.props.person.german_desk)}
                          selected={this.props.person.german_desk || false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

          </div>
        </div>
      </form>
    )
  }
}

PersonPublicData.contextType = AppContext
export default PersonPublicData;