import React from "react";
import { Link, withRouter } from "react-router-dom";
import { throttle } from "throttle-debounce";

import Pagination from "../Shared/element/Pagination/Pagination";
import TableColumnHeader from "../../layout/Tables/TableColumnHeader";
import Searchbar from "../Shared/element/Searchbar/Searchbar";
import DeleteConfirmOverlay from "../Shared/module/Overlay/DeleteConfirmOverlay";
import Title from "../Shared/module/Title/Title"
import Icon from "../Shared/atom/Icon/Icon";
import LangSelector from "../Shared/element/LangTools/LangSelector";
import LangChooserSelect from "../Shared/element/LangTools/LangChooserSelect";
import Loader from "../Shared/atom/Loader/Loader";

import { getArticles, getArticle, deleteArticle, updateArticle, getLanguages, translateArticle } from "../../data/api";
import { getToken, formatDate } from "../../lib/lib";
import { AppContext } from "../../data/ContextProvider";

class ArticlesTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      loading: true,
      openLangChooser: false,
      lang: "de",
      deleteConfirmOverlay: false,
      confirmMode: "delete",
      articleToDelete: 0,
      count: 0,
      pages: 0,
      currentPage: 0,
      limit: 10,
      defaultLimit: 10,
      orderField: 'updated_at',
      orderDirection: 'desc',
      articles: [],
      articleActiveLanguageList: 0,
      selectedTranslations: {}
    }

    this.searchThrottled = throttle(500, this.loadData);
  }

  setLang = async (lang) => {
    this.setState({
      lang: lang,
      selectedTranslations: {}
    }, async () => {
      await this.loadData()
    })
  }

  toogleLangChooser = () => {
    this.setState({
      openLangChooser: !this.state.openLangChooser
    })
  }

  toggleLanguageList = (article) => {
    if (this.state.articleActiveLanguageList === 0) {
      this.setState({
        articleActiveLanguageList: article.id
      })
    } else {
      this.setState({
        articleActiveLanguageList: 0
      })
    }
  }

  selectTranslation = async (article, lang) => {
    const { selectedTranslations } = this.state
    const alreadyTranslated = Object.keys(selectedTranslations)

    const idToTranslate = article.translations[lang].toString()

    // reset
    if (alreadyTranslated.includes(idToTranslate)) {
      delete selectedTranslations[idToTranslate]
    } else {
      const transltedArticle = await getArticle(getToken(), article.translations[lang])
      selectedTranslations[article.id] = transltedArticle.article
    }

    this.setState({
      selectedTranslations
    }, () => { console.log(this.state.selectedTranslations) })
  }

  onFilterChange = (event) => {
    this.setState({
      query: event.target.value,
      currentPage: 0
    }, () => {
      this.searchThrottled()
    })
  }

  handlePaginationClick = async (e, page) => {
    this.setState({
      ...this.state,
      currentPage: page
    }, async () => {
      await this.loadData()
    })
  }

  handleSelectLimit = (limit) => {
    localStorage.setItem('articles_limit', limit);
    this.setState({ limit, currentPage: 0 }, async () => {
      await this.loadData()
    })
  }

  onTableColumnClick = (column) => {
    const { orderDirection } = this.state

    this.setState({
      orderField: column,
      orderDirection: (orderDirection === 'desc') ? 'asc' : 'desc'
    }, async () => {
      await this.loadData()
    })
  }

  closeOverlay = () => {
    this.setState({
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false,
    })
  }

  showDeleteConfirmation = (article) => {
    this.setState({
      deleteConfirmOverlay: true,
      articleToDelete: article.id,
      confirmMode: "delete"
    })
  }

  showPublishConfirmation = (article) => {
    this.setState({
      hideConfirmOverlay: true,
      articleToHide: article.id,
      confirmMode: (article.published) ? "hide" : "show"
    })
  }

  translateArticle = async (articleToTranslate, lang) => {
    const article = await translateArticle(getToken(), articleToTranslate.id, lang)
    const sysLang = this.props.match.params.lang
    this.props.history.push(`/${sysLang}/articles/${article.article.id}/content`);
  }

  handlePublication = async () => {

    const index = this.state.articles.findIndex(s => s.id === this.state.articleToHide)
    const article = this.state.articles[index]

    article.published = !article.published

    this.setState({
      isUpdating: true,
    }, () => {
        updateArticle(getToken(), article.id, article)
        .then(async updatedArticle => {
          await this.loadData()
        })
        .catch(err => {
          this.setState({ isUpdating: false })
        })
    })
  }

  onDeleteConfirmation = async () => {
    const articleToDelete = this.state.articleToDelete

    if (articleToDelete) {
      this.setState({
        isUpdating: true,
      }, () => {
          deleteArticle(getToken(), articleToDelete)
          .then(async updatedArticle => {
            this.setState({ selectedTranslations: {}})
            await this.loadData()
          })
          .catch(err => {
            this.setState({ isUpdating: false })
          })
      })
    }
  }

  loadData = async () => {
    const limit = localStorage.getItem('articles_limit') || this.state.defaultLimit
    const offset = this.state.currentPage * limit

    const syslanguages = await getLanguages(getToken())
    const articles = await getArticles(getToken(), this.state.lang, limit, offset, this.state.orderField, this.state.orderDirection, this.state.query)

    this.setState({
      ...this.state,
      loading: false,
      limit: limit,
      pages: Math.ceil(articles.count / limit),
      count: articles.count,
      articles: articles.articles,
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false,
      syslanguages: syslanguages.syslanguages,
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  renderAuthorsList = (article) => {
    const out = []

    if (article.authors) {
      article.authors.forEach ((author) => {
        out.push(author.full_name)
      })
    }
    return out.join(", ")
  }

  isArticleEditable = (article) => {
    if (article.deleted_publication_on_network || article.revoked_publication_on_network) {
      return false
    }
    return true
  }

  renderPublishingState = (article)=> {


    if (article.deleted_publication_on_network) {
      return "ZG"
    }

    if (article.revoked_publication_on_network) {
      return "Z"
    }

    if (article.released_on_network) {
      return "FP"
    }

    if (article.published_on_network) {
      return "F"
    }

    if (this.context.company.is_diro) {

    }
  }

  renderRow = (article, key) => {

    const { selectedTranslations } = this.state

    let a = article

    if (selectedTranslations[article.id]) {
      a = selectedTranslations[article.id]
    }

    return <tr key={key} className={(!a.published) ? 'is-inactive' : ''}>

      <td className="languages--table-view">
        <div className="publish-status no-label">
          <span className="publish-status--live">Live</span>
          <span className="publish-status--offline">Offline</span>
        </div>
        <span>{this.renderPublishingState(article)}&nbsp;</span>
        <div className="languages__wrapper">
          <LangSelector
            item={a}
            syslanguages={this.state.syslanguages}
            active={(this.state.articleActiveLanguageList === a.id) ? true : false}
            onActivate={() => this.toggleLanguageList(a)}
            onTranslate={(lang) => this.translateArticle(a, lang)}
            onSelect={(lang) => this.selectTranslation(a, lang)}
          />
        </div>
      </td>


      <td className={(this.state.orderField === 'title') ? 'is-active' : ''}>{a.title}</td>
      <td className={(this.state.orderField === 'updated_at') ? 'is-active' : ''}>{formatDate(a.updated_at)}</td>
      <td className={(this.state.orderField === 'created_at') ? 'is-active' : ''}>{formatDate(a.created_at)}</td>
      <td className={(this.state.orderField === 'author') ? 'is-active' : ''}>{this.renderAuthorsList(a)}</td>

      {this.context.company.is_diro &&
        <td className={(this.state.orderField === 'views') ? 'is-active' : ''}>{a.published_from_company}</td>
      }

      <td className="justify-content-center dd-table--actions">
        <div className="dd-table--actions_wrapper">

          <button type="submit" className="dd-btn dd-btn--danger dd-btn--icon-only mr-2" onClick={() => this.showDeleteConfirmation(a)}>
            <Icon icon="trash" />
          </button>

          {this.isArticleEditable(article) &&
            <>
              <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only" onClick={() => this.showPublishConfirmation(a)}>
                {a.published
                  ? < Icon icon="visibilty" />
                  : < Icon icon="hidden" />
                }
              </button>

              <Link to={`/de/articles/${a.id}/content`}>
                <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only">
                  <Icon icon="edit" />
                </button>
              </Link>
            </>
          }

        </div>
      </td>
    </tr>
  }

  render() {

    if (this.state.loading) {
      return <Loader />
    }

    return (
      <>
        <Title headline="Fachbeiträge" />

        <table className="table dd-table">
          <thead>
            <tr>
              <th width="90" className="languages--table-view">
                <div className="languages__wrapper">
                  <LangChooserSelect
                    lang={this.state.lang}
                    active={this.state.openLangChooser}
                    syslanguages={this.state.syslanguages}
                    onSelect={(lang) => this.setLang(lang)}
                    onActivate={this.toogleLangChooser}
                  />
                </div>
              </th>
              <TableColumnHeader
                name={"Thema"}
                field={"title"}
                width={"40%"}
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick} />
              <TableColumnHeader
                name={"Geändert"}
                field={"updated_at"}
                width={"144"}
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick} />
              <TableColumnHeader
                name={"Erstellt"}
                field={"created_at"}
                width={"144"}
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick} />
              <TableColumnHeader
                name={"Autor"}
                field={"author"}
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick} />

                {this.context.company.is_diro &&
                  // #681 Sort, but why is column dispatched as "views"?
                  <TableColumnHeader
                    name={"Kanzlei"}
                    field={"company"}
                    currentOrderField={this.state.orderField}
                    currentOrderDirection={this.state.orderDirection}
                    onClick={this.onTableColumnClick}
                  />
                }

              <th width="160" scope="col" className="dd-table--actions-th">
                <div className="dd-search-bar dd-search-bar--small dd-search-bar--white dd-table--search-filter">
                  <Searchbar
                    placeholder="Verfügbare Artikel filtern …"
                    icon="filter"
                    onChangeCallback={(e) => this.onFilterChange(e)}
                  />
                </div>
              </th>
              {/*<th scope="col" className="dd-table--actions-th">Actions</th>*/}
            </tr>
          </thead>
          <tbody>
            {this.state.articles.map((article, key) => {
              return this.renderRow(article, key)
            })}
          </tbody>
        </table>

        {this.state.deleteConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.onDeleteConfirmation}
            mode={this.state.confirmMode}
          />
        }

        {this.state.hideConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.handlePublication}
            mode={this.state.confirmMode}
          />
        }


        <div className="float-right">
          <Pagination
            total={this.state.pages}
            current={this.state.currentPage}
            onChange={this.handlePaginationClick}
            onSelect={this.handleSelectLimit}
            limit={this.state.limit}
            />
        </div>

        <Link to={`/de/articles/new`} className="dd-btn__wrapper">
          <button className="dd-btn dd-btn--primary dd-btn--icon-only" >
            <Icon icon="plus" />
          </button>
          <span className="dd-btn__text">Neuen Fachartikel anlegen</span>
        </Link>
      </>
    )
  }
}

ArticlesTable.contextType = AppContext
export default withRouter(ArticlesTable)
