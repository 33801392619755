import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";


// import Loader from "../Shared/atom/Loader/Loader";
import Textfield from "../Shared/atom/Textfield/Textfield";
import { AppContext } from '../../data/ContextProvider'

import { getCompany, getLanguages } from "../../data/api";
import { tryToLogInUser, getToken } from "../../lib/lib";


class Login extends Component {

  constructor(props) {
    super(props)
    const resetToken = (props.match && props.match.params && props.match.params.reset_token) ? props.match.params.reset_token : ''
    this.state = {
      step: props.step ? props.step : 0,
      error: "",
      message: "",
      email: '',
      password: '',
      resetToken: resetToken,
    }
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const state = this.state
    state[name] = value
    this.setState({ ...state });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const userData = await tryToLogInUser(this.state.email, this.state.password)
      const userCompany = await getCompany(getToken(), userData.company_id)

      if (userData) {
        if (this.state.error) {
          this.setState({ error: "" })
        }
        this.context.saveUserData(userData, userCompany.company)
      }

    } catch (error) {
      if (error && error.status === 401) {
        this.setState({
          message: error.data,
          error: true
          })
      } else {
        this.setState({
          message:  "Backend is not running!",
          error: true
        })
      }
      console.log("ein schlimmer error", error)
    }

    try {
      const syslanguages = await getLanguages(getToken())
      this.context.saveSysLanguages(syslanguages.syslanguages)
    } catch (error) {
      console.log(error)
    }
  }


  render() {
    // if (!this.context.user.diro_admin) {
    //   return <Loader />
    // }
    return (
      <form onSubmit={(e) => this.handleSubmit(e)}>
        <h2>Zugang für DIRO-Mitglieder</h2>
        {this.state.message &&
        <p className={this.state.error ? "text-danger" : "text-info" }>
          {this.state.message}
        </p>
        }
        <Textfield
          name={"email"}
          label={"E-Mail"}
          handleChange={this.handleChange}
          isRequired={true}
          type={"email"}
        />
        <Textfield
          name={"password"}
          label={"Password"}
          handleChange={this.handleChange}
          isRequired={true}
          type={"password"}
        />
        <div className="form-group d-flex align-items-center justify-content-between">
          <span className="btn-link"><Link to="/password_forgot">Passwort vergessen?</Link></span>
          <button className="dd-btn dd-btn--primary" type="submit">Anmelden</button>
        </div>
      </form>
    );
  }
}

Login.contextType = AppContext
export default withRouter(Login);
