import React from "react";
import Checkbox from '../Shared/atom/Checkbox/Checkbox'

function PersonsFilter(props) {
  return (
    <div className="dd-personsfilter" style={{display: 'flex'}}>
      <div className="dd-personsfilter__label" style={{marginRight: '25px'}}>Nur Personen am Standort ...</div>
        {props.locations.map((location, key) => {
          return(
            <div key={key} className="dd-personsfilter__item" style={{marginRight: '20px'}}>
              <Checkbox
                name="noLocation"
                label={location.city}
                onChange={(e) => props.onChange(location.city, key)}
                selected={location.selected || false}
              />
            </div>
          )})
        }
    </div>
  );
}

export default PersonsFilter;