import React from "react";

import Icon from "../../atom/Icon/Icon";
import SimpleSelectfield from "../../atom/Selectfield/SimpleSelectfield";
import Checkbox from "../../atom/Checkbox/Checkbox";

class BlockMapLocationEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      preview: true,
    }
  }

  togglePreviewEditMode(e) {
    e.preventDefault()
    this.setState({
      preview: !this.state.preview
    })
  }

  onSelectSite = (value) => {
    const item = this.props.item
    item.siteID = value
    this.saveContent(item)
  }

  onChange = (event) => {
    const item = this.props.item
    item[event.target.name] = event.target.value
    this.saveContent(item)
  }

  showOpeningHoursChange = () => {
    const item = this.props.item
    item.showOpeningHours = !this.props.item.showOpeningHours
    this.props.onChange(item)
  }

  saveContent = (item) => {
    let preview = this.state.preview
    if (this.props.item.newRow) {
      preview = !preview
    }

    this.setState({
      savedOnce: true,
      preview
    }, () => {
      this.props.onChange(item)
    })
  }

  render() {

    let showPreview = this.state.preview
    if (this.props.item.newRow && !this.state.savedOnce) {
      showPreview = false
    }

    let siteName = this.props.sites.find(site => site.id.toString() === this.props.item.siteID.toString())

    return (
      <div>
        {siteName && showPreview ?
          <div className="dd-mini-block__preview">
            <div className="dd-mini-block__preview-item" onClick={(e) => this.togglePreviewEditMode(e)}>
              <div className="dd-mini-block__preview-image"></div>
              <div className="dd-mini-block__preview-title">{siteName.option}&nbsp;</div>
            </div>
          </div>

          :

          <div className="dd-mini-block__edit">
            <div className="dd-mini-block__close">
              <button className="dd-btn dd-btn--icon-only" onClick={(e) => this.togglePreviewEditMode(e)}>
                <Icon icon="close" />
              </button>
            </div>
            <div className="dd-block__edit-section">

              <div className="form-group">
                <SimpleSelectfield
                  label="Standort"
                  name="site"
                  firstOptionValue="0"
                  firstOptionLabel="Bitte auswählen"
                  indexLabel="id"
                  optionLabel="option"
                  options={this.props.sites}
                  onSelect={this.onSelectSite}
                  selected={this.props.item.siteID}
                />
              </div>

              <div className="form-group">
                <Checkbox
                  label="Öffnungszeiten anzeigen"
                  id="showOpeningHours"
                  selected={this.props.item.showOpeningHours}
                  onChange={(e) => this.showOpeningHoursChange(e)}
                />
              </div>

            </div>
          </div>
        }
      </div>
    )
  }
}

export default BlockMapLocationEdit
