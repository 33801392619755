import React from "react";

import Icon from "../../atom/Icon/Icon";
import RadioButton from "../../atom/RadioButton/RadioButton";
import Textfield from "../../atom/Textfield/Textfield";
import SimpleSelectfield from "../../../Shared/atom/Selectfield/SimpleSelectfield";

import DnDRows from "../../../Shared/module/DnDRows/DnDRows";
import BlockPreviewTeam from './BlockPreviews/Team';
import BlockSectionSettings from './BlockSectionSettings';
import Loader from "../../../Shared/atom/Loader/Loader";
import BlockTeamMemberEdit from './BlockTeamMemberEdit';

import { AppContext } from '../../../../data/ContextProvider'

import { getCompany } from "../../../../data/api";
import { getToken, createID } from "../../../../lib/lib";
import BlockActions from "./BlockActions";

class BlockTeamEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content:{},
      preview: true,
      showSettings: false,
      loading: true,
      langQualificationOptions: [
        { value: 'disabled', position: 'keine Anzeige' },
        { value: 'flags', position: 'Anzeige mit Flaggen' },
        { value: 'abbreviation', position: 'Anzeige mit Sprachkürzel' }
      ]
    }
  }

  togglePreviewEditMode() {
    this.setState({
      preview: !this.state.preview
    })
  }

  toggleSettings = (e) => {
    e.preventDefault()
    this.setState({
      showSettings: !this.state.showSettings
    })
  }

  closeEditMode() {
    this.togglePreviewEditMode()
  }

  onChange = (event) => {
    const content = this.state.content
    content[event.target.name] = event.target.value
    this.saveContent(content)
  }

  onRadioChange = (event, field) => {
    const content = this.state.content
    content[field] = event.target.value
    this.saveContent(content)
  }

  onSelectLangQualificationMode = (value) => {
    const content = this.state.content
    content.langQualificationMode = value
    this.saveContent(content)
  }

  onSelectProfile = (value) => {

    const employee = this.state.employees.find(s => s.id === parseInt(value))

    if (employee) {
      const content = this.state.content
      // console.table(employee)
      content.team.push({
        'full_name': employee.full_name,
        'userID': employee.id,
        'advocacies': [], // init advocacies,
        'key': createID(),
      })
      this.saveContent(content)
    }
  }

  onUpdateProfileAdvocacies = (id, updatedAdvocacies) => {

    const content = {...this.state.content}

    for (let index=0; index < content.team.length; index++) {
      if (id === content.team[index].userID) {
        content.team[index].advocacies = updatedAdvocacies
      }
    }
    this.saveContent(content)
  }

  onRowsChange = (rows) => {
    const content = this.state.content
    content.team = rows
    this.saveContent(content)
  }

  saveContent = (content) => {
    this.setState({ content: content }, () => {
      this.sendChangeUpstream()
    })
  }

  sendChangeUpstream = () => {
    this.props.onChange(this.props.index, [this.state.content])
  }

  loadData = async () => {
    const company = await getCompany(getToken(), this.context.company.id)

    this.setState({
      ...this.state,
      loading: false,
      employees: company.company.employees,
    })
  }

  getAdvocacies = (member) => {
    for (let {id, advocacies} of this.state.employees) {
      if (id === member.userID)
      return advocacies
    }
    return []
  }

  async componentDidMount() {
    if (this.props.initData) {
      const preview = this.props.preview
      const content = this.props.initData[0]

      this.setState({ content, preview }, async () => {
        await this.loadData()
      })
    }
  }

  renderPreviews = () => {
    const previews = []
    if (this.state.content.team) {
      this.state.content.team.map((member, index) => {
        return previews.push(
          <BlockTeamMemberEdit
            advocacies={this.getAdvocacies(member)}
            member={member}
            key={member.key}
            onChangeAdvocacies={this.onUpdateProfileAdvocacies}
          />
        )
      })
    }
    return previews
  }


  render() {
    if (this.state.loading) {
      return <Loader />
    }

    return (
        <>
        {this.state.preview
          ? <div className="dd-block" onClick={() => this.togglePreviewEditMode()}>
              <div className="dd-block__preview">
                <BlockPreviewTeam block={this.props.initData} />
              </div>
            </div>
          :
          <div className="dd-block dd-block--edit">
            <div className="dd-block__edit">
              <div className="dd-block__edit-info">
                <button className="dd-block__settings-trigger dd-btn--reset" onClick={(e) => this.toggleSettings(e)}>
                  <Icon icon="settings" />
                </button>
                <Icon icon="btn-editor-team" /> Team
              </div>

              {this.state.showSettings &&
                <BlockSectionSettings
                  content={this.state.content}
                  saveContent={this.saveContent}
                />
              }

              <div className="dd-block__edit-section">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <label className="radio-button__group-label text-nowrap ">Darstellung auswählen</label>
                    <RadioButton
                      name={`${this.props.blockKey}-viewmode`}
                      label="Kompakt"
                      value="compact"
                      handleChange={(e) => this.onRadioChange(e, "viewmode")}
                      selected={this.state.content.viewmode === "compact"}
                    />
                    <RadioButton
                      name={`${this.props.blockKey}-viewmode`}
                      label="Ausführlich"
                      value="extended"
                      handleChange={(e) => this.onRadioChange(e, "viewmode")}
                      selected={this.state.content.viewmode === "extended"}
                    />
                  </div>
                  <div className="form-check form-check-inline">
                    <label className="radio-button__group-label text-nowrap ">Teamzusammenstellung</label>
                    <RadioButton
                      name={`${this.props.blockKey}-build`}
                      label="automatisch"
                      value="automatic"
                      handleChange={(e) => this.onRadioChange(e, "build")}
                      selected={this.state.content.build === "automatic"}
                    />
                    <RadioButton
                      name={`${this.props.blockKey}-build`}
                      label="manuell"
                      value="custom"
                      handleChange={(e) => this.onRadioChange(e, "build")}
                      selected={this.state.content.build === "custom"}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <label className="radio-button__group-label text-nowrap ">Qualifikationen anzeigen</label>
                    <RadioButton
                      name={`${this.props.blockKey}-qualification_type`}
                      label="Fachanwaltschaften"
                      value="advocacies"
                      handleChange={(e) => this.onRadioChange(e, "qualification_type")}
                      selected={this.state.content.qualification_type !== "lawfields"}
                    />
                    <RadioButton
                      name={`${this.props.blockKey}-qualification_type`}
                      label="Rechtsgebiete"
                      value="lawfields"
                      handleChange={(e) => this.onRadioChange(e, "qualification_type")}
                      selected={this.state.content.qualification_type === "lawfields"}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <label className="radio-button__group-label text-nowrap ">Sprachqualifikation</label>
                    <SimpleSelectfield
                      name="langQualificationMode"
                      indexLabel="value"
                      optionLabel="position"
                      options={this.state.langQualificationOptions}
                      onSelect={this.onSelectLangQualificationMode}
                      selected={this.state.content.langQualificationMode}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <Textfield
                    name="title"
                    type="text"
                    isRequired={true}
                    label=""
                    placeholder="Team Titel*"
                    handleChange={this.onChange}
                    defaultValue={this.state.content.title}
                    errorHandler={this.props.errorHandler}
                  />
                </div>

                {this.state.content.build === "custom" &&
                  <div>

                  <div className="dd-mini-block">
                    <div className="dd-mini-block__blocks">
                      <DnDRows
                        rows={this.renderPreviews()}
                        rowsData={this.state.content.team}
                        handleChange={this.onRowsChange}
                      />
                    </div>
                  </div>
                  <div className="dd-mini-block__add">
                    <SimpleSelectfield
                      label="Team Mitglied hinzufügen"
                      name="itemsCountPerPage"
                      indexLabel="id"
                      optionLabel="full_name"
                      firstOptionLabel="Bitte auswählen"
                      options={this.state.employees}
                      onSelect={this.onSelectProfile}
                      selected={this.state.content.userID}
                    />
                  </div>
                </div>
                }

              </div>
              <BlockActions
                closeBlockCallback={this.closeEditMode.bind(this)}
                deleteBlockCallback={this.props.deleteBlockCallback.bind(this)}
                blockContent={this.state.content}
                blockKey={this.props.blockKey}
              />
            </div>
          </div>
          }
        </>
    )
  }
}

BlockTeamEdit.contextType = AppContext
export default BlockTeamEdit
