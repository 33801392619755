import React from "react";
import { getToken } from "../../../lib/lib";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Textfield from "../../Shared/atom/Textfield/Textfield";
import { updateConsentTypeTranslation } from "../../../data/api";
import Icon from "../../Shared/atom/Icon/Icon";
import Consent from "./Consent";

class ConsentType extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dirty: false,
      isUpdating: false,
      expanded: false,
      consentTypeTranslation: this.props.consentType.consent_type_translations[0]
    }
  }

  handleEditClick() {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  changeTranslationValue(e, fieldname) {
    this.setState({ dirty: true });
    const consentTypeTranslation = this.state.consentTypeTranslation
    consentTypeTranslation[fieldname] = e.target.value
    this.setState({ consentTypeTranslation })
  }

  onSubmit() {
    this.setState({ isUpdating: true });

    updateConsentTypeTranslation(getToken(), this.state.consentTypeTranslation)
    .then(
      setTimeout(() => {
        this.setState({ isUpdating: false })
        this.setState({ dirty: false });
      }, 1000)
    )
    .catch(err => {
      this.setState({ isUpdating: false })
    })
  }

  render() {

    const consentType = this.props.consentType;
    const consents = this.props.consents;

    return (
      <Droppable droppableId={consentType.id.toString()}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="dd-card"
          >
            <div className={ this.state.expanded ? "consent-type consent-type--expanded" : "consent-type" }>
              <div className="consent-type__header">
                <h3 className="consent-type__title">{ this.state.consentTypeTranslation.title }</h3>
                <button className="dd-btn dd-btn--primary dd-btn--icon-only" onClick={() => this.handleEditClick()}>
                  {this.state.expanded ? <Icon icon="plus" /> : <Icon icon="edit" />}
                </button>
              </div>
              { this.state.expanded &&
              <>
              <div className="consent-type__form">
                <h5>Datenschutz-Popup</h5>
                <div className="form-group">
                  <label className="textfield__label">Titel</label>
                  <Textfield
                    name="title"
                    type="text"
                    handleChange={(e) => this.changeTranslationValue(e, "title")}
                    defaultValue={ this.state.consentTypeTranslation.title}
                  />
                </div>
                <div className="form-group">
                  <label className="textfield__label">Kurzbeschreibung</label>
                  <textarea
                    className="form-control"
                    name="description"
                    rows="3"
                    defaultValue={ this.state.consentTypeTranslation.description}
                    onChange={(e) => this.changeTranslationValue(e, "description")}
                  />
                </div>
              </div>
              <div className="consent-type__save">
                <button className="dd-btn dd-btn--primary" disabled={this.state.isUpdating || !this.state.dirty} onClick={() => this.onSubmit()}>
                  {this.state.isUpdating ? (
                    <>
                      <div className="spinner-grow spinner-grow-sm text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <span style={{marginLeft: "10px"}}>Wird gespeichert</span>
                    </>
                  ) : "Speichern"}
                </button>
              </div>
              </>
            }
          </div>

            {consents.filter((consent) => consent.consent_type_id === consentType.id).map((consent, index) => (
              <Draggable key={consent.id.toString()} draggableId={consent.id.toString()} index={index} isDragDisabled={consent.third_party_service.identifier === 'diro'}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <Consent
                      consent={consent}
                      mandatory={consentType.mandatory}
                      activeLang={this.props.activeLang}
                      analytics_body={this.props.analytics_body}
                      analytics_head={this.props.analytics_head}
                      head_metatags={this.props.head_metatags}
                      changeValue={this.props.changeValue}
                      refreshCallback={this.props.refreshCallback}
                      submitCallback={this.props.submitCallback}
                      dragHandleProps={provided.dragHandleProps}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )
  }
}

export default ConsentType;

