import React from "react";
import { withRouter } from "react-router-dom";

import Flatpickr from "react-flatpickr";
import { German } from 'flatpickr/dist/l10n/de.js';

import PublishOnNetworkConfirmOverlay from "../Shared/module/Overlay/PublishOnNetworkConfirmOverlay";
import RadioButton from "../Shared/atom/RadioButton/RadioButton";
import Checkbox from "../Shared/atom/Checkbox/Checkbox";
import { dateToRailsDateString } from "../../lib/lib";
import { AppContext } from "../../data/ContextProvider";

class ArticlePublishing extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      publishOverlay: false,
    }
  }

  closeOverlay = () => {
    this.setState({
      publishOverlay: false,
    })
  }

  handlePublication = async () => {
    this.closeOverlay()
    this.props.publishOnNetwork()
  }

  publishOnNetwork = (e) => {
    this.setState({
      publishOverlay: true,
    })
  }


  render() {

    const published_on_network = (this.props.article.published_on_network) ? true : false
    const released_on_network  = (this.props.article.released_on_network) ? true : false

    const published_since = (this.props.article.published_since) ? new Date(this.props.article.published_since) : new Date()
    const published_until = (this.props.article.published_until) ? new Date(this.props.article.published_until) : new Date()
    const previous_date   = (this.props.article.previous_date)   ? new Date(this.props.article.previous_date)   : new Date()

    return (
      <React.Fragment>
        <div className="form-row border-bottom border-black">
          <div className="form-group mt-3">
            <label className="textfield__label">Veröffentlichungsstatus</label>
            <RadioButton
              name="published"
              label="Entwurf"
              value="0"
              handleChange={(e) => this.props.onChange(e.target.name, !e.target.checked)}
              selected={!this.props.article.published}
            />
            <RadioButton
              name="published"
              label="Veröffentlicht"
              value="1"
              handleChange={(e) => this.props.onChange(e.target.name, e.target.checked)}
              selected={this.props.article.published}
            />
          </div>
        </div>
        <div className="form-row border-bottom border-black">
          <div className="form-group mt-3">
            <label className="textfield__label">Freigabe für …</label>
            <RadioButton
              name="released_for"
              label={["Nur intern", <a key="intranet_link" href="/">&nbsp;(Intranet)</a>]}
              value="0"
              handleChange={(e) => this.props.onChange(e.target.name, Number(e.target.value))}
              selected={!this.props.article.released_for}
            />
            <RadioButton
              name="released_for"
              label="Öffentlich (www)"
              value="1"
              handleChange={(e) => this.props.onChange(e.target.name, Number(e.target.value))}
              selected={this.props.article.released_for}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group mt-3">
            <label className="textfield__label">Soll dieser Beitrag zeitgesteuert veröffentlicht werden?</label>
            <RadioButton
              name="instant_release"
              label="Nein (Veröffentlichung sofort und ohne Ablaufdatum)"
              handleChange={(e) => this.props.onChange(e.target.name, e.target.checked)}
              selected={this.props.article.instant_release}
            />
            <RadioButton
              name="instant_release"
              label="Ja"
              handleChange={(e) => this.props.onChange(e.target.name, !e.target.checked)}
              selected={!this.props.article.instant_release}
            />
          </div>
        </div>
        {this.props.article.instant_release &&
          <div className="form-row border-bottom border-black"></div>
        }
        {!this.props.article.instant_release &&
          <React.Fragment>
            <div className="form-group">
              <div className="row">
                <div className="col-3">
                  <label className="textfield__label">Ab dem ...</label>
                  <Flatpickr
                    options={{
                      mode: "single",
                      dateFormat: "d-m-Y HH:MM",
                      altInput: true,
                      enableTime: true,
                      time_24hr: true,
                      locale: German,
                      altInputClass: "form-control textfield__input"
                    }}
                    value={published_since}
                    onChange={(date) => this.props.onChange("published_since", dateToRailsDateString(date[0]))}
                  />
                </div>
              </div>
            </div>
            <div className="form-group mt-3">
              <div className="row">
                <div className="col-3">
                  <label className="textfield__label">Bis zum ...</label>
                  <Flatpickr
                    options={{
                      mode: "single",
                      dateFormat: "d-F-Y H:i",
                      altInput: true,
                      enableTime: true,
                      time_24hr: true,
                      locale: German,
                      altInputClass: "form-control textfield__input"
                    }}
                    value={published_until}
                    onChange={(date) => this.props.onChange("published_until", dateToRailsDateString(date[0]))}
                  />
                </div>
              </div>
            </div>
            <div className="form-row border-bottom border-black"></div>
          </React.Fragment>
        }
        <div className="form-row">
          <div className="form-group mt-3">
            <label className="textfield__label">Soll das Ersterscheinungsdatum dieses Beitrags rückdatiert werden?</label>
            <RadioButton
              name="previous_release"
              label="Nein"
              handleChange={(e) => this.props.onChange(e.target.name, !e.target.checked)}
              selected={!this.props.article.previous_release}
            />
            <RadioButton
              name="previous_release"
              label="Ja"
              handleChange={(e) => this.props.onChange(e.target.name, e.target.checked)}
              selected={this.props.article.previous_release}
            />
          </div>
        </div>
        {!this.props.article.previous_release &&
          <div className="form-row border-bottom border-black"></div>
        }
        {this.props.article.previous_release &&
          <React.Fragment>
            <div className="form-group">
              <div className="row">
                <div className="col-3">
                  <label className="textfield__label">Ersterscheinungsdatum</label>
                  <Flatpickr
                    options={{
                      mode: "single",
                      dateFormat: "d-m-Y HH:MM",
                      altInput: true,
                      enableTime: true,
                      time_24hr: true,
                      locale: German,
                      altInputClass: "form-control textfield__input"
                    }}
                    value={previous_date}
                    onChange={(date) => this.props.onChange("previous_date", dateToRailsDateString(date[0]))}
                  />
                </div>
              </div>
            </div>
            <div className="form-row border-bottom border-black"></div>
          </React.Fragment>
        }

        {this.context.company.is_diro_member &&
          <div className="form-row">
            <div className="form-group mt-3">
              <label className="textfield__label">Diesen Beitrag im DIRO-Netzwerk veröffentlichen</label>
              {!this.context.company.is_diro &&
                <Checkbox
                  label="ja"
                  id="published_on_network"
                  selected={published_on_network}
                  onChange={(e) => this.publishOnNetwork(e)}
                />
              }
              {this.context.company.is_diro &&
                <Checkbox
                  label="ja"
                  id="released_on_network"
                  selected={released_on_network}
                  onChange={(e) => this.props.releaseOnNetwork(e)}
                />
              }
            </div>
          </div>
        }

        {this.state.publishOverlay &&
          <PublishOnNetworkConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.handlePublication}
            published={this.props.article.published_on_network}
            content_type={this.props.article.content_type}
          />
        }
      </React.Fragment>
    )
  }
}

ArticlePublishing.contextType = AppContext
export default withRouter(ArticlePublishing)
