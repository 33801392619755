import React from "react";

import { throttle } from "throttle-debounce";

import Loader from "../Shared/atom/Loader/Loader";
import Icon from "../Shared/atom/Icon/Icon";
import Textfield from "../Shared/atom/Textfield/Textfield";
import Searchbar from "../Shared/element/Searchbar/Searchbar";
import Pagination from "../Shared/element/Pagination/Pagination";
import Editbar from "../Shared/element/Editbar/Editbar";
import DeleteConfirmOverlay from "../Shared/module/Overlay/DeleteConfirmOverlay";
import PageSelector from "../Shared/module/PageSelector/PageSelector";
import Overlay from "../Shared/element/Overlay/Overlay";

import { updateRedirects, createRedirect, getRedirects, deleteRedirect } from "../../data/api";
import { getToken } from "../../lib/lib";
import { AppContext } from "../../data/ContextProvider";

class Taxonomies extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      loading: true,
      addNewItem: false,
      newItems: [{ id: 0, from_path: '', to_path: ''}],
      pageOverlayActive: false,
      pageNewOverlayActive: false,
      count: 0,
      pages: 0,
      currentPage: 0,
      limit: 10,
      deleteConfirmOverlay: false,
      confirmMode: "total_delete",
      items: [],
      selectedPage: 0,
    }

    this.searchThrottled = throttle(500, this.loadData);
  }

  openPageSelectorOverlay = (e, item_id, isNewItem) => {
    e.preventDefault()

    this.setState({
      pageOverlayActive: !isNewItem,
      pageNewOverlayActive: isNewItem,
      selectedPage: item_id
    })
  }

  closePageSelectorOverlay = () => {
    this.setState({
      pageOverlayActive: false,
      pageNewOverlayActive: false,
    })
  }

  handlePageSelect = (page) => {
    const index = this.state.items.findIndex(s => s.id === this.state.selectedPage)

    if (index >= 0) {

      const items = [...this.state.items]

      if (page.id && ('pagetype_id' in page)) {
        items[index]["page_id"] = page.id
        items[index]["page"]    = page
        items[index]["article_id"] = 0
        items[index]["article"]    = null
      } else if (page.id) {
        items[index]["article_id"] = page.id
        items[index]["article"]    = page
        items[index]["page_id"] = 0
        items[index]["page"]    = null
      }

      this.setState({ items }, () => {
      })
    }

    this.closePageSelectorOverlay()
  }

  handleNewPageSelect = (page) => {
    const index = this.state.newItems.findIndex(s => s.id === this.state.selectedPage)

    if (index >= 0) {
      const newItems = [...this.state.newItems]

      if (page.id && ('pagetype_id' in page)) {
        newItems[index]["page_id"] = page.id
        newItems[index]["page"] = page
        newItems[index]["article_id"] = 0
        newItems[index]["article"]    = null
      } else if (page.id) {
        newItems[index]["article_id"] = page.id
        newItems[index]["article"]    = page
        newItems[index]["page_id"] = 0
        newItems[index]["page"]    = null
      }

      this.setState({ newItems }, () => {
      })
    }

    this.closePageSelectorOverlay()
  }


  closeOverlay = () => {
    this.setState({
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false,
    })
  }

  showDeleteConfirmation = (item) => {
    this.setState({
      deleteConfirmOverlay: true,
      itemToDelete: item.id,
      confirmMode: "total_delete"
    })
  }

  onFilterChange = (event) => {
    this.setState({
      query: event.target.value,
      currentPage: 0
    }, () => {
      this.searchThrottled()
    })
  }


  addNewItem = () => {
    const newItems = this.state.newItems
    if (this.state.addNewItem) {
      let id = newItems[0].id
      id++
      newItems.unshift({ id: id, from_path: '', to_path: '', page_id: 0});
    }
    this.setState({ addNewItem: true, newItems })
  }

  changeNewValue = (e, item, fieldname) => {
    const index = this.state.newItems.findIndex(s => s.id === item.id)
    if (index >= 0) {
      const newItems = [...this.state.newItems]
      newItems[index][fieldname] = e.target.value
      this.setState({ newItems }, () => {
      })
    }
  }

  changeValue = (e, item, fieldname) => {
    const index = this.state.items.findIndex(s => s.id === item.id)
    if (index >= 0) {
      const items = [...this.state.items]
      items[index][fieldname] = e.target.value
      this.setState({ items }, () => {
      })
    }
  }

  onSelectType = (value) => {
    this.setState({ selectedType: value }, async () => {
      await this.loadData()
    })
  }

  handlePaginationClick = async (e, page) => {
    this.setState({
      currentPage: page
    }, async () => {
      await this.loadData()
    })
  }

  handleSelectLimit = (limit) => {
    this.setState({ limit, currentPage: 0 }, async () => {
      await this.loadData()
    })
  }

  onSubmit = async () => {
    this.setState({ isUpdating: true })

    if (this.state.addNewItem) {
      this.state.newItems.map(async newItem => {
        await createRedirect(getToken(), newItem)
      })
    }

    await updateRedirects(getToken(), this.state.items)

    this.setState({ isUpdating: false })

    await this.loadData()
  }

  onDeleteConfirmation = async () => {

    const itemToDelete = this.state.itemToDelete

    if (itemToDelete) {
      this.setState({
        isUpdating: true,
      }, () => {
        deleteRedirect(getToken(), itemToDelete)
          .then(async updatedArticle => {
            this.closeOverlay()
            await this.loadData()
          })
          .catch(err => {
            this.setState({ isUpdating: false })
          })
      })
    }
  }

  loadData = async () => {
    const offset = this.state.currentPage * this.state.limit

    const items = await getRedirects(
      getToken(),
      this.state.limit,
      offset,
      this.state.query
    )

    this.setState({
      ...this.state,
      pagesCount: Math.ceil(items.count / this.state.limit),
      count: items.count,
      items: items.redirects,
      loading: false,
      newItems: [{ id: 0, from_path: '', to_path: ''}],
      addNewItem: false
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  renderPageforItem = (item) => {
    if (item && item.page_id && item.page) {
      return item.page.title
    } else if (item && item.article_id && item.article) {
      return item.article.title
    }
    return ""
  }

  render() {
    const admin = (this.context.user.admin || this.context.user.diro_admin)

    if (this.state.loading || !admin) {
      return <Loader />
    }

    return (
      <>
        <div className="dd-card">
          <div className="dd-form-section dd-form-section--transparent d-flex">
            <div className="dd-search-bar dd-search-bar--small">
              <Searchbar
                placeholder=""
                icon="filter"
                onChangeCallback={(e) => this.onFilterChange(e)}
              />
            </div>
          </div>

          <div className="dd-form-section dd-form-section--first">
            <div className="d-flex justify-content-center">
              <span
                className="dd-btn__wrapper"
                onClick={this.addNewItem}
              >
                <button className="dd-btn dd-btn--primary dd-btn--icon-only" >
                  <Icon icon="plus" />
                </button>
                <span className="dd-btn__text">Neue Weiterleitung</span>
              </span>
            </div>
          </div>


          <div className="dd-form-section dd-form-section--small dd-form-section--last">
            <table className="table dd-table dd-table--transparent">
              <thead>
                <tr>
                  <th scope="col">Von (alter Pfad)</th>
                  <th scope="col">Nach (neuer Pfad)</th>
                  <th scope="col">internes Ziel</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>

              {(this.state.addNewItem) &&
                <React.Fragment>
                  {this.state.newItems.map((newItem, key) => {
                    return <tr key={newItem.id}>
                      <td key={`${newItem.id}-from_path`}>
                        <Textfield
                          name="from_path"
                          type="text"
                          handleChange={(e) => this.changeNewValue(e, newItem, "from_path")}
                          defaultValue={newItem.from_path}
                        />
                      </td>
                      <td key={`${newItem.id}-to_path`}>
                        <Textfield
                          name="to_path"
                          type="text"
                          handleChange={(e) => this.changeNewValue(e, newItem, "to_path")}
                          defaultValue={newItem.to_path}
                        />
                      </td>
                      <td>
                        {this.renderPageforItem(newItem)} &nbsp;
                      </td>
                      <td key={`${newItem.id}-new_intern_page_id`}>
                      <button className="dd-btn dd-btn--primary dd-link-selector__button" onClick={(e) => this.openPageSelectorOverlay(e, newItem.id, true)}>
                          Linkziel ändern
                      </button>
                        {this.state.pageNewOverlayActive &&
                          <Overlay closeOverlayCallback={() => this.closePageSelectorOverlay()}>
                            <PageSelector
                              disableExternal={true}
                              selectPageCallback={(page) => this.handleNewPageSelect(page)}
                            />
                          </Overlay>
                        }
                      </td>

                      <td className="justify-content-center dd-table--actions">
                      </td>
                    </tr>
                  })}
                </React.Fragment>
              }

                {this.state.items.map((item, key) => {
                  return <tr key={key}>
                    <td key={`${item.id}-from_path`}>
                      <Textfield
                        name="from_path"
                        type="text"
                        handleChange={(e) => this.changeValue(e, item, "from_path")}
                        defaultValue={item.from_path}
                      />
                    </td>
                    <td key={`${item.id}-to_path`}>
                      <Textfield
                        name="to_path"
                        type="text"
                        handleChange={(e) => this.changeValue(e, item, "to_path")}
                        defaultValue={item.to_path}
                      />
                    </td>
                    <td>
                      {this.renderPageforItem(item)}
                    </td>
                    <td key={`${item.id}-intern_page_id`}>
                      <button className="dd-btn dd-btn--primary dd-link-selector__button" onClick={(e) => this.openPageSelectorOverlay(e, item.id, false)}>
                        Linkziel ändern
                      </button>

                      {this.state.pageOverlayActive &&
                        <Overlay closeOverlayCallback={() => this.closePageSelectorOverlay()}>
                          <PageSelector
                            disableExternal={true}
                            selectPageCallback={(page) => this.handlePageSelect(page)}
                          />
                        </Overlay>
                      }
                    </td>

                    <td className="justify-content-center dd-table--actions">
                      <div className="dd-table--actions_wrapper">
                        <button type="submit" className="dd-btn dd-btn--danger dd-btn--icon-only" onClick={() => this.showDeleteConfirmation(item)}>
                          <Icon icon="trash" />
                        </button>
                      </div>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </div>

        {this.state.deleteConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.onDeleteConfirmation}
            mode={this.state.confirmMode}
          />
        }

        <Pagination
          total={this.state.pagesCount}
          current={this.state.currentPage}
          onChange={this.handlePaginationClick}
          onSelect={this.handleSelectLimit}
          limit={this.state.limit}
        />

        <Editbar
          hasPublish={false}
          hasPreview={false}
          onSave={this.onSubmit}
          successMessage="Erfolgreich gespeichert"
        />
      </>
    )
  }
}

Taxonomies.contextType = AppContext
export default Taxonomies;
