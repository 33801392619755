import React from "react";
import Icon from "../../atom/Icon/Icon";
import Navigation from "../../module/Navigation/Navigation";

import { AppContext } from "../../../../data/ContextProvider";

class Navbar extends React.Component {

  render() {
    const title = (this.props.isDiroLayout) ? "Diro" : "Kanzlei " + this.context.company.name

    return (
      <nav
        className={`navbar navbar-expand-lg navbar-dark fixed-top dd_navbar ${this.props.isDiroLayout ? "dd_navbar--diro" : "dd_navbar--company"}`}>
        <div className="row w-100 align-items-center mt-2 mb-1">
          <button className={"btn dd_navbar-dashboard_btn"}>
            <a href="/"><Icon icon={"dashboard"} /></a>
          </button>
          <a className="navbar-brand mx-auto" href="/">{title}</a>
            <span className="navbar-username">{this.context.user.firstname} {this.context.user.lastname}</span>
            <button className="btn dd_navbar-logout_btn" type="submit" onClick={this.context.logout}>
              <Icon icon={"exit"} />
            </button>
        </div>
        <div className="row w-100 align-items-center">
          <Navigation isDiro={this.props.isDiroLayout} />
        </div>
      </nav>
    )
  }
}

Navbar.contextType = AppContext
export default Navbar;
