import React from "react";
import { Link } from "react-router-dom";

import { throttle } from "throttle-debounce";

import { AppContext } from '../../data/ContextProvider'

import Searchbar from "../Shared/element/Searchbar/Searchbar";
import Title from "../Shared/module/Title/Title"
import Pagination from "../Shared/element/Pagination/Pagination";
import DeleteConfirmOverlay from "../Shared/module/Overlay/DeleteConfirmOverlay";
import TableColumnHeader from "../../layout/Tables/TableColumnHeader";
import Icon from "../Shared/atom/Icon/Icon";

import { getCompanies, updateCompany } from "../../data/api";
import { getToken, formatShortDate } from "../../lib/lib";
import { SITE_TYPES } from "../../data/config";

class OfficesTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      loading: false,
      deleteConfirmOverlay: false,
      count: 0,
      pages: 0,
      currentPage: 0,
      limit: 10,
      defaultLimit: 10,
      orderField: 'updated_at',
      orderDirection: 'desc',
      companies: []
    }

    this.searchThrottled = throttle(500, this.loadData);
  }

  onFilterChange = (event) => {
    this.setState({
      query: event.target.value,
      currentPage: 0
    }, () => {
      this.searchThrottled()
    })
  }


  handlePaginationClick = async (e, page) => {
    this.setState({
      ...this.state,
      currentPage: page
    }, async () => {
      await this.loadData()
    })
  }

  handleSelectLimit = (limit) => {
    localStorage.setItem('offices_limit', limit);
    this.setState({ limit, currentPage: 0 }, async () => {
      await this.loadData()
    })
  }

  onTableColumnClick = (column) => {
    const { orderDirection } = this.state

    this.setState({
      orderField: column,
      orderDirection: (orderDirection === 'desc') ? 'asc' : 'desc'
    }, async () => {
      await this.loadData()
    })
  }

  closeOverlay = () => {
    this.setState({
      deleteConfirmOverlay: false,
      hideConfirmOverlay: false,
    })
  }

  showDeleteConfirmation = (company) => {
    this.setState({
      deleteConfirmOverlay: true,
      companyToDelete: company.id,
      confirmMode: "delete"
    })
  }

  showPublishConfirmation = (company) => {
    this.setState({
      hideConfirmOverlay: true,
      companyToHide: company.id,
      confirmMode: (company.published) ? "hide" : "show"
    })
  }

  handlePublication = async () => {

    const index = this.state.companies.findIndex(s => s.id === this.state.companyToHide)
    const company = this.state.companies[index]

    company.published = !company.published

    this.setState({
      isUpdating: true,
    }, () => {
      updateCompany(getToken(), company.id, company)
      .then(async updatedArticle => {
        await this.loadData()
      })
      .catch(err => {
        this.setState({ isUpdating: false })
      })
    })
  }


  onDeleteConfirmation = async (page) => {

    // const pageToDelete = this.state.pageToDelete

    // if (pageToDelete) {
    //   this.setState({
    //     isUpdating: true,
    //   }, () => {
    //     deletePage(getToken(), pageToDelete)
    //       .then(async updatedArticle => {
    //         this.setState({ deleteConfirmOverlay: false })
    //         await this.loadData()
    //       })
    //       .catch(err => {
    //         this.setState({ isUpdating: false })
    //       })
    //   })
    // }
  }

  loadData = async () => {
    try {

      const limit = localStorage.getItem('offices_limit') || this.state.defaultLimit

      const offset = this.state.currentPage * limit

      const companies = await getCompanies(getToken(), limit, offset, this.state.orderField, this.state.orderDirection, this.state.query)

      this.setState({
        ...this.state,
        limit: limit,
        pages: Math.ceil(companies.count / limit),
        count: companies.count,
        companies: companies.companies,
        deleteConfirmOverlay: false,
        hideConfirmOverlay: false
      })
    } catch (error) {
      //FIXME NotFound
    }
  }

  async componentDidMount() {
    await this.loadData()
  }

  render() {
    return (
      <React.Fragment>
        <Title headline="Liste der Kanzleien" />

        <table className="table dd-table">
          <thead>
          <tr>
            <TableColumnHeader
                name="Name"
                width="30%"
                field="name"
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick}/>

            <th scope="col">Typ</th>

            <TableColumnHeader
                name="Erstellt"
                field="created_at"
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick}/>

            <TableColumnHeader
                name="Geändert"
                field="updated_at"
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick}/>

            <TableColumnHeader
                name="Ort"
                width="15%"
                field="city"
                currentOrderField={this.state.orderField}
                currentOrderDirection={this.state.orderDirection}
                onClick={this.onTableColumnClick}/>

            <th scope="col">Ansprechpartner</th>
            <th scope="col" className="dd-table--actions-th">
              <div className="dd-search-bar dd-search-bar--small dd-search-bar--white dd-table--search-filter">
                <Searchbar
                    placeholder="Verfügbare Kanzleien filtern …"
                    icon="filter"
                    onChangeCallback={(e) => this.onFilterChange(e)}
                />
              </div>
            </th>
            {/*<th scope="col" className="dd-table--actions-th">Actions</th>*/}
          </tr>
          </thead>
          <tbody>
          {this.state.companies.map((office, key) => {
            const type = SITE_TYPES[office.site_type];
            return <tr key={key} className={(!office.published) ? 'is-inactive' : ''}>
              <td className={(this.state.orderField === 'name') ? 'is-active' : ''}>{office.name}</td>
              <td>{type && [1, 2].includes(office.site_type)
                  ? <span className="pill pill--emphasized">{type}</span>
                  : <span className="pill">{type}</span>}
              </td>
              <td className={(this.state.orderField === 'created_at') ? 'is-active' : ''}>{formatShortDate(office.created_at)}</td>
              <td className={(this.state.orderField === 'updated_at') ? 'is-active' : ''}>{formatShortDate(office.updated_at)}</td>
              <td className={(this.state.orderField === 'city') ? 'is-active' : ''}>{(office.hq.city) ? office.hq.city.title_de : ""}</td>
              <td>
                { office.administrator &&
                    <Link to={`/de/offices/${office.id}/team/${office.administrator.id}`}>
                      {office.administrator.full_name}
                    </Link>
                }
              </td>
              <td className="justify-content-center dd-table--actions">
                <div className="dd-table--actions_wrapper">
                  <button type="submit" className="dd-btn dd-btn--danger dd-btn--icon-only mr-2" onClick={(e) => this.showDeleteConfirmation(office)}>
                    <Icon icon="trash"/>
                  </button>
                  <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only" onClick={() => this.showPublishConfirmation(office)}>
                    {office.published
                        ? < Icon icon="visibilty"/>
                        : < Icon icon="hidden"/>
                    }
                  </button>
                  <Link to={`/de/offices/${office.id}/data`}>
                    <button type="submit" className="dd-btn dd-btn--primary dd-btn--inverted dd-btn--icon-only">
                      <Icon icon="edit"/>
                    </button>
                  </Link>
                </div>
              </td>
            </tr>
          })}
          </tbody>
        </table>
        {this.state.deleteConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.onDeleteConfirmation}
            mode={this.state.confirmMode}
          />
        }

        {this.state.hideConfirmOverlay &&
          <DeleteConfirmOverlay
            closeOverlay={this.closeOverlay}
            onConfirmation={this.handlePublication}
            mode={this.state.confirmMode}
          />
        }
        <div className="float-right">
          <Pagination
            total={this.state.pages}
            current={this.state.currentPage}
            onChange={this.handlePaginationClick}
            onSelect={this.handleSelectLimit}
            limit={this.state.limit}
          />
        </div>

        {this.context.user.diro_admin &&
          <Link to={`/de/offices/new`} className="dd-btn__wrapper">
            <button className="dd-btn dd-btn--primary dd-btn--icon-only" >
              <Icon icon="plus" />
            </button>
            <span className="dd-btn__text">Neue Kanzlei anlegen</span>
          </Link>
        }

      </React.Fragment>
    )
  }
}

OfficesTable.contextType = AppContext
export default OfficesTable;
