import React from "react";
import { withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import t from "../../../../helpers/t9n";
import Icon from "../../atom/Icon/Icon";

class Editbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      actionDone: false,
    }
  }

  handleSave = () => {
    this.setState({
      success: true, //TO SUCCESS OR NOT SUCCESS ?
      actionDone: !this.state.actionDone
    }, () => {
      this.props.onSave()
      this.saveTimeout = setTimeout(() => {
        this.setState({
          success: false,
          actionDone: false
        });
      }, 3000);
    })
  }

  handleRepublish = () => {
    this.setState({
      success: true, //TO SUCCESS OR NOT SUCCESS ?
      actionDone: !this.state.actionDone
    }, () => {
      this.props.onRepublish()
      this.republishTimeout = setTimeout(() => {
        this.setState({
          success: false,
          actionDone: false
        });
      }, 3000);
    })
  }

  renderPublishButton = () => {
    if (this.props.hasPublish) {
      if (this.props.isAllowedToPublish) {
        return <button className="dd-btn dd-btn--inverted" onClick={this.handleRepublish}>{t('action.reset_publish')}</button>
      } else {
        return <button className="dd-btn dd-btn--inverted">{t('action.sendToAcceptance')}</button>
      }
    }
  }

  componentDidMount() {
    this.setState({
      successMessage: this.props.successMessage || "",
      errorMessage: this.props.errorMessage || "",
    })
  }

  componentWillUnmount() {
    clearTimeout(this.saveTimeout);
    clearTimeout(this.republishTimeout);
  }

  render() {
    let buttonClassName = this.props.hasError
      ? 'dd-btn dd-btn--disabled'
      : 'dd-btn dd-btn--inverted'

    const message = this.props.hasError
      ? this.props.errorMessage
      : this.props.successMessage

    return (
      <nav className="navbar navbar-expand-lg navbar-dark fixed-bottom dd_navbar--actionbar">
        <div className="dd_navbar--actionbar__container w-100 d-flex h-100">
          <div className="loading-bar__wrapper">
            <span className={`loading-bar__progress ${(this.state.success || this.props.hasError) ? "done" : ""}`} />
          </div>

          {(this.state.actionDone || this.props.hasError) &&
          <span className="d-flex align-items-center info-message pl-5">
            <div className={"checkmark__container"}>
              {this.state.success ?
                <Icon icon={"edit"} />
                :
                <Icon icon={"plus"} />
              }
            </div>
            <p>{message}</p>
          </span>
          }

          {this.props.historyBack &&
            <span className="d-flex align-items-center info-message pl-5">
              <button className="dd-btn dd-btn--primary" onClick={() => this.props.history.goBack()}>
                « Zurück zur Übersicht
              </button>
            </span>
          }

          <div className="form-inline ml-auto my-2 my-lg-0 pr-4">
            {this.props.hasPreview &&
              <a href={this.props.previewURL} target="_blank" rel="noopener noreferrer" className="link-btn">
                <button className="dd-btn dd-btn--inverted">{t('action.preview')}</button>
              </a>
            }
            {this.props.hasSave &&
              <button
              className={buttonClassName}
              onClick={() => this.handleSave()}
              disabled={this.props.hasError}
              >
              {t('action.save')}
              </button>
            }
            {
              this.renderPublishButton()
            }
            </div>
        </div>
      </nav>
    )
  }
}

Editbar.propTypes = {
  hasInfoMessage: PropTypes.bool,
  hasSave: PropTypes.bool,
  hasPublish: PropTypes.bool,
  isAllowedToPublish: PropTypes.bool,
  hasPreview: PropTypes.bool,
};
Editbar.defaultProps = {
  hasInfoMessage: false,
  hasSave: true,
  hasPreview: true,
  isAllowedToPublish: true,
};

export default withRouter(Editbar);
