import React from 'react'

import Icon from "../Icon/Icon";
import Textfield from "../Textfield/Textfield";
import Overlay from "../../element/Overlay/Overlay";

class TagList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      loading: true,
      availableData: [],
      filteredData: [],
      filteredDataCached: [],
      data: [],
      isPopupVisible: false,
      addedTagsVisible: true,
      hasAddNew: false,
      newTagTmp: "",
      isAddNewMode: false,
      name: "",
      hasMargin: false,
      isPlusButtonVisible: true,
      disabled: false
    }
  }

  handleTagAdd = (e, tagID) => {
    e.preventDefault()
    const data = [...this.state.data]
    const addedTag = this.state.filteredData.find(tag => tag.id === tagID)

    data.push(addedTag)

    this.setState({
      data: data,
      filteredData: this.filterAssignedTags(this.state.filteredData, data),
      filteredDataCached: this.filterAssignedTags(this.state.filteredDataCached, data)
    }, () => {
      this.props.onUpdate(data)
    })
  }

  removeTag = (e, tagID) => {

    if (!this.props.onUpdate) {
      return
    }

    e.preventDefault()
    const data = this.state.data.filter(tag => tag.id !== tagID)

    const hasError = (!data.length && this.props.isRequired) ? true : false

    this.setState({
      hasError,
      data: data,
      filteredData: this.filterAssignedTags(this.props.availableData, data),
      filteredDataCached: this.filterAssignedTags(this.props.availableData, data)
    }, () => {
      this.props.onUpdate(data)
    })

  }

  setIsPopupStatus = (e, status) => {
    e.preventDefault()
    this.setState({
      ...this.state,
      isAddNewMode: false,
      isPopupVisible: status,
      filteredData: this.state.filteredDataCached // reset
    })
  }

  closeTagOverlay(e) {
    this.setIsPopupStatus(e, false)
  }

  filterAssignedTags = (allTags, assignedTags) => {
    const assignedIDs = assignedTags ? assignedTags.map(tag => tag.id) : []
    return allTags ? allTags.filter(tag => !assignedIDs.includes(tag.id)) : []
  }

  handleSearch = (e) => {
    const originValue = e.target.value
    const value = originValue.toLowerCase()

    const newTagTmp = (this.state.hasAddNew) ? originValue : this.state.newTagTmp
    const isAddNewMode = (this.state.hasAddNew) ? true : this.state.isAddNewMode

    const searchResults = this.state.filteredDataCached.filter((tag) => {
      const tmp = this.termLabel(tag)
      if (tmp) {
        return tmp.toLowerCase().includes(value)
      }
      return false
    })

    if (searchResults.length !== 0) {
      this.setState({
        filteredData: searchResults,
        isAddNewMode: false
      })
    }
    else {
      this.setState({
        filteredData: searchResults,
        newTagTmp,
        isAddNewMode
      })
    }
  }

  termLabel = (term) => {
    if (this.props.labelCallback) {
      return this.props.labelCallback(term)
    }

    if (this.props.indexLabel) {
      return term[this.props.indexLabel]
    }
  }

  addNewTag = (e) => {
    e.preventDefault();
    const data = [...this.state.data]
    const newTag = {
      title: this.state.newTagTmp
    }

    data.push(newTag)

    this.props.onCreate(newTag)

    this.setState({
      data: data
    })
  }

  componentDidMount() {

    this.setState({
      isPlusButtonVisible: (this.props.isPlusButtonVisible === undefined) ? true : this.props.isPlusButtonVisible,
      addedTagsVisible: (this.props.addedTagsVisible === undefined) ? true : this.props.addedTagsVisible,
      disabled: (this.props.disabled === undefined) ? false : this.props.disabled ,
      data: this.props.data || [],
      label: this.props.label,
      hasAddNew: this.props.hasAddNew,
      availableData: this.props.availableData,
      indexLabel: this.props.indexLabel,
      labelCallback: this.props.labelCallback,
      filteredData: this.filterAssignedTags(this.props.availableData, this.props.data),
      filteredDataCached: this.filterAssignedTags(this.props.availableData, this.props.data)
    })
  }

  getTagAdder = () => {

    return (
      <Overlay title={this.state.label} closeOverlayCallback={(e) => this.closeTagOverlay(e)}>
        <div className={`tag-adder ${this.state.isAddNewMode ? "tag-adder--add" : ""}`}>
          <div className="tag-adder__upper">
            <span className="tag-adder__selected">
              <span className={`tag-adder__search`}>
                <Textfield name="tag-search" type="text" handleChange={(e) => this.handleSearch(e)} />
                {this.state.isAddNewMode ?
                  <button className="btn btn-light dd_tag-add-btn" onClick={(e) => this.addNewTag(e)}>
                    <Icon icon="plus" />
                  </button>
                  :
                  <Icon icon={"search"} />
                }
              </span>
              {this.state.data.map((elem, i) => {
                return (
                  <span className="dd_tag" key={"tag_" + i}>
                    <span className="dd_tag-label">{this.termLabel(elem)}</span>
                    <button className="btn btn-light dd_tag-remove-btn" onClick={(e) => this.removeTag(e, elem.id)}>
                      <Icon icon="plus" />
                    </button>
                  </span>
                )
              })}
            </span>
          </div>
          <div className="tag-adder__lower">
            <span className="tag-adder__available">
              {this.state.filteredData.map((elem, i) => {
                return (
                  <span className="dd_tag" key={"tag_" + i}>

                    <span className="dd_tag-label">{this.termLabel(elem)}</span>

                    <button className="btn btn-light dd_tag-add-btn" onClick={(e) => this.handleTagAdd(e, elem.id)}>
                      <Icon icon="plus" />
                    </button>
                  </span>
                )
              })}
            </span>
          </div>
        </div>
      </Overlay>
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className={`form-group ${this.state.hasMargin ? "" : "mb-0"}`}>
          {this.state.label &&
            <label className={"textfield__label"} htmlFor={this.state.name + "tag-list"}>{this.state.label}{this.props.isRequired ? '*' : ''}</label>
          }
          <div className="d-block dd_tag-list__wrapper ml-2" id={this.state.name + "tag-list"}>
            {this.state.isPopupVisible &&
              this.getTagAdder()
            }

            <div className="dd_tag-list">
              {this.state.isPlusButtonVisible &&
                <button className="btn btn-primary dd_tag-popup-btn"
                  onClick={(e) => this.setIsPopupStatus(e, true)}>
                  <Icon icon="plus" />
                </button>
              }
              {this.state.addedTagsVisible && this.state.data && this.state.data.map((elem, i) => {
                return (
                  <span className={(this.state.disabled) ? "dd_tag dd_tag--disabled" : "dd_tag"} key={"tag_" + i}>

                    <span className="dd_tag-label">{this.termLabel(elem)}</span>

                    {!this.state.disabled &&
                      <button className="btn btn-light dd_tag-remove-btn" onClick={(e) => this.removeTag(e, elem.id)}>
                        <Icon icon="plus" />
                      </button>
                    }
                  </span>
                )
              })}
            </div>
          </div>
        </div>

        {this.state.hasError && (
          <div className="textfield__error-wrapper--left-aligned">
            <div className="textfield__error-message">Bitte fügen Sie eine Angabe hinzu</div>
          </div>
        )}
      </React.Fragment>
    );
  }
}


export default TagList
