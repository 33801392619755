import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Flatpickr from 'react-flatpickr'

import Loader from '../Shared/atom/Loader/Loader'
import Checkbox from '../Shared/atom/Checkbox/Checkbox'
import SimpleSelectfield from '../Shared/atom/Selectfield/SimpleSelectfield'
import Textfield from '../Shared/atom/Textfield/Textfield'
import TextfieldGroup from '../Shared/atom/TextfieldGroup/TextfieldGroup'
import TagList from '../Shared/atom/TagList/TagList'

import { German } from 'flatpickr/dist/l10n/de.js'

import { getTerms, getCompany } from '../../data/api'
import { getToken, dateToRailsDateString } from '../../lib/lib'

class EventDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      eventcategories: [],
      targetgroups: [],
      countries: [],
      loading: true
    }
  }

  loadData = async () => {
    try {
      const eventcategories = await getTerms(getToken(), 'eventcategories')
      const targetgroups = await getTerms(getToken(), 'targetgroups')
      const countries = await getTerms(getToken(), 'countries')
      const company = await getCompany(getToken(), this.props.event.company_id)

      // Sort alphabetically and put hq first

      company.company.company_sites.sort((a,b) => {
        const acity = a.city.title_de;
        const bcity = b.city.title_de;
        return (acity < bcity) ? -1 : (acity > bcity) ? 1 : 0;
      }).sort((a) => {
        const site_id = a.id;
        const hq_id = company.company.hq.id;
        return site_id === hq_id ? -1 : 0;
      })

      this.setState({
        eventcategories: eventcategories.eventcategories,
        targetgroups: targetgroups.targetgroups,
        company: company.company,
        countries: countries.countries,
        loading: false
      })
    } catch (error) {
      //FIXME NotFound
    }
  }

  cityLabel = (site) => {
    return site.city.title_de
  }

  componentDidMount() {
    this.loadData()
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }

    const event_start = this.props.event.event_start
      ? new Date(this.props.event.event_start)
      : new Date()
    const event_end = this.props.event.event_end
      ? new Date(this.props.event.event_end)
      : new Date()
    const deadline = this.props.event.deadline
      ? new Date(this.props.event.deadline)
      : new Date()

    const hasMultipleSites = this.state.company.company_sites.length > 1;
    const company_sites = this.state.company.company_sites;

    return (
      <React.Fragment>
        <div className="form-row">
          <div className="col-md-6 mb-2">
            <SimpleSelectfield
              label="Veranstalter"
              name="organizer_id"
              firstOptionValue={hasMultipleSites ? "0" : ""}
              firstOptionLabel={hasMultipleSites ? "Bitte auswählen" : ""}
              indexLabel="id"
              optionLabelCallback={this.cityLabel}
              options={company_sites}
              onSelect={(value) =>
                this.props.onChange('organizer_id', value)
              }
              selected={hasMultipleSites ? this.props.event.organizer_id : '0'}
              disabled={this.props.event.is_external_organizer || !hasMultipleSites}
            />
          </div>
          <div className="col-md-6 pt-4 mt-3">
            <Checkbox
              name="is_external_organizer"
              label="Anderer Veranstalter"
              onChange={() =>
                this.props.onChangeExternalOrganizer(
                  'is_external_organizer',
                  !this.props.event.is_external_organizer
                )
              }
              selected={this.props.event.is_external_organizer || false}
            />
          </div>
        </div>
        {this.props.event.is_external_organizer && (
          <div className="form-row">
            <div className="col-md-6 mb-2">
              <Textfield
                name="organizer"
                label="Veranstalter Name der Organisation"
                type="text"
                defaultValue={this.props.event.organizer}
                handleChange={(e) =>
                  this.props.onChange('organizer', e.target.value)
                }
              />
            </div>
          </div>)
        }
          <div className="form-row">
              <div className="col-md-6 mb-2">
                  <SimpleSelectfield
                      label="Kategorie"
                      name="eventcategory_id"
                      firstOptionValue="0"
                      firstOptionLabel="Bitte auswählen"
                      indexLabel="id"
                      optionLabel="title_de"
                      options={this.state.eventcategories}
                      onSelect={(value) =>
                          this.props.onChange('eventcategory_id', value)
                      }
                      selected={this.props.event.eventcategory_id}
                  />
              </div>
              <div className="col-md-6 pt-4 mt-3">
                  <Checkbox
                      name="is_webinar"
                      label="Webinar (nur online)"
                      onChange={() =>
                          this.props.onChangeWebinar(
                              'is_webinar',
                              !this.props.event.is_webinar
                          )
                      }
                      selected={this.props.event.is_webinar || false}
                  />
              </div>
              <div className="col-md-6 mb-2">
              <TagList
                      indexLabel="title_de"
                      name="targetgroups"
                      data={this.props.event.targetgroups}
                      availableData={this.state.targetgroups}
                      label="Zielgruppe"
                      onUpdate={(assignedTags) =>
                          this.props.onChange('targetgroups', assignedTags)
                      }
                  />

              </div>
          </div>
          <hr/>
          <div className="form-row">
              <div className="col-md-6 mb-2">
                  <label className="selectfield__label">Veranstaltungsbeginn</label>
                  <Flatpickr
                      options={{
                          mode: 'single',
                          dateFormat: "d-m-Y HH:MM",
                          altInput: true,
                          enableTime: true,
                          time_24hr: true,
                          locale: German,
                          altInputClass: 'form-control textfield__input'
              }}
              value={event_start}
              onChange={(date) =>
                this.props.onChange(
                  'event_start',
                  dateToRailsDateString(date[0])
                )
              }
            />
          </div>

          <div className="col-md-6 mb-2">
            <label className="selectfield__label">Veranstaltungsende</label>
            <Flatpickr
              options={{
                mode: 'single',
                dateFormat: "d-m-Y HH:MM",
                altInput: true,
                enableTime: true,
                time_24hr: true,
                locale: German,
                altInputClass: 'form-control textfield__input'
              }}
              value={event_end}
              onChange={(date) =>
                this.props.onChange(
                  'event_end',
                  dateToRailsDateString(date[0])
                )
              }
            />
          </div>
        </div>
          <div className="form-row">
              <div className="col-md-6 mt-4 mb-3">
                  <Checkbox
                      name="has_deadline"
                      label="Anmeldeschluss"
                      onChange={() =>
                          this.props.onChange('has_deadline', !this.props.event.has_deadline)
                      }
                      selected={this.props.event.has_deadline || false}
                  />
                  {this.props.event.has_deadline && (
                      <Flatpickr
                          options={{
                              mode: 'single',
                              dateFormat: "d-m-Y HH:MM",
                              altInput: true,
                              enableTime: true,
                              time_24hr: true,
                              locale: German,
                              altInputClass: 'form-control textfield__input'
                          }}
                          value={deadline}
                          onChange={(date) =>
                              this.props.onChange(
                                  'deadline',
                                  dateToRailsDateString(date[0])
                              )
                          }
                      />)
                  }
              </div>
              <div className="col-md-6 mt-4">
                  <Checkbox
                      name="has_max_participants"
                      label="Maximale Teilnehmerzahl"
                      onChange={() =>
                          this.props.onChange(
                              'has_max_participants',
                              !this.props.event.has_max_participants
                          )
                      }
                      selected={this.props.event.has_max_participants || false}
                  />
                {this.props.event.has_max_participants &&
                  <Textfield
                      name="max_participants"
                      type="number"
                      handleChange={(e) =>
                          this.props.onChange('max_participants', e.target.value)
                      }
                      defaultValue={this.props.event.max_participants}
                  />
                }
              </div>
          </div>
          <hr/>
          {!this.props.event.is_webinar &&
            <div className="form-row">
              <div className="col-md-6 mb-2">
                <SimpleSelectfield
                    label="Veranstaltung am Kanzleistandort"
                    name="eventlocation_company_site_id"
                    firstOptionValue={hasMultipleSites ? "0" : ""}
                    firstOptionLabel={hasMultipleSites ? "Bitte auswählen" : ""}
                    indexLabel="id"
                    optionLabelCallback={this.cityLabel}
                    options={this.state.company.company_sites}
                    onSelect={(value) =>
                        this.props.onChange('eventlocation_company_site_id', parseInt(value))
                    }
                    selected={hasMultipleSites ? this.props.event.eventlocation_company_site_id : '0'}
                    disabled={
                        this.props.event.is_external_location ||
                        !hasMultipleSites
                    }
                />
              </div>
              <div className="col-md-3 pt-4 mt-3">
                <Checkbox
                  name="is_external_location"
                  label="Anderer Ort"
                  onChange={() =>
                    this.props.onChangeExternalLocation(
                      'is_external_location',
                      !this.props.event.is_external_location
                    )
                  }
                  selected={this.props.event.is_external_location || false}
                />
              </div>
            </div>
        }

        {this.props.event.is_webinar &&
          <>
            <div className="form-row">
              <div className="col-md-12 mb-2">
                <Textfield
                    name="webinar_link"
                    label="Webinar-Link"
                    type="text"
                    handleChange={(e) =>
                        this.props.onChange('webinar_link', e.target.value)
                    }
                    defaultValue={this.props.event.webinar_link}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-6 mb-2">
                <Textfield
                    name="webinar_meeting_passcode"
                    label={this.props.event.webinar_meeting_passcode_label || 'Meeting-Passwort'}
                    type="text"
                    handleChange={(e) =>
                        this.props.onChange('webinar_meeting_passcode', e.target.value)
                    }
                    defaultValue={this.props.event.webinar_meeting_passcode}
                />
              </div>
              <div className="col-md-6 mb-2">
                <Textfield
                    name="webinar_meeting_passcode_label"
                    label="Bezeichnung für Meeting-Passwort überschreiben"
                    placeholder="Kenncode, PIN,…"
                    type="text"
                    handleChange={(e) =>
                        this.props.onChange('webinar_meeting_passcode_label', e.target.value)
                    }
                    defaultValue={this.props.event.webinar_meeting_passcode_label}
                    hint="Kann sinnvoll sein, wenn Meeting-Anbieter eigene Bezeichnung verwenden (z.B. Kenncode oder PIN)"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-2">
                <Textfield
                    name="webinar_recording_url"
                    label="URL der Webinar-Aufzeichnung (optional)"
                    type="text"
                    handleChange={(e) =>
                        this.props.onChange('webinar_recording_url', e.target.value)
                    }
                    defaultValue={this.props.event.webinar_recording_url}
                />
              </div>
              <div className="col-md-6 mb-2">
                <Checkbox
                    name="webinar_has_recording"
                    label="Wird es eine Aufzeichnung geben?"
                    onChange={() =>
                        this.props.onChangeWebinar(
                            'webinar_has_recording',
                            !this.props.event.webinar_has_recording
                        )
                    }
                    selected={this.props.event.webinar_has_recording || false}
                />
              </div>
            </div>

            <hr/>

            <div className="form-row">
              <div className="col-12">
                  <p>
                    Kontaktdaten, die
                    während der laufenden Veranstaltung angezeigt werden (optional)
                  </p>
              </div>
            </div>

            <div className="form-group">
              <label className="textfield__label">
                Text: Technische Voraussetzungen
              </label>
              <textarea
                className="form-control"
                name="webinar_tech_description"
                defaultValue={this.props.event.webinar_tech_description || 'Das Webinar wird über Zoom durchgeführt. Bitte prüfen Sie vorab, ob Ihr System die technischen Voraussetzungen erfüllt.'}
                onChange={(e) => this.props.onChange('webinar_tech_description', e.target.value)}
              />
            </div>

            <div className="form-group">
              <label className="textfield__label">
                Text: Technische Probleme
              </label>
              <textarea
                className="form-control"
                name="webinar_tech_help"
                defaultValue={this.props.event.webinar_tech_help || 'Haben Sie technische Probleme? Wir helfen Ihnen gerne weiter. Bitte wenden Sie sich an: support@diro.eu oder telefonisch unter +49 123 456 789.'}
                onChange={(e) => this.props.onChange('webinar_tech_help', e.target.value)}
              />
            </div>

            <div className="form-row">
              <div className="col-md-6 mb-2">
                <Textfield
                    name="webinar_contact_mail"
                    label="E-Mail"
                    type="mail"
                    handleChange={(e) =>
                        this.props.onChange('webinar_contact_mail', e.target.value)
                    }
                    defaultValue={this.props.event.webinar_contact_mail}
                />
              </div>
              <div className="col-md-6 mb-2">
                <Textfield
                    name="webinar_contact_phone"
                    label="Telefon"
                    type="tel"
                    handleChange={(e) =>
                        this.props.onChange('webinar_contact_phone', e.target.value)
                    }
                    defaultValue={this.props.event.webinar_contact_phone}
                />
              </div>
            </div>

            <hr/>

            <div className="form-row">
                <div className="col-12">
                    <p>
                        Möchten Sie den Teilnehmenden im Anschluss an die Veranstaltung
                        Optionen für Feedback anbieten?
                    </p>
                </div>
              <div className="col-md-6 mb-2">
                <Checkbox
                    name="is_webinar"
                    label="Feedbackoptionen anbieten"
                    onChange={() =>
                        this.props.onChangeWebinar(
                            'requests_feedback',
                            !this.props.event.requests_feedback
                        )
                    }
                    selected={this.props.event.requests_feedback || false}
                />
              </div>
            </div>

          </>
        }

        {this.props.event.is_external_location && (
          <div className="form-row">
            <div className="col-md-6 mb-2">
              <Textfield
                name="locationname"
                label="Name des Veranstaltungortes"
                type="text"
                defaultValue={this.props.event.eventlocation.locationname || ''}
                handleChange={(e) =>
                  this.props.onChangeEventLocation('locationname', e.target.value)
                }
              />
              <Textfield
                name="street"
                label="Straße (inkl. Hausnummer)"
                type="text"
                defaultValue={this.props.event.eventlocation.street || ''}
                handleChange={(e) =>
                  this.props.onChangeEventLocation('street', e.target.value)
                }
              />
              <TextfieldGroup>
                <Textfield
                  name="zip"
                  label="PLZ"
                  type="text"
                  defaultValue={this.props.event.eventlocation.zip || ''}
                  maxLength="7"
                  handleChange={(e) =>
                    this.props.onChangeEventLocation('zip', e.target.value)
                  }
                />
                <Textfield
                  name="city"
                  label="Ort"
                  type="text"
                  defaultValue={this.props.event.eventlocation.city || ''}
                  handleChange={(e) =>
                    this.props.onChangeEventLocation('city', e.target.value)
                  }
                />
              </TextfieldGroup>
              <SimpleSelectfield
                label="Land"
                name="country_id"
                firstOptionValue="0"
                firstOptionLabel="Bitte auswählen"
                indexLabel="id"
                optionLabel="title_de"
                options={this.state.countries}
                onSelect={(value) =>
                  this.props.onChangeEventLocation('country_id', value)
                }
                selected={this.props.event.eventlocation.country_id || '0'}
              />
            </div>
            <div className="col-md-6 mb-2">
              <Textfield
                name="room"
                label="Raumbezeichnung"
                type="text"
                defaultValue={this.props.event.eventlocation.room || ''}
                handleChange={(e) =>
                  this.props.onChangeEventLocation('room', e.target.value)
                }
              />
              <Textfield
                name="phone"
                label="Telefonnummer"
                type="text"
                defaultValue={this.props.event.eventlocation.phone || ''}
                handleChange={(e) =>
                  this.props.onChangeEventLocation('phone', e.target.value)
                }
              />
              <Textfield
                name="url"
                label="WWW-Adresse"
                type="text"
                defaultValue={this.props.event.eventlocation.url || ''}
                handleChange={(e) =>
                  this.props.onChangeEventLocation('url', e.target.value)
                }
              />
              <Textfield
                name="email"
                label="E-Mail Adresse"
                type="email"
                defaultValue={this.props.event.eventlocation.email || ''}
                handleChange={(e) =>
                  this.props.onChangeEventLocation('email', e.target.value)
                }
              />
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default withRouter(EventDetails)
