import React from "react";

const BlockPreviewArticlesOverview = (props) => {

  // const content = props.block[0] // FIXME
  const articles = [1, 2, 3, 4]

  return <div className="dd-block-preview dd-block-preview--articles">
    <h2 className="pb-2">Übersicht Fachartikel</h2>
    {
      articles.map((key) => {
        return <div key={key} className="dd-block-preview__articles-article">
          An dieser Stelle werden die aktuellsten Einträge angezeigt.
        </div>
      })
    }
    <div>…</div>
  </div>
}

export default BlockPreviewArticlesOverview;
