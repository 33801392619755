import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Textfield from "../Shared/atom/Textfield/Textfield";
import TagList from "../Shared/atom/TagList/TagList";
import Editbar from "../Shared/element/Editbar/Editbar";
import Loader from "../Shared/atom/Loader/Loader";
import Checkbox from "../Shared/atom/Checkbox/Checkbox";

import { AppContext } from '../../data/ContextProvider'

import {
  getAsset,
  updateAsset,
  getTerms,
  createKeyword,
  updateVariant
} from "../../data/api"
import { getToken } from "../../lib/lib"


class MediaEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      assetID: 0,
      loading: true,
      isUpdateing: false,
      showUpdateVariantButton: false,
      asset: {},
      keywords: [],
      assetKeywords: [],
      ratio: "1:1",
      crop: {
        unit: 'px',
        width: 200,
        aspect: 1,
      },
    }
  }

  isAssetEditable = (asset) => {
    if (asset.released_for_diro) {
      if (asset.company_id !== this.context.company.id) {
        return false
      }
    }

    return true
  }

  setCropAspectRatio = ratio => {
    this.reInitAscpectRatioForCropping(ratio)
  }

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  }

  onReleaseChange = (e) => {
    const asset = { ...this.state.asset };
    asset.released_for_diro = !asset.released_for_diro
    this.setState({ asset: asset })
  }

  onCropChange = (crop, percentCrop) => {

    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {

      const scaleX = this.imageRef.naturalWidth / this.imageRef.width;
      const scaleY = this.imageRef.naturalHeight / this.imageRef.height;

      const cropX = Math.floor(crop.x * scaleX)
      const cropY = Math.floor(crop.y * scaleY)
      const width = Math.floor(crop.width * scaleX)
      const height = Math.floor(crop.height * scaleY)

      this.setState({ cropX, cropY, width, height })
    }
  }

  onChange = (event) => {
    const asset = { ...this.state.asset };
    asset[event.target.name] = event.target.value;
    this.setState({ asset: asset }, () => {
      //this.validateField(name, value)
    });
  }

  onSubmit = async () => {
    this.setState({ isUpdating: true })

    const asset = this.state.asset
    asset.keyword_ids = this.state.assetKeywords.map(tag => tag.id)

    updateAsset(getToken(), this.state.asset.id, asset)
    .then(async updatedArticle => {
      await this.loadData()
    })
    .catch(err => {
      this.setState({ isUpdating: false })
    })
  }

  onUpdateVariant = async () => {
    this.setState({ isUpdating: true })
    const asset = this.state.asset

    updateVariant(getToken(), asset.id, this.state.ratio, this.state.cropX, this.state.cropY, this.state.width, this.state.height)
    .then( async updatedArticle => {
        await this.loadData()
    })
    .catch(err => {
      this.setState({ isUpdating: false, showUpdateVariantButton: false })
    })
  }

   reInitAscpectRatioForCropping = (aspectRatioString) => {
    const mapping = { "1:1": 1, "2:3": 0.6666666666666666, "3:2": 1.5, "16:9": 1.7777777778 , "5:2": 2.5}
    const aspect = mapping[aspectRatioString]

    const newCrop = makeAspectCrop({ ...this.state.crop, width: 200, aspect: aspect }, aspect)

    if (newCrop.width < 0) (
      newCrop.width = newCrop.width * -1
    )

    if (newCrop.height < 0) (
      newCrop.height = newCrop.height * -1
    )

    this.setState({ crop: newCrop, ratio: aspectRatioString, showUpdateVariantButton: true})
   }

  loadData = async () => {
    try {
      const asset    = await getAsset(getToken(), this.state.assetID)
      const keywords = await getTerms(getToken(), 'keywords')

      this.setState({
        ...this.state,
        asset: asset.asset,
        keywords: keywords.keywords,
        assetKeywords: asset.asset.keywords,
        loading: !this.isAssetEditable(asset.asset)
      })

    } catch (error) {
      //FIXME NotFound
    }
  }

  async componentDidMount() {
    if (this.props.match && this.props.match.params) {
      this.setState({
        ...this.state,
        assetID: this.props.match.params.asset_id,
        lang: this.props.match.params.lang,
      }, async () => {
        await this.loadData()
      })
    }
  }

  addKeywordsTag = (newTag) => {
    const { keywords, assetKeywords } = this.state
    createKeyword(getToken(), newTag)
    .then(newKeyword => {
      keywords.push(newKeyword.keyword)
      assetKeywords.push(newKeyword.keyword)
      this.setState({
        keywords,
        assetKeywords
      })
    })
    .catch(err => {
    })
  }

  updateKeywordsTags = (assignedTags) => {
    this.setState({
      assetKeywords: assignedTags
    })
  }

  render() {

    if (this.state.loading) {
      return <Loader />
    }

    const diro_admin = this.context.user.diro_admin

    const { crop  } = this.state;

    return (
      <div className="dd-media-edit">
        <div className="row">
          <div className="col-md-3">
            <Link className="dd-media__link" to={`/${this.state.lang}/media`}>« Zurück zur Übersicht</Link>
          </div>
          <div className="col">
              <p>Bitte geben Sie die folgenden Meta-Daten für das Bild an.</p>

              <div className="row">
                <div className="col">
                  <Textfield
                    name="title"
                    type="text"
                    isRequired={false}
                    label="Bildtitel"
                    placeholder="Bildtitel"
                    defaultValue={this.state.asset.title}
                    handleChange={this.onChange}
                  />
                </div>
                <div className="col">
                  <Textfield
                    name="copyright"
                    type="text"
                    isRequired={false}
                    label="Copyright"
                    placeholder="Copyright"
                    defaultValue={this.state.asset.copyright}
                    handleChange={this.onChange}
                  />
                </div>
              </div>

            <div className="row">
              <div className="col">
                <Textfield
                  name="alt"
                  type="text"
                  isRequired={false}
                  label="Alternativtext dieses Bildes (u.a. für blinde Nutzer)"
                  placeholder="Eine kurze Beschreibung was auf dem Bild zu sehen ist"
                  defaultValue={this.state.asset.alt}
                  handleChange={this.onChange}
                />
              </div>
                <div className="col">
                  <Textfield
                    name="filename"
                    type="text"
                    isRequired={false}
                    label="Dateiname"
                    placeholder="Dateiname"
                    defaultValue={this.state.asset.filename}
                    handleChange={this.onChange}
                  />
                </div>
            </div>



              {this.state.keywords.length &&
                <TagList
                  name="Stichworte, um die es in diesem Bild geht"
                  hasAddNew={true}
                  indexLabel="title_de"
                  data={this.state.assetKeywords}
                  availableData={this.state.keywords}
                  label="Stichworte, die auf dieses Bild zutreffen"
                  onUpdate={(assignedTags) => this.updateKeywordsTags(assignedTags)}
                  onCreate={(newTag) => this.addKeywordsTag(newTag)}
                />
              }

            {diro_admin &&
              <div className="form-group">
              <label className="textfield__label">Dieses Bild für das DIRO Netzwerk freigeben?</label>
                <Checkbox
                  label="ja"
                  id="incomplete"
                  selected={this.state.asset.released_for_diro}
                  onChange={(e) => this.onReleaseChange(e)}
                />
              </div>
            }


            {this.state.asset.content_type === "image" &&
              <div className="dd-media-edit__cropping cropping">
                <p>Bitte wählen Sie bitte die optimalen Bildausschnitte für die verschiedenen Anwendungen</p>

                <div className="row">
                  <div className="col-md-8">
                    <div className="cropping__canvas">
                      <ReactCrop
                        src={this.state.asset.file_path }
                        crop={crop}
                        imageStyle={{}}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        crossorigin="anonymous"
                      />
                    </div>

                    { this.state.showUpdateVariantButton &&
                      <div className="cropping__confirm">
                      <button className="dd-btn dd-btn--primary" onClick={this.onUpdateVariant}>Ausschnitt {this.state.ratio} bestätigen</button>
                      </div>
                    }

                  </div>
                  <div className="col-md-4">
                    <div className="cropping__selection">

                      <button className={`cropping__button ${(this.state.ratio === "1:1") ? "is-active" : ""}`} onClick={() => this.setCropAspectRatio("1:1")}>
                        {(this.state.asset.one_by_one_file_path) ?
                          <img className="cropping__preview" src={this.state.asset.one_by_one_file_path} alt="" width="200px" />
                          :
                          <div className="cropping__preview ratio--1-1"></div>
                        }
                        <span className="cropping__ratio">1:1</span>
                      </button>

                      <button className={`cropping__button ${(this.state.ratio === "2:3") ? "is-active" : ""}`} onClick={() => this.setCropAspectRatio("2:3")}>
                        {(this.state.asset.two_by_three_file_path) ?
                          <img className="cropping__preview" src={this.state.asset.two_by_three_file_path} alt="" width="200px" />
                          :
                          <div className="cropping__preview ratio--2-3"></div>
                        }
                        <span className="cropping__ratio">2:3</span>
                      </button>

                      <button className={`cropping__button ${(this.state.ratio === "3:2") ? "is-active" : ""}`} onClick={() => this.setCropAspectRatio("3:2")}>
                        {(this.state.asset.three_by_two_file_path) ?
                          <img className="cropping__preview" src={this.state.asset.three_by_two_file_path} alt="" width="200px" />
                          :
                          <div className="cropping__preview ratio--3-2"></div>
                        }
                        <span className="cropping__ratio">3:2</span>
                      </button>

                      <button className={`cropping__button ${(this.state.ratio === "16:9") ? "is-active" : ""}`} onClick={() => this.setCropAspectRatio("16:9")}>
                        {(this.state.asset.sixteen_by_nine_file_path) ?
                          <img className="cropping__preview" src={this.state.asset.sixteen_by_nine_file_path} alt="" width="200px" />
                          :
                          <div className="cropping__preview ratio--16-9"></div>
                        }
                        <span className="cropping__ratio">16:9</span>
                      </button>

                    <button className={`cropping__button ${(this.state.ratio === "5:2") ? "is-active" : ""}`} onClick={() => this.setCropAspectRatio("5:2")}>
                      {(this.state.asset.five_by_two_file_path) ?
                        <img className="cropping__preview" src={this.state.asset.five_by_two_file_path} alt="" width="200px" />
                        :
                        <div className="cropping__preview ratio--5-2"></div>
                      }
                      <span className="cropping__ratio">5:2</span>
                    </button>

                    </div>
                  </div>
                </div>
              </div>
            }
            <Editbar
              hasPublish={false}
              hasPreview={false}
              onSave={this.onSubmit}
              successMessage="Erfolgreich gespeichert"
            />
          </div>
        </div>
      </div>
    );
  }
}

MediaEdit.contextType = AppContext
export default withRouter(MediaEdit);